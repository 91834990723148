import axios from 'axios';

export default class ApiService {
    constructor(baseUrl, baseEndpoint, version) {
        this.baseEndpoint = baseEndpoint;
        this.baseUrl = baseUrl;
        this.version = version;
    }

    axios() {
        const axiosConfig = {
            baseURL: `/${this.baseUrl}/v${this.version}/${this.baseEndpoint}`
        }

        return axios.create(axiosConfig);
    }

    static make() {
        return new ApiService('internal-api', 'configurable-fields', 1);
    }

    list(type, category, categoryId = null) {
        return this.axios().get('/list', {
            params: {
                type: type,
                category: category,
                category_id: categoryId
            }
        })
    }

    save(fields, type, category, categoryId = null) {
        return this.axios().post('/save', {
            fields: fields,
            type: type,
            category: category,
            category_id: categoryId
        });
    }

    getTypeCategories() {
        return this.axios().get('/type-categories');
    }

    getFieldTypes() {
        return this.axios().get('/field-types');
    }

    getCompanyFieldCategories() {
        return this.axios().get('/company/categories');
    }
}

