<template>
    <modal
        :dark-mode="darkMode"
        :small="true"
        :confirm-text="savingTask ? 'Saving...' : 'Create Task'"
        :close-text="'Cancel'"
        @confirm="createTask" @close="$emit('close')"
    >
        <template v-slot:header>
            <h4 class="text-xl">Create Task</h4>
        </template>
        <template v-slot:content>
            <div class="mb-6" v-if="alertMessage">
                <alert :text="alertMessage" :alert-type="alertType" :dark-mode="darkMode"></alert>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-3">
                <div class="col-span-2">
                    <p class="uppercase font-semibold text-xs mb-2"
                       :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                        Task Note
                    </p>
                    <input class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                           placeholder="Task note"
                           v-model="taskNote"
                           :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                </div>
                <div>
                    <p class="uppercase font-semibold text-xs mb-2"
                       :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                        Task Type
                    </p>
                    <dropdown :options="taskTypes" :dark-mode="darkMode" :placeholder="'Select Task Type'" v-model="taskType"></dropdown>
                </div>
                <div>
                    <p class="uppercase font-semibold text-xs mb-2"  :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                        Task Category
                    </p>

                    <Dropdown v-model="taskCategory" :options="taskCategories" :dark-mode="darkMode" placeholder="Select Category" :selected="taskCategory"></Dropdown>
                </div>
                <div>
                    <p class="uppercase font-semibold text-xs mb-2"
                       :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                        Priority
                    </p>
                    <Dropdown :dark-mode="darkMode" class="mr-2" v-model="priority" :options="taskPriorities" placeholder="Priority" :selected="priority"></Dropdown>
                </div>
                <div>
                    <p class="uppercase font-semibold text-xs mb-2"
                       :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                        Available At
                    </p>
                    <button class="transition duration-200 text-sm font-semibold focus:outline-none py-3 rounded-lg px-5" @click="showCalender = true"
                            :class="{'bg-grey-475 hover:bg-blue-800 text-white': !darkMode, 'bg-blue-400 hover:bg-blue-500 text-white': darkMode}">
                        Pick Date Time
                    </button>
                    {{ availableAt }}
                    <div v-if="showCalender" class="mt-3">
                        <Datepicker :inline="true" @update:modelValue="setDateTime" :format="'yyyy-MM-dd HH:mm'" :start-time="manualTaskDefaultTime" :dark="darkMode"></Datepicker>
                    </div>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import Dropdown from "./Dropdown.vue";
import Modal from "./Modal.vue";
import Alert from "./Alert.vue";
import ApiService from "../../Tasks/services/api";
import TaskManagementApiService from "../../TaskManagement/services/api";

export default {
    name: "CreateTaskModal",
    components: {Dropdown, Modal, Alert},
    emits: ['task-created', 'close'],
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        companyId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            apiService: ApiService.make(),
            taskManagementApi: TaskManagementApiService.make(),
            availableAt: null,
            taskCategories: [],
            taskTypes: [],
            alertMessage: null,
            savingTask: false,
            alertType: 'success',
            showCalender: false,
            taskPriorities: [
                {id: 1, name: 'Low'},
                {id: 2, name: 'Medium'},
                {id: 3, name: 'High'},
                {id: 4, name: 'Urgent'}
            ],
            manualTaskDefaultTime: {hours: 8, minutes: 0, seconds: 0},
            taskType: null,
            taskNote: null,
            taskCategory: null,
            priority: null
        }
    },
    created() {
        this.getTaskTypes();
        this.getTaskCategories();
    },
    methods: {
        createTask() {
            if (this.savingTask) return;

            this.alertMessage = null;
            this.savingTask = true;

            this.apiService.createTask({
                company_id: this.companyId,
                task_type_id: this.taskType,
                subject: this.taskNote,
                available_at: this.availableAt,
                priority: this.priority,
                task_category_id: this.taskCategory
            }).then(() => {
                this.alertType = 'success';
                this.alertMessage = 'A task was created successfully';
                this.$emit('task-created');
            }).catch(e => {
                this.alertType = 'error';
                this.alertMessage = e.response.data.message;
            }).finally(() => {
                this.savingTask = false;
            });
        },
        setDateTime(date) {
            this.availableAt = this.$filters.dateFromTimestamp(date, 'YYYY-MM-DD HH:mm');
            this.showCalender = false;
        },
        getTaskTypes() {
            this.taskManagementApi.getTaskTypes().then(resp => this.taskTypes = resp.data.data.task_types);
        },
        getTaskCategories() {
            this.taskManagementApi.getTaskCategories().then(resp => this.taskCategories = resp.data.data.categories);
        },
    }
}
</script>

<style scoped>

</style>
