<template>
    <div class="border rounded-lg py-4 px-6 relative"
         :class="{ 'bg-light-module border-light-border text-dark-175': !darkMode, 'bg-dark-module border-dark-border text-gray-300': darkMode}">
        <header @click="toggleExpand()" class="mb-2 cursor-pointer pb-2">
            <div class="flex items-center">
                <svg class="w-3 h-3 mr-2" :class="{'transform transition duration-300 rotate-90' : expanded }" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 9L5 5L1 1" stroke="#0081FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p class="text-base font-semibold text-primary-400 mr-2 capitalize">{{ activityType }}</p>
                <p class="text-sm" :class="[darkMode ? 'text-slate-400' : 'text-slate-600']">{{activity.item?.type === 'call' || activity.item?.type === 'text' ? direction : 'created by'}}<span :class="[darkMode ? 'text-slate-300' : 'text-slate-800']" class="ml-2 font-medium">{{activity.user?.name}}</span></p>
            </div>
            <p class="text-sm text-slate-500">
                <span :title="createdTimestamp"> created {{ createdTimeAgo }} </span> {{ createDateTime }}
                <span v-if="activity.created_at !== activity.updated_at" :title="updatedTimestamp" class="block">updated {{ updatedTimeAgo }}</span>
            </p>
        </header>
        <div class="border-t py-2 relative transition-[max-height] duration-300 h-auto"
            :class="{ 'border-dark-border': darkMode, 'border-light-border': !darkMode, [`${expandedMaxHeight} overflow-y-auto`]: expanded, [`${collapsedMaxHeight} overflow-hidden`]: !expanded }">
            <component class="py-2 w-full mx-auto"
                       :is="getComponent"
                       :item-data="activity.item.data ?? {}"
                       :user-data="activity.user ?? {}"
                       :dark-mode="darkMode"
                       :company-id="companyId"
                       :expanded="expanded"
                       @reload="reload">
            </component>
            <div @click="toggleExpand()"
                class="absolute left-0 top-0 w-full h-full transition-[max-height] duration-300"
                :class="{ 'bg-gradient-to-t from-dark-module': darkMode, 'bg-gradient-to-t from-light-module': !darkMode, [`${collapsedMaxHeight} cursor-pointer`]: !expanded, [`${expandedMaxHeight} opacity-0 pointer-events-none cursor-default`]: expanded }" />
        </div>
        <footer class="pt-2 mt-2 mx-auto relative">
            <ActivityComments
                :comment-count="activity.comment_count"
                :expanded="expanded"
                :activity-id="activity.id"
                :company-id="companyId"
                :dark-mode="darkMode"
            />
        </footer>
    </div>

</template>

<script>
import ActivityComments from "../comments/ActivityComments";
import CallItem from "./CallItem";
import ActionItem from "./ActionItem";
import TaskItem from "./TaskItem";
import TextItem from "./TextItem";
import EmailItem from "./EmailItem";
import {DateTime} from "luxon";

const componentMap = {
    'call'  : CallItem,
    'action': ActionItem,
    'task'  : TaskItem,
    'text'  : TextItem,
    'email' : EmailItem,
}

export default {
    name: "ActivityItem",
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        activity: {
            type: Object,
            default: {},
        },
        companyId: {
            type: Number,
            default: null,
        },
        collapsedMaxHeight: {
            type: String,
            default: 'max-h-36'
        },
        expandedMaxHeight: {
            type: String,
            default: 'max-h-[48rem]',
        },
    },
    computed: {
        createdTimeAgo() { return this.$filters.simpleRelativeTimeFromTimestamp(this.activity.created_at); },
        createdTimestamp() { return this.$filters.absoluteDatetimeFromTimestamp(this.activity.created_at); },
        updatedTimeAgo() { return  this.$filters.simpleRelativeTimeFromTimestamp(this.activity.updated_at); },
        updatedTimestamp() { return this.$filters.absoluteDatetimeFromTimestamp(this.activity.updated_at); },
        createDateTime() {
            if (!this.activity.item?.data?.created_at) return null;

            return '(' + DateTime.fromISO(this.activity.item.data.created_at).toFormat('DD hh:mm a') + ')';
        },
        activityType() {
            if (!this.activity.item?.type) return 'Unknown Activity';
            if (this.activity.item.type === 'call' || this.activity.item.type === 'text') return `${this.activity.item.data?.direction} ${this.activity.item.type}`;

            return this.activity.item.type;
        },
        direction () { return this.activity.item?.data?.direction === 'inbound' ? 'to' : 'from'; },
        getComponent() {
            return componentMap[this.activity.item?.type] ?? 'div';
        }
    },
    data() {
        return {
            expanded: false,
            comments: null,
        }
    },
    emits: [
        "reload"
    ],
    components: {
        ActivityComments,
        CallItem,
        ActionItem,
        TextItem,
        TaskItem,
        EmailItem
    },
    methods: {
        toggleExpand() {
            this.expanded = !this.expanded;
        },
        reload() {
            this.$emit('reload');
        }
    }
}
</script>

<style scoped>

</style>
