<template xmlns="http://www.w3.org/1999/html">
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
        <alerts-container :text="alertText" :alert-type="alertType" v-if="alertActive" :dark-mode="darkMode"/>

        <div class="border rounded-lg"
             :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
            <div class="p-5">
                <div class="flex items-center justify-between pb-3">
                    <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Call Forwarding</h5>
                </div>
                <div class="grid gap-4">
                    <div>
                        <label class="block mb-1 font-semibold text-sm" :class="[darkMode ? 'text-slate-500' : 'text-slate-600']" >Status</label>
                        <toggle :dark-mode="darkMode" v-model="phoneForwardStatus" @click="updateCallForwardingStatus" ></toggle><br>
                    </div>
                    <div>
                        <label class="block mb-1 font-semibold text-sm" :class="[darkMode ? 'text-slate-500' : 'text-slate-600']" >To Number</label>
                        <div class="flex flex-wrap items-center mt-2 gap-3">
                            <input onkeydown="return event.keyCode !== 69" :class="[darkMode ? 'border-dark-border bg-dark-background text-slate-100' : 'border-light-border bg-light-background text-slate-800']" class="rounded-md h-9 py-2 px-3 w-full text-black" type="number" v-model="phoneForwardNumber"/>
                            <button class="text-sm bg-primary-500 h-9 px-4 py-2 rounded-md hover:bg-primary-400 transition duration-200 text-white font-medium" @click="updateCallForwardingNumber">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="border rounded-lg"
             :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
            <div class="p-5">
                <div class="flex flex-col pb-3">
                    <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight pb-2">Default Timezone</h5>
                    <p class="text-xs text-slate-500">This is used as the fallback timezone when we can't calculate timezones for tasks, etc.</p>
                </div>

                <label class="block mb-1 font-semibold text-sm" :class="[darkMode ? 'text-slate-500' : 'text-slate-600']" >Timezone</label>
                <div class="flex flex-wrap gap-3">
                    <dropdown :dark-mode="darkMode" v-model="selectedTimezone" :options="timezoneOptions"></dropdown>
                    <button class="text-sm bg-primary-500 h-9 px-4 py-2 rounded-md hover:bg-primary-400 transition duration-200 text-white font-medium" @click="saveTimezone">Save</button>
                </div>
            </div>
        </div>

        <div class="border rounded-lg"
             :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
            <div class="p-5">
                <div class="flex flex-col pb-3">
                    <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight pb-2">Filter Presets</h5>
                    <p class="text-xs text-slate-500">Set presets for certain component filters</p>
                </div>
                <a href="/filter-presets" class="text-sm bg-primary-500 h-9 px-4 py-2 rounded-md hover:bg-primary-400 transition duration-200 text-white font-medium">Configure Presets</a>
            </div>
        </div>
    </div>
</template>

<script>
import Toggle from "../Inputs/Toggle/Toggle";
import {UserSettingsApiFactory} from "../../../services/api/user_settings/factory";
import AlertsContainer from "../Shared/components/AlertsContainer";
import Dropdown from "../Shared/components/Dropdown.vue";

export default {
    components: {Toggle, AlertsContainer, Dropdown},
    data() {
        return {
            phoneForwardStatus: false,
            phoneForwardNumber: null,
            oldPhoneForwardNumber: null,
            api: null,
            alertActive: false,
            alertType: '',
            alertText: '',
            timezoneOptions: [
                {id: "-05:00", name: "Eastern"},
                {id: "-06:00", name: "Central"},
                {id: "-07:00", name: "Mountain"},
                {id: "-08:00", name: "Pacific"},
                {id: "-09:00", name: "Alaska"},
                {id: "-10:00", name: "Hawaii"},
            ],
            selectedTimezone: null
        }
    },
    props: {
        user: {
            type: Object,
            default: {},
        },
        apiDriver: {
            type: String,
            default: 'api'
        },
        darkMode : {
            type: Boolean,
            default: false,
        }
    },
    created() {
        this.api = UserSettingsApiFactory.makeApiService(this.apiDriver);
        this.getCallForwardingData();
        this.getTimezone();
    },
    methods: {
        getCallForwardingData() {
            this.api.getCallForwardingData(this.user.id).then(resp => {
                this.phoneForwardStatus = resp.data.data[0].status === 1;
                this.phoneForwardNumber = resp.data.data[0].forward_to_number;
                this.oldPhoneForwardNumber = resp.data.data[0].forward_to_number;
            })
        },
        updateCallForwardingStatus() {
            this.api.updateCallForwardingStatus(this.user.id, this.phoneForwardStatus)
                .catch(error => {
                    this.phoneForwardStatus = !this.phoneForwardStatus;
                    this.activateAlert('error', 'There was an issue saving');
                });
            this.activateAlert('success', 'Saved');
        },
        updateCallForwardingNumber() {
            this.api.updateCallForwardingNumber(this.user.id, this.phoneForwardNumber)
                .then(resp => {
                    if('errors' in resp.data.data){
                        this.phoneForwardNumber = this.oldPhoneForwardNumber;
                        this.activateAlert('error', resp.data.data.errors);
                    }else{
                        this.phoneForwardNumber = resp.data.data[0].forward_to_number;
                        this.oldPhoneForwardNumber = resp.data.data[0].forward_to_number;
                        this.activateAlert('success', 'Saved');
                    }
                })
                .catch(error => {
                    this.phoneForwardNumber = this.oldPhoneForwardNumber;
                    this.activateAlert('error', 'There was an issue saving');
                });
        },
        // This method controls the Alert Container
        activateAlert(type, text) {
            this.alertActive = true;
            this.alertType = type;
            this.alertText = text;

            setTimeout(() => {
                this.alertActive = false;
                this.alertType = '';
                this.alertText = '';
            },1000)
        },
        getTimezone() {
            this.api.getUserTimezone(this.user.id).then(resp => {
                this.selectedTimezone = resp.data.data.timezone;
            });
        },
        saveTimezone() {
            this.api.updateUserTimezone(this.user.id, this.selectedTimezone).then(resp => {
                this.activateAlert('success', 'Saved timezone settings');
            });
        }
    },
};
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
</style>
