<template>
    <div class="p-5">
        <div class="flex items-center justify-between mb-3">
            <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Industry - Company Field List</h5>
            <button
                class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5" @click="openModal">
                Add New Company Field
            </button>
        </div>
        <div class="grid grid-cols-12 gap-x-3 mb-2 px-5" v-if="!loading">
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Industry</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Name</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Key</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Type</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Category</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Show On Profile</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Show On Dashboard</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Created At</p>
        </div>
        <div class="border-t border-b" v-if="!loading"
             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-40 border-dark-border': darkMode}">
            <div>
                <div class="grid grid-cols-12 gap-x-3 border-b px-5 py-3 items-center"
                     v-for="industryCompanyField in industryCompanyFields" :key="industryCompanyField.id"
                     :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <p class="text-sm col-span-2 truncate">
                        {{ industryCompanyField.industry }}
                    </p>
                    <p class="text-sm col-span-1 truncate">
                        {{ industryCompanyField.name }}
                    </p>
                    <p class="text-sm col-span-1 truncate">
                        {{ industryCompanyField.key }}
                    </p>
                    <p class="text-sm col-span-1 truncate">
                        {{ industryCompanyField.type?.name }}
                    </p>
                    <p class="text-sm col-span-1 truncate">
                        {{ industryCompanyField.category }}
                    </p>
                    <p class="text-sm col-span-1">
                        {{ industryCompanyField.show_on_profile }}
                    </p>
                    <p class="text-sm col-span-2">
                        {{ industryCompanyField.show_on_dashboard }}
                    </p>
                    <p class="text-sm col-span-2">
                        {{ formatDate(industryCompanyField.created_at) }}
                    </p>
                    <ActionsHandle :dark-mode="darkMode" @edit="editIndustryCompanyField(industryCompanyField)" @delete="deleteIndustryCompanyField(industryCompanyField)"/>
                </div>
            </div>
        </div>
        <div class="h-32 flex items-center justify-center" v-if="loading">
            <loading-spinner></loading-spinner>
        </div>
        <modal :small="true" v-if="showModal" @close="closeModal" :dark-mode="darkMode" @confirm="saveIndustryCompanyField" :close-text="'Cancel'" :confirm-text="confirmText">
            <template v-slot:header>
                <h4>{{ industryCompanyField.id ? 'Update' : 'Create' }} Company Field</h4>
            </template>
            <template v-slot:content>
                <div class="grid gap-3">
                    <div v-if="!industryCompanyField.id" class="flex items-center">
                        <p class="uppercase font-semibold text-xs w-20"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Type
                        </p>
                        <Dropdown :dark-mode="darkMode" v-model="industryCompanyField.type.id" :options="fieldTypes" placeholder="Type" :selected="industryCompanyField.type.id"></Dropdown>
                    </div>
                    <div class="flex items-center">
                        <p class="uppercase font-semibold text-xs w-20"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Category
                        </p>
                        <Dropdown :dark-mode="darkMode" v-model="industryCompanyField.category" :options="fieldCategories" placeholder="Category" :selected="industryCompanyField.category"></Dropdown>
                    </div>
                    <div class="flex items-center">
                        <p class="uppercase font-semibold text-xs w-20"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Name
                        </p>
                        <input class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                               placeholder="Name"
                               v-model="industryCompanyField.name"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                    </div>
                    <div class="flex items-center">
                        <p class="uppercase font-semibold text-xs w-20"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Key
                        </p>
                        <input class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                               placeholder="Key"
                               v-model="industryCompanyField.key"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                    </div>
                    <div class="flex items-center">
                        <p class="uppercase font-semibold text-xs w-40"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Show On Profile
                        </p>
                        <toggle v-model="industryCompanyField.show_on_profile"></toggle>
                    </div>
                    <div class="flex items-center">
                        <p class="uppercase font-semibold text-xs w-40"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Show On Dashboard
                        </p>
                        <toggle v-model="industryCompanyField.show_on_dashboard"></toggle>
                    </div>
                </div>
            </template>
        </modal>
        <alerts-container :dark-mode="darkMode" :alert-type="'error'" :text="error" v-if="error"></alerts-container>
    </div>

</template>

<script>
import Dropdown from "../../../Shared/components/Dropdown";
import Toggle from "../../../Inputs/Toggle/Toggle";
import Modal from "../../../Shared/components/Modal";
import ApiService from "../services/api";
import ConfigurableFieldApi from "../../ConfigurableFields/services/api";
import FieldsApiService from "../../ConfigurableFields/services/api";
import ActionsHandle from "../../../Shared/components/ActionsHandle";
import LoadingSpinner from "../../../Shared/components/LoadingSpinner";
import AlertsContainer from "../../../Shared/components/AlertsContainer";
import {DateTime} from "luxon";

export default {
    name: "IndustryCompanyFields",
    components: {Dropdown, Toggle, Modal, ActionsHandle, LoadingSpinner, AlertsContainer},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        industry: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            api: ApiService.make(),
            configurableFieldApi: ConfigurableFieldApi.make(),
            fieldApi: FieldsApiService.make(),
            showModal: false,
            industryCompanyFields: [],
            industryCompanyField: {type: {id: null, name: null}, name: null, key: null, show_on_profile: false, show_on_dashboard: false, payload: [], category: null},
            fieldTypes: [],
            saving: false,
            loading: false,
            error: null,
            fieldCategories: []
        }
    },
    created() {
        this.getIndustryCompanyFields();
        this.getFieldTypes();
        this.getFieldCategories();
    },
    computed: {
        confirmText: function () {
            if (this.saving) return 'Saving...';
            if (this.industryCompanyField.id) return 'Update';

            return 'Create';
        }
    },
    methods: {
        openModal() {
            this.showModal = true;
        },
        closeModal () {
            this.showModal = false;
            this.industryCompanyField = {type: {id: null, name: null}, name: null, key: null, show_on_profile: false, show_on_dashboard: false, payload: [], category: null};
        },
        getIndustryCompanyFields() {
            if(!this.industry) return;
            if(!this.loading) this.loading = true;
            this.error = null;

            this.api.getIndustryCompanyFields(this.industry).then(resp => this.industryCompanyFields = resp.data.data.fields)
                .catch(e => this.error = e.response.data.message).finally(() => this.loading = false);
        },
        saveIndustryCompanyField() {
            if(this.saving) return;
            this.saving = true;
            this.error = null;

            if (this.industryCompanyField.id) {
                this.updateIndustryCompanyField();
                return;
            }
            this.createIndustryCompanyField();
        },
        getParamsToMakeRequest() {
            return {
                name              : this.industryCompanyField.name,
                key               : this.industryCompanyField.key,
                type              : this.industryCompanyField.type?.id,
                show_on_profile   : this.industryCompanyField.show_on_profile === true ?? false,
                show_on_dashboard : this.industryCompanyField.show_on_dashboard === true ?? false,
                payload           : this.industryCompanyField.payload ?? [],
                category          : this.industryCompanyField.category
            }
        },
        createIndustryCompanyField() {
            this.api.createIndustryCompanyField(this.industry, this.getParamsToMakeRequest()).then(() => this.refreshList(true))
                .catch(e => this.error = e.response.data.message).finally(() => this.saving = false);
        },
        editIndustryCompanyField(industryCompanyField) {
            this.industryCompanyField = {...industryCompanyField};
            this.industryCompanyField.show_on_profile = this.industryCompanyField.show_on_profile === 'Yes';
            this.industryCompanyField.show_on_dashboard = this.industryCompanyField.show_on_dashboard === 'Yes';
            this.openModal();
        },
        updateIndustryCompanyField() {
            this.api.updateIndustryCompanyField(this.industryCompanyField.id, this.industry, this.getParamsToMakeRequest()).then(() => this.refreshList(true))
                .catch(e => this.error = e.response.data.message).finally(() => this.saving = false);
        },
        deleteIndustryCompanyField(industryCompanyField) {
            this.error = null;
            this.api.deleteIndustryCompanyField(industryCompanyField.id).then(() => this.refreshList(false))
                .catch(e => this.error = e.response.data.message);
        },
        formatDate(date) {
            return date ? DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT) : null
        },
        getFieldTypes() {
            this.fieldApi.getFieldTypes().then(resp => {
                resp = resp.data.data?.field_types;
                let types = [];
                Object.keys(resp).forEach(function(key){ types.push({id: key, name: resp[key]}) });
                this.fieldTypes = types;
            }).catch(e => this.error = e.response.data.message);
        },
        refreshList(closeModal = false) {
            this.getIndustryCompanyFields();
            if(closeModal) this.closeModal();
        },
        getFieldCategories() {
            this.configurableFieldApi.getCompanyFieldCategories().then(resp => this.fieldCategories = resp.data.data.categories);
        }
    }
}
</script>
