<template>
    <div>
        <alerts-container v-if="alertActive" :alert-type="alertType" :text="alertText" :dark-mode="darkMode" />

        <div class="grid grid-cols-9 gap-5 my-3">
            <div>
                ID
            </div>
            <div>
                Company
            </div>
            <div>
                Consumer Name
            </div>
            <div>
                Title
            </div>
            <div>
                Status
            </div>
            <div>
                Phone Validated
            </div>
            <div>
                Email Validated
            </div>
            <div>
                Last Updated
            </div>
            <div>
                Actions
            </div>
        </div>
        <template v-if="loading">
            <loading-spinner/>
        </template>
        <template v-else-if="reviews">
            <div v-for="review in reviews.data"
                 :key="review.id"
                 class="grid grid-cols-9 gap-5 my-3">
                <div>
                    {{ review.id }}
                </div>
                <div>
                    {{ review.company.name }}
                </div>
                <div>
                    {{ review.first_name }} {{ review.last_name }}
                </div>
                <div>
                    {{ review.title }}
                </div>
                <div>
                    {{ reviewStatuses[review.status] }}
                </div>
                <div>
                    <svg v-if="review.phone_validated" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                        <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                    </svg>
                </div>
                <div>
                    <svg v-if="review.email_validated" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                        <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                    </svg>
                </div>
                <div>
                    {{ (new Date(review.updated_at || review.created_at)).toLocaleString() }}
                </div>
                <div>
                    <button
                        type="button"
                        class="px-5 py-3 rounded border border-cyan-400 text-cyan-400 hover:bg-cyan-400 hover:text-white"
                        @click="edit(review)">
                        Edit
                    </button>
                </div>
            </div>

            <pagination :dark-mode="darkMode" :pagination-data="reviews" @change-page="list($event.link)" />
        </template>
        <template>
            <div class="mx-auto text-2xl mt-8">
                No Reviews
            </div>
        </template>
    </div>
</template>

<script>
    import ApiService from "../API/api";
    import Pagination from "../../Shared/components/Pagination";
    import LoadingSpinner from "../../Shared/components/LoadingSpinner";
    import AlertsContainer from "../../Shared/components/AlertsContainer";

    export default {
        name: "CompanyReviewsList",
        props: {
            darkMode: {
                type: Boolean,
                default: false
            },
            api: {
                type: ApiService,
                required: true
            }
        },
        components: {
            AlertsContainer,
            LoadingSpinner,
            Pagination
        },
        emits: [
            'change-tab',
            'edit-review'
        ],
        data: function() {
            return {
                loading: false,
                reviews: null,
                reviewStatuses: {}
            };
        },
        created: function() {
            this.list();

            this.api.getReviewStatuses().then(res => {
                if(res.data.data.status === true) {
                    this.reviewStatuses = res.data.data.statuses;
                }
                else {
                    this.showAlert('error', 'Problem retrieving statuses');
                }
            }).catch(err => {
                this.showAlert('error', 'Error retrieving statuses');
            });
        },
        methods: {
            list(url) {
                this.loading = true;

                let request = url ? this.api.changeListPage(url) : this.api.list();

                request.then((res) => {
                    if(res.data.data.status === true) {
                        this.reviews = res.data.data.company_reviews;
                    }
                }).catch((err) => {

                }).finally(() => {
                    this.loading = false;
                });
            },
            edit(review) {
                this.$emit('edit-review', review);

                this.changeTab('editor');
            },
            changeTab(tab) {
                this.$emit('change-tab', tab);
            }
        }
    }
</script>
