<template>
    <div class="mb-10" :class="[disabled ? 'opacity-25 pointer-events-none' : '']">
        <div class="flex items-center space-x-4 mb-2">
            <label class="block text-sm font-semibold">{{ filterName }}</label>
            <p
                class="text-sm font-medium text-slate-500 opacity-50 cursor-default">
                Reset</p>
        </div>
        <div class="flex items-center space-x-6">
            <toggle-switch v-model="active" :dark-mode="darkMode"></toggle-switch>
            <div class="grid grid-cols-12 gap-3 w-full items-center"
                 :class="[active ? '' : 'opacity-25 pointer-events-none']">
                <Dropdown :options="operatorOptions"
                          :maxHeight="'max-h-[8rem]'"
                          :selected="firstOperator" v-model="firstOperator" :dark-mode="darkMode"
                          class="col-span-2"></Dropdown>
                <div class="col-span-2">
                    <slot name="first-input" v-if="$slots['first-input']"></slot>
                    <CustomInput
                        v-else
                        :dark-mode="darkMode"
                        type="number"
                        v-model:modelValue="firstValue"
                        @update:modelValue="firstValue = validateMinimumInteger(firstValue)"></CustomInput>
                </div>
                <div class="flex justify-center" @click="toggleOperator">
                    <p v-if="logical"
                       class="uppercase font-bold text-sm cursor-pointer text-center text-primary-500 hover:text-primary-600">
                        {{ logical }}
                    </p>
                    <svg v-else class="fill-primary-500 hover:fill-primary-600 cursor-pointer" width="16" height="16"
                         viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM9 5C9 4.73478 8.89464 4.48043 8.70711 4.29289C8.51957 4.10536 8.26522 4 8 4C7.73478 4 7.48043 4.10536 7.29289 4.29289C7.10536 4.48043 7 4.73478 7 5V7H5C4.73478 7 4.48043 7.10536 4.29289 7.29289C4.10536 7.48043 4 7.73478 4 8C4 8.26522 4.10536 8.51957 4.29289 8.70711C4.48043 8.89464 4.73478 9 5 9H7V11C7 11.2652 7.10536 11.5196 7.29289 11.7071C7.48043 11.8946 7.73478 12 8 12C8.26522 12 8.51957 11.8946 8.70711 11.7071C8.89464 11.5196 9 11.2652 9 11V9H11C11.2652 9 11.5196 8.89464 11.7071 8.70711C11.8946 8.51957 12 8.26522 12 8C12 7.73478 11.8946 7.48043 11.7071 7.29289C11.5196 7.10536 11.2652 7 11 7H9V5Z"/>
                    </svg>
                </div>
                <Dropdown
                    v-if="logical"
                    :disabled="logical === null && active"
                    :options="operatorOptions" :selected="secondOperator"
                    v-model="secondOperator"
                    :dark-mode="darkMode"
                    :maxHeight="'max-h-[8rem]'"
                    class="col-span-2"
                ></Dropdown>
                <div v-if="logical" class="col-span-2">
                    <slot name="second-input" v-if="$slots['second-input']"></slot>
                    <CustomInput
                        :dark-mode="darkMode"
                        v-else
                        :disabled="logical === null && filters.companyContacts.active"
                        type="number"
                        v-model:modelValue="secondValue"
                        @update:modelValue="secondValue = validateMinimumInteger(secondValue)"></CustomInput>
                </div>
                <slot name="condition"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import ToggleSwitch from "../ToggleSwitch.vue";
import Dropdown from "../Dropdown.vue";
import CustomInput from "../CustomInput.vue";

/**
 * @typedef CompanyServicingAreaFilterJsDocType
 * @property {string} name The name of the filter being used.
 * @property {boolean} active Whether or not the filter is being sued.
 * @property {number} firstValue The first value to be filtering against.
 * @property {OperatorJSDocType} firstOperator The operator used against the value.
 * @property {?"and"|"or"} logical A string representation of logical operators like && (and) and || (or).
 * @property {?number} secondValue
 * @property {?"greaterThan"|"lessThan"|"greaterThanOrEqualTo"|"lessThanOrEqualTo"|"equalTo"} secondOperator
 */

export default {
    emits: ["update:filter"],
    name: "FilterRow",
    components: {CustomInput, Dropdown, ToggleSwitch},
    props: {
        darkMode: {type: Boolean, required: true},
        filterName: {type: String, required: true},
        filter: {type: Object, required: false},
        disabled: {type: Boolean, default: false}
    },
    created() {
        this.active = this.filter.active;
        this.firstOperator = this.filter.firstOperator;
        this.secondOperator = this.filter.secondOperator;
        this.firstValue = this.filter.firstValue;
        this.secondValue = this.filter.secondValue;
        this.logical = this.filter.logical;
    },
    watch: {
        active(to, from) {
            let filter = this.filter;

            filter.active = to;

            this.updateFilter(filter);
        },
        firstOperator(to, from) {
            let filter = this.filter;

            filter.firstOperator = to;

            this.updateFilter(filter);
        },
        secondOperator(to, from) {
            let filter = this.filter;

            filter.secondOperator = to;

            this.updateFilter(filter);
        },
        firstValue(to, from) {
            let filter = this.filter;

            filter.firstValue = to;

            this.updateFilter(filter);
        },
        secondValue(to, from) {
            let filter = this.filter;

            filter.secondValue = to;

            this.updateFilter(filter);
        },
        logical(to, from) {
            let filter = this.filter;

            filter.logical = to;

            this.updateFilter(filter);
        },
    },
    methods: {
        toggleOperator() {
            if (this.logical === 'and') {
                this.logical = 'or';
            } else if (this.logical === 'or') {
                this.logical = null;
            } else {
                this.logical = 'and';
            }
        },
        updateFilter(filter) {
            this.$emit('update:filter', filter);
        },
        validateMinimumInteger(value) {
            if ((value < this.limitMin) || (value.includes('-'))) {
                value = this.limitMin;
            }

            return value;
        }
    },
    data() {
        return {
            active: false,
            firstOperator: "equalTo",
            secondOperator: null,
            firstValue: 0,
            secondValue: null,
            logical: null,

            // Structure and naming of data endpoints to be discussed.
            operatorOptions: [
                {id: 'greaterThan', name: 'Greater Than'},
                {id: 'lessThan', name: 'Less Than'},
                {id: 'greaterThanOrEqualTo', name: 'Greater Than Or Equal To'},
                {id: 'lessThanOrEqualTo', name: 'Less Than Or Equal To'},
                {id: 'equalTo', name: 'Equal To'},
            ],
            ratingsOptions: [
                0,
                1,
                2,
                3,
                4,
                5,
            ],
            logicalOptions: [
                'and',
                'or',
            ],
            limitMin: 0,
        }
    },
}
</script>
