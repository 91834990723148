import axios from 'axios';
import {BaseApiService} from "./base";

class ApiService extends BaseApiService {
    constructor(baseUrl, baseEndpoint, version) {
        super("ApiService");

        this.baseEndpoint = baseEndpoint;
        this.baseUrl = baseUrl;
        this.version = version;
    }

    axios() {
        const axiosConfig = {
            baseURL: `/${this.baseUrl}/v${this.version}/${this.baseEndpoint}`
        }

        return axios.create(axiosConfig);
    }

    getRecentNotifications() {
        return this.axios().get('/notifications');
    }

    markNotificationAsRead(id, type) {
        return this.axios().patch(`/notifications/${id}`, {type});
    }

    getBouncedLeads() {
        return this.axios().get('/management/bounced-leads');
    }

    getQueues() {
        return this.axios().get('/management/queues');
    }

    getManagementQueue(id) {
        return this.axios().get(`/management/queues/${id}`);
    }

    createQueue(name, status) {
        return this.axios().post('/management/queues', {name, status});
    }

    updateQueue(id, name, status) {
        return this.axios().put(`/management/queues/${id}`, {name, status});
    }

    deleteQueue(id) {
        return this.axios().delete(`/management/queues/${id}`);
    }

    getTeams() {
        return this.axios().get('/management/teams');
    }

    getTeam(id) {
        return this.axios().get(`/management/teams/${id}`);
    }

    createTeam(name, queueId, offset, industry) {
        return this.axios().post('/management/teams', {name, queue_id: queueId, offset, industry});
    }

    updateTeam(id, name, queueId, offset, industry) {
        return this.axios().put(`/management/teams/${id}`, {name, queue_id: queueId, offset, industry});
    }

    deleteTeam(id) {
        return this.axios().delete(`/management/teams/${id}`);
    }

    getProcessors() {
        return this.axios().get('/management/processors');
    }

    getProcessor(id) {
        return this.axios().get(`/management/processors/${id}`);
    }

    createProcessor(userId, teamId, phoneId) {
        return this.axios().post(`/management/processors`, {user_id: userId, team_id: teamId, phone_id: phoneId});
    }

    updateProcessor(id, userId, teamId, phoneId) {
        return this.axios().put(`/management/processors/${id}`, {user_id: userId, team_id: teamId, phone_id: phoneId});
    }

    deleteProcessor(id) {
        return this.axios().delete(`/management/processors/${id}`);
    }

    searchUserNames(name) {
        return this.axios().get(`/management/admins/search`, {
            params: {
                name: name
            }
        });
    }

    getNextLead(previousLeadId, specificLeadId) {
        return this.axios().get('/leads/next-lead', {
            params: {
                previous_lead_id: previousLeadId,
                specific_lead_id: specificLeadId
            }
        });
    }

    cancelLead(id, reason, publicComment) {
        return this.axios().post(`/leads/cancel/${id}`, {reason, public_comment: publicComment});
    }

    markLeadAsPendingReview(id, reason) {
        return this.axios().post(`/leads/mark-as-pending-review/${id}`, {reason});
    }

    markLeadAsUnderReview(id, reason, publicComment) {
        return this.axios().post(`/leads/mark-as-under-review/${id}`, {reason, public_comment: publicComment});
    }

    approveLead(id, reason, bestTimeToContact, publicComment) {
        return this.axios().post(`/leads/approve/${id}`, {reason: reason, best_time_to_contact: bestTimeToContact, public_comment: publicComment});
    }

    updateBasicInfo(id, data) {
        return this.axios().patch(`/leads/update-basic-info/${id}`, {data: data});
    }

    updateStatusReason(id, data) {
        return this.axios().patch(`/leads/update-status-reason/${id}`, {data: data});
    }

    heartbeat(id) {
        return this.axios().post(`/leads/heartbeat/${id}`);
    }

    getQueue() {
        return this.axios().get('/leads/queue');
    }

    getStatistics() {
        return this.axios().get('/management/statistics');
    }

    getIndustries() {
        return this.axios().get('/management/industries');
    }

    lookupLead(number) {
        return this.axios().get('/communication/details', {params: {other_number: number}});
    }

    getSMSHistory(phoneNumber) {
        return this.axios().get(`/communication/history/sms/${phoneNumber}`);
    }

    // createOutboundCall(serviceName, userPhoneNumber, otherNumber, reference) {
    //     console.log('__API__');
    //     return this.axios().post(`/communication/outbound/call/`, {
    //         service_name: serviceName,
    //         ring_central_phone_number: userPhoneNumber,
    //         other_number: otherNumber,
    //         reference: reference
    //     });
    // }

    // updateOutboundCall(communicationId, callComplete, result) {
    //     return this.axios().patch(`/communication/outbound/call`, {
    //         lead_processing_communication_id: communicationId,
    //         call_complete: callComplete,
    //         call_result: result
    //     });
    // }

    updateInboundCall(communicationId, callComplete) {
        return this.axios().patch(`/communication/inbound/call`, {
            lead_processing_communication_id: communicationId,
            call_complete: callComplete
        });
    }

    // createOutboundSMS(leadId, body) {
    //     return this.axios().post(`/leads/send-sms/${leadId}`, {body})
    // }

    getCommunicationToken() {
        return this.axios().post(`/communication/token`);
    }

    getProcessingHistory() {
        return this.axios().get('/leads/processing-history');
    }

    getGlobalConfigs() {
        return this.axios().get('/management/global-configs');
    }

    saveGlobalConfigs(
        timeZoneOpeningDelay,
        recencyThreshold,
        minimumReviewTime,
        leadProcessableDelay,
        checkNextLeadInterval,
        timeZoneConfigurations,
        callingTimeZoneConfigurations,
        lastLeadCreatedInterval
    )
    {
        return this.axios().post('/management/global-configs', {
            time_zone_opening_delay_in_minutes: timeZoneOpeningDelay,
            recency_threshold_in_seconds: recencyThreshold,
            minimum_review_time: minimumReviewTime,
            lead_processable_delay_seconds: leadProcessableDelay,
            check_next_lead_interval_seconds: checkNextLeadInterval,
            lead_processing_time_zone_configurations: timeZoneConfigurations,
            lead_processing_calling_time_zone_configurations: callingTimeZoneConfigurations,
            last_lead_created_interval_min: lastLeadCreatedInterval
        });
    }

    getNextProduct(previousProductId, specificProductId) {
        return this.axios().get('/products/next-product', {
            params: {
                previous_product_id: previousProductId,
                specific_product_id: specificProductId
            }
        });
    }

    getTimeConfigurations() {
        return this.axios().get('/leads/time-configurations');
    }

    getLeadBasicInfo(leadId) {
        return this.axios().get(`/leads/${leadId}/basic`);
    }

    getLeadRelatedActivity(leadId) {
        return this.axios().get(`/leads/${leadId}/related-activity`);
    }

    getLeadVerification(leadId, service) {
        return this.axios().get(`leads/${leadId}/${service}/verification`);
    }
}

export { ApiService };

