import {defineStore} from "pinia";
import {reactive} from "vue";

export const useContactsStore = defineStore('contacts',
    () => {
        const contacts = reactive([]);

        const contact = reactive({
            first_name: undefined,
            last_name: undefined,
            title: undefined,
            email: undefined,
            office_phone: undefined,
            cell_phone: undefined,
            total_calls_count: undefined,
            total_calls_over_one_minute_count: undefined,
            last_called_timestamp: undefined,
        });

        return {
            contacts,
            contact,
        }
    },
    {
        persist: false,
    }
)

