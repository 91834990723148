<template>
    <div class="main-layout font-body">
        <div class="w-full">
            <div class="w-full flex-auto pt-3 relative" :class="{'bg-light-background': !darkMode, 'bg-dark-background': darkMode}">
                <div class="px-10" :class="[darkMode ? 'text-white' : 'text-slate-900']">
                    <div class="flex items-center justify-between flex-wrap py-4">
                        <div class="flex justify-between items-center w-full py-2">
                            <h3 class="text-xl font-medium pb-0 leading-none mr-5">Admin Management</h3>
                        </div>
                    </div>
                    <div>
                        <div class="grid md:grid-cols-1 lg:grid-cols-3 gap-4 mb-4">
                            <div class="border rounded-lg"
                                 :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
                                <div class="p-5">
                                    <div class="flex items-center justify-between pb-3">
                                        <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Sales Bait Overview</h5>
                                        <div class="text-sm rounded-full flex items-center"
                                             :class="{'text-grey-400': !darkMode, 'text-blue-400': darkMode}">
                                            <a href="/admin/sales-bait-management" class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5">Manage Salesbait</a>
                                        </div>
                                    </div>
                                    <div v-if="!isLoading && !statistics.salesbaits" class="text-sm py-4">
                                        <p>No Salesbait records for the previous 7 days</p>
                                    </div>
                                    <div v-if="isLoading" class="flex items-center justify-center h-full">
                                        <loading-spinner></loading-spinner>
                                    </div>
                                    <metrics-chart
                                        :module-title="metricsChartTile.main"
                                        :dark-mode="darkMode"
                                        v-if="statistics.salesbaits"
                                        :metrics-A-title="metricsChartTile.metrics.A"
                                        :metrics-B-title="metricsChartTile.metrics.B"
                                        :metrics-A-value="statistics.salesbaits"
                                        :metrics-B-value="statistics.clicks"
                                    />
                                </div>
                            </div>
                            <div class="border rounded-lg"
                                 :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
                                <div class="p-5">
                                    <div class="flex items-center justify-between pb-3">
                                        <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">LEAD
                                            ISSUES REPORT</h5>
                                        <div class="text-sm rounded-full flex items-center"
                                             :class="{'text-grey-400': !darkMode, 'text-blue-400': darkMode}">
                                            <a href="/admin/reports/lead-issue"
                                               class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5">View
                                                Report</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MetricsChart from "../Shared/components/MetricsChart";
import SharedApiService from "../Shared/services/api";
import dayjs from 'dayjs';
import LoadingSpinner from "../Shared/components/LoadingSpinner";

export default {
    name: "AdminDashboard.vue",
    components: {LoadingSpinner, MetricsChart},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            sharedApiService: null,
            isLoading: true,
            statistics: {
                salesbaits: null,
                clicks: null
            },
            metricsChartTile: {
                main: 'Salesbait over the last 7 days',
                metrics: {
                    A: 'Sales Baits',
                    B: 'Clicks'
                }
            }
        }
    },
    created() {
        this.sharedApiService = SharedApiService.make();
        this.getStatistics();
    },
    methods: {
        getStatistics() {
            const today = dayjs().format('YYYY-MM-DD');
            const sevenDaysAgo = dayjs().subtract(7, 'month').format('YYYY-MM-DD');
            // @TODO: cleanup this query string to use axios params
            this.sharedApiService.getStatistics('?'
                + 'start_date='    + sevenDaysAgo
                + '&end_date='     + today).then(resp => {
                this.statistics = resp.data.data ?? [];
                this.isLoading = false;
            }).catch(e => console.log(e));
        }
    }
}
</script>

<style scoped>

</style>
