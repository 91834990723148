<template>
    <div v-if="this.showPagination"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}"
         class="px-4 flex items-center justify-between sm:px-6">
        <div class="flex-1 flex justify-between sm:hidden">
            <div v-if="this.showPreviousButton" @click="handleChangePage(this.paginationData.prev_page_url)"
                 class="relative inline-flex items-center px-4 py-2 border-y border-gray-300 text-sm font-medium rounded-lg text-gray-700 bg-light-module hover:bg-gray-50">
                Previous
            </div>
            <div v-if="this.showNextButton" @click="handleChangePage(this.paginationData.next_page_url)"
                 class="ml-3 relative inline-flex items-center px-4 py-2 border-y border-gray-300 text-sm font-medium rounded-lg text-gray-700 bg-light-module hover:bg-gray-50">
                Next
            </div>
        </div>
        <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
                <p v-if="showTotalRecordsDetail" class="text-sm" :class="{'text-grey-800': !darkMode, 'text-grey-200': darkMode}">
                    Showing
                    <span class="font-medium">{{ paginationData.from }}</span>
                    to
                    <span class="font-medium">{{ paginationData.to }}</span>
                    of
                    <span class="font-medium">{{ paginationData.total }}</span>
                    results
                </p>
                <p v-else class="text-sm" :class="{'text-grey-800': !darkMode, 'text-grey-200': darkMode}">
                    Total: <span class="font-medium">{{ paginationData.total }}</span>
                </p>
            </div>
            <div v-if="showResultsPerPage" class="ml-auto sm:flex items-center">
                <span class="text-sm text-slate-500 whitespace-nowrap">Show Rows:</span> <Dropdown class="p-2" v-model="perPage" v-on:update:modelValue="handleChangePage(this.paginationData.path + '?page=' + this.paginationData.current_page)" :options="perPageOptions" :dark-mode="darkMode" :selected="paginationData.per_page"/>
            </div>
            <div>
                <nav class="relative z-0 cursor-pointer inline-flex rounded-lg divide-x" :class="[darkMode ? 'divide-dark-border' : 'divide-light-border']" aria-label="Pagination">
                    <div v-for="(link, index) in paginationData.links"
                         @click="link.url !== null ? handleChangePage(link.url) : null"
                         class="relative inline-flex items-center px-4 py-2 text-sm font-medium"
                         :class="[link.active ?? 'z-10 text-primary-500' , link.url !== null ? (darkMode ? 'hover:bg-primary-800' : 'hover:bg-primary-100') : '',darkMode ? 'bg-dark-module text-grey-200' : 'bg-light-background text-slate-800']">
                        <p v-html="link.label"
                           class="pb-0 elect-none"
                           :class="{
                                'text-primary-500': link.active,
                                'text-slate-400': link.url === null
                            }"></p>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import Dropdown from "./Dropdown";
export default {
    components: {Dropdown},
    props: {
        paginationData: {
            type: Object,
            default: {
                current_page: {
                    type: Number,
                    default: null
                },
                from: {
                    type: Number,
                    default: null
                },
                last_page: {
                    type: Number,
                    default: null
                },
                last_page_url: {
                    type: String,
                    default: null
                },
                links: {
                    url: {
                        type: String,
                        default: null
                    },
                    label: {
                        type: String,
                        default: null
                    },
                    active: {
                        type: Boolean,
                        default: null
                    }
                },
                next_page_url: {
                    type: String,
                    default: null
                },
                prev_page_url: {
                    type: String,
                    default: null
                },
                per_page: {
                    type: Number,
                    default: null
                },
                to: {
                    type: Number,
                    default: null
                },
                total: {
                    type: Number,
                    default: null
                }
            }
        },
        showPagination: {
            type: Boolean,
            default: true
        },
        showResultsPerPage: {
            type: Boolean,
            default: false
        },
        darkMode: {
            type: Boolean,
            default: false,
        },
        showTotalRecordsDetail: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            perPageOptions: [
                { id: 5, name: 5 },
                { id: 10, name: 10 },
                { id: 25, name: 25 },
                { id: 50, name: 50 },
                { id: 100, name: 100 },
                { id: this.paginationData.total, name: 'All'}
            ],
            perPage: this.paginationData.per_page,
        }
    },
    computed: {
        showPreviousButton() {
            return this.paginationData.current_page !== 1;
        },
        showNextButton() {
            return this.paginationData.current_page !== this.paginationData.last_page;
        }
    },
    methods: {
        handleChangePage: function(linkUrl) {
            if(!linkUrl) {
                return;
            }

            linkUrl += '&perPage=' + this.perPage

            const newPage = Number( (new URLSearchParams(linkUrl.split('?')[1])).get('page') );

            this.$emit('change-page', {
                link: linkUrl,
                perPage: this.perPage,
                newPage: newPage
            });
        },
    }
};
</script>

<style scoped>

</style>
