<template>
    <div class="relative">
        <select class="w-full p-2 rounded hidden d-none" v-model="selectedOptionIds" multiple="multiple">
            <option v-for="option in options" :value="option.id">
                {{option.name}}
            </option>
        </select>

        <div>
            <button type="button"
                    :class="{'hover:bg-grey-120 border-grey-200 text-grey-400 bg-grey-50': !darkMode, 'hover:border-blue-400 border-blue-700 text-blue-400 bg-dark-background': darkMode}"
                    class="z-30 truncate cursor-pointer uppercase text-xs font-semibold transition duration-100 w-full border flex justify-between items-center rounded py-2 px-4 h-9"
            aria-haspopup="true"
                    aria-expanded="false"
                    @click="toggleMultiselect">
                <span class="truncate">{{ selectedOptionByName.join(', ') || textPlaceHolder }}</span>
                <svg class="fill-current flex-shrink-0" v-if="classes.length > 0" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.7071 0.292893C12.0976 0.683418 12.0976 1.31658 11.7071 1.70711L6.70711 6.70711C6.31658 7.09763 5.68342 7.09763 5.29289 6.70711L0.292893 1.70711C-0.0976315 1.31658 -0.0976315 0.683417 0.292893 0.292893C0.683417 -0.0976316 1.31658 -0.0976316 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976312 11.3166 -0.0976311 11.7071 0.292893Z" />
                </svg>
            </button>

            <div v-if="showMultiselect"
                 class="absolute left-0 max-h-56 divide-y divide-inherit z-50 overflow-y-auto mt-10 rounded top-0 w-full border shadow-module"
                 :class="{'border-grey-200 bg-light-module': !darkMode, 'border-blue-800 bg-dark-background': darkMode}">
                <div v-if="showSearchBox">
                    <input type="text" class="rounded border-grey-200 p-2 w-full m-1" v-model.trim="textFilter">
                </div>
                <p
                    v-for="option in optionList"
                    :class="[!option.show ? 'hidden d-none' :  '', !darkMode ? 'hover:bg-grey-120' : 'hover:bg-dark-175']"
                    class="py-3 px-4 uppercase text-xs font-semibold transition duration-200"
                    @click.prevent.stop="handleSelect(option)">
                    <span>{{option.name}}</span>
                    <span v-show="option.selected" class="float-right">
                        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.27411 6.5197L1.62626 3.69335L0 5.43719L4.27642 10L12 1.74138L10.3714 0L4.27411 6.5197Z" fill="#0081FF"/>
                        </svg>
                    </span>
                </p>
            </div>
        </div>

        <div v-if="showMultiselect" @click="toggleMultiselect" class="fixed inset-0 z-10"></div>
    </div>
</template>

<script>
export default {
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Object,
            required: true
        },
        selectedIds: {
            type: Array,
            default: function() {
                return [];
            }
        },
        textPlaceHolder: {
            type: String,
            default: 'Select Options'
        },
        showSearchBox: {
            type: Boolean,
            default: true
        },
        classes: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            optionList: [],
            textFilter: '',
            showMultiselect: false
        };
    },
    methods: {
        handleSelect(option) {
            const optPos = this.selectedOptionIds.indexOf(option.id);

            if (optPos >= 0) {
                this.selectedOptionIds.splice(optPos, 1);
                option.selected = false;
            }
            else {
                option.selected = true;
                this.selectedOptionIds.push(option.id);
            }

            this.$emit('input', this.selectedOptionIds);
        },
        toggleMultiselect() {
            this.showMultiselect = !this.showMultiselect;
        },
        checkMarkerStatus() {
            if(this.selectedIds.length > 0) {
                return;
            }

            if(this.showMultiselect) {
                this.showMultiselect = false;
            }

            this.resetMarker();
        },
        resetMarker() {
            this.optionList.forEach((opt) => {
                opt.selected = false;
            });
        }
    },
    created: function() {
        if (this.options && this.options.length) {
            this.optionList = this.options;
            this.optionList.forEach((opt) => {
                opt.selected = this.selectedOptionIds.indexOf(opt.id) >= 0;

                opt['show'] = true;
            })
        }
    },
    computed: {
        selectedOptionByName() {
            const selectedOptionsByName = this.optionList.filter((opt) => {
                return this.selectedOptionIds.indexOf(opt.id) >= 0;
            });
            return selectedOptionsByName.map((opt) => opt.name);
        },
        selectedOptionIds: {
            get() {
                return this.selectedIds;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        hasSelection() {
            return this.selectedIds.length && this.selectedIds.length > 0;
        }
    },
    watch: {
        textFilter(value) {
            if (value && value.length) {
                this.optionList.forEach((opt) => {
                    opt['show'] = opt.name.toLowerCase().startsWith(value.toLowerCase());
                });
            } else {
                this.optionList.forEach((opt) => {
                    opt['show'] = true;
                });
            }
        },
        selectedIds() {
            this.checkMarkerStatus();
        }
    }
}
</script>

<style>

</style>
