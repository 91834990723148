<template>
    <div class="border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="p-5 flex items-center justify-between">
            <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Filter Presets</h5>
        </div>
        <div class="grid grid-cols-3 gap-x-3 mb-2 px-5">
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs">Component</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs">Preset</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs text-center">Configure</p>
        </div>
        <div class="border-t border-b h-100 overflow-y-auto"
             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
            <div>
                <div v-for="component in filterComponents" :key="component" class="grid grid-cols-3 gap-x-3 border-b px-5 py-3 items-center" :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <p class="font-medium text-sm">{{ component.name }}</p>
                    <p class="font-medium text-sm capitalize">{{ component.userPreset }}</p>
                    <div class="flex justify-center">
                        <svg @click="toggleFilterModal(component)" class="cursor-pointer" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.0956937 9.2897L1.52568 11.7117C1.62062 11.8724 1.77684 11.9895 1.95999 12.0374C2.14314 12.0853 2.33823 12.0601 2.50236 11.9672L3.50049 11.403C3.91519 11.7229 4.3735 11.9854 4.8554 12.1814V13.3C4.8554 13.4857 4.93073 13.6637 5.06482 13.795C5.19891 13.9263 5.38077 14 5.5704 14H8.43037C8.62 14 8.80186 13.9263 8.93595 13.795C9.07003 13.6637 9.14536 13.4857 9.14536 13.3V12.1814C9.63104 11.9834 10.0869 11.7216 10.5003 11.403L11.4984 11.9672C11.8395 12.1597 12.2785 12.0442 12.4751 11.7117L13.9051 9.2897C13.9992 9.12884 14.0245 8.93809 13.9757 8.759C13.9268 8.5799 13.8076 8.42697 13.6441 8.3335L12.6631 7.7784C12.7398 7.26215 12.7393 6.73772 12.6617 6.2216L13.6427 5.6665C13.983 5.474 14.101 5.0435 13.9036 4.7103L12.4737 2.2883C12.3787 2.12765 12.2225 2.01048 12.0393 1.96257C11.8562 1.91466 11.6611 1.93992 11.497 2.0328L10.4988 2.597C10.086 2.27806 9.63031 2.01615 9.14465 1.8186V0.7C9.14465 0.514348 9.06932 0.336301 8.93523 0.205025C8.80114 0.0737498 8.61928 0 8.42965 0H5.56968C5.38005 0 5.19819 0.0737498 5.0641 0.205025C4.93002 0.336301 4.85469 0.514348 4.85469 0.7V1.8186C4.36901 2.01657 3.91318 2.27845 3.49978 2.597L2.50236 2.0328C2.42111 1.98672 2.33138 1.95677 2.23831 1.94467C2.14523 1.93257 2.05062 1.93856 1.95991 1.96229C1.8692 1.98602 1.78415 2.02703 1.70964 2.08297C1.63513 2.13891 1.57262 2.20868 1.52568 2.2883L0.0956937 4.7103C0.00159194 4.87116 -0.0237821 5.06191 0.0250973 5.241C0.0739766 5.4201 0.19315 5.57303 0.356666 5.6665L1.33764 6.2216C1.26051 6.73779 1.26051 7.26221 1.33764 7.7784L0.356666 8.3335C0.0163295 8.526 -0.101644 8.9565 0.0956937 9.2897ZM6.99967 4.2C8.57694 4.2 9.85964 5.4558 9.85964 7C9.85964 8.5442 8.57694 9.8 6.99967 9.8C5.42239 9.8 4.13969 8.5442 4.13969 7C4.13969 5.4558 5.42239 4.2 6.99967 4.2Z" fill="#0081FF"/>
                        </svg>
                    </div>
                    <FilterConfigModal :dark-mode="darkMode" :filter-component="currentEditConfig" v-if="currentEditConfig !== null" @close="currentEditConfig = null">

                    </FilterConfigModal>
                </div>
            </div>
        </div>
        <div class="p-3"></div>
    </div>
</template>

<script>
import ActionsHandle from "../LeadProcessing/components/ActionsHandle";
import FilterConfigModal from "../Shared/components/FilterConfigModal";
export default {
    name: "FilterPresetConfig",
    components: {FilterConfigModal, ActionsHandle},
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            currentEditConfig: null,
            filterComponents: {
                "companyServicingAreaFilters" : {
                    "id" : "CompanyServicingAreaFilters",
                    "name" : "Company Servicing Area",
                    "userPreset" : "default",
                    "userPresetList": [
                        {"id" : "default", "name" : "Default"},
                        {"id" : "test", "name" : "Test"},
                    ]
                },
            },
            initialPreset: null,
        }
    },
    methods: {
        toggleFilterModal(component) {
            this.currentEditConfig = component;
        }
    }
}
</script>

<style scoped>

</style>
