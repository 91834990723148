export function waitForCallbackToReturnTrue(callback, checkDelay = 100, checkLimit = 10) {
    return new Promise((resolve, reject) => {
        let tries = 0;
        const check = () => {
            if(callback())
                return resolve();

            if(tries++ > checkLimit)
                return reject();

            setTimeout(check, checkDelay);
        }

        setTimeout(check, checkDelay);
    });
}
