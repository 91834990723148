import {logicalOptions, operatorOptions} from "./options";
import {testTwoOperators} from "./company-servicing-area-filter/helpers";
import _ from "lodash";

const filterKeys = ['companyContacts', 'estimatedRevenue', 'employeeCount', 'googleReviews', 'googleRating'];

export const companyContactConditionOptions = ["withPhone", "withEmail", "withBoth", "withNeither"];

/**
 *
 * @param {string} condition
 * @param {CompanyJsDocType} company
 * @return {boolean}
 * @throws TypeError
 * @return boolean
 */
export const evaluateCompanyContactsFilterCondition = (condition, company) => {
    hasProperty(company, 'contacts_with_phone_count');
    hasProperty(company, 'contacts_with_email_count');

    switch (condition) {
        case 'withPhone':
            return _.isFinite(company.contacts_with_phone_count) && company.contacts_with_phone_count > 0;
        case 'withEmail':
            return _.isFinite(company.contacts_with_email_count) && company.contacts_with_email_count > 0;
        case 'withBoth':
            return company.contacts_with_phone_count > 0 && company.contacts_with_email_count > 0 && _.isFinite(company.contacts_with_email_count) && _.isFinite(company.contacts_with_phone_count);
        case 'withNeither':
            return company.contacts_with_phone_count <= 0
                && company.contacts_with_email_count <= 0
                ||
                (!_.isFinite(company.contacts_with_email_count)
                    || !_.isFinite(company.contacts_with_phone_count)
                );
        default:
            throw new TypeError(`"condition" must be one of ${companyContactConditionOptions}. ${condition} passed.`);
    }
}

/**
 * Checks if value is a finite number.
 * @param value
 * @param {string} label
 * @throws TypeError
 * @return boolean
 */
const checkIfFiniteNumber = (value, label) => {
    if (!_.isFinite(value)) {
        throw new TypeError(`${label} must be a finite number. ${value} passed.`);
    }

    return true;
}


/**
 * Checks if object has the property.
 * @param {*} object
 * @param {string} key
 * @param {boolean} stop
 * @param {string|null} error
 * @throws TypeError
 * @return boolean
 */
const hasProperty = (object, key, stop = true, error = null) => {
    if (!object.hasOwnProperty(key)) {
        const message = error ?? `Object must have ${key}. ${JSON.stringify(object)} passed.`;

        if (stop) {
            throw new TypeError(message);
        } else {
            console.warn(message)
        }

        return false;
    }

    return true;
}

/**
 * Checks if property of object is an object.
 * @param object
 * @param property
 * @param stop
 * @param error
 * @throws TypeError
 * @return boolean
 */
const propertyIsObject = (object, property, stop = true, error) => {
    if (typeof object[property] != 'object') {

        const error = error ?? `${property} must be an object. ${object[property]} passed.`;

        if (stop) {
            throw new TypeError(error);
        } else {
            console.warn(error)
        }

        return false;
    }

    return true;
}

/**
 * @param value
 * @param {string} label
 * @throws TypeError
 */
const evaluateValue = (value, label) => {
    const isInteger = _.isInteger(value);

    if (!isInteger) {
        const isFiniteNumber = _.isFinite(value);

        if (isFiniteNumber) {
            return _.round(value);
        } else {
            const isNumericString = _.isString(value) && _.isFinite(Number(value));

            if (isNumericString) {
                return _.parseInt(value.replaceAll(',', ''));
            } else {
                throw new TypeError(`"${label}" must be a finite integer or finite numeric string. "${value}" passed.`);
            }
        }
    }

    return value;
}

/**
 * @param operator
 * @param {string} label
 * @throws TypeError
 * @throws Error
 */
const evaluateOperator = (operator, label) => {
    const result = operatorOptions.includes(operator);

    if (!result) {
        throw new TypeError(`"${label}" must be one of the following: ${JSON.stringify(operatorOptions)}. ${operator} passed.`);
    }
}

/**
 * Evaluate the filter.
 * @param {CompanyServicingAreaFilterJsDocType} filter
 * @param {"companyContacts"|"amountOfPurchasedLeads"|"estimatedRevenue"|"employeeCount"|"googleReviews"|"googleRating"} filterLabel
 * @throws TypeError
 */
export const evaluateFilterObject = (filter, filterLabel) => {
    if (!filterKeys.includes(filterLabel)) {
        throw new TypeError(`"filterLabel" must one of the following: ${JSON.stringify(filterKeys)}. ${filterLabel} passed.`)
    }

    let newFilter = _.cloneDeep(filter);

    hasProperty(newFilter, "active", false, `filter object, "${filterLabel}", is missing "active" property.`)

    const evaluateFirstValue = () => {
        hasProperty(newFilter, "firstValue", false, `${filterLabel} must have "firstValue".`)

        const firstValueIsNull = _.isNull(newFilter.firstValue);

        if (firstValueIsNull) {
            throw new TypeError(`"filter.firstValue" cannot be null.`);
        } else {
            try {
                newFilter.firstValue = evaluateValue(newFilter.firstValue, "firstValue");
            } catch (e) {
                throw new Error(`Issue with "firstValue" in "${filterLabel}". ${e}`);
            }
        }
    }

    try {
        evaluateFirstValue();
    } catch (e) {
        throw new Error(e);
    }

    const evaluateFirstOperator = () => {
        hasProperty(newFilter, "firstOperator", false, `${filterLabel} must have "firstOperator".`)

        const firstOperatorIsNull = _.isNull(newFilter.firstOperator);

        if (firstOperatorIsNull) {
            throw new TypeError(`"filter.firstOperator" cannot be null.`);
        } else {
            try {
                evaluateOperator(newFilter.firstOperator, "filter.firstOperator");
            } catch (e) {
                throw new Error(`Issue with "firstOperator" in "${filterLabel}". ${e}`);
            }
        }
    }


    try {
        evaluateFirstOperator();
    } catch (e) {
        throw new Error(e);
    }

    const logicalExists = hasProperty(newFilter, "logical", false, `"filter" object, ${filterLabel}, is missing "logical" property.`)
    hasProperty(newFilter, "secondValue", false, `filter object, "${filterLabel}", is missing "secondValue" property.`)
    hasProperty(newFilter, "secondOperator", false, `filter object, "${filterLabel}", is missing "secondOperator" property.`)

    if (logicalExists) {
        if (!logicalOptions.includes(newFilter.logical)) {
            if (!_.isNull(newFilter.logical)) {
                console.warn(`"${newFilter.logical}" was passed as "filter.logical" for "${filterLabel}". Accepted values are: 'and', 'or', and null. "filter.logical" will be treated as null for this case.`)
            }
        } else {
            try {
                newFilter.secondValue = evaluateValue(newFilter.secondValue, "secondValue");
            } catch (e) {
                throw new Error(`Issue with "secondValue" in "${filterLabel}". ${e}`);
            }

            try {
                evaluateOperator(newFilter.secondOperator, "filter.secondOperator");
            } catch (e) {
                throw new Error(`Issue with "secondOperator" in "${filterLabel}". ${e}`);
            }
        }
    }

    if (filterLabel === 'companyContacts') {
        hasProperty(newFilter, 'condition');

        const conditionIsValid = companyContactConditionOptions.includes(newFilter['condition'])

        if (!conditionIsValid) {
            throw new TypeError(`"filter.condition" must be one of the following: ${companyContactConditionOptions}. ${newFilter['condition']} passed.`);
        }
    }

    return newFilter;
}

/**
 * Evaluate each filter.
 * @param {CompanyServicingAreaFiltersJsDocType} filters
 * @throws Error
 */
export const evaluateFiltersObject = (filters) => {
    let newFilters = _.cloneDeep(filters);

    if (typeof newFilters != "object") {
        throw new TypeError(`"filters" must be an object. "${newFilters}" passed.`)
    }

    hasProperty(newFilters, 'companyContacts');
    hasProperty(newFilters, 'estimatedRevenue');
    hasProperty(newFilters, 'employeeCount');
    hasProperty(newFilters, 'googleReviews');
    hasProperty(newFilters, 'googleRating');

    propertyIsObject(newFilters, 'companyContacts');
    propertyIsObject(newFilters, 'estimatedRevenue');
    propertyIsObject(newFilters, 'employeeCount');
    propertyIsObject(newFilters, 'googleReviews');
    propertyIsObject(newFilters, 'googleRating');

    return newFilters;
}

/**
 * Applies filter for each company.
 * @param {CompanyJsDocType} company
 * @param {CompanyServicingAreaFiltersJsDocType} filters
 * @return boolean
 * @throws Error
 */
export const companyMatchesFilter = (company, filters) => {
    /**
     * @type CompanyServicingAreaFiltersJsDocType
     */
    let evaluatedFilters;

    try {
        evaluatedFilters = evaluateFiltersObject(filters);
    } catch (e) {
        throw new Error(e);
    }

    const evaluateCompanyContactsFilter = () => {
        /**
         * @type {CompanyContactsCompanyServicingAreaFilterJsDocType}
         */
        const companyContactsFilter = evaluatedFilters.companyContacts;

        if (companyContactsFilter?.active) {

            let operatorResult = false;

            try {
                evaluateFilterObject(filters.companyContacts, 'companyContacts');

                hasProperty(company, 'contacts_count');
                hasProperty(company, 'contacts_with_phone_count');
                hasProperty(company, 'contacts_with_email_count');

                if (!_.isNull(company.contacts_count) && !_.isNull(company.contacts_with_phone_count) && !_.isNull(company.contacts_with_email_count)) {
                    checkIfFiniteNumber(company.contacts_count, "company.contacts_count")
                    checkIfFiniteNumber(company.contacts_with_phone_count, "company.contacts_with_phone_count")
                    checkIfFiniteNumber(company.contacts_with_email_count, "company.contacts_with_email_count")

                    operatorResult = testTwoOperators(company.contacts_count, companyContactsFilter.firstOperator, companyContactsFilter.firstValue, companyContactsFilter.secondOperator, companyContactsFilter.secondValue, companyContactsFilter.logical);
                }
            } catch (e) {
                throw new Error(e);
            }

            try {
                const conditionResult = evaluateCompanyContactsFilterCondition(companyContactsFilter.condition, company)

                return operatorResult && conditionResult;
            } catch (e) {
                throw new Error(`Could not evaluate "condition" for company contacts. ${e}`)
            }
        }

        return true;
    }

    const evaluateEstimatedRevenueFilter = () => {
        const estimatedRevenueFilter = evaluatedFilters.estimatedRevenue;

        if (estimatedRevenueFilter?.active) {

            let operatorResult = false;

            try {
                evaluateFilterObject(filters.estimatedRevenue, 'estimatedRevenue');
                hasProperty(company, 'estimated_revenue');

                if (!_.isNull(company.estimated_revenue)) {
                    checkIfFiniteNumber(company.estimated_revenue, "company.estimated_revenue")

                    operatorResult = testTwoOperators(company.estimated_revenue, estimatedRevenueFilter.firstOperator, estimatedRevenueFilter.firstValue, estimatedRevenueFilter.secondOperator, estimatedRevenueFilter.secondValue, estimatedRevenueFilter.logical);
                }
            } catch (e) {
                throw new Error(e);
            }

            return operatorResult;
        }

        return true;
    }

    const evaluateEmployeeCountFilter = () => {
        const employeeCountFilter = evaluatedFilters.employeeCount;

        if (employeeCountFilter?.active) {
            let operatorResult = false;

            try {
                evaluateFilterObject(filters.employeeCount, 'employeeCount');

                hasProperty(company, 'employee_count');

                if (!_.isNull(company.employee_count)) {

                    checkIfFiniteNumber(company.employee_count, "company.employee_count")

                    operatorResult = testTwoOperators(company.employee_count, employeeCountFilter.firstOperator, employeeCountFilter.firstValue, employeeCountFilter.secondOperator, employeeCountFilter.secondValue, employeeCountFilter.logical);

                }
            } catch (e) {
                throw new Error(e);
            }

            return operatorResult;
        }

        return true;
    }

    const evaluateGoogleReviewCountFilter = () => {
        const googleReviewCountFilter = evaluatedFilters.googleReviews;

        if (googleReviewCountFilter?.active) {
            let operatorResult = false;

            try {
                evaluateFilterObject(filters.googleReviews, 'googleReviews');

                hasProperty(company, 'google_review_count');

                if (!_.isNull(company.google_review_count)) {

                    checkIfFiniteNumber(company.google_review_count, "company.google_review_count")

                    operatorResult = testTwoOperators(company.google_review_count, googleReviewCountFilter.firstOperator, googleReviewCountFilter.firstValue, googleReviewCountFilter.secondOperator, googleReviewCountFilter.secondValue, googleReviewCountFilter.logical);
                }
            } catch (e) {
                throw new Error(e);
            }

            return operatorResult;
        }

        return true;
    }

    const evaluateGoogleRatingFilter = () => {
        const googleRating = evaluatedFilters.googleRating;

        if (googleRating?.active) {
            let operatorResult = false;

            try {
                evaluateFilterObject(filters.googleRating, 'googleRating');

                hasProperty(company, 'google_rating');

                if (!_.isNull(company.google_rating)) {

                    /**
                     * @param rating
                     * @throws TypeError
                     */
                    const checkRating = (rating) => {
                        const ratingCheck = [1, 2, 3, 4, 5].includes(rating);

                        if (!ratingCheck) {
                            throw new TypeError(`Rating must be a number from 1 to 5. ${rating} passed.`);
                        }
                    }

                    checkRating(company.google_rating)

                    operatorResult = testTwoOperators(company.google_rating, googleRating.firstOperator, googleRating.firstValue, googleRating.secondOperator, googleRating.secondValue, googleRating.logical);
                }
            } catch (e) {
                throw new Error(e);
            }

            return operatorResult;
        }

        return true;
    }

    try {
        return evaluateCompanyContactsFilter() &&
            evaluateEstimatedRevenueFilter() &&
            evaluateEmployeeCountFilter() &&
            evaluateGoogleReviewCountFilter() &&
            evaluateGoogleRatingFilter()
    } catch (e) {
        throw new Error(e);
    }
}

/**
 * @version R3
 * @param {CompanyServicingAreaFiltersJsDocType} filters
 * @param {CompanyJsDocType[]} companies
 * @return {CompanyJsDocType[]} companies
 * @throws Error
 */
export function filterCompanies(filters, companies) {

    try {
        return companies.filter(x => companyMatchesFilter(x, filters));
    } catch (e) {
        throw new Error(e);
    }
}
