<template>
    <div class="absolute right-0 bottom-0 z-20">
        <div @click="toggleVoicemailList" :class="iconClasses" class="z-20 mr-10 relative border transition duration-200 shadow-module w-10 h-10 rounded-full inline-flex justify-center items-center cursor-pointer">
            <svg class="fill-current" width="20" height="9" viewBox="0 0 20 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.5 0C14.3069 0.00132343 13.1631 0.475854 12.3195 1.31948C11.4759 2.16311 11.0013 3.30693 11 4.5C11 5.425 11.281 6.284 11.762 7H8.239C8.719 6.284 9 5.425 9 4.5C9 2.019 6.981 0 4.5 0C2.019 0 0 2.019 0 4.5C0 6.981 2.019 9 4.5 9C4.671 9 4.834 8.968 5 8.949V9H16V8.949C18.244 8.697 20 6.81 20 4.5C20 2.019 17.981 0 15.5 0ZM2 4.5C2 3.121 3.121 2 4.5 2C5.879 2 7 3.121 7 4.5C7 5.879 5.879 7 4.5 7C3.121 7 2 5.879 2 4.5ZM15.5 7C14.121 7 13 5.879 13 4.5C13 3.121 14.121 2 15.5 2C16.879 2 18 3.121 18 4.5C18 5.879 16.879 7 15.5 7Z"/>
            </svg>
            <div v-if="unreadCount > 0" class="absolute right-0 top-0 mr-2 mt-2 h-3 w-3 rounded-full inline-flex justify-center items-center bg-red-500 text-white text-xs">
                {{ unreadCount }}
            </div>
        </div>
        <div v-if="voicemailList" class="z-10 absolute -right-1 -bottom-2 mr-9 w-100 h-100 shadow-module border rounded-t-md rounded-bl-md rounded-br-xl"
             :class="{'bg-dark-module border-primary-500' : darkMode ,'bg-light-module border-light-border' : !darkMode }">
            <div class="p-5">
                <h5 class="text-primary-500 text-sm uppercase font-semibold">Voicemails</h5>
            </div>
            <div class="border-t border-b overflow-y-auto"
                 :class="{'bg-dark-background border-dark-border' : darkMode ,'bg-light-background border-light-border' : !darkMode }"
                 style="height: 75%;">
                <div @click="openVoicemail(voicemail)" v-for="(voicemail, i) in voicemails" :key="i"
                     :class="{'hover:bg-dark-module border-dark-border text-grey-120' : darkMode ,'hover:bg-light-module border-light-border  text-grey-900' : !darkMode, 'hover:bg-dark-module border-dark-border text-grey-120' : darkMode && voicemail.read ,'hover:bg-light-module bg-grey-150 border-light-border  text-grey-900' : !darkMode && voicemail.read, 'bg-dark-175 hover:bg-blue-700 border-dark-40 text-grey-120': darkMode && !voicemail.read, 'hover:bg-light-module bg-grey-50 border-light-border  text-grey-900': !darkMode && !voicemail.read}"
                     class="items-center py-5 px-5 border-b transition duration-200 cursor-pointer flex justify-between relative"
                >
                    <div v-if="!voicemail.read" class="absolute left-0 bg-primary-500 h-2 w-2 rounded-full ml-2" style="top: 1.65rem;"></div>
                    <div>
                        <p class="text-sm font-medium truncate">
                            {{ voicemail.from_name }}
                        </p>
                        <p class="text-xs capitalize">
                            {{ voicemail.from_type }} ({{ voicemail.from_id }}) - {{ voicemail.duration }}s
                        </p>
                        <p class="text-sm text-center" :class="{'text-blue-400' : darkMode ,'text-grey-400' : !darkMode }">
                            {{ $filters.dateFromTimestamp(voicemail.created_at) }}
                        </p>
                    </div>
                    <div class="flex items-center justify-end">
                        <div class="flex items-center justify-between">
                            <div class="flex items-center">
                                <svg class="fill-current text-blue-550 mr-5" @click.stop="play(voicemail)" width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0V12L10 6L0 0Z"/>
                                </svg>
                                <svg class="fill-current text-blue-400" @click.stop="stop()" width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H3.75V12H0V0ZM6.25 0H10V12H6.25V0Z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import DispatchesGlobalEventsMixin from "../../../mixins/dispatches-global-events-mixin";
import {waitForCallbackToReturnTrue} from "../../../../utilities/loading-utilities";

export default {
    name: "VoicemailWrapper",
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        voicemailList: {
            type: Boolean,
            default: false,
        },
        voicemails: {
            type: Array,
            default: null
        }
    },
    data: function () {
        return {
            audio: null,
            rcService: null,
        }
    },
    mixins: [DispatchesGlobalEventsMixin],
    methods: {
        toggleVoicemailList() {
            this.$emit('toggle-voicemail-list')
        },
        stopIfPlaying() {
            if(this.audio !== null) {
                this.audio.pause();
                this.audio = null;
            }
        },

        async play(voicemail) {
            voicemail.read = true;
            this.$emit('voicemailPlayed', voicemail.id);
            const recording = voicemail.voicemail_link;
            this.stopIfPlaying();

            if(recording) {
                this.audio = new Audio(recording);
                this.audio.play();
            }
        },

        stop() {
            this.stopIfPlaying();
        },

        openVoicemail(voicemail) {
            switch(voicemail.from_type) {
                case 'lead':
                    window.location.href = `/lead-processing?lead_id=${voicemail.from_id}`;
                    break;

                case 'company':
                    window.open(`https://admin.solarreviews.com/company.php?getcompanyid=${voicemail.from_id}`, "_blank")
                    break;
            }
        }
    },
    computed: {
        iconClasses() {
            const classes = [];

            if(this.voicemailList) {
                if(this.darkMode) {
                    classes.push('bg-dark-background', 'text-blue-550', 'border-primary-500', 'hover:border-blue-400', 'hover:bg-dark-175');
                } else {
                    classes.push('bg-primary-500', 'text-white', 'hover:bg-blue-500', 'border-transparent');
                }
            } else {
                if(this.darkMode) {
                    classes.push('bg-dark-background', 'text-blue-550', 'border-primary-500', 'hover:border-blue-400', 'hover:bg-dark-background');
                } else {
                    classes.push('bg-light-module', 'text-blue-550', 'border-light-border', 'hover:border-primary-500', 'hover:bg-cyan-125');
                }
            }

            return classes;
        },
        unreadCount() {
            if(this.voicemails.length === 0)
                return 0;

            return this.voicemails.reduce((prev, cur) => prev + (cur.read ? 0 : 1), 0);
        }
    },
    mounted() {
        this.dispatchGlobalEvent('request-service');
        this.listenForGlobalEvent('service', ({service}) => this.rcService = service);
    }
}
</script>

<style lang="scss" scoped>


</style>
