<template id="modal-template">
    <div class="absolute">
        <transition name="modal">
            <div class="fixed inset-0 flex items-center justify-center bg-opacity-75 bg-dark-background z-50">
                <div class="absolute shadow rounded-lg"
                     :class="{
                        'bg-light-module': !darkMode,
                        'bg-dark-module border border-dark-border': darkMode,
                        'max-w-screen-lg' : restrictWidth && !small,
                        'max-w-screen-md': restrictWidth && small,
                        'w-3/4': !restrictWidth && !small,
                        'w-full': fullWidth,
                     }">
                    <div class="border-b"
                         :class="{'border-light-border': !darkMode, 'border-dark-border': darkMode}">
                        <div class="px-6 py-5 flex justify-between items-center">
                            <div class="" :class="{'text-black': !darkMode, 'text-grey-120': darkMode}">
                                <slot name="header">

                                </slot>
                            </div>
                            <button class="modal-default-button" :disabled="inputsDisabled" @click="$emit('close')"
                                    :class="{'text-black': !darkMode, 'text-grey-120': darkMode}">
                                <svg class="w-4 fill-current" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.9035 4.78018L9.32136 1.36237C9.47273 1.21896 9.56036 1.01977 9.56036 0.796696C9.56036 0.358513 9.20184 0 8.76366 0C8.54058 0 8.34139 0.0876191 8.19799 0.231024L4.78018 3.65686L1.36237 0.231024C1.21896 0.0876191 1.01977 0 0.796696 0C0.358513 0 0 0.358513 0 0.796696C0 1.01977 0.0876191 1.21896 0.231024 1.36237L3.65686 4.78018L0.238999 8.19799C0.0876269 8.34139 0 8.54058 0 8.76366C0 9.20184 0.358513 9.56036 0.796696 9.56036C1.01977 9.56036 1.21896 9.47274 1.36237 9.32933L4.78018 5.9035L8.19799 9.32136C8.34139 9.47273 8.54058 9.56036 8.76366 9.56036C9.20184 9.56036 9.56036 9.20184 9.56036 8.76366C9.56036 8.54058 9.47274 8.34139 9.32933 8.19799L5.9035 4.78018Z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div :class="containerClasses">
                        <div class="" :class="{'text-black': !darkMode, 'text-grey-120': darkMode}">
                            <slot name="content">

                            </slot>
                        </div>
                        <div :class="{'hidden' : noButtons, 'inline-block' : !noButtons}" class="flex justify-end gap-3 pt-5">
                            <slot name="buttons"></slot>
                            <button class="transition duration-200 bg-grey-250 hover:bg-light-background text-white font-medium focus:outline-none py-2 rounded-md px-5 mr-6"
                                    :class="{'bg-grey-250 hover:bg-light-background text-white': !darkMode, 'bg-grey-500 hover:bg-grey-600 text-white': darkMode}"
                                    :disabled="inputsDisabled" @click.stop="$emit('close')">{{ closeText }}</button>
                            <button class="transition duration-200 text-white font-medium focus:outline-none py-2 rounded-md px-5" :class="{'bg-primary-500 hover:bg-blue-500': !disableConfirm, 'bg-grey-200 text-grey-500': disableConfirm, 'cursor-not-allowed': disableConfirm}" @click.stop="$emit('confirm')" :disabled="disableConfirm">{{ confirmText }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        confirmText: {
            type: String,
            default: "Save"
        },
        closeText: {
            type: String,
            default: 'Close'
        },
        noButtons: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        disableConfirm: {
            type: Boolean,
            default: false
        },
        inputsDisabled: {
            type: Boolean,
            default: false
        },
        darkMode: {
            type: Boolean,
            default: false,
        },
        fullWidth: {
            type: Boolean,
            default: true
        },
        restrictWidth: {
            type: Boolean,
            default: true
        },
        containerClasses: {
            type: String,
            default: 'p-8'
        }
    }
}
</script>

<style lang="scss">

</style>
