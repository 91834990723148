<template>
    <div class="row-span-3 border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <Tab
            :dark-mode="darkMode"
            :tabs="tabs"
            @selected="switchTab"
            :tabs-classes="'w-full'"
            :tab-type="'Normal'"
            :default-tab-index="0"
        />
        <ip-quality-score-verification v-if="selectedTab === 'IP Quality Score'" :consumer-product-id="consumerProductId" :dark-mode="darkMode"/>
    </div>
</template>

<script>
import IpQualityScoreVerification from "../../modules/Consumer/IpQualityScoreVerification";
import Tab from "../../components/Tab";

export default {
    name: "ConsumerProductVerification",
    components: {
        IpQualityScoreVerification,
        Tab
    },
    props: {
        consumerProductId: {
            type: Number,
            default: null
        },
        darkMode: {
            type: Boolean,
            default: false,
        }
    },
    data: function() {
        return {
            showIpQualityScoreVerification: false,
            selectedTab: 'IP Quality Score',

            tabs: [
                { name: 'IP Quality Score', current: true  }
            ]
        };
    },
    methods: {
        switchTab(tab) {
            this.selectedTab = tab;
        },
    }
};
</script>
