import _ from "lodash";

const fields = ["estRevenue", "employeeCount", "googleReviewCount", "googleRating", "amountOfPurchasedLeads"];

const companyAttributes = [
    "employee_count",
    "google_review_count",
    "revenue_in_thousands",
    "google_rating",
    "lead_cost"
];

/**
 * @param {CompanyJsDocType} company
 * @param {"estRevenue"|"employeeCount"|"googleReviewsCount"|"googleRating"|"amountOfPurchasedLeads"} field
 * @throws Error
 */
export const validateCompany = (company, field) => {
    if (!(company instanceof Object)) {
        throw `"company" must be an object.`;
    }

    if (!company.hasOwnProperty('employee_count') && field === 'employeeCount') {
        throw `"company" must be an object with the "employee_count" attribute.`;
    }

    if (!company.hasOwnProperty('google_review_count') && field === 'googleReviewsCount') {
        throw `"company" must be an object with the "google_review_count" attribute.`;
    }

    if (!company.hasOwnProperty('revenue_in_thousands') && field === 'estRevenue') {
        throw `"company" must be an object with the "revenue_in_thousands" attribute.`;
    }

    if (!company.hasOwnProperty('google_rating') && field === 'googleRating') {
        throw `"company" must be an object with the "google_rating" attribute.`;
    }

    if (!company.hasOwnProperty('lead_cost') && field === 'amountOfPurchasedLeads') {
        throw `"company" must be an object with the "lead_cost" attribute.`;
    }
}

/**
 * @typedef SortJsDocType
 * @property {boolean} ascending
 * @property {"revenue_in_thousands"|"employee_count"|"google_review_count"|"google_rating"|"lead_cost"} value
 * @property {boolean} active
 */

/**
 * @typedef SortObjectJsDocType
 * @property {SortJsDocType} estRevenue
 * @property {SortJsDocType} employeeCount
 * @property {SortJsDocType} googleReviewsCount
 * @property {SortJsDocType} googleRating
 * @property {SortJsDocType} amountOfPurchasedLeads
 */

/**
 * @param {SortObjectJsDocType} sortObject
 * @throws Error
 */
export const validateSortObject = (sortObject) => {
    fields.forEach((field) => {
        if (!sortObject.hasOwnProperty(field)) {
            throw new TypeError(`"sortObject.field" must be accessed by one of the following properties: ${JSON.stringify(fields)}. ${field} passed.`);
        }
    })

    for (const object in sortObject) {
        if (!sortObject[object].hasOwnProperty('active')) {
            throw new TypeError(`"object" must have "active" property.}`);
        }

        if (!sortObject[object].hasOwnProperty('value')) {
            throw new TypeError(`"object" must have "value" property.`);
        }

        if (!sortObject[object].hasOwnProperty('ascending')) {
            throw new TypeError(`"object" must have "ascending" property.`)
        }

        if (typeof sortObject[object].ascending != 'boolean') {
            throw new TypeError(`"object.ascending" must be type of boolean. ${sortObject[object].ascending} passed.`)
        }

        if (typeof sortObject[object].active != 'boolean') {
            throw new TypeError(`"object.active" must be type of boolean. ${sortObject[object].active} passed.`)
        }

        if (!companyAttributes.includes(sortObject[object].value)) {
            throw new TypeError(`"object.value" must be one of the following: ${JSON.stringify(companyAttributes)}. ${sortObject[object].value} passed.`);
        }
    }
}

/**
 * @param {boolean} ascending
 * @return {"asc"|"desc"}
 */
export const translateDirection = (ascending) => {
    if (ascending) {
        return "asc";
    } else {
        return "desc";
    }
}

/**
 * @param {CompanyJsDocType[]} companies
 * @param {SortObjectJsDocType} sortObject
 * @param {"estRevenue"|"employeeCount"|"googleReviewsCount"|"googleRating"|"amountOfPurchasedLeads"} field
 * @return {CompanyJsDocType[]}
 * @throws Error
 */
export const sortCompanies = (companies, sortObject, field) => {
    if (!(companies instanceof Array)) {
        throw new TypeError(`"companies" must be an array. ${JSON.stringify(companies)} passed.`)
    }

    companies.forEach((company) => validateCompany(company, field));

    validateSortObject(sortObject);

    return _.orderBy(companies, [
        sortObject[field].value,
    ], [
        translateDirection(sortObject[field].ascending),
    ]);
}
