<template>
    <div class="border rounded-lg"  :class="[darkMode ? 'bg-dark-module border-dark-border' : 'bg-light-module border-light-border']">
        <div class="p-5">
            <div class="flex items-center justify-between pb-4">
                <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Revenue Overview</h5>
            </div>
            <div class="grid grid-cols-2 gap-4" :class="[darkMode ? 'text-slate-100' : 'text-slate-900']" v-if="!loading">
                <div @click="sendToTab('Overview')" class="col-span-2 rounded-md p-4 relative cursor-pointer" :class="[darkMode ? 'border border-primary-600 bg-dark-background hover:bg-dark-module' : 'bg-primary-50 hover:bg-primary-100']">
                    <div class="absolute right-0 top-0 mt-2 mr-2">
                        <svg class="w-3" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.52273 0.818182C5.29679 0.818182 5.11364 0.635026 5.11364 0.409091C5.11364 0.183156 5.29679 0 5.52273 0H8.59084C8.59086 0 8.59089 0 8.59091 0C8.64638 0 8.69927 0.0110395 8.7475 0.0310412C8.79575 0.0510047 8.84096 0.0805976 8.88018 0.11982C8.9194 0.159042 8.949 0.204248 8.96896 0.252498C8.98711 0.296373 8.9973 0.342765 8.99953 0.389467C8.9998 0.395187 8.99996 0.400912 8.99999 0.406637C9 0.407455 9 0.408273 9 0.409091C9 0.409224 9 0.409358 9 0.409492V3.47727C9 3.70321 8.81684 3.88636 8.59091 3.88636C8.36497 3.88636 8.18182 3.70321 8.18182 3.47727V1.39672L6.10127 3.47727L7.85745 5.23346C7.93417 5.31018 7.97727 5.41423 7.97727 5.52273V7.56818C7.97727 7.94792 7.82642 8.31211 7.5579 8.58063C7.28938 8.84915 6.9252 9 6.54545 9H1.43182C1.05208 9 0.687888 8.84915 0.41937 8.58063C0.150852 8.31211 0 7.94792 0 7.56818V2.45455C0 2.0748 0.150852 1.71062 0.41937 1.4421C0.687888 1.17358 1.05208 1.02273 1.43182 1.02273H3.47727C3.58577 1.02273 3.68982 1.06583 3.76654 1.14255L5.52273 2.89873L7.60328 0.818182H5.52273ZM4.94419 3.47727L3.30782 1.84091H1.43182C1.26907 1.84091 1.11299 1.90556 0.997912 2.02064C0.882833 2.13572 0.818182 2.2918 0.818182 2.45455V7.56818C0.818182 7.73093 0.882832 7.88701 0.997912 8.00209C1.11299 8.11717 1.26907 8.18182 1.43182 8.18182H6.54545C6.7082 8.18182 6.86428 8.11717 6.97936 8.00209C7.09444 7.88701 7.15909 7.73093 7.15909 7.56818V5.69218L5.52273 4.05581L3.76654 5.812C3.60678 5.97176 3.34776 5.97176 3.188 5.812C3.02824 5.65224 3.02824 5.39322 3.188 5.23346L4.94419 3.47727Z" fill="#0081FF"/>
                        </svg>
                    </div>
                    <p class="text-sm font-semibold pb-2">
                        Outstanding Invoice
                    </p>
                    <p class="text-lg font-semibold">
                        {{ $filters.currency(revenueOverview.outstanding_invoice) }}
                    </p>
                </div>
                <div @click="sendToTab('Leads')" class="rounded-md p-4 relative cursor-pointer" :class="[darkMode ? 'border border-primary-600 bg-dark-background hover:bg-dark-module' : 'bg-primary-50 hover:bg-primary-100']">
                    <div class="absolute right-0 top-0 mt-2 mr-2">
                        <svg class="w-3" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.52273 0.818182C5.29679 0.818182 5.11364 0.635026 5.11364 0.409091C5.11364 0.183156 5.29679 0 5.52273 0H8.59084C8.59086 0 8.59089 0 8.59091 0C8.64638 0 8.69927 0.0110395 8.7475 0.0310412C8.79575 0.0510047 8.84096 0.0805976 8.88018 0.11982C8.9194 0.159042 8.949 0.204248 8.96896 0.252498C8.98711 0.296373 8.9973 0.342765 8.99953 0.389467C8.9998 0.395187 8.99996 0.400912 8.99999 0.406637C9 0.407455 9 0.408273 9 0.409091C9 0.409224 9 0.409358 9 0.409492V3.47727C9 3.70321 8.81684 3.88636 8.59091 3.88636C8.36497 3.88636 8.18182 3.70321 8.18182 3.47727V1.39672L6.10127 3.47727L7.85745 5.23346C7.93417 5.31018 7.97727 5.41423 7.97727 5.52273V7.56818C7.97727 7.94792 7.82642 8.31211 7.5579 8.58063C7.28938 8.84915 6.9252 9 6.54545 9H1.43182C1.05208 9 0.687888 8.84915 0.41937 8.58063C0.150852 8.31211 0 7.94792 0 7.56818V2.45455C0 2.0748 0.150852 1.71062 0.41937 1.4421C0.687888 1.17358 1.05208 1.02273 1.43182 1.02273H3.47727C3.58577 1.02273 3.68982 1.06583 3.76654 1.14255L5.52273 2.89873L7.60328 0.818182H5.52273ZM4.94419 3.47727L3.30782 1.84091H1.43182C1.26907 1.84091 1.11299 1.90556 0.997912 2.02064C0.882833 2.13572 0.818182 2.2918 0.818182 2.45455V7.56818C0.818182 7.73093 0.882832 7.88701 0.997912 8.00209C1.11299 8.11717 1.26907 8.18182 1.43182 8.18182H6.54545C6.7082 8.18182 6.86428 8.11717 6.97936 8.00209C7.09444 7.88701 7.15909 7.73093 7.15909 7.56818V5.69218L5.52273 4.05581L3.76654 5.812C3.60678 5.97176 3.34776 5.97176 3.188 5.812C3.02824 5.65224 3.02824 5.39322 3.188 5.23346L4.94419 3.47727Z" fill="#0081FF"/>
                        </svg>
                    </div>
                    <p class="text-sm font-semibold pb-2">
                        Last Lead
                    </p>
                    <p class="text-lg font-semibold">
                        {{ revenueOverview.last_lead ? $filters.dateFromTimestamp(this.revenueOverview.last_lead)
                         : "No lead purchased yet" }}
                    </p>
                </div>
                <div @click="sendToTab('Revenue')" class="rounded-md p-4 relative cursor-pointer" :class="[darkMode ? 'border border-primary-600 bg-dark-background hover:bg-dark-module' : 'bg-primary-50 hover:bg-primary-100']">
                    <div class="absolute right-0 top-0 mt-2 mr-2">
                        <svg class="w-3" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.52273 0.818182C5.29679 0.818182 5.11364 0.635026 5.11364 0.409091C5.11364 0.183156 5.29679 0 5.52273 0H8.59084C8.59086 0 8.59089 0 8.59091 0C8.64638 0 8.69927 0.0110395 8.7475 0.0310412C8.79575 0.0510047 8.84096 0.0805976 8.88018 0.11982C8.9194 0.159042 8.949 0.204248 8.96896 0.252498C8.98711 0.296373 8.9973 0.342765 8.99953 0.389467C8.9998 0.395187 8.99996 0.400912 8.99999 0.406637C9 0.407455 9 0.408273 9 0.409091C9 0.409224 9 0.409358 9 0.409492V3.47727C9 3.70321 8.81684 3.88636 8.59091 3.88636C8.36497 3.88636 8.18182 3.70321 8.18182 3.47727V1.39672L6.10127 3.47727L7.85745 5.23346C7.93417 5.31018 7.97727 5.41423 7.97727 5.52273V7.56818C7.97727 7.94792 7.82642 8.31211 7.5579 8.58063C7.28938 8.84915 6.9252 9 6.54545 9H1.43182C1.05208 9 0.687888 8.84915 0.41937 8.58063C0.150852 8.31211 0 7.94792 0 7.56818V2.45455C0 2.0748 0.150852 1.71062 0.41937 1.4421C0.687888 1.17358 1.05208 1.02273 1.43182 1.02273H3.47727C3.58577 1.02273 3.68982 1.06583 3.76654 1.14255L5.52273 2.89873L7.60328 0.818182H5.52273ZM4.94419 3.47727L3.30782 1.84091H1.43182C1.26907 1.84091 1.11299 1.90556 0.997912 2.02064C0.882833 2.13572 0.818182 2.2918 0.818182 2.45455V7.56818C0.818182 7.73093 0.882832 7.88701 0.997912 8.00209C1.11299 8.11717 1.26907 8.18182 1.43182 8.18182H6.54545C6.7082 8.18182 6.86428 8.11717 6.97936 8.00209C7.09444 7.88701 7.15909 7.73093 7.15909 7.56818V5.69218L5.52273 4.05581L3.76654 5.812C3.60678 5.97176 3.34776 5.97176 3.188 5.812C3.02824 5.65224 3.02824 5.39322 3.188 5.23346L4.94419 3.47727Z" fill="#0081FF"/>
                        </svg>
                    </div>
                    <p class="text-sm font-semibold pb-2">
                        Revenue All-time
                    </p>
                    <p class="text-lg font-semibold">
                        {{ $filters.currency(revenueOverview.revenue_all_time) }}
                    </p>
                </div>
            </div>
            <div v-if="loading">
                <loading-spinner></loading-spinner>
            </div>
        </div>
    </div>

</template>

<script>
import Tab from "../components/Tab";
import ApiService from "../../Companies/services/api";
import LoadingSpinner from "../components/LoadingSpinner";
export default {
    name: "RevenueOverview",
    components: {Tab, LoadingSpinner},
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        companyId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            api: ApiService.make(),
            revenueOverview: {
                outstanding_invoice: null,
                last_lead: null,
                revenue_all_time: null
            },
            loading: false
        }
    },
    created() {
        if (this.companyId) this.getRevenueOverview();
    },
    methods: {
        sendToTab(value) {
            this.$emit('send-to-tab', value)
        },
        getRevenueOverview() {
            this.loading = true;
            this.api.getRevenueOverview(this.companyId)
                .then(resp => this.revenueOverview = resp.data.data)
                .finally(() => this.loading = false);
        }
    }
}
</script>

<style scoped>

</style>
