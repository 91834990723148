<template>
    <div class="">

    </div>
</template>

<script>
export default {
    name: "CRMEntry",
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: Object
        }
    },
    emits: [
        'update:modelValue'
    ],
    mounted() {
        this.$emit('update:modelValue', null);
    }
}
</script>

<style scoped>

</style>
