import {defineStore} from "pinia";

export const useCompanyServicingAreaSorterStore = defineStore('company-servicing-area-sorter', {
    state: () => {
        return {
            sorters: {
                employeeCount: {
                    value: "employee_count",
                    ascending: true,
                    active: false
                },
                googleReviewCount: {
                    value: "google_review_count",
                    ascending: true,
                    active: false
                },
                estRevenue: {
                    value: "revenue_in_thousands",
                    ascending: true,
                    active: false
                },
                googleRating: {
                    value: "google_rating",
                    ascending: true,
                    active: false
                },
                amountOfPurchasedLeads: {
                    value: "lead_cost",
                    ascending: true,
                    active: false
                },
            },
        }
    },
    persist: true,
})
