import axios from 'axios';
import {BaseApiService} from "./base";

class ApiService extends BaseApiService {
    constructor(baseUrl, baseEndpoint, version) {
        super("ApiService");

        this.baseEndpoint = baseEndpoint;
        this.baseUrl = baseUrl;
        this.version = version;
    }

    axios() {
        const axiosConfig = {
            baseURL: `/${this.baseUrl}/v${this.version}/${this.baseEndpoint}`
        }

        return axios.create(axiosConfig);
    }

    createOutboundCall(serviceName, userPhoneNumber, otherNumber, reference) {
        return this.axios().post(`/update-outbound-call/`, {
            service_name: serviceName,
            user_phone_number: userPhoneNumber,
            other_phone_number: otherNumber,
            external_reference: reference,
            call_result: 'initial'
        });
    }

    concludeOutboundCall(serviceName, userPhoneNumber, otherNumber, reference) {
        return this.axios().post(`/update-outbound-call/`, {
            service_name: serviceName,
            user_phone_number: userPhoneNumber,
            other_phone_number: otherNumber,
            external_reference: reference,
            call_result: 'answered'
        });
    }

    createInboundCall(serviceName, userPhoneNumber, otherNumber, reference) {
        return this.axios().post(`/update-inbound-call/`, {
            service_name: serviceName,
            user_phone_number: userPhoneNumber,
            other_phone_number: otherNumber,
            external_reference: reference,
            call_result: 'initial'
        });
    }

    concludeInboundCall(serviceName, userPhoneNumber, otherNumber, reference, result = 'answered') {
        return this.axios().post(`/update-inbound-call/`, {
            service_name: serviceName,
            user_phone_number: userPhoneNumber,
            other_phone_number: otherNumber,
            external_reference: reference,
            call_result: result
        });
    }

    lookupCaller(phoneNumber) {
        return this.axios().get('/lookup-caller', {params: {other: phoneNumber}});
    }

    getVoicemails(phoneNumber) {
        return this.axios().get('/voicemails');
    }

    createOutboundSMS(contactPhone, body) {
        return this.axios().post('/create-outbound-sms', {other_phone_number: contactPhone, sms_body: body});
    }

    markVoicemailHeard(voicemailId) {
        return this.axios().post('/voicemails/' + voicemailId + '/mark-heard/');
    }

    getCallRecordingsForLeadId(leadId) {
        return this.axios().get('/call_recordings/lead/' + leadId);
    }

}

export { ApiService };

