<template>
    <div class="border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="p-5">
            <div class="flex items-center justify-between pb-3">
                <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Campaigns</h5>
            </div>
            <div v-if="showFilters" class="flex justify-between">
                <div class="flex items-center mr-3">
                    <p class="text-grey-300 uppercase text-xs mr-2">Filters</p>
                    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 4H13V6H3V4ZM0 0H16V2H0V0ZM6 8H10V10H6V8Z" fill="#ABB0B5"/>
                    </svg>
                    <Dropdown v-model="selectedCampaignStatus" v-on:update:modelValue="getCampaigns" :dark-mode="darkMode" class="w-48" :options="filterOptions" :selected="campaignStatus"></Dropdown>
                </div>
                <div class="flex items-center">
                    <a class="transition duration-200 text-sm font-semibold focus:outline-none py-2 rounded-md px-5 mr-3"
                       :href="getLegacyAdminCompanyCampaignsUrl(legacyCompanyId)" target="_blank"
                       :class="{'bg-grey-475 hover:bg-blue-800 text-white': !darkMode, 'bg-blue-400 hover:bg-blue-500 text-white': darkMode}">
                        Create New Campaign
                    </a>
                    <a class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-semibold focus:outline-none py-2 rounded-md px-5"
                       :href="getLegacyAdminCompanyCampaignsUrl(legacyCompanyId)" target="_blank"
                       :class="{'bg-grey-475 hover:bg-blue-800 text-white': !darkMode, 'bg-blue-400 hover:bg-blue-500 text-white': darkMode}">
                        Download Zipcodes
                    </a>
                </div>
            </div>
        </div>
        <div v-if="!loading">
            <div class="border-t border-b"
                 :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
                <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-3 m-4">
                    <div v-for="campaign in campaigns" class="border rounded p-4 relative"
                         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
                        <div class="absolute right-0 top-0 mt-2 mr-2">
                            <ActionsHandle v-if="hasEditRights" :dark-mode="darkMode" :custom-actions="['Set to Inactive']" @edit="editCampaign(campaign.uuid)" @delete="openDeleteModal(campaign.uuid, campaign.name)"></ActionsHandle>
                            <modal
                                :small="true"
                                :dark-mode="darkMode"
                                v-if="showDeleteModal"
                                @close="this.closeDeleteModal()"
                                :close-text="'Cancel'"
                                :confirm-text="'Delete'"
                                :disable-confirm="deleteInProgress"
                                @confirm="deleteCampaign(campaign.uuid)"
                            >
                                <template v-slot:header>
                                    Confirm Delete?
                                </template>
                                <template v-slot:content>
                                    <p v-if="deleteError === null">Are you sure you wish to delete this Lead Campaign - <strong>{{ deleteCampaignName }}</strong>?</p>
                                    <alert v-else :text="deleteError" :alert-type="'error'" :dark-mode="darkMode"></alert>
                                </template>
                            </modal>
                        </div>
                        <h4 class="text-lg font-semibold mb-3 truncate" :class="[darkMode ? 'text-slate-100' : 'text-slate-900']">{{ campaign.name }}</h4>
                        <div class="px-4 mb-3 inline-flex items-center rounded-full py-1 font-medium whitespace-no-wrap"
                             :class="[darkMode ? (campaign.status == true ? 'text-green-550 border border-green-550' : 'text-red-350 border border-red-350') : (campaign.status == true ? 'text-green-550 bg-green-150' : 'text-red-350 bg-red-75')]">
                            <p v-if="campaign.status == true" class="text-xs">Active</p>
                            <p v-else class="text-xs">Inactive</p>
                        </div>
                        <div class="mb-3">
                            <p class="font-semibold" :class="{'text-grey-800': !darkMode, 'text-grey-200': darkMode}">
                                Region & Utilities
                            </p>
                            <div :class="{'text-grey-500': !darkMode, 'text-grey-300': darkMode}">
                                <p class="text-sm">{{ campaign.zipcode_count }}  zip codes in {{ campaign.state_count }} state</p>
                                <p class="text-sm">{{ campaign.utility_count }} utilities</p>
                            </div>
                        </div>
                        <div class="mb-3">
                            <p class="font-semibold" :class="{'text-grey-800': !darkMode, 'text-grey-200': darkMode}">
                                Budget
                            </p>
                            <div :class="{'text-grey-500': !darkMode, 'text-grey-300': darkMode}">
                                <p class="text-sm">Campaign: {{ campaign.budget }}</p>
                            </div>
                            <ul class="text-sm list-disc pl-4" :class="{'text-grey-500': !darkMode, 'text-grey-300': darkMode}">
                                <li v-for="leadCampaignSalesType in campaign.sales_types">{{ leadCampaignSalesType.sales_type }}: {{ leadCampaignSalesType.limit }}</li>
                            </ul>
                        </div>
                        <div class="mb-3">
                            <p class="font-semibold" :class="{'text-grey-800': !darkMode, 'text-grey-200': darkMode}">
                                Lead Category
                            </p>
                            <ul class="text-sm list-disc pl-4" :class="{'text-grey-500': !darkMode, 'text-grey-300': darkMode}">
                                <li v-for="category in campaign.lead_categories">{{ category[0] }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-3">
                <Pagination :dark-mode="darkMode" :pagination-data="paginationData" :show-pagination="true" @change-page="handlePaginationEvent"></Pagination>
            </div>
        </div>
        <div v-else class="border-t border-b h-64 overflow-y-auto"  :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
            <div class="flex h-full justify-center items-center">
                <loading-spinner />
            </div>
        </div>
    </div>
</template>

<script>
import Dropdown from "../Shared/components/Dropdown";
import ActionsHandle from "../Shared/components/ActionsHandle";
import Pagination from "../Shared/components/Pagination";
import ApiService from "./services/api";
import LoadingSpinner from "../Shared/components/LoadingSpinner";
import LegacyAdminMixin from "../Shared/mixins/legacy-admin-mixin";
import Modal from "../Shared/components/Modal";
import Alert from "../Shared/components/Alert";

export default {
    name: "Campaigns",
    mixins: [LegacyAdminMixin],
    components: {Pagination, ActionsHandle, Dropdown, LoadingSpinner, Modal, Alert},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        companyId: {
            type: Number,
            default: null
        },
        legacyCompanyId: {
            Type: Number,
            default: null,
        },
        showFilters: {
            type: Boolean,
            default: false
        },
        hasEditRights: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            apiService: ApiService.make(),
            campaigns: [],
            paginationData: null,
            loading: false,
            filterOptions: [
                {id: -1, name: 'All'},
                {id: 1, name: 'Active'},
                {id: 0, name: 'Inactive'}
            ],
            campaignStatus: null,
            selectedCampaignStatus: null,
            showDeleteModal: false,
            deleteError: null,
            deleteInProgress: false,
            deleteCampaignUuid: null,
            deleteCampaignName: '',
        }
    },
    created: function () {
        this.getCampaigns();
    },
    methods: {
        getCampaigns() {
            this.loading = true;
            this.apiService.getCampaigns(this.companyId, this.selectedCampaignStatus).then(resp => {
                let {data, ...paginationData} = resp.data.data.campaigns;
                this.campaigns = data;
                this.paginationData = paginationData;
            }).catch(e => console.log(e)).finally(() => this.loading = false);
        },
        async handlePaginationEvent(newPageUrl) {
            this.loading = true;
            await axios.get(newPageUrl.link, {
                params:{
                    status: this.selectedCampaignStatus
                }
            }).then(resp => {
                let {data, ...paginationData} = resp.data.data.campaigns;
                this.campaigns = data;
                this.paginationData = paginationData;
            }).catch(e => console.log(e)).finally(() => this.loading = false);
        },
        editCampaign(campaignUuid) {
            const url = this.getLegacyAdminCompanyCampaignsUrl(this.legacyCompanyId, campaignUuid);
            window.open(url, '_blank').focus();
        },
        deleteCampaign() {
            this.deleteInProgress = true;
            this.apiService.deleteCampaign(this.companyId, this.deleteCampaignUuid)
                .then(resp => {
                    if (!resp?.data?.result) {
                        this.deleteError = `A legacy server error has occurred.`;
                    }
                    else {
                        this.closeDeleteModal();
                    }
                }).catch(errResponse => {
                    this.deleteError = errResponse?.response?.data?.message ?? errResponse.message ?? `An error has occurred.`;
                }).finally(() => {
                    this.getCampaigns();
                    this.deleteInProgress = false;
                });
        },
        openDeleteModal(campaignUuid, campaignName) {
            this.deleteCampaignUuid = campaignUuid;
            this.deleteCampaignName = campaignName;
            this.deleteError = null;
            this.showDeleteModal = true;
        },
        closeDeleteModal() {
            this.showDeleteModal = false;
            this.deleteCampaignUuid = null;
            this.deleteCampaignName = '';
        }
    }
}
</script>

<style scoped>

</style>
