import DispatchesGlobalEventsMixin
    from "../../../mixins/dispatches-global-events-mixin";

export default {
    data: function() {
      return {
          loading: true,
          heartbeatTimeout: null,
          data: {
              lead: null,
          },
          locked: false,
          publicComment: null
      }
    },

    mixins: [DispatchesGlobalEventsMixin],

    props: ["api", "consumerApi", "minimumReviewTimeMillisecs", "nextLeadRecheckTimeMillisecs", "initialLeadId", "displayedQueue"],

    created() {
        this.getNextLead(this.initialLeadId ? (Number(this.initialLeadId) ? Number(this.initialLeadId) : null) : null);

        if(this.initialLeadId)
            window.history.pushState({}, document.title, window.location.pathname);

        this.listenForGlobalEvent("next-lead", ({lead_id}) => this.getNextLead(lead_id));
        this.listenForGlobalEvent("load-lead", ({lead_id}) => {
            this.$emit('set-specific-lead', lead_id);

            if(this.displayedQueue === 'display') {
                this.getNextLead(lead_id);
            }
            else {
                this.$emit('set-displayed-queue', 'display');
            }
        });
    },

    methods: {
        getNextLead(specificLeadId = null) {
            const previousLeadId = this.data.lead !== null ? this.data.lead.basic.id : null;
            const existingQueue = this.data.lead !== null ? this.data.lead.basic.queue : null;

            if(existingQueue) {
                this.dispatchGlobalEvent('clear-lead-data');

                if(!specificLeadId) {
                    this.$emit('clear-specific-lead');
                }
            }

            this.loading = true;
            this.locked = true;

            this.api.getNextProduct(previousLeadId, specificLeadId).then(resp => {
                if(resp.data.data.status === true) {
                    this.tidyData();
                    this.heartbeat();

                    if(resp.data.data.lead) {
                        this.data.lead = resp.data.data.lead;

                        if(existingQueue !== null && existingQueue !== this.data.lead.basic.queue) {
                            this.$emit('status-change', existingQueue);
                        }

                        this.dispatchGlobalEvent("new-lead", this.data.lead);
                    }
                    else {
                        this.data.lead = null;
                        setTimeout(() => this.getNextLead(), resp.data.data.retry === true ? 0 : this.nextLeadRecheckTimeMillisecs);
                    }
                }
                else {
                    this.$emit('activate-alert', {
                        type: 'error',
                        text: resp.data.data.msg || 'Could not get next lead'
                    });
                }
            }).catch(e => {
                this.$emit('activate-alert', {
                    type: 'error',
                    text: e.response.data?.data?.msg || 'Error getting next lead'
                });
            }).finally(() => {
                this.loading = false;
                setTimeout(() => this.locked = false, this.minimumReviewTimeMillisecs);
            });
        },

        tidyData() {
            this.publicComment = null;
        },

        cancelConsumerProduct(reason, publicComment = '') {
            this.loading = true;

            //todo: update this.data.lead.basic.id with the actual consumer product id after "get next product" implemented
            this.consumerApi.cancelConsumerProduct(this.data.lead.basic.id, reason, publicComment).then(resp => {
                if(resp.data.data.status === true) {
                    this.getNextLead();
                }
                else {
                    this.$emit('activate-alert', {
                        type: 'error',
                        text: resp.data.data.msg || "Could not cancel lead"
                    });

                    this.loading = false;
                }
            }).catch(e => {
                this.$emit('activate-alert', {
                    type: 'error',
                    text: e.response.data?.data?.msg || 'Error cancelling lead'
                });

                this.loading = false;
            })
        },

        markForReview(reason) {
            this.loading = true;

            //todo: update this.data.lead.basic.id with the actual consumer product id after "get next product" implemented
            this.consumerApi.markConsumerProductAsPendingReview(this.data.lead.basic.id, reason).then(resp => {
                if(resp.data.data.status === true) {
                    this.getNextLead();
                }
                else {
                    this.$emit('activate-alert', {
                        type: 'error',
                        text: resp.data.data.msg || "Could not move to pending review"
                    });

                    this.loading = false;
                }
            }).catch(e => {
                this.$emit('activate-alert', {
                    type: 'error',
                    text: e.response.data?.data?.msg || 'Error moving lead to pending review'
                });

                this.loading = false;
            })
        },

        markAsUnderReview(reason, publicComment = '') {
            this.loading = true;

            //todo: update this.data.lead.basic.id with the actual consumer product id after "get next product" implemented
            this.consumerApi.markConsumerProductAsUnderReview(this.data.lead.basic.id, reason, publicComment).then(resp => {
                if(resp.data.data.status === true) {
                    this.getNextLead();
                }
                else {
                    this.$emit('activate-alert', {
                        type: 'error',
                        text: resp.data.data.msg || "Could not move to under review"
                    });

                    this.loading = false;
                }
            }).catch(e => {
                this.$emit('activate-alert', {
                    type: 'error',
                    text: e.response.data?.data?.msg || 'Error moving lead to under review'
                });

                this.loading = false;
            })
        },

        allocateProduct(scenario, bestTimeToContact = null, publicComment = '') {
            this.loading = true;

            // TODO: update this.data.lead.basic.id to the consumer product id once the "get next product" is implemented
            this.consumerApi.approveConsumerProduct(this.data.lead.basic.id, scenario, bestTimeToContact, publicComment).then(resp => {
                if(resp.data.data.status === true) {
                    this.getNextLead();
                }
                else {
                    this.$emit('activate-alert', {
                        type: 'error',
                        text: resp.data.data.msg || "Could not allocate product"
                    });

                    this.loading = false;
                }
            }).catch(e => {
                this.$emit('activate-alert', {
                    type: 'error',
                    text: e.response.data?.data?.msg || 'Error allocating product'
                });

                this.loading = false;
            })
        },

        heartbeat() {
            if(this.data.lead !== null && this.data.lead.basic.id) {
                this.consumerApi.heartbeat(this.data.lead.basic.id).then(resp => {
                    // TODO: if status is false, need to report to user. Edge case.

                }).catch(e => {
                    console.error("Heartbeat has failed.");
                })
            }

            if(this.heartbeatTimeout)
                clearTimeout(this.heartbeatTimeout);

            this.heartbeatTimeout = setTimeout(() => this.heartbeat(), 30000);
        },

        updateBasicField(fieldName, newValue) {
            this.data.lead.basic[fieldName] = newValue;

            let data = this.getFormattedData(fieldName, newValue);

            this.api.updateBasicInfo(this.data.lead.basic.id, data).then(resp => {

            }).catch(e => {
                console.error("Error updating data");
            });
        },

        updateStatusReason(field, value) {
            this.data.lead.basic[field] = value;

            let data = this.getFormattedData(field, value);

            this.api.updateStatusReason(this.data.lead.basic.id, data).then(resp => {

            }).catch(e => {
                //TODO: Error Handling
            });
        },

        getFormattedData(fieldName, fieldValue) {
            let data = {};

            if(fieldName === 'name') {
                let name = fieldValue.split(' ')
                data.first_name = name.shift();
                data.last_name = name.join(' ');
            } if(fieldName === 'own_property') {
                data.own_property = fieldValue ? 'Yes' : 'No';
            } else {
                data[fieldName] = fieldValue;
            }

            return data;
        }
    }
}
