<template>
    <div class="border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="p-5 flex items-center justify-between">
            <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Processor List</h5>
            <CustomButton @click="addProcessor">
                Add Processor
            </CustomButton>
        </div>
        <div class="grid grid-cols-5 gap-x-3 mb-2 px-5">
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">User</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Team</p>
<!--            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Phone</p>-->
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs"></p>
        </div>
        <div class="border-t border-b h-64 overflow-y-auto"
             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
            <div>
                <div class="grid grid-cols-5 gap-x-3 border-b px-5 py-3"
                     v-for="processor in processors" :key="processor.id"
                     :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <p class="text-sm col-span-2 truncate">
                        {{ processor.name }}
                    </p>
                    <p class="text-sm col-span-2 truncate">
                        {{ processor.team }}
                    </p>
                    <ActionsHandle :dark-mode="darkMode" @edit="editProcessor(processor)"
                                   @delete="deleteProcessor(processor.id)"/>
                </div>
            </div>
        </div>
        <div class="p-3"></div>
        <Modal :dark-mode="darkMode" :small="true" @close="toggleProcessorModal" close-text="Cancel"
               @confirm="editId > 0 ? updateProcessor() : createProcessor()"
               :confirm-text="editId > 0 ? 'Update Processor' : 'Save & Add Processor'" v-if="processorModal">
            <template v-slot:header>
                <h4 class="text-xl font-medium">{{ editId > 0 ? 'Edit' : 'Add' }} Processor</h4>
            </template>
            <template v-slot:content>
                <div class="px-6">
                    <div class="mb-6">
                        <label class="block pb-2 font-medium">User</label>
                        <Autocomplete
                            :dark-mode="darkMode"
                            v-model="selectedUserId"
                            :value="selectedUserId"
                            :options="userOptions"
                            @search="searchUsers"
                            :placeholder="'Enter Name'">
                        </Autocomplete>
                        <label class="block pb-2 pt-5 font-medium">Assign a team</label>
                        <Dropdown :dark-mode="darkMode" :options="teamsSorted" v-model="selectedTeamId"
                                  :selected="editTeam"/>
                    </div>
                </div>
                <div v-if="error !== null" class="my-4 font-bold text-red-400">{{ error }}</div>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from "../../Shared/components/Modal";
import Dropdown from "../../Shared/components/Dropdown";
import ActionsHandle from "../../Shared/components/ActionsHandle";
import Autocomplete from "../../Shared/components/Autocomplete";
import CustomButton from "../../Shared/components/CustomButton";

export default {
    name: "ProcessorList",
    components: {
        CustomButton,
        ActionsHandle,
        Modal,
        Dropdown,
        Autocomplete
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        queues: {
            type: Array,
            default: () => []
        },
        teams: {
            type: Array,
            default: () => []
        },
        processors: {
            type: Array,
            default: () => []
        },
        userSearchOptions: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            processorModal: false,
            selectedTeamId: 0,
            selectedUserId: -1,
            selectedPhoneId: -1,
            editTeam: null,
            editPhone: null,
            editId: 0,
            error: null
        }
    },
    computed: {
        userOptions: {
            get() {
                return this.userSearchOptions;
            },
            set(value) {
                this.$emit('change-user-search-options', value);
            }
        },
        teamsSorted: function () {
            return this.teams.sort((teamA, teamB) => {
                return teamA.id - teamB.id;
            })
        }
    },
    methods: {
        toggleProcessorModal(selectedTeamId = -1, selectedUserId = null, userOptions = [], editTeam = null, selectedPhone = -1, editPhone = null) {
            this.processorModal = !this.processorModal;

            this.editTeam = editTeam;
            this.editPhone = editPhone;
            this.selectedTeamId = selectedTeamId
            this.selectedUserId = selectedUserId;
            this.selectedPhoneId = selectedPhone;
            this.userOptions = userOptions;
            this.error = null;
        },
        addProcessor() {
            this.editId = 0;

            this.toggleProcessorModal();
        },
        createProcessor() {
            if (!this.selectedUserId || this.selectedTeamId === -1) {
                return this.error = "Please enter the required fields.";
            }

            this.$emit('save-processor', {userId: this.selectedUserId, teamId: this.selectedTeamId, phoneId: this.selectedPhoneId});
            this.processorModal = false;
        },
        searchUsers(query) {
            this.userOptions = [];
            this.$emit('search-users', query);
        },
        deleteProcessor(id) {
            this.$emit('delete-processor', id);
        },
        editProcessor(processor) {
            console.log(processor);
            this.editId = processor.id;

            this.toggleProcessorModal(
                processor.team_id,
                processor.user_id,
                [
                    {
                        id: processor.user_id,
                        name: processor.name
                    }
                ],
                {
                    id: this.selectedTeamId,
                    name: processor.team
                },
                -1,
                {
                    id: -1,
                    name: "None"
                }
            );
        },
        updateProcessor() {
            if (!this.selectedUserId || this.selectedTeamId === -1 || !this.editId) {
                return this.error = "Please enter the required fields.";
            }

            this.$emit('update-processor', {id: this.editId, userId: this.selectedUserId, teamId: this.selectedTeamId, phoneId: this.selectedPhoneId});

            this.toggleProcessorModal();
        }
    }
}
</script>

<style scoped>

</style>
