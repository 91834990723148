<template>
    <div>
        <alerts-container v-if="alertActive" :alert-type="alertType" :text="alertText" :dark-mode="darkMode"/>

        <div v-if="initializing === 2">
            <loading-spinner :label="'Initializing'" :dark-mode="darkMode" />
        </div>
        <div v-else-if="initializing === 1"
            class="text-center text-red-400 my-10">
            Initialization error
        </div>
        <div v-else-if="initializing === 0"
             class="w-full flex-auto pt-3 relative"
             :class="{'bg-light-background': !darkMode, 'bg-dark-background': darkMode}">
            <div class="px-10" :class="[darkMode ? 'text-white' : 'text-slate-900']">
                <div class="flex items-center justify-between flex-row py-4">
                    <div class="flex flex-row justify-between items-center py-2">
                        <h3 class="text-xl font-medium pb-0 leading-none mr-5">Advertising</h3>
                    </div>

                    <div v-if="isAdvertisingAdmin" class="flex flex-row items-end justify-end gap-5">
                        <button
                            type="button"
                            @click="togglePlatformAuthenticationModal(true)"
                            class="px-5 py-2 h-9 rounded bg-primary-500 hover:bg-primary-600 text-white text-sm font-medium">
                            Manage Platform Authentication
                        </button>
                        <button
                            type="button"
                            @click="toggleAccountsModal(true)"
                            class="px-5 py-2 h-9 rounded bg-primary-500 hover:bg-primary-600 text-white text-sm font-medium">
                            Manage Accounts
                        </button>
                    </div>
                </div>
                <div class="border rounded-lg"
                     :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
                    <div class="p-5">
                        <div class="flex items-center justify-between pb-3">
                            <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Ad Campaigns</h5>
                        </div>
                        <div class="grid grid-cols-5 gap-5 justify-between">
                            <div class="col-span-3 flex flex-col gap-3">
                                <div class="grid grid-cols-6 gap-3">
                                    <Dropdown class="col-span-2" :dark-mode="darkMode" :placeholder="'Choose a platform'" :options="platformOptions" v-model="platform" @change="handleChangePlatform($event.id)"/>
                                    <Dropdown class="col-span-2" :dark-mode="darkMode" :placeholder="loadingAccounts ? 'Loading accounts' : 'Choose an account'" :options="accountOptions" v-model="accountId"/>
                                </div>
                                <div class="grid grid-cols-6 gap-3">
                                    <Dropdown class="col-span-2" :dark-mode="darkMode" :placeholder="campaignStatusOptions.length > 0 ? 'All Statuses' : 'Choose an account'" :options="campaignStatusOptions" v-model="campaignStatus" />
                                    <Dropdown class="col-span-2" :dark-mode="darkMode" :placeholder="stateOptions.length > 0 ? 'States' : 'Loading states'" :options="stateOptions" v-model="state"/>
                                    <Dropdown :dark-mode="darkMode" :placeholder="'TCPA & CPC'" :options="costMetricOptions" v-model="costMetric"/>
                                    <div class="flex flex-row items-center">
                                        <Dropdown :dark-mode="darkMode" class="w-24" :placeholder="'Per Page'" :selected="'10'"
                                                  :options="['10', '25', '50', '100']" v-model="perPage"/>
                                        <p class="text-sm ml-2" :class="[darkMode ? 'text-slate-500' : 'text-slate-400']">
                                            /Page</p>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col items-start justify-end">
                                <button
                                    type="button"
                                    @click="getCampaigns(platform, accountId, 1, perPage, state, costMetric, campaignStatus)"
                                    class="px-5 py-2 h-9 w-32 rounded bg-primary-500 hover:bg-primary-600 text-white text-sm font-medium">
                                    Search
                                </button>
                            </div>
                            <div class="flex flex-col items-end justify-end">
                                <button
                                    type="button"
                                    @click="updateCampaigns"
                                    :disabled="!selectedCampaigns?.length || saving"
                                    class="px-5 py-2 h-9 rounded inline-flex items-center text-sm font-medium"
                                    :class="{
                                        'bg-emerald-600 text-white': saving,
                                        'bg-emerald-500 hover:bg-emerald-600 text-white': !saving && selectedCampaigns?.length > 0,
                                        'bg-grey-200 text-grey-400': !saving && selectedCampaigns?.length <= 0
                                    }">
                                    <span v-if="saving" class="flex flex-row gap-5 items-center">
                                        Saving <loading-spinner :size="'w-4 h-4'" :dark-mode="darkMode" :border-color="'border-white'" />
                                    </span>
                                    <span v-else>
                                        Save Selected Campaigns
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div v-if="currentPlatform.length > 0 && currentAccountId.length > 0"
                             class="text-sm text-primary-400 mt-2">
                            Currently viewing {{ unslugify(currentPlatform, '_') }}
                            Account ID: {{ currentAccountId }}
                        </div>
                    </div>
                    <div v-if="loading">
                        <div class="border-t border-b h-100 overflow-y-auto justify-center items-center"
                             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
                            <loading-spinner class="h-full" :label="'Loading'" :dark-mode="darkMode"/>
                        </div>
                        <div class="p-3"></div>
                    </div>

                    <div v-else-if="campaigns?.data?.length > 0">
                        <div class="border-t border-b"
                             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
                            <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-3 m-4">
                                <div v-for="(campaign, idx) in campaigns.data"
                                     :key="campaign.platform_campaign_id"
                                     class="border rounded-md p-4 relative"
                                     :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">

                                    <div class="flex justify-between mb-2">
                                        <div :class="[darkMode ? 'text-slate-200' : 'text-slate-900']">
                                            <p class="text font-semibold">{{ campaign.name }}</p>
                                        </div>
                                        <input class="p-2 accent-emerald-500 bg-transparent rounded border-2 border-primary-500 cursor-pointer"
                                               type="checkbox" :id="campaign.platform_campaign_id" :value="campaign.platform_campaign_id"
                                               v-model="selectedCampaigns">
                                    </div>
                                    <div class="mb-2" :class="[darkMode ? 'text-slate-200' : 'text-slate-900']">
                                        <p class="text-sm text-slate-500">ID</p>
                                        <p class="text-sm font-medium">{{ campaign.platform_campaign_id }}</p>
                                    </div>
                                    <div class="grid grid-cols-2 gap-3 mb-3">
                                        <div :class="[darkMode ? 'text-slate-200' : 'text-slate-900']">
                                            <p class="text-sm text-slate-500">Status</p>
                                            <p class="text-sm font-medium">{{ campaign.status }}</p>
                                        </div>
                                        <div :class="[darkMode ? 'text-slate-200' : 'text-slate-900']">
                                            <p class="text-sm text-slate-500">Budget</p>
                                            <p class="text-sm font-medium">${{ campaign.campaign_budget_amount }}</p>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <button
                                            type="button"
                                            @click="toggleLocationsModal(true, campaign.locations, campaign.name)"
                                            class="px-3 py-1 font-semibold text-sm h-9 inline-flex items-center rounded border border-cyan-400 text-cyan-400 hover:bg-cyan-400 hover:text-white">
                                            Locations
                                        </button>
                                    </div>
                                    <div class="my-2">
                                        <toggle-switch :dark-mode="darkMode"
                                                       :label="'Automated'"
                                                       :label-class="'text-sm text-slate-500'"
                                                       v-model="campaign.automated" />
                                    </div>
                                    <div class="mb-3">
                                        <button
                                            type="button"
                                            :disabled="!campaign.automated"
                                            class="px-3 py-1 font-semibold text-sm h-9 inline-flex items-center rounded border"
                                            :class="{
                                                'border-cyan-400 text-cyan-400 hover:bg-cyan-400 hover:text-white': campaign.automated,
                                                'border-grey-200 text-grey-350 bg-grey-175': !campaign.automated
                                            }"
                                            @click="toggleAutomationModal(true, campaign.name, idx)">
                                            Automation Parameters
                                        </button>
                                    </div>
                                    <div>
                                        <div class="flex flex-row text-sm mb-2 items-center" :class="[darkMode ? 'text-slate-200' : 'text-slate-900']">
                                            <div class="w-full">
                                                Update Every
                                            </div>
                                            <input
                                                type="number"
                                                :disabled="!campaign.automated"
                                                v-model="campaign.run_interval"
                                                :placeholder="'#'"
                                                min="0"
                                                step="1"
                                                class="p-2 rounded text-sm font-medium w-full h-9 border focus:outline-none outline-none focus:ring-0 focus:border-primary-500 focus:shadow-lg focus:shadow-primary-500/10"
                                                :class="{
                                                    'text-grey-300 bg-grey-175 border-grey-200': !campaign.automated,
                                                    'text-slate-100 bg-dark-background border-dark-border': campaign.automated && darkMode,
                                                    'text-slate-500 bg-light-background border-light-border': campaign.automated && !darkMode,
                                                }">
                                            <Dropdown
                                                :disabled="!campaign.automated"
                                                :dark-mode="darkMode"
                                                class="mx-2 w-24"
                                                v-model="campaign.run_interval_display_unit"
                                                :placeholder="'Units'"
                                                :options="runIntervalOptions"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-3">
                            <pagination
                                @change-page="getCampaigns(currentPlatform, currentAccountId, $event.newPage, perPage, currentState, currentCostMetric, currentCampaignStatus, pageTokens)"
                                :show-pagination="true"
                                :dark-mode="darkMode"
                                :pagination-data="campaigns"/>
                        </div>
                    </div>
                    <div v-else>
                        <div
                            class="border-t border-b h-100 overflow-y-auto flex justify-center items-center"
                            :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
                            <div>No campaigns</div>
                        </div>
                        <div class="p-3"></div>
                    </div>
                </div>

                <modal v-if="showLocationsModal" :no-buttons="true" :dark-mode="darkMode" :full-width="true"
                       @close="toggleLocationsModal(false)">
                    <template v-slot:header>
                        <span class="font-medium">Locations for campaign {{ locationsCampaignName }}</span>
                    </template>
                    <template v-slot:content>
                        <div class="flex flex-row items-center gap-5">
                            <div class="uppercase text-base border-b cursor-pointer"
                                 :class="locationsModalTab === 'included' ? 'font-medium text-cyan-400 border-cyan-400' : 'text-grey-400 border-grey-400'"
                                 @click="setLocationsModalTab('included')">
                                Included
                            </div>
                            <div class="uppercase text-base border-b cursor-pointer"
                                 :class="locationsModalTab === 'excluded' ? 'font-medium text-cyan-400 border-cyan-400' : 'text-grey-400 border-grey-400'"
                                 @click="setLocationsModalTab('excluded')">
                                Excluded
                            </div>
                        </div>

                        <div class="my-2">
                            <div v-show="locationsModalTab === 'included'" class="h-80 overflow-auto">
                                <div v-for="location in campaignLocations.include" class="my-1 h-10 grid grid-cols-2 gap-5 items-center">
                                    <div>
                                        {{ location.name }}
                                    </div>
                                    <div v-if="location.locationType === 'co'" class="flex flex-row justify-end mr-2">
                                        <Dropdown
                                            class="w-1/2"
                                            :dark-mode="darkMode"
                                            :placeholder="'Unlocked'"
                                            :options="locationLockedOptions"
                                            v-model="location.locked" />
                                    </div>
                                </div>
                            </div>
                            <div v-show="locationsModalTab === 'excluded'" class="h-80 overflow-auto">
                                <div v-for="location in campaignLocations.exclude" class="my-1 h-10 grid grid-cols-2 gap-5 items-center">
                                    <div>
                                        {{ location.name }}
                                    </div>
                                    <div v-if="location.locationType === 'co'" class="flex flex-row justify-end mr-2">
                                        <Dropdown
                                            class="w-1/2"
                                            :dark-mode="darkMode"
                                            :placeholder="'Unlocked'"
                                            :options="locationLockedOptions"
                                            v-model="location.locked" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </modal>

                <modal v-if="showAutomationModal" :no-buttons="true" :dark-mode="darkMode" :full-width="false" :restrict-width="false" @close="toggleAutomationModal(false)">
                    <template v-slot:header>
                        Automation Parameters for {{ automationCampaignName }}
                    </template>
                    <template v-slot:content>
                        <div class="flex flex-row justify-between items-center mb-5">
                            <div>
                                <span class="font-semibold text-sm" :class="[darkMode ? 'text-slate-200' : 'text-slate-900']">
                                    Location will be excluded if any of the following conditions applies
                                </span>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    @click="addAutomationParameter(automationCampaignIdx)"
                                    class="px-3 py-1 font-semibold text-sm h-9 inline-flex items-center rounded border border-cyan-400 text-cyan-400 hover:bg-cyan-400 hover:text-white">
                                    Add Condition
                                </button>
                            </div>
                        </div>
                        <div class="h-80 overflow-auto">
                            <div v-for="(parameter, parameterIdx) in campaigns.data[automationCampaignIdx].automation_parameters"
                                 :key="parameterIdx"
                                 class="grid grid-cols-12 gap-3 my-2">
                                <div class="col-span-2">
                                    <Dropdown :dark-mode="darkMode"
                                              :placeholder="'Type'"
                                              :options="automationTypeOptions"
                                              v-model="parameter.type"
                                              @change="handleChangeAutomationType(automationCampaignIdx, parameterIdx)" />
                                </div>
                                <div class="col-span-2">
                                    <Dropdown :dark-mode="darkMode"
                                              :disabled="!parameter.type?.length"
                                              v-model="parameter.strategy"
                                              @change="handleChangeAutomationStrategy(automationCampaignIdx, parameterIdx)"
                                              :placeholder="'Strategy'"
                                              :options="getAutomationStrategyOptions(parameter.type)" />
                                </div>
                                <div class="col-span-3">
                                    <Dropdown :dark-mode="darkMode"
                                              :disabled="!parameter.type?.length"
                                              :placeholder="'Parameter'"
                                              v-model="parameter.parameter"
                                              :options="getAutomationParameterOptions(parameter.type, parameter.strategy)" />
                                </div>
                                <div>
                                    <Dropdown :dark-mode="darkMode"
                                              :disabled="!parameter.type?.length"
                                              :placeholder="'op'"
                                              v-model="parameter.operator"
                                              :options="operatorOptions" />
                                </div>
                                <div>
                                    <input
                                        type="number"
                                        :disabled="!parameter.type?.length"
                                        v-model="parameter.threshold"
                                        :placeholder="'#'"
                                        min="0"
                                        step="1"
                                        class="p-2 rounded text-sm font-medium w-full h-9 border focus:outline-none outline-none focus:ring-0 focus:border-primary-500 focus:shadow-lg focus:shadow-primary-500/10"
                                        :class="{
                                            'text-grey-300 bg-grey-175 border-grey-200': !parameter.type?.length,
                                            'text-slate-100 bg-dark-background border-dark-border': parameter.type?.length && darkMode,
                                            'text-slate-500 bg-light-background border-light-border': parameter.type?.length && !darkMode,
                                        }">
                                </div>
                                <div class="col-span-2">
                                    <Dropdown :dark-mode="darkMode"
                                              v-model="parameter.threshold_type"
                                              :disabled="!parameter.type?.length"
                                              :placeholder="'Unit'"
                                              :options="getAutomationThresholdTypeOptions(parameter.type, parameter.strategy, parameter.parameter)" />
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        @click="removeAutomationParameter(automationCampaignIdx, parameterIdx)"
                                        class="px-3 py-1 font-semibold text-sm h-9 inline-flex items-center rounded border border-red-400 text-red-400 hover:bg-red-400 hover:text-white">
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </template>
                </modal>
            </div>
        </div>

        <account-management v-if="showAccountsModal" :dark-mode="darkMode" @close="toggleAccountsModal(false)" />
        <platform-authentication-management v-if="showPlatformAuthenticationModal" :dark-mode="darkMode" @close="togglePlatformAuthenticationModal(false)" />
    </div>
</template>

<script>
import AlertsContainer from "../Shared/components/AlertsContainer";
import LoadingSpinner from "../Shared/components/LoadingSpinner";
import Pagination from "../Shared/components/Pagination";
import AlertsMixin from '../../mixins/alerts-mixin';
import Api from './services/api';
import SharedApiService from "../Shared/services/api";
import Modal from "../Shared/components/Modal";
import Dropdown from "../Shared/components/Dropdown";
import AccountManagement from "./components/AccountManagement";
import ToggleSwitch from "../Shared/components/ToggleSwitch";
import PlatformAuthenticationManagement from "./components/PlatformAuthenticationManagement";
import {unslugify} from "../Shared/services/strings";

export default {
    name: "Advertising",
    components: {
        ToggleSwitch,
        Dropdown,
        AlertsContainer,
        LoadingSpinner,
        Pagination,
        Modal,
        AccountManagement,
        PlatformAuthenticationManagement
    },
    mixins: [
        AlertsMixin
    ],
    props: {
        darkMode: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            api: Api.make(),
            sharedApi: SharedApiService.make(),

            campaigns: null,

            selectedCampaigns: [],

            platform: '',
            accountId: '',
            state: 0,
            costMetric: 'all',
            campaignStatus: '',

            currentPlatform: '',
            currentAccountId: '',
            currentPage: null,
            currentState: null,
            currentCostMetric: null,
            currentCampaignStatus: null,
            pageTokens: null,
            perPage: 10,

            showLocationsModal: false,
            locationsCampaignName: '',
            campaignLocations: {},
            locationsModalTab: 'included',
            locationsModalTabs: [
                'included',
                'excluded'
            ],

            showPlatformAuthenticationModal: false,

            showAccountsModal: false,

            isAdvertisingAdmin: false,

            showAutomationModal: false,
            automationCampaignName: '',
            automationCampaignIdx: null,

            campaignStatusOptions: [],
            locationLockedOptions: [],
            parameterOptions: {},
            costMetricOptions: [],
            stateOptions: [],
            runIntervalOptions: [
                {id: 'hours', name: 'Hour(s)'},
                {id: 'weeks', name: 'Week(s)'}
            ],
            operatorOptions: [],
            platformOptions: [],
            accountOptions: [],

            unslugify: unslugify,

            loading: false,
            loadingAccounts: false,
            saving: false,
            initializing: 2
        };
    },
    computed: {
        automationTypeOptions: function() {
            return Object.keys(this.parameterOptions).map(type => {
                return {
                    id: type,
                    name: this.unslugify(type, '_')
                };
            });
        }
    },
    created: function () {
        this.initReferenceLists();
    },
    methods: {
        initReferenceLists() {
            this.api.initReferenceLists().then(res => {
                if(res.data.data.status === true) {
                    this.parameterOptions = res.data.data.parameter_options;
                    this.isAdvertisingAdmin = res.data.data.is_advertising_admin;
                    this.operatorOptions = res.data.data.operators;

                    this.locationLockedOptions = Object.entries(res.data.data.location_locked_options).map(([id, name]) => {
                        return {
                            id: Number(id),
                            name: name
                        };
                    });

                    this.platformOptions = res.data.data.platforms.map((platform) => {
                        return {
                            id: platform,
                            name: this.unslugify(platform, '_')
                        }
                    });

                    this.costMetricOptions = res.data.data.cost_metric_options.map((metric) => {
                        return {
                            id: metric,
                            name: metric
                        };
                    });

                    this.costMetricOptions.unshift({
                        id: 'all',
                        name: 'TCPA & CPC'
                    });

                    this.stateOptions = res.data.data.states.map((state) => {
                        return {
                            id: state.num_id,
                            name: state.name
                        };
                    });

                    this.stateOptions.unshift({
                        id: 0,
                        name: 'All States'
                    });

                    this.initializing = 0;
                }
                else {
                    this.showAlert('error', 'Failed to initialize');

                    this.initializing = 1;
                }
            }).catch(err => {
                this.showAlert('error', 'Initialization error');

                this.initializing = 1;
            })
        },
        handleChangePlatform(platform) {
            this.getPlatformReferenceLists(platform);
            this.getPlatformSearchAccounts(platform);
        },
        getPlatformSearchAccounts(platform) {
            this.loadingAccounts = true;
            this.accountOptions = [];
            this.accountId = '';

            return this.api.getPlatformAccounts(platform).then(res => {
                if(res.data.data.status === true) {
                    this.accountOptions = res.data.data.accounts.map((account) => {
                        return {
                            id: account.platform_account_id,
                            name: account.name
                        }
                    });
                }
                else {
                    this.showAlert('error', 'Failed to retrieve platform accounts');
                }
            }).catch(err => {
                this.showAlert('error', 'Error retrieving platform accounts');
            }).finally(() => {
                this.loadingAccounts = false;
            })
        },
        getPlatformReferenceLists(platform) {
            return this.api.getPlatformReferenceLists(platform).then(res => {
                if(res.data.data.status === true) {
                    this.campaignStatusOptions = Object.entries(res.data.data.campaign_statuses).map(([id, name]) => {
                        return {
                            id: id,
                            name: name
                        };
                    });

                    this.campaignStatusOptions.unshift({
                        id: 'all',
                        name: 'All Statuses'
                    });

                    this.campaignStatus = 'all';
                }
                else {
                    this.showAlert('error', 'Failed to retrieve platform reference lists');
                }
            }).catch(err => {
                this.showAlert('error', 'Error retrieving platform reference lists');
            })
        },
        getCampaigns(platform, accountId, page = 1, perPage = 10, state = null, costMetric = null, campaignStatus = null, pageTokens = null) {
            if (!platform
            || !accountId) {
                this.showAlert('error', 'Missing platform or account ID');
                return;
            }

            this.loading = true;

            this.clearSelectedCampaigns();

            this.currentPlatform = platform;
            this.currentAccountId = accountId;
            this.currentState = state;
            this.currentCostMetric = costMetric;
            this.currentCampaignStatus = campaignStatus;
            this.currentPage = page;

            this.api.getCampaignsPaginated(
                platform,
                accountId,
                page,
                perPage,
                state,
                costMetric,
                campaignStatus,
                pageTokens
            ).then(res => {
                if (res.data.data.status === true) {
                    this.campaigns = res.data.data.campaigns;
                    this.pageTokens = res.data.data.page_tokens;
                } else {
                    this.showAlert('error', res?.data?.data?.msg || "Problem loading campaigns");
                }
            }).catch(err => {
                this.showAlert('error', err.response?.data?.data?.msg || "Error loading campaigns");
            }).finally(() => {
                this.loading = false;
            });
        },
        updateCampaigns() {
            this.saving = true;

            const updateCampaigns = this.campaigns.data.filter((campaign) => {
                return this.selectedCampaigns.includes(campaign.platform_campaign_id);
            });

            this.api.updateCampaigns(
                this.currentPlatform,
                this.currentAccountId,
                updateCampaigns
            ).then(res => {
                if (res.data.data.status === true) {
                    this.showAlert('success', 'Campaign(s) updated');
                    this.clearSelectedCampaigns();
                    this.getCampaigns(this.currentPlatform, this.currentAccountId, this.currentPage, this.perPage, this.currentState, this.currentCostMetric, this.currentCampaignStatus, this.pageTokens);
                } else {
                    this.showAlert('error', res?.data?.data?.msg || "Problem updating campaigns");
                    this.loading = false;
                }
            }).catch(err => {
                this.showAlert('error', err.response?.data?.data?.msg || err.response?.data?.error || "Error updating campaigns");
                this.loading = false;
            }).finally(() => {
                this.saving = false;
            });
        },
        clearSelectedCampaigns() {
            this.selectedCampaigns = [];
        },
        toggleLocationsModal(show, campaignLocations = {}, campaignName = '') {
            this.showLocationsModal = !!show;
            this.setLocationsModalTab('included');

            if (this.showLocationsModal) {
                this.campaignLocations = campaignLocations;
                this.locationsCampaignName = campaignName;
            }
            else {
                this.campaignLocations = {};
                this.locationsCampaignName = '';
            }
        },
        setLocationsModalTab(tab) {
            if(this.locationsModalTabs.includes(tab)) {
                this.locationsModalTab = tab;
            }
        },
        toggleAccountsModal(show) {
            this.showAccountsModal = show && this.isAdvertisingAdmin;
        },
        togglePlatformAuthenticationModal(show) {
            this.showPlatformAuthenticationModal = show && this.isAdvertisingAdmin;
        },
        toggleAutomationModal(show, campaignName, campaignIdx) {
            if(show) {
                this.automationCampaignName = campaignName;
                this.automationCampaignIdx = campaignIdx;
                this.showAutomationModal = true;
            }
            else {
                this.automationCampaignName = '';
                this.automationCampaignIdx = null;
                this.showAutomationModal = false;
            }
        },
        getAutomationStrategyOptions(type) {
            if(!type) {
                return [];
            }

            return Object.keys(this.parameterOptions[type]).map(strategy => {
                return {
                    id: strategy,
                    name: this.unslugify(strategy, '_')
                }
            });
        },
        getAutomationParameterOptions(type, strategy) {
            if(!type || !strategy) {
                return [];
            }

            return Object.keys(this.parameterOptions[type][strategy]).map(parameter => {
                return {
                    id: parameter,
                    name: this.unslugify(parameter, '_')
                }
            });
        },
        getAutomationThresholdTypeOptions(type, strategy, parameter) {
            if(!type || !strategy || !parameter) {
                return [];
            }

            return this.parameterOptions[type][strategy][parameter].map(thresholdType => {
                return {
                    id: thresholdType,
                    name: this.unslugify(thresholdType, '_')
                }
            });
        },
        addAutomationParameter(campaignIdx) {
            this.campaigns.data[campaignIdx].automation_parameters.push({
                type: '',
                strategy: '',
                parameter: '',
                operator: '',
                threshold: null,
                threshold_type: ''
            });
        },
        removeAutomationParameter(campaignIdx, parameterIdx) {
            this.campaigns.data[campaignIdx].automation_parameters.splice(parameterIdx, 1);
        },
        handleChangeAutomationType(campaignIdx, parameterIdx) {
            this.campaigns.data[campaignIdx].automation_parameters[parameterIdx].strategy = '';
            this.campaigns.data[campaignIdx].automation_parameters[parameterIdx].parameter = '';
            this.campaigns.data[campaignIdx].automation_parameters[parameterIdx].operator = '';
            this.campaigns.data[campaignIdx].automation_parameters[parameterIdx].threshold = null;
            this.campaigns.data[campaignIdx].automation_parameters[parameterIdx].threshold_type = '';
        },
        handleChangeAutomationStrategy(campaignIdx, parameterIdx) {
            this.campaigns.data[campaignIdx].automation_parameters[parameterIdx].parameter = '';
            this.campaigns.data[campaignIdx].automation_parameters[parameterIdx].operator = '';
            this.campaigns.data[campaignIdx].automation_parameters[parameterIdx].threshold = null;
            this.campaigns.data[campaignIdx].automation_parameters[parameterIdx].threshold_type = '';
        }
    }
}
</script>
