<template>
    <div class="border rounded-md h-full"
         :class="{'bg-white border-grey-120': !darkMode, 'bg-dark-0 border-dark-175': darkMode}">
        <div class="px-5 pt-5 pb-1">
            <div class=" flex items-center justify-between">
                <h5 class="text-blue-550 text-sm uppercase font-medium leading-tight">
                    Companies Sold To</h5>
            </div>
            <div class="grid grid-cols-9 gap-x-3 mt-2">
                <p class="text-blue-400 uppercase text-xs col-span-2">Name</p>
                <p class="text-blue-400 uppercase text-xs col-span-2">Cost</p>
                <p class="text-blue-400 uppercase text-xs col-span-2">Charge/Deliv.</p>
                <p class="text-blue-400 uppercase text-xs col-span-3">Date</p>
            </div>
        </div>
        <div class="border-t border-b h-100 overflow-y-auto"
             :class="{'bg-grey-40  border-grey-120': !darkMode, 'bg-dark-background border-dark-175': darkMode, 'flex flex-col items-center justify-center': loading}">
            <div class="grid grid-cols-9 gap-x-3 border-b p-3 transition duration-200"
                 v-for="company in companies"
                 :class="{'text-grey-800 hover:bg-white border-grey-175': !darkMode, 'text-grey-200 hover:bg-dark-0 border-dark-175': darkMode}">
                    <div class="col-span-2 truncate">{{ company.company_name }}</div>
                    <div class="col-span-2">${{ company.cost }}</div>
                    <div class="col-span-2">{{ company.chargeable }} / {{ company.delivered }}</div>
                    <div class="col-span-3">{{ $filters.dateFromTimestamp(company.date) }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import SharedApiService from "../services/api";

export default {
    name: "CompaniesAlreadySoldTo",
    props: {
        leadId: {
            type: Number,
            default: null
        },
        darkMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            companies: [],
            api: SharedApiService.make()
        }
    },
    created() {
        if (this.leadId) this.getCompanies();
    },
    methods: {
        getCompanies() {
            this.api.getCompaniesSoldTo(this.leadId).then(resp => {
                this.companies = resp.data.data;
            });
        },
    }
}
</script>
