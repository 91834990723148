<template>
    <button v-if="disabled" disabled class="text-sm rounded-md inline-flex items-center px-5 py-2 h-9 font-semibold transition duration-200 cursor-not-allowed"
            :class="[darkMode ? 'bg-slate-700 text-slate-500' : 'text-slate-400 bg-slate-300']"
    >
        <span class="mr-1" v-if="icon">
            <slot name="icon">

            </slot>
        </span>
        <slot></slot>
    </button>
    <button v-else class="text-sm rounded-md inline-flex items-center px-5 py-2 h-9 font-semibold transition duration-200"
            :class="[
                color === 'primary' ? 'bg-primary-500 hover:bg-primary-600 text-white' : '',
                color === 'primary-outline' ? 'text-primary-500 border border-primary-500 bg-transparent hover:bg-primary-100 hover:bg-opacity-50' : '',
                color === 'green' ? 'bg-emerald-500 hover:bg-emerald-600 text-white' : '',
                color === 'green-outline' ? 'text-emerald-500 border border-emerald-500 bg-transparent hover:bg-emerald-100 hover:bg-opacity-50' : '',
                color === 'red' ? 'bg-rose-500 hover:bg-rose-600 text-white' : '',
                color === 'red-outline' ? 'text-rose-500 border border-rose-500 bg-transparent hover:bg-rose-100 hover:bg-opacity-50' : '',
                color === 'slate' ? 'bg-slate-600 hover:bg-slate-700 text-white' : '',
                color === 'slate-outline' ? 'text-slate-500 border border-slate-500 bg-transparent hover:bg-slate-500 hover:bg-opacity-25' : '',
                color === 'slate-light' ? (darkMode ? 'bg-slate-500 hover:bg-slate-600 text-white' : 'bg-slate-400 hover:bg-slate-500 text-white') : '',
                ]">
        <span class="mr-1" v-if="icon">
            <slot name="icon">

            </slot>
        </span>
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "CustomButton",
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: 'primary',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style scoped>

</style>
