<template>
    <div class="mt-5">
        <div class="grid grid-cols-1 gap-3">
            <div>
                <p class="uppercase font-semibold text-xs mb-1" :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                    TextBox Prompt
                </p>
                <input v-model="payload"
                       class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                       :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"
                       placeholder="Notification text" type="text" />

            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "TextBox",
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: Object
        }
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {

        }
    },
    computed: {
        payload: {
            get() {
                return this.modelValue?.text;
            },
            set(value) {
                this.$emit('update:modelValue', {'text': value});
            }
        }
    }
}
</script>

<style scoped>

</style>
