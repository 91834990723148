<template>
    <div class="border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="p-5 flex items-center justify-between">
            <h5 class="text-primary-500 text-sm uppercase font-semibold py-2 leading-tight">Queue List</h5>
        </div>
        <div class="grid grid-cols-5 gap-x-3 mb-2 px-5">
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-3">Name</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Status</p>
        </div>
        <div class="border-t border-b h-64 overflow-y-auto"
             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
            <div>
                <div class="grid grid-cols-5 gap-x-3 border-b px-5 py-3"
                     v-for="queue in queues" :key="queue.id"
                     :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <p class="text-sm col-span-3 truncate">
                        {{ queue.name }}
                    </p>
                    <p class="text-sm col-span-2 truncate">
                        {{ queue.status }}
                    </p>
                </div>
            </div>
        </div>
        <div class="p-3"></div>
    </div>
</template>

<script>

export default {
    name: "QueueList",
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        queues: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>

<style scoped>

</style>
