<template>
    <div class="h-80 flex items-center justify-center" v-if="loading">
        <loading-spinner></loading-spinner>
    </div>
    <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-10 py-5" :class="[darkMode ? 'text-slate-200' : 'text-slate-900']">
        <div class="border rounded-lg row-span-2"  :class="[darkMode ? 'bg-dark-module border-dark-border' : 'bg-light-module border-light-border']">
            <div>
                <div class="flex items-center justify-between p-5">
                    <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Basic Info</h5>
                    <!-- Disabling the edit option until the backend logic to sync this data with Legacy is finalized -->
                    <div v-if="false">
                        <div v-if="!editingBasicInfo" @click="editBasicInfo" class="w-4 p-1 cursor-pointer mr-5" >
                            <svg class="w-4"  viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.25 0L10.3125 1.95117L8.74019 3.43926L6.67769 1.48809L8.25 0ZM0 7.79688V9.74805H2.0625L7.76806 4.35827L5.70556 2.40709L0 7.79688ZM0 11.6992H11V13H0V11.6992Z" fill="#0081FF"/>
                            </svg>
                        </div>
                        <div v-else class="flex items-center">
                            <button v-if="editingBasicInfo" @click="saveBasicInfo" class="mr-3 text-white h-9 bg-emerald-500  hover:bg-emerald-600 mt-5 sm:mt-0 px-5 py-2 font-medium text-sm rounded-md inline-flex items-center justify-center">
                                Save
                            </button>
                            <button v-if="editingBasicInfo" @click="editBasicInfo" class="text-white h-9 bg-slate-400 hover:bg-slate-500 mt-5 sm:mt-0 px-5 py-2 font-medium text-sm rounded-md inline-flex items-center justify-center">
                                Cancel
                            </button>
                        </div>
                    </div>

                </div>

                <div class="grid lg:grid-cols-2 gap-5 px-5 pb-5">
                    <div>
                        <p class="font-semibold pb-1">Status</p>
                        <div v-if="!editingBasicInfo">
                            <p :class="[darkMode ? 'text-slate-400' : 'text-slate-700']">{{ company.status || placeholder.generic }}</p>
                        </div>
                        <Dropdown :dark-mode="darkMode" v-else :selected="company.status" :options="companyStatusOptions" v-model="basicInfoUpdateData.status"></Dropdown>
                    </div>
                    <div>
                        <p class="font-semibold pb-1">Date Registered</p>
                        <p :class="[darkMode ? 'text-slate-400' : 'text-slate-700']">{{ `${$filters.dateFromTimestamp(company.dateRegistered)}` || placeholder.generic }}</p>
                    </div>
                    <div>
                        <p class="font-semibold pb-1">Trading Name</p>
                        <p v-if="!editingBasicInfo" :class="[darkMode ? 'text-slate-400' : 'text-slate-700']">{{company.name || placeholder.generic}}</p>
                        <input v-else type="text" v-model="company.name" class="rounded border font-medium text-sm h-9 w-full" :class="[darkMode ? 'bg-dark-background border-dark-border text-slate-200' : 'bg-light-background border-light-border text-slate-900']" />
                    </div>
                    <div>
                        <p class="font-semibold pb-1">Entity Name</p>
                        <p v-if="!editingBasicInfo" :class="[darkMode ? 'text-slate-400' : 'text-slate-700']">{{company.entity_name || placeholder.generic}}</p>
                        <input v-else type="text" v-model="company.entity_name" class="rounded border font-medium text-sm h-9 w-full" :class="[darkMode ? 'bg-dark-background border-dark-border text-slate-200' : 'bg-light-background border-light-border text-slate-900']" />
                    </div>
                    <div>
                        <p class="font-semibold pb-1">Company ID</p>
                        <p :class="[darkMode ? 'text-slate-400' : 'text-slate-700']">{{company.id || placeholder.generic}}</p>
                    </div>
                    <div>
                        <p class="font-semibold pb-1">Legacy Company ID</p>
                        <a :href="legacyHref('companyProfile')" class="text-primary-500" target="_blank">
                            {{company.legacy_id}}
                        </a>
                    </div>
                    <div>
                        <p class="font-semibold pb-1">Company Services</p>
                        <div v-if="!editingBasicInfo">
                            <p v-if="!company.services?.length">{{ placeholder.generic }}</p>
                            <p v-else :class="[darkMode ? 'text-slate-400' : 'text-slate-700']" class="mb-2" v-for="service in company.services.map(a => a.name)">
                                {{ service }}
                            </p>
                        </div>
                        <multi-select class="w-full" v-else
                                      :options="industryServices"
                                      :dark-mode="darkMode"
                                      :text-place-holder="'Company Services'"
                                      :show-search-box="false"
                                      @input="basicInfoUpdateData.services = $event"
                                      :classes="'h-9 inline-flex items-center justify-between relative border-none font-medium transition duration-200'"
                                      :selected-ids="company.services.map(a => a.slug)"/>
                    </div>
                    <div>
                        <p class="font-semibold pb-1">Buying Leads</p>
                        <p :class="[darkMode ? 'text-slate-400' : 'text-slate-700']">{{company.status === 'Active' ? 'Active' : 'Inactive'}}</p>
                        <!--todo enable updating buying leads-->
<!--                        <input v-else type="text" :name="company.buyingLeads" v-model="company.buyingLeads" class="rounded border font-medium text-sm h-9 w-full" :class="[darkMode ? 'bg-dark-background border-dark-border text-slate-200' : 'bg-light-background border-light-border text-slate-900']" />-->
<!--                        <Dropdown :dark-mode="darkMode" v-else :placeholder="company.buyingLeads ? 'Yes' : 'No'" :options="['Yes', 'No']" :value="company.buyingLeads"></Dropdown>-->
                    </div>
                    <div>
                        <p class="font-semibold pb-1">License Status</p>
                        <div>
                            <p v-if="!company.licenses?.length">{{ placeholder.generic }}</p>
                            <p v-else :class="[darkMode ? 'text-slate-400' : 'text-slate-700']" class="mb-2" v-for="license in company.licenses">
                               - {{ license.license }}
                            </p>
                        </div>
                        <!--todo enable saving licence information -->
<!--                        <Dropdown :dark-mode="darkMode" v-else :placeholder="company.licenseStatus === true ? 'Active' : 'Inactive'" :options="['Active', 'Inactive']"></Dropdown>-->
                    </div>
                </div>
            </div>
        </div>

        <Addresses :dark-mode="darkMode" :has-edit-rights="hasEditRights" :company-id="companyId"></Addresses>

        <div class="border rounded-lg"  :class="[darkMode ? 'bg-dark-module border-dark-border' : 'bg-light-module border-light-border']">
            <div>
                <div class="flex items-center justify-between p-5">
                    <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Profile Activity</h5>
                </div>
                <div class="grid lg:grid-cols-2 gap-5 px-5 pb-5">
                    <div>
                        <p class="font-semibold pb-1">Last Revised</p>
                        <p :class="[darkMode ? 'text-slate-400' : 'text-slate-700']">{{$filters.dateFromTimestamp(company.lastRevised) || placeholder.generic}}</p>
                    </div>
                    <div>
                        <p class="font-semibold pb-1">Last Changed</p>
                        <p :class="[darkMode ? 'text-slate-400' : 'text-slate-700']">{{$filters.dateFromTimestamp(company.lastChanged) || placeholder.generic}}</p>
                    </div>
                    <div>
                        <p class="font-semibold pb-1">Last Login</p>
                        <p :class="[darkMode ? 'text-slate-400' : 'text-slate-700']">{{$filters.dateFromTimestamp(company.lastLogin) || placeholder.generic}}</p>
                    </div>
                    <div>
                        <p class="font-semibold pb-1">Last Quote</p>
                        <p :class="[darkMode ? 'text-slate-400' : 'text-slate-700']">{{$filters.dateFromTimestamp(company.lastQuote) || placeholder.generic}}</p>
                    </div>
                    <div>
                        <p class="font-semibold pb-1">Last Review</p>
                        <p :class="[darkMode ? 'text-slate-400' : 'text-slate-700']">{{$filters.dateFromTimestamp(company.lastReview) || placeholder.generic}}</p>
                    </div>
                    <div>
                        <p class="font-semibold pb-1">Last Review</p>
                        <p :class="[darkMode ? 'text-slate-400' : 'text-slate-700']">{{$filters.dateFromTimestamp(company.lastReview) || placeholder.generic}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="border rounded-lg"  :class="[darkMode ? 'bg-dark-module border-dark-border' : 'bg-light-module border-light-border']">
            <div>
                <div class="flex items-center justify-between p-5">
                    <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Contact & Social Links</h5>
                    <!-- Disabling the edit option until the backend logic to sync this data with Legacy is finalized -->
                    <div v-if="false">
                        <div v-if="!editingSocialInfo" @click="editSocialInfo" class="w-4 p-1 cursor-pointer mr-5" >
                            <svg class="w-4"  viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.25 0L10.3125 1.95117L8.74019 3.43926L6.67769 1.48809L8.25 0ZM0 7.79688V9.74805H2.0625L7.76806 4.35827L5.70556 2.40709L0 7.79688ZM0 11.6992H11V13H0V11.6992Z" fill="#0081FF"/>
                            </svg>
                        </div>
                        <div v-else class="flex items-center">
                            <button v-if="editingSocialInfo" @click="editSocialInfo" class="mr-3 text-white h-9 bg-emerald-500  hover:bg-emerald-600 mt-5 sm:mt-0 px-5 py-2 font-medium text-sm rounded-md inline-flex items-center justify-center">
                                Save
                            </button>
                            <button v-if="editingSocialInfo" @click="editSocialInfo" class="text-white h-9 bg-slate-400 hover:bg-slate-500 mt-5 sm:mt-0 px-5 py-2 font-medium text-sm rounded-md inline-flex items-center justify-center">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
                <div class="grid lg:grid-cols-2 gap-5 px-5 pb-5">
                    <div>
                        <p class="font-semibold pb-1">Link to Profile</p>
                        <a target="_blank" class="text-primary-500" :href="company.profilePageLink">Click to open</a>
                    </div>
                    <div>
                        <p class="font-semibold pb-1">Website Link</p>
                        <p  v-if="!editingSocialInfo" :class="[darkMode ? 'text-slate-400' : 'text-slate-700']">{{company.website || placeholder.generic}}</p>
                        <input v-else type="text" :name="company.website" v-model="company.website" class="rounded border font-medium text-sm h-9 w-full" :class="[darkMode ? 'bg-dark-background border-dark-border text-slate-200' : 'bg-light-background border-light-border text-slate-900']" />
                    </div>
                    <div>
                        <p class="font-semibold pb-1">Social</p>
                        <div v-if="!editingSocialInfo" class="inline-flex items-center gap-x-6 mt-1">
                            <div v-for="link in company.socialMediaLinks">
                                <a v-if="link.linktype === 'twitter'" :href="link.linkvalue" target="_blank">
                                    <svg class="cursor-pointer w-4 h-4 flex-shrink-0" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.7671 2.24295C10.7751 2.34162 10.7751 2.43973 10.7751 2.53784C10.7751 5.54028 8.30022 9 3.77733 9C2.38388 9 1.08935 8.6273 0 7.98002C0.197842 8.00088 0.388357 8.00821 0.594138 8.00821C1.7012 8.01068 2.77691 7.66885 3.64787 7.03784C3.13459 7.02926 2.63703 6.87289 2.22465 6.59057C1.81227 6.30824 1.50567 5.91405 1.34765 5.46304C1.49969 5.4839 1.65235 5.498 1.81233 5.498C2.03277 5.498 2.25443 5.4698 2.46021 5.42075C1.90317 5.3169 1.40228 5.0381 1.04271 4.63176C0.683145 4.22542 0.487091 3.71663 0.487889 3.19189V3.1637C0.815795 3.33229 1.19621 3.43773 1.59923 3.45182C1.26161 3.24466 0.984767 2.96355 0.793409 2.63357C0.602051 2.3036 0.502125 1.93501 0.502544 1.56071C0.502544 1.13896 0.624059 0.752162 0.837167 0.414986C1.4552 1.11698 2.22603 1.69127 3.09974 2.10067C3.97344 2.51007 4.93053 2.74545 5.90902 2.79157C5.87116 2.62242 5.84795 2.44706 5.84795 2.27114C5.84779 1.97285 5.9113 1.67745 6.03485 1.40184C6.1584 1.12622 6.33957 0.875797 6.568 0.664872C6.79642 0.453948 7.06763 0.286662 7.36611 0.172579C7.6646 0.0584958 7.98451 -0.00014794 8.30755 2.80264e-07C9.01588 2.80264e-07 9.6552 0.274026 10.1046 0.717203C10.6553 0.61888 11.1833 0.433267 11.6654 0.168588C11.4818 0.693419 11.0973 1.13845 10.584 1.42031C11.0723 1.36888 11.5496 1.25029 12 1.06848C11.6636 1.52129 11.2468 1.91838 10.7671 2.24295V2.24295Z" fill="#0081FF"/>
                                    </svg>
                                </a>
                                <a v-else-if="link.linktype === 'linkedin'" :href="link.linkvalue" target="_blank">
                                    <svg class="cursor-pointer w-4 h-4 flex-shrink-0" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.32205 2.64411C2.05221 2.64411 2.64411 2.05221 2.64411 1.32205C2.64411 0.591904 2.05221 0 1.32205 0C0.591904 0 0 0.591904 0 1.32205C0 2.05221 0.591904 2.64411 1.32205 2.64411Z" fill="#0081FF"/>
                                        <path d="M3.89233 3.64593V10.9807H6.16967V7.35348C6.16967 6.39638 6.34973 5.4695 7.53644 5.4695C8.70684 5.4695 8.72134 6.56376 8.72134 7.41391V10.9813H10.9999V6.95892C10.9999 4.98309 10.5745 3.46466 8.26514 3.46466C7.15638 3.46466 6.41318 4.07312 6.10925 4.64895H6.07844V3.64593H3.89233V3.64593ZM0.181152 3.64593H2.46212V10.9807H0.181152V3.64593Z" fill="#0081FF"/>
                                    </svg>
                                </a>
                                <a v-else-if="link.linktype === 'facebook'" :href="link.linkvalue" target="_blank">
                                    <svg class="cursor-pointer w-4 h-4 flex-shrink-0" viewBox="0 0 5 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.24818 10V5.44595H4.72901L4.94912 3.66289H3.24818V2.52716C3.24818 2.01263 3.38635 1.66036 4.09812 1.66036H5V0.0706607C4.56118 0.0218702 4.1201 -0.00168805 3.67877 9.3996e-05C2.36986 9.3996e-05 1.47119 0.829113 1.47119 2.35102V3.65956H0V5.44262H1.4744V10H3.24818Z" fill="#0081FF"/>
                                    </svg>
                                </a>
                                <a v-else-if="link.linktype === 'youtube'" :href="link.linkvalue" target="_blank">
                                    <svg class="cursor-pointer w-4 h-4 flex-shrink-0" viewBox="0 0 29 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M28.0882 16.8181C27.7563 18.0417 26.7987 18.9999 25.5713 19.3272C23.9225 19.9617 7.9524 20.2727 3.31969 19.309C2.09221 18.9781 1.13101 18.0236 0.80271 16.7999C0.0585591 13.5454 0.00201823 6.50902 0.820949 3.18174C1.1529 1.95811 2.11045 0.999924 3.33793 0.672651C6.60271 -0.0764397 22.1241 -0.181894 25.5895 0.672651C26.817 1.00356 27.7782 1.95811 28.1065 3.18174C28.8999 6.7272 28.9564 13.3272 28.0882 16.8181Z" fill="#0081FF"/>
                                        <path d="M19.151 9.99985L11.7095 14.2544V5.7453L19.151 9.99985Z" fill="white"/>
                                    </svg>
                                </a>
                                <a v-else :title="link.linktype" :href="link.linkvalue" target="_blank">
                                    <svg class="cursor-pointer w-4 h-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" >
                                        <path fill="#0081FF" fill-rule="evenodd" d="M19.902 4.098a3.75 3.75 0 00-5.304 0l-4.5 4.5a3.75 3.75 0 001.035 6.037.75.75 0 01-.646 1.353 5.25 5.25 0 01-1.449-8.45l4.5-4.5a5.25 5.25 0 117.424 7.424l-1.757 1.757a.75.75 0 11-1.06-1.06l1.757-1.757a3.75 3.75 0 000-5.304zm-7.389 4.267a.75.75 0 011-.353 5.25 5.25 0 011.449 8.45l-4.5 4.5a5.25 5.25 0 11-7.424-7.424l1.757-1.757a.75.75 0 111.06 1.06l-1.757 1.757a3.75 3.75 0 105.304 5.304l4.5-4.5a3.75 3.75 0 00-1.035-6.037.75.75 0 01-.354-1z" clip-rule="evenodd" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div v-else>
                            <div class="mb-2">
                                <label class="block mb-1">Twitter</label>
                                <input type="text" :name="company.twitter" v-model="company.twitter" class="rounded border font-medium text-sm h-9 w-full" :class="[darkMode ? 'bg-dark-background border-dark-border text-slate-200' : 'bg-light-background border-light-border text-slate-900']" />
                            </div>
                            <div class="mb-2">
                                <label class="block mb-1">LinkedIn</label>
                                <input type="text" :name="company.linkedIn" v-model="company.linkedIn" class="rounded border font-medium text-sm h-9 w-full" :class="[darkMode ? 'bg-dark-background border-dark-border text-slate-200' : 'bg-light-background border-light-border text-slate-900']" />
                            </div>
                            <div class="mb-2">
                                <label class="block mb-1">Facebook</label>
                                <input type="text" :name="company.facebook" v-model="company.facebook" class="rounded border font-medium text-sm h-9 w-full" :class="[darkMode ? 'bg-dark-background border-dark-border text-slate-200' : 'bg-light-background border-light-border text-slate-900']" />
                            </div>
                            <div class="mb-2">
                                <label class="block mb-1">Youtube</label>
                                <input type="text" :name="company.youtube" v-model="company.youtube" class="rounded border font-medium text-sm h-9 w-full" :class="[darkMode ? 'bg-dark-background border-dark-border text-slate-200' : 'bg-light-background border-light-border text-slate-900']" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="border rounded-lg"  :class="[darkMode ? 'bg-dark-module border-dark-border' : 'bg-light-module border-light-border']">
            <div>
                <div class="flex items-center justify-between p-5">
                    <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Billing & Plan Details</h5>
                </div>
                <div class="grid lg:grid-cols-2 gap-5 px-5 pb-5">
                    <div>
                        <p class="font-semibold pb-1">Paying By</p>
                        <p :class="[darkMode ? 'text-slate-400' : 'text-slate-700']">
                            {{ company.payingBy ? `Paying By ${company.payingBy}` : placeholder.notSet }}
                        </p>
                        <a :href="legacyHref('generateInvoice')" v-if="company.payingBy === 'Invoice'" class="text-primary-500 text-sm" target="_blank">
                            Generate Invoice
                        </a>
                    </div>
                    <div>
                        <p class="font-semibold pb-1">Payment Source</p>
                        <p :class="[darkMode ? 'text-slate-400' : 'text-slate-700']">{{company.paymentSource || placeholder.notSet }}</p>
                    </div>
                    <div>
                        <p class="font-semibold pb-1">Date of Next Charge</p>
                        <p :class="[darkMode ? 'text-slate-400' : 'text-slate-700']">
                            {{ company.dateOfNextCharge ? $filters.dateFromTimestamp(company.dateOfNextCharge) : 'Not set' }}
                        </p>
                    </div>
                    <div>
                        <p class="font-semibold pb-1">Active Payment Gateway</p>
                        <p :class="[darkMode ? 'text-slate-400' : 'text-slate-700']">{{company.activePaymentGateway}}</p>
                    </div>
                    <div>
                        <p class="font-semibold pb-1">Payment Methods</p>
                        <a :href="legacyHref('paymentMethods')" class="text-primary-500 text-sm" target="_blank">
                            View Payment Methods
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="border rounded-lg"  :class="[darkMode ? 'bg-dark-module border-dark-border' : 'bg-light-module border-light-border']">
            <div class="flex items-center justify-between p-5">
                <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Media & Attachments</h5>
            </div>
            <div class="grid lg:grid-cols-2 gap-5 px-5 pb-5">
                <div>
                    <p class="font-semibold pb-1">Logo File (Square)</p>
                    <a v-if="company.logoFileSquare" target="_blank" :href="legacyHref('logoSquare')" class="text-primary-500">
                        {{ company.logoFileSquare }}
                    </a>
                    <p v-else>
                        {{ placeholder.generic }}
                    </p>
                </div>
                <div>
                    <p class="font-semibold pb-1">Logo File (Rectangle)</p>
                    <a v-if="company.logoFileRectangle" target="_blank" :href="legacyHref('logoRectangle')" class="text-primary-500">
                        {{ company.logoFileRectangle }}
                    </a>
                    <p v-else>
                        {{ placeholder.generic }}
                    </p>
                </div>
                <div class="lg:col-span-2">
                    <p class="font-semibold pb-1">Profile Media</p>
                    <a target="_blank" :href="legacyHref('profileMedia')" class="text-primary-500">
                        Media ({{ company.mediaCount }} files)
                    </a>
                </div>
                <div>
                    <p class="font-semibold pb-1">Attachments</p>
                    <a target="_blank" :href="legacyHref('attachments')" class="text-primary-500">
                        Attachment ({{ company.attachmentCount }} files)
                    </a>
                </div>
            </div>
        </div>
        <div class="border rounded-lg lg:col-span-2"  :class="[darkMode ? 'bg-dark-module border-dark-border' : 'bg-light-module border-light-border']">
            <div class="flex items-center justify-between p-5">
                <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Additional Information</h5>
                <!-- Disabling the edit option until the backend logic to sync this data with Legacy is finalized -->
                <div v-if="false">
                    <div v-if="!editingAdditionalInfo" @click="editAdditionalInfo" class="w-4 p-1 cursor-pointer mr-5" >
                        <svg class="w-4"  viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.25 0L10.3125 1.95117L8.74019 3.43926L6.67769 1.48809L8.25 0ZM0 7.79688V9.74805H2.0625L7.76806 4.35827L5.70556 2.40709L0 7.79688ZM0 11.6992H11V13H0V11.6992Z" fill="#0081FF"/>
                        </svg>
                    </div>
                    <div v-else class="flex items-center">
                        <button v-if="editingAdditionalInfo" @click="editAdditionalInfo" class="mr-3 text-white h-9 bg-emerald-500  hover:bg-emerald-600 mt-5 sm:mt-0 px-5 py-2 font-medium text-sm rounded-md inline-flex items-center justify-center">
                            Save
                        </button>
                        <button v-if="editingAdditionalInfo" @click="editAdditionalInfo" class="text-white h-9 bg-slate-400 hover:bg-slate-500 mt-5 sm:mt-0 px-5 py-2 font-medium text-sm rounded-md inline-flex items-center justify-center">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
            <div class="grid lg:grid-cols-6 gap-5 px-5 pb-5">
                <div>
                    <p class="font-semibold pb-1">Reviews</p>
                    <a :href="legacyHref('reviews')" class="text-primary-500 text-sm">Link to reviews</a>
                </div>
                <div>
                    <p class="font-semibold pb-1">Tickets</p>
                    <a :href="legacyHref('tickets')" class="text-primary-500 text-sm">Link to tickets</a>
                </div>
                <div>
                    <p class="font-semibold pb-1">Contract</p>
                    <p :class="[darkMode ? 'text-slate-400' : 'text-slate-700']" v-if="company.contractApproved">
                        Approved: {{ $filters.dateFromTimestamp(company.contractApproved) }}
                    </p>
                    <p v-else>{{ placeholder.generic }}</p>
                </div>
                <div>
                    <p class="font-semibold pb-1">Purchased Leads</p>
                    <a :href="''" class="text-primary-500 text-sm">Link to leads</a>
                </div>
                <div>
                    <p class="font-semibold pb-1">Expert Review</p>
                    <a :href="legacyHref('expertReviews')" class="text-primary-500 text-sm">Link to expert review</a>
                </div>
            </div>
        </div>
        <div class="col-span-full">
            <h3 class="text-xl font-semibold mb-1">Configurable Fields</h3>
            <alert class="flex items-center justify-center" :dark-mode="darkMode" v-if="alertActive" :alert-type="alertType" :text="alertText" />
        </div>
        <div v-for="(scope, scopeName) in company.companyFields" class="border rounded-lg"
             :class="[darkMode ? 'bg-dark-module border-dark-border' : 'bg-light-module border-light-border']">
            <div v-for="(scopeCategory, scopeCategoryName) in scope" class="p-5">
                <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight mb-4">{{ scopeCategoryName || scopeName }}</h5>
                <div v-for="(fields, categoryName) in scopeCategory" class="ml-2 mb-8">
                    <h6 class="text-primary-300 text-sm uppercase font-semibold leading-tight">{{ categoryName }}</h6>
                    <div v-for="field in fields" class="my-4 ml-2">
                        <div class="flex items-center">
                            <svg @click="editField(field.key)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="cursor-pointer bi bi-info-circle mr-2" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                            </svg>
                            <p class="font-semibold pb-1">{{ field.name }}</p>
                        </div>
                        <edit-field
                            v-if="editingField === field.key"
                            :dark-mode="darkMode"
                            @update-field="updateField"
                            @cancel-edit="cancelEdit"
                            :value="field.value"
                            :field-name="field.key"
                            :type="getFieldType(field.type)"
                            :options="booleanOptions"
                        />
                        <div v-else>
                            <p v-if="field.type === 'Boolean'" :class="[darkMode ? 'text-slate-400' : 'text-slate-700']">{{ formatBooleanValue(field.value) }}</p>
                            <p v-else :class="[darkMode ? 'text-slate-400' : 'text-slate-700']">{{ field.value }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Dropdown from "../../LeadProcessing/components/Dropdown";
import Addresses from "../../Shared/modules/Addresses";
import ApiService from "../services/api";
import LoadingSpinner from "../../Shared/components/LoadingSpinner";
import CompanyApiService from "../../Companies/services/api";
import EditField from "../../Shared/components/EditField";
import AlertsMixin from "../../../mixins/alerts-mixin";
import Alert from "../../Shared/components/Alert";
import MultiSelect from "../../Shared/components/MultiSelect";
import SharedApiService from "../../Shared/services/api";

const activeInactiveOptions = [
    'Active', 'Inactive'
];

const positiveNegativeOptions = [
    'Yes', 'No'
];

export default {
    name: "CompanyProfile",
    components: {
        Addresses,
        Dropdown,
        LoadingSpinner,
        EditField,
        Alert,
        MultiSelect
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        hasEditRights: {
            type: Boolean,
            default: false,
        },
        companyId: {
            type: Number,
            default: null
        },
        legacyCompanyId: {
            type: Number,
            default: null
        }
    },
    mixins: [
        AlertsMixin
    ],
    data() {
        return {
            api: ApiService.make(),
            sharedApiService: SharedApiService.make(),
            companyStatusOptions: [],
            industryServices: [],
            company: {},
            editingBasicInfo: false,
            editingSocialInfo: false,
            editingAdditionalInfo: false,
            adminBaseUrl: '',
            loading: false,
            apiService: CompanyApiService.make(),
            basicInfoUpdateData: {},
            booleanOptions: [
                {id: true, value: "Yes"},
                {id: false, value: "No" }
            ],
            editingField: null,
            messages: {
                updateFailed: 'Oops, the server error occurred while updating data.',
                updateSuccess: 'Hurrah, the update went through successfully.',
            },
            alertTimeOutLimit: 3000,
            placeholder: {
                generic: '-',
                notSet: 'Not set',
            }
        }
    },
    created() {
        if (this.companyId) {
            this.getCompanyProfileData();
            this.getCompanyServicesData();
            this.getCompanyStatusData();
        }
    },
    methods: {
        legacyHref(section) {
            switch(section) {
                case 'reviews': return `${this.adminBaseUrl}/review/review-search?company_name=${encodeURIComponent(this.company.tradingName)}&review_status=any&email_validation=any&limit=10`;
                case 'tickets': return `${this.adminBaseUrl}/tickets.php?ticketcompanyid=${this.legacyCompanyId}`;
                case 'expertReviews': return `${this.adminBaseUrl}/companies/${this.legacyCompanyId}/expert-reviews`;
                case 'generateInvoice': return `${this.adminBaseUrl}/company.php?getcompanyid=${this.legacyCompanyId}&action=generateinvoice`;
                case 'attachments': return `${this.adminBaseUrl}/companies/${this.legacyCompanyId}/attachments`;
                case 'media': return `${this.adminBaseUrl}/companymedia.php?getcompanyid=${this.legacyCompanyId}`;
                case 'logoRectangle': return `${this.adminBaseUrl}/companies/${this.legacyCompanyId}/logo-rectangle`;
                case 'paymentMethods': return `${this.adminBaseUrl}/company.php?getcompanyid=${this.legacyCompanyId}`;
                case 'logoSquare': return `${this.adminBaseUrl}/company.php?getcompanyid=${this.legacyCompanyId}&action=logo`;
                case 'profileMedia': return `${this.adminBaseUrl}/companymedia.php?getcompanyid=${this.legacyCompanyId}`;
                case 'companyProfile': return `${this.adminBaseUrl}/company.php?getcompanyid=${this.legacyCompanyId}`;
                default: return ``;
            }
        },
        async saveBasicInfo() {
            this.basicInfoUpdateData.name = this.company.name;
            this.basicInfoUpdateData.entity_name = this.company.entity_name;
            const resp = await this.apiService.updateCompanyDetails(this.companyId, this.basicInfoUpdateData);
            if (resp.data.data.status === true) {
                this.$emit('activate-alert', 'success', 'Company profile updated');
                this.getCompanyProfileData();
                this.basicInfoUpdateData = {};
                this.editBasicInfo();
            } else {
                this.$emit('activate-alert', {
                    type: 'error',
                    text: resp.data.data.msg || 'Could not update company information'
                });
            }
        },
        editBasicInfo() {
            this.editingBasicInfo = ! this.editingBasicInfo;
        },
        editSocialInfo() {
            this.editingSocialInfo = ! this.editingSocialInfo;
        },
        editAdditionalInfo() {
            this.editingAdditionalInfo = ! this.editingAdditionalInfo;
        },
        getCompanyProfileData() {
            this.loading = true;
            this.api.getCompanyProfileData(this.companyId).then(resp => {
                this.company = resp.data.data.company;
                this.adminBaseUrl = resp.data.data.adminUrl;
            }).finally(() => this.loading = false);
        },
        async getCompanyServicesData() {
            this.sharedApiService.getIndustryServices().then((res) => {
                if(res.data.data.status === true) {
                    this.industryServices =  res.data.data.industry_services;
                }
            });
        },
        async getCompanyStatusData() {
            this.sharedApiService.getCompanyStatuses().then((res) => {
                Object.entries(res.data.data.company_statuses).forEach(([key, label]) => {
                    this.companyStatusOptions.push({
                        id: key,
                        name: label
                    })
                });
            })
        },
        formatBooleanValue(value) {
            if (value === null) return null;

            return value ? 'Yes' : 'No';
        },
        getFieldType(type) {
            return type === 'Boolean'
                ? 'select'
                : 'text';
        },
        editField(field) {
            this.editingField = field;
        },
        updateField(field, value) {
            this.apiService.updateCompanyConfigurableFields(this.companyId, {[field]: value}).then(resp => {
                if (resp.data.data.status === true) {
                    this.showAlert('success', this.messages.updateSuccess, this.alertTimeOutLimit);
                    this.company['companyFields'] = resp.data.data.updatedFields;
                    return;
                }
                this.showAlert('error', this.messages.updateFailed, this.alertTimeOutLimit);
            })
            .catch((e) => this.showAlert('error', e.response.data.message, this.alertTimeOutLimit))
            .finally(() => this.editingField = null);
        },
        cancelEdit() {
            this.editingField = null;
        }
    }
}
</script>

<style scoped>

</style>
