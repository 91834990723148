<template>
    <div class="relative w-full">
        <div @click="toggleDropdown"
             :class="[dropdownActive ? (darkMode ? 'bg-dark-module border-primary-500 shadow-lg shadow-primary-500/10' : 'bg-light-module border-primary-500 shadow-lg shadow-primary-500/10') : (darkMode ? 'border-dark-border' : 'border-light-border'), darkMode ? ('hover:bg-dark-module text-slate-200 bg-dark-background') : 'hover:bg-light-module text-slate-700 bg-light-background', disabled ? 'opacity-25' : '']"
             class="z-30 cursor-pointer capitalize text-sm font-medium transition duration-100 w-full border flex justify-between items-center rounded py-2 px-3 h-9">
            <p class="leading-5">{{  payloadName ? payloadName : placeholder }}</p>
            <svg
                :class="{
                    'text-grey-550': disabled,
                    'text-blue-550': !disabled,
                    'rotate-180': dropdownActive
                }"
                class="fill-current w-6 transform transition-all duration-200"
                width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2071 0.792893C12.5976 1.18342 12.5976 1.81658 12.2071 2.20711L7.20711 7.20711C6.81658 7.59763 6.18342 7.59763 5.79289 7.20711L0.792893 2.20711C0.402368 1.81658 0.402369 1.18342 0.792893 0.792893C1.18342 0.402368 1.81658 0.402368 2.20711 0.792893L6.5 5.08579L10.7929 0.792893C11.1834 0.402369 11.8166 0.402369 12.2071 0.792893Z"/>
            </svg>

            <div v-if="dropdownActive" class="absolute left-0 divide-y divide-inherit z-50 overflow-y-auto mt-10 rounded top-0 w-full border shadow-module"
                 :class="[darkMode ? 'border-dark-border bg-dark-background' : 'border-light-border bg-light-background', maxHeight]">
                <p
                    v-for="option in options"
                    :class="{'hover:bg-light-module': !darkMode, 'hover:bg-dark-module': darkMode}"
                    class="py-3 px-3 capitalize text-sm font-medium transition duration-200"
                    @click="() => selectOption(option)"
                    :key="option.id ? option.id : option">
                    {{ option.name ? option.name : option }}
                </p>
            </div>

        </div>
        <div v-if="dropdownActive" @click="toggleDropdown" class="fixed inset-0 z-10"></div>
    </div>

</template>

<script>
export default {
    name: "Dropdown",
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: 'All'
        },
        modelValue: {
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        maxHeight: {
            type: String,
            default: 'max-h-56'
        }
    },
    data() {
        return {
            dropdownActive: false,
        }
    },
    computed: {
        payload: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value);
                this.$emit('input', value);
            }
        },
        payloadName() {
            const item = this.options.find(item => item === this.payload || item.id === this.payload);

            return item ? (item.name ? item.name : item) : this.payload;
        }
    },
    emits: [
        'update:modelValue',
        'change',
        'input'
    ],
    methods: {
        toggleDropdown() {
            if(this.disabled) {
                this.dropdownActive = false;
            }
            else {
                this.dropdownActive = ! this.dropdownActive
            }
        },

        selectOption(option) {
            const id = option.id;

            if((
                !id
                && id !== 0
                && option !== this.payload
            )
            || id !== this.payload?.id) {
                this.$emit('change', option);
            }

            this.payload = id || (id === 0 ? id : option);
        }
    }
}
</script>

<style scoped>

</style>
