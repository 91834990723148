<template>
    <div v-if="callActive" class="absolute right-0 bottom-0 z-30"
         :class="{'mr-6 mb-4' : minimizeCall, 'mr-0 mb-0' : !minimizeCall}">
        <div class="transition-all transform duration-400"
             :class="{'right-0 bottom-0 left-full top-full' : minimizeCall, 'fixed z-50 inset-0 flex items-center justify-center bg-opacity-75 bg-dark-background' : !minimizeCall}">
            <div class="w-full  mx-4 md:mx-0 shadow rounded-lg transform transition-all duration-500"
                 :class="[modalClasses, minimizeCall ? 'w-80' : 'md:w-3/4 md:max-w-screen-sm']">
                <div class="flex items-center justify-between px-6 py-4"
                     :class="modalHeaderClasses">
                    <h4 v-if="!minimizeCall" class="text-xl">Outgoing Call</h4>
                    <h5 v-if="minimizeCall" class="text-sm uppercase font-medium">Outgoing call - {{ status }}</h5>
                    <svg v-if="!minimizeCall" @click="toggleMinimizeCall" class="cursor-pointer fill-current" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.2929 0.292893C18.6834 -0.0976311 19.3166 -0.0976311 19.7071 0.292893C20.0976 0.683417 20.0976 1.31658 19.7071 1.70711L14.4142 7H18C18.5523 7 19 7.44772 19 8C19 8.55228 18.5523 9 18 9H12C11.4477 9 11 8.55228 11 8V2C11 1.44772 11.4477 1 12 1C12.5523 1 13 1.44772 13 2V5.58579L18.2929 0.292893ZM1 12C1 11.4477 1.44772 11 2 11H8C8.55228 11 9 11.4477 9 12V18C9 18.5523 8.55228 19 8 19C7.44772 19 7 18.5523 7 18V14.4142L1.70711 19.7071C1.31658 20.0976 0.683417 20.0976 0.292893 19.7071C-0.0976311 19.3166 -0.0976311 18.6834 0.292893 18.2929L5.58579 13H2C1.44772 13 1 12.5523 1 12Z"/></svg>
                    <svg v-if="minimizeCall" @click="toggleMinimizeCall" class="cursor-pointer" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 1C0 0.447715 0.447715 0 1 0H5C5.55228 0 6 0.447715 6 1C6 1.55228 5.55228 2 5 2H3.41421L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711C6.31658 7.09763 5.68342 7.09763 5.29289 6.70711L2 3.41421V5C2 5.55228 1.55228 6 1 6C0.447715 6 0 5.55228 0 5V1ZM11.2929 11.2929C11.6834 10.9024 12.3166 10.9024 12.7071 11.2929L16 14.5858V13C16 12.4477 16.4477 12 17 12C17.5523 12 18 12.4477 18 13V17C18 17.5523 17.5523 18 17 18H13C12.4477 18 12 17.5523 12 17C12 16.4477 12.4477 16 13 16H14.5858L11.2929 12.7071C10.9024 12.3166 10.9024 11.6834 11.2929 11.2929Z" fill="white"/></svg>
                </div>
                <div>
                    <div class="flex justify-center">
                        <div
                            :class="{'mt-0' : minimizeCall, 'mt-6' : !minimizeCall}">
                            <h3 class="text-center font-medium mb-1"
                                :class="modalNameTextClasses"
                            >{{ contactName }}</h3>
                            <h3 v-if="!minimizeCall"
                                class="text-2xl text-center font-medium text-blue-550"
                                :class="{'mb-2': ['company', 'legacy-company'].includes(relationType) && relationId !== null, 'mb-6': !['company', 'legacy-company'].includes(relationType) || relationId === null}"
                            >{{ formattedNumber }}</h3>
                            <p v-if="!minimizeCall && relationType === 'company' && relationId !== null"
                               class="mb-4 text-center cursor-pointer text-blue-550 text-sm"
                               @click="openCompanyPage"
                            >
                                Open Company Page
                            </p>
                            <p v-if="!minimizeCall && relationType === 'legacy-company' && relationId !== null"
                               class="mb-4 text-center cursor-pointer text-blue-550 text-sm"
                               @click="openLegacyCompanyPage"
                            >
                                Open Legacy Company Page
                            </p>
                            <p v-if="comment" class="text-white text-base text-center">#{{ comment }}</p>
                            <div v-if="!minimizeCall" class="flex justify-center items-center mb-2">
                                <div class="w-24 h-16">
                                    <div v-if="status === 'Answered'" class="flex flex-auto justify-between items-center h-full">
                                        <div class="connected-1 w-1 h-1 rounded-full bg-primary-500"></div>
                                        <div class="connected-2 w-1 h-1 rounded-full bg-primary-500"></div>
                                        <div class="connected-3 w-1 h-1 rounded-full bg-primary-500"></div>
                                        <div class="connected-4 w-1 h-1 rounded-full bg-primary-500"></div>
                                        <div class="connected-5 w-1 h-1 rounded-full bg-primary-500"></div>
                                    </div>
                                    <div v-else class="flex flex-auto justify-between items-center h-full">
                                        <div class="connecting-1 w-1 h-1 rounded-full bg-primary-500"></div>
                                        <div class="connecting-2 w-1 h-1 rounded-full bg-primary-500"></div>
                                        <div class="connecting-3 w-1 h-1 rounded-full bg-primary-500"></div>
                                        <div class="connecting-4 w-1 h-1 rounded-full bg-primary-500"></div>
                                        <div class="connecting-5 w-1 h-1 rounded-full bg-primary-500"></div>
                                    </div>
                                </div>
                                <svg class="mx-6" width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M55.0744 46.2455L46.267 38.2376C45.8506 37.8592 45.3036 37.6574 44.7412 37.6748C44.1789 37.6921 43.6453 37.9274 43.2532 38.3308L38.0684 43.6628C36.8204 43.4245 34.3114 42.6424 31.7287 40.0662C29.1461 37.4814 28.3639 34.966 28.1321 33.7267L33.4599 28.5397C33.8638 28.1478 34.0993 27.6142 34.1167 27.0517C34.1341 26.4892 33.932 25.942 33.5531 25.5259L25.5473 16.7207C25.1682 16.3033 24.6413 16.0502 24.0786 16.015C23.5159 15.9798 22.9616 16.1653 22.5335 16.5322L17.8318 20.5643C17.4572 20.9403 17.2336 21.4406 17.2035 21.9705C17.171 22.5121 16.5513 35.343 26.5006 45.2965C35.1802 53.9739 46.0525 54.6087 49.0468 54.6087C49.4845 54.6087 49.7531 54.5957 49.8246 54.5914C50.3544 54.5617 50.8545 54.3371 51.2286 53.9609L55.2586 49.2571C55.6269 48.8303 55.8139 48.2765 55.7795 47.7138C55.745 47.1511 55.492 46.6242 55.0744 46.2455V46.2455Z" fill="#0081FF"/></svg>
                                <div class="w-24 h-16">
                                    <div v-if="status === 'Answered'" class="flex flex-auto justify-between items-center h-full">
                                        <div class="connected-6 w-1 h-1 rounded-full bg-primary-500"></div>
                                        <div class="connected-7 w-1 h-1 rounded-full bg-primary-500"></div>
                                        <div class="connected-8 w-1 h-1 rounded-full bg-primary-500"></div>
                                        <div class="connected-9 w-1 h-1 rounded-full bg-primary-500"></div>
                                        <div class="connected-10 w-1 h-1 rounded-full bg-primary-500"></div>
                                    </div>
                                    <div v-else class="flex flex-auto justify-between items-center h-full">
                                        <div class="connecting-6 w-1 h-1 rounded-full bg-primary-500"></div>
                                        <div class="connecting-7 w-1 h-1 rounded-full bg-primary-500"></div>
                                        <div class="connecting-8 w-1 h-1 rounded-full bg-primary-500"></div>
                                        <div class="connecting-9 w-1 h-1 rounded-full bg-primary-500"></div>
                                        <div class="connecting-10 w-1 h-1 rounded-full bg-primary-500"></div>
                                    </div>
                                </div>
                            </div>
                            <h3 v-if="!minimizeCall" class="text-center pt-2">
                                <span v-if="status === 'Answered'" class="text-lg font-medium text-green-400">Answered</span>
                                <span v-else-if="status === 'Setup'" class="text-lg font-medium text-blue-550">{{ status }}...</span>
                                <span v-else class="text-lg font-medium text-blue-550">{{ status }}</span>
                            </h3>
                        </div>
                    </div>
                    <div class=" flex items-center justify-between"
                         :class="{'py-6 mx-6' : minimizeCall, 'py-10 mx-4 md:mx-16' : !minimizeCall}">
                        <div>
                            <div @click="toggleMute" class="cursor-pointer transition duration-200 rounded-full h-16 w-16 inline-flex items-center justify-center"
                                 :class="muteButtonClasses">
                                <svg class="fill-current" width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.68416 5.12816C8.68416 3.96298 9.0464 2.84553 9.69119 2.02163C10.336 1.19773 11.2105 0.734863 12.1224 0.734863C13.0343 0.734863 13.9088 1.19773 14.5536 2.02163C15.1984 2.84553 15.5606 3.96298 15.5606 5.12816V10.9859C15.5606 12.1511 15.1984 13.2685 14.5536 14.0924C13.9088 14.9163 13.0343 15.3792 12.1224 15.3792C11.2105 15.3792 10.336 14.9163 9.69119 14.0924C9.0464 13.2685 8.68416 12.1511 8.68416 10.9859V5.12816ZM13.2685 21.1344C15.1781 20.7819 16.9243 19.5619 18.1872 17.6981C19.4502 15.8342 20.1452 13.4513 20.1449 10.9859C20.1449 10.5975 20.0242 10.225 19.8092 9.95037C19.5943 9.67574 19.3028 9.52145 18.9988 9.52145C18.6949 9.52145 18.4034 9.67574 18.1884 9.95037C17.9735 10.225 17.8528 10.5975 17.8528 10.9859C17.8528 12.9278 17.249 14.7903 16.1744 16.1634C15.0997 17.5366 13.6422 18.308 12.1224 18.308C10.6026 18.308 9.14505 17.5366 8.0704 16.1634C6.99574 14.7903 6.39201 12.9278 6.39201 10.9859C6.39201 10.5975 6.27126 10.225 6.05633 9.95037C5.8414 9.67574 5.54989 9.52145 5.24593 9.52145C4.94197 9.52145 4.65046 9.67574 4.43553 9.95037C4.2206 10.225 4.09985 10.5975 4.09985 10.9859C4.0996 13.4513 4.79461 15.8342 6.05753 17.6981C7.32045 19.5619 9.0667 20.7819 10.9763 21.1344V24.1658H7.53808C7.23412 24.1658 6.94262 24.32 6.72768 24.5947C6.51275 24.8693 6.39201 25.2418 6.39201 25.6302C6.39201 26.0186 6.51275 26.3911 6.72768 26.6657C6.94262 26.9403 7.23412 27.0946 7.53808 27.0946H16.7067C17.0107 27.0946 17.3022 26.9403 17.5171 26.6657C17.732 26.3911 17.8528 26.0186 17.8528 25.6302C17.8528 25.2418 17.732 24.8693 17.5171 24.5947C17.3022 24.32 17.0107 24.1658 16.7067 24.1658H13.2685V21.1344Z"/>
                                    <path d="M0.92041 25.1006L22.3046 1.29475L23.7924 2.63126L2.40827 26.4371L0.92041 25.1006Z"/>
                                </svg>
                            </div>
                            <p class="text-center font-medium mt-1"
                               :class="modalButtonTextClasses">
                                Mute<span v-if="muted">d</span>
                            </p>
                        </div>
                        <div>
                            <div @click="toggleHold" class="cursor-pointer transition duration-200 rounded-full h-16 w-16 inline-flex items-center justify-center"
                                 :class="holdButtonClasses">
                                <svg class="fill-current" width="17" height="25" viewBox="0 0 17 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0H5V25H0V0Z"/><path d="M12 0H17V25H12V0Z"/></svg>
                            </div>
                            <p class="text-center font-medium mt-1"
                               :class="modalButtonTextClasses">
                                <span v-if="onHold">On </span>
                                Hold
                            </p>
                        </div>
                        <div v-if="!minimizeCall" class="relative">
                            <dialer
                                :block-display="true"
                                @key-pressed="onDialerKeyPressed"
                                :onlyShowKeys="true"
                                :dark-mode="darkMode"
                            />
                        </div>
                        <div>
                            <div @click="endCall" class="cursor-pointer bg-red-450 hover:bg-red-500 transition duration-200 rounded-full h-16 w-16 inline-flex items-center justify-center">
                                <svg width="37" height="16" viewBox="0 0 37 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.81354 15.5665L11.7471 15.1417C12.1693 15.1215 12.5672 14.9381 12.8567 14.6301C13.1462 14.3221 13.3047 13.9137 13.2988 13.491L13.2205 7.90365C14.0101 7.36724 15.7586 6.44982 18.4994 6.44636C21.2447 6.4475 22.9966 7.36836 23.7782 7.90361L23.7034 13.4898C23.697 13.9126 23.8554 14.3213 24.145 14.6293C24.4346 14.9374 24.8327 15.1207 25.255 15.1405L34.1863 15.5652C34.6094 15.5855 35.0238 15.4401 35.3415 15.1599C35.6592 14.8796 35.8551 14.4865 35.8876 14.0642L36.2433 9.42414C36.2426 9.0254 36.0955 8.64079 35.8301 8.34327C35.5596 8.03824 29.0721 0.892343 18.4982 0.890085C9.27692 0.891273 3.1635 6.33016 1.5727 7.92097C1.34018 8.15349 1.20435 8.30313 1.16867 8.34341C0.902967 8.64063 0.756595 9.02562 0.757728 9.42428L1.11569 14.0643C1.14672 14.4867 1.34166 14.8803 1.65888 15.1609C1.97611 15.4416 2.39048 15.5871 2.81354 15.5665V15.5665Z" fill="white"/>
                                </svg>
                            </div>
                            <p class="text-center font-medium mt-1"
                               :class="modalButtonTextClasses">
                                End Call
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import Dialer from "./Dialer";
import DispatchesGlobalEventsMixin from "../../../mixins/dispatches-global-events-mixin";
import LegacyAdminMixin from "../../Shared/mixins/legacy-admin-mixin";
import {formatPhoneNumber} from "../../../../modules/contacts/helpers";

export default {
    name: "OutgoingCall",
    components: {Dialer},
    mixins: [DispatchesGlobalEventsMixin, LegacyAdminMixin],
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        minimizeCall: {
            type: Boolean,
            default: false,
        },
        callActive: {
            type: Boolean,
            default: false,
        },
        muted: {
            type: Boolean,
            default: false,
        },
        onHold: {
            type: Boolean,
            default: false,
        },
        comment: {
            default: null,
        },
        contactName: {
            type: String,
            default: null
        },
        contactPhone: {
            type: String,
            default: null
        },
        status: {
            type: String,
            default: "Connecting"
        },
        relationType: {
            type: String,
            default: null
        },
        relationId: {
            type: Number,
            default: null
        }
    },
    methods: {
        toggleMinimizeCall() {
            this.$emit('minimize-call');
        },
        endCall() {
            this.$emit('end-call');
        },
        toggleMute() {
            this.$emit('muted');
        },
        toggleHold() {
            this.$emit('on-hold');
        },
        onDialerKeyPressed(key)
        {
            this.$emit('key-pressed', key)
        },
        openCompanyPage()
        {
            if(this.relationType !== 'company') return;

            if(!this.minimizeCall)
                this.toggleMinimizeCall();

            this.dispatchGlobalEvent('load-company-page', {companyId: this.relationId})
        },
        openLegacyCompanyPage()
        {
            if(this.relationType !== 'legacy-company') return;

            window.open(this.getLegacyAdminCompanyUrl(this.relationType), "_blank");
        }
    },
    computed: {
        modalClasses() {
            const classes = [];

            if(this.minimizeCall) {
                if(this.darkMode) {
                    classes.push('bg-primary-500');
                } else {
                    classes.push('bg-primary-500');
                }
            } else {
                if(this.darkMode) {
                    classes.push('bg-dark-background', 'border', 'border-primary-500', 'overflow-hidden');
                } else {
                    classes.push('bg-light-module', 'border', 'border-light-border', 'overflow-hidden');
                }
            }
            return classes;
        },
        modalHeaderClasses() {
            const classes = [];

            if(this.minimizeCall) {
                if(this.darkMode) {
                    classes.push('text-white');
                } else {
                    classes.push('text-white');
                }
            } else {
                if(this.darkMode) {
                    classes.push('text-white', 'border-b', 'border-dark-border');
                } else {
                    classes.push('text-grey-900', 'border-b', 'border-light-border');
                }
            }
            return classes;
        },
        modalNameTextClasses() {
            const classes = [];

            if(this.minimizeCall) {
                if(this.darkMode) {
                    classes.push('text-lg', 'text-white');
                } else {
                    classes.push('text-lg', 'text-white');
                }
            } else {
                if(this.darkMode) {
                    classes.push('text-lg', 'text-white');
                } else {
                    classes.push('text-2xl', 'text-grey-900');
                }
            }
            return classes;
        },
        modalButtonTextClasses() {
            const classes = [];

            if(this.minimizeCall) {
                if(this.darkMode) {
                    classes.push('text-white');
                } else {
                    classes.push('text-white');
                }
            } else {
                if(this.darkMode) {
                    classes.push('text-white');
                } else {
                    classes.push('text-grey-900');
                }
            }
            return classes;
        },
        muteButtonClasses() {
            const classes = [];

            if(this.minimizeCall) {
                if(this.darkMode) {
                    if(this.muted) {
                        classes.push('bg-blue-500', 'text-white', 'hover:bg-blue-400');
                    }
                    else {
                        classes.push('bg-cyan-125', 'text-blue-550', 'border', 'border-transparent', 'hover:border-primary-500')
                    }
                } else {
                    if(this.muted) {
                        classes.push('bg-blue-500', 'text-white', 'hover:bg-blue-400');
                    }
                    else {
                        classes.push('bg-cyan-125', 'text-blue-550', 'border', 'border-transparent', 'hover:border-primary-500')
                    }
                }
            } else {
                if(this.darkMode) {
                    if(this.muted) {
                        classes.push('bg-primary-500', 'text-white', 'hover:bg-primary-500');
                    }
                    else {
                        classes.push('bg-blue-800', 'text-blue-550', 'border', 'border-transparent', 'hover:border-primary-500')
                    }
                } else {
                    if(this.muted) {
                        classes.push('bg-primary-500', 'text-white', 'hover:bg-primary-500');
                    }
                    else {
                        classes.push('bg-cyan-125', 'text-blue-550', 'hover:bg-cyan-200')
                    }
                }
            }
            return classes;
        },
        holdButtonClasses() {
            const classes = [];

            if(this.minimizeCall) {
                if(this.darkMode) {
                    if(this.onHold) {
                        classes.push('bg-blue-500', 'text-white', 'hover:bg-blue-400');
                    }
                    else {
                        classes.push('bg-cyan-125', 'text-blue-550', 'border', 'border-transparent', 'hover:border-primary-500')
                    }
                } else {
                    if(this.onHold) {
                        classes.push('bg-blue-500', 'text-white', 'hover:bg-blue-400');
                    }
                    else {
                        classes.push('bg-cyan-125', 'text-blue-550', 'border', 'border-transparent', 'hover:border-primary-500')
                    }
                }
            } else {
                if(this.darkMode) {
                    if(this.onHold) {
                        classes.push('bg-primary-500', 'text-white', 'hover:bg-primary-500');
                    }
                    else {
                        classes.push('bg-blue-800', 'text-blue-550', 'border', 'border-transparent', 'hover:border-primary-500')
                    }
                } else {
                    if(this.onHold) {
                        classes.push('bg-primary-500', 'text-white', 'hover:bg-primary-500');
                    }
                    else {
                        classes.push('bg-cyan-125', 'text-blue-550', 'hover:bg-cyan-200')
                    }
                }
            }
            return classes;
        },
        formattedNumber() {
            return formatPhoneNumber(this.contactPhone)
        }
    },

}
</script>


<style scoped>

    .connecting-1 {
        animation-duration: 2s;
        animation-name: connecting-1;
        animation-iteration-count: infinite;
        transform: translateY(0);
    }
    .connecting-2 {
        animation-duration: 2s;
        animation-name: connecting-1;
        animation-iteration-count: infinite;
        transform: translateY(0);
    }
    .connecting-3 {
        animation-duration: 2s;
        animation-name: connecting-1;
        animation-iteration-count: infinite;
        transform: translateY(0);
    }
    .connecting-4 {
        animation-duration: 2s;
        animation-name: connecting-1;
        animation-iteration-count: infinite;
        transform: translateY(0);
    }
    .connecting-5 {
        animation-duration: 2s;
        animation-name: connecting-1;
        animation-iteration-count: infinite;
        transform: translateY(0);
    }
    .connecting-6 {
        animation-duration: 2s;
        animation-name: connecting-1;
        animation-iteration-count: infinite;
        transform: translateY(0);
    }
    .connecting-7 {
        animation-duration: 2s;
        animation-name: connecting-1;
        animation-iteration-count: infinite;
        transform: translateY(0);
    }
    .connecting-8 {
        animation-duration: 2s;
        animation-name: connecting-1;
        animation-iteration-count: infinite;
        transform: translateY(0);
    }
    .connecting-9 {
        animation-duration: 2s;
        animation-name: connecting-1;
        animation-iteration-count: infinite;
        transform: translateY(0);
    }
    .connecting-10 {
        animation-duration: 2s;
        animation-name: connecting-1;
        animation-iteration-count: infinite;
        transform: translateY(0);
    }

    @keyframes connecting-1 {
        0% {transform: translateY(0px);} 5% {transform: translateY(4px);} 10% {transform: translateY(0px);} 15% {transform: translateY(-4px);} 100% {transform: translateY(0px);}
    }
    @keyframes connecting-2 {
        0% {transform: translateY(0px);} 10% {transform: translateY(4px);} 15% {transform: translateY(0px);} 20% {transform: translateY(-4px);} 100% {transform: translateY(0px);}
    }
    @keyframes connecting-3 {
        0% {transform: translateY(0px);} 15% {transform: translateY(4px);} 20% {transform: translateY(0px);} 25% {transform: translateY(-4px);} 100% {transform: translateY(0px);}
    }
    @keyframes connecting-4 {
        0% {transform: translateY(0px);} 20% {transform: translateY(4px);} 25% {transform: translateY(0px);} 30% {transform: translateY(-4px);} 100% {transform: translateY(0px);}
    }
    @keyframes connecting-5 {
        0% {transform: translateY(0px);} 25% {transform: translateY(4px);} 30% {transform: translateY(0px);} 40% {transform: translateY(-4px);} 100% {transform: translateY(0px);}
    }
    @keyframes connecting-6 {
        0% {transform: translateY(0px);} 35% {transform: translateY(4px);} 40% {transform: translateY(0px);} 50% {transform: translateY(-4px);} 100% {transform: translateY(0px);}
    }
    @keyframes connecting-7 {
        0% {transform: translateY(0px);} 45% {transform: translateY(4px);} 50% {transform: translateY(0px);} 60% {transform: translateY(-4px);} 100% {transform: translateY(0px);}
    }
    @keyframes connecting-8 {
        0% {transform: translateY(0px);} 55% {transform: translateY(4px);} 60% {transform: translateY(0px);} 70% {transform: translateY(-4px);} 100% {transform: translateY(0px);}
    }
    @keyframes connecting-9 {
        0% {transform: translateY(0px);} 65% {transform: translateY(4px);} 70% {transform: translateY(0px);} 80% {transform: translateY(-4px);} 100% {transform: translateY(0px);}
    }
    @keyframes connecting-10 {
        0% {transform: translateY(0px);} 75% {transform: translateY(4px);} 80% {transform: translateY(0px);} 90% {transform: translateY(-4px);} 100% {transform: translateY(0px);}
    }

    .connected-1 {
        animation-duration: 10s;
        animation-name: connected-1;
        animation-iteration-count: infinite;
        transition: transform 4s ease-in-out;
        animation-direction: alternate-reverse;
        transform: scaleY(1);
    }
    .connected-2 {
        animation-duration: 10s;
        animation-name: connected-1;
        animation-iteration-count: infinite;
        transition: transform 4s ease-in-out;
        animation-direction: alternate-reverse;
        transform: scaleY(1);
    }
    .connected-3 {
        animation-duration: 10s;
        animation-name: connected-1;
        animation-iteration-count: infinite;
        transition: transform 4s ease-in-out;
        animation-direction: alternate-reverse;
        transform: scaleY(1);
    }
    .connected-4 {
        animation-duration: 10s;
        animation-name: connected-1;
        animation-iteration-count: infinite;
        transition: transform 4s ease-in-out;
        animation-direction: alternate-reverse;
        transform: scaleY(1);
    }
    .connected-5 {
        animation-duration: 10s;
        animation-name: connected-1;
        animation-iteration-count: infinite;
        transition: transform 4s ease-in-out;
        animation-direction: alternate-reverse;
        transform: scaleY(1);
    }
    .connected-6 {
        animation-duration: 10s;
        animation-name: connected-1;
        animation-iteration-count: infinite;
        transition: transform 4s ease-in-out;
        animation-direction: alternate-reverse;
        transform: scaleY(1);
    }
    .connected-7 {
        animation-duration: 10s;
        animation-name: connected-1;
        animation-iteration-count: infinite;
        transition: transform 4s ease-in-out;
        animation-direction: alternate-reverse;
        transform: scaleY(1);
    }
    .connected-8 {
        animation-duration: 10s;
        animation-name: connected-1;
        animation-iteration-count: infinite;
        transition: transform 4s ease-in-out;
        animation-direction: alternate-reverse;
        transform: scaleY(1);
    }
    .connected-9 {
        animation-duration: 10s;
        animation-name: connected-1;
        animation-iteration-count: infinite;
        transition: transform 4s ease-in-out;
        animation-direction: alternate-reverse;
        transform: scaleY(1);
    }
    .connected-10 {
        animation-duration: 10s;
        animation-name: connected-1;
        animation-iteration-count: infinite;
        transition: transform 4s ease-in-out;
        animation-direction: alternate-reverse;
        transform: scaleY(1);
    }

    @keyframes connected-1 {
        0% {transform: scaleY(1);} 5% {transform: scaleY(5);} 10% {transform: scaleY(6);} 15% {transform: scaleY(3);} 100% {transform: scaleY(2);}
    }
    @keyframes connected-2 {
        0% {transform: scaleY(1);} 10% {transform: scaleY(4);} 15% {transform: scaleY(5);} 20% {transform: scaleY(3);} 100% {transform: scaleY(3);}
    }
    @keyframes connected-3 {
        0% {transform: scaleY(1);} 15% {transform: scaleY(2);} 20% {transform: scaleY(6);} 25% {transform: scaleY(3);} 100% {transform: scaleY(4);}
    }
    @keyframes connected-4 {
        0% {transform: scaleY(1);} 20% {transform: scaleY(1);} 25% {transform: scaleY(2);} 30% {transform: scaleY(3);} 100% {transform: scaleY(4);}
    }
    @keyframes connected-5 {
        0% {transform: scaleY(1);} 25% {transform: scaleY(3);} 30% {transform: scaleY(6);} 40% {transform: scaleY(3);} 100% {transform: scaleY(6);}
    }
    @keyframes connected-6 {
        0% {transform: scaleY(1);} 35% {transform: scaleY(5);} 40% {transform: scaleY(1);} 50% {transform: scaleY(3);} 100% {transform: scaleY(4);}
    }
    @keyframes connected-7 {
        0% {transform: scaleY(1);} 45% {transform: scaleY(2);} 50% {transform: scaleY(4);} 60% {transform: scaleY(3);} 100% {transform: scaleY(1);}
    }
    @keyframes connected-8 {
        0% {transform: scaleY(1);} 55% {transform: scaleY(3);} 60% {transform: scaleY(5);} 70% {transform: scaleY(3);} 100% {transform: scaleY(5);}
    }
    @keyframes connected-9 {
        0% {transform: scaleY(1);} 65% {transform: scaleY(2);} 70% {transform: scaleY(4);} 80% {transform: scaleY(3);} 100% {transform: scaleY(5);}
    }
    @keyframes connected-10 {
        0% {transform: scaleY(1);} 75% {transform: scaleY(3);} 80% {transform: scaleY(6);} 90% {transform: scaleY(3);} 100% {transform: scaleY(5);}
    }

</style>
