<template>
    <div class="p-5">
        <div class="flex items-center justify-between mb-3">
            <h5 class="text-primary-500 font-semibold leading-tight">Keys for {{ website?.name }}</h5>
            <button
                class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5" @click="openModal">
                Add New Key
            </button>
        </div>
        <div class="grid grid-cols-6 gap-x-3 mb-2 px-5" v-if="!loading">
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Name</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-3">Key</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs">Status</p>
        </div>
        <div class="border-t border-b" v-if="!loading"
             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-40 border-dark-border': darkMode}">
            <div>
                <div class="grid grid-cols-6 gap-x-3 border-b px-5 py-3 items-center"
                     v-for="key in apiKeys" :key="key.id"
                     :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <p class="text-sm col-span-2 truncate">
                        {{ key.name }}
                    </p>
                    <p class="text-sm col-span-3 truncate">
                        {{ key.key }}
                    </p>
                    <toggle v-model="key.status" @click="updateKeyStatus(key)"></toggle>
                </div>
            </div>
        </div>
        <div class="h-32 flex items-center justify-center" v-if="loading">
            <loading-spinner></loading-spinner>
        </div>
        <modal :small="true" v-if="showModal" @close="closeModal" :dark-mode="darkMode" @confirm="addKey" :close-text="'Cancel'" :confirm-text="loading ? 'Saving...' : 'Save'">
            <template v-slot:header>
                <h4>Add Key</h4>
            </template>
            <template v-slot:content>
                <div class="grid gap-3">
                    <div class="flex items-center">
                        <p class="uppercase font-semibold text-xs w-20"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Name
                        </p>
                        <input class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                               placeholder="Key name"
                               v-model="name"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                    </div>
                </div>
            </template>
        </modal>
        <alerts-container :dark-mode="darkMode" :alert-type="'error'" :text="error" v-if="error !== null"></alerts-container>
    </div>
</template>

<script>
import ApiService from "./services/api";
import loadingSpinner from "../../Shared/components/LoadingSpinner";
import Modal from "../../Shared/components/Modal";
import Toggle from "../../Inputs/Toggle/Toggle";
import AlertsContainer from "../../Shared/components/AlertsContainer";

export default {
    name: "ApiKeys",
    components: {loadingSpinner, Modal, Toggle, AlertsContainer},
    props: {
        website: {
            type: Object,
            default: null
        },
        darkMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            apiKeys: [],
            name: null,
            api: ApiService.make(),
            loading: false,
            error: null,
            showModal: false
        }
    },
    created() {
        if (this.website) this.getKeys()
    },
    methods: {
        getKeys() {
            this.apiKeys = [];
            this.loading = true;
            this.resetError();
            this.api.getKeys(this.website.id)
                .catch(e => this.error = e.response.data.message)
                .then(resp => this.apiKeys = resp.data.data.api_keys)
                .finally(() => this.loading = false);
        },
        addKey() {
            this.loading = true;
            this.resetError();
            this.api.addKey(this.website.id, {name: this.name})
                .then(() => {
                    this.getKeys();
                    this.closeModal();
                })
                .catch(e => {
                    this.error = e.response.data.message;
                    this.loading = false;
                });
        },
        updateKeyStatus(key) {
            let response = null;
            this.loading = true;
            this.resetError();

            if (key.status) response = this.api.enableKey(this.website.id, key.id);
            else response = this.api.disableKey(this.website.id, key.id);

            response.catch(e => {
                this.error = e.response.data.message;
                key.status = !key.status;
            }).finally(() => this.loading = false);
        },
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        resetError() {
            this.error = null;
        }
    }
}
</script>
