class BaseApiService {
    constructor(serviceName = "BaseApiService") {
        this.serviceName = serviceName;
    }

    _throwUnimplementedError(fn) {
        throw new Error("Not implemented error. `" + this.serviceName + "::" + fn + "()`");
    }


}

export { BaseApiService };
