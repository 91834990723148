<template>
    <div class="main-layout font-body">
        <alerts-container :text="alertText" :alert-type="alertType" v-if="alertActive" :dark-mode="darkMode"/>
        <div class="w-full">
            <div class="w-full flex-auto relative"
                 :class="{'bg-light-background': !darkMode, 'bg-dark-background': darkMode}">

                <!-- Fixed Company Info Panel -->
                <div class="px-10 border-b relative" :class="[darkMode ? 'text-white bg-dark-module border-dark-border' : 'bg-light-module border-light-border text-slate-900']">
                    <div class="absolute inset-0 z-40 flex items-center justify-center" :class="{ 'bg-dark-module': darkMode, 'bg-light-module': !darkMode }"   v-if="saving">
                        <loading-spinner></loading-spinner>
                    </div>
                    <div class="flex items-center justify-between flex-wrap pt-4 pb-5">
                        <a href="/companies" class="text-base text-primary-500 font-medium pb-0 leading-none mr-5 inline-flex items-center">
                            <svg class="mr-2" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292894 6.70711C-0.0976305 6.31658 -0.0976304 5.68342 0.292894 5.29289L5.29289 0.292893C5.68342 -0.0976316 6.31658 -0.0976315 6.70711 0.292893C7.09763 0.683417 7.09763 1.31658 6.70711 1.70711L2.41421 6L6.70711 10.2929C7.09763 10.6834 7.09763 11.3166 6.70711 11.7071Z" fill="#0081FF"/>
                            </svg>
                            Back to companies list
                        </a>
                        <div class="inline-flex items-center">
                            <div v-if="hasEditRights" class="flex items-center">
                                <button v-if="!editingBasicInfo" @click="editBasicInfo" class="mr-6 text-primary-500 mt-5 sm:mt-0 px-5 py-2 font-medium text-sm rounded-md inline-flex items-center justify-center">
                                    <svg class="mr-2" width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.25 0L10.3125 1.95117L8.74019 3.43926L6.67769 1.48809L8.25 0ZM0 7.79688V9.74805H2.0625L7.76806 4.35827L5.70556 2.40709L0 7.79688ZM0 11.6992H11V13H0V11.6992Z" fill="#0081FF"/>
                                    </svg>
                                    Edit Main Info
                                </button>
                                <button v-if="editingBasicInfo" @click="saveBasicInfo" class="mr-3 text-white h-9 bg-emerald-500  hover:bg-emerald-600 mt-5 sm:mt-0 px-5 py-2 font-medium text-sm rounded-md inline-flex items-center justify-center">
                                    Save
                                </button>
                                <button v-if="editingBasicInfo" @click="editBasicInfo" class="mr-6 text-white h-9 bg-slate-400 hover:bg-slate-500 mt-5 sm:mt-0 px-5 py-2 font-medium text-sm rounded-md inline-flex items-center justify-center">
                                    Cancel
                                </button>
                            </div>
                            <form :action="company.shadow_url" method="get" target="_blank">
                                <input type="hidden" name="shadow_token" :value="company.shadow_token">
                                <button type="submit" class="bg-slate-600 hover:bg-slate-700 transition duration-200 mt-5 sm:mt-0 px-5 h-9 py-2 font-medium text-sm rounded-md text-white inline-flex items-center justify-center">
                                    <svg class="mr-2" width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.1341 5.78572C6.4751 5.78572 6.80212 5.65026 7.04324 5.40915C7.28436 5.16803 7.41982 4.841 7.41982 4.50001C7.41982 4.15902 7.28436 3.83199 7.04324 3.59087C6.80212 3.34975 6.4751 3.21429 6.1341 3.21429C5.79311 3.21429 5.46608 3.34975 5.22497 3.59087C4.98385 3.83199 4.84839 4.15902 4.84839 4.50001C4.84839 4.841 4.98385 5.16803 5.22497 5.40915C5.46608 5.65026 5.79311 5.78572 6.1341 5.78572Z" fill="white"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.5C0.819 1.89193 3.25543 0 6.13414 0C9.01286 0 11.4493 1.89193 12.2683 4.5C11.4493 7.10807 9.01286 9 6.13414 9C3.25543 9 0.819 7.10807 0 4.5ZM8.70557 4.5C8.70557 5.18199 8.43465 5.83604 7.95242 6.31827C7.47018 6.80051 6.81613 7.07143 6.13414 7.07143C5.45216 7.07143 4.7981 6.80051 4.31587 6.31827C3.83363 5.83604 3.56271 5.18199 3.56271 4.5C3.56271 3.81801 3.83363 3.16396 4.31587 2.68173C4.7981 2.19949 5.45216 1.92857 6.13414 1.92857C6.81613 1.92857 7.47018 2.19949 7.95242 2.68173C8.43465 3.16396 8.70557 3.81801 8.70557 4.5V4.5Z" fill="white"/>
                                    </svg>
                                    Shadow Dashboard
                                </button>
                            </form>
                        </div>

                    </div>
                    <div class="flex gap-4 mb-6 w-full">
                        <div class="hidden w-32 h-32 flex-shrink-0 xl:block border rounded-lg overflow-hidden p-2 relative" :class="[darkMode ? 'border-dark-border' : 'border-light-border']">
                            <svg v-if="editingBasicInfo" class="absolute right-2 top-2 cursor-pointer w-4"  viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 13H11V5.90909H14L10 0L6 5.90909H9V13Z" fill="#0081FF"/>
                                <path d="M18 16.5556H2V8H0V16.5556C0 17.9037 0.897 19 2 19H18C19.103 19 20 17.9037 20 16.5556V8H18V16.5556Z" fill="#0081FF"/>
                            </svg>
                            <div class="w-full h-full flex-shrink-0  bg-center bg-no-repeat bg-contain"
                                 :style="{ backgroundImage: 'url(' + company.image + ')' }">
                            </div>
                        </div>
                        <div class="grid grid-cols-1 md:grid-cols-2 items-center xl:flex gap-8 w-full">
                            <div class="xl:max-w-md xl:w-full">
                                <h3 v-if="!editingBasicInfo" class="text-xl font-semibold mb-1">{{ company.name }}</h3>
                                <input v-else type="text" :name="company.name" v-model="editing.name" class="rounded mb-2 border font-medium text-sm h-9 w-full" :class="[darkMode ? 'bg-dark-background border-dark-border text-slate-200' : 'bg-light-background border-light-border text-slate-900']" />
                                <div class="flex items-center mb-2">
                                    <svg class="w-4 mr-2 flex-shrink-0" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.56225 5.25H3.02175C3.0885 4.0905 3.309 3.0225 3.6495 2.1615C3.10126 2.49744 2.63131 2.94689 2.27128 3.47963C1.91125 4.01236 1.66949 4.61602 1.56225 5.25ZM6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0V0ZM6 1.5C5.943 1.5 5.826 1.524 5.65125 1.6965C5.47275 1.872 5.2785 2.16375 5.0985 2.583C4.80675 3.26325 4.59375 4.1895 4.524 5.25H7.476C7.40625 4.1895 7.19325 3.26325 6.9015 2.583C6.7215 2.163 6.5265 1.872 6.34875 1.6965C6.174 1.524 6.057 1.5 6 1.5ZM8.97825 5.25C8.9115 4.0905 8.691 3.0225 8.3505 2.1615C8.89874 2.49744 9.36869 2.94689 9.72872 3.47963C10.0887 4.01236 10.3305 4.61602 10.4377 5.25H8.97825ZM7.476 6.75H4.524C4.59375 7.8105 4.80675 8.73675 5.0985 9.417C5.2785 9.837 5.4735 10.128 5.65125 10.3035C5.826 10.476 5.943 10.5 6 10.5C6.057 10.5 6.174 10.476 6.34875 10.3035C6.52725 10.128 6.72225 9.83625 6.9015 9.417C7.19325 8.73675 7.40625 7.8105 7.476 6.75ZM8.3505 9.8385C8.691 8.97825 8.9115 7.9095 8.97825 6.75H10.4377C10.3305 7.38398 10.0887 7.98764 9.72872 8.52037C9.36869 9.05311 8.89874 9.50256 8.3505 9.8385V9.8385ZM3.6495 9.8385C3.309 8.9775 3.0885 7.9095 3.0225 6.75H1.56225C1.66949 7.38398 1.91125 7.98764 2.27128 8.52037C2.63131 9.05311 3.10126 9.50256 3.6495 9.8385V9.8385Z" fill="#C9C9C9"/>
                                    </svg>
                                    <a v-if="!editingBasicInfo" :href="company.website" class="text-primary-500 truncate">
                                        {{ company.website }}
                                    </a>
                                    <input v-else type="text" :name="company.website" v-model="editing.website" class="rounded border font-medium text-sm h-9 w-full" :class="[darkMode ? 'bg-dark-background border-dark-border text-slate-200' : 'bg-light-background border-light-border text-slate-900']" />
                                </div>
                                <div class="block xl:flex items-center mb-3">
                                    <div class="flex items-center" :class="editingBasicInfo ? ' w-full' : ''">
                                        <svg class="w-4 mr-2 flex-shrink-0" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.7796 9.3999L9.04134 6.91027C8.91191 6.79263 8.74182 6.72988 8.56699 6.73529C8.39216 6.74069 8.22628 6.81382 8.10435 6.93923L6.49241 8.59697C6.10441 8.52287 5.32438 8.2797 4.52144 7.47879C3.7185 6.67518 3.47533 5.89313 3.40325 5.50783L5.05965 3.89523C5.18522 3.77339 5.25846 3.60748 5.26386 3.4326C5.26927 3.25773 5.20642 3.08761 5.08862 2.95825L2.59964 0.220734C2.48179 0.0909691 2.31799 0.0122571 2.14304 0.00131473C1.96808 -0.00962763 1.79575 0.0480617 1.66265 0.162131L0.200925 1.4157C0.0844657 1.53258 0.0149551 1.68814 0.00557879 1.85287C-0.00452532 2.02127 -0.197177 6.01034 2.89603 9.10486C5.59449 11.8026 8.97465 12 9.90558 12C10.0416 12 10.1252 11.996 10.1474 11.9946C10.3121 11.9854 10.4676 11.9156 10.5839 11.7986L11.8368 10.3362C11.9513 10.2035 12.0094 10.0313 11.9987 9.8564C11.988 9.68146 11.9094 9.51763 11.7796 9.3999V9.3999Z" fill="#C9C9C9"/>
                                        </svg>
                                        <p>
                                            {{ $filters.formatPhoneNumber(company.phone) || '-' }}
                                        </p>

                                    </div>
                                    <div v-if="!editingBasicInfo" class="inline-flex items-center gap-x-6 ml-8">
                                        <div v-for="link in company.socialMediaLinks">
                                            <a v-if="link.linktype === 'twitter'" :href="link.linkvalue" target="_blank">
                                                <svg class="cursor-pointer w-4 h-4 flex-shrink-0" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.7671 2.24295C10.7751 2.34162 10.7751 2.43973 10.7751 2.53784C10.7751 5.54028 8.30022 9 3.77733 9C2.38388 9 1.08935 8.6273 0 7.98002C0.197842 8.00088 0.388357 8.00821 0.594138 8.00821C1.7012 8.01068 2.77691 7.66885 3.64787 7.03784C3.13459 7.02926 2.63703 6.87289 2.22465 6.59057C1.81227 6.30824 1.50567 5.91405 1.34765 5.46304C1.49969 5.4839 1.65235 5.498 1.81233 5.498C2.03277 5.498 2.25443 5.4698 2.46021 5.42075C1.90317 5.3169 1.40228 5.0381 1.04271 4.63176C0.683145 4.22542 0.487091 3.71663 0.487889 3.19189V3.1637C0.815795 3.33229 1.19621 3.43773 1.59923 3.45182C1.26161 3.24466 0.984767 2.96355 0.793409 2.63357C0.602051 2.3036 0.502125 1.93501 0.502544 1.56071C0.502544 1.13896 0.624059 0.752162 0.837167 0.414986C1.4552 1.11698 2.22603 1.69127 3.09974 2.10067C3.97344 2.51007 4.93053 2.74545 5.90902 2.79157C5.87116 2.62242 5.84795 2.44706 5.84795 2.27114C5.84779 1.97285 5.9113 1.67745 6.03485 1.40184C6.1584 1.12622 6.33957 0.875797 6.568 0.664872C6.79642 0.453948 7.06763 0.286662 7.36611 0.172579C7.6646 0.0584958 7.98451 -0.00014794 8.30755 2.80264e-07C9.01588 2.80264e-07 9.6552 0.274026 10.1046 0.717203C10.6553 0.61888 11.1833 0.433267 11.6654 0.168588C11.4818 0.693419 11.0973 1.13845 10.584 1.42031C11.0723 1.36888 11.5496 1.25029 12 1.06848C11.6636 1.52129 11.2468 1.91838 10.7671 2.24295V2.24295Z" fill="#0081FF"/>
                                                </svg>
                                            </a>
                                            <a v-else-if="link.linktype === 'linkedin'" :href="link.linkvalue" target="_blank">
                                                <svg class="cursor-pointer w-4 h-4 flex-shrink-0" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.32205 2.64411C2.05221 2.64411 2.64411 2.05221 2.64411 1.32205C2.64411 0.591904 2.05221 0 1.32205 0C0.591904 0 0 0.591904 0 1.32205C0 2.05221 0.591904 2.64411 1.32205 2.64411Z" fill="#0081FF"/>
                                                    <path d="M3.89233 3.64593V10.9807H6.16967V7.35348C6.16967 6.39638 6.34973 5.4695 7.53644 5.4695C8.70684 5.4695 8.72134 6.56376 8.72134 7.41391V10.9813H10.9999V6.95892C10.9999 4.98309 10.5745 3.46466 8.26514 3.46466C7.15638 3.46466 6.41318 4.07312 6.10925 4.64895H6.07844V3.64593H3.89233V3.64593ZM0.181152 3.64593H2.46212V10.9807H0.181152V3.64593Z" fill="#0081FF"/>
                                                </svg>
                                            </a>
                                            <a v-else-if="link.linktype === 'facebook'" :href="link.linkvalue" target="_blank">
                                                <svg class="cursor-pointer w-4 h-4 flex-shrink-0" viewBox="0 0 5 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.24818 10V5.44595H4.72901L4.94912 3.66289H3.24818V2.52716C3.24818 2.01263 3.38635 1.66036 4.09812 1.66036H5V0.0706607C4.56118 0.0218702 4.1201 -0.00168805 3.67877 9.3996e-05C2.36986 9.3996e-05 1.47119 0.829113 1.47119 2.35102V3.65956H0V5.44262H1.4744V10H3.24818Z" fill="#0081FF"/>
                                                </svg>
                                            </a>
                                            <a v-else-if="link.linktype === 'youtube'" :href="link.linkvalue" target="_blank">
                                                <svg class="cursor-pointer w-4 h-4 flex-shrink-0" viewBox="0 0 29 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M28.0882 16.8181C27.7563 18.0417 26.7987 18.9999 25.5713 19.3272C23.9225 19.9617 7.9524 20.2727 3.31969 19.309C2.09221 18.9781 1.13101 18.0236 0.80271 16.7999C0.0585591 13.5454 0.00201823 6.50902 0.820949 3.18174C1.1529 1.95811 2.11045 0.999924 3.33793 0.672651C6.60271 -0.0764397 22.1241 -0.181894 25.5895 0.672651C26.817 1.00356 27.7782 1.95811 28.1065 3.18174C28.8999 6.7272 28.9564 13.3272 28.0882 16.8181Z" fill="#0081FF"/>
                                                    <path d="M19.151 9.99985L11.7095 14.2544V5.7453L19.151 9.99985Z" fill="white"/>
                                                </svg>
                                            </a>
                                            <a v-else :title="link.linktype" :href="link.linkvalue" target="_blank">
                                                <svg class="cursor-pointer w-4 h-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" >
                                                    <path fill="#0081FF" fill-rule="evenodd" d="M19.902 4.098a3.75 3.75 0 00-5.304 0l-4.5 4.5a3.75 3.75 0 001.035 6.037.75.75 0 01-.646 1.353 5.25 5.25 0 01-1.449-8.45l4.5-4.5a5.25 5.25 0 117.424 7.424l-1.757 1.757a.75.75 0 11-1.06-1.06l1.757-1.757a3.75 3.75 0 000-5.304zm-7.389 4.267a.75.75 0 011-.353 5.25 5.25 0 011.449 8.45l-4.5 4.5a5.25 5.25 0 11-7.424-7.424l1.757-1.757a.75.75 0 111.06 1.06l-1.757 1.757a3.75 3.75 0 105.304 5.304l4.5-4.5a3.75 3.75 0 00-1.035-6.037.75.75 0 01-.354-1z" clip-rule="evenodd" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex items-center mb-1">
                                    <div class="mr-3 px-5 inline-flex items-center py-1 text-xs font-semibold rounded-full capitalize" :class="{'bg-orange-100 border-orange-700 text-orange-700': !darkMode, 'bg-dark-background border-transparent text-orange-500': darkMode}">
                                        {{ company.status || 'Unknown Status' }}
                                    </div>
                                    <div class="mr-3 px-5 inline-flex items-center py-1 text-xs font-semibold rounded-full capitalize" :class="{'bg-orange-100 border-orange-700 text-orange-700': !darkMode, 'bg-dark-background border-transparent text-orange-500': darkMode}">
                                        {{ company.type || 'No Services' }}
                                    </div>
                                </div>
                                <!-- TODO: disabling the status/admin-status and type update until we finalize the backend flow.
                                           Type/service handling possibly could go through the industry management screen and that's already done.
                                 -->
<!--                                <div v-else class="grid grid-cols-2 gap-3 items-center mb-1">-->
<!--                                    <Dropdown :dark-mode="darkMode" :options="statusOptions" v-model="editing.status" :selected="editing.status"></Dropdown>-->
<!--                                    <Dropdown :dark-mode="darkMode" :options="typeOptions" v-model="editing.industry" :selected="editing.industry"-->
<!--                                    ></Dropdown>-->
<!--                                </div>-->
                            </div>
                            <div class="xl:px-8 xl:w-200" :class="[darkMode ? 'text-slate-100' : 'text-slate-900']">
                                <p class="font-semibold text-sm mb-2 inline-flex items-center">
                                    <svg class="mr-2 flex-shrink-0" width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 5.15625C11 5.42975 10.8883 5.69206 10.6894 5.88545C10.4906 6.07885 10.2209 6.1875 9.93964 6.1875C9.65842 6.1875 9.38871 6.07885 9.18986 5.88545C8.991 5.69206 8.87929 5.42975 8.87929 5.15625V1.03125C8.87929 0.757746 8.991 0.495443 9.18986 0.302046C9.38871 0.10865 9.65842 2.56494e-07 9.93964 2.56494e-07C10.2209 2.56494e-07 10.4906 0.10865 10.6894 0.302046C10.8883 0.495443 11 0.757746 11 1.03125V5.15625ZM8.17238 5.27106V1.53794C8.17257 1.28249 8.09958 1.03204 7.96161 0.814679C7.82364 0.597314 7.62614 0.421625 7.39125 0.307313L7.35591 0.290125C6.96301 0.0991493 6.52978 -0.000184225 6.09055 2.56494e-07H2.26266C1.93569 -0.000125695 1.61879 0.109967 1.36591 0.311534C1.11302 0.5131 0.939793 0.79368 0.875708 1.1055L0.0274224 5.2305C-0.0135826 5.43002 -0.00854721 5.6359 0.0421654 5.8333C0.092878 6.0307 0.188004 6.21469 0.320682 6.37201C0.45336 6.52932 0.620283 6.65604 0.809412 6.74302C0.99854 6.83001 1.20516 6.87508 1.41437 6.875H3.93095V9.625C3.93095 9.98967 4.07991 10.3394 4.34505 10.5973C4.61019 10.8551 4.9698 11 5.34476 11C5.53224 11 5.71205 10.9276 5.84462 10.7986C5.97719 10.6697 6.05167 10.4948 6.05167 10.3125V9.85394C6.05167 9.25892 6.2501 8.67995 6.61719 8.20394L7.60686 6.92106C7.97394 6.44505 8.17238 5.86608 8.17238 5.27106V5.27106Z" fill="#94A3B8"/>
                                    </svg>
                                    Rejection:
                                    <span class="font-normal ml-2">
                                        {{ company.overallRejection }}
                                    </span>
                                </p>
                                <div>
                                    <div class="inline-flex items-center mb-2">
                                        <svg class="mr-2 flex-shrink-0" width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 4.54935C0.000739505 5.26524 0.168773 5.97081 0.490404 6.60857C0.812035 7.24633 1.27816 7.79822 1.85079 8.21925L1.85143 8.2199C2.22005 8.49312 2.62784 8.70771 3.06064 8.85625L3.09343 8.8686C3.33715 8.94903 3.58729 9.00797 3.84107 9.04475C4.05881 9.07995 4.27886 9.09842 4.49936 9.1H4.5C4.725 9.1 4.94357 9.0779 5.16021 9.0454L5.27721 9.0272C5.49321 8.98885 5.70407 8.93685 5.90786 8.86925L5.93486 8.85885C7.71429 8.25045 9 6.5507 9 4.54935C9 2.041 6.98143 0 4.5 0C2.01857 0 0 2.041 0 4.54935ZM4.5 1.3C6.27171 1.3 7.71429 2.7573 7.71429 4.54935H6.42857C6.42789 4.03245 6.22446 3.53693 5.86292 3.17148C5.50137 2.80604 5.01122 2.60052 4.5 2.6V1.3ZM1.62064 9.61675V13L4.5 12.025L7.37936 13L7.38 9.61675C6.50504 10.1296 5.51165 10.3997 4.50032 10.3997C3.48899 10.3997 2.4956 10.1296 1.62064 9.61675Z" fill="#94A3B8"/>
                                        </svg>
                                        <p class="font-semibold text-sm mr-2">Never Exceed Budget:</p>
                                            <svg v-if="company.neverExceedBudget === true" width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.27411 6.5197L1.62626 3.69335L0 5.43719L4.27642 10L12 1.74138L10.3714 0L4.27411 6.5197Z" fill="#00AE07"/>
                                            </svg>
                                            <svg v-else-if="company.neverExceedBudget === false" width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.219369 0.228966C0.359874 0.088363 0.550413 0.00937635 0.749086 0.00937635C0.947759 0.00937635 1.1383 0.088363 1.2788 0.228966L4.49532 3.44872L7.71183 0.228966C7.78095 0.157334 7.86362 0.100197 7.95503 0.0608901C8.04644 0.0215834 8.14476 0.00089368 8.24424 2.83178e-05C8.34373 -0.000837044 8.44239 0.0181391 8.53447 0.0558497C8.62655 0.0935604 8.7102 0.14925 8.78055 0.21967C8.8509 0.290089 8.90653 0.373828 8.94421 0.466001C8.98188 0.558173 9.00084 0.656933 8.99997 0.756517C8.99911 0.856102 8.97844 0.954516 8.93917 1.04602C8.8999 1.13752 8.84282 1.22028 8.77126 1.28947L5.55475 4.50922L8.77126 7.72897C8.90774 7.87042 8.98326 8.05987 8.98156 8.25652C8.97985 8.45316 8.90105 8.64127 8.76214 8.78033C8.62322 8.91939 8.4353 8.99826 8.23885 8.99997C8.0424 9.00168 7.85314 8.92608 7.71183 8.78947L4.49532 5.56972L1.2788 8.78947C1.13749 8.92608 0.948232 9.00168 0.751782 8.99997C0.555332 8.99826 0.367412 8.91939 0.228496 8.78033C0.0895797 8.64127 0.0107821 8.45316 0.00907497 8.25652C0.00736788 8.05987 0.082888 7.87042 0.219369 7.72897L3.43588 4.50922L0.219369 1.28947C0.0789073 1.14882 0 0.958089 0 0.759216C0 0.560343 0.0789073 0.369612 0.219369 0.228966V0.228966Z" fill="#E13131"/>
                                            </svg>
                                            <span v-else class="text-sm">
                                                {{ company.neverExceedBudget }}
                                            </span>
                                    </div>
                                </div>
                                <div>
                                    <div class="inline-flex items-center mb-2">
                                        <svg class="mr-2 flex-shrink-0" width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 4.54935C0.000739505 5.26524 0.168773 5.97081 0.490404 6.60857C0.812035 7.24633 1.27816 7.79822 1.85079 8.21925L1.85143 8.2199C2.22005 8.49312 2.62784 8.70771 3.06064 8.85625L3.09343 8.8686C3.33715 8.94903 3.58729 9.00797 3.84107 9.04475C4.05881 9.07995 4.27886 9.09842 4.49936 9.1H4.5C4.725 9.1 4.94357 9.0779 5.16021 9.0454L5.27721 9.0272C5.49321 8.98885 5.70407 8.93685 5.90786 8.86925L5.93486 8.85885C7.71429 8.25045 9 6.5507 9 4.54935C9 2.041 6.98143 0 4.5 0C2.01857 0 0 2.041 0 4.54935ZM4.5 1.3C6.27171 1.3 7.71429 2.7573 7.71429 4.54935H6.42857C6.42789 4.03245 6.22446 3.53693 5.86292 3.17148C5.50137 2.80604 5.01122 2.60052 4.5 2.6V1.3ZM1.62064 9.61675V13L4.5 12.025L7.37936 13L7.38 9.61675C6.50504 10.1296 5.51165 10.3997 4.50032 10.3997C3.48899 10.3997 2.4956 10.1296 1.62064 9.61675Z" fill="#94A3B8"/>
                                        </svg>
                                        <p class="font-semibold text-sm mr-2">Prescreened:</p>
                                        <div v-if="!editingBasicInfo" :title="prescreenedAt">
                                            <svg v-if="company.prescreened" width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.27411 6.5197L1.62626 3.69335L0 5.43719L4.27642 10L12 1.74138L10.3714 0L4.27411 6.5197Z" fill="#00AE07"/>
                                            </svg>
                                            <svg v-else width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.219369 0.228966C0.359874 0.088363 0.550413 0.00937635 0.749086 0.00937635C0.947759 0.00937635 1.1383 0.088363 1.2788 0.228966L4.49532 3.44872L7.71183 0.228966C7.78095 0.157334 7.86362 0.100197 7.95503 0.0608901C8.04644 0.0215834 8.14476 0.00089368 8.24424 2.83178e-05C8.34373 -0.000837044 8.44239 0.0181391 8.53447 0.0558497C8.62655 0.0935604 8.7102 0.14925 8.78055 0.21967C8.8509 0.290089 8.90653 0.373828 8.94421 0.466001C8.98188 0.558173 9.00084 0.656933 8.99997 0.756517C8.99911 0.856102 8.97844 0.954516 8.93917 1.04602C8.8999 1.13752 8.84282 1.22028 8.77126 1.28947L5.55475 4.50922L8.77126 7.72897C8.90774 7.87042 8.98326 8.05987 8.98156 8.25652C8.97985 8.45316 8.90105 8.64127 8.76214 8.78033C8.62322 8.91939 8.4353 8.99826 8.23885 8.99997C8.0424 9.00168 7.85314 8.92608 7.71183 8.78947L4.49532 5.56972L1.2788 8.78947C1.13749 8.92608 0.948232 9.00168 0.751782 8.99997C0.555332 8.99826 0.367412 8.91939 0.228496 8.78033C0.0895797 8.64127 0.0107821 8.45316 0.00907497 8.25652C0.00736788 8.05987 0.082888 7.87042 0.219369 7.72897L3.43588 4.50922L0.219369 1.28947C0.0789073 1.14882 0 0.958089 0 0.759216C0 0.560343 0.0789073 0.369612 0.219369 0.228966V0.228966Z" fill="#E13131"/>
                                            </svg>
                                        </div>
                                        <Dropdown :dark-mode="darkMode" v-else :options="prescreenedOptions" v-model="editing.prescreened" :selected="editing.prescreened"></Dropdown>

                                    </div>
                                </div>
                                <div>
                                    <div class="inline-flex items-center mb-2">
                                        <svg class="mr-2" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.9 0H1.1C0.49335 0 0 0.4485 0 1V7C0 7.5515 0.49335 8 1.1 8H9.9C10.5067 8 11 7.5515 11 7V1C11 0.4485 10.5067 0 9.9 0ZM3.69325 2C4.3263 2 4.79325 2.4245 4.79325 3C4.79325 3.5755 4.3263 4 3.69325 4C3.0602 4 2.59325 3.5755 2.59325 3C2.59325 2.4245 3.05965 2 3.69325 2ZM5.7365 6H1.65V5.7675C1.65 5.081 2.5718 4.375 3.69325 4.375C4.8147 4.375 5.7365 5.081 5.7365 5.7675V6ZM9.35 5.5H7.15V4.5H9.35V5.5ZM9.35 3.5H6.6V2.5H9.35V3.5Z" fill="#94A3B8"/>
                                        </svg>
                                        <p class="font-semibold text-sm mr-2">Account Manager:</p>
                                        <div v-if="!editingBasicInfo" class="inline-flex items-center">
                                            <p class="text-sm"
                                               :class="[company.account_manager.active ? '' : 'text-gray-500 cursor-help' ]"
                                               :title="[company.account_manager.active === false ? 'Inactive Account Manager' : '' ]"
                                            >
                                                {{ company.account_manager.name || 'None Assigned' }}
                                            </p>
                                            <div v-if="company.last_account_manager?.id"
                                                 :title="`Previous Account Manager: ${company.last_account_manager.name || 'Unknown'}`"
                                                 class="ml-4"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                                     class="w-4 h-4 cursor-help"
                                                >
                                                    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z" fill="#94A3B8" clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                        </div>
                                        <Dropdown
                                            :dark-mode="darkMode"
                                            v-else
                                            :options="accountManagerOptions"
                                            v-model="editing.account_manager"
                                            :selected="accountManagerOptions.find(i => i.id === editing.account_manager) || 0"
                                        ></Dropdown>
                                    </div>
                                </div>
                                <div>
                                    <div class="inline-flex items-center mb-2">
                                        <svg class="mr-2" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.9 0H1.1C0.49335 0 0 0.4485 0 1V7C0 7.5515 0.49335 8 1.1 8H9.9C10.5067 8 11 7.5515 11 7V1C11 0.4485 10.5067 0 9.9 0ZM3.69325 2C4.3263 2 4.79325 2.4245 4.79325 3C4.79325 3.5755 4.3263 4 3.69325 4C3.0602 4 2.59325 3.5755 2.59325 3C2.59325 2.4245 3.05965 2 3.69325 2ZM5.7365 6H1.65V5.7675C1.65 5.081 2.5718 4.375 3.69325 4.375C4.8147 4.375 5.7365 5.081 5.7365 5.7675V6ZM9.35 5.5H7.15V4.5H9.35V5.5ZM9.35 3.5H6.6V2.5H9.35V3.5Z" fill="#94A3B8"/>
                                        </svg>
                                        <p class="font-semibold text-sm mr-2">Success Manager:</p>
                                        <p v-if="!editingBasicInfo" class=" text-sm">{{ company.success_manager.name || 'None Assigned' }}</p>
                                        <Dropdown
                                            v-else
                                            :dark-mode="darkMode"
                                            :options="successManagerOptions"
                                            v-model="editing.success_manager"
                                            :selected="successManagerOptions.find(i => i.id === editing.success_manager) || 0"
                                        ></Dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="xl:px-8 w-full">
                                <div>
                                    <div class="text-sm inline-flex items-center mb-3 w-full">
                                        <span class="font-semibold mr-2">Office:</span>
                                        <span class="truncate" >{{ company.mainOfficeLocation}}</span>
                                    </div>
                                </div>
                                <div>
                                    <div  class="text-sm inline-flex items-center mb-3 w-full">
                                        <span class="font-semibold mr-2">Employees:</span>
                                        <span v-if="!editingBasicInfo">{{ company.employees  ?? '-' }}</span>
                                        <input v-else type="text" :name="company.employees" v-model="editing.employees" class="rounded border font-medium text-sm h-9 w-full" :class="[darkMode ? 'bg-dark-background border-dark-border text-slate-200' : 'bg-light-background border-light-border text-slate-900']" />
                                    </div>
                                </div>
                                <div>
                                    <div class="text-sm inline-flex items-center  mb-3 w-full">
                                        <span class="font-semibold mr-2">Revenue:</span>
                                        <span v-if="!editingBasicInfo">{{ company.revenue ?? '-' }}</span>
                                        <input v-else type="text" :name="company.revenue" v-model="editing.revenue" class="rounded border font-medium text-sm h-9 w-full" :class="[darkMode ? 'bg-dark-background border-dark-border text-slate-200' : 'bg-light-background border-light-border text-slate-900']" />
                                    </div>
                                </div>
                                <div>
                                    <div class="text-sm inline-flex items-center mb-3 w-full">
                                        <span class="font-semibold mr-2">Legacy:</span>
                                        <span v-if="company.legacy_id" class="text-primary-500 truncate">
                                            <a :href="getLegacyAdminCompanyUrl(company.legacy_id)" target="_blank">
                                                {{ company.name }}
                                            </a>
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <div class="text-sm inline-flex items-center mb-3 w-full">
                                        <span class="font-semibold mr-2">Industries:</span>
                                        <div class="flex items-center" v-if="!editingBasicInfo">
                                            <div
                                                class="mr-3 px-5 inline-flex items-center py-1 text-xs font-semibold rounded-full capitalize"
                                                :class="{'bg-orange-100 border-orange-700 text-orange-700': !darkMode, 'bg-dark-background border-transparent text-orange-500': darkMode}"
                                                v-for="industry in company.industries"
                                            >
                                                {{ industry.name }}
                                            </div>
                                        </div>
                                        <multi-select
                                            v-if="editingBasicInfo"
                                            :options="industries"
                                            :dark-mode="darkMode"
                                            :text-place-holder="'Select Industries'"
                                            :show-search-box="false"
                                            @input="editing.industries = $event"
                                            :selected-ids="editing.industries"
                                            class="w-full"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Tab
                        :dark-mode="darkMode"
                        :tabs="tabs"
                        @selected="selectTab"
                        :tabs-classes="'w-full'"
                        :tab-type="'Normal'"
                        :default-tab-index="defaultTab"
                    />
                </div>
                <div>

                    <Overview :has-edit-rights="hasEditRights" @send-to-tab="sendToTab" :dark-mode="darkMode" v-if="selectedTab === 'Overview'" :company-id="company.id" :legacy-company-id="company.legacy_id"/>

                    <UsersAndContacts :has-edit-rights="hasEditRights" :dark-mode="darkMode" v-if="selectedTab === 'Users & Contacts'" :company-id="company.id"/>

                    <!-- Disabling edit rights until completed to a satisfactory level. -->
                    <CompanyProfile
                        :has-edit-rights="hasEditRights"
                        :dark-mode="darkMode"
                        v-if="selectedTab === 'Company Profile'"
                        :company-id="company.id"
                        :legacy-company-id="company.legacy_id"
                    />

                    <LeadsPage :dark-mode="darkMode" v-if="selectedTab === 'Leads'" :company-id="company.id"/>

                    <CampaignsPage :has-edit-rights="hasEditRights" :dark-mode="darkMode" v-if="selectedTab === 'Campaigns'" :company-id="company.id" :legacy-company-id="company.legacy_id"/>

                    <Revenue :dark-mode="darkMode" v-if="selectedTab === 'Revenue'" :company-id="company.id"/>

                    <InvoicesAndBilling :dark-mode="darkMode" v-if="selectedTab === 'Invoices & Billing'" :company-id="company.id"/>

                    <CompanyTasks :dark-mode="darkMode"  v-if="selectedTab === 'Tasks'" :company-id="company.id" :company-name="company.name"/>

                    <Emails :dark-mode="darkMode" v-if="selectedTab === 'Emails'"/>

                    <div class="px-10 pt-5 pb-10">
                        <ActivityPage :dark-mode="darkMode" v-if="selectedTab === 'Activity Feed'" :company-id="company.id"/>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Tab from "../Shared/components/Tab";
import Overview from "./components/Overview";
import UsersAndContacts from "./components/UsersAndContacts";
import CompanyProfile from "./components/CompanyProfile";
import LeadsPage from "./components/LeadsPage";
import CampaignsPage from "./components/CampaignsPage";
import Revenue from "./components/Revenue";
import InvoicesAndBilling from "./components/InvoicesAndBilling";
import Emails from "./components/Emails";
import CompanyTasks from "./components/CompanyTasks";
import Dropdown from "../LeadProcessing/components/Dropdown";
import SharedApiService from "../Shared/services/api";
import LoadingSpinner from "../LeadProcessing/components/LoadingSpinner";
import AlertsContainer from "../LeadProcessing/components/AlertsContainer";
import HasAlertsMixin, {AlertTypes} from "../Shared/mixins/has-alerts-mixin";
import ActivityPage from "./components/ActivityPage";
import LegacyAdminMixin from "../Shared/mixins/legacy-admin-mixin";
import MultiSelect from "../Shared/components/MultiSelect.vue";

export default {
    name: "CompanyPage",
    components: {
        AlertsContainer,
        LoadingSpinner,
        MultiSelect,
        Dropdown, CompanyTasks, Emails, InvoicesAndBilling, Revenue, CampaignsPage, LeadsPage, CompanyProfile, UsersAndContacts, Overview, Tab, ActivityPage},
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        initCompany: {
            type: Object,
            default: {}
        },
        accountManagers: {
            type: Array,
            default: ([]),
        },
        successManagers: {
            type: Array,
            default: ([])
        },
        tab: {
            type: String,
            default: null,
        },
        industries: {
            type: Array,
            default: []
        }
    },
    mixins: [HasAlertsMixin, LegacyAdminMixin],
    data() {
        return {
            api: SharedApiService.make(),
            company: this.initCompany,
            tabs : [
                {name: 'Overview', current: true},
                {name: 'Users & Contacts', current: false},
                {name: 'Company Profile', current: false},
                {name: 'Leads', current: false},
                {name: 'Campaigns', current: false},
                {name: 'Revenue', current: false},
                {name: 'Invoices & Billing', current: false},
                {name: 'Tasks', current: false},
                {name: 'Activity Feed', current: false}
                // {name: 'Emails', current: false},
            ],
            defaultTab: 0,
            selectedTab: 'Overview',
            editingBasicInfo: false,
            // TODO: Edit Rights should be true only if Active user has Admin rights.
            hasEditRights: true,
            editing: {
                name: null,
                website: null,
                phone: null,
                status: null,
                industry: null,
                prescreened: null,
                employees: null,
                revenue: null,
                account_manager: null,
                success_manager: null,
                industries: []
            },
            accountManagerOptions: [
                { id: 0, name: 'None assigned' }
            ],
            successManagerOptions: [
                { id: 0, name: 'None assigned' }
            ],
            statusOptions: [
                {id: 'active', name: 'Active'},
                {id: 'inactive', name: 'Inactive'},
                {id: 'registering', name: 'Registering'},
                {id: 'presales', name: 'Presales'},
                {id: 'collection', name: 'Collection'},
                {id: 'archive', name: 'Archived'},
                {id: 'suspended', name: 'Suspended'},
                {id: 'pending', name: 'Pending'},
            ],
            typeOptions: [
                {id: 'installer', name: "Installer"},
                {id: 'roofer', name: "Roofer"},
                {id: 'manufacturer', name: "Manufacturer"},
                {id: 'affiliate', name: "Affiliate"},
                {id: 'aggregator', name: "Aggregator"},
                {id: 'pp_agg', name: "Ping Post Aggregator"},
            ],
            prescreenedOptions: [
                {id: "yes", name: "Yes"},
                {id: "no", name: "No"}
            ],
            saving: false,
        }
    },
    computed: {
        prescreenedAt() {
            return this.company.prescreened_at > 1
                ? `Last prescreened at: ${this.$filters.dateFromTimestamp(this.company.prescreened_at, 'usWithTime')}`
                : null;
        }
    },
    mounted() {
        this.accountManagerOptions.push(...this.accountManagers);
        this.successManagerOptions.push(...this.successManagers);
    },
    created() {
        if(this.tab) {
            this.selectTab(this.tab)
            this.tabs.find(item => item.current === true).current = false;
            this.tabs.find(item => item.name === this.tab).current = true;
            this.defaultTab = this.tabs.findIndex(item => {
                return item.current === true;
            });
        }
    },
    methods: {
        selectTab(selected) {
            this.selectedTab = selected;
        },
        sendToTab(value) {
            this.selectTab(value);
        },
        getPayload() {
            return {
                companyname: this.editing.name,
                website: this.editing.website,
                status: this.editing.status,
                type: this.editing.industry,
                prescreened: this.editing.prescreened === 'yes',
                employee_count: this.editing.employees,
                revenue_in_thousands: this.editing.revenue,
                account_manager_id: this.editing.account_manager,
                success_manager_id: this.editing.success_manager,

                // Front End Payload Requirements.
                name: this.editing.name,
                employees: this.editing.employees,
                revenue: this.editing.revenue,
                account_manager: {id: this.editing.account_manager, name: this.accountManagers.find(item => item.id === this.editing.account_manager)?.name ?? null },
                success_manager: {id: this.editing.success_manager, name: this.successManagers.find(item => item.id === this.editing.success_manager)?.name ?? null },
                industries: this.editing.industries
            }
        },
        saveBasicInfo() {
            const payload = this.getPayload();
            this.saving = true;

            this.api.updateCompanyBasicInfo(this.company.id, payload).then(resp => {
                if(resp.data.data.status) {
                    const companyRefresh = resp.data.data.company;
                    if (companyRefresh) {
                        Object.assign(this.company, companyRefresh);
                    }
                }
                else {
                    throw new Error("Status was not successful");
                }
            }).catch((e) => {
                console.error("Saving Company Error:", e); // Log to console for bug reports if needed.
                this.showAlert(AlertTypes.error, e.response.data.message);
            }).finally(() => {
                this.stopEditing();
                this.saving = false;
            });
        },
        startEditing() {
            this.assignEditData();
            this.editingBasicInfo  = true;
        },
        stopEditing() {
            this.clearEditData();
            this.editingBasicInfo = false;
        },
        editBasicInfo() {
            if(this.editingBasicInfo)
                this.stopEditing();
            else
                this.startEditing();
        },
        assignEditData() {
            this.editing.name = this.company.name;
            this.editing.website = this.company.website;
            this.editing.phone = this.company.phone;
            this.editing.status = this.company.status;
            this.editing.industry = this.company.type;
            this.editing.prescreened = this.company.prescreened ? 'yes' : 'no';
            this.editing.employees = this.company.employees;
            this.editing.revenue = this.company.revenue;
            this.editing.account_manager  = this.company.account_manager.id;
            this.editing.success_manager  = this.company.success_manager.id;
            this.editing.industries = this.company.industries.map(industry => industry.id)
        },
        clearEditData() {
            for(const key in this.editing) {
                this.editing[key] = null
            }
        }
    },
    watch: {

    }
}
</script>

<style scoped>

</style>
