<template>
    <div class="grid grid-cols-1 gap-4 px-10 py-5">
        <div class="border rounded-lg"
             :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
            <div>
                <div class="flex items-center justify-between">
                    <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight p-5">Emails</h5>
                    <button @click="toggleComposeEmailModal"
                        type="button"
                        class="px-5 py-3 h-9 rounded-md text-white text-sm font-medium bg-primary-500 hover:bg-blue-500 transition duration-200 mr-3 inline-flex items-center">
                        + Compose
                    </button>
                </div>
                <div class="grid grid-cols-8 gap-3 items-center px-5 pb-5">
                    <Dropdown v-model="selectedEmailTemplate" :placeholder="'Templates'" :options="templates" :dark-mode="darkMode" :selected="editTemplates"></Dropdown>
                    <Dropdown v-model="selectedTeam" :placeholder="'Teams'" :options="teams" :dark-mode="darkMode" :selected="editTeams"></Dropdown>
                    <Dropdown v-model="selectedTime" :placeholder="'All Time'" :options="times" :dark-mode="darkMode" :selected="editTimes"></Dropdown>
                    <input class="col-span-2 h-9 w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                           placeholder="Search email" type="text"
                           :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>
                    <div>
                        <button
                            type="button"
                            class="px-5 py-3 h-9 rounded-md text-white text-sm font-medium bg-primary-500 hover:bg-blue-500 transition duration-200 mr-3 inline-flex items-center">
                            <svg class="mr-2" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 13L9 9L13 13ZM10.3333 5.66667C10.3333 6.2795 10.2126 6.88634 9.97811 7.45252C9.74358 8.01871 9.39984 8.53316 8.9665 8.9665C8.53316 9.39984 8.01871 9.74358 7.45252 9.97811C6.88634 10.2126 6.2795 10.3333 5.66667 10.3333C5.05383 10.3333 4.447 10.2126 3.88081 9.97811C3.31462 9.74358 2.80018 9.39984 2.36683 8.9665C1.93349 8.53316 1.58975 8.01871 1.35523 7.45252C1.12071 6.88634 1 6.2795 1 5.66667C1 4.42899 1.49167 3.242 2.36683 2.36683C3.242 1.49167 4.42899 1 5.66667 1C6.90434 1 8.09133 1.49167 8.9665 2.36683C9.84167 3.242 10.3333 4.42899 10.3333 5.66667Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            Search
                        </button>
                    </div>

                </div>
            </div>
            <div class="grid grid-cols-2 divide-x" :class="[darkMode ? 'divide-dark-border' : 'divide-light-border']">
                <div>
                    <div class="border-y h-88 overflow-y-auto" :class="[darkMode ? 'border-dark-border bg-dark-background' : 'bg-light-background border-light-border']">
                        <div v-for="email in emails" :key="email">
                            <div @click="displayEmail(email)"  class="grid grid-cols-1 gap-x-3 border-b cursor-pointer"
                                 :class="[darkMode ? ((selectedEmail === email.id) ? 'text-slate-100 bg-dark-module border-dark-border' : 'bg-dark-background text-slate-100 hover:bg-dark-module border-dark-border') : ((selectedEmail === email.id) ? 'text-slate-900 bg-light-module border-light-border' : 'text-slate-900 hover:bg-light-module border-light-border bg-light-background')]">
                                <div class="flex items-center justify-between">
                                    <div class="flex items-center w-full relative">
                                        <div class="absolute left-4 top-7 w-2 h-2 rounded-full" :class="[email.unread ? 'bg-primary-500' : 'bg-transparent']"/>
                                        <div v-if="selectedEmail === email.id" class="absolute right-3">
                                            <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 9.57227L5 5.57227L1 1.57227" stroke="#0081FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
<!--                                        <div v-if="selectedEmail === email.id" class="absolute left-0 h-full w-1 bg-primary-500"></div>-->
                                        <div class="w-full ml-3 p-5">
                                            <div class="flex items-start relative">
                                                <p class="truncate pb-0 font-semibold text-sm w-48 mr-5">
                                                    {{ email.from }}
                                                </p>
                                                <p class="truncate pb-0 max-w-sm font-medium text-sm mr-20">
                                                    {{ email.subject }}
                                                    <span class="truncate ml-3 font-normal"
                                                          :class="[darkMode ? 'text-slate-400' : 'text-slate-500']">
                                                        -
                                                    </span>
                                                    <span class="truncate ml-3 font-normal"
                                                          :class="[darkMode ? 'text-slate-400' : 'text-slate-500']">
                                                         {{ email.message }}
                                                    </span>
                                                </p>
                                                <p class="truncate absolute right-0 pb-0 font-normal text-sm mr-4" :class="[darkMode ? 'text-slate-400' : 'text-slate-500']">
                                                    {{email.date}}
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-3"></div>
                </div>
                <div class="border-t overflow-y-auto h-88" :class="[darkMode ? 'border-dark-border' : 'border-light-border']">
                    <div class="p-5">
                        <p class="font-semibold text-lg pb-3" :class="[darkMode ? 'text-slate-100' : 'text-slate-900']">{{emails[selectedEmail].subject}}</p>
                        <p class="font-normal text-sm pb-0" :class="[darkMode ? 'text-slate-400' : 'text-slate-500']">From: {{emails[selectedEmail].from}}</p>
                        <p class="font-normal text-sm pb-3" :class="[darkMode ? 'text-slate-400' : 'text-slate-500']">Date: {{emails[selectedEmail].date}}</p>
                        <p v-html="emails[selectedEmail].message" :class="[darkMode ? 'text-slate-100' : 'text-slate-900']">
                        </p>
                    </div>
                </div>
            </div>
            <div v-if="loading" class="flex items-center justify-center h-full">
                <loading-spinner :dark-mode="darkMode"></loading-spinner>
            </div>
        </div>
        <Modal :dark-mode="darkMode" v-if="composeEmailModal" @close="toggleComposeEmailModal">
            <template v-slot:header>
                <p class="font-medium">Compose Email</p>
            </template>
            <template v-slot:content>
                <form class="grid grid-cols-1 gap-4">
                    <div>
                        <label class="font-medium mb-1">Cc:</label>
                        <input v-model="composeEmailTo" class="h-9 w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                               placeholder="Cc." type="text"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>
                    </div>
                    <div>
                        <label class="font-medium mb-1">Subject:</label>
                        <input v-model="composeEmailSubject" class="h-9 w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                               placeholder="Subject" type="text"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>
                    </div>
                    <div>
                        <label class="font-medium mb-1">Message:</label>
                        <textarea v-model="composeEmailMessage" rows="8" class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                               placeholder="Message" type="text"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>
                    </div>
                </form>
            </template>
        </Modal>
    </div>
</template>

<script>
import LoadingSpinner from "../../LeadProcessing/components/LoadingSpinner";
import Dropdown from "../../LeadProcessing/components/Dropdown";
import Modal from "../../Shared/components/Modal";
export default {
    name: "Emails",
    components: {Modal, Dropdown, LoadingSpinner},
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            loading: false,
            emails: [
                {id: 0, unread: true, message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum..',subject: 'This is a new email 1.', from: 'That Random Guy 1', date: '09/08/22'},
                {id: 1, unread: true, message: 'this is a message test 2.',subject: 'This is a new email 2.', from: 'That Random Guy 2', date: '09/08/22'},
                {id: 2, unread: true, message: 'this is a message test 2.',subject: 'This is a new email 2.', from: 'That Random Guy 2', date: '09/08/22'},
                {id: 3, unread: true, message: 'this is a message test 2.',subject: 'This is a new email 2.', from: 'That Random Guy 2', date: '09/08/22'},
                {id: 4, unread: true, message: 'this is a message test 2.',subject: 'This is a new email 2.', from: 'That Random Guy 2', date: '09/08/22'},
                {id: 5, unread: true, message: 'this is a message test 2.',subject: 'This is a new email 2.', from: 'That Random Guy 2', date: '09/08/22'},
                {id: 6, unread: true, message: 'this is a message test 2.',subject: 'This is a new email 2.', from: 'That Random Guy 2', date: '09/08/22'},
                {id: 7, unread: true, message: 'this is a message test 2.',subject: 'This is a new email 2.', from: 'That Random Guy 2', date: '09/08/22'},
                {id: 8, unread: true, message: 'this is a message test 2.',subject: 'This is a new email 2.', from: 'That Random Guy 2', date: '09/08/22'},
                {id: 9, unread: true, message: 'this is a message test 2.',subject: 'This is a new email 2.', from: 'That Random Guy 2', date: '09/08/22'},
                {id: 10, unread: true, message: 'this is a message test 2.',subject: 'This is a new email 2.', from: 'That Random Guy 2', date: '09/08/22'},
            ],
            selectedEmail: 0,
            teams: [
                {id: 0, name: "New Team"},
            ],
            editTeams: null,
            selectedTeam: null,
            templates: [
                {id: 0, name: "October Template"},
            ],
            editTemplates: null,
            selectedEmailTemplate: null,
            times: [
                {id: 0, name: "Last 6 Months"},
                {id: 1, name: "Last 30 Days"},
            ],
            editTimes: null,
            selectedTime: null,
            composeEmailModal: false,
            composeEmailTo: '',
            composeEmailSubject: '',
            composeEmailMessage: ''
        }
    },
    methods: {
        displayEmail(email) {
            this.selectedEmail = email.id;
            // Dismiss Unread Bubble
            if( email.unread === true ) {
                email.unread = false;
            }
        },
        toggleComposeEmailModal() {
            this.composeEmailModal = ! this.composeEmailModal;
        }
    }
}
</script>

<style scoped>

</style>
