import TwilioCommunicationService from "./twilio";

export default class CommunicationFactory {
    static makeService(driver, token) {
        switch(driver) {
            case 'twilio':
                return new TwilioCommunicationService();
            case 'dummy':
                return null;
        }
    }
}
