<template>
    <div class="main-layout font-body">
        <alerts-container :text="alertText" :alert-type="alertType" v-if="alertActive" :dark-mode="darkMode"/>
        <div class="w-full">
            <div class="w-full flex-auto px-6 py-5 relative" :class="{'bg-light-background': !darkMode, 'bg-dark-background': darkMode}">
                <div class="border rounded-lg px-8 pb-8 "
                     :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border text-grey-120': darkMode}">
                    <div class="flex items-center justify-between flex-wrap pt-6 pb-4">
                        <div class="flex justify-between items-center w-full">
                            <div class="inline-flex items-center" :class="{'text-grey-120': darkMode}">
                                <a href="/reports" class="text-xl font-medium text-grey-300 leading-none hover:text-grey-400 transition duration-200 cursor-pointer">Reports</a>
                                <h3 class="text-xl inline-flex font-medium leading-none items-center" :class="{'text-black': !darkMode, 'text-grey-120': darkMode}">
                                    <svg class="mx-4 fill-current" width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.327334 0.260067C0.735992 -0.11144 1.36844 -0.0813234 1.73995 0.327334L6.73995 5.82733C7.08669 6.20876 7.08669 6.79126 6.73995 7.17268L1.73995 12.6727C1.36844 13.0813 0.735992 13.1115 0.327334 12.7399C-0.0813234 12.3684 -0.11144 11.736 0.260067 11.3273L4.64855 6.50001L0.260067 1.67268C-0.11144 1.26402 -0.0813234 0.631574 0.327334 0.260067Z"/>
                                    </svg>
                                    Estimated Revenue Per Lead - {{ dateOfData }}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 flex flex-col">
                        <div class="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                            <nav class="flex pl-8 py-4" :class="[!darkMode ? 'bg-light-module' : 'bg-dark-module']">
                                <ol role="list" class="flex items-center space-x-4 text-lg">
                                    <li>
                                        <div>
                                            <p @click="changeScope('country')" class="font-bold text-sm cursor-pointer"
                                               :class="[!darkMode ? ((currentScope === 'country') ? 'text-blue-550' : 'text-gray-500 hover:text-gray-900') : ((currentScope === 'country') ? 'text-blue-550' : 'text-gray-500 hover:text-white')]">All States</p>
                                        </div>
                                    </li>
                                    <li v-if="currentScope !== 'country'">
                                        <div class="flex items-center">
                                            <ChevronRightIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"/>
                                            <p @click="changeScope('state', currentState)"
                                               class="ml-4 cursor-pointer font-bold text-sm"
                                               :class="[!darkMode ? ((currentScope === 'state') ? 'text-blue-550' : 'text-gray-500 hover:text-gray-900') : ((currentScope === 'state') ? 'text-blue-550' : 'text-gray-500 hover:text-white')]">{{ currentState }}</p>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                            <div class="w-full overflow-x-auto" style="max-height: 60vh;">
                                <div class="shadow-sm ring-1 ring-black ring-opacity-5">
                                    <table  class="min-w-full divide-y" :class="[!darkMode ? 'divide-gray-300' : 'divide-dark-175']">
                                        <thead>
                                        <tr class="divide-x" :class="[!darkMode ? 'divide-gray-200' : 'divide-dark-175']" v-if="currentScope !== 'state'">
                                            <th @click="sortTable('name')" scope="col"
                                                class="sticky left-0 top-0 whitespace-nowrap z-30 border-b backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                                <a href="#" class="group inline-flex">
                                                    State
                                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                                </a>
                                            </th>
                                            <th @click="sortTable('high_revenue_count')" scope="col"
                                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                                <a href="#" class="group inline-flex">
                                                    High Revenue ($200+)
                                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                                </a>
                                            </th>
                                            <th @click="sortTable('medium_revenue_count')" scope="col"
                                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                                <a href="#" class="group inline-flex">
                                                    Medium Revenue ($75 - $199)
                                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                                </a>
                                            </th>
                                            <th @click="sortTable('low_revenue_count')" scope="col"
                                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                                <a href="#" class="group inline-flex">
                                                    Low Revenue ($1 - $74)
                                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                                </a>
                                            </th>
                                            <th @click="sortTable('no_revenue_count')" scope="col"
                                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                                <a href="#" class="group inline-flex">
                                                    No Revenue
                                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                                </a>
                                            </th>
                                        </tr>
                                        <tr class="divide-x" :class="[!darkMode ? 'divide-gray-200' : 'divide-dark-175']" v-else>
                                            <th scope="col"
                                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                                <a href="#" class="group inline-flex">
                                                    Revenue
                                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                                </a>
                                            </th>
                                            <th scope="col"
                                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                                <a href="#" class="group inline-flex">
                                                    Zipcode Count
                                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                                </a>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody class="divide-y " :class="[!darkMode ? 'divide-gray-200 bg-light-background' : 'divide-dark-175 bg-dark-background']">
                                        <tr class="divide-x" :class="[!darkMode ? 'divide-gray-200' : 'divide-dark-175']" v-if="currentScope === 'country'" v-for="state in sortedStates">
                                            <td class="whitespace-nowrap left-0 sticky py-4 bg-opacity-50 pl-8 pr-3 text-sm font-medium sm:pl-8 cursor-pointer" :class="[!darkMode ? 'text-gray-600 hover:text-gray-900 bg-light-module' : 'text-grey-300 hover:text-white bg-dark-module']"
                                                @click="changeScope('state', state.name)">{{ state.name }}
                                            </td>
                                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']"
                                                @click="downloadZips(state.high_revenue_zips)" >
                                                {{ formatNumber(state.high_revenue_count) }} / {{ formatNumber(state.zip_count) }}
                                            </td>
                                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']"
                                                @click="downloadZips(state.medium_revenue_zips)">
                                                {{ formatNumber(state.medium_revenue_count) }} / {{ formatNumber(state.zip_count) }}
                                            </td>
                                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']"
                                                @click="downloadZips(state.low_revenue_zips)">
                                                {{ formatNumber(state.low_revenue_count) }} / {{ formatNumber(state.zip_count) }}
                                            </td>
                                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']"
                                                @click="downloadZips(state.no_revenue_zips)">
                                                {{ formatNumber(state.no_revenue_count) }} / {{ formatNumber(state.zip_count) }}
                                            </td>
                                        </tr>

                                        <tr class="divide-x" :class="[!darkMode ? 'divide-gray-200' : 'divide-dark-175']" v-else v-for="(zips, revenue)  in data[currentState].revenue_groups">
                                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">${{ formatNumber(revenue) }}</td>
                                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']" @click="downloadZips(zips)">{{ formatNumber(zips.length) }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <loading-spinner class="h-80 flex items-center justify-center" v-if="loading" :dark-mode="darkMode"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AlertsContainer from "../../Shared/components/AlertsContainer";
import LoadingSpinner from "../../Shared/components/LoadingSpinner";
import {ChevronDownIcon, ChevronRightIcon} from '@heroicons/vue/solid'
import ApiService from "./services/api/api";
import {DateTime} from "luxon";

export default {
    name: "EstimatedRevenuePerLeadReport",
    components: {
        AlertsContainer,
        LoadingSpinner,
        ChevronRightIcon,
        ChevronDownIcon
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
    },
    data: function() {
        return {
            data: null,
            loading: true,
            apiService: null,
            currentScope: 'country',
            sortedStates: null,
            sortedRevenueGroups: null,
            alertActive: false,
            alertType: '',
            alertText: '',
            dateOfData: null
        }
    },
    created() {
        this.apiService = ApiService.make();
        this.getData();
    },
    methods: {
        getData() {
            this.loading = true;
            this.apiService.getEstimatedRevenuePerLead({
                //'industry': this.industry.toLowerCase(),
            }).then(resp => {
                this.data = resp.data.data;
                let date = DateTime.fromSeconds(this.data['California']['summary']['timestamp']).setZone('America/Los_Angeles');
                this.dateOfData = `${date.toLocaleString(DateTime.DATETIME_SHORT)} PST`;
            }).catch(e => console.log(e)).finally(() => {
                this.sortTable();
                this.loading = false;
            });
        },
        formatNumber(value) {
            return value ? value.toLocaleString("en-US") : 0;
        },
        downloadZips(zips) {
            let zipList = zips.join("\n");
            navigator.clipboard.writeText(zipList);

            let text = `Copied ${zips.length} zip codes`;
            this.activateAlert('success', text);
        },
        activateAlert(type, text) {
            this.alertActive = true;
            this.alertType = type;
            this.alertText = text;
            setTimeout(()=>{
                this.alertActive = false
            },6000)
        },
        changeScope(scope, name) {
            this.currentScope = scope;

            if (scope === 'state') {
                this.currentState = name;
            }
        },
        sortTable(sortValue = 'name') {
            let sortedLocations = [];
            let valueIndex = [];
            let arrayToSort = [];
            let data = [];
            let indexes = [];
            let usedIndexes = [];

            Object.entries(this.data).forEach(function(item, index) {
                valueIndex.push(item[1].summary[sortValue]);
                arrayToSort.push(item[1].summary[sortValue]);
                data.push(item[1].summary);
            });

            switch(sortValue) {
                case 'low_revenue_count':
                case 'medium_revenue_count':
                case 'high_revenue_count':
                case 'no_revenue_count':
                    arrayToSort.sort((a,b) => b - a);
                    break;
                case 'name':
                default:
                    arrayToSort.sort();
                    break;
            }

            arrayToSort.forEach(function (item, index) {
                for (let index = 0; index < valueIndex.length; index++) {
                    if (valueIndex[index] === item) {
                        if(!usedIndexes.includes(index)) {
                            indexes.push(index);
                        }
                    }
                }

                sortedLocations.push(data[indexes[0]]);
                usedIndexes.push(indexes[0]);
                indexes = [];
            });

            this.sortedStates = sortedLocations;
        },
    }
}
</script>

<style scoped>

</style>
