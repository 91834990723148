<template>
    <div class="flex flex-col">
        <div class="-m-1.5 overflow-x-auto">
            <div class="p-1.5 min-w-full inline-block align-middle">
                <div class="overflow-hidden">
                    <table class="min-w-full divide-y"
                           :class="{'divide-gray-200': !darkMode, 'divide-gray-700': darkMode}">
                        <thead>
                        <tr>
                            <th v-for="(th, idx) in thead" :key="idx" scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                {{ th }}
                            </th>
                        </tr>
                        </thead>
                        <tbody class="divide-y" :class="{'divide-gray-200': !darkMode, 'divide-gray-100': darkMode}">
                        <tr v-for="(data, idx) in datas" :key="idx">
                            <td v-for="(td, ky) in tbody" :key="ky"
                                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                                <a v-if="td == 'lead_id'" :href="`/lead-processing?lead_id=${data[td]}`"
                                   class="hover:underline hover:text-blue-600">{{ data[td] ?? '-' }}</a>
                                <a v-else-if="td == 'quote_id'"
                                   :href="`https://legacy.admin-sr.com/quote.php?quoteid=${data[td]}`"
                                   class="hover:underline hover:text-blue-600">{{ data[td] ?? '-' }}</a>
                                <span v-else>{{ data[td] ?? '-' }}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "TableLeadReport",
    components: {},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        datas: {
            type: Object,
            default: {}
        },
        thead: {
            type: Array,
            default: {}
        },
        tbody: {
            type: Array,
            default: {}
        }
    }
}
</script>
