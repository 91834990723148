import axios from 'axios';

export default class ApiService {
    constructor(baseUrl, baseEndpoint, version) {
        this.baseEndpoint = baseEndpoint;
        this.baseUrl = baseUrl;
        this.version = version;
    }

    axios() {
        const axiosConfig = {
            baseURL: `/${this.baseUrl}/v${this.version}/${this.baseEndpoint}`
        }

        return axios.create(axiosConfig);
    }

    static make() {
        return new ApiService('internal-api', 'historical-available-budgets', 1);
    }

    getDateOptions() {
        return this.axios().get('/date-options');
    }

    getHistoricalAvailableBudgets(params) {
        return this.axios().get('/', {params});
    }

    getCompanyDetails(params) {
        return this.axios().get('/company-details', {params});
    }
}
