<template>
    <div>
        <div class="px-10 mt-3">
            <breadcrumbs :dark-mode="darkMode" @page-selected="breadcrumbPageSelected" :pages="breadcrumbPages"></breadcrumbs>
        </div>
        <div class="w-full flex-auto pt-3 relative" :class="{'bg-light-background': !darkMode, 'bg-dark-background': darkMode}">
            <div class="px-10" :class="[darkMode ? 'text-white' : 'text-slate-900']">
                <div class="border"
                     :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
                    <div>
                        <tab :tabs="tabs" :dark-mode="darkMode" :tabs-classes="'xl:w-1/2 md:w-2/3'" :show-total="false" @selected="tabSelected" :default-tab-index="selectedTabIndex"></tab>
                    </div>
                    <div>
                        <website-management v-if="selectedTabName === 'Websites'" :dark-mode="darkMode"
                                            @add-breadcrumb-page="addBreadcrumbPage" :breadcrumb-pages="breadcrumbPages"
                                            :website-data="website"/>
                        <industry-setup v-if="selectedTabName === 'Industries'" :dark-mode="darkMode"
                                        @add-breadcrumb-page="addBreadcrumbPage" :breadcrumb-pages="breadcrumbPages"
                                        :industry="industryId"/>
                        <configurable-fields v-if="selectedTabName === 'Configurable Fields'" :dark-mode="darkMode"/>
                        <product-management :dark-mode="darkMode" v-if="selectedTabName === 'Products'"
                                            @add-breadcrumb-page="addBreadcrumbPage" :breadcrumb-pages="breadcrumbPages"
                                            :product-data="product"/>
                        <companies :dark-mode="darkMode" v-if="selectedTabName === 'Companies'"  @add-breadcrumb-page="addBreadcrumbPage"></companies>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Tab from "../Shared/components/Tab";
import WebsiteManagement from "./WebsiteManagement/WebsiteManagement";
import Breadcrumbs from "../Shared/components/Breadcrumbs";
import ProductManagement from "./ProductManagement/ProductManagement";
import ConfigurableFields from "./ConfigurableFields/ConfigurableFields";
import IndustrySetup from "./IndustrySetup/IndustrySetup";
import Companies from "./Companies/Companies";

export default {
    name: "IndustryManagement",
    components: {
        IndustrySetup,
        Tab,
        WebsiteManagement,
        ConfigurableFields,
        Breadcrumbs,
        ProductManagement,
        Companies
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        breadcrumbData: {
            type: Object,
            default: {
                name: 'Websites',
                id: 'websites'
            }
        },
        industry: {
            type: Object,
            default: null
        },
        website: {
            type: Object,
            default: null
        },
        product: {
            type: Object,
            default: null
        }
    },
    created() {
        this.breadcrumbData.forEach((element) => {
            this.addBreadcrumbPage(element);
        });
        this.breadcrumbPageSelected(this.breadcrumbPages[0], true);
        this.industryId = (this.industry) ? this.industry?.id : null;
    },
    data () {
        return {
            tabs: [
                {name: 'Websites', current: true},
                {name: 'Industries', current: false},
                {name: 'Products', current: false},
                {name: 'Configurable Fields', current: false},
                {name: 'Companies', current: false}
            ],
            breadcrumbPages: [],
            selectedTabName: 'Websites',
            selectedTabIndex: 0,
            industryId: null,
        }
    },
    methods: {
        tabSelected (tabName) {
            this.selectedTabName = tabName;

            switch (tabName) {
                case 'Websites':
                    this.breadcrumbPages = [
                        {name: 'Websites', id: 'websites'}
                    ];
                    break;
                case 'Products':
                    this.breadcrumbPages = [
                        {name: 'Products', id: 'products'}
                    ];
                    break;
                case 'Industries':
                    this.breadcrumbPages = [
                        {name: 'Industries', id: 'industries'}
                    ];
                    this.industryId = null;
                    break;
                case 'Configurable Fields':
                    this.breadcrumbPages = [
                        {name: 'Configurable Fields', id: 'configurable-fields'}
                    ];
                    break;
                case 'Companies':
                    this.breadcrumbPages = [
                        {name: 'Companies', id: 'companies'}
                    ];
                    break;
            }

            this.updateUrl();
        },
        breadcrumbPageSelected(page, initial = false) {
           this.unsetCurrentTab();

            if (this.tabs.find(tab => tab.name === page.name)) this.selectedTabName = page.name;

            const breadcrumbIndex = this.breadcrumbPages.findIndex(pg => pg.id = page.id);
            if(!initial) this.breadcrumbPages = (breadcrumbIndex !== -1) ? this.breadcrumbPages.filter((page, index) => index <= breadcrumbIndex) : this.breadcrumbPages[0];
            else if(breadcrumbIndex === -1) this.breadcrumbPages = this.breadcrumbPages[0];

            if(initial) this.tabs[0].current = true;
            else {
                let tabIndex = this.tabs.findIndex(tab => tab.name === page.name);
                if(tabIndex === -1) {
                    //when the breadcrumb is clicked go to the corresponding tab or if not found go to the first tab
                    tabIndex = this.tabs.findIndex(tab => tab.name === this.breadcrumbPages[0].name) !== -1 ? this.tabs.findIndex(tab => tab.name === this.breadcrumbPages[0].name) : 0;
                }
                this.tabs[tabIndex].current = true;
                this.selectedTabIndex = tabIndex;
            }


            this.updateUrl();
        },
        addBreadcrumbPage(page) {
            this.breadcrumbPages.push(page);
            this.updateUrl();
        },
        updateUrl() {
            let url = this.breadcrumbPages.map(breadcrumbs => breadcrumbs.id).join('/');
            history.pushState({}, undefined, '/industry-management/' + url);
        },
        unsetCurrentTab() {
            let tabIndex = this.tabs.findIndex(tab => tab.name === this.selectedTabName);
            this.tabs[tabIndex].current = false;
        }
    }
}
</script>
