<template>
    <div class="p-5">
        <div class="flex items-center justify-between mb-3">
            <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Industry - Website List</h5>
            <button
                class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5" @click="openModal">
                Add New Website
            </button>
        </div>
        <div class="grid grid-cols-9 gap-x-3 mb-2 px-5" v-if="!loading">
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Industry</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Website</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Slug</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Created At</p>
        </div>
        <div class="border-t border-b" v-if="!loading"
             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-40 border-dark-border': darkMode}">
            <div>
                <div class="grid grid-cols-9 gap-x-3 border-b px-5 py-3 items-center"
                     v-for="industryWebsite in industryWebsites" :key="industryWebsite.id"
                     :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <p class="text-sm col-span-2 truncate">
                        {{ industryWebsite.industry }}
                    </p>
                    <p class="text-sm col-span-2 truncate">
                        {{ industryWebsite.website.name }}
                    </p>
                    <p class="text-sm col-span-2 truncate">
                        {{ industryWebsite.slug }}
                    </p>
                    <p class="text-sm col-span-2">
                        {{ formatDate(industryWebsite.created_at) }}
                    </p>
                    <ActionsHandle :dark-mode="darkMode" @edit="editIndustryWebsite(industryWebsite)" @delete="deleteIndustryWebsite(industryWebsite)"/>
                </div>
            </div>
        </div>
        <div class="h-32 flex items-center justify-center" v-if="loading">
            <loading-spinner></loading-spinner>
        </div>
        <modal :small="true" v-if="showModal" @close="closeModal" :dark-mode="darkMode" @confirm="saveIndustryWebsite" :close-text="'Cancel'" :confirm-text="confirmText">
            <template v-slot:header>
                <h4>{{ industryWebsite.id ? 'Update' : 'Create' }} Industry Website</h4>
            </template>
            <template v-slot:content>
                <div class="grid gap-3">
                    <div v-if="!industryWebsite.id" class="flex items-center">
                        <p class="uppercase font-semibold text-xs w-20"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Website
                        </p>
                        <Dropdown :dark-mode="darkMode" v-model="industryWebsite.website.id" :options="systemWebsites" placeholder="Website" :selected="industryWebsite.website.id"></Dropdown>
                    </div>
                    <div class="flex items-center">
                        <p class="uppercase font-semibold text-xs w-20"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Slug
                        </p>
                        <input class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                               placeholder="Website Slug"
                               v-model="industryWebsite.slug"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                    </div>
                </div>
            </template>
        </modal>
        <alerts-container :dark-mode="darkMode" :alert-type="'error'" :text="error" v-if="error"></alerts-container>
    </div>

</template>

<script>
import Dropdown from "../../../Shared/components/Dropdown";
import Modal from "../../../Shared/components/Modal";
import ApiService from "../services/api";
import ActionsHandle from "../../../Shared/components/ActionsHandle";
import LoadingSpinner from "../../../Shared/components/LoadingSpinner";
import AlertsContainer from "../../../Shared/components/AlertsContainer";
import {DateTime} from "luxon";

export default {
    name: "IndustryWebsites",
    components: {Dropdown, Modal, ActionsHandle, LoadingSpinner, AlertsContainer},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        industry: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            api: ApiService.make(),
            showModal: false,
            industryWebsites: [],
            industryWebsite: {website: {id: null, name: null}, slug: null},
            systemWebsites: [],
            saving: false,
            loading: false,
            error: null
        }
    },
    created() {
        this.getIndustryWebsites();
        this.getSystemWebsites();
    },
    computed: {
        confirmText: function () {
            if (this.saving) return 'Saving...';
            if (this.industryWebsite.id) return 'Update';

            return 'Create';
        }
    },
    methods: {
        openModal() {
            this.showModal = true;
        },
        closeModal () {
            this.showModal = false;
            this.industryWebsite = {website: {id: null, name: null}, slug: null};
        },
        getIndustryWebsites() {
            if(!this.industry) return;
            if(!this.loading) this.loading = true;
            this.error = null;

            this.api.getIndustryWebsites(this.industry).then(resp => this.industryWebsites = resp.data.data.websites)
                .catch(e => this.error = e.response.data.message).finally(() => this.loading = false);
        },
        saveIndustryWebsite() {
            if(this.saving) return;
            this.saving = true;
            this.error = null;

            if (this.industryWebsite.id) {
                this.updateIndustryWebsite();
                return;
            }
            this.createIndustryWebsite();
        },
        getParamsToMakeRequest() {
            return {
                website_id  : this.industryWebsite.website.id,
                slug        : this.industryWebsite.slug
            }
        },
        createIndustryWebsite() {
            this.api.createIndustryWebsite(this.industry, this.getParamsToMakeRequest()).then(() => this.refreshList(true, true))
              .catch(e => this.error = e.response.data.message).finally(() => this.saving = false);
        },
        editIndustryWebsite(industryWebsite) {
            this.industryWebsite = {...industryWebsite};
            this.openModal();
        },
        updateIndustryWebsite() {
            this.api.updateIndustryWebsite(this.industryWebsite.id, this.industry, this.getParamsToMakeRequest()).then(() => this.refreshList(true))
              .catch(e => this.error = e.response.data.message).finally(() => this.saving = false);
        },
        deleteIndustryWebsite(industryWebsite) {
            this.error = null;
            this.api.deleteIndustryWebsite(industryWebsite.id).then(() => this.refreshList(false, true))
                .catch(e => this.error = e.response.data.message);
        },
        formatDate(date) {
            return date ? DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT) : null
        },
        getSystemWebsites() {
            this.api.getNonAddedWebsites(this.industry).then(resp => this.systemWebsites = resp.data.data.websites)
                .catch(e => this.error = e.response.data.message);
        },
        refreshList(closeModal = false, fetchSystemWebsites = false) {
            this.getIndustryWebsites();
            if(closeModal) this.closeModal();
            if(fetchSystemWebsites) this.getSystemWebsites();
        }
    }
}
</script>
