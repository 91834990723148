import {isObject} from '../helpers';

/**
 * @typedef contact
 * @property {number} id
 * @property {string} name
 * @property {string} title
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} email
 * @property {number|boolean} status
 * @property {string|null} date_registered
 * @property {string} cell_phone
 * @property {string} office_phone
 * @property {number|boolean} is_contact
 * @property {number|null} total_calls_count
 * @property {number|null} total_calls_over_one_minute_count
 * @property {number|null} latest_call_timestamp
 */

/**
 * @param {object} contact
 */
export const validateContact = (contact) => {
    if (!(isObject(contact))) {
        throw new TypeError(`The contact must be an object. ${contact} passed.`);
    }

    const properties = [
        {
            name: 'id',
            type: 'integer',
            required: true
        },
        {
            name: 'name',
            type: 'string',
            required: true
        },
        {
            name: 'first_name',
            type: 'string',
            required: true
        },
        {
            name: 'last_name',
            type: 'string',
            required: true
        },
        {
            name: 'title',
            type: 'string',
            required: true
        },
        {
            name: 'email',
            type: 'string',
            required: true
        },
        {
            name: 'office_phone',
            type: 'string',
            required: true
        },
        {
            name: 'cell_phone',
            type: 'string',
            required: true
        },
        {
            name: 'total_calls_count',
            type: 'integer',
            required: false
        },
        {
            name: 'total_calls_over_one_minute_count',
            type: 'integer',
            required: false
        },
        {
            name: 'latest_call_timestamp',
            type: 'integer',
            required: false
        },
    ];

    properties.forEach((property) => {
        if (!contact.hasOwnProperty(property.name) && property.required) {
            throw new TypeError(`All contacts must have the following property: ${property.name}. ${JSON.stringify(contact)} is missing it.`)
        }

        const propertyIsNullOrUndefined = contact[property.name] === null || contact[property.name] === undefined;

        const dontTypeCheckProperty = propertyIsNullOrUndefined && !property.required;

        const typeCheckProperty = !dontTypeCheckProperty;

        if (contact.hasOwnProperty(property.name) && property.type === 'string') {
            if (typeCheckProperty && !(typeof contact[property.name] === 'string')) {
                throw new TypeError(`contact.${property.name} must be a string. ${contact[property.name]} passed.`);
            }
        }

        if (contact.hasOwnProperty(property.name) && property.type === 'integer') {

            if (typeCheckProperty && !(Number.isInteger(contact[property.name]))) {
                throw new TypeError(`contact.${property.name} must be an integer. ${contact[property.name]} passed.`);
            }
        }
    })
}

/**
 * @param {Array} contacts
 */
export const validateContacts = (contacts) => {
    contacts.forEach((contact) => validateContact(contact))
}

/**
 * @param phone
 * @return {boolean}
 */
export const phoneIsValid = (phone) => {
    if (!(typeof phone === 'string')) {
        throw new TypeError(`"phone" must a string. ${phone} passed.`);
    }

    return phone && phone.trim().length > 0 && getValidPhoneNumber(phone)
}

/**
 * @param contact
 * @param {"office_phone"|"cell_phone"} phoneType
 * @return {string|null}
 */
export const getPhoneNumber = (contact, phoneType) => {

    const validate = (contact, phoneType) => {
        if (!isObject(contact)) {
            throw new TypeError(`"contact" must be an object.`)
        }

        if (!contact.hasOwnProperty(phoneType)) {
            throw new TypeError(`"contact" must have "cell_phone" property.`)
        }

        if (!(typeof contact[phoneType] === 'string')) {
            throw new TypeError(`"contact.${phoneType}" must be a string. ${contact[phoneType]} passed.`)
        }

        if (!phoneIsValid(contact[phoneType])) {
            throw new TypeError(`"contact.${phoneType} is not a valid phone number.`);
        }

        return contact[phoneType].trim();
    }

    if (phoneType === 'cell_phone') {
        return validate(contact, 'cell_phone')
    } else if (phoneType === 'office_phone') {
        return validate(contact, 'office_phone')
    } else {
        throw new TypeError(`"phoneType" must be either "cell_phone" or "office_phone". ${phoneType} passed.`)
    }
}

/**
 * @param contact
 * @return {boolean}
 */
export const contactIsValid = (contact) => {
    if (!isObject(contact)) throw new TypeError(`"contact" must be an object.`)

    if (!contact.hasOwnProperty('office_phone')) throw new TypeError(`"contact" must have "office_phone" property.`)

    if (!contact.hasOwnProperty('cell_phone')) throw new TypeError(`"contact" must have "office_phone" property.`)

    return (phoneIsValid(contact.office_phone)) || (phoneIsValid(contact.cell_phone));
}

/**
 *
 * @param number
 * @return {string|null}
 */
export const extractNumberFromString = (number) => {
    if (!(typeof number === 'string')) throw new TypeError(`"number" should be a string. ${number} passed.¬`)

    const result = ('' + number).replace(/\D/g, '');

    return !result ? null : result;
}

/**
 * @param number
 * @return {RegExpMatchArray|null}
 */
export const getValidPhoneNumber = (number) => {
    if (!(typeof number === 'string')) throw new TypeError(`"number" should be a string. ${number} passed.¬`)

    return number.match(/^(\d{3})(\d{3})(\d{4})$/);
}

/**
 * @param phoneNumberString
 * @return {null|string}
 */
export const formatPhoneNumber = (phoneNumberString) => {
    if (!(typeof phoneNumberString === 'string')) throw new TypeError(`"phoneNumberString" should be a string. ${phoneNumberString} passed.`)

    const cleaned = extractNumberFromString(phoneNumberString)
    if (cleaned) {
        const match = getValidPhoneNumber(cleaned)
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
    }
    return null;
}
