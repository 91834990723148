<template>
    <div class="border rounded-md h-full w-full"
         :class="{'bg-white border-grey-120': !darkMode, 'bg-dark-0 border-dark-175': darkMode}">
        <div class="px-5 pt-5 pb-1">
            <div class=" flex items-center justify-between">
                <h5 class="text-blue-550 text-sm uppercase font-medium leading-tight">
                    Unsold and Undersold Leads <span v-if="county">in {{ county }} county</span></h5>
            </div>
            <div class="pt-3">
                <p class="uppercase font-semibold text-xs mb-1 mt-5" :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                    Industry
                </p>

                <dropdown placeholder="Select Industry" v-model="industry" :options="industryOptions" :dark-mode="darkMode" :selected="industry" :key="industry" :disabled="loading">
                </dropdown>
            </div>
            <div class="mb-5 pb-3">
                <p class="uppercase font-semibold text-xs mb-1 mt-5" :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                    Lead Days
                </p>

                <dropdown placeholder="Select Industry" v-model="leadDays" :options="leadDaysOptions" :dark-mode="darkMode" :selected="leadDays" :key="leadDays" :disabled="loading">
                </dropdown>
            </div>
            <div class="grid grid-cols-6 gap-x-3 mt-2">
                <p class="text-blue-400 uppercase text-xs col-span-2">Lead Id</p>
                <p class="text-blue-400 uppercase text-xs col-span-2">Status</p>
                <p class="text-blue-400 uppercase text-xs col-span-2">industry</p>
            </div>
        </div>
        <div class="border-t border-b h-100 overflow-y-auto"
             :class="{'bg-grey-40  border-grey-120': !darkMode, 'bg-dark-background border-dark-175': darkMode, 'flex flex-col items-center justify-center': loading}">
            <loading-spinner v-if="loading"></loading-spinner>
            <div v-else>
                <div class="border-b py-3 text-center text-xs font-bold uppercase bg-white">
                    In Home Appointment Leads
                </div>
                <div class="grid grid-cols-6 gap-x-3 border-b px-3 py-2 transition duration-200"
                     v-for="lead in leads.home"
                     :class="{'text-grey-800 hover:bg-white border-grey-175': !darkMode, 'text-grey-200 hover:bg-dark-0 border-dark-175': darkMode}">
                    <div class="text-cyan-500 col-span-2 cursor-pointer">
                        <a :href="getLegacyAdminQuoteUrl(lead.id)" target="_blank">
                            {{ lead.id }}
                        </a>
                    </div>
                    <div class="text-cyan-500 col-span-2">{{ lead.sold_status }}</div>
                    <div class="text-cyan-500 col-span-2">{{ `${industry.charAt(0).toUpperCase()}${industry.slice(1)}` }}</div>
                </div>

                <div class="grid grid-cols-6 gap-x-3 border-b px-3 py-2 transition duration-200">
                    <div class="col-span-2 uppercase text-xs">Total: {{ leads.home.length }}</div>
                    <div class="col-span-2 uppercase text-xs" v-if="leads.home.length">Unsold: {{ unsoldLeads(leads.home).length }}</div>
                    <div class="col-span-2 uppercase text-xs" v-if="leads.home.length">Undersold: {{ undersoldLeads(leads.home).length }}</div>
                </div>

                <div class="border-b py-3 text-center text-xs font-bold uppercase bg-white">
                    Online Consultation Leads
                </div>
                <div class="grid grid-cols-6 gap-x-3 border-b px-3 py-2 transition duration-200"
                     v-for="lead in leads.online"
                     :class="{'text-grey-800 hover:bg-white border-grey-175': !darkMode, 'text-grey-200 hover:bg-dark-0 border-dark-175': darkMode}">
                    <div class="text-cyan-500 col-span-2 cursor-pointer">
                        <a :href="getLegacyAdminQuoteUrl(lead.id)" target="_blank">
                            {{ lead.id }}
                        </a>
                    </div>
                    <div class="text-cyan-500 col-span-2">{{ lead.sold_status }}</div>
                    <div class="text-cyan-500 col-span-2">{{ `${industry.charAt(0).toUpperCase()}${industry.slice(1)}` }}</div>
                </div>

                <div class="grid grid-cols-6 gap-x-3 border-b px-3 py-2 transition duration-200">
                    <div class="col-span-2 uppercase text-xs">Total: {{ leads.online.length }}</div>
                    <div class="col-span-2 uppercase text-xs" v-if="leads.online.length">Unsold: {{ unsoldLeads(leads.online).length }}</div>
                    <div class="col-span-2 uppercase text-xs" v-if="leads.online.length">Undersold: {{ undersoldLeads(leads.online).length }}</div>
                </div>

                <div class="border-b py-3 text-center text-xs font-bold uppercase bg-white">
                    Other Leads
                </div>
                <div class="grid grid-cols-6 gap-x-3 border-b px-3 py-2 transition duration-200"
                     v-for="lead in leads.others"
                     :class="{'text-grey-800 hover:bg-white border-grey-175': !darkMode, 'text-grey-200 hover:bg-dark-0 border-dark-175': darkMode}">
                    <div class="text-cyan-500 col-span-2 cursor-pointer">
                        <a :href="getLegacyAdminQuoteUrl(lead.id)" target="_blank">
                            {{ lead.id }}
                        </a>
                    </div>
                    <div class="text-cyan-500 col-span-2">{{ lead.sold_status }}</div>
                    <div class="text-cyan-500 col-span-2">{{ `${industry.charAt(0).toUpperCase()}${industry.slice(1)}` }}</div>
                </div>

                <div class="grid grid-cols-6 gap-x-3 border-b px-3 py-2 transition duration-200">
                    <div class="col-span-2 uppercase text-xs">Total: {{ leads.others.length }}</div>
                    <div class="col-span-2 uppercase text-xs" v-if="leads.others.length">Unsold: {{ unsoldLeads(leads.others).length }}</div>
                    <div class="col-span-2 uppercase text-xs" v-if="leads.others.length">Undersold: {{ undersoldLeads(leads.others).length }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import SharedApiService from "../services/api";
import {ApiFactory} from "../../LeadProcessing/services/api/factory";
import Dropdown from "../components/Dropdown";
import LoadingSpinner from "../components/LoadingSpinner";
import LegacyAdminMixin from "../mixins/legacy-admin-mixin";

export default {
    name: "UnsoldAndUndersoldLeads",
    components: {Dropdown, LoadingSpinner},
    props: {
        leadId: {
            type: Number,
            default: null
        },
        zipCode: {
            type: String,
            default: null
        },
        darkMode: {
            type: Boolean,
            default: false
        }
    },
    mixins: [LegacyAdminMixin],
    data () {
        return {
            api: SharedApiService.make(),
            leadApi: ApiFactory.makeApiService("api"),
            leads: {
                home: [],
                online: [],
                others: []
            },
            queryZipCode: '',
            county: null,
            loading: false,
            industry: 'solar',
            leadDays: 2,
            industryOptions: [
                {id: 'solar', name: 'Solar'},
                {id: 'roofing', name: 'Roofing'}
            ],
            leadDaysOptions: [
                {id: 2, name: 2},
                {id: 7, name: 7},
                {id: 14, name: 14},
                {id: 30, name: 30}
            ]
        }
    },
    created() {
        if (this.zipCode) this.getLeads();
        else this.getLeadBasicInfo();
    },
    watch: {
        industry: function () {
            this.getLeads();
        },
        leadDays: function () {
            this.getLeads();
        }
    },
    methods: {
        getLeadBasicInfo() {
            this.loading = true;
            this.leadApi.getLeadBasicInfo(this.leadId)
                .then(resp => {
                    this.queryZipCode = resp.data.data.lead.address.zip_code;
                    this.county = resp.data.data.lead.address.county;
                    this.getLeads();
                })
                .catch(e => console.error(e))
                .finally(() => {
                    this.loading = false;
                });
        },
        getLeads() {
            this.loading = true;
            this.resetLeads();
            Promise.all([
                this.api.getUnsoldLeadsInCounty(this.queryZipCode, this.industry, this.leadDays),
                this.api.getUndersoldLeadsInCounty(this.queryZipCode, this.industry, this.leadDays)
            ])
                .then(resp => {
                    this.groupLeads(resp[0].data.data.leads);
                    this.groupLeads(resp[1].data.data.leads)
                })
                .catch(e => console.error(e))
                .finally(() => this.loading = false);
        },
        groupLeads(leads) {
            leads.forEach(lead => {
                if (lead.contact_method === 'online-consultation' && lead.online_consultation_times) this.leads.online.push(lead);
                else if(lead.contact_method === 'in-home-appointment' && lead.home_appointment_times) this.leads.home.push(lead);
                else this.leads.others.push(lead);
            });
        },
        unsoldLeads(leads) {
            return leads.filter(lead => lead.sold_status.toLowerCase() === 'unsold');
        },
        undersoldLeads(leads) {
            return leads.filter(lead => lead.sold_status.toLowerCase() === 'undersold');
        },
        resetLeads() {
            this.leads = {
                home: [],
                online: [],
                others: []
            }
        }
    }
}
</script>

<style scoped>

</style>
