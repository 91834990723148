<template>
    <div>
        <div v-if="loading">
            <loading-spinner/>
        </div>
        <div v-else-if="phoneData && emailData">

            <div class="grid grid-cols-1">
                <div class="border-b py-4"
                     :class="{'border-light-border' : !darkMode, 'border-dark-border' : darkMode}">
                    <div class="flex items-center flex-wrap px-5">
                        <div class="flex items-center flex-wrap gap-2">
                            <h5 class="text-sm font-medium mr-2"
                                :class="{'text-grey-475' : !darkMode, 'text-grey-120' : darkMode}">Matches:</h5>
                            <p v-if="transactionData.transaction_details.email_name_identity_match === 'Match'" :class="[darkMode ? '' : 'bg-green-150']" class="text-green-550 text-xs inline-flex mr-3 px-4 items-center rounded-full py-1 font-medium whitespace-no-wrap">Name-Email</p>
                            <p v-if="transactionData.transaction_details.name_address_identity_match === 'Match'" :class="[darkMode ? '' : 'bg-green-150']" class="text-green-550 text-xs inline-flex mr-3 px-4 items-center rounded-full py-1 font-medium whitespace-no-wrap">Name-Address</p>
                            <p v-if="transactionData.transaction_details.phone_name_identity_match === 'Match'" :class="[darkMode ? '' : 'bg-green-150']" class="text-green-550 text-xs inline-flex mr-3 px-4 items-center rounded-full py-1 font-medium whitespace-no-wrap">Name-Phone</p>
                            <p v-if="transactionData.transaction_details.phone_address_identity_match === 'Match'" :class="[darkMode ? '' : 'bg-green-150']" class="text-green-550 text-xs inline-flex mr-3 px-4 items-center rounded-full py-1 font-medium whitespace-no-wrap">Phone-Address</p>
                            <p v-if="transactionData.transaction_details.phone_email_identity_match === 'Match'" :class="[darkMode ? '' : 'bg-green-150']" class="text-green-550 text-xs inline-flex mr-3 px-4 items-center rounded-full py-1 font-medium whitespace-no-wrap">Phone-Email</p>
                            <!-- If No Matches -->
                            <p v-if="transactionData.transaction_details.email_name_identity_match !== 'Match' && transactionData.transaction_details.name_address_identity_match !== 'Match' && transactionData.transaction_details.phone_name_identity_match !== 'Match' && transactionData.transaction_details.phone_address_identity_match !== 'Match' && transactionData.transaction_details.phone_email_identity_match !== 'Match'" :class="[darkMode ? '' : 'bg-red-200']" class="text-red-500 text-xs inline-flex mr-3 px-4 items-center rounded-full py-1 font-medium whitespace-no-wrap">No Matches</p>
                        </div>
                    </div>
                </div>
                <div class="border-b py-4"
                     :class="{'border-light-border' : !darkMode, 'border-dark-border' : darkMode}">
                    <div class="flex items-center flex-wrap px-5 mb-2">
                        <div class="flex items-center flex-wrap mr-3 gap-2">
                            <h5 class="text-sm font-medium mr-2"
                                :class="{'text-grey-475' : !darkMode, 'text-grey-120' : darkMode}">Phone:</h5>
                            <div class="inline-flex mr-3 px-4 items-center rounded-full py-1 font-medium whitespace-no-wrap"
                                 :class="[darkMode ? (phoneData.valid === true ? 'text-green-550' : 'text-red-350') : (phoneData.valid === true ? 'text-green-550 bg-green-150' : 'text-red-350 bg-red-75')]"
                            >
                                <p v-if="phoneData.valid === true" class="text-xs">Valid</p>
                                <p v-else class="text-xs">Invalid</p>
                            </div>
                            <div class="inline-flex mr-3 px-4 items-center rounded-full py-1 font-medium text-blue-550"
                                 :class="[darkMode ? '' : 'bg-primary-100']">
                                <svg class="mr-2 fill-current flex-shrink-0" width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0H11V1.66667H0V0ZM0 4.16667H11V5.83333H0V4.16667ZM0 8.33333H11V10H0V8.33333Z"/></svg>
                                <p v-if="phoneData.line_type" class="text-xs">Line Type: {{ phoneData.line_type }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-2 px-5">
                        <div class="flex items-center" :class="[darkMode ? 'text-slate-400' : 'text-slate-500']">
                            <div class="text-sm">
                                Activity: {{ phoneData.user_activity }}
                            </div>
                        </div>
                        <div v-if="(phoneData.associated_email_addresses.emails).length > 0" class="col-span-2 flex items-center flex-wrap" :class="[darkMode ? 'text-slate-400' : 'text-slate-500']">
                            <div class="text-sm mr-2">
                                Associated Emails:
                            </div>
                            <div class="text-sm" :class="{'text-grey-475' : !darkMode, 'text-grey-120' : darkMode}"
                                 :title="phoneData.associated_email_addresses.emails.join(', ')">
                                {{ phoneData.associated_email_addresses.emails.join(', ') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="border-b py-4"
                     :class="{'border-light-border' : !darkMode, 'border-dark-border' : darkMode}">
                    <div class="flex items-center flex-wrap px-5 mb-2">
                        <div class="flex items-center flex-wrap mr-3 gap-2">
                            <h5 class="text-sm font-medium mr-2"
                                :class="{'text-grey-475' : !darkMode, 'text-grey-120' : darkMode}">Email:</h5>
                            <div class="px-4 inline-flex items-center rounded-full py-1 font-medium whitespace-no-wrap"
                                 :class="[darkMode ? (emailData.deliverability === 'high' ? 'text-green-550' : emailData.deliverability === 'medium' ? 'text-amber-500' : 'text-red-350') : (emailData.deliverability === 'high' ? 'text-green-550 bg-green-150' : emailData.deliverability === 'medium' ? 'text-amber-700 bg-amber-100' : 'text-red-350 bg-red-75')]"
                            >
                                <p v-if="emailData.deliverability === 'high'" class="text-xs">High Deliverability</p>
                                <p v-else-if="emailData.deliverability === 'medium'" class="text-xs">Medium
                                    Deliverability</p>
                                <p v-else class="text-xs">Low Deliverability</p>
                            </div>
                        </div>
                        <div class="flex items-center flex-wrap mr-3 gap-2">
                            <div class="px-4 inline-flex items-center rounded-full py-1 font-medium whitespace-no-wrap"
                                 :class="[darkMode ? (emailData.frequent_complainer === true ? 'text-green-550' : 'text-red-350') : (emailData.frequent_complainer === true ? 'text-green-550 bg-green-150' : 'text-red-350 bg-red-75')]"
                            >
                                <p v-if="emailData.frequent_complainer === true" class="text-xs">No Complaints</p>
                                <p v-else class="text-xs">Has Complaints</p>
                            </div>
                        </div>

                    </div>
                    <div class="grid grid-cols-2 gap-2 px-5">
                        <div class="flex items-center" :class="[darkMode ? 'text-slate-400' : 'text-slate-500']">
                            <div class="text-sm">
                                Activity: {{ emailData.user_activity }}
                            </div>
                        </div>
                        <div class="flex items-center" :class="[darkMode ? 'text-slate-400' : 'text-slate-500']">
                            <div class="text-sm">
                               Created: {{ emailData.first_seen.human }}
                            </div>
                        </div>
                        <div v-if="(emailData.associated_names.names).length > 0" class="flex items-center flex-wrap col-span-2"
                             :class="[darkMode ? 'text-slate-400' : 'text-slate-500']">
                            <div class="text-sm mr-2">
                                Associated Names:
                            </div>
                            <div class="text-sm" :class="{'text-grey-475' : !darkMode, 'text-grey-120' : darkMode}"
                                 :title="emailData.associated_names.names.join(', ')">
                                {{ emailData.associated_names.names.join(', ') }}
                            </div>
                        </div>

                        <div v-if="(emailData.associated_phone_numbers.phone_numbers).length > 0" class="flex items-center flex-wrap col-span-2"
                             :class="[darkMode ? 'text-slate-400' : 'text-slate-500']">
                            <div class="text-sm mr-2">
                                Associated Phones:
                            </div>
                            <div class="text-sm" :class="{'text-grey-475' : !darkMode, 'text-grey-120' : darkMode}"
                                 :title="emailData.associated_phone_numbers.phone_numbers.join(', ')">
                                {{ emailData.associated_phone_numbers.phone_numbers.join(', ') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="py-4 px-5">
                <div class="flex items-center justify-between mb-2">
                    <div class="flex items-center flex-wrap mr-3 gap-2">
                        <h5 class="text-sm font-medium mr-2"
                            :class="{'text-grey-475' : !darkMode, 'text-grey-120' : darkMode}">Transaction Data:</h5>
                        <div class="flex items-center flex-wrap mr-3 gap-2">
                            <div class="px-4 inline-flex items-center rounded-full py-1 font-medium whitespace-no-wrap"
                                 :class="[darkMode ? (transactionData.recent_abuse === false ? 'text-green-550' : 'text-red-350') : (transactionData.recent_abuse === false ? 'text-green-550 bg-green-150' : 'text-red-350 bg-red-75')]"
                            >
                                <p v-if="transactionData.recent_abuse === true" class="text-xs">Has Recent Abuse</p>
                                <p v-else class="text-xs">No Recent Abuse</p>
                            </div>
                        </div>
                    </div>
                    <div class="font-medium text-sm flex"
                         :class="{'text-grey-475' : !darkMode, 'text-grey-120' : darkMode}">
                        Fraud Score: {{ transactionData.fraud_score }}
                    </div>
                </div>
                <div class="grid grid-cols-2 gap-2">
                    <div class="col-span-2 flex items-center flex-wrap" :class="[darkMode ? 'text-slate-400' : 'text-slate-500']">
                        <div class="text-sm mr-2">
                            Risk Factors:
                        </div>
                        <div class="text-sm text-red-350">
                            {{ transactionData.transaction_details.risk_factors.join(', ') || ''}}
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div v-else class="border-t border-l border-r h-auto max-h-48 overflow-y-auto"
            :class="{'bg-light-background border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
            <div class="px-5 py-2 text-s border-b"
                 :class="{'text-grey-800 border-light-border': !darkMode, 'text-grey-200 border-dark-border': darkMode}">
                {{ error }}
            </div>
        </div>
    </div>
</template>

<script>
    import LoadingSpinner from "../../components/LoadingSpinner";
    import ApiService from "../../services/consumer_api";

    export default {
        name: "IpQualityScoreVerification",
        components: {
            LoadingSpinner
        },
        props: {
            darkMode: {
                type: Boolean,
                default: false
            },
            consumerProductId: {
                type: Number,
                default: null
            },
        },
        data: function() {
            return {
                loading: true,
                phoneData: null,
                emailData: null,
                transactionData: null,
                api: ApiService.make(),
                error: "Problem retrieving IP Quality Score. Please try later!"
            };
        },
        created: function() {
            if(this.transactionData == null && this.consumerProductId !== null) {
                this.getConsumerProductVerification();
            }
        },
        watch: {
            consumerProductId(newVal, oldVal) {
                if(newVal !== oldVal) {
                    this.getConsumerProductVerification();
                }
            }
        },
        methods: {
            async getConsumerProductVerification() {
                this.api.getConsumerProductVerification(this.consumerProductId, 'ip_quality_score').then(res => {
                    if (res.data.data.status === true) {
                        this.phoneData = res.data.data.product_verification.phone;
                        this.emailData = res.data.data.product_verification.email;
                        this.transactionData = res.data.data.product_verification.transaction;
                    }
                }).catch(err => console.log(err)).finally(() => {
                    this.loading = false;
                });
            }
        }
    }
</script>
