<template>
  <div class="flex items-center">
    <input v-if="type === 'text'" v-model="temporaryField"
           class="border rounded px-2"
           :class="{'border-grey-200 bg-light-module': !darkMode, 'border-blue-400 bg-dark-background text-blue-400': darkMode}"
    />
    <select v-if="type ==='select'"
            v-model="temporaryField"
            class="border rounded pr-8 text-xs leading-none cursor-pointer"
            :class="{'border-primary-500 bg-light-module': !darkMode, 'border-blue-400 bg-dark-background text-blue-400': darkMode}">
      <option v-for="option in options" :key="option.id" :value="option.id">{{ option.value }}</option>
    </select>
    <button @click="saveField" class="transition duration-200 text-xs font-medium focus:outline-none rounded py-1 px-3 mx-2"
            :class="{'bg-primary-500 hover:bg-blue-500 text-white': !darkMode, 'bg-primary-500 hover:bg-grey-600 text-white': darkMode}">
      Save
    </button>
    <button @click="cancelEdit" class="transition duration-200 text-xs font-medium focus:outline-none rounded py-1 px-3 mr-2"
            :class="{'bg-light-background hover:bg-grey-500 text-white': !darkMode, 'bg-grey-500 hover:bg-grey-600 text-white': darkMode}">
      Cancel
    </button>
  </div>
</template>

<script>
export default {
  name: "EditField",
  props: {
    value: String|Number,
    fieldName: String,
    darkMode: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text"
    },
    options: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      editing: {
        name: false
      },
      temporaryField: ''
    }
  },
  methods: {
    saveField() {
      this.$emit('update-field', this.fieldName, this.temporaryField);
    },
    cancelEdit() {
      this.$emit('cancel-edit', this.fieldName);
    }
  },
  created() {
    this.temporaryField = this.value;
  }
};
</script>
