<template>
    <div class="my-2 py-2">
        <div id="audio-container"  class="flex items-center justify-around py-2 px-4 w-max">
            <audio ref="audioFile" :src="audioFile" preload="metadata" />
            <div class="flex-shrink-0 self-center mb-2"
                 :class="{ 'text-primary-500 cursor-pointer': readyToPlay, 'text-grey-400 cursor-not-allowed': !readyToPlay }"
                 @click="togglePlayAudio">
                <svg v-if="!isPlaying" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8">
                    <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z" clip-rule="evenodd" />
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8">
                    <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM9 8.25a.75.75 0 00-.75.75v6c0 .414.336.75.75.75h.75a.75.75 0 00.75-.75V9a.75.75 0 00-.75-.75H9zm5.25 0a.75.75 0 00-.75.75v6c0 .414.336.75.75.75H15a.75.75 0 00.75-.75V9a.75.75 0 00-.75-.75h-.75z" clip-rule="evenodd" />
                </svg>
            </div>
            <div class="grid grid-rows-2 justify-items-center text-center gap-2 min-w-[16rem] mx-8">
                <p class="mt-1 block">{{ toMinutesAndSeconds(audioProgress) }} / {{ toMinutesAndSeconds(audioDuration) }}</p>
                <input type="range" ref="progress" :max="audioStepsMax" step="1" :value="audioProgressSteps ?? 0"
                    class="range-sm block bg-gray-500 w-full bg-transparent rounded-lg h-1 appearance-none cursor-pointer text-primary-500"
                    :class="{ 'bg-dark-module': darkMode, 'bg-light-module': !darkMode }"
                    :disabled="!readyToPlay"
                    @mousedown="toggleUserDraggingProgressThumb(true)"
                    @mouseup="toggleUserDraggingProgressThumb(false)"
                    />
                <p class="text-xs">"{{ audioFile }}"</p>
            </div>
            <div class="relative mb-3">
                <svg @click="showVolumeControl" class="cursor-pointer w-6 h-6 text-primary-500"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M13.5 4.06c0-1.336-1.616-2.005-2.56-1.06l-4.5 4.5H4.508c-1.141 0-2.318.664-2.66 1.905A9.76 9.76 0 001.5 12c0 .898.121 1.768.35 2.595.341 1.24 1.518 1.905 2.659 1.905h1.93l4.5 4.5c.945.945 2.561.276 2.561-1.06V4.06zM18.584 5.106a.75.75 0 011.06 0c3.808 3.807 3.808 9.98 0 13.788a.75.75 0 11-1.06-1.06 8.25 8.25 0 000-11.668.75.75 0 010-1.06z" />
                    <path d="M15.932 7.757a.75.75 0 011.061 0 6 6 0 010 8.486.75.75 0 01-1.06-1.061 4.5 4.5 0 000-6.364.75.75 0 010-1.06z" />
                </svg>
                <input type="range" ref="volume" :max="volumeStepsMax" v-model="currentVolumeSlider" step="1"
                       class="absolute right-[-5rem] w-24 h-3 rotate-[270deg] transition-opacity duration-700 top-[50%] translate-y-[-50%] range-sm block bg-gray-500 bg-transparent rounded-lg appearance-none cursor-pointer text-primary-500"
                       :class="{ 'opacity-0 pointer-events-none': !showVolume, 'opacity-100 pointer-events-auto': showVolume }"/>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "AudioPlayer",
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        audioFile: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            audioStepsMax: 1000,
            audioDuration: 0,
            audioProgress: 0,
            readyToPlay: true,
            isPlaying: false,
            userDraggingProgressThumb: false,
            progressBarLoop: null,
            showVolume: false,
            volumeStepsMax: 100,
            currentVolumeSlider: 0,
        }
    },
    computed: {
        audioProgressSteps() {
            return Math.floor((this.audioProgress / this.audioDuration)*this.audioStepsMax || 0);
        },
    },
    mounted() {
        this.$refs.audioFile.addEventListener('error', () => {
            this.readyToPlay = false;
        });
        if (this.$refs.audioFile.readyState > 0) {
            this.audioDuration = this.$refs.audioFile.duration;
        }
        else {
            this.$refs.audioFile.addEventListener('loadedmetadata', () => {
                this.audioDuration = this.$refs.audioFile.duration;
            });
        }
        this.$refs.progress.addEventListener('change', this.handleProgressBarChange);
        this.$refs.audioFile.addEventListener('ended', this.handleAudioEnded);
        this.setVolumeSlider(this.$refs.audioFile.volume);
    },
    methods: {
        togglePlayAudio() {
            if (this.isPlaying) this.pauseAudio();
            else this.playAudio();
        },
        playAudio() {
            if (!this.readyToPlay) return;
            this.isPlaying = true;
            this.progressBarLoop = setInterval(() => {
                if (!this.userDraggingProgressThumb) this.audioProgress = this.$refs.audioFile.currentTime;
            }, 1)
            this.$refs.audioFile.play();
        },
        pauseAudio() {
            if (this.isPlaying) this.$refs.audioFile.pause();
            this.$refs.audioFile.removeEventListener('timeupdate', this.updateProgress);
            clearInterval(this.progressBarLoop);
            this.isPlaying = false;
        },
        updateProgress() {
            if (!this.userDraggingProgressThumb) this.audioProgress = this.$refs.audioFile.currentTime;
        },
        handleAudioEnded() {
            this.pauseAudio();
            this.audioProgress = 0;
        },
        handleProgressBarChange(event) {
            const newProgressValue = this.progressStepsToSeconds(event.target.value);
            this.audioProgress = newProgressValue;
            this.$refs.audioFile.currentTime = newProgressValue;
        },
        progressStepsToSeconds(audioSteps) {
            return (audioSteps / this.audioStepsMax)*this.audioDuration;
        },
        toggleUserDraggingProgressThumb(value) {
            this.userDraggingProgressThumb = value ?? false;
            if (this.userDraggingProgressThumb) {
                this.$refs.progress.addEventListener('mousemove', this.handleProgressThumbDrag);
            }
            else {
                this.$refs.progress.removeEventListener('mousemove', this.handleProgressThumbDrag);
            }
        },
        handleProgressThumbDrag({ target }) {
            const { value } = target;
            if (value != null) this.audioProgress = this.progressStepsToSeconds(value);
        },
        toMinutesAndSeconds(inputTime) {
            if (!parseInt(inputTime)) return `0:00`;
            else {
                const seconds = Math.floor(inputTime);
                return `${Math.floor(seconds/60)}:${(seconds % 60).toString().padStart(2, '0')}`;
            }
        },
        showVolumeControl() {
            this.showVolume = true;
            const volumeFade = {
                interval: null,
                timer: 3000,
                intervalStep: 100,
            }
            const resetFade = () => {
                clearInterval(volumeFade.interval);
                volumeFade.timer = 3000;
            };
            const fadeVolumeControl = () => {
                volumeFade.interval = setInterval(() => {
                    if (volumeFade.timer < 100) {
                        this.showVolume = false;
                        clearInterval(volumeFade.interval);
                        this.$refs.volume.removeEventListener('mouseover', resetFade);
                        this.$refs.volume.removeEventListener('mouseleave', fadeVolumeControl);
                    }
                    volumeFade.timer -= volumeFade.intervalStep;
                }, volumeFade.intervalStep);
            }
            this.$refs.volume.addEventListener('mouseleave', fadeVolumeControl);
            this.$refs.volume.addEventListener('mouseover', resetFade);
        },
        setVolumeSlider(value) {
            this.currentVolumeSlider = value * this.volumeStepsMax;
        },
        setVolume() {
            this.$refs.audioFile.volume = this.currentVolumeSlider / this.volumeStepsMax;
        }
    },
    watch: {
        currentVolumeSlider(newVal, oldVal) {
            if (newVal !== oldVal) this.setVolume();
        }
    }
}
</script>

<style scoped>
    input[type="range"]::-moz-range-progress {
        background-color: rgb(114 185 255);
        height: 0.4rem;
        border-radius: 1rem;
    }
    input[type="range"]::-moz-range-thumb {
        background: rgb(0 84 166);
        height: 1rem;
        width: 1rem;
        border: none;
    }
    input[type="range"]::-webkit-slider-thumb {
        background: rgb(0 84 166);
        height: 1rem;
        width: 1rem;
        border: none;
    }
</style>
