<template>
    <div>
        <websites :dark-mode="darkMode" v-if="page === 'websites'" @api-keys="apiKeys"></websites>
        <api-keys v-if="page === 'api-keys'" @back="page = 'websites'" :dark-mode="darkMode" :website="website"></api-keys>
    </div>

</template>

<script>
import Websites from "./Websites";
import ApiKeys from "./ApiKeys";

export default {
    name: "WebsiteManagement",
    components: {Websites, ApiKeys},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        breadcrumbPages: {
            type: Array,
            default: []
        },
        websiteData: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            page: 'websites',
            website: null,
        }
    },
    watch: {
        breadcrumbPages: {
            immediate: true,
            handler(newVal) {
                switch (newVal[newVal.length - 1].name) {
                    case 'Api Keys':
                        this.page = 'api-keys';
                        break;
                    default:
                        this.page = 'websites';
                }
            }
        },
        websiteData: {
            immediate: true,
            handler(newVal) {
                if(newVal) {
                    this.website = newVal;
                }
            }
        }
    },
    methods: {
        apiKeys(website) {
            this.page = 'api-keys';
            this.website = website;
            let websiteHeader = this.website.name.toLowerCase().replaceAll(' ', '-');
            this.$emit('add-breadcrumb-page', {name: this.website.name, id: websiteHeader})
            this.$emit('add-breadcrumb-page', {name: 'Api Keys', id: 'api-keys'});
        }
    }
}
</script>
