<template>
    <div class="flex justify-end">
        <div class="w-64 rounded-t-lg rounded-bl-lg bg-primary-500 text-white px-4 pt-3 pb-8 relative">
            <p class="font-medium">
                {{ message.first_name }} {{ message.last_name }}
            </p>
            <p class="leading-tight font-light">
                {{ message.message_body }}
            </p>
            <div class="absolute right-0 bottom-0 mr-3 mb-2">
                <p class="font-light text-sm">{{ $filters.dateFromTimestamp(message.timestamp, 'relative') }}</p>
            </div>
            <div class="absolute right-0" style="bottom: -6px;">
                <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 -0.00058685L9.99989 6.5001L0.499895 -0.000623523L10 -0.00058685Z" fill="#0081FF"/>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "OutgoingMessage",
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        message: {
            type: Object,
            default: () => {}
        }
    },
    methods: { }
}
</script>

<style scoped>

</style>
