<template>
    <div>
        <alerts-container v-if="alertActive" :alert-type="alertType" :text="alertText" :dark-mode="darkMode"/>

        <modal :no-buttons="true" :dark-mode="darkMode" :full-width="true" @close="closePlatformAuthenticationModal">
            <template v-slot:header>
                <div class="font-medium">
                    Platform Authentication Management
                </div>
            </template>
            <template v-slot:content>
                <div v-if="loading">
                    <loading-spinner/>
                </div>
                <div v-else-if="platforms.length > 0" class="flex flex-row gap-5 items-center">
                    <Dropdown
                        class="w-1/2"
                        :placeholder="'Choose a platform'"
                        :dark-mode="darkMode"
                        :options="platforms"
                        v-model="platform"/>

                    <div v-if="authPageLoading">
                        Loading Auth Page...
                    </div>
                    <button
                        v-else-if="platform.length > 0"
                        type="button"
                        class="px-5 py-2 h-9 rounded bg-primary-500 hover:bg-primary-600 text-white text-sm font-medium"
                        @click="redirectToAuthPage(platform)">
                        Authenticate
                    </button>
                </div>
                <div v-else class="w-full flex flex-row justify-center items-center">
                    No platforms
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
    import AlertsContainer from "../../Shared/components/AlertsContainer";
    import AlertsMixin from "../../../mixins/alerts-mixin";
    import LoadingSpinner from "../../Shared/components/LoadingSpinner";
    import Api from './../services/api';
    import Modal from "../../Shared/components/Modal";
    import Dropdown from "../../Shared/components/Dropdown";
    import {unslugify} from "../../Shared/services/strings";

    export default {
        name: "PlatformAuthenticationManagement",
        components: {
            Dropdown,
            AlertsContainer,
            Modal,
            LoadingSpinner
        },
        props: {
            darkMode: {
                type: Boolean,
                default: false
            }
        },
        mixins: [
            AlertsMixin
        ],
        emits: [
            'close'
        ],
        data: function() {
            return {
                api: Api.make(),
                platforms: [],
                platform: '',
                responseUri: '',

                unslugify: unslugify,

                loading: true,
                authPageLoading: false
            };
        },
        created: function() {
            this.api.getAuthenticationPlatforms().then(res => {
                if(res.data.data.status === true) {
                    this.platforms = res.data.data.platforms.map((platform) => {
                        return {
                            id: platform,
                            name: this.unslugify(platform, '_')
                        };
                    });
                }
                else {
                    this.showAlert('error', 'Problem retrieving platforms for authentication');
                }
            }).catch(err => {
                this.showAlert('error', 'Error retrieving platforms for authentication');
            }).finally(() => {
                this.loading = false;
            });
        },
        methods: {
            closePlatformAuthenticationModal() {
                this.$emit('close');
            },
            redirectToAuthPage(platform) {
                this.authPageLoading = true;

                this.api.getPlatformTokenAuthEndpoint(platform).then(res => {
                    if(res.data.data.status === true) {
                        window.open(res.data.data.endpoint, '_blank');
                    }
                    else {
                        this.showAlert('error', 'Problem retrieving authentication endpoint');
                    }
                }).catch(err => {
                    this.showAlert('error', 'Error retrieving authentication endpoint');
                }).finally(() => {
                    this.authPageLoading = false;
                });
            }
        }
    }
</script>
