<template>
  <div class="main-layout">
    <div>
        <dashboard :dark-mode="darkMode" :api="api"></dashboard>
    </div>
  </div>
</template>

<script>
import Dashboard from "./layouts/Dashboard";
import {ApiFactory} from "../LeadProcessing/services/api/factory";

export default {
  components: {Dashboard},
  data: function() {
    return {
        api: null,
    }
  },
  props: {
    apiDriver: {
      type: String,
      default: 'dummy'
    },
    darkMode: {
        type: Boolean,
        default: false,
    }
  },
  created() {
      this.api = ApiFactory.makeApiService(this.apiDriver);
  }
};

</script>
