<template>
    <div @click="toggleNotificationsList" class="relative border transition duration-200 shadow-module w-10 h-10 rounded-full inline-flex justify-center items-center cursor-pointer"
         :class="bellClasses">
        <svg class="fill-current"
             width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99999 17.1819C8.50669 17.1825 9.001 17.0253 9.41421 16.732C9.82742 16.4387 10.139 16.024 10.3056 15.5455H5.69435C5.86099 16.024 6.17256 16.4387 6.58577 16.732C6.99898 17.0253 7.49329 17.1825 7.99999 17.1819V17.1819ZM13.7273 11.1159V7.36369C13.7273 4.7316 11.9395 2.51433 9.51772 1.84751C9.27799 1.24369 8.69217 0.818237 7.99999 0.818237C7.30781 0.818237 6.72199 1.24369 6.48226 1.84751C4.06044 2.51515 2.27272 4.7316 2.27272 7.36369V11.1159L0.876081 12.5125C0.799955 12.5884 0.739583 12.6785 0.698441 12.7778C0.657298 12.8771 0.636198 12.9835 0.636353 13.091V13.9091C0.636353 14.1261 0.722554 14.3342 0.875993 14.4877C1.02943 14.6411 1.23754 14.7273 1.45454 14.7273H14.5454C14.7624 14.7273 14.9705 14.6411 15.124 14.4877C15.2774 14.3342 15.3636 14.1261 15.3636 13.9091V13.091C15.3638 12.9835 15.3427 12.8771 15.3015 12.7778C15.2604 12.6785 15.2 12.5884 15.1239 12.5125L13.7273 11.1159Z"/>
        </svg>
        <div v-if="unreadCount > 0" class="absolute right-0 top-0 mr-2 mt-2 h-3 w-3 rounded-full inline-flex justify-center items-center bg-red-500 text-white text-xs">
            {{ unreadCount }}
        </div>
    </div>
</template>

<script>

export default {
    name: "NotificationBell",
    components: {},
    props: {
        notificationsList: {
            type: Boolean,
            default: false,
        },
        notifications: {
            type: Array,
            default: null
        },
        notificationsBadge: {
            type: Boolean,
            default: false,
        },
        darkMode: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {}
    },
    methods: {
        toggleNotificationsList() {
            this.$emit('toggle-notifications-list')
        },
    },
    computed: {
        bellClasses() {
            const classes = [];

            if(this.notificationsList) {
                if(this.darkMode) {
                    classes.push('bg-dark-background', 'text-blue-550', 'border-primary-500', 'hover:border-blue-400', 'hover:bg-dark-175');
                } else {
                    classes.push('bg-primary-500', 'text-white', 'hover:bg-blue-500', 'border-transparent');
                }
            } else {
                if(this.darkMode) {
                    classes.push('bg-dark-background', 'text-blue-550', 'border-primary-500', 'hover:border-blue-400', 'hover:bg-dark-background');
                } else {
                    classes.push('bg-light-module', 'text-blue-550', 'border-light-border', 'hover:border-primary-500', 'hover:bg-cyan-125');
                }
            }

            return classes;
        },
        unreadCount() {
            if(this.notifications.length === 0)
                return 0;

            return this.notifications.reduce((prev, cur) => prev + (cur.read ? 0 : 1), 0);
        }
    }
}
</script>

<style scoped>

</style>
