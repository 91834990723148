<template>
    <alerts-container :dark-mode="darkMode" v-if="alertActive" :alert-type="alertType" :text="alertText"/>
    <div @click="editContact(contact)" v-if="hasEditRights"
         class="w-4 p-1 cursor-pointer mr-5">
        <svg class="w-4" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.25 0L10.3125 1.95117L8.74019 3.43926L6.67769 1.48809L8.25 0ZM0 7.79688V9.74805H2.0625L7.76806 4.35827L5.70556 2.40709L0 7.79688ZM0 11.6992H11V13H0V11.6992Z"
                fill="#0081FF"/>
        </svg>
    </div>
    <ButtonDropdown v-if="phoneNumberOptionsHasAtLeastOneOption" @selected="call(contact, $event['type'])"
                    :options="phoneNumberOptions">
        <svg v-if="contactIsValid(contact)"
             class="w-4 mr-5 cursor-pointer" width="16" height="16" viewBox="0 0 16 16"
             fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.7061 12.5332L12.0551 9.21369C11.8825 9.05684 11.6558 8.97318 11.4227 8.98038C11.1895 8.98759 10.9684 9.0851 10.8058 9.25231L8.65655 11.4626C8.13922 11.3638 7.09917 11.0396 6.02859 9.97172C4.958 8.90024 4.63377 7.85751 4.53767 7.34378L6.7462 5.19364C6.91363 5.03119 7.01128 4.80998 7.01848 4.57681C7.02569 4.34364 6.94189 4.11681 6.78482 3.94433L3.46619 0.294312C3.30905 0.121292 3.09065 0.0163428 2.85738 0.00175297C2.62411 -0.0128368 2.39434 0.0640823 2.21687 0.216174L0.2679 1.8876C0.112621 2.04344 0.0199401 2.25085 0.00743837 2.47049C-0.00603376 2.69503 -0.262902 8.01378 3.86137 12.1398C7.45933 15.7368 11.9662 16 13.2074 16C13.3889 16 13.5002 15.9946 13.5299 15.9928C13.7495 15.9805 13.9568 15.8874 14.1119 15.7315L15.7824 13.7816C15.9351 13.6047 16.0126 13.3751 15.9983 13.1419C15.9841 12.9086 15.8792 12.6902 15.7061 12.5332V12.5332Z"
                fill="#0081FF"/>
        </svg>
    </ButtonDropdown>
    <ButtonDropdown v-if="phoneNumberOptionsHasAtLeastOneOption" @selected="sms(contact, $event['type'])"
                    :options="phoneNumberOptions">
        <svg v-if="contactIsValid(contact)"
             class="w-4 mr-5 cursor-pointer" width="16" height="16" viewBox="0 0 16 16"
             fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.66667 12.9547H3.33333V16L7.58417 12.9547H11.6667C12.5858 12.9547 13.3333 12.2853 13.3333 11.4622V5.49244C13.3333 4.66936 12.5858 4 11.6667 4H1.66667C0.7475 4 0 4.66936 0 5.49244V11.4622C0 12.2853 0.7475 12.9547 1.66667 12.9547Z"
                fill="#0081FF"/>
            <path
                d="M14.3332 0H4.33317C3.414 0 2.6665 0.797333 2.6665 1.77778H12.6665C13.5857 1.77778 14.3332 2.57511 14.3332 3.55556V10.6667C15.2523 10.6667 15.9998 9.86933 15.9998 8.88889V1.77778C15.9998 0.797333 15.2523 0 14.3332 0Z"
                fill="#0081FF"/>
        </svg>
    </ButtonDropdown>
    <svg class="w-4 cursor-pointer" width="16" height="14" viewBox="0 0 16 14" fill="none"
         xmlns="http://www.w3.org/2000/svg"
         v-if="contact.email && contact.email.trim().length > 0">
        <path
            d="M0.00244141 2.09333L7.99944 6.53555L15.9964 2.09333C15.9668 1.52716 15.7435 0.994985 15.3723 0.605807C15.001 0.216628 14.5099 -0.000103983 13.9994 3.7426e-08H1.99944C1.48903 -0.000103983 0.997888 0.216628 0.626619 0.605807C0.25535 0.994985 0.0320455 1.52716 0.00244141 2.09333V2.09333Z"
            fill="#0081FF"/>
        <path
            d="M16 4.57556L8 9.02L0 4.57556V11.1111C0 11.7005 0.210714 12.2657 0.585786 12.6825C0.960859 13.0992 1.46957 13.3333 2 13.3333H14C14.5304 13.3333 15.0391 13.0992 15.4142 12.6825C15.7893 12.2657 16 11.7005 16 11.1111V4.57556Z"
            fill="#0081FF"/>
    </svg>
</template>

<script>
import DispatchesGlobalEventsMixin from "../../../../mixins/dispatches-global-events-mixin";
import {contactIsValid, formatPhoneNumber, getPhoneNumber, phoneIsValid} from "../../../../../modules/contacts/helpers";
import Dropdown from "../../components/Dropdown.vue";
import ButtonDropdown from "../../components/ButtonDropdown.vue";
import CommunicationMixin from "../../../../mixins/communcation/communication-mixin";
import AlertsMixin from "../../../../mixins/alerts-mixin";
import {mapState} from "pinia";
import {useCallStore} from "../../../../../stores/call.store";
import Alert from "../../components/Alert.vue";
import AlertsContainer from "../../components/AlertsContainer.vue";

/**
 * @typedef contact
 * @property {number} id
 * @property {string} name
 * @property {string} title
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} email
 * @property {number|boolean} status
 * @property {string|null} date_registered
 * @property {string} cell_phone
 * @property {string} office_phone
 * @property {number|boolean} is_contact
 * @property {number|null} total_calls_count
 * @property {number|null} total_calls_over_one_minute_count
 * @property {number|null} latest_call_timestamp
 */

export default {
    name: "ContactButtons",
    components: {AlertsContainer, Alert, ButtonDropdown, Dropdown},
    emits: ['edit:contact'],
    props: {
        hasEditRights: {
            type: Boolean,
            default: false,
        },
        contact: {
            type: Object,
            required: true
        },
        darkMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showPhoneCallDropdown: false,
            showPhoneSmsDropdown: false
        }
    },
    computed: {
        phoneNumberOptions() {
            let result = [];

            if (this.contact.hasOwnProperty('office_phone') && this.contact.office_phone && phoneIsValid(this.contact.office_phone)) {
                result.push({
                    name: formatPhoneNumber(this.contact.office_phone) + ' (Office)',
                    value: this.contact.office_phone,
                    type: "office_phone"
                });
            }

            if (this.contact.hasOwnProperty('cell_phone') && this.contact.cell_phone && phoneIsValid(this.contact.cell_phone)) {
                result.push({
                    name: formatPhoneNumber(this.contact.cell_phone) + ' (Mobile)',
                    value: this.contact.cell_phone,
                    type: "cell_phone"
                });
            }

            return result;
        },
        phoneNumberOptionsHasAtLeastOneOption() {
            return this.phoneNumberOptions.length > 0;
        },
        ...mapState(useCallStore, [
            'callActive'
        ])
    },
    mixins: [DispatchesGlobalEventsMixin, AlertsMixin],
    methods: {
        contactIsValid,
        /**
         * @param contact
         */
        editContact(contact) {
            this.$emit('edit:contact', contact)
        },
        /**
         * @param contact
         * @param phoneType
         */
        call(contact, phoneType) {
            if (!this.callActive) {
                const targetNumber = getPhoneNumber(contact, phoneType);
                this.dispatchGlobalEvent('call', {phone: targetNumber, name: contact.name, id: contact.id});
            } else {
                console.warn('Please end your current call before making another one.')
                this.showAlert('warning', 'Please end your current call before making another one.')
            }
        },
        /**
         * @param {contact} contact
         * @param phoneType
         */
        sms(contact, phoneType) {
            const targetNumber = getPhoneNumber(contact, phoneType);
            this.dispatchGlobalEvent('sms', {phone: targetNumber, name: contact.name, id: contact.id});
        },
    }
}
</script>

<style scoped>

</style>
