<template>
    <div class="p-5">
        <div class="xl:w-1/2 lg:w-2/3 flex gap-3 flex-col items-center lg:flex-row mt-2 pb-5" :class="{'border-b': selectedCompanyId}">
            <label class="block pb-2 font-medium">Company</label>
            <div class="flex-grow">
                <Autocomplete
                    :input-class="['w-full border border-grey-350 rounded px-4 py-3', {'border-grey-350 bg-light-module': !darkMode, 'border-blue-400 bg-dark-background text-blue-400': darkMode}]"
                    :list-class="['absolute top-0 w-full border rounded', {'border-grey-200 bg-light-module': !darkMode, 'border-dark-40 bg-dark-module': darkMode}]"
                    :option-class="['cursor-pointer px-5 py-2 my-1', {'hover:bg-grey-100': !darkMode, 'hover:bg-blue-800': darkMode}]"
                    v-model="selectedCompanyId"
                    :value="selectedCompanyId"
                    :options="companyOptions"
                    @search="searchCompany"
                    :placeholder="'Enter Name'">
                </Autocomplete>
            </div>
        </div>
        <div class="xl:w-1/2 lg:w-2/3 flex justify-between gap-3 flex-col lg:flex-row mt-5" v-if="selectedCompanyId">
            <dropdown :dark-mode="darkMode" :options="industries" :placeholder="'Select Industry'" v-model="selectedIndustry"></dropdown>
            <dropdown :dark-mode="darkMode" :options="industryServices" :placeholder="'Select Service'" v-model="selectedService"></dropdown>
            <button class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5 whitespace-nowrap"
                    @click="addService" :disabled="saving">
                Add Service
            </button>
        </div>
        <div class="my-5" v-if="selectedCompanyId">
            <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Services for company: {{ selectedCompany.name}}</h5>
        </div>
        <div class="grid grid-cols-5 gap-x-3 mb-2 px-5" v-if="!loading && selectedCompanyId">
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Industry</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Service</p>
        </div>
        <div class="border-t border-b" v-if="!loading && selectedCompanyId"
             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-40 border-dark-border': darkMode}">
            <div>
                <div class="grid grid-cols-5 gap-x-3 border-b px-5 py-3 items-center"
                     v-for="service in companyServices" :key="service.id"
                     :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <p class="text-sm col-span-2 truncate">
                        {{ service.industry }}
                    </p>
                    <p class="text-sm col-span-2 truncate">
                        {{ service.name }}
                    </p>
                    <ActionsHandle :dark-mode="darkMode" :no-edit-button="true" @delete="deleteService(service)" />
                </div>
            </div>
        </div>
        <div class="h-32 flex items-center justify-center" v-if="loading">
            <loading-spinner></loading-spinner>
        </div>
        <alerts-container :dark-mode="darkMode" :alert-type="'error'" :text="error" v-if="error !== null"></alerts-container>
    </div>
</template>

<script>
import Dropdown from "../../Shared/components/Dropdown";
import ActionsHandle from "../../Shared/components/ActionsHandle";
import LoadingSpinner from "../../Shared/components/LoadingSpinner";
import AlertsContainer from "../../Shared/components/AlertsContainer";
import ApiService from "./services/api";
import IndustryApiService from "../IndustrySetup/services/api";
import SharedApiService from "../../Shared/services/api";
import Autocomplete from "../../Shared/components/Autocomplete";

export default {
    name: "CompanyServices",
    components: {Dropdown, ActionsHandle, LoadingSpinner, AlertsContainer, Autocomplete},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            api: ApiService.make(),
            industryApi: IndustryApiService.make(),
            sharedApi: SharedApiService.make(),
            companyServices: [],
            industries: [],
            industryServices: [],
            selectedIndustry: null,
            selectedService: null,
            loading: false,
            saving: false,
            error: null,
            companyOptions: [],
            selectedCompany: {
                id: null,
                name: null
            },
            selectedCompanyId: null
        }
    },
    created() {
        this.getIndustries();

        if (this.selectedCompany.id) this.getCompanyServices();
    },
    watch: {
        selectedIndustry: function () {
            this.getIndustryServices()
        },
        selectedCompanyId: function () {
            const company = this.companyOptions.find(company => company.id === this.selectedCompanyId);

            if (company) {
                this.selectedCompany = {...company};
                this.getCompanyServices();
            } else {
                this.selectedCompany = {id: null, name: null};
                this.companyServices = [];
            }
        }
    },
    methods: {
        getIndustries() {
            this.industryApi.getIndustries().then(resp => this.industries = resp.data.data.industries);
        },
        getIndustryServices() {
            if (!this.selectedIndustry) return;

            this.industryServices = [];
            this.selectedService = null;

            this.industryApi.getIndustryServices(this.selectedIndustry).then(resp => this.industryServices = resp.data.data.services);
        },
        getCompanyServices() {
            this.companyServices = [];
            this.loading = true;

            this.api.getServices(this.selectedCompany.id)
                .then(resp => this.companyServices = resp.data.data.services)
                .finally(() => this.loading = false);
        },
        addService() {
            if (!this.selectedCompany.id || !this.selectedService || this.saving) return;

            this.saving = true;
            this.resetError();

            this.api.addService(this.selectedCompany.id, this.selectedService)
                .then(() => this.getCompanyServices())
                .catch(e => this.error = e.response.data.message)
                .finally(() => this.saving = false);
        },
        deleteService(service) {
            this.resetError();
            this.api.deleteService(this.selectedCompany.id, service.id)
                .then(() => this.getCompanyServices()).catch(e => this.error = e.response.data.message);
        },
        resetError() {
            this.error = null;
        },
        searchCompany (query) {
            this.sharedApi.getAdminCompanies(query).then(resp => this.companyOptions = resp.data.data.companies);
        }
    }
}
</script>
