<template>
    <div class="p-5">
        <div class="xl:w-1/2 lg:w-2/3 flex justify-between gap-3 flex-col lg:flex-row mt-2">
            <dropdown :dark-mode="darkMode" :options="industries" :placeholder="'Select Industry'" v-model="selectedIndustry"></dropdown>
            <dropdown :dark-mode="darkMode" :options="industryServices" :placeholder="'Select Service'" v-model="selectedService"></dropdown>
            <button class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5 whitespace-nowrap"
                    @click="addService" :disabled="saving">
                Add Service
            </button>
        </div>
        <div class="my-5">
            <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Services for product {{product.name}}</h5>
        </div>
        <div class="grid grid-cols-5 gap-x-3 mb-2 px-5" v-if="!loading">
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Industry</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Service</p>
        </div>
        <div class="border-t border-b" v-if="!loading"
             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-40 border-dark-border': darkMode}">
            <div>
                <div class="grid grid-cols-5 gap-x-3 border-b px-5 py-3 items-center"
                     v-for="service in productServices" :key="service.id"
                     :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <p class="text-sm col-span-2 truncate">
                        {{ service.industry }}
                    </p>
                    <p class="text-sm col-span-2 truncate">
                        {{ service.name }}
                    </p>
                    <ActionsHandle :dark-mode="darkMode" :no-edit-button="true" @delete="deleteService(service)" />
                </div>
            </div>
        </div>
        <div class="h-32 flex items-center justify-center" v-if="loading">
            <loading-spinner></loading-spinner>
        </div>
        <alerts-container :dark-mode="darkMode" :alert-type="'error'" :text="error" v-if="error !== null"></alerts-container>
    </div>
</template>

<script>
import ApiService from "./services/api";
import IndustryApiService from "../IndustrySetup/services/api";
import Dropdown from "../../Shared/components/Dropdown";
import LoadingSpinner from "../../Shared/components/LoadingSpinner";
import ActionsHandle from "../../Shared/components/ActionsHandle";
import AlertsContainer from "../../Shared/components/AlertsContainer";

export default {
    name: "Services",
    components: {Dropdown, LoadingSpinner, ActionsHandle, AlertsContainer},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        product: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            api: ApiService.make(),
            industryApi: IndustryApiService.make(),
            productServices: [],
            industries: [],
            industryServices: [],
            selectedIndustry: null,
            selectedService: null,
            loading: false,
            saving: false,
            error: null
        }
    },
    created() {
        this.getIndustries();

        if (this.product) this.getProductServices();
    },
    watch: {
        selectedIndustry: function () {
            this.getIndustryServices()
        }
    },
    methods: {
        getIndustries() {
            this.industryApi.getIndustries().then(resp => this.industries = resp.data.data.industries);
        },
        getIndustryServices() {
            if (!this.selectedIndustry) return;

            this.industryServices = [];
            this.selectedService = null;

            this.industryApi.getIndustryServices(this.selectedIndustry).then(resp => this.industryServices = resp.data.data.services);
        },
        getProductServices() {
            this.productServices = [];
            this.loading = true;

            this.api.getServices(this.product.id)
                .then(resp => this.productServices = resp.data.data.services)
                .finally(() => this.loading = false);
        },
        addService() {
            if (!this.product || !this.selectedService || this.saving) return;

            this.saving = true;
            this.resetError();

            this.api.addService(this.product.id, this.selectedService)
                .then(() => this.getProductServices())
                .catch(e => this.error = e.response.data.message)
                .finally(() => this.saving = false);
        },
        deleteService(service) {
            this.resetError();
            this.api.deleteService(this.product.id, service.id)
                .then(() => this.getProductServices()).catch(e => this.error = e.response.data.message);
        },
        resetError() {
            this.error = null;
        }
    }
}
</script>
