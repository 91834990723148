<template>
    <div class="row-span-2 border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="p-5">
            <h5 class="text-primary-500 text-sm uppercase font-semibold pb-4 leading-tight">Statistics Overview</h5>

        </div>
    </div>
</template>

<script>
export default {
    name: "StatisticsOverview",
    props: {
        darkMode: false
    }
}
</script>

<style scoped>

</style>
