<template>
    <div class="mt-5">
        <div class="grid grid-cols-2 gap-3 border-t border-b py-4" :class="{'border-grey-200': !darkMode, 'border-blue-400 ': darkMode}">
            <div class="flex items-center">
                <p class="bg-primary-500 h-6 w-6 rounded-full text-white mr-3 inline-flex items-center justify-center font-bold text-sm whitespace-nowrap">1</p>
                <div class="w-full">
                    <p class="uppercase font-semibold text-xs mb-1" :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                        Action Outcome Type
                    </p>
                    <Dropdown :options="actionOutcomeTypes" :dark-mode="darkMode"
                              :selected="getPayload(0, 'type')"
                              @update:modelValue="setPayload(0, 'type', $event)"/>
                </div>
            </div>
            <div>
                <p class="uppercase font-semibold text-xs mb-1" :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                    Action Outcome Name
                </p>
                <input class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                       :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"
                       placeholder="Button name..." type="text"
                       :value="getPayload(0, 'name')"
                       @input="setPayload(0, 'name', $event.target.value)" />
            </div>
        </div>
        <div v-if="getPayload(0, 'type')" class="grid grid-cols-2 gap-3 border-t border-b py-4"  :class="{'border-grey-200': !darkMode, 'border-blue-400 ': darkMode}">
            <div class="flex items-center">
                <p class="bg-primary-500 h-6 w-6 rounded-full text-white mr-3 inline-flex items-center justify-center font-bold text-sm whitespace-nowrap">2</p>
                <div class="w-full">
                    <p class="uppercase font-semibold text-xs mb-1" :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                        Action Outcome Type
                    </p>
                    <Dropdown :options="actionOutcomeTypes" :dark-mode="darkMode"
                              :selected="getPayload(1, 'type')"
                              @update:modelValue="setPayload(1, 'type', $event)"/>
                </div>
            </div>
            <div>
                <p class="uppercase font-semibold text-xs mb-1" :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                    Action Outcome Name
                </p>
                <input class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                       :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"
                       placeholder="Button name..." type="text"
                       :value="getPayload(1, 'name')"
                       @input="setPayload(1, 'name', $event.target.value)"/>
            </div>
        </div>
    </div>
</template>

<script>
import Dropdown from "../../../Shared/components/Dropdown";

export default {
    name: "SelectActions",
    components: { Dropdown },
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: Object
        }
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            actionOutcomeTypes: [
                {id: 1, name: 'Good Outcome'},
                {id: 2, name: 'Bad Outcome'}
            ],
        }
    },
    methods: {
        getPayload(slot, key) {
            if(this.modelValue) {
                const outcome = this.modelValue?.outcomes?.[slot];

                if(outcome) {
                    switch(key) {
                        case 'type':
                            return this.actionOutcomeTypes.find((actionOutcomeType) => {
                                return actionOutcomeType.name === outcome.type;
                            });
                        case 'name':
                            return outcome.name;
                    }
                }
            }
        },
        setPayload(slot, key, value) {
            let outcomes = this.modelValue?.outcomes || {};

            let newValue;
            switch(key) {
                case 'type':
                    const selectedActionOutcomeType = this.actionOutcomeTypes.find((actionOutcomeType) => {
                        return actionOutcomeType.id === value;
                    });

                    newValue = selectedActionOutcomeType.name;
                    break;
                case 'name':
                    newValue = value;
                    break;
            }

            outcomes[`${slot}`] = {
                ...(outcomes[slot] || {}),
                [key]: newValue
            };

            this.$emit('update:modelValue', {"outcomes": outcomes});
        }
    }
}
</script>

<style scoped>

</style>
