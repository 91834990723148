<template>
    <div class="grid grid-cols-1 gap-4 px-10 py-5">
        <Campaigns :has-edit-rights="hasEditRights" :dark-mode="darkMode" :company-id="companyId" :legacy-company-id="legacyCompanyId" :show-filters="true"></Campaigns>
    </div>
</template>

<script>
import Campaigns from "../../Companies/Campaigns";
export default {
    name: "CampaignsPage",
    components: {Campaigns},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        hasEditRights: {
            type: Boolean,
            default: false,
        },
        companyId: {
            type: Number,
            default: null
        },
        legacyCompanyId: {
            type: Number,
        }
    },
    data() {
       return {}
    }
}
</script>

<style scoped>

</style>
