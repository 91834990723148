<template>
    <products :dark-mode="darkMode" v-if="page === 'products'" @services="services"></products>
    <services :dark-mode="darkMode" v-if="page === 'services'" :product="product"></services>
</template>

<script>
import Products from "./Products";
import Services from "./Services";

export default {
    name: "ProductManagement",
    components: {Products, Services},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        breadcrumbPages: {
            type: Array,
            default: []
        },
        productData: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            page: 'products',
            product: null,
        }
    },
    emits: ["add-breadcrumb-page"],
    watch: {
        breadcrumbPages: {
            immediate: true,
            handler(newVal) {
                switch (newVal[newVal.length - 1].name) {
                    case 'Services':
                        this.page = 'services';
                        break;
                    default:
                        this.page = 'products';
                        break;
                }
            }
        },
        productData: {
            immediate: true,
            handler(newVal) {
                if(newVal) {
                    this.product = newVal;
                }
            }
        }
    },
    methods: {
        services(product) {
            this.page = 'services';
            this.product = product;
            let productHeader = this.product.name.toLowerCase().replaceAll(' ', '-');
            this.$emit('add-breadcrumb-page', {name: this.product.name, id: productHeader});
            this.$emit('add-breadcrumb-page', {name: 'Services', id: 'services'});
        }
    }
}
</script>
