<template>
    <div @click="toggleNotificationsList" class="absolute cursor-pointer right-0 h-16 border shadow-module transform transition-all duration-300 rounded-full py-4 pl-6 pr-16"
         :class="[badgeClasses, {'w-100 opacity-100' : notificationsBadge, 'w-0 opacity-0' : !notificationsBadge}]">
        <p class="text-blue-550 font-medium text-sm transform transition-all duration-500"
           :class="{'opacity-100' : notificationsBadge ,'opacity-0' : !notificationsBadge }">
            {{ notification ? notification.subject : "" }}
        </p>
        <p class="text-sm truncate transform transition-all duration-500"
           :class="[badgeTextClasses, {'opacity-100' : notificationsBadge ,'opacity-0' : !notificationsBadge }]">
            {{ notification ? notification.message : "" }}
        </p>
    </div>
</template>

<script>
export default {
    name: "NotificationsBadge",
    props: {
        notificationsBadge: {
            type: Boolean,
            default: false,
        },
        darkMode: {
            type: Boolean,
            default: false,
        },
        notification: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
        }
    },
    methods: {
        toggleNotificationsList() {
            this.$emit('toggle-notifications-list')
        },
    },
    computed: {
        badgeClasses() {
            const classes = [];

            if(this.notificationsBadge) {
                if(this.darkMode) {
                    classes.push('hover:bg-blue-900', 'border-primary-500', 'bg-dark-background');
                } else {
                    classes.push('hover:bg-cyan-125', 'border-light-border', 'bg-light-module');
                }
            } else {
                if(this.darkMode) {
                    classes.push('hover:bg-blue-900', 'border-dark-border', 'bg-dark-background');
                } else {
                    classes.push('hover:bg-cyan-125', 'border-light-border', 'bg-light-module');
                }
            }

            return classes;
        },
        badgeTextClasses() {
            const classes = [];

            if(this.notificationsBadge) {
                if(this.darkMode) {
                    classes.push('text-grey-120');
                } else {
                    classes.push('text-grey-900');
                }
            } else {
                if(this.darkMode) {
                    classes.push('text-grey-120');
                } else {
                    classes.push('text-grey-900');
                }
            }

            return classes;
        }
    }
}
</script>

<style scoped>

</style>
