<template>
    <div>
        <alerts-container :dark-mode="darkMode" v-if="alertActive" :alert-type="alertType" :text="alertText" />

        <div class="grid grid-cols-7 gap-x-3 mb-2 px-5">
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs">Campaign</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs text-center">Status</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs text-center">Spent Today</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs text-center">Avg. Daily Spend</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs text-center">Daily Budget</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs text-center">Budget Usage</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs text-center">Max Budget Usage</p>
        </div>
        <template v-if="!loading && paginationData?.total > 0">
            <div class="border-t border-b overflow-y-auto"
                 :class="[darkMode ? 'bg-dark-background border-dark-border' : 'bg-light-background  border-light-border']">
                <div>
                    <div class="grid grid-cols-7 gap-x-3 border-b px-5 py-3 items-center"
                         v-for="campaignBudget in campaignBudgets" :key="campaignBudget.id"
                         :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                        <a :href="getLegacyAdminCompanyCampaignsUrl(legacyCompanyId, campaignBudget.uuid)" target="_blank" class="text-sm truncate">
                            {{  campaignBudget.name }}
                        </a>
                        <div class="text-sm truncate text-center">
                            <div v-if="campaignBudget.status" class="px-5 inline-flex items-center py-1 text-sm font-semibold rounded-full border" :class="[darkMode ? ' border-green-400 text-green-400 bg-transparent' : 'border-transparent bg-green-150 text-green-400']">
                                Active
                            </div>
                            <div v-else class="px-5 inline-flex items-center py-1 text-sm font-semibold rounded-full border" :class="[darkMode ? ' border-red-400 text-red-400 bg-transparent' : 'border-transparent bg-red-100 text-red-400']">
                                Inactive
                            </div>
                        </div>
                        <p class="text-sm truncate text-center">
                            ${{  campaignBudget.spent_today }}
                        </p>
                        <p class="text-sm truncate text-center">
                            {{ campaignBudget.daily_spend }}
                        </p>
                        <p class="text-sm truncate text-center">
                            {{ campaignBudget.unlimited ? "No Limit" : (campaignBudget.max_daily_lead != null ? campaignBudget.max_daily_lead + ' Lead(s)' : '$' + campaignBudget.max_daily_spend) }}
                        </p>
                        <p class="text-sm truncate text-center">
                            {{ campaignBudget.budget_usage }}%
                        </p>
                        <p class="text-sm truncate text-center">
                            {{ campaignBudget.max_budget_usage }}%
                        </p>
                    </div>
                </div>
            </div>
            <div class="p-3">
                <Pagination :dark-mode="darkMode" :pagination-data="paginationData" :show-pagination="true" @change-page="handlePaginationEvent"></Pagination>
            </div>
        </template>
        <template v-else-if="!loading">
            <div class="text-center w-full my-10">
                No data
            </div>
        </template>
        <template v-else-if="loading">
            <div class="text-center w-full my-10">
                <loading-spinner/>
            </div>
        </template>
    </div>
</template>

<script>
    import SharedApiService from "../services/api";
    import Pagination from "../components/Pagination";
    import LoadingSpinner from "../components/LoadingSpinner";
    import AlertsContainer from "../components/AlertsContainer";
    import AlertsMixin from "../../../mixins/alerts-mixin";
    import LegacyAdminMixin from "../mixins/legacy-admin-mixin";

    export default {
        name: "BudgetUsageTable",
        components: {
            AlertsContainer,
            LoadingSpinner,
            Pagination
        },
        props: {
            darkMode: {
                type: Boolean,
                default: false
            },
            companyId: {
                type: Number,
                default: 0,
                required: true
            },
            legacyCompanyId: {
                type: Number,
                default: null,
            }
        },
        mixins: [
            AlertsMixin,
            LegacyAdminMixin
        ],
        created: function() {
            this.api = SharedApiService.make();
            this.getCampaignBudgets();
        },
        data: function() {
            return {
                api: null,
                campaignBudgets: null,
                paginationData: null,
                loading: false,
                alertType: '',
                alertActive: false,
                alertText: '',
            };
        },
        methods: {
            getCampaignBudgets(page = 1) {
                this.loading = true;

                this.api.getCompanyBudgetUsage(this.companyId, page).then(res => {
                    if(res.data.data.status === true) {
                        let {data, ...paginationData} = res.data.data.campaigns;
                        this.campaignBudgets = data;
                        this.paginationData = paginationData;
                    }
                    else {
                        this.showAlert('error', 'Failed to retrieve campaign budgets');
                    }
                }).catch(() => {
                    this.showAlert('error', 'Failed to retrieve campaign budgets');
                }).finally(() => {
                    this.loading = false;
                });
            },
            async handlePaginationEvent(newPageUrl) {
                this.loading = true;
                await axios.get(newPageUrl.link).then(resp => {
                    let {data, ...paginationData} = resp.data.data.campaigns;
                    this.campaignBudgets = data;
                    this.paginationData = paginationData;
                }).catch(e => console.error(e)).finally(() => this.loading = false);
            }
        }
    }
</script>

<style scoped>

</style>
