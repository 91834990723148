<template>
    <modal :dark-mode="darkMode" @close="closeModal" :small="true" :confirm-text="confirmText" :close-text="'Cancel'" @confirm="saveAction">
        <template v-slot:header>
            <h4 class="text-xl font-medium">{{ action ? 'Edit' : 'Add' }} Action</h4>
        </template>
        <template v-slot:content>
            <alerts-container
                v-if="alertActive"
                :alert-type="alertType"
                :text="alertText"
                :dark-mode="darkMode">
            </alerts-container>
            <div>
                <p class="uppercase font-semibold text-xs" :class="[darkMode ? 'text-slate-100' : 'text-slate-900']">Subject</p>
                <div class="my-2">
                    <input v-model="subject" class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                           placeholder="Enter subject" type="text"
                           :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>
                </div>
            </div>
            <div>
                <p class="uppercase font-semibold text-xs" :class="[darkMode ? 'text-slate-100' : 'text-slate-900']">Category</p>
                <div class="my-2">
                    <Dropdown
                        :placeholder="categories.placeholder"
                        :dark-mode="darkMode"
                        :options="categories.options"
                        v-model="categoryId"
                    />
                </div>
            </div>
            <div>
                <p class="uppercase font-semibold text-xs pt-3" :class="[darkMode ? 'text-slate-100' : 'text-slate-900']">Notes</p>
                <wysiwyg-editor
                    v-model="note"
                    :dark-mode="darkMode"
                    auto-width="100%"
                >
                </wysiwyg-editor>
            </div>
            <div>
                <p class="uppercase font-semibold text-xs pt-3" :class="[darkMode ? 'text-slate-100' : 'text-slate-900']">Target</p>
                <div class="my-2">
                    <Dropdown :dark-mode="darkMode" :options="crmOptions" v-model="targetType" />
                </div>
            </div>
            <div v-if="targetType === 'company_contact'">
                <p class="uppercase font-semibold text-xs pt-3">Company Contact</p>
                <div class="my-2">
                    <Dropdown :placeholder="'Select'" :options="companyContacts" v-model="companyContactId" />
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
    import MarkdownEditorMixin from "../mixins/markdown-editor-mixin";
    import MarkdownEditor from "../components/MarkdownEditor";
    import Modal from "../components/Modal";
    import ApiService from "../../Tasks/services/api";
    import Dropdown from "../components/Dropdown";
    import SharedApiService from "../services/api";
    import WysiwygEditor from "./WysiwygEditor.vue";
    import AlertsContainer from "./AlertsContainer";
    import AlertsMixin from "../../../mixins/alerts-mixin";

    export default {
        name: "ActionNoteModal",
        components: {
            AlertsContainer,
            WysiwygEditor,
            MarkdownEditor,
            MarkdownEditorMixin,
            Modal,
            Dropdown
        },
        mixins: [
            MarkdownEditorMixin, AlertsMixin
        ],
        props: {
            darkMode: {
                type: Boolean,
                default: false
            },
            action: {
                type: Object,
                default: () => {}
            },
            companyId: {
                type: Number,
                default: 0
            },
            taskId: {
                type: Number,
                default: 0
            },
            categoryDefault: {
                type: String,
                default: null
            }
        },
        data: function() {
            return {
                sharedApi: SharedApiService.make(),
                taskApi: ApiService.make(),
                crmOptions: [
                    {id: 'company', name: 'Company'},
                    {id: 'company_contact', name: 'Company Contact'}
                ],
                categories: {
                    options: [],
                    placeholder: 'Select Category',
                },
                companyContacts: [],

                actionId: this.action?.id || 0,
                subject: this.action?.subject || '',
                categoryId: this.action?.action_category_id || 0,
                note: this.action?.message || '',
                targetType: this.action?.target_type || 'company',
                companyContactId: this.action?.target_type === 'company_contact' ? this.action?.target_id || 0 : 0,

                confirmText: 'Save',
                saving: false,

                errorMessages: {
                    getCategories: 'Error fetching Action Categories',
                    saveAction: 'An unknown error occurred while saving an Action'
                },
            };
        },
        async created() {
            await this.getCompanyContacts();
            await this.getActionCategories();
            const item = this.categoryDefault ? this.categories.options.find(item => item.name === this.categoryDefault) : null;
            this.categoryId = item ? item.id : this.categoryId;
        },
        methods: {
            async getActionCategories() {
                return this.sharedApi.getActionCategories().then(response => {
                    if (response.data.data.status) {
                        this.categories.options = response.data.data.categories;
                    } else {
                        this.showAlert('error', this.errorMessages.getCategories);
                    }
                }).catch(err => {
                    this.showAlert('error', err.response?.data?.message || this.errorMessages.getCategories);
                });
            },
            async getCompanyContacts() {
                this.sharedApi.getCompanyContacts(this.companyId).then(resp => this.companyContacts = resp.data.data.contacts);
            },
            saveAction() {
                if(this.saving)
                    return;

                this.saving = true;
                this.confirmText = 'Saving...';

                this.taskApi.saveAction(
                    this.actionId,
                    this.taskId,
                    this.targetType === 'company' ? this.companyId : this.companyContactId,
                    this.targetType,
                    this.subject,
                    this.note,
                    this.categoryId,
                ).then(() => {
                    this.reloadActions();
                    this.closeModal();
                }).catch(err => {
                    this.showAlert('error', err.response?.data?.message || this.errorMessages.saveAction);
                }).finally(() => {
                    this.confirmText = 'Save';
                    this.saving = false;
                });
            },
            closeModal() {
                if (window.tinymce?.editors.length) {
                    window.tinymce?.editors.forEach(editor => window.tinymce.remove(editor));
                }
                this.$emit('close');
            },
            reloadActions() {
                this.$emit('reload-actions');
            }
        }
    }
</script>
