<template>
    <!-- TODO: determine payload -->
</template>

<script>
export default {
    name: "Payload"
}
</script>

<style scoped>

</style>
