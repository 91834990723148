<template>
    <div>
        <alerts-container v-if="alertActive" :alert-type="alertType" :text="alertText" :dark-mode="darkMode" />
        <div v-if="editReview">
            <div class="flex flex-row justify-items-between">
                <button
                    type="button"
                    class="px-5 py-3 rounded text-grey-400 bg-grey-100 hover:bg-grey-300"
                    @click="back">
                    &lt; Back
                </button>

                <div class="flex flex-row">
                    <div class="mr-5">
                        Created: {{ (new Date(editReview.created_at)).toLocaleString() }}
                    </div>
                    <div>
                        Updated: {{ (new Date(editReview.updated_at)).toLocaleString() }}
                    </div>
                </div>
            </div>

            <div class="flex flex-col">
                <div>
                    ID: {{ editReview.id }}
                </div>
                <div>
                    Consumer Name: {{ editReview.first_name }} {{ editReview.last_name }}
                </div>
                <div class="flex flex-col">
                    <div class="flex flex-row items-center">
                        <div class="mr-5">
                            Email: {{ editReview.email }}
                        </div>
                        <div class="flex flex-col">
                            <p class="text-sm uppercase">Validated</p>
                            <input type="checkbox" v-model="emailValidated">
                        </div>
                    </div>
                    <div class="flex flex-row items-center">
                        <div class="mr-5">
                            Phone: {{ editReview.phone }}
                        </div>
                        <div v-show="editReview.phone" class="flex flex-col">
                            <p class="text-sm uppercase">Validated</p>
                            <input type="checkbox" v-model="phoneValidated">
                        </div>
                    </div>
                </div>
                <div>
                    Company: {{ editReview.company?.name }} ({{ editReview.company_id }})
                </div>
                <div>
                    Score: {{ editReview.overall_score }}
                </div>
                <div class="flex flex-col">
                    <div>{{ editReview.title }}</div>
                    <div>{{ reviewBody }}</div>
                </div>
                <div>
                    <select v-model="editReview.status">
                        <option v-for="(name, status) in reviewStatuses" :value="status" :key="status">{{ name }}</option>
                    </select>
                </div>
            </div>

            <div class="flex flex-row w-full justify-items-end">
                <div v-if="updating">
                    Updating...
                </div>
                <button
                    v-else
                    type="button"
                    class="px-5 py-3 rounded border border-cyan-400 text-cyan-400 hover:bg-cyan-400 hover:text-white"
                    @click="update">
                    Update
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import ApiService from "../API/api";
    import AlertsContainer from "../../Shared/components/AlertsContainer";

    export default {
        name: "CompanyReviewsEditor",
        props: {
            darkMode: {
                type: Boolean,
                default: false
            },
            api: {
                type: ApiService,
                required: true
            },
            review: {
                type: Object,
                default: () => {
                    return {};
                }
            }
        },
        emits: [
            'change-tab'
        ],
        components: {
            AlertsContainer
        },
        data: function() {
            return {
                updating: false,
                editReview: null,
                reviewStatuses: {},

                alertActive: false,
                alertType: '',
                alertText: ''
            };
        },
        created: function() {
            this.api.getReviewStatuses().then(res => {
                if(res.data.data.status === true) {
                    this.reviewStatuses = res.data.data.statuses;
                }
                else {
                    this.showAlert('error', 'Problem retrieving statuses');
                }
            }).catch(err => {
                this.showAlert('error', 'Error retrieving statuses');
            });
        },
        computed: {
            emailValidated: {
                get() {
                    return !!this.editReview.email_validated;
                },
                set(value) {
                    this.editReview.email_validated = !!value;
                }
            },
            phoneValidated: {
                get() {
                    return !!this.editReview.phone_validated;
                },
                set(value) {
                    this.editReview.phone_validated = !!value;
                }
            },
            reviewBody: function() {
                return (new DOMParser()).parseFromString(this.editReview.body, 'text/html')?.body.textContent;
            }
        },
        methods: {
            update() {
                this.updating = true;

                this.api.update(
                    this.editReview.id,
                    this.editReview.status,
                    this.editReview.email_validated,
                    this.editReview.phone_validated
                ).then(res => {
                    if(res.data.data.status === true) {
                        this.showAlert('success', 'Review updated');
                    }
                    else {
                        this.showAlert('error', 'Failed to update review');
                    }
                }).catch(err => {
                    this.showAlert('error', 'Error updating review');
                }).finally(() => {
                    this.updating = false;
                });
            },
            showAlert(type, text) {
                this.alertType = type;
                this.alertText = text;
                this.alertActive = true;

                setTimeout(() => {
                    this.alertActive = false;
                    this.alertType = '';
                    this.alertText = '';
                }, 5000);
            },
            back() {
                this.$emit('change-tab', 'list');
            }
        },
        watch: {
            review: {
                handler(newReview) {
                    this.editReview = newReview;
                },
                immediate: true
            }
        }
    }
</script>
