<template>
    <div class="border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="p-5 flex items-center justify-between">
            <h5 class="text-primary-500 text-sm uppercase font-semibold py-2 leading-tight">Sent Sales Baits</h5>
        </div>
        <div class="grid grid-cols-3 gap-x-3 mb-2 px-5">
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Lead Id</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Sent to</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Links clicked</p>
        </div>
        <div v-if="salesBaitLoading" class="border-t border-b h-64 overflow-y-auto"  :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
            <div class="flex h-full justify-center items-center">
                <loading-spinner />
            </div>
        </div>
        <div v-else>
            <div class="border-t border-b h-64 overflow-y-auto"
                 v-if="Object.keys(salesBaits).length > 0"
                 :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
                <div class="grid grid-cols-3 gap-x-3 border-b px-5 py-3"
                     v-for="salesBaits in salesBaits"
                     :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <a class="text-sm col-span-1 truncate hover:text-blue-550" :href="getLegacyAdminQuoteUrl(salesBaits.lead_id)" target="_blank">
                        {{ salesBaits.lead_id }}
                    </a>
                    <p class="text-sm col-span-1 pl-1 truncate hover:text-blue-550" @click="showSalesBaitCompaniesInformation(salesBaits)">
                        {{ salesBaits.sent_to }} (view companies)
                    </p>
                    <p class="text-sm col-span-1 pl-1 truncate">
                        {{ salesBaits.clicked }}
                    </p>
                </div>
            </div>
            <div v-else class="border-t border-b overflow-y-auto text-center py-10"
                 :class="{'text-grey-800 bg-light-background  border-light-border': !darkMode, 'text-grey-200 bg-dark-background border-dark-border': darkMode}">
                No Salesbait records found for these filters
            </div>
            <div class="p-3" v-if="Object.keys(salesBaits).length > 0">
                <Pagination :dark-mode="darkMode" :pagination-data="paginationData" :show-pagination="true" @change-page="handlePaginationEvent"></Pagination>
            </div>
        </div>

        <company-details :open="showSentSalesBaitCompanyInformation" :loading="loading" :companies="salesBaitSentToCompaniesInformation"></company-details>
        <div class="p-3 flex justify-end">
        </div>
    </div>
</template>

<script>
import Modal from "../Shared/components/Modal";
import LegacyAdminMixin from "../Shared/mixins/legacy-admin-mixin";
import CompanyDetails from "../Reports/HistoricalAvailableBudgetsReport/components/CompanyDetails";
import LoadingSpinner from "../Shared/components/LoadingSpinner";
import Pagination from "../Shared/components/Pagination";
import ApiService from "./services/api";

export default {
    name: "SalesBaitTable",
    components: {Modal, CompanyDetails, LoadingSpinner, Pagination},
    mixins: [LegacyAdminMixin],
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        salesBaits: {
            type: Object,
            default: []
        },
        salesBaitLoading: {
            type: Boolean,
            default: false
        },
        paginationData: {
            type: Object,
            default: null
        }
    },
    created() {
        this.apiService = ApiService.make();
    },
    data: function() {
        return {
            showSentSalesBaitCompanyInformation: false,
            salesBaitSentToCompaniesInformation: [],
            loading: false,
        }
    },
    methods: {
        showSalesBaitCompaniesInformation(salesBaits) {
            this.showSentSalesBaitCompanyInformation = true;
            this.loading = true
            this.apiService.getSalesBaitSentToCompanies(salesBaits.lead_id).then(resp => {
                this.salesBaitSentToCompaniesInformation = resp.data.data.companies;
            }).catch(e => console.error(e)).finally(() => this.loading = false);
        },
        toggleSalesBaitCompaniesInformation() {
            this.showSentSalesBaitCompanyInformation = !this.showSentSalesBaitCompanyInformation;
            this.salesBaitSentToCompaniesInformation = null;
        },
        handlePaginationEvent(newUrl) {
            let page = newUrl.link.slice(-1)
            this.$emit('change', page);
        }
    },

}
</script>
