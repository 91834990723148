import axios from 'axios';

export default class ApiService {
    constructor(baseUrl, baseEndpoint, version) {
        this.baseEndpoint = baseEndpoint;
        this.baseUrl = baseUrl;
        this.version = version;
    }

    axios() {
        const axiosConfig = {
            baseURL: `/${this.baseUrl}/v${this.version}/${this.baseEndpoint}`
        }

        return axios.create(axiosConfig);
    }

    static make() {
        return new ApiService('internal-api', 'sales-bait-management', 1);
    }

    getGlobalConfiguration() {
        return this.axios().get('/configuration');
    }

    getStateConfiguration(stateId, industry) {
        return this.axios().get(`/configuration/${industry}/${stateId}`);
    }

    save(industry, body) {
        return this.axios().patch(`/configuration/${industry}`, body);
    }

    getStatistics() {
        return this.axios().get('/statistics');
    }

    getSalesBaits(params) {
        return this.axios().get('/sales-baits', {
            params: params
        });
    }

    getSalesBaitSentToCompanies(leadId) {
        return this.axios().get(`/sales-bait-companies-for-lead/${leadId}`);
    }

    restrictCompany(companyId) {
        return this.axios().post(`/restrict-company`, {company_id: companyId})
    }

    getRestrictedCompanies() {
        return this.axios().get('/restricted-companies');
    }
}
