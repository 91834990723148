<template>
    <div>
        <div class="w-max">
            <header class="mb-4 ">
                <div class="text-base mb-4">
                    <strong>Phone number:</strong> <span class="text-primary-500">{{ call.to ?? 'Unknown' }}</span>
                </div>

                <div v-if="expanded">
                    <dropdown class="max-w-sm"
                              placeholder="Quick Actions"
                              @change="handleAction"
                              :options="this.activityActions"
                              :dark-mode="darkMode"
                    />
                </div>
            </header>
            <div>
                <span class="capitalize">{{ call.summary }}</span>
                <span v-if="call.duration"> - duration {{ call.duration }}s</span>
            </div>
            <div v-if="call.recording_link" class="mt-4">
                <p>Listen to recording:</p>
                <h5 class="text-sm font-bold inline">Conversation with {{ call.call_to }}</h5>
                <audio-player :audio-file="call.recording_link" :dark-mode="darkMode" />
            </div>
        </div>
    </div>
</template>

<script>
import AudioPlayer from "../../../../Shared/components/AudioPlayer";
import Dropdown from "../../../../Shared/components/Dropdown";
import dispatchesGlobalEventsMixin from "../../../../../mixins/dispatches-global-events-mixin";
import {DateTime} from "luxon";

export default {
    name: "CallItem",
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        itemData: {
            type: Object,
            default: {},
        },
        userData: {
            type: Object,
            default: {},
        },
        expanded: {
            type: Boolean,
            default: false,
        }
    },
    mixins: [ dispatchesGlobalEventsMixin ],
    components: {
        AudioPlayer,
        Dropdown
    },
    data() {
        return {
            call: {
                from: null,
                to: null,
                direction: null,
                summary: '',
                duration: 0,
                result: null,
                call_start: null,
                call_end: null,
                recording_link: null,
                phone_id: null,
                other_number: '',
                id: null
            },
            user: {
                name: '',
                id: null,
            },
            activityActions: [
                // Example follow-up actions - return customer call, or contact the admin user who attempted the call
                { id: 'returnCall', name: `Follow-up call ${this.itemData?.other_number ?? ''}` },
                { id: 'emailUser', name: `Email ${this.userData?.name ?? 'User'}` }
            ],
        }
    },
    computed: {

    },
    mounted() {
        this.call = { ...this.call, ...this.itemData };
        this.user = { ...this.user, ...this.userData };
        this.call.recording_link = this.itemData.call_recording?.recording_link ?? '';
        this.getCallSummary()
        this.getCallDuration();
        this.getCallParticipants();
    },
    methods: {
        getCallSummary() {
            this.call.summary = `${this.call.direction} call: ${this.call.result}`;
        },
        handleAction({ id }) {
            if (typeof(this[id]) === 'function') this[id]();
            else alert(`Action triggered: ${id}, not yet implemented.`);
        },
        getCallDuration() {
            if (!this.call.call_end || !this.call.call_start) {
                this.call.duration = 0;
                return;
            }

            const callEnd = DateTime.fromISO(this.call.call_end),
                callStart = DateTime.fromISO(this.call.call_start);

            this.call.duration = callEnd.diff(callStart, 'seconds')?.toObject()?.seconds ?? 0;
        },
        getCallParticipants() {
            const userName =  this.user.name || 'Unknown User';
            const contact = this.call.other_number || 'Unknown Contact';
            Object.assign(this.call, {
                from: /^out/i.test(this.call.direction) ? userName : contact,
                to: /^out/i.test(this.call.direction) ? contact : userName,
            });
        },
        async returnCall() {
            const phoneNumber = `${this.call.other_number}`.replace(/\D/g, '');
            if (/\d{10,11}/.test(phoneNumber)) {
                this.dispatchGlobalEvent('call', { phone: phoneNumber, name: 'Contact', comment: 'ActivityFeed Call' });
            }
        },
        emailUser() {
            if (this.userData.email) window.open(`mailto:${this.userData.email}`, '_self');
        }
    }
}

</script>

<style scoped>

</style>
