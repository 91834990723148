<template>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-10 py-5">
        <div class="lg:col-span-2">
            <CompaniesUsers :has-edit-rights="hasEditRights" :dark-mode="darkMode" :company-id="companyId"></CompaniesUsers>
        </div>
        <CompanyContacts :has-edit-rights="hasEditRights" :dark-mode="darkMode" :show-add-contact-button="true" :company-id="companyId"></CompanyContacts>
    </div>
</template>

<script>
import CompaniesUsers from "../../Shared/modules/CompaniesUsers";
import CompanyContacts from "../../Shared/modules/Contacts";

export default {
    name: "UsersAndContacts",
    components: {CompanyContacts, CompaniesUsers},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        hasEditRights: {
            type: Boolean,
            default: false,
        },
        companyId: {
            type: Number,
            default: null
        }
    }
}
</script>

<style scoped>

</style>
