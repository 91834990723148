class BaseApiService {
    constructor(serviceName = "BaseApiService") {
        this.serviceName = serviceName;
    }

    _throwUnimplementedError(fn) {
        throw new Error("Not implemented error. `" + this.serviceName + "::" + fn + "()`");
    }

    getAvailableNumbers() {
        this._throwUnimplementedError("getAvailableNumbers");

        return new Promise((resolve, reject) => {reject()});
    }

    getUsers() {
        this._throwUnimplementedError("getUsers");

        return new Promise((resolve, reject) => {reject()});
    }

    deleteUsers() {
        this._throwUnimplementedError("deleteUsers");

        return new Promise((resolve, reject) => {reject()});
    }

}

export { BaseApiService };
