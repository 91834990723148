<template>
    <div class="relative w-full">
        <div @click="toggleDropdown"
             :class="{'hover:bg-grey-120 border-grey-200 text-grey-400 bg-grey-50': !darkMode, 'hover:bg-dark-175 border-blue-700 text-blue-400 bg-dark-background': darkMode,  'z-60': this.dropdownActive, 'z-20': !this.dropdownActive }"
             class="relative h-9 cursor-pointer uppercase text-xs font-semibold transition duration-100 w-full border flex justify-between items-center rounded py-3 px-4">
            <p>{{  selectedOption ? (selectedOption.name ? selectedOption.name : selectedOption) : placeholder }}</p>
            <svg :class="{'rotate-180': dropdownActive }" class="fill-current w-6 text-blue-550 transform transition-all duration-200" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2071 0.792893C12.5976 1.18342 12.5976 1.81658 12.2071 2.20711L7.20711 7.20711C6.81658 7.59763 6.18342 7.59763 5.79289 7.20711L0.792893 2.20711C0.402368 1.81658 0.402369 1.18342 0.792893 0.792893C1.18342 0.402368 1.81658 0.402368 2.20711 0.792893L6.5 5.08579L10.7929 0.792893C11.1834 0.402369 11.8166 0.402369 12.2071 0.792893Z"/>
            </svg>

            <div v-if="dropdownActive" class="absolute left-0 max-h-56 divide-y divide-inherit overflow-y-auto z-50 mt-10 rounded top-0 w-full border shadow-module"
                 :class="{'border-grey-200 bg-light-module': !darkMode, 'border-blue-800 bg-dark-background': darkMode}"
            >
                <p
                    :class="{'hover:bg-grey-120': !darkMode, 'hover:bg-dark-175': darkMode}"
                    class="py-3 px-4 uppercase text-xs font-semibold transition duration-200" v-for="option in options" @click="() => selectOption(option)" :key="option.id ? option.id : option">{{ option.name ? option.name : option }}</p>
            </div>

        </div>
        <div v-if="dropdownActive" @click="toggleDropdown" class="fixed inset-0 z-10"></div>
    </div>

</template>

<script>
export default {
    name: "Dropdown",
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default: () => []
        },
        selected: {
            default: null
        },
        placeholder: {
            type: String,
            default: 'Select'
        }
    },
    data() {
        return {
            dropdownActive: false,
            selectedOption: this.selected,
        }
    },
    emits: ['update:modelValue'],
    methods: {
        toggleDropdown() {
            this.dropdownActive = ! this.dropdownActive
        },

        selectOption(option) {
            this.selectedOption = option;
            this.$emit('update:modelValue', this.selectedOption.id);
        }
    }
}
</script>

<style scoped>

</style>
