<template>
    <div class="border rounded-lg"
         :class="[darkMode ? 'text-white bg-dark-module border-dark-border' : 'bg-light-module border-light-border text-slate-900']">
<!--        header -->
        <div class="flex justify-between p-5">
            <div class="flex">
<!--                <svg @click="" class="w-4" :class="{'transform transition duration-200 rotate-90' : true}" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M1 9L5 5L1 1" stroke="#0081FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                </svg>-->
                <p><span class="font-bold">Email - {{email.subject}}</span> from {{email.fromaddress}}</p>
            </div>
            <div class="w-1/3 grid grid-cols-3 gap-2">
<!--                todo add support for whatever pin does -->
                <div class="text-primary-500">
                    Pin
                </div>
                <div class="text-primary-500">
                    Copy Link
                </div>
                <div>
                    {{ email.timestampadded }}
                </div>
            </div>
        </div>
        <div>
<!--        contact info and buttons-->
            <div class="flex justify-between p-5">
                <div class="flex">
<!--                    todo link the sender to a user and get there information to render here-->
                    <div>
                        <img src="" class="rounded-full h-10 w-10">
                    </div>
                    <div>
                        <div @click="receiverDropDown = !receiverDropDown" class="flex">
                            <p>{{email.sender_name}}</p>
<!--                            <svg @click="" class="w-4" :class="{'transform transition duration-200 rotate-90' : true}" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                <path d="M1 9L5 5L1 1" stroke="#0081FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                            </svg>-->
                        </div>
                        <div>
                            <p>to {{email.toaddress}}</p>
<!--                            todo get the email receiver user details and render-->
                            <div v-if="receiverDropDown">
                            </div>
                        </div>
                    </div>
                </div>
<!--                todo support email sending - reply-->
<!--                <div class="flex grid grid-cols-3 gap-2">-->
<!--                    <div class="text-primary-500">-->
<!--                        Reply-->
<!--                    </div>-->
<!--                    <div class="text-primary-500">-->
<!--                        Forward-->
<!--                    </div>-->
<!--                    <div class="text-primary-500">-->
<!--                        Delete-->
<!--                    </div>-->
<!--                </div>-->
            </div>
<!--            <div class="flex">-->
<!--                <div class="w-4 p-1 mr-4 cursor-pointer">-->
<!--                    <svg @click="" class="w-4" :class="{'transform transition duration-200 rotate-90' : false}" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                        <path d="M1 9L5 5L1 1" stroke="#0081FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                    </svg>-->
<!--                </div>-->
<!--                <div>-->
<!--                    Opens: {{email.opens}}-->
<!--                </div>-->
<!--                <div>-->
<!--                    Clicks: {{email.clicks}}-->
<!--                </div>-->
<!--            </div>-->
            <!--    body -->
            <div class="p-5">
                <div>
                    <div v-html="email.content"></div>
                </div>
            </div>
<!--            todo threads-->
<!--            <div>-->
<!--                <div @click="showThread = !showThread" v-if="email.thread">-->
<!--                    View Thread ({{ email.thread.length }})-->
<!--                </div>-->
<!--                <div v-if="showThread">-->

<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
export default {
    name: "EmailItem",
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        itemData: {
            type: Object,
            default: {}
        },
    },
    data() {
        return {
            //todo match this to the new email activity item table names
            // email: {
            //     external_reference: null,
            //     external_type: null,
            //     sender_name: null,
            //     date_sent: null,
            //     receiver_name: null,
            //     receiver_email: null,
            //     subject: null,
            //     body: null,
            //     thread: null,
            // },
            email: {
                fromaddress: null,
                toaddress: null,
                content: null,
                subject: null,
                timestampadded: null,
            },
            receiverDropDown: false,
            showThread: false,
        }
    },
    computed: {

    },
    mounted() {
        Object.assign(this.email, this.itemData);
        this.email.timestampadded = new Date(this.email.timestampadded * 1000).toDateString();
    },
}

</script>

<style scoped>

</style>
