import {validateAlert} from "../../modules/alerts/helpers";

export default {
    data: function () {
        return {
            alertActive: false,
            alertType: '',
            alertText: ''
        }
    },
    methods: {
        /**
         * @param {"warning"|"error"|"success"} type
         * @param text
         * @param timeout
         */
        showAlert(type, text, timeout = 5000) {
            const alertTypes = ['warning', 'error', 'success'];

            if (!alertTypes.includes(type)) {
                throw new TypeError(`"type" must be one of the following: ${JSON.stringify(alertTypes)}. "${type}" passed. Defaulting to "success".`)
            }

            if (typeof text !== 'string') throw new TypeError(`"text" must be a string. ${text} passed.`)

            validateAlert(type, text);

            this.alertType = type;
            this.alertText = text;
            this.alertActive = true;

            setTimeout(() => {
                this.alertText = false;

                this.alertText = '';
                this.alertType = '';
            }, timeout)
        }
    }
}
