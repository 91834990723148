<template>
    <div v-if="callActive" class="absolute right-0 bottom-0 mr-6 mb-4 z-30">
        <div
            class="transition-all transform duration-400 right-0 bottom-0 left-full top-full">
            <div
                class="md:w-64 shadow rounded-lg transform transition-all duration-500 incoming-call-background">
                <div class="flex items-center justify-between px-6 py-4 text-white">
                    <h5 class="text-sm uppercase font-medium">Incoming call</h5>
                </div>
                <div>
                    <div class="flex justify-center">
                        <div class="mt-0" v-if="id != null">
                            <h3 class="text-center font-medium mb-1 text-lg text-white">{{ name }}</h3>
                            <p class="text-white text-base text-center">{{ type }} - #{{ id }}</p>
                        </div>
                        <div class="mt-0" v-else>
                            <h3 class="text-center font-medium mb-1 text-lg text-white">Unknown</h3>
                            <p class="text-white text-base text-center">{{ phone }}</p>
                        </div>
                    </div>
                    <div class="flex items-center justify-between mx-10 py-6">
                        <div>
                            <div @click="endCall"
                                 class="cursor-pointer bg-red-450 hover:bg-red-500 transition duration-200 rounded-full h-16 w-16 inline-flex items-center justify-center">
                                <svg class="w-6 h-6" viewBox="0 0 37 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M2.81354 15.5665L11.7471 15.1417C12.1693 15.1215 12.5672 14.9381 12.8567 14.6301C13.1462 14.3221 13.3047 13.9137 13.2988 13.491L13.2205 7.90365C14.0101 7.36724 15.7586 6.44982 18.4994 6.44636C21.2447 6.4475 22.9966 7.36836 23.7782 7.90361L23.7034 13.4898C23.697 13.9126 23.8554 14.3213 24.145 14.6293C24.4346 14.9374 24.8327 15.1207 25.255 15.1405L34.1863 15.5652C34.6094 15.5855 35.0238 15.4401 35.3415 15.1599C35.6592 14.8796 35.8551 14.4865 35.8876 14.0642L36.2433 9.42414C36.2426 9.0254 36.0955 8.64079 35.8301 8.34327C35.5596 8.03824 29.0721 0.892343 18.4982 0.890085C9.27692 0.891273 3.1635 6.33016 1.5727 7.92097C1.34018 8.15349 1.20435 8.30313 1.16867 8.34341C0.902967 8.64063 0.756595 9.02562 0.757728 9.42428L1.11569 14.0643C1.14672 14.4867 1.34166 14.8803 1.65888 15.1609C1.97611 15.4416 2.39048 15.5871 2.81354 15.5665V15.5665Z"
                                        fill="white"></path>
                                </svg>
                            </div>
                            <p class="text-center font-medium mt-1 text-white">
                                Decline
                            </p>
                        </div>
                        <div>
                            <div @click="answerCall"
                                 class="cursor-pointer bg-green-450 hover:bg-green-500 transition duration-200 rounded-full h-16 w-16 inline-flex items-center justify-center incoming-call-shake">
                                <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.6718 16.535L16.019 12.3046C15.799 12.1047 15.51 11.998 15.213 12.0072C14.9159 12.0164 14.634 12.1407 14.4268 12.3538L11.6878 15.1706C11.0285 15.0447 9.70304 14.6315 8.33868 13.2706C6.97431 11.9051 6.5611 10.5762 6.43863 9.92151L9.25321 7.18135C9.46658 6.97432 9.59103 6.6924 9.60022 6.39525C9.6094 6.09809 9.50261 5.80902 9.30243 5.58921L5.07312 0.937575C4.87286 0.717076 4.59453 0.583327 4.29725 0.564734C3.99996 0.546141 3.70714 0.644167 3.48097 0.837995L0.997177 2.96809C0.799287 3.16669 0.681174 3.43102 0.665241 3.71093C0.648072 3.99708 0.320715 10.7754 5.57674 16.0337C10.162 20.6178 15.9057 20.9531 17.4875 20.9531C17.7187 20.9531 17.8606 20.9463 17.8984 20.944C18.1783 20.9283 18.4425 20.8097 18.6401 20.6109L20.7691 18.126C20.9637 17.9005 21.0624 17.6079 21.0443 17.3107C21.0261 17.0134 20.8924 16.735 20.6718 16.535V16.535Z" fill="white"/>
                                </svg>
                            </div>
                            <p class="text-center font-medium mt-1 text-white">
                                Accept
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "IncomingCall",
    props: {
        callActive: {
            type: Boolean,
            default: false,
        },
        id: {
            default: null,
        },
        name: {
            type: String,
            default: null
        },
        phone: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: null
        },
    },
    methods: {
        answerCall() {
            this.$emit('answer-call');
        },
        endCall() {
            this.$emit('end-call');
        }
    }
}
</script>

<style scoped>
.incoming-call-background {
    animation: color-fade 2s linear infinite;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
}
.incoming-call-shake {
    animation: shake 2s linear infinite;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
}
@keyframes color-fade {
    0% {
        background-color: #0c88ff;
    }
    50% {
        background-color: #005ab0;
    }
    100% {
        background-color: #0c88ff;
    }
}
@-moz-keyframes shake {
    0% { -moz-transform: rotate(0deg); }
    80% { -moz-transform: rotate(0deg); }
    90% { -moz-transform: rotate(-20deg); }
    100% { -moz-transform: rotate(20deg); }
}
@-webkit-keyframes shake {
    0% { -webkit-transform: rotate(0deg); }
    80% { -webkit-transform: rotate(0deg); }
    90% { -webkit-transform: rotate(-20deg); }
    100% { -webkit-transform: rotate(20deg); }
}
@keyframes shake {
    0% { transform:rotate(0deg); }
    80% { transform:rotate(0deg); }
    90% { transform:rotate(-20deg); }
    100% { transform:rotate(20deg); }
}
</style>
