import {defineStore} from "pinia";
import {ref} from "vue";

export const useCallStore = defineStore('call',
    () => {
        const callActive = ref(false);

        return {
            callActive,
        }
    },
    {
        persist: false,
    }
);
