<template>
    <div class="p-5">
        <div class="flex items-center justify-between mb-3">
            <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Industry Type - Review List</h5>
            <button
                class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5" @click="openModal">
                Add New Review
            </button>
        </div>
        <div class="grid grid-cols-11 gap-x-3 mb-2 px-5" v-if="!loading">
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Company</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Name</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Title</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-4">Review</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Score</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Status</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Created At</p>
        </div>
        <div class="border-t border-b" v-if="!loading"
             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-40 border-dark-border': darkMode}">
            <div>
                <div class="grid grid-cols-11 gap-x-3 border-b px-5 py-3 items-center"
                     v-for="review in reviews" :key="review.id"
                     :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <p class="text-sm col-span-1 truncate">
                        {{ review.company.name }}
                    </p>
                    <p class="text-sm col-span-1 truncate">
                        {{ review.first_name + " " + review.last_name }}
                    </p>
                    <p class="text-sm col-span-1 truncate">
                        {{ review.title }}
                    </p>
                    <p class="text-sm col-span-4 truncate">
                        {{ review.body }}
                    </p>
                    <p class="text-sm col-span-1 truncate">
                        {{ review.score }}
                    </p>
                    <p class="text-sm col-span-1 truncate">
                        {{ review.status }}
                    </p>
                    <p class="text-sm col-span-1">
                        {{ formatDate(review.created_at) }}
                    </p>
                    <ActionsHandle :dark-mode="darkMode" @delete="deleteIndustryTypeReview(review)" :no-edit-button="true"/>
                </div>
            </div>
        </div>
        <div class="h-32 flex items-center justify-center" v-if="loading">
            <loading-spinner></loading-spinner>
        </div>
        <modal :small="true" v-if="showModal" @close="closeModal" :dark-mode="darkMode" @confirm="saveIndustryTypeReview" :close-text="'Cancel'" :confirm-text="confirmText">
            <template v-slot:header>
                <h4>Create Review</h4>
            </template>
            <template v-slot:content>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-3">
                    <div>
                        <p class="uppercase font-semibold text-xs mb-2"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Company
                        </p>
                        <Autocomplete
                            :input-class="['w-full border border-grey-350 rounded px-4 py-3', {'border-grey-350 bg-light-module': !darkMode, 'border-blue-400 bg-dark-background text-blue-400': darkMode}]"
                            :list-class="['absolute top-0 w-full border rounded', {'border-grey-200 bg-light-module': !darkMode, 'border-dark-40 bg-dark-module': darkMode}]"
                            :option-class="['cursor-pointer px-5 py-2 my-1', {'hover:bg-grey-100': !darkMode, 'hover:bg-blue-800': darkMode}]"
                            v-model="review.company.id"
                            :value="review.company.id"
                            :options="companies"
                            @search="searchCompany"
                            :placeholder="'Enter Name'">
                        </Autocomplete>
                    </div>
                    <div>
                        <p class="uppercase font-semibold text-xs mb-2"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            First Name
                        </p>
                        <input class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                               placeholder="First Name"
                               v-model="review.first_name"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                    </div>
                    <div>
                        <p class="uppercase font-semibold text-xs mb-2"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Last Name
                        </p>
                        <input class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                               placeholder="Last Name"
                               v-model="review.last_name"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                    </div>
                    <div>
                        <p class="uppercase font-semibold text-xs mb-2"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Title
                        </p>
                        <input class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                               placeholder="Title"
                               v-model="review.title"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                    </div>
                    <div>
                        <p class="uppercase font-semibold text-xs mb-2"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Score
                        </p>
                        <input class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                               placeholder="Score"
                               v-model="review.score"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                    </div>
                </div>
                <div>
                    <p class="uppercase font-semibold text-xs mb-2"
                       :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                        Review/Question
                    </p>
                    <input class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                           placeholder="Review"
                           v-model="review.body"
                           :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                </div>
            </template>
        </modal>
        <alerts-container :dark-mode="darkMode" :alert-type="'error'" :text="error" v-if="error"></alerts-container>
    </div>

</template>

<script>
import Dropdown from "../../../Shared/components/Dropdown";
import Modal from "../../../Shared/components/Modal";
import ApiService from "../services/api";
import ActionsHandle from "../../../Shared/components/ActionsHandle";
import LoadingSpinner from "../../../Shared/components/LoadingSpinner";
import AlertsContainer from "../../../Shared/components/AlertsContainer";
import {DateTime} from "luxon";
import Autocomplete from "../../../Shared/components/Autocomplete";
import SharedApiService from "../../../Shared/services/api";

export default {
    name: "IndustryTypeReviews",
    components: {Autocomplete, Dropdown, Modal, ActionsHandle, LoadingSpinner, AlertsContainer},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        industryType: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            api: ApiService.make(),
            sharedApi: SharedApiService.make(),
            showModal: false,
            reviews: [],
            review: {company: {id: null, name: null}, first_name: null, last_name: null, title: null, body: null, score: null, status: null},
            companies: [],
            saving: false,
            loading: false,
            error: null
        }
    },
    created() {
        this.getIndustryTypeReviews();
    },
    computed: {
        confirmText: function () {
            if (this.saving) return 'Saving...';
            return 'Create';
        }
    },
    methods: {
        openModal() {
            this.showModal = true;
        },
        closeModal () {
            this.showModal = false;
            this.review = {company: {id: null, name: null}, first_name: null, last_name: null, title: null, body: null, score: null, status: null};
        },
        getIndustryTypeReviews() {
            if(!this.industryType) return;
            if(!this.loading) this.loading = true;
            this.error = null;

            this.api.getIndustryTypeReviews(this.industryType).then(resp => {
                console.log(resp.data.data.status)
                if (resp.data.data.status === true) {
                    this.reviews = resp.data.data.reviews;
                    return;
                }
                throw new Error("Status was unsuccessful!")
            }).catch(e => {console.log(e); this.error = e.response.data.message}).finally(() => this.loading = false);
        },
        saveIndustryTypeReview() {
            if(this.saving) return;

            this.saving = true;
            this.error  = null;

            this.createIndustryTypeReview();
        },
        getParamsToMakeRequest() {
            return {
                company_id     : this.review.company.id,
                first_name     : this.review.first_name,
                last_name      : this.review.last_name,
                title          : this.review.title,
                body           : this.review.body,
                overall_score  : this.review.score
            }
        },
        createIndustryTypeReview() {
            this.api.createIndustryTypeReview(this.industryType, this.getParamsToMakeRequest()).then(() => this.refreshList(true, true))
                .catch(e => this.error = e.response.data.message).finally(() => this.saving = false);
        },
        deleteIndustryTypeReview(review) {
            this.error = null;
            this.api.deleteIndustryTypeReview(review.id).then(() => this.refreshList(false, true))
                .catch(e => this.error = e.response.data.message);
        },
        formatDate(date) {
            return date ? DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT) : null
        },
        searchCompany(query) {
            this.sharedApi.getAdminCompanies(query).then(resp => {
                if (resp.data.data.status === true) {
                    this.companies = resp.data.data.companies;
                    return;
                }
                throw new Error("Status was unsuccessful!")
            }).catch(e => this.error = e.response.data.message);
        },
        refreshList(closeModal = false, fetchCompanies = false) {
            this.getIndustryTypeReviews();

            if(closeModal) this.closeModal();
            if(fetchCompanies) this.getSystemCompanies();
        }
    }
}
</script>
