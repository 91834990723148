<template>
    <div class="p-5">
        <div class="flex items-center justify-between mb-3">
            <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Product List</h5>
            <button
                class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5" @click="openModal">
                Add New Product
            </button>
        </div>
        <div class="grid grid-cols-4 gap-x-3 mb-2 px-5" v-if="!loading">
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Name</p>
        </div>
        <div class="border-t border-b" v-if="!loading"
             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-40 border-dark-border': darkMode}">
            <div>
                <div class="grid grid-cols-4 gap-x-3 border-b px-5 py-3 items-center"
                     v-for="product in products" :key="product.id"
                     :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <p class="text-sm col-span-2 truncate">
                        {{ product.name }}
                    </p>
                    <p class="text-primary-500 cursor-pointer text-sm" @click="$emit('services', product)">
                        Services
                    </p>
                    <ActionsHandle :dark-mode="darkMode" @edit="editProduct(product)" @delete="deleteProduct(product)" />
                </div>
            </div>
        </div>
        <div class="h-32 flex items-center justify-center" v-if="loading">
            <loading-spinner></loading-spinner>
        </div>
        <modal :small="true" v-if="showModal" @close="closeModal" :dark-mode="darkMode" @confirm="saveProduct" :close-text="'Cancel'" :confirm-text="confirmText">
            <template v-slot:header>
                <h4>{{ product.id ? 'Update' : 'Create' }} Product</h4>
            </template>
            <template v-slot:content>
                <div class="grid gap-3">
                    <div class="flex items-center">
                        <p class="uppercase font-semibold text-xs w-20"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Name
                        </p>
                        <input class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                               placeholder="Product name"
                               v-model="product.name"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                    </div>
                </div>
            </template>
        </modal>
        <alerts-container :dark-mode="darkMode" :alert-type="'error'" :text="error" v-if="error !== null"></alerts-container>
    </div>
</template>

<script>
import Modal from "../../Shared/components/Modal";
import AlertsContainer from "../../Shared/components/AlertsContainer";
import LoadingSpinner from "../../Shared/components/LoadingSpinner";
import ActionsHandle from "../../Shared/components/ActionsHandle";
import ApiService from "./services/api";

export default {
    name: "Products",
    components: {Modal, AlertsContainer, LoadingSpinner, ActionsHandle},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            api: ApiService.make(),
            products :[],
            loading: false,
            product: {
                name: null
            },
            error: null,
            showModal: false,
            saving: false
        }
    },
    created() {
        this.getProducts();
    },
    computed: {
        confirmText: function() {
            if (this.saving) return 'Saving...';
            if (this.product.id) return 'Update';
            return 'Create';
        }
    },
    methods: {
        getProducts() {
            this.loading = true;
            this.products = [];
            this.resetError();
            this.api.getProducts()
                .then(resp => this.products = resp.data.data.products)
                .catch(e => this.error = e.response.data.message)
                .finally(() => this.loading = false);

        },
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
            this.product = {name: null};
        },
        editProduct(product) {
            this.product = {...product};
            this.openModal();
        },
        deleteProduct(product) {
            this.api.deleteProduct(product.id)
                .then(() => this.getProducts())
                .catch(e => this.error = e.response.data.message);
        },
        saveProduct() {
            if (this.saving) return;

            this.saving = true;
            this.resetError();

            if (this.product.id) {
                this.updateProduct();
                return;
            }

            this.createProduct();
        },
        createProduct() {
            this.api.createProduct(this.product)
                .then(() => {
                    this.getProducts();
                    this.closeModal();
                })
                .catch(e => this.error = e.response.data.message)
                .finally(() => this.saving = false);
        },
        updateProduct() {
            this.api.updateUpdate(this.product.id, this.product)
                .then(() => {
                    this.getProducts();
                    this.closeModal();
                })
                .catch(e => this.error = e.response.data.message)
                .finally(() => this.saving = false);
        },
        resetError() {
            this.error = null;
        }
    }
}
</script>
