export default {
    data() {
        return {
            // TODO: Make dynamic in future
            targetRelations: [
                // TODO: database driven
                {
                    id: 'sales-manager',
                    name: 'Sales Manager'
                },
                {
                    id: 'company-account-manager',
                    name: 'Company Account Manager'
                },
                {
                    id: 'company-success-manager',
                    name: 'Company Success Manager'
                },
                {
                    id: 'support-officer-round-robin',
                    name: 'Support Officer Round Robin'
                },
                {
                    id: 'hunter-round-robin',
                    name: 'Hunter Round Robin'
                },
                {
                    id: 'sales-tool-round-robin',
                    name: 'Sales Tool Round Robin'
                },
                {
                    id: 'user-actioning',
                    name: 'User Actioning (Only use after task)'
                }
            ],
        }
    }
}
