<template>
    <div>
        <div class="grid grid-cols-1 gap-4" v-if="relatedActivity !== null && !loading">
            <!-- Pre-Raised Leads -->
            <div id="pre-raised-leads" class="row-span-3 border rounded-lg"
                 :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
                <div class="pb-6">
                    <h5 class="pt-5 px-5 pb-3 text-primary-500 text-sm uppercase font-semibold pb-4 leading-tight">Pre-Raised Leads</h5>
                    <div class="grid grid-cols-5 gap-3 mb-2 px-5">
                        <p class="text-slate-400 font-medium tracking-wide uppercase text-xs">Id</p>
                        <p class="text-slate-400 font-medium tracking-wide uppercase text-xs">Date</p>
                        <p class="text-slate-400 font-medium tracking-wide uppercase text-xs">Status</p>
                        <p class="text-slate-400 font-medium tracking-wide uppercase text-xs">Name</p>
                        <p class="text-slate-400 font-medium tracking-wide uppercase text-xs">Source</p>
                    </div>
                    <div class="border-t border-b h-24 overflow-y-auto"
                         :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
                        <div class="py-2" v-if="relatedActivity.leads.length > 0">
                            <div class="grid grid-cols-5 gap-3 px-5 pt-2" v-for="lead in relatedActivity.leads"
                                 :class="{'text-grey-800': !darkMode, 'text-grey-200': darkMode}">
                                <p class="text-xs">
                                    {{ lead.id }}
                                </p>
                                <p class="text-xs">
                                    {{ lead.date }}
                                </p>
                                <p class="text-xs">
                                    {{ lead.status }}, {{ lead.is_delivered ? "Delivered" : "Not Delivered" }}
                                </p>
                                <p class="text-xs">
                                    {{ lead.name }}
                                </p>
                                <p class="text-xs">
                                    {{ lead.source }}
                                </p>
                            </div>
                        </div>
                        <div v-else class="h-full flex flex-col justify-center items-center overflow-y-auto"
                             :class="{'bg-light-background  border-light-border text-grey-200': !darkMode, 'text-blue-500 bg-dark-background border-dark-border': darkMode}">
                            <p class="font-semibold">
                                No leads found.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Pre-Raised Reviews -->
            <div id="pre-raised-reviews" class="row-span-3 border rounded-lg"
                 :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
                <div class="pb-6">
                    <h5 class="pt-5 px-5 pb-3 text-primary-500 text-sm uppercase font-semibold pb-4 leading-tight">Pre-Raised Reviews</h5>
                    <div class="grid grid-cols-5 gap-3 mb-2 px-5">
                        <p class="text-slate-400 font-medium tracking-wide uppercase text-xs">Id</p>
                        <p class="text-slate-400 font-medium tracking-wide uppercase text-xs">Date</p>
                        <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-3">Company</p>
                    </div>
                    <div class="border-t border-b h-24 overflow-y-auto"
                         :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
                        <div class="py-2" v-if="relatedActivity.reviews.length > 0">
                            <div class="grid grid-cols-5 gap-3 px-5 pt-2" v-for="review in relatedActivity.reviews"
                                 :class="{'text-grey-800': !darkMode, 'text-grey-200': darkMode}">
                                <p class="text-xs">
                                    {{ review.id }}
                                </p>
                                <p class="text-xs">
                                    {{ review.date }}
                                </p>
                                <p class="text-xs col-span-3">
                                    {{ review.company }}
                                </p>
                            </div>
                        </div>
                        <div v-else class="h-full flex flex-col justify-center items-center overflow-y-auto"
                             :class="{'bg-light-background  border-light-border text-grey-200': !darkMode, 'text-blue-500 bg-dark-background border-dark-border': darkMode}">
                            <p class="font-semibold">
                                No reviews found.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ApiFactory} from "../../services/api/factory";

export default {
    name: "ModularRelatedActivity",
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        leadId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            relatedActivity: null,
            api: ApiFactory.makeApiService("api"),
            loading: false
        }
    },
    created() {
        if(this.relatedActivity === null && this.leadId !== null) {
            this.getRelatedActivity();
        }
    },
    watch: {
        leadId(newVal, oldVal) {
            if(newVal !== oldVal) {
                this.getRelatedActivity();
            }
        }
    },
    methods: {
        getRelatedActivity() {
            this.loading         = true;
            this.relatedActivity = null;

            this.api.getLeadRelatedActivity(this.leadId).then(resp => {
                if(resp.data.data.status === true) {
                    this.relatedActivity = resp.data.data.related_activity;
                    return;
                }
                throw new Error("Status was not successful");
            }).catch(e => console.error(e)).finally(() => this.loading = false);
        }
    }
};
</script>
