export const determineInputType = (col) => {
    if(col.includes('show_on')) {
        return 'boolean';
    }
    else if(col === 'type') {
        return 'field_type'
    }
    else if (col === 'category') {
        return 'companyFieldCategory'
    }
    else if(['name', 'key'].includes(col)) {
        return 'text';
    }
    else if(col === 'payload') {
        return 'json';
    }
    else if(['created_at', 'updated_at'].includes(col)) {
        return 'datetime';
    }
    else if(['industry_id', 'industry_service_id', 'id'].includes(col)) {
        return 'id';
    }
}
