<template>
    <div class="">
        <div v-if="delayOptions !== null && delayOptions.length > 0"
             v-for="(item, index) in payload ?? []" class="grid grid-cols-1 gap-3 border-b py-4"
             :class="{'border-grey-200': !darkMode, 'border-blue-400 ': darkMode}">
            <div class="flex items-center">
                <p @click="removeItem(index)"
                   class="cursor-pointer hover:bg-red-500 bg-primary-500 h-6 w-6 rounded-full text-white mr-3 inline-flex items-center justify-center font-bold text-sm whitespace-nowrap">
                    {{ index + 1 }}</p>
                <div class="w-full md:w-full md:pl-1">
                    <p class="uppercase font-semibold text-xs mb-1"
                       :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                        Select Delay
                    </p>
                    <dropdown :options="delayOptions" :dark-mode="darkMode"
                              v-model="payload[index]" placeholder="Select Delay"/>
                </div>
            </div>
        </div>
        <div class="flex items-center justify-center my-2">
            <button @click="addItem" type="button"
                    class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-primary-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-550">
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                     aria-hidden="true">
                    <path fill-rule="evenodd"
                          d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                          clip-rule="evenodd"/>
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
import Dropdown from "../../../Shared/components/Dropdown";

export default {
    name: "RescheduleResultNode",
    components: {Dropdown},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: Object
        }
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            delayOptions: [
                {id: 30, name: '30 Minutes'},
                {id: 60, name: '1 Hour'},
                {id: 1440, name: '1 Day'},
                {id: 2880, name: '2 Days'},
                {id: 4320, name: '3 Days'},
                {id: 5760, name: '4 Days'},
                {id: 7200, name: '5 Days'},
                {id: 8640, name: '6 Days'},
                {id: 10080, name: '1 Week'},
                {id: 20160, name: '2 Weeks'},
                {id: 43800, name: '1 Month'},
            ]
        }
    },
    computed: {
        payload: {
            get() {
                return this.modelValue?.times ?? [];
            },
            set(value) {
                this.$emit('update:modelValue', {'times': value});
            }
        }
    },
    methods: {
        addItem() {
            this.payload.push(1440);
            this.$emit('update:modelValue', {'times': this.payload});
        },
        removeItem(index) {
            this.payload.splice(index, 1);
        }
    }
}
</script>

<style scoped>

</style>
