<template>
    <div class="absolute">
        <transition name="modal">
            <div class="fixed inset-0 flex items-center justify-center bg-opacity-75 bg-dark-background z-50">
                <div class="absolute shadow rounded-lg w-full max-w-screen-xl mx-4 md:mx-8"
                     :class="[darkMode ? 'bg-dark-module border border-dark-border text-slate-100' : 'bg-light-module border border-light-border text-slate-900']">
                    <div class="flex items-center justify-between p-5 border-b"
                         :class="[darkMode ? 'border-dark-border' : 'border-light-border']">
                        <div class="flex items-center">
                            <h3 class="font-semibold text-lg mr-1"
                                :class="[darkMode ? 'text-slate-400' : 'text-slate-500']">Configure Filters for</h3>
                            <h3 class="font-semibold text-lg">{{ filterComponent.name }}</h3>
                        </div>
                        <div class="flex space-x-2">
                            <CustomButton color="slate-light" :dark-mode="darkMode" @click.stop="$emit('close')">
                                Cancel
                            </CustomButton>
                            <CustomButton :dark-mode="darkMode" @click.stop="apply">
                                Apply Filters
                            </CustomButton>
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-8 p-5 border-b relative"
                         :class="[darkMode ? 'border-dark-border' : 'border-light-border']">
                        <div class="absolute inset-0 opacity-90 z-50 flex items-center justify-center"
                             :class="[darkMode ? 'bg-dark-module' : 'bg-light-module']">
                            <!--                            <p class="font-medium" :class="[darkMode ? 'text-slate-100' : 'text-slate-900']">Filter Preset feature coming soon...</p>-->
                        </div>
                        <div class="flex items-center relative z-0">
                            <h4 class="font-medium mr-2 w-32">Filter preset:</h4>
                            <Dropdown :options="filterComponent.userPresetList" :selected="filterComponent.userPreset"
                                      v-model="filterComponent.userPreset" :dark-mode="darkMode">

                            </Dropdown>
                        </div>
                        <div v-if="initialPreset === filterComponent.userPreset" class="flex items-center gap-x-8">
                            <p :class="[(this.initialPresetList !== this.filterComponent.userPresetList) ? 'text-slate-500' : 'text-primary-500 hover:text-primary-600']"
                               class="font-medium text-sm cursor-pointer">Save</p>
                            <p :class="[(this.initialPresetList !== this.filterComponent.userPresetList) ? 'text-slate-500' : 'text-primary-500 hover:text-primary-600']"
                               class="font-medium text-sm cursor-pointer">Save As...</p>
                            <p class="text-rose-500 hover:text-rose-600 font-medium text-sm cursor-pointer">Delete</p>
                        </div>
                        <div v-else class="flex items-center gap-x-8">
                            <p class="text-primary-500 hover:text-primary-600 font-medium text-sm cursor-pointer">Save
                                Preset As...</p>
                        </div>
                    </div>
                    <div class="overflow-y-auto px-5 pt-5 pb-32 max-h-[25rem] mt-2">
                        <component :dark-mode="darkMode"
                                   :is="filterComponent.id"></component>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import CustomButton from "../components/CustomButton"
import Dropdown from "../../Shared/components/Dropdown";
import ToggleSwitch from "./ToggleSwitch";
import CompanyServicingAreaFilters from "./FilterConfigs/CompanyServicingAreaFilters";

export default {
    emits: ['apply', 'close'],
    name: "FilterConfigModal",
    components: {
        CompanyServicingAreaFilters,
        ToggleSwitch,
        Dropdown,
        CustomButton,
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        filterComponent: {
            type: Object,
        }
    },
    data() {
        return {
            initialPreset: null,
            initialPresetList: null
        }
    },
    mounted() {
        this.initialPreset = this.filterComponent.userPreset;
        this.initialPresetList = this.filterComponent.userPresetList;
    },
    methods: {
        /**
         * Runs the apply event.
         */
        apply() {
            this.$emit('apply')
            this.$emit('close')
        }
    }
}

</script>
