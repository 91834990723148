export const __MARKDOWN_IMAGE_TAG_REGEX__ = /!\[(?<filename>[a-zA-Z0-9._/]+)\]\((?<dataurl>data:\w+\/\w+;base64,[^()]+)\)/g;
export const __IMAGE_NAME_REGEX__ = /[a-zA-Z0-9_./]+/;
export const __SHORTCODE_REGEX__ = /{image:(?<filename>[a-zA-Z0-9_./]+)}/g;

export const extractMarkdownImageTags = (markdown, imagesList) => {
    // Extract all image data urls from the markdown content
    let imagesIterator = markdown.matchAll(__MARKDOWN_IMAGE_TAG_REGEX__);

    let images = {};
    for(const image of imagesIterator) {
        images[image.groups.filename] = image.groups.dataurl;
    }

    // Delete any images from the props that aren't in the new content
    let imageNames = Object.keys(images);
    Object.entries(imagesList).forEach(([name, imageDataURL]) => {
        if(!imageNames.includes(name)) {
            delete imagesList[name];
        }
    });

    // Insert images from the new content into the props images
    const imagesListNames = Object.keys(imagesList);
    Object.entries(images).forEach(([name, data_url]) => {
        if(!imagesListNames.includes(name)) {
            imagesList[name] = data_url;
        }
    });

    return imagesList;
}

export const replaceMarkdownImageTags = (markdown) => {
    return markdown.replace(__MARKDOWN_IMAGE_TAG_REGEX__, "{image:$<filename>}");
}

export const replaceShortcodes = (markdown, imageDataUrls) => {
    const callback = (match, p1) => {
        return `![${p1}](` + imageDataUrls[p1] + ')';
    };

    return markdown.replace(__SHORTCODE_REGEX__, callback);
}
