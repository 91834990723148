<template>
    <div id="comments" class="row-span-3 border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="pb-6">
            <div>
                <h5 class="pt-5 px-5 pb-3 text-primary-500 text-sm uppercase font-semibold pb-4 leading-tight">Admin Comments</h5>
            </div>
            <loading-spinner v-if="loading" :dark-mode="darkMode" />
            <div v-else class="border-t border-l border-r h-auto max-h-48 overflow-y-auto"
                 :class="{'bg-light-background border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
                <div class="border-b" :class="{ 'border-light-border': !darkMode, 'border-dark-border': darkMode }" v-if="comments.length > 0">
                    <div v-for="comment in comments" class="grid grid-cols-2 gap-2 items-center px-5 py-2 border-b"
                         :class="{ 'text-grey-800 hover:bg-light-module border-light-border': !darkMode, 'text-grey-200 hover:bg-dark-module border-dark-border': darkMode }">
                        <div class="text-xs">
                            <p>{{ comment.name }}</p>
                            <p> {{ DateTime.fromSeconds(comment.timestamp).toLocaleString(DateTime.DATE_SHORT) }}</p>
                        </div>
                        <div class="text-xs">
                            {{ comment.comment }}
                        </div>
                    </div>
                </div>
                <div v-else class="text-xs px-5 py-2">
                    No Comments
                </div>
            </div>
            <div v-if="paginationData" class="px-3 pt-5">
                <cursor-pagination :dark-mode="darkMode" :pagination-data="paginationData" @change-page="handlePaginationEvent"></cursor-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import ConsumerApiService from "../../Shared/services/consumer_api";
    import LoadingSpinner from "./LoadingSpinner";
    import {DateTime} from "luxon";
    import CursorPagination from "../../Shared/components/CursorPagination";

    export default {
        name: "Comments",
        components: {CursorPagination, LoadingSpinner},
        props: {
            consumerProductId: {
                type: Number,
                default: null,
            },
            darkMode: {
                type: Boolean,
                default: false,
            }
        },
        data: function() {
            return {
                api: ConsumerApiService.make(),
                comments: [],
                legacyLeadId: null,
                paginationData: null,
                loading: false
            };
        },
        created() {
            if (this.consumerProductId) this.getComments();
        },
        watch: {
            consumerProductId(newVal, oldVal) {
                if (newVal !== oldVal) this.getComments();
            }
        },
        computed: {
            DateTime() {
                return DateTime
            },
        },
        methods: {
            async getComments() {
                this.loading = true;

                this.api.getConsumerComments(this.consumerProductId).then((resp) => {
                    if (resp.data.data.status) this.addPaginatedData(resp.data.data.comments);
                    else this.showAlert('error', `Error fetching Consumer comments from server.`);
                }).catch(() => this.showAlert('error', `Error fetching Consumer comments from server.`)
                ).finally(() => this.loading = false);
            },
            async handlePaginationEvent(newPageUrl) {
                this.loading = true;
                await axios.get(newPageUrl.link).then(resp => {
                    if (resp.data.data.comments.data?.length) this.addPaginatedData(resp.data.data.comments);
                    else this.showAlert('error', `Error fetching Consumer comments from server.`);
                }).catch(() => this.showAlert('error', `Error fetching Consumer comments from server.`)
                ).finally(() => this.loading = false);
            },
            addPaginatedData({ data, ...paginationData }) {
                if (data && paginationData) {
                    this.comments = data;
                    this.paginationData = paginationData;
                }
                else this.showAlert('error', `Error fetching Consumer comments from server.`);
            }
        }
    }
</script>

<style scoped>

</style>
