<template>
    <div class="main-layout">
        <div>
            <alerts-container :text="alertText" :alert-type="alertType" v-if="alertActive" :dark-mode="darkMode"/>
            <div>
                <display v-if="displaySpecificLead"
                         @status-change="getQueue"
                         @set-displayed-queue="setDisplayedQueue"
                         @clear-specific-lead="clearSpecificLead"
                         @set-specific-lead="setSpecificLead"
                         @activate-alert="activateAlert($event.type, $event.text)"
                         :minimum-review-time="minimumReviewTimeMillisecs"
                         :dark-mode="darkMode"
                         :api="api"
                         :consumer-api="consumerApi"
                         :initial-lead-id="specificLeadId"
                         :displayed-queue="displayedQueue"/>
                <initial v-else-if="userQueue === 'initial'"
                         @status-change="getQueue"
                         @set-displayed-queue="setDisplayedQueue"
                         @clear-specific-lead="clearSpecificLead"
                         @set-specific-lead="setSpecificLead"
                         @activate-alert="activateAlert($event.type, $event.text)"
                         :minimum-review-time-millisecs="minimumReviewTimeMillisecs"
                         :next-lead-recheck-time-millisecs="checkNextLeadIntervalMillisecs"
                         :dark-mode="darkMode"
                         :api="api"
                         :consumer-api="consumerApi"
                         :displayed-queue="displayedQueue"/>
                <pending-review v-else-if="userQueue === 'pending_review'"
                         @status-change="getQueue"
                         @set-displayed-queue="setDisplayedQueue"
                         @clear-specific-lead="clearSpecificLead"
                         @set-specific-lead="setSpecificLead"
                         @activate-alert="activateAlert($event.type, $event.text)"
                         :minimum-review-time-millisecs="minimumReviewTimeMillisecs"
                         :next-lead-recheck-time-millisecs="checkNextLeadIntervalMillisecs"
                         :dark-mode="darkMode"
                         :api="api"
                         :consumer-api="consumerApi"
                         :displayed-queue="displayedQueue"/>
                <under-review v-else-if="userQueue === 'under_review'"
                         @status-change="getQueue"
                         @set-displayed-queue="setDisplayedQueue"
                         @clear-specific-lead="clearSpecificLead"
                         @set-specific-lead="setSpecificLead"
                         @activate-alert="activateAlert($event.type, $event.text)"
                         :minimum-review-time-millisecs="minimumReviewTimeMillisecs"
                         :next-lead-recheck-time-millisecs="checkNextLeadIntervalMillisecs"
                         :dark-mode="darkMode"
                         :api="api"
                         :consumer-api="consumerApi"
                         :displayed-queue="displayedQueue"/>
                <div v-else class="flex flex-row justify-center items-center text-2xl p-16"
                     :class="darkMode ? 'text-white' : 'text-black'">
                    {{ error }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Initial from "./layouts/Initial";
import Display from "./layouts/Display";
import PendingReview from "./layouts/PendingReview";
import UnderReview from "./layouts/UnderReview";
import {ApiFactory} from "./services/api/factory";
import AlertsContainer from "./components/AlertsContainer";

import CommunicatesMixin from "../../mixins/communcation/communicates-mixin";
import ConsumerApiService from "../Shared/services/consumer_api";


export default {
    components: {AlertsContainer, Initial, PendingReview, UnderReview, Display},
    mixins: [CommunicatesMixin],
    data: function() {
        return {
            api: null,
            consumerApi: ConsumerApiService.make(),
            userQueue: null,
            displayedQueue: null,
            alertActive: false,
            alertType: '',
            alertText: '',
            displaySpecificLead: false,
            specificLeadId: this.initialLeadId,
            error: '',
            minimumReviewTimeMillisecs: 10000,
            checkNextLeadIntervalMillisecs: 60000
        }
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        apiDriver: {
            type: String,
            default: 'dummy'
        },
        initialLeadId: {
            type: String,
            default: null
        },
    },
    created() {
        this.api = ApiFactory.makeApiService(this.apiDriver);

        this.displaySpecificLead = !!this.specificLeadId;

        this.getQueue().then(() => {
            this.displayedQueue = this.userQueue;
        });

        this.getTimeConfigurations();
    },
    methods: {
        clearSpecificLead() {
            this.specificLeadId = 0;
            this.displaySpecificLead = false;
            this.displayedQueue = this.userQueue;
        },
        setSpecificLead(leadId) {
            this.specificLeadId = leadId;
            this.displaySpecificLead = true;
        },
        getQueue(queue = null) {
            this.userQueue = queue;
            this.error = null;

            return this.api.getQueue().then(resp => {
                if(resp.data.data.status === true) {
                    this.userQueue = resp.data.data.queue;
                }
                else {
                    if(resp.data.data.no_processor) {
                        this.error = "Your user is not a processor";
                    }
                    else if(resp.data.data.no_team) {
                        this.error = "No team assigned";
                    }
                }
            }).catch(e => {
                this.error = 'Error retrieving queue';
            });
        },
        setDisplayedQueue(queue) {
            this.displayedQueue = queue;
        },
        getTimeConfigurations() {
            this.api.getTimeConfigurations().then(
                (resp) => {
                    if(resp.data.data.status === true) {
                        this.minimumReviewTimeMillisecs = resp.data.data.configs.minimum_review_time * 1000;
                        this.checkNextLeadIntervalMillisecs = resp.data.data.configs.check_next_lead_interval_seconds * 1000;
                    }
                }
            ).catch(
                (err) => {

                }
            );
        },

        // This method controls the Alert Container
        activateAlert(type, text) {
            this.alertActive = true;
            this.alertType = type;
            this.alertText = text;

            setTimeout(() => {
                this.alertActive = false;
                this.alertType = '';
                this.alertText = '';
            },5000)
        },
    }
};

</script>
