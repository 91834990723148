<template>
    <div class="mt-5">
        <p class="uppercase font-semibold text-xs mb-1"
           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
            Subject
        </p>
        <shortcode-input :dark-mode="darkMode" v-model="subject"></shortcode-input>
        <p class="uppercase font-semibold text-xs mb-1 mt-5"
           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
            Message Type
        </p>
        <Dropdown v-model="messageType" :options="messageTypes" :dark-mode="darkMode" :selected="messageType"
                  :key="messageType"></Dropdown>
        <p class="uppercase font-semibold text-xs mb-1 mt-5"
           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}" v-if="messageType === 'text'">
            Message Text
        </p>
        <shortcode-input :dark-mode="darkMode" v-model="messageText" v-if="messageType === 'text'"></shortcode-input>
        <p class="uppercase font-semibold text-xs mb-1 mt-5"
           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
            From
        </p>
        <!--todo: target relation multiselect-->
        <Dropdown v-model="targetRelation" :options="targetRelations" :dark-mode="darkMode" :selected="targetRelation"
                  :key="targetRelation"></Dropdown>
    </div>
</template>

<script>
import Dropdown from "../../../Shared/components/Dropdown";
import ShortcodeInput from "../../../Shared/components/ShortcodeInput";
import HasTargetRelations from "../../mixins/has-target-relations";

export default {
    name: "CrmEntry",
    components: {ShortcodeInput, Dropdown},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        action: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            messageText: '',
            messageType: '',
            subject: '',
            targetRelation: 'sales-manager',
            messageTypes: [
                {id: 'text', name: 'Text Value'},
                {id: 'result', name: 'Previous Result Entry'},
            ],
            targetType: 'staff'
        }
    },
    mixins: [HasTargetRelations],
    created() {
        if (this.action) {
            this.subject = this.action.payload.subject;
            this.messageText = this.action.payload.message;
            this.messageType = this.action.payload.message_type;
            this.targetRelation = this.action.payload.targets[0]?.target_relation;
        }
    },
    watch: {
        action: function () {
            if (this.action) {
                this.subject = this.action.payload.subject;
                this.messageText = this.action.payload.message;
                this.messageType = this.action.payload.message_type;
                this.targetRelation = this.action.payload.targets[0]?.target_relation;
            } else {
                this.subject = '';
                this.messageText = '';
                this.messageType = 'text';
                this.targetRelation = 'sales-manager';
            }
        }
    },
    methods: {
        getDisplayName() {
            return 'Notification';
        },
        getPayload() {
            return {
                subject: this.subject,
                message: this.messageType === 'text' ? this.messageText : '',
                message_type: this.messageType,
                targets: [{
                    target_type: this.targetType,
                    target_relation: this.targetRelation
                }]
            }
        }
    }
}
</script>

<style scoped>

</style>
