<template>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-10 pt-5 pb-10">
        <div>
            <RevenueOverview @send-to-tab="sendToTab" class="mb-4" :dark-mode="darkMode" :company-id="companyId"></RevenueOverview>
            <LeadsOverview :dark-mode="darkMode" :company-id="companyId"></LeadsOverview>
        </div>
        <CompanyContacts :has-edit-rights="hasEditRights" :dark-mode="darkMode" :show-add-contact-button="true" :company-id="companyId"></CompanyContacts>
        <Activity :dark-mode="darkMode" :company-id="companyId"></Activity>
        <div class="lg:col-span-3">
            <CampaignsOverview :table-height="'h-88'" :dark-mode="darkMode" :company-id="companyId" :legacy-company-id="legacyCompanyId"></CampaignsOverview>
        </div>
    </div>
</template>

<script>
import RevenueOverview from "../../Shared/modules/RevenueOverview";
import LeadsOverview from "../../Shared/modules/LeadsOverview";
import CampaignsOverview from "../../Shared/modules/CampaignsOverview";
import Activity from "../../Shared/modules/Activity";
import CompanyContacts from "../../Shared/modules/Contacts";

export default {
    name: "Overview",
    components: {CompanyContacts, Activity, CampaignsOverview, LeadsOverview, RevenueOverview},
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        hasEditRights: {
            type: Boolean,
            default: false,
        },
        companyId: {
            type: Number,
            default: null
        },
        legacyCompanyId: {
            type: Number,
            default: null,
        }

    },
    methods: {
        // TODO: This function does update the variable for the Tab selected, but it does not visually change the Tab. May need a global event here. Will come back to this functionality later.
        sendToTab(value) {
            this.$emit('send-to-tab', value)
        }
    }
}
</script>

<style scoped>

</style>
