<template>
    <div class="border rounded-lg h-full"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="px-5 pt-5 pb-4">
            <div class="flex items-center justify-between">
                <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">
                    Addresses</h5>
                <button v-if="hasEditRights"
                        class="ml-2 transition duration-200 inline-flex items-center font-semibold bg-grey-475 hover:bg-grey-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5"
                        @click="openModal(null)">
                    Add Address
                </button>
            </div>
        </div>
        <div class="border-t border-b overflow-y-auto h-64"
             :class="[darkMode ? 'bg-dark-background border-dark-border' : 'bg-light-background  border-light-border']">
            <div class="h-full flex items-center justify-center" v-if="loading">
                <loading-spinner></loading-spinner>
            </div>
            <div v-else>
                <div class="grid grid-cols-1 gap-x-3 border-b px-5" v-for="companyLocation in companyLocations"
                     :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <div class="py-4">
                        <div class="flex items-center justify-between ">
                            <div class="flex items-center">
                                <div>
                                    <p class="text-sm truncate pb-0 font-medium">
                                        {{ companyLocation.name }}
                                    </p>
                                    <p class="text-grey-400 text-sm">{{ companyLocation.address.full_address }}</p>
                                </div>
                            </div>
                                <div v-if="locationHasPhone(companyLocation)" class="flex items-center">
                                    <svg @click="call(companyLocation)" class="w-4 mr-5 cursor-pointer" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.7061 12.5332L12.0551 9.21369C11.8825 9.05684 11.6558 8.97318 11.4227 8.98038C11.1895 8.98759 10.9684 9.0851 10.8058 9.25231L8.65655 11.4626C8.13922 11.3638 7.09917 11.0396 6.02859 9.97172C4.958 8.90024 4.63377 7.85751 4.53767 7.34378L6.7462 5.19364C6.91363 5.03119 7.01128 4.80998 7.01848 4.57681C7.02569 4.34364 6.94189 4.11681 6.78482 3.94433L3.46619 0.294312C3.30905 0.121292 3.09065 0.0163428 2.85738 0.00175297C2.62411 -0.0128368 2.39434 0.0640823 2.21687 0.216174L0.2679 1.8876C0.112621 2.04344 0.0199401 2.25085 0.00743837 2.47049C-0.00603376 2.69503 -0.262902 8.01378 3.86137 12.1398C7.45933 15.7368 11.9662 16 13.2074 16C13.3889 16 13.5002 15.9946 13.5299 15.9928C13.7495 15.9805 13.9568 15.8874 14.1119 15.7315L15.7824 13.7816C15.9351 13.6047 16.0126 13.3751 15.9983 13.1419C15.9841 12.9086 15.8792 12.6902 15.7061 12.5332V12.5332Z" fill="#0081FF"/>
                                    </svg>
                                </div>

                            <ActionsHandle :dark-mode="darkMode" @edit="openModal(companyLocation)" @delete="confirmDeleteLocation(companyLocation)" v-if="hasEditRights"></ActionsHandle>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-3"></div>
        <Modal
            v-if="confirmDeleteModal"
            :small="true"
            @confirm="deleteLocation"
            @close="cancelDeleteLocation"
            :dark-mode="darkMode"
            close-text="Cancel"
            confirm-text="Delete"
        >
            <template v-slot:header>
                <p class="font-medium">
                    Delete Address
                </p>
            </template>
            <template v-slot:content>
                Are you sure you wish to delete Company location "{{ locationForDeletion.name }}"?
            </template>
        </Modal>
        <Modal
            v-if="locationModal"
            :small="true"
            @confirm="saveLocation"
            @close="closeModal"
            :dark-mode="darkMode"
            :close-text="'Cancel'"
            :confirm-text="saving ? 'Saving...' : addingNewLocation ? 'Create' : 'Update'"
            :disable-confirm="disableConfirm"
        >
            <template v-slot:header>
                <p class="font-medium">
                    {{ addingNewLocation ? 'Add' : 'Edit'}} Address
                </p>
            </template>
            <template v-slot:content>
                <div class="grid grid-cols-1 gap-3">
                    <div>
                        <label class="block mb-1">
                            Office Name
                        </label>
                        <input
                            type="text"
                            v-model="location.name"
                            class="rounded border font-medium text-sm h-9 w-full"
                            :class="[darkMode ? 'bg-dark-background border-dark-border text-slate-200' : 'bg-light-background border-light-border text-slate-900']"
                        />
                    </div>
                    <div>
                        <label class="block mb-1">
                            Address Line1
                        </label>
                        <input
                            type="text"
                            v-model="location.address.address_1"
                            class="rounded border font-medium text-sm h-9 w-full"
                            :class="[darkMode ? 'bg-dark-background border-dark-border text-slate-200' : 'bg-light-background border-light-border text-slate-900']"
                        />
                    </div>
                    <div>
                        <label class="block mb-1">
                            Address Line2
                        </label>
                        <input
                            type="text"
                            v-model="location.address.address_2"
                            class="rounded border font-medium text-sm h-9 w-full"
                            :class="[darkMode ? 'bg-dark-background border-dark-border text-slate-200' : 'bg-light-background border-light-border text-slate-900']"
                        />
                    </div>
                    <div>
                        <label class="block mb-1">
                            State
                        </label>
                        <dropdown :dark-mode="darkMode" :placeholder="'Select State'" :options="states" v-model="location.address.state" :key="states.length"></dropdown>
                    </div>
                    <div>
                        <label class="block mb-1">
                            City
                        </label>
                        <input
                            type="text"
                            v-model="location.address.city"
                            class="rounded border font-medium text-sm h-9 w-full"
                            :class="[darkMode ? 'bg-dark-background border-dark-border text-slate-200' : 'bg-light-background border-light-border text-slate-900']"
                        />
                    </div>
                    <div>
                        <label class="block mb-1">
                            Zip Code
                        </label>
                        <input
                            type="text"
                            v-model="location.address.zip_code"
                            class="rounded border font-medium text-sm h-9 w-full"
                            :class="[darkMode ? 'bg-dark-background border-dark-border text-slate-200' : 'bg-light-background border-light-border text-slate-900']"
                        />
                    </div>
                    <div>
                        <label class="block mb-1">
                            Phone
                        </label>
                        <input
                            type="text"
                            v-model="location.phone"
                            class="rounded border font-medium text-sm h-9 w-full"
                            :class="[darkMode ? 'bg-dark-background border-dark-border text-slate-200' : 'bg-light-background border-light-border text-slate-900']"
                        />
                    </div>
                </div>
            </template>
        </Modal>
        <alerts-container :dark-mode="darkMode" :alert-type="alertType" :text="alertText" v-if="alertActive"></alerts-container>
    </div>
</template>

<script>
import ActionsHandle from "../../Shared/components/ActionsHandle";
import Modal from "../../Shared/components/Modal";
import SharedApiService from "../services/api";
import Dropdown from "../components/Dropdown";
import AlertsContainer from "../components/AlertsContainer";
import DispatchesGlobalEventsMixin from "../../../mixins/dispatches-global-events-mixin";
import LoadingSpinner from "../components/LoadingSpinner";
import AlertsMixin from "../../../mixins/alerts-mixin";

export default {
    name: "Addresses",
    components: {Modal, ActionsHandle, Dropdown, AlertsContainer, LoadingSpinner},
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        hasEditRights: {
            type: Boolean,
            default: false
        },
        companyId: {
            type: Number,
            default: null
        }
    },
    mixins: [DispatchesGlobalEventsMixin, AlertsMixin],
    data() {
        return {
            api: SharedApiService.make(),
            locationModal: false,
            location: {
                id: undefined,
                name: undefined,
                phone: undefined,
                address: {
                    address_1: undefined,
                    address_2: undefined,
                    state: undefined,
                    city: undefined,
                    zip_code: undefined,
                },
            },
            companyLocations: [],
            addingNewLocation: false,
            states: [],
            saving: false,
            errorMessages: {
                legacyError: `Operation completed, but sync to Legacy Admin failed.`,
                updateError: `There was an error saving the address.`,
                deleteError: `There was an error deleting the address.`
            },
            loading: false,
            confirmDeleteModal: false,
            locationForDeletion: {
                id: null,
                name: '',
            },
        }
    },
    created() {
        this.getStates();

        if (this.companyId) this.getCompanyLocations();
    },
    computed: {
        disableConfirm: function () {
            return this.saving || !this.location.address.address_1 || !this.location.address.state || !this.location.address.city || !this.location.address.zip_code;
        }
    },
    methods: {
        async getCompanyLocations() {
            this.loading = true;
            this.api.getCompanyLocations(this.companyId)
                .then(resp => this.companyLocations = resp.data.data.addresses)
                .finally(() => this.loading = false);
        },
        openModal(location) {
            if (location) {
                this.location.id = location.id;
                this.location.name = location.name;
                this.location.phone = location.phone;
                this.location.address.address_1 = location.address.address_1;
                this.location.address.address_2 = location.address.address_2;
                this.location.address.state = this.states.find(state => state.stateAbbr === location.address.state)?.id;
                this.location.address.city = location.address.city;
                this.location.address.zip_code = location.address.zip_code;
            }
            else {
                this.location.id = null;
                this.addingNewLocation = true;
            }
            this.locationModal = true;
        },
        closeModal() {
            this.location = {
                id: undefined,
                name: undefined,
                phone: undefined,
                address: {
                    address_1: undefined,
                    address_2: undefined,
                    state: undefined,
                    city: undefined,
                    zip_code: undefined,
                },
            };
            this.locationModal = false;
        },
        saveLocation() {
            this.saving = true;
            const payload = { ...this.location };
            if (payload.id) {
                this.api.updateCompanyLocation(this.companyId, this.location.id, payload)
                    .then((response) => {
                        if (!response.data.data.status) { this.showAlert('error', this.errorMessages.updateError); }
                        else if (!response.data.data.legacy_status) { this.showAlert('error', this.errorMessages.legacyError); }
                        this.updateLocation(payload);
                        this.closeModal();
                    })
                    .catch(e => this.showAlert('error', e.response.data.message))
                    .finally(() => {
                        this.getCompanyLocations();
                        this.saving = false;
                    });
            }
            else {
                this.api.createCompanyLocation(this.companyId, payload)
                    .then(() => {
                        this.closeModal();
                        this.getCompanyLocations();
                    })
                    .catch(e => this.showAlert('error', e.response.data.message))
                    .finally(() => {
                        this.getCompanyLocations();
                        this.saving = false;
                        this.addingNewLocation = false;
                    });
            }
        },
        getStates() {
            this.api.getStates().then(resp => this.states = resp.data.data.map(state => ({ ...state, id: state.stateAbbr })));
        },
        updateLocation(location) {
            const companyLocation = this.companyLocations.find(companyLocation => companyLocation.id === location.id);

            companyLocation.id = location.id;
            companyLocation.name = location.name;
            companyLocation.phone = location.phone;
            companyLocation.address_1 = location.address.address_1;
            companyLocation.address_2 = location.address.address_2;
            companyLocation.state = location.address.state
            companyLocation.city = location.address.city;
            companyLocation.zip_code = location.address.zip_code;
        },
        confirmDeleteLocation(location) {
            this.confirmDeleteModal = true;
            this.locationForDeletion = { id: location.id, name: location.name };
        },
        async deleteLocation() {
            this.saving = true;
            this.api.deleteCompanyLocation(this.companyId, this.locationForDeletion.id).then(response => {
                if (!response.data.data.status) this.showAlert('error', this.errorMessages.deleteError)
                else if (!response.data.data.legacy_status) this.showAlert('error', this.errorMessages.legacyError);
            }).catch(err => {
                this.showAlert('error', err.response.data.message);
            }).finally(() => {
                this.getCompanyLocations();
                this.saving = false;
                this.cancelDeleteLocation();
            });
        },
        cancelDeleteLocation() {
            this.locationForDeletion = { id: null, name: '' };
            this.confirmDeleteModal = false;
        },
        locationHasPhone(companyLocation) {
            return (companyLocation.phone && companyLocation.phone.trim().length > 0);
        },
        call(companyLocation) {
            this.dispatchGlobalEvent('call', {phone: companyLocation.phone, name: (companyLocation.name) ? companyLocation.name : companyLocation.address.city + ', ' + companyLocation.address.state , id: companyLocation.id});
        },
    }
}
</script>

<style scoped>

</style>
