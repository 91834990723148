<template>
    <div class="border-l border-dashed ml-8" :class="{'text-grey-800 border-grey-300': !darkMode, 'text-grey-200 border-blue-400': darkMode}">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "ActionSubBranch",
    props: {
        darkMode: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>

</style>
