import {BaseApiService} from "./base";

class DummyApiService extends BaseApiService {
    constructor(delay = 150) {
        super("DummyApiService");

        this.delay = delay;
    }

    _makeResponse(data) {
        return new Promise((resolve, reject) => {
            setTimeout(() => resolve({data: {data}}), this.delay);
        });
    }

    getAccountManagers() {
        return this._makeResponse({result: true});
    }

    deleteAccountManager(id) {
        return this._makeResponse({result: true});
    }

    createAccountManager() {
        return this._makeResponse({result: true});
    }

    updateAccountManager(id) {
        return this._makeResponse({result: true});
    }

    getAccountManagerTypes() {
        return this._makeResponse({result: true});
    }

    getSuccessManagers() {
        return this._makeResponse({result: true});
    }

    createSuccessManager(payload) {
        return this._makeResponse({result: true});
    }

    updateSuccessManager(id, payload) {
        return this._makeResponse({result: true});
    }

    deleteSuccessManager(id) {
        return this._makeResponse({result: true});
    }

    getSuccessManagerTypes() {
        return this._makeResponse({result: true});
    }

    searchUserNames() {
        return this._makeResponse({result: true});
    }

    getSupportOfficers() {
        return this._makeResponse({result: true});
    }

    createSupportOfficer(payload) {
        return this._makeResponse({result: true});
    }

    updateSupportOfficer(id, payload) {
        return this._makeResponse({result: true});
    }

    deleteSupportOfficer(id) {
        return this._makeResponse({result: true});
    }

    getHunters() {
        return this._makeResponse({result: true});
    }

    createHunter(payload) {
        return this._makeResponse({result: true});
    }

    updateHunter(id, payload) {
        return this._makeResponse({result: true});
    }

    deleteHunter(id) {
        return this._makeResponse({result: true});
    }
}

export { DummyApiService };

