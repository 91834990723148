export class SimpleRelativeTime {

    /**
     * @param {object?} timeStringTemplates - overwrite or provide new templates for output
     * @param {object?} timeRanges - overwrite or provide new cutoff times for different outputs
     * @param {boolean?} roundDown - override the default behaviour of rounding conversions up
     */
    constructor(timeStringTemplates, timeRanges, roundDown) {
        this.timeStringTemplates = timeStringTemplates;
        this.timeRangeConfig = timeRanges;
        this._roundDown = roundDown === true;
    }

    // Keys for string conversion - %p handled by _pluralise
    // time replacers must exist in _convertSeconds
    _timeStringTemplates = {
        s   : 'a few seconds ago',
        m   : 'a minute ago',
        mm  : '%m minute%p ago',
        h   : 'an hour ago',
        hh  : '%h hour%p ago',
        d   : 'a day ago',
        dd  : '%d day%p ago',
        M   : 'a month ago',
        MM  : '%M month%p ago',
        y   : 'a year ago',
        yy  : '%y year%p ago',
    }
    _fallbackTemplate = `%s seconds ago`;
    _maxTimeKey = 'yy';

    // Simple conversions for seconds: multiply to convert TO seconds, divide to convert FROM seconds
    _convertSeconds = {
        s: 1,
        m: 60,
        h: 60 * 60,
        d: 24 * 60 * 60,
        M: 30 * 24 * 60 * 60,
        y: 365 * 24 * 60 * 60,
    }

    // "Less than" ranges in seconds, will use the template in
    // _timeStringTemplates with a matching key
    _timeRangeConfig = {
        s   : 44,                               // 44 seconds or less
        m   : 90,                               // 90 seconds or less
        mm  : this._convertSeconds.m * 44,      // 44 minutes or less
        h   : this._convertSeconds.m * 90,      // 90 minutes or less
        hh  : this._convertSeconds.h * 21,      // 21 hours or less
        d   : this._convertSeconds.h * 35,      // 35 hours or less
        dd  : this._convertSeconds.d * 25,      // 25 days or less
        M   : this._convertSeconds.d * 45,      // 45 days or less
        MM  : this._convertSeconds.M * 10,      // 10 months or less
        y   : this._convertSeconds.M * 17,      // 17 months or less
    }

    // Guarantee the key order by assigning to an array so 'less than' chain can be used confidently
    _generateTimeRangeKeyOrder() {
        this._timeRangeKeyOrder = Object.entries(this._timeRangeConfig)
            .sort(([_keyA, valueA], [_keyB, valueB]) => {
                return valueA < valueB
                    ? -1
                    : 1;
            })
            .map(([key, _value]) => key);
    }
    _timeRangeKeyOrder = [];

    _roundDown = false;

    get timeStringTemplates() { return this._timeStringTemplates; }
    set timeStringTemplates(newKey) {
        if (newKey && typeof(newKey) === 'object') {
            for (const prop in newKey) {
                this._timeStringTemplates[prop] = newKey[prop];
            }
        }
    }

    get timeRangeConfig() { return this._timeRangeConfig; }
    set timeRangeConfig(newRanges) {
        if (newRanges && typeof(newRanges) === 'object') {
            for (const prop in newRanges) {
                this._timeRangeConfig[prop] = newRanges[prop];
            }
        }
        this._generateTimeRangeKeyOrder();
    }

    // Default Laravel Carbon strings are implicitly interpreted as local time, not UTC
    // Add explicit Z timezone marker if required to fix this
    _explicitTimezone(datetimeString) {
        return /(T|\s)\d+:\d+:\d+(\.\d+)?$/.test(datetimeString)
            ? `${datetimeString}Z`
            : datetimeString;
    }

    // Default behaviour is to round up
    _roundTime(time) { return this._roundDown ? Math.floor(time) : Math.ceil(time); }

    // Add 's' if required and template has a %p plural marker
    _pluralise(stringTemplate, timeValue) {
        const rxPlural = /%p/;
        return timeValue === 1
            ? stringTemplate.replace(rxPlural, '')
            : stringTemplate.replace(rxPlural, 's');
    }

    // Pick the required template from the time difference in seconds, according to Range config, fill as required
    _convertSecondsToTemplate(seconds, rangeKey) {
        const template = this._timeStringTemplates[rangeKey] ?? this._fallbackTemplate;
        const rxTimePlaceholders = new RegExp(`%([${Object.keys(this._convertSeconds).join('')}])`);
        const replacerKey = template.match(rxTimePlaceholders) ?? null;
        if (!replacerKey) return template;
        else {
            const convertedTime = this._roundTime(seconds / (this._convertSeconds[replacerKey[1]]));
            const filledTemplate = template.replace(replacerKey[0], `${convertedTime}`);
            return this._pluralise(filledTemplate, convertedTime);
        }
    }

    /**
     * Provide a datetimeString to find the relative time from now
     *
     * @param {number|string} datetimeStringOrUnixEpoch - unix epoch millisecond timestamp, or valid date string
     * @param {boolean} UTC - explicitly set a date string to UTC if no timezone provided
     * @returns {string} - simple relative time string from template
     */
    getRelativeTimeString(datetimeStringOrUnixEpoch, UTC = true) {
        const epochUtc = typeof(datetimeStringOrUnixEpoch) === 'string'
            ? UTC
                ? Date.parse(this._explicitTimezone(datetimeStringOrUnixEpoch))
                : Date.parse(datetimeStringOrUnixEpoch)
            : datetimeStringOrUnixEpoch;
        const timeDifferenceInSeconds = epochUtc
            ? (Date.now() - epochUtc)/1000
            : null;
        if (isNaN(timeDifferenceInSeconds)) return 'an unknown time ago';

        let rangeKey = this._maxTimeKey;
        for (const key in this._timeRangeKeyOrder) {
            if (timeDifferenceInSeconds < this._timeRangeConfig[this._timeRangeKeyOrder[key]]) {
                rangeKey = this._timeRangeKeyOrder[key];
                break;
            }
        }
        return this._convertSecondsToTemplate(timeDifferenceInSeconds, rangeKey);
    }

}
