<template>
    <div class="border rounded-lg"  :class="[darkMode ? 'bg-dark-module border-dark-border' : 'bg-light-module border-light-border']">
        <div class="p-5">
            <div class="flex items-center justify-between">
                <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Campaigns Overview</h5>
            </div>
        </div>
        <budget-usage-table :company-id="companyId" :legacy-company-id="legacyCompanyId" :dark-mode="darkMode" v-if="companyId"></budget-usage-table>
        <div class="p-3"></div>
    </div>
</template>

<script>
import BudgetUsageTable from "./BudgetUsageTable";

export default {
    name: "CampaignsOverview",
    components: {BudgetUsageTable},
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        tableHeight: {
            type: String,
            default: 'h-100'
        },
        companyId: {
            type: Number,
            default: null
        },
        legacyCompanyId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {}
    }
}
</script>

<style scoped>

</style>
