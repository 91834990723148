import {PusherServiceFactory} from "../../LeadProcessing/services/pusher/pusher-service";
import {DateTime} from "luxon";
import DispatchesGlobalEventsMixin from "../../../mixins/dispatches-global-events-mixin";

export default {
    data: function() {
        return {
            pusher: null
        }
    },
    props: {
        pusherAppKey: {
            type: String,
            default: null
        },
        userId: {
            type: String,
            default: null
        }
    },
    mixins: [DispatchesGlobalEventsMixin],
    created() {
        this.pusher = PusherServiceFactory.make(this.pusherAppKey, this.userId).pusher;

        if(this.pusher !== null && this.userId !== null)
            this.initializePusherEvents();
    },
    methods: {
        initializePusherEvents() {
            this.pusher.subscribe(`private-notifications-${this.userId}`);

            this.pusher.bind('new-notification', (data) => this.newNotification(data));
            this.pusher.bind('new-message', (data) => this.newMessage(data));
        },
        newNotification(data) {
            if(this.$refs.notificationWrapper)
                this.$refs.notificationWrapper.addNotification(data);
        },
        newMessage(data) {
            this.dispatchGlobalEvent("new-message", data.direction, data.first_name, data.last_name, data.message_body, data.timestamp);
        },
        addMessage(direction, firstName, lastName, messageBody, timestamp) {}
    },
}
