<template>
    <div class="py-2">
        <p class="italic text-slate-500" :class="[darkMode ? 'text-slate-400' : 'text-slate-600']">{{ totalComments || 0 }} comment{{ commentPlural }}</p>
    </div>
    <div v-if="this.loadingComments || this.savingComment" class="flex items-center justify-center h-20">
        <loading-spinner />
    </div>
    <div v-if="expanded && !this.loadingComments" class="mt-4">
        <div class="rounded-lg p-2" :class="[darkMode ? 'bg-slate-800' : 'bg-primary-50']" v-if="totalComments > 0">
            <div v-for="(comment, index) in comments" class="py-2 px-4">
                <div class="flex items-center">
                    <p>
                        <span class="inline-block font-bold text-sm" :class="[darkMode ? 'text-primary-300' : 'text-primary-800']">{{ comment.user.name || 'Unknown User'}}</span>
                        <span :title="$filters.absoluteDatetimeFromTimestamp(comment.created_at)" class="text-gray-500 text-xs ml-2 cursor-default">({{ $filters.simpleRelativeTimeFromTimestamp(comment.created_at) }})</span>
                    </p>
                </div>
                <comment-content :content="comment.comment" />
                <p class="inline-flex items-center text-primary-500 cursor-pointer py-1 text-sm"
                        @click="writeComment(comment.id)">
                    Reply
                </p>
                <comment-node :dark-mode="darkMode" v-if="comment.children.length" :replies="comment.children" />
                <comment-editor v-if="showCommentEditor === comment.id"
                    :dark-mode="darkMode"
                    :parent-id="comment.id"
                    @saveComment="submitComment"
                    @cancelComment="cancelComment"
                />
                <div class="spacer pb-6 mb-2 w-[97%] mx-auto border-b-[1px] border-gray-500 opacity-50"
                    :class="{ 'border-b-0': index+1 === comments.length }"
                />
            </div>
        </div>
        <button class="text-primary-500 my-4 text-sm font-semibold border border-primary-500 rounded-md py-2 px-5"
            @click="writeComment(0)">
                New comment
        </button>
        <comment-editor v-if="showCommentEditor === 0"
            :dark-mode="darkMode"
            :parent-id="null"
            @saveComment="submitComment"
            @cancelComment="cancelComment"
        />
        <alerts-container :dark-mode="darkMode" v-if="alertActive" :alert-type="alertType" :text="alertText" />
    </div>
</template>

<script>
import CommentNode from "./CommentNode";
import CommentContent from "./CommentContent";
import LoadingSpinner from "../../../../Shared/components/LoadingSpinner";
import Api from "../../../services/api";
import CommentEditor from "./CommentEditor";
import AlertsContainer from "../../../../Shared/components/AlertsContainer";
import AlertsMixin from "../../../../../mixins/alerts-mixin";

export default {
    name: "ActivityComments",
    components: {AlertsContainer, CommentEditor, LoadingSpinner, CommentContent, CommentNode },
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        commentCount: {
            type: Number,
            default: 0,
        },
        activityId: {
            type: Number,
            default: null,
        },
        companyId: {
            type: Number,
            default: null,
        },
        expanded: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [ AlertsMixin ],
    data() {
        return {
            api: Api.make(),
            comments: null,
            showCommentEditor: false,
            loadingComments: false,
            savingComment: false,
            commentContent: 0,
            totalComments: 0,
        }
    },
    computed: {
        commentPlural() { return this.totalComments === 1 ? '' : 's'; }
    },
    mounted() {
        this.totalComments = this.commentCount;
    },
    methods: {
        writeComment(parentId) {
            if (window.tinymce?.editors.length) this.closeTinyMce();
            this.showCommentEditor = parentId;
        },
        async submitComment(id, content) {
            if (this.savingComment) return;
            this.savingComment = true;
            const payload = {
                parent_id: id || null,
                comment: content,
            };
            this.api.saveActivityConversation(this.companyId, this.activityId, payload).then(response => {
                if (response.data.data.status) {
                    this.closeTinyMce();
                    this.getComments();
                }
                else this.showAlert('error', response.data.message ?? 'Unknown error saving comment.' );
            }).catch(err => {
                this.showAlert('error', err.response.data.message || 'Unknown error saving comment.' );
            }).finally(() => {
                this.savingComment = false;
            });

        },
        cancelComment() {
            this.closeTinyMce();
            this.commentContent = '';
        },
        closeTinyMce() {
            if (window.tinymce?.editors.length) {
                window.tinymce.editors.forEach(editor => window.tinymce.remove(editor));
            }
            this.showCommentEditor = null;
        },
        async getComments() {
            this.loadingComments = true;
            this.api.getActivityConversations(this.companyId, this.activityId).then(response => {
                if (response.data.data.status) {
                    this.comments = response.data.data.conversations;
                    this.updateCommentCount();
                }
                else this.showAlert('error', 'Error fetching comments');
            }).catch(err => {
                this.showAlert('error', err.response?.data?.message || 'Error fetching comments');
            }).finally(() => {
                this.loadingComments = false;
            });
        },
        updateCommentCount() {
            const countChildren = (children) => {
                return children.reduce((output, child) => {
                    return child.children?.length
                        ? output + 1 + countChildren(child.children)
                        : output + 1;
                }, 0);
            }
            this.totalComments = countChildren(this.comments);
        }
    },
    watch: {
        expanded(newVal) {
            if (newVal && !this.comments) this.getComments();
        }
    }
}
</script>

<style scoped>

</style>
