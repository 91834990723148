<template>
    <div>
        <div class="flex items-end py-2">
            <div>
                <div class="flex items-center">
                    <div class="w-4 mt-1 border-b border-dashed"  :class="{'border-grey-300': !darkMode, 'border-blue-400': darkMode}"></div>
                    <p class="px-2 py-1 cursor-pointer hover:bg-cyan-500 hover:bg-opacity-25 rounded"  :class="{'bg-transparent': !selected, 'bg-opacity-25 bg-cyan-500': selected}">
                        <span class="text-xs font-bold" v-if="nodeType">{{ nodeType.replace(/(^\w{1})|(\s+\w{1})/gm, letter => letter.toUpperCase()) }}:</span>
                        {{ type }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ActionType",
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: ''
        },
        selected: {
            type: Boolean,
            default: false
        },
        nodeType: {
            type: String,
            default: null
        }
    }
}
</script>

<style scoped>

</style>
