<template>
    <div>
        <div class="grid grid-cols-1 gap-4">
            <div class="w-full flex flex-auto rounded-md border overflow-hidden" style="height: 388px;"
                 :class="{'bg-white border-grey-120': !darkMode, 'bg-dark-0 border-dark-175': darkMode}">
                <div ref="map" class="w-full h-full"></div>
            </div>
        </div>
    </div>
</template>

<script>
import {waitForCallbackToReturnTrue} from "../../../../../utilities/loading-utilities";
import {ApiFactory} from "../../services/api/factory";

export default {
    name: "ModularInteractiveMap",
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        leadId: {
            type: Number,
            default: null
        }
    },
    mounted() {
        waitForCallbackToReturnTrue(() => window.google !== null && window.google.maps !== null).then(() => this.initMap());
    },
    beforeDestroy() {
    },
    computed: {
        mapLink: function() {
            return 'https://google.com/maps/?q=' +
                this.address.latitude + ',' +
                this.address.longitude + '&ll=' +
                this.address.latitude + ',' +
                this.address.longitude + '&spn=0.004250,0.011579&t=h&z=19&hl=en';
        }
    },
    data: function() {
        return {
            map: null,
            address: null,
            loading: false,
            api: ApiFactory.makeApiService("api"),
        }
    },
    watch: {
        leadId(newVal, oldVal) {
            if(newVal !== oldVal && this.map !== null) {
                this.getLead().then(() => this.updateMap());
            }
        }
    },
    methods: {
        getLead() {
            return new Promise((resolve) => {
                this.api.getLeadBasicInfo(this.leadId).then(resp => {
                    this.address = resp.data.data.lead.address;
                }).catch(e => console.error(e)).finally(() => {
                    this.loading = false;
                    resolve();
                });
            })
        },
        initMap() {
            if(this.leadId !== null) {
                this.getLead().then(resp => {
                    if(this.map !== null)
                        return this.updateMap();

                    const latLng = {lat: Number(this.address.latitude), lng: Number(this.address.longitude)};
                    try {
                        this.map = new window.google.maps.Map(this.$refs.map, {
                            zoom: 18,
                            scale: 2,
                            mapTypeId: 'satellite',
                            center: latLng
                        });

                        this.map.setTilt(0);

                        this.marker = new google.maps.Marker({
                            position: latLng,
                            map: this.map,
                            title: 'Interactive Map'
                        });
                    } catch(e) {console.error(e);}
                });
            }
        },
        updateMap() {
            if(this.marker !== null)
                this.marker.setMap(null);

            const latLng = {lat: this.address.latitude, lng: this.address.longitude};

            this.map.setPosition();
            this.map.setZoom(18);
            this.map.setScale(2);

            this.marker = new google.maps.Marker({
                position: latLng,
                map: this.map,
                title: 'Interactive Map'
            });
        }
    }
}
</script>

<style scoped>

</style>
