<template>
    <div class="p-5">
        <div class="flex items-center justify-between mb-3">
            <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Industry - Type List</h5>
            <button
                class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5" @click="openModal">
                Add New Type
            </button>
        </div>
        <div class="grid grid-cols-11 gap-x-3 mb-2 px-5" v-if="!loading">
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Global Type</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Industry</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Name</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Key</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Created At</p>
        </div>
        <div class="border-t border-b" v-if="!loading"
             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-40 border-dark-border': darkMode}">
            <div>
                <div class="grid grid-cols-11 gap-x-3 border-b px-5 py-3 items-center"
                     v-for="industryType in industryTypes" :key="industryType.id"
                     :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <p class="text-sm col-span-2 truncate">
                        {{ industryType.global_type.name }}
                    </p>
                    <p class="text-sm col-span-2 truncate">
                        {{ industryType.industry }}
                    </p>
                    <p class="text-sm col-span-2 truncate">
                        {{ industryType.name }}
                    </p>
                    <p class="text-sm col-span-2 truncate">
                        {{ industryType.key }}
                    </p>
                    <p class="text-sm col-span-2">
                        {{ formatDate(industryType.created_at) }}
                    </p>
                    <p class="text-primary-500 cursor-pointer text-sm" @click="viewReviews(industryType)">
                        Reviews
                    </p>
                    <ActionsHandle :dark-mode="darkMode" @edit="editIndustryType(industryType)" @delete="deleteIndustryType(industryType)"/>
                </div>
            </div>
        </div>
        <div class="h-32 flex items-center justify-center" v-if="loading">
            <loading-spinner></loading-spinner>
        </div>
        <modal :small="true" v-if="showModal" @close="closeModal" :dark-mode="darkMode" @confirm="saveIndustryType" :close-text="'Cancel'" :confirm-text="confirmText">
            <template v-slot:header>
                <h4>{{ industryType.id ? 'Update' : 'Create' }} Type</h4>
            </template>
            <template v-slot:content>
                <div class="grid gap-3">
                    <div v-if="!industryType.id" class="flex items-center">
                        <p class="uppercase font-semibold text-xs w-32"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Global Type
                        </p>
                        <Dropdown :dark-mode="darkMode" v-model="industryType.global_type.id" :options="globalTypes" placeholder="Type" :selected="industryType.global_type.id"></Dropdown>
                    </div>
                    <div class="flex items-center">
                        <p class="uppercase font-semibold text-xs w-32"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Name
                        </p>
                        <input class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                               placeholder="Name"
                               v-model="industryType.name"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                    </div>
                    <div class="flex items-center">
                        <p class="uppercase font-semibold text-xs w-32"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Key
                        </p>
                        <input class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                               placeholder="Key"
                               v-model="industryType.key"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                    </div>
                </div>
            </template>
        </modal>
        <alerts-container :dark-mode="darkMode" :alert-type="'error'" :text="error" v-if="error"></alerts-container>
    </div>

</template>

<script>
import Dropdown from "../../../Shared/components/Dropdown";
import Toggle from "../../../Inputs/Toggle/Toggle";
import Modal from "../../../Shared/components/Modal";
import ApiService from "../services/api";
import SharedApiService from "../../../Shared/services/api";
import ActionsHandle from "../../../Shared/components/ActionsHandle";
import LoadingSpinner from "../../../Shared/components/LoadingSpinner";
import AlertsContainer from "../../../Shared/components/AlertsContainer";
import {DateTime} from "luxon";

export default {
    name: "IndustryTypes",
    components: {Dropdown, Toggle, Modal, ActionsHandle, LoadingSpinner, AlertsContainer},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        industry: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            api: ApiService.make(),
            sharedApi: SharedApiService.make(),
            showModal: false,
            industryTypes: [],
            industryType: {global_type: {id: null, name: null}, name: null, key: null},
            globalTypes: [],
            saving: false,
            loading: false,
            error: null
        }
    },
    created() {
        this.getIndustryTypes();
        this.getGlobalTypes();
    },
    computed: {
        confirmText: function () {
            if (this.saving) return 'Saving...';
            if (this.industryType.id) return 'Update';

            return 'Create';
        }
    },
    methods: {
        openModal() {
            this.showModal = true;
        },
        closeModal () {
            this.showModal = false;
            this.industryType = {global_type: {id: null, name: null}, name: null, key: null};
        },
        getIndustryTypes() {
            if(!this.industry) return;
            if(!this.loading) this.loading = true;
            this.error = null;

            this.api.getIndustryTypes(this.industry).then(resp => this.industryTypes = resp.data.data.types)
                .catch(e => this.error = e.response.data.message).finally(() => this.loading = false);
        },
        saveIndustryType() {
            if(this.saving) return;
            this.saving = true;
            this.error = null;

            if (this.industryType.id) {
                this.updateIndustryType();
                return;
            }
            this.createIndustryType();
        },
        getParamsToMakeRequest() {
            return {
                global_type_id  : this.industryType.global_type?.id,
                name            : this.industryType.name,
                key             : this.industryType.key
            }
        },
        createIndustryType() {
            this.api.createIndustryType(this.industry, this.getParamsToMakeRequest()).then(() => this.refreshList(true))
                .catch(e => this.error = e.response.data.message).finally(() => this.saving = false);
        },
        editIndustryType(industryType) {
            this.industryType = {...industryType};
            this.industryType.global_type.id = this.industryType.global_type?.num_id;
            this.openModal();
        },
        updateIndustryType() {
            this.api.updateIndustryType(this.industryType.id, this.industry, this.getParamsToMakeRequest()).then(() => this.refreshList(true))
                .catch(e => this.error = e.response.data.message).finally(() => this.saving = false);
        },
        deleteIndustryType(industryType) {
            this.error = null;
            this.api.deleteIndustryType(industryType.id).then(() => this.refreshList(false))
                .catch(e => this.error = e.response.data.message);
        },
        formatDate(date) {
            return date ? DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT) : null
        },
        getGlobalTypes() {
            this.sharedApi.getGlobalTypes().then(resp => {
                this.globalTypes = resp.data.data?.global_types.map(type => {
                    type.id = type.num_id
                    return type;
                });
            }).catch(e => this.error = e.response.data.message);
        },
        refreshList(closeModal = false) {
            this.getIndustryTypes();
            if(closeModal) this.closeModal();
        },
        viewReviews(industryType) {
            this.$emit('industry-type-reviews-requested', industryType);
        },
    }
}
</script>
