<template>
    <div class="absolute right-0 bottom-28 z-20" v-if="recordings !== null && recordings.length > 0">
        <div @click="toggleRecordingList" :class="iconClasses" class="z-20 mr-10 relative border transition duration-200 shadow-module w-10 h-10 rounded-full inline-flex justify-center items-center cursor-pointer">
            <svg class="fill-current" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.68421 9.20655C7.57421 9.20655 9 7.73503 9 5.7844C9 3.83378 7.57421 2.36226 5.68421 2.36226C3.79421 2.36226 2.36842 3.83378 2.36842 5.7844C2.36842 7.73503 3.79421 9.20655 5.68421 9.20655ZM6.63158 10.1335H4.73684C2.12495 10.1335 0 12.3266 0 15.0222V16H11.3684V15.0222C11.3684 12.3266 9.24347 10.1335 6.63158 10.1335ZM15.5027 0L14.1632 1.38255C15.4146 2.67612 16.1053 4.39501 16.1053 6.22244C16.1053 8.04987 15.4146 9.76876 14.1632 11.0623L15.5027 12.4449C17.1133 10.7817 18 8.57199 18 6.22244C18 3.87289 17.1133 1.66316 15.5027 0Z"/>
                <path d="M12.9823 3L12 4.40158C12.3939 4.96083 12.6106 5.70616 12.6106 6.5C12.6106 7.29383 12.3939 8.03917 12 8.59842L12.9823 10C13.6381 9.06561 14 7.82339 14 6.5C14 5.17661 13.6381 3.93439 12.9823 3Z"/>
            </svg>
        </div>
        <div v-if="recordingList" class="z-10 absolute -right-1 -bottom-2 mr-9 w-100 h-100 shadow-module border rounded-t-md rounded-bl-md rounded-br-xl"
             :class="{'bg-dark-module border-primary-500' : darkMode ,'bg-light-module border-light-border' : !darkMode }">
            <div class="p-5">
                <h5 class="text-primary-500 text-sm uppercase font-semibold">Recordings</h5>
            </div>
            <div class="border-t border-b overflow-y-auto"
                 :class="{'bg-dark-background border-dark-border' : darkMode ,'bg-light-background border-light-border' : !darkMode }"
                 style="height: 75%;">
                <div v-for="(recording, i) in recordings" :key="i"
                     :class="{'hover:bg-dark-module border-dark-border text-grey-120' : darkMode ,'hover:bg-light-module border-light-border  text-grey-900' : !darkMode}"
                     class="py-5 px-5 border-b transition duration-200 cursor-pointer grid grid-cols-3"
                >
                    <p class="text-sm font-medium truncate">Call Recording</p>
                    <p class="text-sm text-center"
                       :class="{'text-blue-400' : darkMode ,'text-grey-400' : !darkMode }"
                    >{{ $filters.dateFromTimestamp(recording.created_at) }} ({{ Math.round((recording.duration_seconds / 60) * 10) / 10 }}m)</p>
                    <div class="flex items-center justify-end">
                        <div class="flex items-center justify-between">
                            <div class="flex items-center">
                                <svg class="fill-current text-blue-550 mr-5" @click="play(recording.recording_link)" width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0V12L10 6L0 0Z"/>x
                                </svg>
                                <svg class="fill-current text-blue-400" @click="stop()" width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H3.75V12H0V0ZM6.25 0H10V12H6.25V0Z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import DispatchesGlobalEventsMixin from "../../../mixins/dispatches-global-events-mixin";
import {waitForCallbackToReturnTrue} from "../../../../utilities/loading-utilities";

export default {
    name: "RecordingsWrapper",
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        recordingList: {
            type: Boolean,
            default: false,
        },
        recordings: {
            type: Array,
            default: null
        }
    },
    mixins: [DispatchesGlobalEventsMixin],
    data: function() {
      return {
          audio: null
          // rcService: null
      }
    },
    methods: {
        toggleRecordingList() {
            this.$emit('toggle-recording-list')
        },
        stopIfPlaying() {
            if(this.audio !== null) {
                this.audio.pause();
                this.audio = null;
            }
        },

        async play(recording) {
            this.stopIfPlaying();

            if(recording) {
                this.audio = new Audio(recording);
                this.audio.play();
            }
        },

        stop() {
            this.stopIfPlaying();
        },

        initializeListeners() {
            this.listenForGlobalEvent('clear-lead-data', () => {this.$emit('clear-recordings');});
            this.listenForGlobalEvent('new-lead', (lead) => {this.$emit('get-call-recordings-for-lead', lead.basic.id);})
        }
    },
    computed: {
        iconClasses() {
            const classes = [];

            if(this.recordingList) {
                if(this.darkMode) {
                    classes.push('bg-dark-background', 'text-blue-550', 'border-primary-500', 'hover:border-blue-400', 'hover:bg-dark-175');
                } else {
                    classes.push('bg-primary-500', 'text-white', 'hover:bg-blue-500', 'border-transparent');
                }
            } else {
                if(this.darkMode) {
                    classes.push('bg-dark-background', 'text-blue-550', 'border-primary-500', 'hover:border-blue-400', 'hover:bg-dark-background');
                } else {
                    classes.push('bg-light-module', 'text-blue-550', 'border-light-border', 'hover:border-primary-500', 'hover:bg-cyan-125');
                }
            }

            return classes;
        },
    },
    mounted() {
        this.dispatchGlobalEvent('request-service');
        // this.listenForGlobalEvent('service', ({service}) => this.rcService = service);
        this.initializeListeners();
    }
}
</script>

<style scoped>

</style>
