<template>
    <!-- Contact -->
    <div id="contact" class="row-span-5 border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="p-5" v-if="!loading">
            <h5 class="text-primary-500 text-sm uppercase font-semibold pb-4 leading-tight">Contact</h5>
            <div class="flex items-center truncate pb-3">
                <svg @click="editField(fields.contactName.name)" class="cursor-pointer mr-2 flex-shrink-0" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7V7ZM8.75 4.375C8.75 4.83913 8.56563 5.28425 8.23744 5.61244C7.90925 5.94063 7.46413 6.125 7 6.125C6.53587 6.125 6.09075 5.94063 5.76256 5.61244C5.43437 5.28425 5.25 4.83913 5.25 4.375C5.25 3.91087 5.43437 3.46575 5.76256 3.13756C6.09075 2.80937 6.53587 2.625 7 2.625C7.46413 2.625 7.90925 2.80937 8.23744 3.13756C8.56563 3.46575 8.75 3.91087 8.75 4.375V4.375ZM7 7.875C6.16228 7.87483 5.34212 8.11518 4.63699 8.56748C3.93186 9.01978 3.37141 9.66501 3.02225 10.4265C3.51459 10.9993 4.12499 11.4588 4.81158 11.7735C5.49817 12.0883 6.2447 12.2508 7 12.25C7.7553 12.2508 8.50183 12.0883 9.18842 11.7735C9.87501 11.4588 10.4854 10.9993 10.9777 10.4265C10.6286 9.66501 10.0681 9.01978 9.36301 8.56748C8.65788 8.11518 7.83772 7.87483 7 7.875V7.875Z" fill="#0081FF"/>
                </svg>
                <edit-field :dark-mode="darkMode" @update-field="updateField" @cancel-edit="cancelEdit" v-if="editingField === fields.contactName.name" :value="consumerContact[fields.contactName.dataName]" :field-name="fields.contactName.name"></edit-field>
                <p v-else class="pb-0 truncate">{{ consumerContact[fields.contactName.dataName] ?? 'Unknown' }}</p>
            </div>
            <div class="flex items-center truncate pb-3">
                <svg @click="editField(fields.email.name)" class="cursor-pointer mr-2 flex-shrink-0" width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.00262451 1.6485L7 5.14675L13.9974 1.6485C13.9715 1.20264 13.7761 0.783552 13.4512 0.477073C13.1264 0.170595 12.6966 -8.18866e-05 12.25 2.9473e-08H1.75C1.30339 -8.18866e-05 0.873641 0.170595 0.54878 0.477073C0.223919 0.783552 0.0285281 1.20264 0.00262451 1.6485V1.6485Z" fill="#0081FF"/>
                    <path d="M14 3.60324L7 7.10324L0 3.60324V8.74999C0 9.21412 0.184374 9.65924 0.512563 9.98743C0.840752 10.3156 1.28587 10.5 1.75 10.5H12.25C12.7141 10.5 13.1592 10.3156 13.4874 9.98743C13.8156 9.65924 14 9.21412 14 8.74999V3.60324Z" fill="#0081FF"/>
                </svg>
                <edit-field :dark-mode="darkMode" @update-field="updateField" @cancel-edit="cancelEdit" v-if="editingField === fields.email.name" :value="consumerContact[fields.email.dataName]" :field-name="fields.email.name"></edit-field>
                <p v-else class="pb-0 truncate">{{ consumerContact[fields.email.dataName] ?? 'Unknown@email' }}</p>
            </div>
            <div class="flex items-center justify-between pb-3">
                <div class="flex items-center">
                    <svg class="mr-2 flex-shrink-0" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.61958 0.256582C7.45524 0.0922926 7.23238 0 7 0C6.76762 0 6.54476 0.0922926 6.38042 0.256582L0.246001 6.391C0.0863671 6.55629 -0.00196356 6.77765 3.31282e-05 7.00743C0.00202981 7.2372 0.0941942 7.457 0.256676 7.61948C0.419158 7.78196 0.638956 7.87413 0.868731 7.87612C1.09851 7.87812 1.31987 7.78979 1.48515 7.63016L1.74192 7.37339V13.145C1.74192 13.3774 1.83425 13.6003 1.9986 13.7647C2.16295 13.929 2.38585 14.0213 2.61827 14.0213H4.37096C4.60338 14.0213 4.82628 13.929 4.99063 13.7647C5.15498 13.6003 5.24731 13.3774 5.24731 13.145V11.3923C5.24731 11.1599 5.33964 10.937 5.50398 10.7726C5.66833 10.6083 5.89123 10.516 6.12365 10.516H7.87635C8.10877 10.516 8.33167 10.6083 8.49602 10.7726C8.66036 10.937 8.75269 11.1599 8.75269 11.3923V13.145C8.75269 13.3774 8.84502 13.6003 9.00937 13.7647C9.17371 13.929 9.39662 14.0213 9.62904 14.0213H11.3817C11.6142 14.0213 11.8371 13.929 12.0014 13.7647C12.1657 13.6003 12.2581 13.3774 12.2581 13.145V7.37339L12.5148 7.63016C12.6801 7.78979 12.9015 7.87812 13.1313 7.87612C13.361 7.87413 13.5808 7.78196 13.7433 7.61948C13.9058 7.457 13.998 7.2372 14 7.00743C14.002 6.77765 13.9136 6.55629 13.754 6.391L7.61958 0.256582V0.256582Z" fill="#0081FF"/>
                    </svg>
                    <p class="pb-0 break-words">{{ consumerContact[fields.address.dataName] ?? 'No Address Found'}}</p>
                </div>
                <div v-if="editingField !== fields.ownProperty.name">
                    <div @click="editField(fields.ownProperty.name)" v-if="darkMode" class="cursor-pointer px-4 inline-flex items-center rounded-full py-1 font-medium whitespace-no-wrap" :class="{'text-blue-550' : isPropertyOwner(consumerContact[fields.ownProperty.dataName]), 'text-red-350' : !isPropertyOwner(consumerContact[fields.ownProperty.dataName])}">
                        <svg v-if="isPropertyOwner(consumerContact.own_property)" class="mr-1 flex-shrink-0" width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.91794 5.86773L1.49074 3.32401L0 4.89347L3.92005 9L11 1.56724L9.50715 0L3.91794 5.86773Z" fill="#0081FF"/>
                        </svg>
                        <svg v-else class="mr-1 flex-shrink-0" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 1L1 9" stroke="#E25555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1 1L9 9" stroke="#E25555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p v-if="isPropertyOwner(consumerContact.own_property)" class="text-sm">Property Owner</p>
                        <p v-else class="text-sm">Not Property Owner</p>
                    </div>
                    <div @click="editField(fields.ownProperty.name)" v-if="!darkMode" class="cursor-pointer px-4 inline-flex items-center rounded-full py-1 font-medium whitespace-no-wrap" :class="{'text-blue-550 bg-cyan-125' : isPropertyOwner(consumerContact[fields.ownProperty.dataName]), 'text-red-350 bg-red-75' : !isPropertyOwner(consumerContact[fields.ownProperty.dataName])}">
                        <svg v-if="isPropertyOwner(consumerContact[fields.ownProperty.dataName])" class="mr-1 flex-shrink-0" width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.91794 5.86773L1.49074 3.32401L0 4.89347L3.92005 9L11 1.56724L9.50715 0L3.91794 5.86773Z" fill="#0081FF"/>
                        </svg>
                        <svg v-else class="mr-1 flex-shrink-0" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 1L1 9" stroke="#E25555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1 1L9 9" stroke="#E25555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p v-if="isPropertyOwner(consumerContact[fields.ownProperty.dataName])" class="text-sm">Property Owner</p>
                        <p v-else class="text-sm">Not Property Owner</p>
                    </div>
                </div>
                <edit-field v-else :dark-mode="darkMode" :type="fields.ownProperty.editType" :options="fields.ownProperty.editOptions" @update-field="updateField" @cancel-edit="cancelEdit" :value="consumerContact[fields.ownProperty.dataName]" :field-name="fields.ownProperty.name"></edit-field>
            </div>
            <div class="flex items-center justify-between">
                <div class="flex items-center">
                    <svg @click="editField('phone')" class="cursor-pointer mr-2 flex-shrink-0" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.7428 10.9665L10.5482 8.06198C10.3972 7.92473 10.1988 7.85153 9.99482 7.85784C9.79086 7.86414 9.59732 7.94946 9.45508 8.09577L7.57448 10.0298C7.12182 9.94335 6.21177 9.65965 5.27501 8.72525C4.33825 7.78771 4.05455 6.87532 3.97046 6.4258L5.90293 4.54443C6.04942 4.40229 6.13487 4.20873 6.14117 4.00471C6.14748 3.80068 6.07416 3.60221 5.93672 3.45129L3.03291 0.257523C2.89542 0.106131 2.70432 0.0142999 2.50021 0.00153385C2.2961 -0.0112322 2.09505 0.056072 1.93976 0.189152L0.234412 1.65165C0.0985433 1.78801 0.0174476 1.9695 0.00650858 2.16168C-0.00527953 2.35815 -0.23004 7.01206 3.3787 10.6223C6.52691 13.7697 10.4704 14 11.5565 14C11.7153 14 11.8127 13.9953 11.8386 13.9937C12.0308 13.983 12.2122 13.9015 12.3479 13.765L13.8096 12.0589C13.9432 11.9041 14.011 11.7032 13.9985 11.4991C13.9861 11.295 13.8943 11.1039 13.7428 10.9665Z" fill="#0081FF"/>
                    </svg>
                    <edit-field :allow-controls-to-wrap="true" :dark-mode="darkMode" @update-field="updateField" @cancel-edit="cancelEdit" v-if="editingField === fields.phone.name" :value="consumerContact[fields.phone.dataName]" :field-name="fields.phone.name"></edit-field>
                    <p v-else class="pb-0 text-lg">{{ $filters.formatPhoneNumber(consumerContact[fields.phone.dataName]) || '-' }}</p>
                    <div v-if="editingField !== fields.phone.name" class="inline-flex items-center ml-2">
                        <button @click="call" class="inline-flex items-center px-3 py-1 mx-2 bg-primary-500 hover:bg-blue-500 transition duration-200 rounded text-xs font-medium text-white">
                            <svg class="mr-1 w-3" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7796 9.3999L9.04134 6.91027C8.91191 6.79263 8.74182 6.72988 8.56699 6.73529C8.39216 6.74069 8.22628 6.81382 8.10435 6.93923L6.49241 8.59697C6.10441 8.52287 5.32438 8.2797 4.52144 7.47879C3.7185 6.67518 3.47533 5.89313 3.40325 5.50783L5.05965 3.89523C5.18522 3.77339 5.25846 3.60748 5.26386 3.4326C5.26927 3.25773 5.20642 3.08761 5.08862 2.95825L2.59964 0.220734C2.48179 0.0909691 2.31799 0.0122571 2.14304 0.00131473C1.96808 -0.00962763 1.79575 0.0480617 1.66265 0.162131L0.200925 1.4157C0.0844657 1.53258 0.0149551 1.68814 0.00557879 1.85287C-0.00452532 2.02127 -0.197177 6.01034 2.89603 9.10486C5.59449 11.8026 8.97465 12 9.90558 12C10.0416 12 10.1252 11.996 10.1474 11.9946C10.3121 11.9854 10.4676 11.9156 10.5839 11.7986L11.8368 10.3362C11.9513 10.2035 12.0094 10.0313 11.9987 9.8564C11.988 9.68146 11.9094 9.51763 11.7796 9.3999V9.3999Z" fill="white"/>
                            </svg>
                            Call
                        </button>
                        <button @click="sms"  class="inline-flex items-center px-3 py-1 border border-primary-500 hover:bg-cyan-125 transition duration-200 rounded text-xs font-medium text-blue-550">
                            <svg class="mr-1 w-3" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 2C0 1.46957 0.229869 0.960859 0.63904 0.585786C1.04821 0.210714 1.60316 0 2.18182 0H9.81818C10.3968 0 10.9518 0.210714 11.361 0.585786C11.7701 0.960859 12 1.46957 12 2V6C12 6.53043 11.7701 7.03914 11.361 7.41421C10.9518 7.78929 10.3968 8 9.81818 8H7.63636L4.36364 11V8H2.18182C1.60316 8 1.04821 7.78929 0.63904 7.41421C0.229869 7.03914 0 6.53043 0 6V2Z" fill="#0081FF"/>
                            </svg>
                            Text
                        </button>
                    </div>
                </div>
                <div v-if="consumerContact[fields.validPhone.dataName] !== null">
                    <div v-if="darkMode" class="px-4 inline-flex items-center rounded-full py-1 font-medium whitespace-no-wrap" :class="{'text-green-550' : consumerContact[fields.validPhone.dataName] === true, 'text-red-350' : consumerContact[fields.validPhone.dataName] !== true}">
                        <svg v-if="consumerContact[fields.validPhone.dataName] === true" class="mr-1 flex-shrink-0" width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.91794 5.86773L1.49074 3.32401L0 4.89347L3.92005 9L11 1.56724L9.50715 0L3.91794 5.86773Z" fill="#00AE07"/>
                        </svg>
                        <svg v-else class="mr-1 flex-shrink-0" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 1L1 9" stroke="#E25555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1 1L9 9" stroke="#E25555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p v-if="consumerContact[fields.validPhone.dataName] === true" class="text-sm">Phone Verified</p>
                        <p v-else class="text-sm">Phone Unverified</p>
                    </div>
                    <div v-if="!darkMode" class="px-4 inline-flex items-center rounded-full py-1 font-medium whitespace-no-wrap" :class="{'text-green-550 bg-green-150' : consumerContact[fields.validPhone.dataName] === true, 'text-red-350 bg-red-75' : consumerContact[fields.validPhone.dataName] !== true}">
                        <svg v-if="consumerContact[fields.validPhone.dataName] === true" class="mr-1 flex-shrink-0" width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.91794 5.86773L1.49074 3.32401L0 4.89347L3.92005 9L11 1.56724L9.50715 0L3.91794 5.86773Z" fill="#00AE07"/>
                        </svg>
                        <svg v-else class="mr-1 flex-shrink-0" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 1L1 9" stroke="#E25555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1 1L9 9" stroke="#E25555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p v-if="consumerContact[fields.validPhone.dataName] === true" class="text-sm">Phone Verified</p>
                        <p v-else class="text-sm">Phone Unverified</p>
                    </div>
                </div>
            </div>
        </div>
        <loading-spinner class="row-start-5 col-span-2" v-else :dark-mode="darkMode" />
        <alert :dark-mode="darkMode" class="row-start-5 col-span-2" v-if="alertActive" :alert-type="alertType" :text="alertText" />
    </div>
</template>

<script>
import EditField from "../../components/EditField";
import ConsumerApiService from "../../services/consumer_api";
import AlertsMixin from "../../../../mixins/alerts-mixin";
import Alert from "../../components/Alert";
import LoadingSpinner from "../../components/LoadingSpinner";
import DispatchesGlobalEventsMixin from "../../../../mixins/dispatches-global-events-mixin";

export default {
    name: "ConsumerContact",
    components: {LoadingSpinner, Alert, EditField},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        consumerProductId: {
            type: Number,
            default: null
        },
        canEdit: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            api: ConsumerApiService.make(),
            consumerContact: {},
            responseKey: 'consumer_contact',
            loading: false,
            editingField: null,
            fields: {
                contactName: {
                    name: 'contactName',
                    dataName: 'name',
                    editType: 'text',
                },
                email: {
                    name: 'email',
                    dataName: 'email',
                    editType: 'text'
                },
                address: {
                    name: 'address',
                    dataName: 'address'
                },
                phone: {
                    name: 'phone',
                    dataName: 'phone',
                    editType: 'text',
                },
                ownProperty: {
                    name: 'ownProperty',
                    dataName: 'own_property',
                    editType: 'select',
                    editOptions: [ {id: "yes", value: "Yes"}, {id: "no", value: "No"} ],
                },
                validPhone: {
                    name: 'validPhone',
                    dataName: 'phone_is_valid',
                }
            },
            messages: {
                missingData: `Not all expected data fetched.`,
                noData: `Failed to retrieve contact data.`,
                updateFailed: `Server error while updating data.`,
                updateSuccess: `Contact details updated.`,
            }
        }

    },
    mixins: [
        AlertsMixin, DispatchesGlobalEventsMixin
    ],
    created() {
        if (this.consumerProductId) this.getConsumerContact();
    },
    methods: {
        getConsumerContact() {
            this.loading = true;
            this.api.getConsumerProductContactInfo(this.consumerProductId).then(resp => {
                if (resp.data.data.status) {
                    const contactData = resp.data.data[this.responseKey];
                    for (const field in this.fields) {
                        if (contactData[this.fields[field].dataName] === undefined) this.showAlert('warning', this.messages.missingData);
                        else this.consumerContact[this.fields[field].dataName] = contactData[this.fields[field].dataName];
                    }
                }
                else this.showAlert('error', this.messages.noData);
            }).catch(() => this.showAlert('error', this.messages.noData)
            ).finally(() => this.loading = false);
        },
        editField(name) {
            this.editingField = name;
        },
        cancelEdit() {
            this.editingField = null;
        },
        updateField(name, value) {
            if (this.fields[name]?.dataName) {
                this.loading = true;
                if (name === this.fields.contactName.name) {

                }
                const updatePayload = name === this.fields.contactName.name
                    ? this.splitContactName(value)
                    : { [this.fields[name].dataName]: value };
                this.api.updateConsumerProductContactInfo(this.consumerProductId, updatePayload).then(resp => {
                    if (resp.data.data.status) {
                        this.showAlert('success', this.messages.updateSuccess);
                        this.getConsumerContact();
                    }
                    else this.showAlert('error', this.messages.updateFailed);
                }).catch(err => {
                    const errorMessage = err.response?.data?.message ?? this.messages.updateFailed;
                    this.showAlert('error', errorMessage);
                }).finally(() => {
                    this.loading = false;
                    this.editingField = null;
                });
            }
            else this.showAlert(`warning`, `Field "${name}" not a known editable field.`)
        },
        splitContactName(fullName) {
            const parts = `${fullName}`.trim().split(/\s+/);
            return {
                first_name: parts.shift(),
                last_name: parts.join(' ')
            };
        },
        skewer(inputString, snake = false) {
            const delimiter = snake ? '_' : '-';
            return `${inputString}`.trim()
                .replace(/\s+/g, delimiter)
                .replace(/([a-z])([A-Z])/g, `$1${delimiter}$2`)
                .toLowerCase();
        },
        isPropertyOwner(value) {
            if (value === '' || value === null || value.toLowerCase() === 'yes') {
                return true;
            }
            return false;
        },
        call() {
            this.dispatchGlobalEvent('call', {phone: this.consumerContact[this.fields.phone.dataName], name: this.consumerContact[this.fields.contactName.dataName], id: this.consumerProductId});
        },
        sms() {
            this.dispatchGlobalEvent('sms', {phone: this.consumerContact[this.fields.phone.dataName], name: this.consumerContact[this.fields.contactName.dataName], id: this.consumerProductId});
        }
    },
    watch: {
        consumerProductId(newVal, oldVal) {
            if (newVal !== oldVal) this.getConsumerContact();
        }
    }
}
</script>
