import {getOperatorResult} from "../helpers";

/**
 * Looks at the two operator results and returns an object of booleans for both.
 * @param {number} comparedAgainst
 * @param {OperatorJSDocType} firstOperator
 * @param {number} firstComparer
 * @param {OperatorJSDocType} secondOperator
 * @param {number} secondComparer
 * @param {string} logical
 * @returns boolean
 * @throws Error
 */
export const testTwoOperators = (comparedAgainst, firstOperator, firstComparer, secondOperator, secondComparer, logical) => {
    let operatorOneResult = true;
    let operatorTwoResult = true;

    try {
        operatorOneResult = getOperatorResult(comparedAgainst, firstOperator, firstComparer);
    } catch (e) {
        throw e;
    }

    if (logical === 'and' || logical === 'or') {
        try {
            operatorTwoResult = getOperatorResult(comparedAgainst, secondOperator, secondComparer)
        } catch (e) {
            throw e;
        }
    }

    if (logical === 'and') {
        return operatorOneResult && operatorTwoResult;
    } else if (logical === 'or') {
        return operatorOneResult || operatorTwoResult;
    } else {
        return operatorOneResult;
    }
}

/**
 * @typedef CompanyServicingAreaFiltersOptionalJsDocType
 * @property {?CompanyServicingAreaFilterJsDocType} companyContactsFilter
 * @property {?CompanyServicingAreaFilterJsDocType} amountOfPurchasedLeadsFilter
 * @property {?CompanyServicingAreaFilterJsDocType} estimatedRevenueFilter
 * @property {?CompanyServicingAreaFilterJsDocType} employeeCountFilter
 * @property {?CompanyServicingAreaFilterJsDocType} googleReviewsFilter
 * @property {?CompanyServicingAreaFilterJsDocType} googleRatingFilter
 */

/**
 * Evaluates the filters object into primitives.
 * @param {CompanyServicingAreaFiltersJsDocType} filters
 * @return CompanyServicingAreaFiltersOptionalJsDocType
 */
export const evaluateFiltersObject = (filters) => {
    let companyContactsFilter = null;
    let amountOfPurchasedLeadsFilter = null;
    let estimatedRevenueFilter = null;
    let employeeCountFilter = null;
    let googleReviewsFilter = null;
    let googleRatingFilter = null;

    if (typeof filters === "object") {
        companyContactsFilter = filters?.companyContacts;
        amountOfPurchasedLeadsFilter = filters?.amountOfPurchasedLeads;
        estimatedRevenueFilter = filters?.estimatedRevenue;
        employeeCountFilter = filters?.employeeCount;
        googleReviewsFilter = filters?.googleReviews;
        googleRatingFilter = filters?.googleRating;
    }

    return {
        companyContactsFilter,
        amountOfPurchasedLeadsFilter,
        estimatedRevenueFilter,
        employeeCountFilter,
        googleReviewsFilter,
        googleRatingFilter
    }
}
