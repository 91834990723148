import axios from 'axios';

export default class ApiService {
    constructor(baseUrl, baseEndpoint, version) {
        this.baseEndpoint = baseEndpoint;
        this.baseUrl = baseUrl;
        this.version = version;
    }

    axios() {
        const axiosConfig = {
            baseURL: `/${this.baseUrl}/v${this.version}/${this.baseEndpoint}`
        }

        return axios.create(axiosConfig);
    }

    static make() {
        return new ApiService('internal-api', 'websites', 1);
    }

    getWebsites() {
        return this.axios().get('/');
    }

    createWebsite(data) {
        return this.axios().post('/', data);
    }

    updateWebsite(id, data) {
        return this.axios().patch(`/${id}`, data);
    }

    deleteWebsite(id) {
        return this.axios().delete(`/${id}`);
    }

    getKeys(websiteId) {
        return this.axios().get(`/${websiteId}/website-api-keys`);
    }

    addKey(websiteId, data) {
        return this.axios().post(`/${websiteId}/website-api-keys`, data);
    }

    enableKey(websiteId, keyId) {
        return this.axios().patch(`/${websiteId}/website-api-keys/${keyId}/enable`);
    }

    disableKey(websiteId, keyId) {
        return this.axios().patch(`/${websiteId}/website-api-keys/${keyId}/disable`);
    }
}
