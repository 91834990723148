<template>
    <div>
        <div class="main-layout font-body">
            <div class="w-full">
                <div class="w-full flex-auto pt-3 pb-10 relative"
                     :class="{'bg-light-background': !darkMode, 'bg-dark-background': darkMode}">
                    <div class="px-10" :class="[darkMode ? 'text-white' : 'text-slate-900']">
                        <div class="flex items-center justify-between flex-wrap py-6">
                            <h3 class="text-xl font-medium pb-0 leading-none mr-5">Task View Editor</h3>
                        </div>
                        <div>
                            <div class="grid md:grid-cols-1 xl:grid-cols-3 gap-4">
                                <task-types
                                    :dark-mode="darkMode"
                                    :loading="loading"
                                    :task-types="taskTypes"
                                    @update="update"
                                    @edit-task-type="editTaskType"
                                    @cancel-edit="cancelEdit"
                                ></task-types>
                                <editor
                                    :dark-mode="darkMode"
                                    v-model="editingTaskType"
                                    :modules="modules"
                                    @delete-task-type="deleteTaskType"
                                    @save="saveEdit"
                                    @cancel="cancelEdit"
                                ></editor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TaskTypes from "./Components/TaskTypes";
import Editor from "./Components/Editor";
import ApiService from "../TaskManagement/services/api";

export default {
    name: "TaskTypeEditor",
    components: {Editor, TaskTypes},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            editingTaskType: {
                id: null,
                name: null,
                modules: []
            },
            api: ApiService.make(),
            loading: false,
            taskTypes: [],
            modules: [],
        }
    },
    created() {
        this.update();
    },
    methods: {
        editTaskType(taskType) {
            if (this.editingTaskType.id == taskType.id)
                return this.cancelEdit();

            this.editingTaskType.id = taskType.id;
            this.editingTaskType.name = taskType.name;
            this.editingTaskType.modules = taskType.modules;
        },
        saveEdit() {
            this.loading = true;

            this.api.saveTaskType(this.editingTaskType.id, this.editingTaskType.name, this.editingTaskType.modules).then(resp => {
                this.update();
                this.cancelEdit();
            });
        },
        cancelEdit() {
            this.editingTaskType.id = null;
            this.editingTaskType.name = null;
            this.editingTaskType.modules = [];
        },
        update() {
            this.loading = true;

            Promise.all([this.getTaskTypes(), this.getModules()]).then(resp => {
                this.loading = false;
            });
        },
        getTaskTypes() {
            return new Promise(resolve => {
                this.api.getTaskTypes().then(resp => {
                    this.taskTypes = resp.data.data.task_types;
                    resolve();
                });
            })
        },
        getModules() {
            return new Promise(resolve => {
                this.api.getModules().then(resp => {
                    this.modules = resp.data.data.modules;
                    resolve();
                });
            })
        },
        deleteTaskType(id) {
            this.loading = true;
            this.cancelEdit();

            this.api.deleteTaskType(id).then(resp => {
                this.update();
            });
        }
    }
}
</script>

<style lang="scss">

</style>
