<template>
    <div class="flex items-center justify-center relative">
        <div @click="toggleActionsHandle" class="relative inline-flex items-center justify-center cursor-pointer p-2 rounded-full">
            <div class="bg-primary-500 h-1 w-1 rounded-full mr-1"></div>
            <div class="bg-primary-500 h-1 w-1 rounded-full mr-1"></div>
            <div class="bg-primary-500 h-1 w-1 rounded-full mr-1"></div>
        </div>
        <div v-if="actionsHandle" class="absolute top-0 right-0 z-50 w-40 border divide-y shadow-module rounded overflow-hidden"
             :class="{'border-light-border bg-light-background divide-light-border': !darkMode, 'divide-dark-border border-dark-border hover:bg-dark-module text-slate-200': darkMode}"
        >
            <p v-if="!noEditButton" class="py-2 px-3 cursor-pointer transition duration-200"
               :class="{'border-light-border bg-light-background hover:bg-light-module': !darkMode, 'border-dark-border hover:bg-dark-module bg-dark-background text-slate-200': darkMode}"
                @click="actionClicked('edit')"
            >Edit</p>
            <p v-if="!noDeleteButton" class="py-2 px-3 border-b cursor-pointer transition duration-200"
               :class="{'border-light-border bg-light-background hover:bg-light-module': !darkMode, 'border-dark-border hover:bg-dark-module bg-dark-background text-slate-200': darkMode}"
                @click="actionClicked('delete')"
            >Delete</p>
            <p v-if="!noCustomAction" class="py-2 px-3 cursor-pointer transition duration-200" v-for="customAction in customActions"
               :class="{'border-light-border bg-light-module hover:bg-light-module': !darkMode, 'border-dark-border hover:bg-dark-module bg-dark-background text-slate-200': darkMode}"
               @click="actionClicked(customAction.event)"
            >{{ customAction.name }}</p>
        </div>
        <div v-if="actionsHandle" @click="toggleActionsHandle" class="fixed z-40 w-full h-full bg-transparent">

        </div>
    </div>
</template>

<script>
export default {
    name: "ActionsHandle",
    data() {
        return {
            actionsHandle: false,
        }
    },
    props: {
        darkMode: false,
        noEditButton: {
            type: Boolean,
            default: false
        },
        noDeleteButton: {
            type: Boolean,
            default: false
        },
        noCustomAction: {
            type: Boolean,
            default: true,
        },
        customActions: {
            type: Array,
            default: []
        }
    },
    methods: {
        toggleActionsHandle() {
            this.actionsHandle = ! this.actionsHandle
        },
        actionClicked(action) {
            this.toggleActionsHandle();
            this.$emit(action);
        }
    }
}
</script>

<style scoped>

</style>
