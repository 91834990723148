/**
 * @todo this is a temporary fix to get around env variables not being compiled into the JS during the build.
 * @returns {string|*}
 */
const getLegacyBaseUrl = () => {
    switch(window.location.host) {
        case("dev.admin-sr.com"):
            return "https://devlegacy.admin-sr.com";
            break;
        case("admin-sr.com"):
            return "https://legacy.admin-sr.com";
            break;
        default:
            return process.env.MIX_LEGACY_ADMIN_BASE_URL;
            break;
    }
}

export default {
    methods: {
        getLegacyAdminQuoteUrl(leadId) {
            const baseUrl = getLegacyBaseUrl();
            return `${baseUrl}/quote.php?quoteid=${leadId}`
        },
        getLegacyAdminCompanyUrl(companyId) {
            const baseUrl = getLegacyBaseUrl();
            return  `${baseUrl}/company.php?getcompanyid=${companyId}`
        }
    }
}
