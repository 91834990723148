import axios from 'axios';

export default class ApiService {
    constructor(baseUrl, baseEndpoint, version) {
        this.baseEndpoint = baseEndpoint;
        this.baseUrl = baseUrl;
        this.version = version;
    }

    axios() {
        const axiosConfig = {
            baseURL: `/${this.baseUrl}/v${this.version}/${this.baseEndpoint}`
        }

        return axios.create(axiosConfig);
    }

    static make() {
        return new ApiService('internal-api', 'companies', 1);
    }

    getServices(companyId) {
        return this.axios().get(`/${companyId}/services`)
    }

    addService(companyId, serviceId) {
        return this.axios().post(`/${companyId}/services/${serviceId}`)
    }

    deleteService(companyId, serviceId) {
        return this.axios().delete(`/${companyId}/services/${serviceId}`)
    }
}
