<template>
    <div class="main-layout font-body">
        <div class="w-full">
            <div class="w-full flex-auto py-5 relative"
                 :class="{'bg-light-background': !darkMode, 'bg-dark-background': darkMode}">
                <div class="border rounded-lg p-8 "
                     :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border text-grey-120': darkMode}">
                    <nav-lead-report @params="getLeadReportDataWithFilter" :navData="navData"></nav-lead-report>
                    <loading-spinner v-if="loading" :dark-mode="darkMode"/>
                    <div v-else class="mt-8">
                        <table-lead-report :datas="data" :thead="tHeadData" :tbody="tBodyData"
                                           v-if="data.length > 0"></table-lead-report>
                        <div v-if="data.length == 0" class="text-center my-32 text-sm text-gray-500">
                            No leads between {{ start_date_str }} and {{ end_date_str }}, past {{ daysCount }} day(s).
                        </div>
                        <div class="mt-8" v-if="data.length > 0">
                            <Pagination :dark-mode="darkMode" :pagination-data="paginationData" :show-pagination="true"
                                        @change-page="handlePaginationEvent"></Pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LoadingSpinner from "../../Shared/components/LoadingSpinner.vue";
import TableLeadReport from "./components/TableLeadReport.vue";
import ApiService from "./services/api";
import Pagination from "../../Shared/components/Pagination.vue";
import NavLeadReport from "./components/NavLeadReport.vue";
export default {
    name: "AllocatedLeadReport",
    components: {
        LoadingSpinner,
        NavLeadReport,
        TableLeadReport,
        Pagination
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
    },
    data: function () {
        return {
            data: null,
            loading: true,
            paginationData: null,
            tHeadData: ['Created date', 'Lead ID'],
            tBodyData: ['created_date', 'lead_id'],
            navData: {
                breadcrumb: {
                    parent: 'Lead Issues Report',
                    current: 'Allocated Lead',
                },
                description: 'Lead is marked as “allocated” but it has not been delivered to any companies',
                showFilters: true,
                showDatePicker: true
            },
            page: 1,
            daysCount: null,
            start_date: null,
            end_date: null,
            start_date_str: null,
            end_date_str: null,
        }
    },
    created() {
        this.apiService = ApiService.make();
    },
    methods: {
        async handlePaginationEvent(newPageUrl) {
            this.loading = true;
            this.apiService.getPaginatedLead(newPageUrl.link, this.start_date, this.end_date).then(resp => {
                let {data, ...paginationData} = resp.data.data.leads;
                this.data = data;
                this.paginationData = paginationData;
                this.page = paginationData.page;
            }).catch(e => console.error(e)).finally(() => this.loading = false);
        },
        async getLeadReportDataWithFilter(filter) {
            this.loading = true;
            this.daysCount = filter.daysCount;
            this.start_date = filter.start_date;
            this.end_date = filter.end_date;
            this.start_date_str = (new Date(filter.start_date)).toDateString();
            this.end_date_str = (new Date(filter.end_date)).toDateString();
            this.apiService.getAllocatedLead({
                page: this.page,
                start_date: filter.start_date,
                end_date: filter.end_date
            }).then(resp => {
                if (resp.data.data.status === true) {
                    let {data, ...paginationData} = resp.data.data.leads;
                    this.data = data;
                    this.paginationData = paginationData;
                } else {
                    console.log('Something went wrong!');
                }
            }).catch(e => console.log(e)).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>
<style scoped>
</style>
