<template>
    <div>
        <div class="grid grid-cols-3 max-w-[38rem] gap-2 p-3"
             :class="[darkMode ? 'bg-dark-module border-b border-dark-border' : 'bg-light-module border-b border-light-border']">
            <div class="relative">
                <input
                    class="w-full border rounded pl-8 text-sm focus:outline-none focus:border focus:border-primary-500 pr-4 py-2 h-9"
                    placeholder="Search" v-model="filters.query" type="text" v-on:keyup.enter="getActivities"

                    :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>
                <div class="absolute top-0 left-0 w-8 h-full flex justify-center items-center">
                    <svg class="inset-y-0 fill-current text-grey-400" width="14" height="14" viewBox="0 0 14 14"
                         fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M5.66667 2C4.69421 2 3.76158 2.38631 3.07394 3.07394C2.38631 3.76158 2 4.69421 2 5.66667C2 6.14818 2.09484 6.62498 2.27911 7.06984C2.46338 7.5147 2.73346 7.91891 3.07394 8.25939C3.41442 8.59987 3.81863 8.86996 4.26349 9.05423C4.70835 9.23849 5.18515 9.33333 5.66667 9.33333C6.14818 9.33333 6.62498 9.23849 7.06984 9.05423C7.5147 8.86996 7.91891 8.59987 8.25939 8.25939C8.59987 7.91891 8.86996 7.5147 9.05423 7.06984C9.23849 6.62498 9.33333 6.14818 9.33333 5.66667C9.33333 4.69421 8.94703 3.76158 8.25939 3.07394C7.57176 2.38631 6.63913 2 5.66667 2ZM1.65973 1.65973C2.72243 0.597022 4.16377 0 5.66667 0C7.16956 0 8.6109 0.597022 9.6736 1.65973C10.7363 2.72243 11.3333 4.16377 11.3333 5.66667C11.3333 6.41082 11.1868 7.14769 10.902 7.83521C10.7458 8.21219 10.5498 8.57029 10.3178 8.90361L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L8.90361 10.3178C8.57029 10.5498 8.21219 10.7458 7.83521 10.902C7.14769 11.1868 6.41082 11.3333 5.66667 11.3333C4.92251 11.3333 4.18564 11.1868 3.49813 10.902C2.81062 10.6172 2.18593 10.1998 1.65973 9.6736C1.13353 9.14741 0.716126 8.52272 0.431349 7.83521C0.146573 7.1477 0 6.41082 0 5.66667C0 4.16377 0.597022 2.72243 1.65973 1.65973Z"/>
                    </svg>
                </div>
            </div>
            <div>
                <Datepicker
                    :dark="darkMode"
                    v-model="dateRange"
                    range
                    placeholder="Date"
                    @update:modelValue="processDatePeriod"
                    :format="'yyyy-MM-dd'"
                />
            </div>
            <div class="flex items-center">
                <dropdown class="w-full inline-block"
                          :options="sortByOptions"
                          :dark-mode="darkMode"
                          :model-value="filters.sort_by"
                          @change="updateSortBy"
                />
            </div>
            <div class="col-span-2">
                <Autocomplete
                    :dark-mode="darkMode"
                    :input-class="[{'text-black': !darkMode}]"
                    :option-class="[{'text-white': darkMode}]"
                    v-model="filters.user_id"
                    :value="filters.user_id"
                    :options="users"
                    @search="searchUsers"
                    :placeholder="'Enter Name'"
                />
            </div>
            <div class="flex items-center">
                <button
                    class="max-w-[4rem] mr-2 transition duration-200 bg-primary-500 font-semibold hover:bg-blue-500 text-white text-sm h-9 justify-center flex items-center font-medium focus:outline-none py-2 rounded-md px-5"
                    @click="getActivities">
                    <svg class="inset-y-0 w-4 my-1 fill-current" width="14" height="14" viewBox="0 0 14 14" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M5.66667 2C4.69421 2 3.76158 2.38631 3.07394 3.07394C2.38631 3.76158 2 4.69421 2 5.66667C2 6.14818 2.09484 6.62498 2.27911 7.06984C2.46338 7.5147 2.73346 7.91891 3.07394 8.25939C3.41442 8.59987 3.81863 8.86996 4.26349 9.05423C4.70835 9.23849 5.18515 9.33333 5.66667 9.33333C6.14818 9.33333 6.62498 9.23849 7.06984 9.05423C7.5147 8.86996 7.91891 8.59987 8.25939 8.25939C8.59987 7.91891 8.86996 7.5147 9.05423 7.06984C9.23849 6.62498 9.33333 6.14818 9.33333 5.66667C9.33333 4.69421 8.94703 3.76158 8.25939 3.07394C7.57176 2.38631 6.63913 2 5.66667 2ZM1.65973 1.65973C2.72243 0.597022 4.16377 0 5.66667 0C7.16956 0 8.6109 0.597022 9.6736 1.65973C10.7363 2.72243 11.3333 4.16377 11.3333 5.66667C11.3333 6.41082 11.1868 7.14769 10.902 7.83521C10.7458 8.21219 10.5498 8.57029 10.3178 8.90361L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L8.90361 10.3178C8.57029 10.5498 8.21219 10.7458 7.83521 10.902C7.14769 11.1868 6.41082 11.3333 5.66667 11.3333C4.92251 11.3333 4.18564 11.1868 3.49813 10.902C2.81062 10.6172 2.18593 10.1998 1.65973 9.6736C1.13353 9.14741 0.716126 8.52272 0.431349 7.83521C0.146573 7.1477 0 6.41082 0 5.66667C0 4.16377 0.597022 2.72243 1.65973 1.65973Z"/>
                    </svg>
                </button>
                <button
                    class="max-w-[5rem] transition duration-200 h-9 text-sm font-semibold focus:outline-none py-2 rounded-md px-4"
                    :class="{'bg-grey-250 hover:bg-light-background text-white': !darkMode, 'bg-grey-500 hover:bg-grey-600 text-white': darkMode}"
                    @click="resetFilters">
                    Reset
                </button>
            </div>
            <slot name="extra-filters"></slot>
        </div>
        <div class="overflow-y-auto h-100 p-3" :class="[darkMode ? 'bg-dark-background' : 'bg-light-background']">
            <div class="w-full h-100 flex justify-center items-center" v-if="loading">
                <loading-spinner/>
            </div>
            <alerts-container v-if="alertActive" :text="alertText" :alert-type="alertType"/>
            <div v-if="!loading && activities.length"
                 class="w-full mb-4"
                 v-for="(activity, index) in activities" :key="index">
                <activity-item :activity="activity" :dark-mode="darkMode" :company-id="companyId"
                               @reload="getActivities"/>
            </div>
            <div v-if="paginationData && paginationData.to" class="p-3">
                <Pagination :dark-mode="darkMode" :pagination-data="paginationData" :show-pagination="true"
                            :show-total-records-detail="false" @change-page="handlePaginationEvent"></Pagination>
            </div>
            <div v-if="!loading && !activities.length" class="h-100 flex items-center justify-center text-center pb-4"
                 :class="{'text-grey-800': !darkMode, 'text-grey-120': darkMode}">
                {{ this.messages.emptyData }}
            </div>
        </div>
    </div>
</template>


<script>
import Api from "../../services/api";
import ActivityItem from "./activityItem/ActivityItem";
import LoadingSpinner from "../../../Shared/components/LoadingSpinner";
import AlertsMixin from "../../../../mixins/alerts-mixin";
import AlertsContainer from "../../../Shared/components/AlertsContainer";
import dayjs from "dayjs";
import Autocomplete from "../../../Shared/components/Autocomplete";
import SharedApiService from "../../../Shared/services/api";
import Pagination from "../../../Shared/components/Pagination";
import Dropdown from "../../../Shared/components/Dropdown";
import {mapActions, mapWritableState} from "pinia";
import {useActivityTabStore} from "../../../../../stores/activity-tab.store";

export default {
    name: "ActivityTab",
    components: {
        Dropdown,
        Pagination,
        Autocomplete,
        ActivityItem,
        LoadingSpinner,
        AlertsContainer
    },
    mixins: [AlertsMixin],
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        companyId: {
            type: Number,
            default: null
        },
        activityType: {
            type: String,
            default: null,
            validator: (s) => {
                return ['all', 'action', 'call', 'task'].includes(s);
            }
        }
    },
    data() {
        return {
            api: Api.make(),
            sharedApi: SharedApiService.make(),
            messages: {
                retrievalError: `Error getting Company ${this.activityType ?? ''} activities.`,
                emptyData: `No Activities found${this.activityType ? ' of ' + this.activityType + ' type' : ''}.`
            },
            dateRange: null,
            sortBy: 'updated_at',
            sortByOptions: [
                {id: 'updated_at', name: 'Last Updated'},
                {id: 'created_at', name: 'Created'},
            ],
        }
    },
    created() {
        this.getActivities();
    },
    watch: {
        companyId(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getActivities();
            }
        },
    },
    computed: {
        ...mapWritableState(useActivityTabStore, [
            'loading',
            'activities',
            'paginationData',
            'users',
            'filters'
        ])
    },
    methods: {
        ...mapActions(useActivityTabStore, [
            'addPaginatedData'
        ]),
        async getActivities() {
            try {
                await useActivityTabStore().getActivities(this.companyId, this.api);
            } catch (err) {
                this.showAlert('error', err.response?.data?.message || this.messages.retrievalError);
            }
        },
        resetFilters() {
            this.filters.query = null;
            this.filters.user_id = null;
            this.dateRange = null;
            this.updateDateFilters();
            this.getActivities();
        },
        processDatePeriod(datePeriod) {
            this.updateDateFilters(datePeriod
                ? datePeriod.map(date => dayjs(date).format('YYYY-MM-DD'))
                : null
            );
        },
        updateDateFilters(datePeriod = null) {
            this.filters.start_date = datePeriod && datePeriod[0]
                ? datePeriod[0]
                : null;
            this.filters.end_date = datePeriod && datePeriod[1]
                ? datePeriod[1]
                : null;
        },
        searchUsers(query) {
            this.sharedApi.searchUserByNamesAndId(query).then(resp => {
                if (resp.data.data.status) {
                    this.users = resp.data.data.users ?? [];
                }
            }).catch(e => console.log(e));
        },
        async handlePaginationEvent(newPageUrl) {
            this.loading = true;
            await axios.get(newPageUrl.link, {params: this.filters}).then(response => {
                this.addPaginatedData(response)
            }).catch(err => {
                this.showAlert('error', err.response?.data?.message || this.messages.retrievalError);
            }).finally(() => this.loading = false);
        },
        updateSortBy({id}) {
            if (this.filters.sort_by !== id) {
                this.filters.sort_by = id;
                this.getActivities();
            }
        },
    }
}
</script>

<style scoped>
.dp__theme_dark {
    --dp-background-color: rgb(13, 26, 38);
    --dp-hover-color: #484848;
    --dp-hover-text-color: #4D87C1;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #005cb2;
    --dp-primary-text-color: #4D87C1;
    --dp-secondary-color: #a9a9a9;
    --dp-border-color: rgb(0, 55, 95);
    --dp-menu-border-color: #2d2d2d;
    --dp-border-color-hover: #418FDB;
    --dp-disabled-color: #737373;
    --dp-scroll-bar-background: #212121;
    --dp-scroll-bar-color: #484848;
    --dp-success-color: #00701a;
    --dp-success-color-disabled: #428f59;
    --dp-icon-color: rgb(0, 129, 255);
    --dp-danger-color: #e53935;
}

.dp__theme_light {
    --dp-background-color: rgb(249, 249, 249);
    --dp-hover-color: #ECECEC;
    --dp-hover-text-color: #212121;
    --dp-hover-icon-color: #1976d2;
    --dp-border-color: rgb(202, 207, 211);
    --dp-icon-color: rgb(0, 129, 255);
}
</style>
