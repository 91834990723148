<template>
    <div class="relative">
        <div>
            <input
                :class="[darkMode ? 'text-slate-100 bg-dark-background border-dark-border focus:bg-dark-module hover:bg-dark-module' : 'focus:bg-light-module hover:bg-light-module text-slate-900 bg-light-background border-light-border', searchIcon ? 'pl-8' : '']"
                class="rounded relative text-sm font-medium inline-flex items-center w-full h-9 border px-3 focus:outline-none outline-none focus:ring-0 focus:border-primary-500 focus:shadow-lg focus:shadow-primary-500/10 transition duration-200"
                type="text"
                v-model="searchText"
                :placeholder="placeholder"
                @blur="blur"
                @focus="focus"
                @input="input"
                @change="change"
                @keyup.enter="enter"
                autocomplete="off"
                ref="autocompleteInput"
            >
            <span v-if="searchIcon">
                <svg class="absolute left-3 top-3 fill-current text-grey-400" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.66667 2C4.69421 2 3.76158 2.38631 3.07394 3.07394C2.38631 3.76158 2 4.69421 2 5.66667C2 6.14818 2.09484 6.62498 2.27911 7.06984C2.46338 7.5147 2.73346 7.91891 3.07394 8.25939C3.41442 8.59987 3.81863 8.86996 4.26349 9.05423C4.70835 9.23849 5.18515 9.33333 5.66667 9.33333C6.14818 9.33333 6.62498 9.23849 7.06984 9.05423C7.5147 8.86996 7.91891 8.59987 8.25939 8.25939C8.59987 7.91891 8.86996 7.5147 9.05423 7.06984C9.23849 6.62498 9.33333 6.14818 9.33333 5.66667C9.33333 4.69421 8.94703 3.76158 8.25939 3.07394C7.57176 2.38631 6.63913 2 5.66667 2ZM1.65973 1.65973C2.72243 0.597022 4.16377 0 5.66667 0C7.16956 0 8.6109 0.597022 9.6736 1.65973C10.7363 2.72243 11.3333 4.16377 11.3333 5.66667C11.3333 6.41082 11.1868 7.14769 10.902 7.83521C10.7458 8.21219 10.5498 8.57029 10.3178 8.90361L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L8.90361 10.3178C8.57029 10.5498 8.21219 10.7458 7.83521 10.902C7.14769 11.1868 6.41082 11.3333 5.66667 11.3333C4.92251 11.3333 4.18564 11.1868 3.49813 10.902C2.81062 10.6172 2.18593 10.1998 1.65973 9.6736C1.13353 9.14741 0.716126 8.52272 0.431349 7.83521C0.146573 7.1477 0 6.41082 0 5.66667C0 4.16377 0.597022 2.72243 1.65973 1.65973Z"/></svg>
            </span>
        </div>
        <div class="relative" v-show="options.length > 0 && showOptions">
            <div
                class="absolute left-0 max-h-56 divide-y divide-inherit z-50 overflow-y-auto rounded cursor-pointer top-0 w-full border shadow-module"
                :class="{'border-light-border bg-light-background': !darkMode, 'border-dark-border bg-dark-background': darkMode}">
                <p
                    :class="[!darkMode ? 'hover:bg-light-module' : 'hover:bg-dark-module', optionClass]"
                    class="py-3 px-3 capitalize text-sm font-medium transition duration-200"
                    v-if="createUserInputOption"  @click="selectUserOption(searchText)">{{ searchText }}</p>
                <p
                    :class="[!darkMode ? 'hover:bg-light-module' : 'hover:bg-dark-module', optionClass]"
                    class="py-3 px-3 capitalize text-sm font-medium transition duration-200"
                    v-for="option in options" @click="selectedValue = option">{{ option.name }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Autocomplete",
        props: {
            darkMode: {
              type: Boolean,
              default: false,
            },
            options: {
                type: Array,
                default: () => []
            },
            placeholder: {
                type: String,
                default: "Search"
            },
            inputClass: {
                type: Array,
                default: () => []
            },
            listClass: {
                type: Array,
                default: () => []
            },
            optionClass: {
                type: Array,
                default: () => []
            },
            minSearchInputLen: {
                type: Number,
                default: 2
            },
            debounceDelay: {
                type: Number,
                default: 500
            },
            modelValue: {
                type: [Number, String],
                default: 0
            },
            createUserInputOption: {
                type: Boolean,
                default: false
            },
            searchIcon: {
                type: Boolean,
                default: false,
            },
        },
        data: function() {
            return {
                showOptions: false,
                searchText: '',
                debouncer: null
            };
        },
        created() {
            if (this.modelValue) {
                const option = this.options.find(option => option.id === this.modelValue);

                if (option) this.selectedValue = option;
            }
        },
        computed: {
            selectedValue: {
                get() {
                    return this.modelValue;
                },
                set(option) {
                    this.searchText = option.name;
                    this.$emit('update:modelValue', option.id);
                }
            }
        },
        emits: ['update:modelValue', 'blur', 'change', 'search', 'enter'],
        methods: {
            input(e) {
                clearTimeout(this.debouncer);

                if(e.target.value.length >= this.minSearchInputLen) {
                    this.debouncer = setTimeout(() => {
                        this.showOptions = true;
                        this.$emit('search', e.target.value);
                    }, this.debounceDelay);
                }

                if(this.createUserInputOption) {
                    this.selectUserOption(e.target.value);
                }
            },
            focus() {
                this.showOptions = true;
            },
            blur() {
                this.$emit('blur');
                setTimeout(() => this.showOptions = false, 200);
            },
            change() {
                this.$emit('change');
            },
            enter() {
                this.$emit('enter');
            },
            selectUserOption(value) {
                this.selectedValue = {
                    id: value,
                    name: value
                };
            }
        },
        watch: {
            modelValue: {
                handler: function(newValue) {
                    if(typeof newValue === 'string') {
                        this.searchText = newValue;
                    }
                    else {
                        let selectedOption = this.options.find((option) => {
                            return option.id === newValue;
                        });

                        this.searchText = selectedOption?.name || '';
                    }
                },
                immediate: true
            }
        }
    }
</script>

<style scoped>

</style>
