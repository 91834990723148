<template>
    <div>
        <div class="main-layout font-body">
            <div class="w-full">
                <div class="w-full flex-auto pt-3 relative">
                    <loading-spinner v-if="loading" :dark-mode="darkMode"/>
                    <div class="px-10" :class="[darkMode ? 'text-white' : 'text-slate-900']">
                        <div class="flex items-center justify-between flex-wrap py-4">
                            <div class="flex justify-between items-center w-full py-2">
                                <h3 class="text-xl font-medium pb-0 leading-none mr-5">Sales Management</h3>
                            </div>
                        </div>
                        <div>
                            <div class="grid md:grid-cols-1 lg:grid-cols-7 gap-4">
                                <div class="lg:col-span-2">
                                    <account-managers-list
                                        @save-account-manager="createAccountManager($event.userId, $event.type, $event.includeInSalesToolRoundRobin)"
                                        @update-account-manager="updateAccountManager($event.id, $event.userId, $event.type, $event.includeInSalesToolRoundRobin)"
                                        @delete-account-manager="deleteAccountManager($event)"
                                        :types="data.account_manager_types"
                                        :account-managers="data.account_managers"
                                        :dark-mode="darkMode"
                                        :user-search-options="data.userSearchOptions"
                                        @change-user-search-options="data.userSearchOptions = $event"
                                        @search-users="searchUsers" />
                                </div>
                                <div  class="lg:col-span-2">
                                    <success-managers-list :dark-mode="darkMode" :api="api"></success-managers-list>
                                </div>
                                <div  class="lg:col-span-2">
                                    <support-officers-list :dark-mode="darkMode" :api="api"></support-officers-list>
                                </div>
                                <div  class="lg:col-span-4">
                                    <hunters-list :dark-mode="darkMode" :api="api"></hunters-list>
                                </div>
                            </div>
                            <task-category-editor :dark-mode="darkMode"></task-category-editor>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingSpinner from "../../Shared/components/LoadingSpinner";
import AccountManagersList from "../components/AccountManagersList";
import SuccessManagersList from "../components/SuccessManagersList";
import SupportOfficersList from "../components/SupportOfficersList";
import TaskCategoryEditor from "../../TaskCategoryEditor/TaskCategoryEditor";
import HuntersList from "../components/HuntersList";

export default {
    name: "Dashboard",
    data: function() {
        return {
            loading: false,
            data: {
                account_managers: [],
                account_manager_types: []
            }
        }
    },
    components: {
        LoadingSpinner,
        AccountManagersList,
        SuccessManagersList,
        SupportOfficersList,
        TaskCategoryEditor,
        HuntersList
    },
    props: ["api", "darkMode"],
    created() {
        this.updateData();
        this.getAccountManagerTypes();
    },
    mounted() {

    },
    methods: {
        updateData() {
            this.getAccountManagers();
        },
        getAccountManagers() {
            this.data.account_managers = [];

            this.api.getAccountManagers().then(resp => {
                if(resp.data.data.status === true) {
                    this.data.account_managers = resp.data.data.account_managers;
                }
            }).catch(e => {});
        },
        createAccountManager(userId, type, includeInSalesToolRoundRobin) {
            this.api.createAccountManager(userId, type, includeInSalesToolRoundRobin).then(resp => {
                this.getAccountManagers();
            })
        },
        updateAccountManager(accountManagerId, userId, type, includeInSalesToolRoundRobin) {
            this.api.updateAccountManager(accountManagerId, userId, type, includeInSalesToolRoundRobin).then((res) => {
                this.getAccountManagers();
            })
        },
        deleteAccountManager(accountManagerId) {
            this.api.deleteAccountManager(accountManagerId).then(
                (res) => {
                    if(res.data.data.status) {
                        this.getAccountManagers();
                    }
                }
            ).catch(() => {
                //TODO: Add Error Handling
            });
        },
        getAccountManagerTypes() {
            this.data.account_manager_types = [];

            this.api.getAccountManagerTypes().then(resp => {
                if(resp.data.data.status === true) {
                    this.data.account_manager_types = resp.data.data.account_manager_types;
                }
            }).catch(e => {});
        },
        searchUsers(query) {
            this.api.searchUserNames(query).then(
                (res) => {
                    if(res.data.data.status) {
                        this.data.userSearchOptions = res.data.data.users;
                    }
                    else {
                        //TODO: Report Error
                    }
                }
            ).catch(
                () => {
                    //TODO: Report Error
                }
            )
        },
    }
}
</script>

<style scoped>

</style>
