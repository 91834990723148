<template>
    <label :class="[darkMode ? 'text-slate-100' : 'text-slate-900']" v-if="label" class="block mb-1" :for="label.toLowerCase()">
        {{ label }}
    </label>
    <input
        :disabled="disabled"
        class="rounded text-sm font-medium w-full h-9 border focus:outline-none outline-none focus:ring-0 focus:border-primary-500 focus:shadow-lg focus:shadow-primary-500/10"
        :class="[darkMode ? 'text-slate-100 bg-dark-background border-dark-border' : 'text-slate-900 bg-light-background border-light-border', disabled ? 'opacity-25' : '']"
        :type="type"
        :value="modelValue"
        @input="updateInput"
        :placeholder="placeholder ? placeholder : label"
        :name="label.toLowerCase()"/>
</template>

<script>
export default {
    name: 'CustomInput',
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'text',
        },
        label: {
            type: String,
            default: '',
        },
        modelValue: {
            type: [String, Number],
            default: "",
        },
        placeholder: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        updateInput(event) {
            this.$emit("update:modelValue", event.target.value);
        }
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    }
}
</script>

<style scoped>

</style>
