require('./bootstrap');
import Alpine from 'alpinejs';

const {initializeApp, ADMIN_MOUNT_SELECTOR} = require('./vue/vue');

if (document.querySelector(ADMIN_MOUNT_SELECTOR)) {
    initializeApp();
} else {
    /**
     * @TODO: Breeze by default uses Alpine to scaffold it's default front-end logic.
     *        Once we upgrade this to use vue, we can safely remove Alpine.
     */
    window.Alpine = Alpine;
    Alpine.start();
}

/**
 * @typedef CompanyJsDocType
 * @property {number} companyid
 * @property {string} companyname
 * @property {?} sales_email
 * @property {string} link
 * @property {string} website
 * @property {string} shortdescription
 * @property {string} description
 * @property {string} linktologo
 * @property {number} officeinusa
 * @property {string} countryofmanufacture
 * @property {string} type
 * @property {string} products
 * @property {number} contactuserid
 * @property {number} timestampadded
 * @property {string} status
 * @property {number} timestampstatusupdated
 * @property {number} accountmanagerid
 * @property {number} salesconsultantid
 * @property {string} inboundenquiry
 * @property {string} enquirymethod
 * @property {string} initialcontactdate
 * @property {string} salesstatus
 * @property {string} buyingleadsfrom
 * @property {string} notes
 * @property {string} todo
 * @property {string} source
 * @property {string} crm
 * @property {number} crm_active
 * @property {string} crm_description
 * @property {string} facebook
 * @property {string} linkedin
 * @property {string} twitter
 * @property {string} yearstartedbusiness
 * @property {string} yearstartedsolar
 * @property {number} qualified
 * @property {number} timestamplastupdate
 * @property {number} solarestimateid
 * @property {string} othercertifications
 * @property {string} otherindustryassociations
 * @property {number} pricingplanid
 * @property {number} nextpricingplanid
 * @property {number} planmonths
 * @property {number} timestampnextinvoice
 * @property {number} autobilling
 * @property {string} payment_source
 * @property {number} arbsubscriptionid
 * @property {number} authorize_customerprofileid
 * @property {number} authorize_defaultpaymentprofileid
 * @property {number} trialgiven
 * @property {number} timestamplastprescreened
 * @property {number} buyingsolarestimateleads
 * @property {number} leadbudgetlimit
 * @property {number} timestampbudgetstart
 * @property {number} timestamppressrelease
 * @property {number} timestampreviewssnapshot
 * @property {string} reference
 * @property {?} registration_reference
 * @property {number} familybusiness
 * @property {string} rectanglelinktologo
 * @property {number} enable_multi_campaign
 * @property {number} enable_lead_compliance_jornaya
 * @property {number} enable_watchdog_compliance_links
 * @property {number} allow_lead_sales_without_cc
 * @property {number} valid_payment_method
 * @property {?} tech_support_email
 * @property {number} is_phone_required_for_review
 * @property {?} charge_attempts
 * @property {?} last_processed_date
 * @property {?} roofing_calculator_id
 * @property {?} companylegalentityname
 * @property {?} licenses
 * @property {number|null} revenue_in_thousands
 * @property {number|null} estimated_revenue
 * @property {number|null} employee_count
 * @property {number|null} google_review_count
 * @property {number|null} google_rating
 * @property {?} imported
 * @property {?} import_id
 * @property {?} temporary_link
 * @property {number} never_exceed_budget
 * @property {number} disallow_ranking
 * @property {?} watchdog_id
 * @property {number} contacts_count
 * @property {number} contacts_with_email_count
 * @property {number} contacts_with_phone_count
 */

/**
 * @typedef {"greaterThan"|"lessThan"|"greaterThanOrEqualTo"|"lessThanOrEqualTo"|"equalTo"} OperatorJSDocType
 */
