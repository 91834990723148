import axios from 'axios';

export default class ApiService {
    constructor(baseUrl, baseEndpoint, version) {
        this.baseEndpoint = baseEndpoint;
        this.baseUrl = baseUrl;
        this.version = version;
    }

    axios() {
        const axiosConfig = {
            baseURL: `/${this.baseUrl}/v${this.version}/${this.baseEndpoint}`
        }

        return axios.create(axiosConfig);
    }

    static make() {
        return new ApiService('internal-api', 'companies', 1);
    }

    getCampaigns(companyId, status, perPage) {
        return this.axios().get(`/${companyId}/campaigns`, {
            params: {
                status: status,
                per_page: perPage
            }
        });
    }

    getRevenueOverview(companyId) {
        return this.axios().get(`/${companyId}/revenue-overview`);
    }

    getRevenueGraph(companyId, period, duration) {
        return this.axios().get(`/${companyId}/revenue-graph-data`, {
            params: {period, duration}
        })
    }

    getLeadsOverview(companyId, startTimestamp) {
        return this.axios().get(`/${companyId}/leads-overview`, {
            params: {
                start_timestamp: startTimestamp
            }
        });
    }

    getCompanyProfileData(companyId) {
        return this.axios().get(`/${companyId}/profile-data`);
    }

    getCompanyTypesData($companyId) {
        return this.axios().get('/company-types');
    }

    updateCompanyDetails(companyId, companyDetails) {
        return this.axios().patch(`/${companyId}`, companyDetails);
    }

    updateCompanyConfigurableFields(companyId, data) {
        return this.axios().patch(`/${companyId}/configurable-fields`, data);
    }

    deleteCampaign(companyId, campaignUuid) {
        return this.axios().delete(`/${companyId}/campaigns/${campaignUuid}`);
    }

    getServices(companyId) {
        return this.axios().get(`/${companyId}/services`);
    }

    getCompanyActivityFeed(companyId, params = {}) {
        if (!(params instanceof Object)) {
            throw new TypeError(`"params" must be an object.`);
        }

        return this.axios().get(`/${companyId}/activities`, {
            params: params
        });
    }

    getCompanyActivityFeedOverview(companyId, params = {}) {
        if (!(params instanceof Object)) {
            throw new TypeError(`"params" must be an object.`);
        }

        return this.axios().get(`/${companyId}/activities/overview`, {
            params
        });
    }

    getActivityConversations(companyId, activityId) {
        return this.axios().get(`/${companyId}/activities/${activityId}/conversations`);
    }

    saveActivityConversation(companyId, activityId, payload) {
        return this.axios().post(`/${companyId}/activities/${activityId}/conversation`, payload);
    }

    getSmsHistory(externalPhoneNumber, companyId) {
        return this.axios().get(`/${companyId}/activities/sms-history/${externalPhoneNumber}`);
    }

}
