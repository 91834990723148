<template>
    <div class="border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="p-5 flex items-center justify-between">
            <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Success Managers List</h5>
            <button @click="openModal"
                    class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5">
                Add Success Manager
            </button>
        </div>
        <div class="grid grid-cols-7 gap-x-3 mb-2 px-5">
            <p class="text-slate font-medium-400 uppercase text-xs col-span-2">User</p>
            <p class="text-slate font-medium-400 uppercase text-xs col-span-2">Type</p>
            <p class="text-slate font-medium-400 uppercase text-xs col-span-2">ST Round Robin</p>
            <p class="text-slate font-medium-400 uppercase text-xs"></p>
        </div>
        <div class="border-t border-b h-64 overflow-y-auto"
             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
            <div class="h-full">
                <div class="flex items-center h-full justify-center" v-if="loading">
                    <loading-spinner></loading-spinner>
                </div>
                <div class="grid grid-cols-7 gap-x-3 border-b px-5 py-3"
                     v-else
                     v-for="successManager in successManagers" :key="successManager.id"
                     :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <p class="text-sm col-span-2 truncate">
                        {{ successManager.name }}
                    </p>
                    <p class="text-sm col-span-2 truncate">
                        {{ successManager.type }}
                    </p>
                    <p class="text-sm col-span-2 pl-1 truncate">
                        {{ successManager.include_in_sales_round_robin }}
                    </p>
                    <ActionsHandle :dark-mode="darkMode" @edit="editSuccessManager(successManager)" @delete="deleteSuccessManager(successManager)"/>
                </div>
            </div>
        </div>
        <div class="p-3"></div>
        <Modal :dark-mode="darkMode" :small="true" @close="closeModal" close-text="Cancel"
               @confirm="saveSuccessManager"
               :confirm-text="confirmText" v-if="showModal">
            <template v-slot:header>
                <h4 class="text-xl font-medium">{{ successManager.id ? `Edit ${successManager.name}` : 'Add Success Manager' }}</h4>
            </template>
            <template v-slot:content>
                <div class="px-6">
                    <div class="mb-6" v-if="!successManager.id">
                        <label class="block pb-2 font-medium">User</label>
                        <Autocomplete
                            :input-class="['w-full border border-grey-350 rounded px-4 py-3', {'border-grey-350 bg-light-module': !darkMode, 'border-blue-400 bg-dark-background text-blue-400': darkMode}]"
                            :list-class="['absolute top-0 w-full border rounded', {'border-grey-200 bg-light-module': !darkMode, 'border-dark-40 bg-dark-module': darkMode}]"
                            :option-class="['cursor-pointer px-5 py-2 my-1', {'hover:bg-grey-100': !darkMode, 'hover:bg-blue-800': darkMode}]"
                            v-model="successManager.user_id"
                            :value="successManager.user_id"
                            :options="userOptions"
                            @search="searchUsers"
                            :placeholder="'Enter Name'">
                        </Autocomplete>
                    </div>
                    <div class="mb-5 grid grid-cols-2 gap-2">
                        <div>
                            <p class="block pb-2 font-medium">Choose a type</p>
                            <Dropdown :dark-mode="darkMode" :options="successManagerTypes" v-model="successManager.type"
                                      :selected="successManager.type"/>
                        </div>
                    </div>
                    <div class="mb-5 grid grid-cols-2 gap-2">
                        <div>
                            <p class="block pb-2 font-medium">Include in sales tool round robin?</p>
                            <Dropdown :dark-mode="darkMode" :options="roundRobinTypes" v-model="successManager.include_in_sales_round_robin"
                                      :selected="successManager.include_in_sales_round_robin"/>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
        <alerts-container :text="alertText" :alert-type="alertType" v-if="alertActive" :dark-mode="darkMode"/>
    </div>
</template>

<script>
import Modal from "../../Shared/components/Modal";
import Dropdown from "../../Shared/components/Dropdown";
import ActionsHandle from "../../Shared/components/ActionsHandle";
import Autocomplete from "../../Shared/components/Autocomplete";
import LoadingSpinner from "../../Shared/components/LoadingSpinner";
import HasAlertsMixin, {AlertTypes} from "../../Shared/mixins/has-alerts-mixin";
import AlertsContainer from "../../Shared/components/AlertsContainer";

export default {
    name: "SuccessManagersList",
    components: {AlertsContainer, Modal, Dropdown, ActionsHandle, Autocomplete, LoadingSpinner},
    props: ["api", "darkMode"],
    mixins: [HasAlertsMixin],
    data() {
        return {
            successManagers: [],
            loading: false,
            successManager: {
                user_id: null,
                type: 0,
                include_in_sales_round_robin: 0
            },
            showModal: false,
            roundRobinTypes: [
                {id: 0, name: "No"},
                {id: 1, name: "Yes"}
            ],
            successManagerTypes: [],
            userOptions: [],
            saving: false
        }
    },
    created() {
        this.getSuccessManagers();
        this.getSuccessManagerTypes();
    },
    computed: {
        confirmText: function () {
            if (this.saving) return 'Saving...';
            if (this.successManager.id) return 'Update';

            return 'Save';
        }
    },
    methods: {
        getSuccessManagers() {
            this.loading = true;

            this.api.getSuccessManagers().then(resp => {
                if (resp.data.data.status === true) {
                    this.successManagers = resp.data.data.success_managers;
                    return;
                }
                this.showStatusError();
            })
            .catch((e) => this.showAlert(AlertTypes.error, e.response.data.message))
            .finally(() => this.loading = false);
        },
        saveSuccessManager() {
            this.saving = true;

            if (this.successManager.id) {
                this.updateSuccessManager();
                return;
            }
            this.createSuccessManager();
        },
        createSuccessManager() {
            this.api.createSuccessManager(this.successManager).then((resp) => {
                if (resp.data.data.status === true) {
                    this.getSuccessManagers();
                    this.closeModal();

                    this.showAlert(AlertTypes.success, 'Success Manager created.');
                    return;
                }
                this.showStatusError();
            })
            .catch(e => this.showAlert(AlertTypes.error, e.response.data.message))
            .finally(() => this.saving = false);
        },
        editSuccessManager(successManager) {
            this.successManager = {...successManager};
            this.successManager.type                         = this.successManager.type === "Senior" ? 1 : 0;
            this.successManager.include_in_sales_round_robin = this.successManager.include_in_sales_round_robin === "Yes" ? 1 : 0;
            this.openModal();
        },
        updateSuccessManager() {
            this.api.updateSuccessManager(this.successManager.id, this.successManager).then((resp) => {
                if (resp.data.data.status === true) {
                    this.getSuccessManagers();
                    this.closeModal();

                    this.showAlert(AlertTypes.success, 'Success Manager updated.');
                    return;
                }
                this.showStatusError();
            })
            .catch(e => this.showAlert(AlertTypes.error, e.response.data.message))
            .finally(() => this.saving = false);
        },
        deleteSuccessManager(successManager) {
            this.api.deleteSuccessManager(successManager.id).then((resp) => {
                if (resp.data.data.status === true) {
                    this.getSuccessManagers();

                    this.showAlert(AlertTypes.success, 'Success Manager deleted.');
                    return;
                }
                this.showStatusError();
            })
            .catch(e => this.showAlert(AlertTypes.error, e.response.data.message));
        },
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal      = false;
            this.successManager = {user_id: null, type: 0, include_in_sales_round_robin: 0};
        },
        getSuccessManagerTypes() {
            this.api.getSuccessManagerTypes().then(resp => {
                if (resp.data.data.status === true) {
                    this.successManagerTypes = resp.data.data.success_manager_types;
                    return;
                }
                this.showStatusError();
            })
            .catch((e) => this.showAlert(AlertTypes.error, e.response.data.message));
        },
        searchUsers(query) {
            this.api.searchUserNames(query).then(resp => this.userOptions = resp.data.data.users);
        },
        showStatusError() {
            this.showAlert(AlertTypes.error, "The status was unsuccessful.");
        }
    }
}
</script>

