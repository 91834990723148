<template>
    <div v-if="showing">
        <div v-if="loading">
            <loading-spinner :dark-mode="darkMode" />
        </div>
        <div v-if="!loading && initCompany !== null">
            <company-page
                :dark-mode='darkMode'
                :init-company="initCompany"
                :account-managers="accountManagers"
                :success-managers="successManagers"
                :tab="null"
            />
        </div>
    </div>
</template>

<script>
import DispatchesGlobalEventsMixin from "../../mixins/dispatches-global-events-mixin";
import SharedApiService from "../Shared/services/api";
import LoadingSpinner from "../LeadProcessing/components/LoadingSpinner.vue";
import CompanyPage from "./CompanyPage.vue";

export default {
    name: "GlobalCompanyWrapper",
    mixins: [DispatchesGlobalEventsMixin],
    components: {CompanyPage, LoadingSpinner},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            api: SharedApiService.make(),
            showing: false,
            companyId: null,
            loading: false,
            initCompany: null,
            accountManagers: null,
            successManagers: null,
        }
    },
    mounted() {
        this.initialize()
    },
    methods: {
        initialize() {
            this.listenForGlobalEvent('load-company-page', ({companyId}) => {
                this.showing = true;
                this.companyId = companyId;
                this.loadCompany();
            });
        },
        loadCompany() {
            this.loading = true;

            this.api.getProfile(this.companyId).then(resp => {
                this.initCompany = resp.data.data.company;
                this.accountManagers = resp.data.data.account_managers;
                this.successManagers = resp.data.data.success_managers;
            }).catch(e => {console.error(`Error loading company: ${e}`)}).finally(() => this.loading = false);
        }
    }
}
</script>

<style scoped>

</style>
