<template>
    <div class="mt-4 flex flex-col">
        <div class="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
            <nav class="flex pl-8 py-4" :class="[!darkMode ? 'bg-light-module' : 'bg-dark-module']">
                <ol role="list" class="flex items-center space-x-4 text-lg">
                    <li>
                        <div>
                            <p @click="changeScope('country')" class="font-bold text-sm cursor-pointer"
                               :class="[!darkMode ? ((currentScope === 'country') ? 'text-blue-550' : 'text-gray-500 hover:text-gray-900') : ((currentScope === 'country') ? 'text-blue-550' : 'text-gray-500 hover:text-white')]">All States</p>
                        </div>
                    </li>
                    <li v-if="currentScope !== 'country'">
                        <div class="flex items-center">
                            <ChevronRightIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"/>
                            <p @click="changeScope('state', currentState)"
                               class="ml-4 cursor-pointer font-bold text-sm"
                               :class="[!darkMode ? ((currentScope === 'state') ? 'text-blue-550' : 'text-gray-500 hover:text-gray-900') : ((currentScope === 'state') ? 'text-blue-550' : 'text-gray-500 hover:text-white')]">{{ currentState }}</p>
                        </div>
                    </li>
                    <li v-if="currentScope === 'county'">
                        <div class="flex items-center">
                            <ChevronRightIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"/>
                            <p class="ml-4 cursor-pointer font-bold text-sm"
                               :class="[!darkMode ? ((currentScope === 'county') ? 'text-blue-550' : 'text-gray-500 hover:text-gray-900') : ((currentScope === 'county') ? 'text-blue-550' : 'text-gray-500 hover:text-white')]">{{ currentCounty }}</p>
                        </div>
                    </li>
                </ol>
            </nav>
            <div class="w-full overflow-x-auto" style="max-height: 60vh;">
                <div class="shadow-sm ring-1 ring-black ring-opacity-5">
                    <table v-if="reportMode === 'single'" class="min-w-full divide-y" :class="[!darkMode ? 'divide-gray-300' : 'divide-dark-175']">
                        <thead>
                        <tr class="divide-x" :class="[!darkMode ? 'divide-gray-200' : 'divide-dark-175']" v-if="currentScope !== 'county'">
                            <th @click="sortTable('name')" scope="col"
                                class="sticky left-0 top-0 whitespace-nowrap z-30 border-b backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    {{ currentScope === 'country' ? 'State' : 'County' }}
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('company_count')" scope="col"
                                class="sticky top-0 whitespace-nowrapwhitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    Active<br>Companies
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('avail_budget_company_count')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    Avail Budget<br>Companies
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('no_limit_company_count')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    No Limit<br>Companies
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('avg_dollars_no_reject')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    {{ currentScope === 'country' ? 'Avg Avail County Dollar Budget' : 'Avg Avail Dollar Budget' }}
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('avg_volume_no_reject')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    {{ currentScope === 'country' ? 'Avg Avail County Volume Budget' : 'Avg Avail Volume Budget' }}
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('zips_w_no_active_cam')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    Zips w/ No Active Cam
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('zips_w_active_cam')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    Zips w/ Active Cam
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('zips_w_no_limit_budget')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    Zips w/ No Limit Cam
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('zips_w_1_cam')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    Zips w/ 1 Cam
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('zips_w_2_cams')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    Zips w/ 2 Cams
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('zips_w_3_cams')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    Zips w/ 3 Cams
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('zips_w_4_plus_cams')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    Zips w/ 4+ Cams
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                        </tr>
                        <tr v-else>
                            <th scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    Zip Code
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('company_ids')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    Active<br>Companies
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('cam_ids')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    Active<br>Campaigns
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('avail_budget_company_count')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    Avail Budget<br>Companies
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('avail_budget_cam_ids')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    Avail Budget<br>Campaigns
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('no_limit_company_count')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    No Limit<br>Companies
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('no_limit_campaign_ids')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    No Limit<br>Campaigns
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('dollars_no_reject')" cope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    Dollars Available
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('volume_no_reject')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    Volume Available
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                        </tr>
                        </thead>
                        <tbody class="divide-y " :class="[!darkMode ? 'divide-gray-200 bg-light-background' : 'divide-dark-175 bg-dark-background']">
                        <tr class="divide-x" :class="[!darkMode ? 'divide-gray-200' : 'divide-dark-175']" v-if="currentScope === 'country'" v-for="state in sortedStates">
                            <td class="whitespace-nowrap left-0 sticky py-4 bg-opacity-50 pl-8 pr-3 text-sm font-medium sm:pl-8 cursor-pointer" :class="[!darkMode ? 'text-gray-600 hover:text-gray-900 bg-light-module' : 'text-grey-300 hover:text-white bg-dark-module']"
                                @click="changeScope('state', state.name)">{{ state.name }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                <a href="#" @click="showCompanyDetails(state.company_ids)">{{ state.company_ids ? state.company_ids.length : 0 }}</a><br>
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                <a href="#" @click="showCompanyDetails(state.avail_budget_company_ids)">{{ state.avail_budget_company_ids ? state.avail_budget_company_ids.length : 0 }}</a><br>
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                <a href="#" @click="showCompanyDetails(state.no_limit_company_ids)">{{ state.no_limit_company_ids ? state.no_limit_company_ids.length : 0 }}</a>
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                ${{ formatNumber(state.avg_dollars_no_reject) }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                {{ formatNumber(state.avg_volume_no_reject) }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm cursor-pointer" :class="[!darkMode ? 'text-gray-600 hover:text-gray-900' : 'text-grey-300 hover:text-white']"
                                @click="downloadZipsInState(state.name, false,0)">
                                {{ formatNumber(state.zips_w_no_active_cam) }} / {{ formatNumber(state.zip_count) }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm cursor-pointer" :class="[!darkMode ? 'text-gray-600 hover:text-gray-900' : 'text-grey-300 hover:text-white']"
                                @click="downloadZipsInState(state.name, false)" >
                                {{ formatNumber(state.zips_w_active_cam) }} / {{ formatNumber(state.zip_count) }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm cursor-pointer" :class="[!darkMode ? 'text-gray-600 hover:text-gray-900' : 'text-grey-300 hover:text-white']"
                                @click="downloadZipsInState(state.name, true)" >
                                {{ formatNumber(state.zips_w_no_limit_budget) }} / {{ formatNumber(state.zip_count) }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm cursor-pointer" :class="[!darkMode ? 'text-gray-600 hover:text-gray-900' : 'text-grey-300 hover:text-white']"
                                @click="downloadZipsInState(state.name, false, 1)">
                                {{ formatNumber(state.zips_w_1_cam) }} / {{ formatNumber(state.zip_count) }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm cursor-pointer" :class="[!darkMode ? 'text-gray-600 hover:text-gray-900' : 'text-grey-300 hover:text-white']"
                                @click="downloadZipsInState(state.name, false, 2)">
                                {{ formatNumber(state.zips_w_2_cams) }} / {{ formatNumber(state.zip_count) }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm cursor-pointer" :class="[!darkMode ? 'text-gray-600 hover:text-gray-900' : 'text-grey-300 hover:text-white']"
                                @click="downloadZipsInState(state.name, false, 3)">
                                {{ formatNumber(state.zips_w_3_cams) }} / {{ formatNumber(state.zip_count) }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm cursor-pointer" :class="[!darkMode ? 'text-gray-600 hover:text-gray-900' : 'text-grey-300 hover:text-white']"
                                @click="downloadZipsInState(state.name, false, 4)">
                                {{ formatNumber(state.zips_w_4_plus_cams) }} / {{ formatNumber(state.zip_count) }}
                            </td>
                        </tr>
                        <tr class="divide-x" :class="[!darkMode ? 'divide-gray-200' : 'divide-dark-175']" v-else-if="currentScope === 'state'" v-for="county in sortedCounties">
                            <td class="whitespace-nowrap left-0 sticky py-4 bg-opacity-50 pl-8 pr-3 text-sm font-medium sm:pl-8 cursor-pointer" :class="[!darkMode ? 'text-gray-600 hover:text-gray-900 bg-light-module' : 'text-grey-300 hover:text-white bg-dark-module']"
                                @click="changeScope('county', county.name)">{{ county.name }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                <a href="#" @click="showCompanyDetails(county.company_ids)">{{ county.company_ids ? county.company_ids.length : 0 }}</a><br>
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                <a href="#" @click="showCompanyDetails(county.avail_budget_company_ids)">{{ county.avail_budget_company_ids ? county.avail_budget_company_ids.length : 0 }}</a>
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                <a href="#" @click="showCompanyDetails(county.no_limit_company_ids)">{{ county.no_limit_company_ids ? county.no_limit_company_ids.length : 0 }}</a><br>
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                ${{ formatNumber(county.avg_dollars_no_reject) }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                {{ formatNumber(county.avg_volume_no_reject) }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm text-gray-500 hover:text-gray-900 cursor-pointer"
                                @click="downloadZipsInCounty(county.name, false,0)">
                                {{ formatNumber(county.zips_w_no_active_cam) }} / {{ formatNumber(county.zip_count) }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm text-gray-500 hover:text-gray-900 cursor-pointer"
                                @click="downloadZipsInCounty(county.name, false)">
                                {{ formatNumber(county.zips_w_active_cam) }} / {{ formatNumber(county.zip_count) }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm text-gray-500 hover:text-gray-900 cursor-pointer"
                                @click="downloadZipsInCounty(county.name, true)">
                                {{ formatNumber(county.zips_w_no_limit_budget) }} / {{ formatNumber(county.zip_count) }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm text-gray-500 hover:text-gray-900 cursor-pointer"
                                @click="downloadZipsInCounty(county.name, false, 1)">
                                {{ formatNumber(county.zips_w_1_cam) }} / {{ formatNumber(county.zip_count) }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm text-gray-500 hover:text-gray-900 cursor-pointer"
                                @click="downloadZipsInCounty(county.name, false, 2)">
                                {{ formatNumber(county.zips_w_2_cams) }} / {{ formatNumber(county.zip_count) }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm text-gray-500 hover:text-gray-900 cursor-pointer"
                                @click="downloadZipsInCounty(county.name, false, 3)">
                                {{ formatNumber(county.zips_w_3_cams) }} / {{ formatNumber(county.zip_count) }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm text-gray-500 hover:text-gray-900 cursor-pointer"
                                @click="downloadZipsInCounty(county.name, false, 4)">
                                {{ formatNumber(county.zips_w_4_plus_cams) }} / {{ formatNumber(county.zip_count) }}
                            </td>
                        </tr>
                        <tr class="divide-x" :class="[!darkMode ? 'divide-gray-200' : 'divide-dark-175']" v-else v-for="zipData in sortedZips">
                            <td class="whitespace-nowrap left-0 sticky py-4 bg-opacity-50 pl-8 pr-3 text-sm font-medium sm:pl-8 cursor-pointer" :class="[!darkMode ? 'text-gray-600 bg-light-module' : 'text-grey-300 bg-dark-module']">
                                {{zipData.zip}}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                <a href="#" @click="showCompanyDetails(zipData.company_ids)">{{ zipData.company_ids ? zipData.company_ids.length : 0 }}</a>
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                {{ zipData.cam_ids ? zipData.cam_ids.length : 0 }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                <a href="#" @click="showCompanyDetails(zipData.avail_budget_company_ids)">{{ zipData.company_ids ? zipData.company_ids.length : 0 }}</a>
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                {{ zipData.avail_budget_cam_ids ? zipData.avail_budget_cam_ids.length : 0 }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                <a href="#" @click="showCompanyDetails(zipData.no_limit_company_ids)">{{ zipData.no_limit_company_ids ? zipData.no_limit_company_ids.length : 0 }}</a>
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                {{ zipData.no_limit_cam_ids.length }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                ${{ zipData.dollars_no_reject }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                {{ zipData.volume_no_reject }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table v-else class="min-w-full divide-y" :class="[!darkMode ? 'divide-gray-300' : 'divide-dark-175']">
                        <thead>
                        <tr class="divide-x" :class="[!darkMode ? 'divide-gray-200' : 'divide-dark-175']" v-if="currentScope !== 'county'">
                            <th @click="sortTable('name')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    {{ currentScope === 'country' ? 'State' : 'County' }}
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('new_company_count')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    New Active<br>Companies
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('lost_company_count')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    Lost Active<br>Companies
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('new_avail_budget_company_count')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    New Avail<br>Budget Companies
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('lost_avail_budget_company_count')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    Lost Avail<br>Budget Companies
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('new_no_limit_company_count')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    New No Limit<br>Companies
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('lost_no_limit_company_count')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    Lost No Limit<br>Companies
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('avg_dollars_no_reject_change')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    {{ currentScope === 'country' ? 'Avg Avail County Dollar Budget' : 'Avg Avail Dollar Budget' }}
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                            <th @click="sortTable('avg_volume_no_reject_change')" scope="col"
                                class="sticky top-0 whitespace-nowrap z-10 border-b bg-opacity-75 backdrop-blur-2xl py-3.5 pl-8 pr-3 text-left text-sm font-semibold backdrop-filter" :class="[!darkMode ? 'border-gray-300 bg-light-module text-gray-900' : 'border-dark-border bg-dark-module text-grey-100']">
                                <a href="#" class="group inline-flex whitespace-nowrap">
                                    {{ currentScope === 'country' ? 'Avg Avail County Volume Budget' : 'Avg Avail Volume Budget' }}
                                    <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                </a>
                            </th>
                        </tr>
                        </thead>
                        <tbody class="divide-y" :class="[!darkMode ? 'divide-gray-200 bg-light-background' : 'divide-dark-175 bg-dark-background']">
                        <tr class="divide-x"  :class="[!darkMode ? 'divide-gray-200' : 'divide-dark-175']" v-if="currentScope === 'country'" v-for="state in sortedStates">
                            <td class="whitespace-nowrap py-4 pl-8 pr-3 text-sm font-medium cursor-pointer"  :class="[!darkMode ? 'text-gray-600 hover:text-gray-900' : 'text-grey-300 hover:text-white']"
                                @click="changeScope('state', state.name)">{{ state.name }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-900' : 'text-grey-100']">
                                <a href="#" @click="showCompanyDetails(state.new_company_ids)">{{ state.new_company_count }}</a><br>
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                <a href="#" @click="showCompanyDetails(state.lost_company_ids)">{{ state.lost_company_count }}</a><br>
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                <a href="#" @click="showCompanyDetails(state.new_avail_budget_company_ids)">{{ state.new_avail_budget_company_count }}</a><br>
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                <a href="#" @click="showCompanyDetails(state.lost_avail_budget_company_ids)">{{ state.lost_avail_budget_company_count }}</a><br>
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                <a href="#" @click="showCompanyDetails(state.new_no_limit_company_ids)">{{ state.new_no_limit_company_count }}</a>
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                <a href="#" @click="showCompanyDetails(state.lost_no_limit_company_ids)">{{ state.lost_no_limit_company_count }}</a>
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                ${{ formatNumber(state.avg_dollars_no_reject_change) }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                {{ formatNumber(state.avg_volume_no_reject_change) }}
                            </td>
                        </tr>
                        <tr class="divide-x"  :class="[!darkMode ? 'divide-gray-200' : 'divide-dark-175']" v-else-if="currentScope === 'state'" v-for="county in sortedCounties">
                            <td class="whitespace-nowrap py-4 pl-8 pr-3 text-sm font-medium cursor-pointer" :class="[!darkMode ? 'text-gray-600 hover:text-gray-900' : 'text-grey-300 hover:text-white']"
                                @click="changeScope('county', county.name)">{{ county.name }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                <a href="#" @click="showCompanyDetails(county.new_company_ids)">{{ county.new_company_count }}</a><br>
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                <a href="#" @click="showCompanyDetails(county.lost_company_ids)">{{ county.lost_company_count }}</a><br>
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                <a href="#" @click="showCompanyDetails(county.new_avail_budget_company_ids)">{{ county.new_avail_budget_company_count }}</a>
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                <a href="#" @click="showCompanyDetails(county.lost_avail_budget_company_ids)">{{ county.lost_avail_budget_company_count }}</a>
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                <a href="#" @click="showCompanyDetails(county.new_no_limit_company_ids)">{{ county.new_no_limit_company_count }}</a><br>
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                <a href="#" @click="showCompanyDetails(county.lost_no_limit_company_ids)">{{ county.lost_no_limit_company_count }}</a><br>
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                ${{ formatNumber(county.avg_dollars_no_reject_change) }}
                            </td>
                            <td class="whitespace-nowrap pl-8  py-4 text-sm" :class="[!darkMode ? 'text-gray-500' : 'text-grey-300']">
                                {{ formatNumber(county.avg_volume_no_reject_change) }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ChevronDownIcon, ChevronRightIcon} from '@heroicons/vue/solid'

export default {
    name: "AvailableBudgetTable",
    components: {
        ChevronDownIcon,
        ChevronRightIcon
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        states: {
            type: Object,
            default: null
        },
        reportMode: {
            type: String,
            default: 'single'
        }
    },
    data: function () {
        return {
            currentScope: 'country',
            currentState: null,
            currentCounty: null,
            sortedStates: null,
            sortedCounties: null,
            sortedZips: null
        }
    },
    created() {
        this.sortTable();
    },
    methods: {
        formatNumber(value) {
            return value ? value.toLocaleString("en-US") : 0;
        },
        sortTable(sortValue = 'name') {

            let sortedLocations = [];
            let valueIndex = [];
            let arrayToSort = [];
            let data = [];
            let indexes = [];
            let usedIndexes = [];

            switch(this.currentScope) {
                case 'county':
                    Object.entries(this.states[this.currentState].counties[this.currentCounty].zips).forEach(function(item, index) {
                        valueIndex.push(item[1][sortValue]);
                        arrayToSort.push(item[1][sortValue]);
                        data.push(item[1]);
                    });

                    break;
                case 'state':
                    Object.entries(this.states[this.currentState].counties).forEach(function(item, index) {
                        valueIndex.push(item[1].summary[sortValue]);
                        arrayToSort.push(item[1].summary[sortValue]);
                        data.push(item[1].summary);
                    });
                    break;
                case 'country':
                default:
                    Object.entries(this.states).forEach(function(item, index) {
                        valueIndex.push(item[1].summary[sortValue]);
                        arrayToSort.push(item[1].summary[sortValue]);
                        data.push(item[1].summary);
                    });
                    break;
            }

            switch(sortValue) {
                case 'avg_dollars':
                case 'avg_dollars_no_reject':
                case 'avg_dollars_no_reject_change':
                case 'avg_volume':
                case 'avg_volume_no_reject':
                case 'avg_volume_no_reject_change':
                case 'zips_w_no_active_cam':
                case 'zips_w_active_cam':
                case 'zips_w_1_cam':
                case 'zips_w_2_cams':
                case 'zips_w_3_cams':
                case 'zips_w_4_plus_cams':
                case 'zips_w_no_limit_budget':
                case 'zips_w_1_no_limit_budget':
                case 'zips_w_2_no_limit_budgets':
                case 'zips_w_3_no_limit_budgets':
                case 'zips_w_4_plus_no_limit_budgets':
                case 'avail_budget_company_count':
                case 'new_avail_budget_company_count':
                case 'lost_avail_budget_company_count':
                case 'company_count':
                case 'new_company_count':
                case 'lost_company_count':
                case 'no_limit_company_count':
                case 'new_no_limit_company_count':
                case 'lost_no_limit_company_count':
                    arrayToSort.sort((a,b) => b - a);
                    break;
                case 'name':
                case 'company_ids':
                case 'cam_ids':
                case 'no_limit_cam_ids':
                default:
                    arrayToSort.sort();
                    break;
            }

            arrayToSort.forEach(function (item, index) {
                for (let index = 0; index < valueIndex.length; index++) {
                    if (valueIndex[index] === item) {
                        if(!usedIndexes.includes(index)) {
                            indexes.push(index);
                        }
                    }
                }

                sortedLocations.push(data[indexes[0]]);
                usedIndexes.push(indexes[0]);
                indexes = [];
            });

            switch(this.currentScope) {
                case 'county':
                    this.sortedZips = sortedLocations;
                    break;
                case 'state':
                    this.sortedCounties = sortedLocations;
                    break;
                case 'country':
                default:
                    this.sortedStates = sortedLocations;
                    break;
            }
        },
        showCompanyDetails(companyIds) {
            companyIds = typeof companyIds === 'object' ? Object.values(companyIds) : companyIds;
            this.$emit('show-company-details', companyIds);
        },
        changeScope(scope, name) {
            this.currentScope = scope;

            if (scope === 'state') {
                this.currentState = name;
                this.sortTable();
            } else if (scope === 'county') {
                this.currentCounty = name;
                this.sortTable();
            }
        },
        downloadZipsInState(stateName, noLimit, campaignCount = null) {
            let zipList = [];
            let tempZip = null;

            let stateData = this.states[stateName];

            const countyKeys = Object.keys(stateData.counties);

            countyKeys.forEach(countyKey => {
                let zipKeys = Object.keys(stateData.counties[countyKey].zips);

                zipKeys.forEach(zipKey => {
                    if(campaignCount === 0) {
                        if(!stateData.counties[countyKey].zips[zipKey].active) {
                            zipList.push(stateData.counties[countyKey].zips[zipKey].zip);
                        }
                    } else {
                        if (stateData.counties[countyKey].zips[zipKey].active) {

                            tempZip = stateData.counties[countyKey].zips[zipKey].zip;

                            let camCount = 0;

                            if(stateData.counties[countyKey].zips[zipKey].cam_ids) {
                                if(noLimit) {
                                    camCount = stateData.counties[countyKey].zips[zipKey].no_limit_cam_ids.length;
                                } else {
                                    camCount = stateData.counties[countyKey].zips[zipKey].cam_ids.length;
                                }
                            }

                            if (campaignCount === null) {
                                if(!noLimit && camCount > 0) {
                                    zipList.push(tempZip);
                                } else if(camCount > 0) {
                                    zipList.push(tempZip);
                                }
                            } else if (campaignCount <= 3 && camCount === campaignCount) {
                                zipList.push(tempZip);
                            } else if (campaignCount === 4 && camCount >= campaignCount) {
                                zipList.push(tempZip);
                            }
                        }
                    }
                })
            });

            zipList = zipList.join("\n");

            navigator.clipboard.writeText(zipList);

            let text = '';

            switch (campaignCount) {
                case null:
                    text = `Copied all zip codes with an active ${noLimit ? 'no limit ' : ''}campaign in ${stateName}`;
                    break;
                case 0:
                    text = `Copied all zip codes with no active campaign in ${stateName}`;
                    break;
                case 1:
                case 2:
                case 3:
                    text = `Copied all zip codes with ${campaignCount} active ${noLimit ? 'no limit ' : ''}campaign${campaignCount > 1 ? 's' : ''} in ${stateName}`;
                    break;
                case 4:
                default:
                    text = `Copied all zip codes with 4 or more active ${noLimit ? 'no limit ' : ''}campaigns in ${stateName}`;
                    break;
            }

            this.$emit('activate-alert', 'success', text);
        },
        downloadZipsInCounty(countyName, noLimit, campaignCount = null) {
            let zipList = [];
            let tempZip = null;

            let countyData = this.states[this.currentState].counties[countyName];

            let zipKeys = Object.keys(countyData.zips);

            zipKeys.forEach(zipKey => {
                if(campaignCount === 0) {
                    if (!countyData.zips[zipKey].active) {
                        zipList.push(countyData.zips[zipKey].zip);
                    }
                } else {
                    if (countyData.zips[zipKey].active) {

                        tempZip = countyData.zips[zipKey].zip;

                        let camCount = 0;

                        if(countyData.zips[zipKey].cam_ids) {
                            if(noLimit) {
                                camCount = countyData.zips[zipKey].no_limit_cam_ids.length;
                            } else {
                                camCount = countyData.zips[zipKey].cam_ids.length;
                            }
                        }

                        if (campaignCount === null) {
                            if(!noLimit && camCount > 0) {
                                zipList.push(tempZip);
                            } else if(camCount > 0) {
                                zipList.push(tempZip);
                            }
                        } else if (campaignCount <= 3 && camCount === campaignCount) {
                            zipList.push(tempZip);
                        } else if (campaignCount === 4 && camCount >= campaignCount) {
                            zipList.push(tempZip);
                        }
                    }
                }
            });

            zipList = zipList.join("\n");

            navigator.clipboard.writeText(zipList);

            let text = '';

            switch (campaignCount) {
                case null:
                    text = `Copied all zip codes with an active ${noLimit ? 'no limit ' : ''}campaign in ${countyName}, ${this.currentState}`;
                    break;
                case 0:
                    text = `Copied all zip codes with no active campaign in ${countyName}, ${this.currentState}`;
                    break;
                case 1:
                case 2:
                case 3:
                    text = `Copied all zip codes with ${campaignCount} active ${noLimit ? 'no limit ' : ''}campaign${campaignCount > 1 ? 's' : ''} in ${countyName}, ${this.currentState}`;
                    break;
                case 4:
                default:
                    text = `Copied all zip codes with 4 or more active ${noLimit ? 'no limit ' : ''}campaigns in ${countyName}, ${this.currentState}`;
                    break;
            }

            this.$emit('activate-alert', 'success', text);
        }
    },
}
</script>

<style scoped>

</style>
