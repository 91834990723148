<template>
    <nav class="flex" aria-label="Breadcrumb">
        <ol role="list" class="flex space-x-4 rounded-md px-6 shadow" :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
            <li class="flex">
                <div class="flex items-center">
                    <a href="/" class="text-gray-400 hover:text-gray-500">
                        <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true"/>
                        <span class="sr-only">Home</span>
                    </a>
                </div>
            </li>
            <li v-for="(page, index) in pages" :key="page.name" class="flex">
                <div class="flex items-center">
                    <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                         aria-hidden="true">
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z"/>
                    </svg>
                    <a :href="page.href" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer"
                       @click="$emit('page-selected', page)" v-if="!isLastPage(index)" >{{ page.name }}</a>
                    <div class="ml-4 text-sm font-medium text-gray-500" v-else>
                        {{ page.name }}
                    </div>
                </div>
            </li>
        </ol>
    </nav>
</template>

<script>
import {HomeIcon} from '@heroicons/vue/solid'

export default {
    name: "BreadCrumbs",
    components: {HomeIcon},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        pages: {
            type: Array,
            default: []
        }
    },
    methods: {
        isLastPage(itemIndex) {
            return itemIndex + 1 === this.pages.length;
        }
    }
}
</script>
