export const PREVIOUS_NODE_TYPE_PARENT = 'parent';
export const PREVIOUS_NODE_TYPE_SIBLING = 'sibling';
export const TASK_NODE_TYPES = ['add_task', 'schedule_task'];
export const LOGIC_NODE_TYPES = ['comparison', 'modulo'];
export const RESULT_NODE_TYPES = 'result_node';

export default class WorkflowFactory {
    static makeWorkflow(workflow) {
        return {
            id: workflow.id,
            name: workflow.name,
            workflow_event_id: workflow.workflow_event_id,
            entry_action_id: null,
            actions: [],
            action_tree: {actions: []}
        }
    }

    static makeAction(workflowId, previousType = PREVIOUS_NODE_TYPE_SIBLING, previousId = null) {
        return {
            id: null,
            previous_node_id: previousId,
            previous_node_type: previousType,
            display_name: null,
            workflow_id: workflowId,
            action_type: null,
            payload: {},
        }
    }
}
