<template>
    <div v-if="loading"><loading-spinner></loading-spinner></div>
    <div v-else>
        <div class="mb-5 text-red-500" v-if="error">{{ error }}</div>
        <div class="grid gap-3 grid-cols-4 items-center border-b pb-3 text-slate-400 font-medium tracking-wide uppercase text-sm">
            <div>Appointment</div>
            <div>Date</div>
            <div>Time</div>
            <div></div>
        </div>
        <div v-for="appointment in appointments" class="grid gap-3 grid-cols-4 items-center border-b py-3 text-sm">
            <div>{{ appointment.type }}</div>
            <div>{{ appointment.date }}</div>
            <div>{{ formatTime(appointment.time) }}</div>
            <div>
                <svg width="12" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer" @click="deleteAppointment(appointment.id)">
                    <path
                        d="M1.5 2.75H1V9.9C1 10.1917 1.10536 10.4715 1.29289 10.6778C1.48043 10.8841 1.73478 11 2 11H7C7.26522 11 7.51957 10.8841 7.70711 10.6778C7.89464 10.4715 8 10.1917 8 9.9V2.75H1.5ZM6.809 1.1L6 0H3L2.191 1.1H0V2.2H9V1.1H6.809Z"
                        fill="#339AFF"></path>
                </svg>
            </div>
        </div>
        <div class="mt-5" v-if="appointmentDateTime === null">
            <button class="transition duration-200 text-sm font-semibold focus:outline-none py-3 rounded-lg px-5" @click="showCalender"
                    :class="{'bg-grey-475 hover:bg-blue-800 text-white': !darkMode, 'bg-blue-400 hover:bg-blue-500 text-white': darkMode}">
                Pick Appointment Date Time
            </button>
            <div v-if="showingCalender" class="mt-3">
                <Datepicker :inline="true" @update:modelValue="setDateTime" :format="'yyyy-MM-dd hh:mm a'" :dark="darkMode"></Datepicker>
            </div>
        </div>
        <div v-else class="mt-5 flex items-center justify-between">
            <p>Date and Time: {{ this.appointmentDateTime.toFormat('yyyy-MM-dd hh:mm a') }}</p>
            <div>
                <dropdown :dark-mode="darkMode" :options="appointmentTypes" v-model="appointmentType" :selected="appointmentType"></dropdown>
            </div>
            <div>
                <button class="transition duration-200 text-white font-medium focus:outline-none py-2 rounded-md px-5 bg-primary-500 hover:bg-blue-500" @click="createAppointment">
                    Save
                </button>
                <button class="transition duration-200 bg-grey-250 hover:bg-light-background text-white font-medium focus:outline-none py-2 rounded-md px-5 ml-3"
                        :class="{'bg-grey-250 hover:bg-light-background text-white': !darkMode, 'bg-grey-500 hover:bg-grey-600 text-white': darkMode}"
                        @click="appointmentDateTime = null">Cancel
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import ConsumerApiService from "../../services/consumer_api";
import {DateTime} from "luxon";
import Dropdown from "../../components/Dropdown.vue";
import LoadingSpinner from "../../components/LoadingSpinner.vue";

export default {
    name: "ProductAppointments",
    components:{LoadingSpinner, Dropdown},
    props: {
        consumerProductId: {
            type: Number,
            required: true
        },
        darkMode: {
            type: Boolean,
            default: false
        }
    },
    emits: ['appointment-added', 'appointment-deleted'],
    data () {
        return {
            appointments: [],
            api: ConsumerApiService.make(),
            showingCalender: false,
            appointmentDateTime: null,
            appointmentType: 'in-home-appointment',
            appointmentTypes: [
                {id: 'in-home-appointment', name: 'in-home-appointment'},
                {id: 'online-consultation', name: 'online-consultation'}
            ],
            loading: false,
            error: null
        }
    },
    created() {
        if (this.consumerProductId) this.getAppointments();
    },
    methods: {
        getAppointments () {
            this.loading = true;
            this.error = null;

            this.api.getAppointments(this.consumerProductId)
                .then(resp => this.appointments = resp.data.data.appointments)
                .catch(e => this.error = e.response.data.message)
                .finally(() => this.loading = false);
        },
        deleteAppointment(appointmentId) {
            this.loading = true;
            this.error = null;

            this.api.deleteAppointment(this.consumerProductId, appointmentId)
                .then((resp) => {
                    if (resp.data.data.status === true) {
                        this.appointments = this.appointments.filter(appointment => appointment.id !== appointmentId);
                        this.$emit('appointment-deleted', appointmentId)
                    }
                })
                .catch(e => this.error = e.response.data.message)
                .finally(() => this.loading = false);
        },
        createAppointment() {
            this.loading = true;
            this.error = null;

            this.api.createAppointment(this.consumerProductId, this.appointmentType, this.appointmentDateTime.toFormat('yyyy-MM-dd'), this.appointmentDateTime.toFormat('HH:mm:ss'))
                .then(resp => {
                    this.appointments.push(resp.data.data.appointment);
                    this.appointmentDateTime = null;
                    this.$emit('appointment-added', resp.data.data.appointment)
                })
                .catch(e => this.error = e.response.data.message)
                .finally(() => this.loading = false);
        },
        setDateTime(date) {
            this.appointmentDateTime = DateTime.fromJSDate(date);
            this.showingCalender = false;
        },
        showCalender() {
            this.showingCalender = true;
            this.appointmentDateTime = null;
        },
        formatTime(time) {
            if (!time) return '';

            return DateTime.fromISO(time).toFormat('hh:mm a');
        }
    }
}
</script>
