import axios from 'axios';
import {BaseApiService} from "./base";

class ApiService extends BaseApiService {

    ROUTE_INFO = '/info';
    ROUTE_MANAGEMENT = '/management';

    constructor(baseUrl, baseEndpoint, version) {
        super("ApiService");

        this.baseEndpoint = baseEndpoint;
        this.baseUrl = baseUrl;
        this.version = version;
    }

    axios() {
        const axiosConfig = {
            baseURL: `/${this.baseUrl}/v${this.version}/${this.baseEndpoint}`
        }

        return axios.create(axiosConfig);
    }

    getAccountManagers() {
        return this.axios().get(`${this.ROUTE_INFO}/account-managers`);
    }

    deleteAccountManager(id) {
        return this.axios().patch(`${this.ROUTE_MANAGEMENT}/disable-account-manager/` + id);
    }

    createAccountManager(userId, type, includeInSalesToolRoundRobin) {
        return this.axios().post(`${this.ROUTE_MANAGEMENT}/account-manager`, {
            user_id: userId,
            type: type,
            include_in_sales_tool_round_robin: includeInSalesToolRoundRobin
        });
    }

    updateAccountManager(id, userId, type, includeInSalesToolRoundRobin) {
        return this.axios().put(`${this.ROUTE_MANAGEMENT}/account-manager/` + id, {
            user_id: userId,
            type: type,
            include_in_sales_tool_round_robin: includeInSalesToolRoundRobin
        });
    }

    getAccountManagerTypes() {
        return this.axios().get(`${this.ROUTE_INFO}/account-manager-types`);
    }

    getSuccessManagers() {
        return this.axios().get(`${this.ROUTE_INFO}/success-managers`);
    }

    createSuccessManager(payload) {
        return this.axios().post(`${this.ROUTE_MANAGEMENT}/success-managers`, payload);
    }

    updateSuccessManager(id, payload) {
        return this.axios().patch(`${this.ROUTE_MANAGEMENT}/success-managers/${id}`, payload);
    }

    deleteSuccessManager(id) {
        return this.axios().delete(`${this.ROUTE_MANAGEMENT}/success-managers/${id}`);
    }

    getSuccessManagerTypes() {
        return this.axios().get(`${this.ROUTE_INFO}/success-manager-types`);
    }

    searchUserNames(query) {
        return this.axios().get(`${this.ROUTE_MANAGEMENT}/admins/search`, {
            params: {
                name: query
            }
        });
    }

    getSupportOfficers() {
        return this.axios().get(`${this.ROUTE_MANAGEMENT}/support-officers`);
    }

    createSupportOfficer(payload) {
        return this.axios().post(`${this.ROUTE_MANAGEMENT}/support-officers`, payload);
    }

    updateSupportOfficer(id, payload) {
        return this.axios().patch(`${this.ROUTE_MANAGEMENT}/support-officers/${id}`, payload);
    }

    deleteSupportOfficer(id) {
        return this.axios().delete(`${this.ROUTE_MANAGEMENT}/support-officers/${id}`);
    }

    getHunters() {
        return this.axios().get(`${this.ROUTE_MANAGEMENT}/hunters`);
    }

    createHunter(payload) {
        return this.axios().post(`${this.ROUTE_MANAGEMENT}/hunters`, payload);
    }

    updateHunter(id, payload) {
        return this.axios().patch(`${this.ROUTE_MANAGEMENT}/hunters/${id}`, payload);
    }

    deleteHunter(id) {
        return this.axios().delete(`${this.ROUTE_MANAGEMENT}/hunters/${id}`);
    }
}

export { ApiService };

