<template>
    <div>
        <modal :dark-mode="darkMode" :small="true" :close-text="'Cancel'" :confirm-text="saving ? 'Saving...' : 'Complete'" @close="$emit('close')" @confirm="completeTasks">
            <template v-slot:header>
                <h4 class="text-xl">Complete Tasks</h4>
            </template>
            <template v-slot:content>
                <div class="mb-5">
                    <label class="text-base font-medium">Task Complete Options</label>
                    <fieldset class="mt-4">
                        <legend class="sr-only">Task Complete Options</legend>
                        <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div v-for="completeOption in completeOptions" :key="completeOption.id" class="flex items-center">
                                <input :id="completeOption.id" name="notification-method" type="radio" :value="completeOption.id" v-model="selectedOption"
                                       class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"/>
                                <label :for="completeOption.id" class="ml-3 block text-sm font-medium">{{ completeOption.title }}</label>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="grid grid-cols-1 gap-4 my-5 w-full" v-if="selectedOption === 'crm'">
                    <div>
                        Subject
                        <input v-model="crmSubject" class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                               placeholder="Enter subject..." type="text"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>
                    </div>
                    <div>
                        Notes
                        <textarea
                            class="min-h-88 w-full border rounded pl-4 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                            placeholder="Enter notes..." type="text" v-model="crmNote"
                            :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>
                    </div>
                </div>
                <div class="my-5 w-full" v-if="selectedOption === 'note'">
                    Task Notes
                    <textarea
                        class="min-h-88 w-full border rounded pl-4 focus:outline-none focus:border focus:border-primary-500 pr-4"
                        placeholder="Enter notes..." type="text" v-model="taskNote"
                        :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>
                </div>
            </template>
        </modal>
        <alerts-container :alert-type="'error'" v-if="error !== null" :dark-mode="darkMode" :text="error"></alerts-container>
    </div>
</template>

<script>
import Modal from "../Shared/components/Modal";
import Dropdown from "../Shared/components/Dropdown";
import ApiService from "./services/api";
import AlertsContainer from "../Shared/components/AlertsContainer";

export default {
    name: "BulkComplete",
    components: {Modal, Dropdown, AlertsContainer},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        tasks: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            api:ApiService.make(),
            completeOptions: [
                {id: 'no_action', title: 'Acknowledge - No Further Action'},
                {id: 'crm', title: 'Add CRM Entry'},
                {id: 'note', title: 'Add Task Note'},
            ],
            selectedOption: 'no_action',
            crmSubject: null,
            crmNote: null,
            taskNote: null,
            saving: false,
            error: null
        }
    },
    methods: {
        completeTasks() {
            if (this.saving) return;

            this.saving = true;
            this.error = null;
            this.api.batchComplete(this.buildRequestPayload())
                .then(() => this.$emit('completed'))
                .catch(e => this.error = e.response.data.message)
                .finally(() => this.saving = false);
        },
        buildRequestPayload() {
            if (this.selectedOption === 'no_action') return {tasks: this.tasks, action_type: 'no_action'};

            let payload = {
                tasks: this.tasks,
                note: this.selectedOption === 'crm' ? this.crmNote : this.taskNote,
                action_type: this.selectedOption === 'crm' ? 'crm' : 'task_note',
            };

            if (this.selectedOption === 'crm') payload = {...payload, subject: this.crmSubject};

            return payload;
        }
    }
}
</script>
