<template>
    <div class="col-span-3 border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="p-5">
            <div class="flex items-center justify-between pb-3">
                <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Leads</h5>
            </div>
            <div v-if="showFilters && !loading">
                <div class="flex items-center mb-2">
                    <div class="flex items-center mr-3">
                        <p class="text-grey-300 uppercase text-xs mr-2">Filters</p>
                        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 4H13V6H3V4ZM0 0H16V2H0V0ZM6 8H10V10H6V8Z" fill="#ABB0B5"/>
                        </svg>
                    </div>
                    <Dropdown v-model="selectedCampaign" :options="campaigns" :dark-mode="darkMode" class="w-48 mr-2" placeholder="Campaigns"></Dropdown>
                    <Dropdown v-model="selectedLeadStatus" :options="leadStatuses" :dark-mode="darkMode" class="w-48 mr-2" placeholder="Status"></Dropdown>
                    <Dropdown v-model="selectedService" :options="services" :dark-mode="darkMode" class="w-48 mr-2" placeholder="Service"></Dropdown>
                    <Datepicker :class="{'w-1/3': date}" :dark="darkMode" v-model="date" range></Datepicker>
                </div>
                <div class="flex items-center">
                    <div class="relative mr-2">
                        <input v-model="searchLeadId" class="w-full border rounded pl-8  focus:outline-none focus:border focus:border-primary-500 pr-4 py-2 h-9" placeholder="Lead ID" type="text"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>
                        <div class="absolute top-0 left-0 w-8 h-full flex justify-center items-center">
                            <svg class="inset-y-0 fill-current text-grey-400" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.66667 2C4.69421 2 3.76158 2.38631 3.07394 3.07394C2.38631 3.76158 2 4.69421 2 5.66667C2 6.14818 2.09484 6.62498 2.27911 7.06984C2.46338 7.5147 2.73346 7.91891 3.07394 8.25939C3.41442 8.59987 3.81863 8.86996 4.26349 9.05423C4.70835 9.23849 5.18515 9.33333 5.66667 9.33333C6.14818 9.33333 6.62498 9.23849 7.06984 9.05423C7.5147 8.86996 7.91891 8.59987 8.25939 8.25939C8.59987 7.91891 8.86996 7.5147 9.05423 7.06984C9.23849 6.62498 9.33333 6.14818 9.33333 5.66667C9.33333 4.69421 8.94703 3.76158 8.25939 3.07394C7.57176 2.38631 6.63913 2 5.66667 2ZM1.65973 1.65973C2.72243 0.597022 4.16377 0 5.66667 0C7.16956 0 8.6109 0.597022 9.6736 1.65973C10.7363 2.72243 11.3333 4.16377 11.3333 5.66667C11.3333 6.41082 11.1868 7.14769 10.902 7.83521C10.7458 8.21219 10.5498 8.57029 10.3178 8.90361L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L8.90361 10.3178C8.57029 10.5498 8.21219 10.7458 7.83521 10.902C7.14769 11.1868 6.41082 11.3333 5.66667 11.3333C4.92251 11.3333 4.18564 11.1868 3.49813 10.902C2.81062 10.6172 2.18593 10.1998 1.65973 9.6736C1.13353 9.14741 0.716126 8.52272 0.431349 7.83521C0.146573 7.1477 0 6.41082 0 5.66667C0 4.16377 0.597022 2.72243 1.65973 1.65973Z"/>
                            </svg>
                        </div>
                    </div>
                    <div class="relative mr-2">
                        <input v-model="searchName" class="w-full border rounded pl-8  focus:outline-none focus:border focus:border-primary-500 pr-4 py-2 h-9" placeholder="Name or Email" type="text"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>
                        <div class="absolute top-0 left-0 w-8 h-full flex justify-center items-center">
                            <svg class="inset-y-0 fill-current text-grey-400" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.66667 2C4.69421 2 3.76158 2.38631 3.07394 3.07394C2.38631 3.76158 2 4.69421 2 5.66667C2 6.14818 2.09484 6.62498 2.27911 7.06984C2.46338 7.5147 2.73346 7.91891 3.07394 8.25939C3.41442 8.59987 3.81863 8.86996 4.26349 9.05423C4.70835 9.23849 5.18515 9.33333 5.66667 9.33333C6.14818 9.33333 6.62498 9.23849 7.06984 9.05423C7.5147 8.86996 7.91891 8.59987 8.25939 8.25939C8.59987 7.91891 8.86996 7.5147 9.05423 7.06984C9.23849 6.62498 9.33333 6.14818 9.33333 5.66667C9.33333 4.69421 8.94703 3.76158 8.25939 3.07394C7.57176 2.38631 6.63913 2 5.66667 2ZM1.65973 1.65973C2.72243 0.597022 4.16377 0 5.66667 0C7.16956 0 8.6109 0.597022 9.6736 1.65973C10.7363 2.72243 11.3333 4.16377 11.3333 5.66667C11.3333 6.41082 11.1868 7.14769 10.902 7.83521C10.7458 8.21219 10.5498 8.57029 10.3178 8.90361L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L8.90361 10.3178C8.57029 10.5498 8.21219 10.7458 7.83521 10.902C7.14769 11.1868 6.41082 11.3333 5.66667 11.3333C4.92251 11.3333 4.18564 11.1868 3.49813 10.902C2.81062 10.6172 2.18593 10.1998 1.65973 9.6736C1.13353 9.14741 0.716126 8.52272 0.431349 7.83521C0.146573 7.1477 0 6.41082 0 5.66667C0 4.16377 0.597022 2.72243 1.65973 1.65973Z"/>
                            </svg>
                        </div>
                    </div>
                    <div class="relative mr-2">
                        <input v-model="searchState" class="w-full border rounded pl-8  focus:outline-none focus:border focus:border-primary-500 pr-4 py-2 h-9" placeholder="State or Zipcode" type="text"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>
                        <div class="absolute top-0 left-0 w-8 h-full flex justify-center items-center">
                            <svg class="inset-y-0 fill-current text-grey-400" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.66667 2C4.69421 2 3.76158 2.38631 3.07394 3.07394C2.38631 3.76158 2 4.69421 2 5.66667C2 6.14818 2.09484 6.62498 2.27911 7.06984C2.46338 7.5147 2.73346 7.91891 3.07394 8.25939C3.41442 8.59987 3.81863 8.86996 4.26349 9.05423C4.70835 9.23849 5.18515 9.33333 5.66667 9.33333C6.14818 9.33333 6.62498 9.23849 7.06984 9.05423C7.5147 8.86996 7.91891 8.59987 8.25939 8.25939C8.59987 7.91891 8.86996 7.5147 9.05423 7.06984C9.23849 6.62498 9.33333 6.14818 9.33333 5.66667C9.33333 4.69421 8.94703 3.76158 8.25939 3.07394C7.57176 2.38631 6.63913 2 5.66667 2ZM1.65973 1.65973C2.72243 0.597022 4.16377 0 5.66667 0C7.16956 0 8.6109 0.597022 9.6736 1.65973C10.7363 2.72243 11.3333 4.16377 11.3333 5.66667C11.3333 6.41082 11.1868 7.14769 10.902 7.83521C10.7458 8.21219 10.5498 8.57029 10.3178 8.90361L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L8.90361 10.3178C8.57029 10.5498 8.21219 10.7458 7.83521 10.902C7.14769 11.1868 6.41082 11.3333 5.66667 11.3333C4.92251 11.3333 4.18564 11.1868 3.49813 10.902C2.81062 10.6172 2.18593 10.1998 1.65973 9.6736C1.13353 9.14741 0.716126 8.52272 0.431349 7.83521C0.146573 7.1477 0 6.41082 0 5.66667C0 4.16377 0.597022 2.72243 1.65973 1.65973Z"/>
                            </svg>
                        </div>
                    </div>
                    <!-- todo impliment searching invoice                   -->
<!--                    <div class="relative">-->
<!--                        <input v-model="searchInvoice" class="w-full border rounded pl-8  focus:outline-none focus:border focus:border-primary-500 pr-4 py-2 h-9" placeholder="Invoice No." type="text"-->
<!--                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>-->
<!--                        <div class="absolute top-0 left-0 w-8 h-full flex justify-center items-center">-->
<!--                            <svg class="inset-y-0 fill-current text-grey-400" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.66667 2C4.69421 2 3.76158 2.38631 3.07394 3.07394C2.38631 3.76158 2 4.69421 2 5.66667C2 6.14818 2.09484 6.62498 2.27911 7.06984C2.46338 7.5147 2.73346 7.91891 3.07394 8.25939C3.41442 8.59987 3.81863 8.86996 4.26349 9.05423C4.70835 9.23849 5.18515 9.33333 5.66667 9.33333C6.14818 9.33333 6.62498 9.23849 7.06984 9.05423C7.5147 8.86996 7.91891 8.59987 8.25939 8.25939C8.59987 7.91891 8.86996 7.5147 9.05423 7.06984C9.23849 6.62498 9.33333 6.14818 9.33333 5.66667C9.33333 4.69421 8.94703 3.76158 8.25939 3.07394C7.57176 2.38631 6.63913 2 5.66667 2ZM1.65973 1.65973C2.72243 0.597022 4.16377 0 5.66667 0C7.16956 0 8.6109 0.597022 9.6736 1.65973C10.7363 2.72243 11.3333 4.16377 11.3333 5.66667C11.3333 6.41082 11.1868 7.14769 10.902 7.83521C10.7458 8.21219 10.5498 8.57029 10.3178 8.90361L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L8.90361 10.3178C8.57029 10.5498 8.21219 10.7458 7.83521 10.902C7.14769 11.1868 6.41082 11.3333 5.66667 11.3333C4.92251 11.3333 4.18564 11.1868 3.49813 10.902C2.81062 10.6172 2.18593 10.1998 1.65973 9.6736C1.13353 9.14741 0.716126 8.52272 0.431349 7.83521C0.146573 7.1477 0 6.41082 0 5.66667C0 4.16377 0.597022 2.72243 1.65973 1.65973Z"/>-->
<!--                            </svg>-->
<!--                        </div>-->
<!--                    </div>-->
                    <button @click="getLeads" class="inline-flex items-center px-5 h-9 py-1 mx-2 bg-primary-500 hover:bg-blue-500 transition duration-200 rounded-lg text-xs font-medium text-white">
                        <svg class="fill-current text-white" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.66667 2C4.69421 2 3.76158 2.38631 3.07394 3.07394C2.38631 3.76158 2 4.69421 2 5.66667C2 6.14818 2.09484 6.62498 2.27911 7.06984C2.46338 7.5147 2.73346 7.91891 3.07394 8.25939C3.41442 8.59987 3.81863 8.86996 4.26349 9.05423C4.70835 9.23849 5.18515 9.33333 5.66667 9.33333C6.14818 9.33333 6.62498 9.23849 7.06984 9.05423C7.5147 8.86996 7.91891 8.59987 8.25939 8.25939C8.59987 7.91891 8.86996 7.5147 9.05423 7.06984C9.23849 6.62498 9.33333 6.14818 9.33333 5.66667C9.33333 4.69421 8.94703 3.76158 8.25939 3.07394C7.57176 2.38631 6.63913 2 5.66667 2ZM1.65973 1.65973C2.72243 0.597022 4.16377 0 5.66667 0C7.16956 0 8.6109 0.597022 9.6736 1.65973C10.7363 2.72243 11.3333 4.16377 11.3333 5.66667C11.3333 6.41082 11.1868 7.14769 10.902 7.83521C10.7458 8.21219 10.5498 8.57029 10.3178 8.90361L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L8.90361 10.3178C8.57029 10.5498 8.21219 10.7458 7.83521 10.902C7.14769 11.1868 6.41082 11.3333 5.66667 11.3333C4.92251 11.3333 4.18564 11.1868 3.49813 10.902C2.81062 10.6172 2.18593 10.1998 1.65973 9.6736C1.13353 9.14741 0.716126 8.52272 0.431349 7.83521C0.146573 7.1477 0 6.41082 0 5.66667C0 4.16377 0.597022 2.72243 1.65973 1.65973Z"/>
                        </svg>
                    </button>
                    <button @click="resetSearch" class="transition duration-200 text-sm font-semibold focus:outline-none py-2 rounded-md px-5 mr-3"
                            :class="{'bg-grey-250 hover:bg-light-background text-white': !darkMode, 'bg-grey-500 hover:bg-grey-600 text-white': darkMode}">
                        Reset
                    </button>
                </div>
            </div>
        </div>
        <div v-if="!loading">
            <div class="grid grid-cols-12 gap-x-3 my-2 px-5">
                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">ID</p>
                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Legacy ID</p>
                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Service</p>
                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Date Sent</p>
                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Chargeable</p>
                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Delivered</p>
                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Contact</p>
                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-3">Address</p>
                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Cost</p>
            </div>
            <div class="border-t border-b h-80 overflow-y-auto divide-y"
                 :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
                <div v-for="lead in leads" class="grid grid-cols-12 gap-x-3 p-5 items-center"  :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <!-- todo send this to the leads page -->
                    <a class="text-sm text-blue-550 col-span-1" href="#">{{ lead.id }}</a>
                    <a class="text-sm text-blue-550 col-span-1" :href="getLegacyAdminQuoteUrl(lead.legacy_id)" target="_blank">{{ lead.legacy_id }}</a>
                    <p class="text-sm col-span-1">{{ lead.service }}</p>
                    <p class="text-sm col-span-1">{{ lead.date }}</p>
                    <p class="text-sm col-span-1">{{ lead.chargeable }}</p>
                    <p class="text-sm col-span-1">{{ lead.delivered }}</p>
                    <p class="text-sm col-span-2">{{ lead.contact }}</p>
                    <p class="text-sm col-span-3">{{ lead.address }}</p>
                    <p class="text-sm col-span-1">{{ lead.cost }}</p>
                </div>
            </div>
            <div class="p-3">
                <Pagination :dark-mode="darkMode" :pagination-data="paginationData" :show-pagination="true" @change-page="handlePaginationEvent"></Pagination>
            </div>
        </div>
        <div v-if="loading">
            <loading-spinner :dark-mode="darkMode"></loading-spinner>
        </div>
    </div>
</template>

<script>
import Dropdown from "../components/Dropdown";
import ActionsHandle from "../components/ActionsHandle";
import Pagination from "../components/Pagination";
import SharedApiService from "../services/api";
import LoadingSpinner from "../components/LoadingSpinner";
import LegacyAdminMixin from "../mixins/legacy-admin-mixin";
import CompanyApiService from "../../Companies/services/api";
import Modal from "../../Shared/components/Modal";

export default {
    name: "Leads",
    components: {Pagination, ActionsHandle, Dropdown, LoadingSpinner, Modal},
    mixins: [LegacyAdminMixin],
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        companyId: {
            type: Number,
            default: null
        },
        showFilters: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            leads: [],
            campaigns: [],
            leadStatuses: [],
            services: [],
            selectedCampaign: null,
            selectedLeadStatus: null,
            selectedService: null,
            searchLeadId: null,
            searchName: null,
            searchState: null,
            paginationData: null,
            apiService: null,
            companyApiService: null,
            loading: false,
            searchParams: {},
            date: null,
        }
    },
    created() {
        this.apiService = SharedApiService.make();
        this.companyApiService = CompanyApiService.make();

        if(this.companyId) {
            this.getLeads();
            this.getCampaigns();
            this.getStatuses();
            this.getServices();

        }
    },
    methods: {
        getLeads () {
            this.loading = true;
            this.setSearchParams();
            this.apiService.getCompanyLeads(this.companyId, this.searchParams).then(resp => {
                let {data, ...paginationData} = resp.data.data.leads;
                this.leads = data;
                this.paginationData = paginationData;
            }).catch(e => console.log(e)).finally(() => this.loading = false);
        },
        async handlePaginationEvent(newPageUrl) {
            this.loading = true;
            this.setSearchParams();
            await axios.get(newPageUrl.link, {
                params: this.searchParams
            }).then(resp => {
                let {data, ...paginationData} = resp.data.data.leads;
                this.leads = data;
                this.paginationData = paginationData;
            }).catch(e => console.log(e)).finally(() => this.loading = false);
        },
        getCampaigns() {
            this.companyApiService.getCampaigns(this.companyId, null, 1000).then(resp => {
                this.campaigns = resp.data.data.campaigns.data;
            })
        },
        getStatuses() {
            this.apiService.getLeadStatuses().then(resp => {
                this.leadStatuses = resp.data.data.statuses;
            })
        },
        getServices() {
            this.companyApiService.getServices(this.companyId).then(resp => {
                this.services = resp.data.data.services;
            })
        },
        resetSearch() {
            this.searchParams = {};
            this.selectedCampaign = null;
            this.selectedLeadStatus = null;
            this.selectedService = null;
            this.searchLeadId = null;
            this.searchName = null;
            this.searchState = null;
            this.date = null;
        },
        setSearchParams() {
            this.searchParams = {
                'campaign': this.selectedCampaign,
                'status': this.selectedLeadStatus,
                'service': this.selectedService,
                'lead_id': this.searchLeadId,
                'name': this.searchName,
                'state': this.searchState,
                'date_from': (this.date) ? new Date(this.date[0]).getTime() / 1000 : null,
                'date_to': (this.date) ? new Date(this.date[1]).getTime() / 1000 : null,
            }
        },
    }
}
</script>

<style scoped>

</style>
