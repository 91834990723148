<template>
    <div class="fixed bottom-24 inset-x-full z-50">

        <recordings-wrapper
            @toggle-recording-list="toggleRecordingList"
            @clear-recordings="clearCallCallRecordings"
            @get-call-recordings-for-lead="getCallRecordingsForLeadId"
            :recording-list="recordingList"
            :dark-mode="darkMode"
            :recordings="recordings"
        />

        <dialer
            @sms="onDiallerSMS"
            @call="onDiallerCall"
            :dark-mode="darkMode"
        />


        <voicemail-wrapper
            @toggle-voicemail-list="toggleVoicemailList"
            @voicemail-played="markVoicemailHeard"
            :voicemail-list="voicemailList"
            :dark-mode="darkMode"
            :voicemails="voicemails"
        />

        <outgoing-call
            :minimize-call="minimizeCall"
            :call-active="callActive"
            :muted="muted"
            :on-hold="onHold"
            :dark-mode="darkMode"
            :comment="contactComment"
            :contact-name="contactName"
            :contact-phone="contactPhone"
            :status="status"
            :session="null"
            @minimize-call="toggleMinimizeCall"
            @end-call="endCall"
            @on-hold="toggleHold"
            @muted="toggleMute"
            @key-pressed="onDialerKeyPressed"
            :relation-type="contactRelationType"
            :relation-id="contactRelationId"
        />


        <incoming-call
            @answer-call="answerCall"
            @end-call="declineCall"
            :call-active="incoming !== null"
            :id="incomingId"
            :name="incomingName"
            :phone="incomingPhone"
            :type="incomingType"
        />

        <messenger
            :messenger-active="messengerActive"
            :messages="messages"
            :dark-mode="darkMode"
            :loading="messagesLoading"
            :name="name"
            ref="messenger"
            @close-messenger="closeMessenger"
            @send-message="sendMessage"
        />
    </div>
</template>

<script>

// import NotificationsWrapper from "./components/NotificationsWrapper";
import {ApiFactory} from "../LeadProcessing/services/api/factory";
// import HandlesNotificationsMixin from "./mixins/handles-notifications-mixin";
import OutgoingCall from "./components/OutgoingCall";
import IncomingCall from "./components/IncomingCall";
// import Messenger from "./components/Messenger";
import {DateTime} from "luxon";
import VoicemailWrapper from "./components/VoicemailWrapper";
import Messenger from "./components/Messenger";
import RecordingsWrapper from "./components/RecordingsWrapper";
import DispatchesGlobalEventsMixin from "../../mixins/dispatches-global-events-mixin";
import CommunicationMixin from "../../mixins/communcation/communication-mixin";
import Dialer from "./components/Dialer";

export default {
    name: "Communications",
    props: {
        apiDriver: {
            type: String,
            default: 'dummy'
        },
        darkMode: {
            type: Boolean,
            default: false,
        }
    },
    data: function() {
        return {
            api: null,
            messengerActive: false,
            messages: null,
            messagesLoading: true,
            name: null,
            targetPhone: null,
            targetId: null,
            voicemailList: false,
            recordingList: false,
            dialerModal: false,
        }
    },
    components: {RecordingsWrapper, VoicemailWrapper, IncomingCall, OutgoingCall, Messenger, Dialer},
    created() {
        this.api = ApiFactory.makeApiService(this.apiDriver);

        this.initializeEventListeners();
    },
    mixins: [DispatchesGlobalEventsMixin, CommunicationMixin],
    mounted() {},
    computed: {},
    methods: {
        toggleDialerModal() {
            this.dialerModal = ! this.dialerModal;
        },
        onDiallerSMS(phone) {
            this.lookupCaller(phone).then(data => {
                this.onAttemptSMS({phone, name: data.name, id: data.relation_id});
            });
        },
        onDiallerCall(phone) {
            this.lookupCaller(phone).then(data => {
                this.onAttemptCall({
                    phone,
                    name: data.name,
                    comment: `${data.relation_type} - #${data.relation_id}`,
                    relType: data.relation_type,
                    relId: data.relation_id
                });
            });
        },
        onDialerKeyPressed(key) {
            this.generateKeyTone(key);
        },
        onAttemptCall({phone, name, comment, relType = null, relId = null}) {
            if(relType === null || relId === null) {
                this.lookupCaller(phone).then(data => {
                    this.attemptCall({phone, name, comment, relType: data.relation_type, relId: data.relation_id});
                })
            } else {
                this.attemptCall({phone, name, comment, relType, relId});
            }
        },
        onAttemptSMS({phone, name, id}) {
            this.messagesLoading = true;
            this.messages = null;
            this.targetPhone = phone;
            this.targetId = id;

            this.api.getSMSHistory(this.targetPhone).then(resp => {
                this.messages = resp.data.data.messages;
                this.messagesLoading = false;
                this.$refs.messenger.scrollDown();
            });

            this.name = name;
            this.messengerActive = true;
        },
        initializeEventListeners() {
            this.listenForGlobalEvent('call', this.onAttemptCall.bind(this));
            this.listenForGlobalEvent('sms', this.onAttemptSMS.bind(this));
            this.listenForGlobalEvent("new-message", this.newMessage.bind(this));
        },
        closeMessenger() {
            this.messengerActive = false;
            this.targetPhone = null;
            this.targetId = null;
        },
        sendMessage(data) {
            const body = data.message;
            const formatted = {direction: 'outbound', first_name: 'You', last_name: '', message_body: body, timestamp: Math.round(DateTime.now().toSeconds())};
            if(!this.messages)
                this.messages = [];

            this.messages.push(formatted);
            this.$refs.messenger.scrollDown();

            this.attemptSMS(this.targetPhone, body);
        },
        toggleRecordingList() {
            this.recordingList = ! this.recordingList;
            this.notificationsList = false;
            this.voicemailList = false;
        },
        toggleVoicemailList() {
            this.voicemailList = ! this.voicemailList;
            this.notificationsList = false;
            this.recordingList = false;
        },
        toggleNotificationsList() {
            this.notificationsList = ! this.notificationsList;
            this.voicemailList = false;
            this.recordingList = false;
        },
        addMessage(direction, first_name, last_name, message_body, timestamp) {
            this.messages.push({direction, first_name, last_name, message_body, timestamp});
            this.$refs.messenger.scrollDown();
        },
        newMessage() {
            if(this.messengerActive)
                this.api.getSMSHistory(this.targetPhone).then(resp => {
                    this.messages = resp.data.data.messages;
                    this.messagesLoading = false;
                    this.$refs.messenger.scrollDown();
                });
        },
    }
}
</script>
