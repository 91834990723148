import SharedApiService from "./api";

const ENDPOINT_CONSUMER_PRODUCTS = 'consumer-products';
const ENDPOINT_PRODUCTS = 'products';

export default class ConsumerApiService extends SharedApiService {

    static make() {
        return new ConsumerApiService(
            SharedApiService.getBaseUrl(),
            `${SharedApiService.getBaseEndpoints().LEAD_PROCESSING}`,
            SharedApiService.getVersion()
        );
    }

    getConsumerProductContactInfo(consumerProductId) {
        return this.axios().get(`${ENDPOINT_CONSUMER_PRODUCTS}/${consumerProductId}/contact-info`);
    }

    getConsumerProductBasicInfo(consumerProductId) {
        return this.axios().get(`${ENDPOINT_CONSUMER_PRODUCTS}/${consumerProductId}/basic-info`);
    }

    getConsumerProductsByConsumerProductId(consumerProductId) {
        return this.axios().get(`${ENDPOINT_CONSUMER_PRODUCTS}/${consumerProductId}/other-consumer-products`);
    }

    getConsumerProductUtilityInfo(consumerProductId) {
        return this.axios().get(`${ENDPOINT_CONSUMER_PRODUCTS}/${consumerProductId}/utility-info`);
    }

    getReviewsByConsumerProductId(consumerProductId) {
        return this.axios().get(`${ENDPOINT_CONSUMER_PRODUCTS}/${consumerProductId}/reviews`);
    }

    updateConsumerProductBasicInfo(consumerProductId, updatePayload) {
        return this.axios().patch(`${ENDPOINT_CONSUMER_PRODUCTS}/${consumerProductId}/update-basic-info`, updatePayload);
    }

    updateConsumerProductContactInfo(consumerProductId, updatePayload) {
        return this.axios().patch(`${ENDPOINT_CONSUMER_PRODUCTS}/${consumerProductId}/update-contact-info`, updatePayload);
    }

    updateConsumerProductUtilityInfo(consumerProductId, updatePayload) {
        return this.axios().patch(`${ENDPOINT_CONSUMER_PRODUCTS}/${consumerProductId}/update-utility-info`, updatePayload);
    }

    cancelConsumerProduct(consumerProductId, reason, publicComment) {
        return this.axios().post(`${ENDPOINT_PRODUCTS}/${consumerProductId}/cancel`, {reason, public_comment: publicComment});
    }

    markConsumerProductAsPendingReview(consumerProductId, reason) {
        return this.axios().post(`${ENDPOINT_PRODUCTS}/${consumerProductId}/mark-as-pending-review`, {reason});
    }

    markConsumerProductAsUnderReview(consumerProductId, reason, publicComment) {
        return this.axios().post(`${ENDPOINT_PRODUCTS}/${consumerProductId}/mark-as-under-review`, {reason, public_comment: publicComment});
    }

    approveConsumerProduct(consumerProductId, reason, bestTimeToContact, publicComment) {
        return this.axios().post(`${ENDPOINT_PRODUCTS}/${consumerProductId}/approve`, {reason: reason, best_time_to_contact: bestTimeToContact, public_comment: publicComment});
    }

    getConsumerProductVerification(consumerProductId, service) {
        return this.axios().get(`${ENDPOINT_CONSUMER_PRODUCTS}/${consumerProductId}/${service}/verification`);
    }

    getConsumerProductDateInfo(consumerProductId) {
        return this.axios().get(`${ENDPOINT_CONSUMER_PRODUCTS}/${consumerProductId}/date-info`);
    }

    heartbeat(consumerProductId) {
        return this.axios().post(`${ENDPOINT_PRODUCTS}/${consumerProductId}/heartbeat`);
    }

    getConsumerComments(consumerProductId) {
        return this.axios().get(`${ENDPOINT_CONSUMER_PRODUCTS}/${consumerProductId}/comments`)
    }

    getAppointments(consumerProductId) {
        return this.axios().get(`${ENDPOINT_CONSUMER_PRODUCTS}/${consumerProductId}/appointments`);
    }

    deleteAppointment(consumerProductId, appointmentId) {
        return this.axios().delete(`${ENDPOINT_CONSUMER_PRODUCTS}/${consumerProductId}/appointments/${appointmentId}`);
    }

    createAppointment(consumerProductId, type, date, time) {
        return this.axios().post(`${ENDPOINT_CONSUMER_PRODUCTS}/${consumerProductId}/appointments`, {
            appointment_type: type,
            appointment_date: date,
            appointment_time: time,
        });
    }
}
