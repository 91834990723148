const PREFIX = 'toastui-editor-';

function createToolbarItemOption(container) {
    return {
        name: 'shortcodes',
        tooltip: 'Shortcodes',
        text: 'SC',
        className: 'shortcodes',
        popup: {
            className: `${PREFIX}popup-shortcodes`,
            body: container,
            style: { width: 'auto' },
        },
    };
}

function createShortcodesDropdown(shortcodes) {
    const select = document.createElement('select');

    let option = document.createElement('option');
    option.value = '';
    option.innerText = 'Select Shortcode';
    option.disabled = true;
    option.selected = true;

    select.appendChild(option);

    shortcodes.forEach((shortcode) => {
        option = document.createElement('option');
        option.value = shortcode.value;
        option.innerText = shortcode.label;

        select.appendChild(option);
    });

    return select;
}

function findParentByClassName(el, className) {
    let currentEl = el;

    while (currentEl && !currentEl.classList.contains(className)) {
        currentEl = currentEl.parentElement;
    }

    return currentEl;
}

function getCurrentEditorEl(shortcodePickerEl, containerClassName) {
    const editorDefaultEl = findParentByClassName(shortcodePickerEl, `${PREFIX}defaultUI`);

    return editorDefaultEl.querySelector(`.${containerClassName} .ProseMirror`);
}

let containerClassName;
let currentEditorEl;

export default function shortcodesPlugin(context, shortcodes) {
    const {eventEmitter, usageStatistics = false, pmState} = context;

    eventEmitter.listen('focus', (editType) => {
        containerClassName = `${PREFIX}${editType === 'markdown' ? 'md' : 'ww'}-container`;
    });

    const container = document.createElement('div');
    container.append(createShortcodesDropdown(shortcodes));

    container.querySelector('select').addEventListener('change', (event) => {
        const selectedShortcode = event.target.value;

        currentEditorEl = getCurrentEditorEl(container, containerClassName);

        eventEmitter.emit('command', 'shortcode', { selectedShortcode });
        eventEmitter.emit('closePopup');
        // force the current editor to focus
        currentEditorEl.focus();

        //Reset dropdown
        event.target.value = '';
    });

    const toolbarItem = createToolbarItemOption(container);

    return {
        markdownCommands: {
            shortcode: (payload, state, dispatch) => {
                if(payload.selectedShortcode) {
                    dispatch(state.tr.insertText(`{${payload.selectedShortcode}}`));

                    return true;
                }

                return false;
            }
        },
        wysiwygCommands: {
            shortcode: (payload, state, dispatch) => {
                if(payload.selectedShortcode) {
                    dispatch(state.tr.insertText(`{${payload.selectedShortcode}}`));

                    return true;
                }

                return false;
            }
        },
        toolbarItems: [
            {
                groupIndex: 2,
                itemIndex: 0,
                item: toolbarItem
            }
        ]
    };
}
