import axios from 'axios';

export default class ApiService {
    constructor(baseUrl, baseEndpoint, version) {
        this.baseEndpoint = baseEndpoint;
        this.baseUrl = baseUrl;
        this.version = version;
    }

    axios() {
        const axiosConfig = {
            baseURL: `/${this.baseUrl}/v${this.version}/${this.baseEndpoint}`
        }

        return axios.create(axiosConfig);
    }

    static make() {
        return new ApiService('internal-api', 'task-management', 1);
    }

    getEventCategories(testEvents=false) {
        return this.axios().get('/event-categories', {
            params: {'test_events': testEvents ? 1 : 0}
        });
    }

    getEvents(eventCategory, eventName) {
        return this.axios().get('/events', {
            params: {
                'event_category': eventCategory,
                'event_name': eventName
            }
        });
    }

    getWorkflows(eventId) {
        return this.axios().get(`/workflows/${eventId}`);
    }

    createWorkflow(eventId, entryActionId, name) {
        return this.axios().post('/workflows', {
            'workflow_event_id': eventId === 0 ? null : eventId,
            'entry_action_id': entryActionId,
            'name': name,
            'generic': eventId === 0 ? 1 : 0
        });
    }

    deleteWorkflow(workflowId) {
        return this.axios().delete('/workflows', {
            params: {'id': workflowId}
        });
    }

    updateWorkflowName(workflowId, workflowName) {
        return this.axios().patch(`/workflows/${workflowId}/rename`, {
            'name': workflowName
        })
    }

    saveWorkflowAsTemplate(workflowId) {
        return this.axios().patch(`/workflows/${workflowId}/save-as-template`)
    }

    createWorkflowEvent(eventCategory, eventName) {
        return this.axios().post('/workflow-event', {
            'event_category': eventCategory,
            'event_name': eventName
        });
    }

    createWorkflowAction(workflowId, payload) {
        return this.axios().post(`/workflows/${workflowId}/actions`, payload);
    }

    updateWorkflowAction(workflowAction) {
        return this.axios().patch(`/workflows/${workflowAction.workflow_id}/actions/${workflowAction.id}`, workflowAction);
    }

    deleteWorkflowAction(workflowAction) {
        return this.axios().delete(`/workflows/${workflowAction.workflow_id}/actions/${workflowAction.id}`);
    }

    getWorkflowShortcodes() {
        return this.axios().get('/workflow-shortcodes');
    }

    fireTestEvent(eventCategory, eventName, companyId, campaignId, leadId) {
        return this.axios().post('/create-test-event', {
            'event_category': eventCategory,
            'event_name'    : eventName,
            'company_id'    : companyId,
            'campaign_id'   : campaignId,
            'lead_id': leadId
        });
    }

    getTaskTypes() {
        return this.axios().get('/task-types');
    }

    getModules() {
        return this.axios().get('/modules');
    }

    createTaskType(taskName) {
        return this.axios().post('/task-types', {name: taskName});
    }

    deleteTaskType(id) {
        return this.axios().delete(`/task-types/${id}`);
    }

    saveTaskType(id, name, modules) {
        return this.axios().patch(`/task-types/${id}`, {id, name, modules});
    }

    getTaskCategories() {
        return this.axios().get('/task-categories');
    }

    getDynamicPriorityTypes() {
        return this.axios().get('/dynamic-priority-types');
    }

    createTaskCategory(payload) {
        return this.axios().post('/task-categories', payload);
    }

    updateTaskCategory(categoryId, payload) {
        return this.axios().patch(`/task-categories/${categoryId}`, payload);
    }
}
