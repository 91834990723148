<template>
    <div class="relative flex flex-auto justify-center items-center">
        <div>
            <div :class="[size, borderColor, 'loading-spinner rounded-full border-r-3 border-r-2 mx-auto my-4 relative']">
            </div>
            <p class="text-center"
               :class="{'text-grey-120' : darkMode, 'text-grey-400': !darkMode}"
               v-if="label.length > 0">{{ label }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "LoadingSpinner",
    props: {
        label: {
            type: String,
            default: ""
        },
        darkMode: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'w-16 h-16'
        },
        borderColor: {
            type: String,
            default: 'border-primary-500'
        }
    }
}
</script>

<style scoped>
.loading-spinner {
    animation: spin 1.3s linear infinite;
}

@keyframes spin {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>

