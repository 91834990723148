<template>
    <div class="border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="px-5 pt-5 pb-4">
            <div class="flex items-center justify-between">
                <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Activity</h5>
                <button
                    class="ml-2 my-1 transition duration-200 inline-flex items-center font-semibold bg-grey-475 hover:bg-grey-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5"
                    @click="toggleNoteModal(true)">
                    Add Action
                </button>
            </div>
<!--        <div class="relative mb-3" v-if="showFilters">
                <input class="w-full border rounded pl-8  focus:outline-none focus:border focus:border-primary-500 pr-4 py-2 h-9" placeholder="Search activity" type="text" v-model="filterEventName"
                       :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>
                <div class="absolute top-0 left-0 w-8 h-full flex justify-center items-center">
                    <svg class="inset-y-0 fill-current text-grey-400" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.66667 2C4.69421 2 3.76158 2.38631 3.07394 3.07394C2.38631 3.76158 2 4.69421 2 5.66667C2 6.14818 2.09484 6.62498 2.27911 7.06984C2.46338 7.5147 2.73346 7.91891 3.07394 8.25939C3.41442 8.59987 3.81863 8.86996 4.26349 9.05423C4.70835 9.23849 5.18515 9.33333 5.66667 9.33333C6.14818 9.33333 6.62498 9.23849 7.06984 9.05423C7.5147 8.86996 7.91891 8.59987 8.25939 8.25939C8.59987 7.91891 8.86996 7.5147 9.05423 7.06984C9.23849 6.62498 9.33333 6.14818 9.33333 5.66667C9.33333 4.69421 8.94703 3.76158 8.25939 3.07394C7.57176 2.38631 6.63913 2 5.66667 2ZM1.65973 1.65973C2.72243 0.597022 4.16377 0 5.66667 0C7.16956 0 8.6109 0.597022 9.6736 1.65973C10.7363 2.72243 11.3333 4.16377 11.3333 5.66667C11.3333 6.41082 11.1868 7.14769 10.902 7.83521C10.7458 8.21219 10.5498 8.57029 10.3178 8.90361L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L8.90361 10.3178C8.57029 10.5498 8.21219 10.7458 7.83521 10.902C7.14769 11.1868 6.41082 11.3333 5.66667 11.3333C4.92251 11.3333 4.18564 11.1868 3.49813 10.902C2.81062 10.6172 2.18593 10.1998 1.65973 9.6736C1.13353 9.14741 0.716126 8.52272 0.431349 7.83521C0.146573 7.1477 0 6.41082 0 5.66667C0 4.16377 0.597022 2.72243 1.65973 1.65973Z"/>
                    </svg>
                </div>
            </div>-->
            <div class="flex items-center" v-if="showFilters">
                <div class="flex items-center mr-3">
                    <p class="text-grey-300 uppercase text-xs mr-2">Filters</p>
                    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 4H13V6H3V4ZM0 0H16V2H0V0ZM6 8H10V10H6V8Z" fill="#ABB0B5"/>
                    </svg>
                </div>

                <div class="grid grid-cols-2 w-full gap-5">
                    <div>
                        <p class="text-grey-400 uppercase text-xs mr-2">Users</p>
                        <Dropdown :dark-mode="darkMode" class="mr-2" :options="users" v-model="selectedFilterUser" />
                    </div>
                    <div>
                        <p class="text-grey-400 uppercase text-xs mr-2">Contacts</p>
                        <Dropdown :dark-mode="darkMode" class="mr-2" :options="companyContactsWithActions" v-model="selectedFilterContact" />
                    </div>
                </div>

<!--            <Dropdown :dark-mode="darkMode" class="mr-2" placeholder="Team"/>
                <Dropdown :dark-mode="darkMode" placeholder="Type"/>-->
            </div>
        </div>
        <div class="border-t border-b overflow-y-auto"
             :class="[darkMode ? 'bg-dark-background border-dark-border' : 'bg-light-background  border-light-border', tableHeight]">
            <template v-if="!loading">
                <AlertsContainer :dark-mode="darkMode" v-if="alertActive" :alert-type="alertType" :text="alertText" />
                <template v-for="(action, idx) in actions" :key="action.id">
                    <div v-if="checkFilters(action)"
                         class="relative"
                         :class="{ 'border-l-primary-500 border-l-4': action.pinned }">
                        <div class="absolute top-1 select-none cursor-pointer w-6 scale-x-75 "
                             :class="{ 'text-primary-500': action.pinned, 'text-slate-300 hover:text-primary-200': !action.pinned && !darkMode, 'text-slate-600 hover:text-primary-200': !action.pinned && darkMode }"
                             @click="toggleActionPinned(action.id)"
                             @mouseover="mouseOverPin = action.id"
                             @mouseleave="mouseOverPin = null"
                        >
                            <svg v-if="action.pinned && mouseOverPin == action.id" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM20.25 5.507v11.561L5.853 2.671c.15-.043.306-.075.467-.094a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93zM3.75 21V6.932l14.063 14.063L12 18.088l-7.165 3.583A.75.75 0 013.75 21z" />
                            </svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                <path fill-rule="evenodd" d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z" clip-rule="evenodd" />
                            </svg>
                        </div>
                        <div class="absolute top-1 right-2 select-none text-xs uppercase"
                            :class="{ 'text-primary-300': darkMode, 'text-primary-700': !darkMode }">
                            {{ action.pinned ? ('Pinned' + (action.category && action.category.length > 0 ? ' - ' : ''))  : '' }}
                            {{ action.category ?? '' }}
                        </div>
                        <div @click="toggleExpandActivity(action.id)"  class="grid grid-cols-1 gap-x-3 border-b px-5"
                             :class="{
                                'bg-primary-50 hover:bg-primary-100': action.pinned && !darkMode,
                                'bg-dark-module hover:bg-dark-module': action.pinned && darkMode,
                                'hover:bg-light-module': !action.pinned && !darkMode,
                                'hover:bg-dark-module': !action.pinned && darkMode,
                                'text-slate-900 border-light-border': !darkMode,
                                'text-slate-100 hover:bg-dark-module border-dark-border': darkMode
                            }">
                            <div class="py-4 cursor-pointer">
                                <div class="flex items-center justify-between">
                                    <div class="flex items-center w-full">
                                        <svg :class="{'transform transition duration-200 rotate-90' : openedActivities.includes(action.id) }" class="mr-4" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 9L5 5L1 1" stroke="#0081FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <div class="w-full">
                                            <div class="flex justify-start items-center">
                                                <p class="text-xs text-grey-400 mr-5">
                                                    Created: {{ $filters.dateFromTimestamp(action.created_timestamp, 'usWithTime') }}
                                                </p>
                                                <p v-if="action.updated_timestamp && action.updated_timestamp > action.created_timestamp" class="text-xs text-grey-400">
                                                    Last Updated: {{ $filters.dateFromTimestamp(action.updated_timestamp, 'usWithTime') }}
                                                </p>
                                            </div>

                                            <div class="flex items-center">
                                                <p class="truncate pb-0 font-semibold" style="max-width: 14rem;">
                                                    {{ action.subject }}
                                                </p>
                                                <p v-if="action.from" class="text-sm truncate text-grey-400 ml-2" style="max-width: 14rem;">
                                                    from {{ action.from }}
                                                </p>
                                            </div>
                                            <div v-if="action.to"  class="flex items-center">
                                                <p class="text-sm truncate text-grey-400" style="max-width: 14rem;">
                                                    to {{ action.to }}
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <actions-handle :dark-mode="darkMode" :no-delete-button="true" @edit="toggleNoteModal(true, action)" @click.stop/>
                                        </div>
                                    </div>
                                </div>
                                <div class="px-5 pt-2" v-if="openedActivities.includes(action.id)">
                                    <p class="text-sm border-l border-primary-500 pl-3" :class="{'text-grey-500': !darkMode, 'text-grey-300': darkMode}" v-html="$filters.scrubHtml(action.message)">
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
            <div v-else class="flex items-center justify-center h-full">
                <loading-spinner :dark-mode="darkMode"></loading-spinner>
            </div>
        </div>
        <div v-if="paginationData && paginationData.to" class="p-3">
            <Pagination :dark-mode="darkMode" :pagination-data="paginationData" :show-pagination="true" :show-total-records-detail="false" @change-page="handlePaginationEvent"></Pagination>
        </div>

        <action-note-modal
            v-if="showNoteModal"
            :dark-mode="darkMode"
            :action="editAction"
            :company-id="companyId"
            :task-id="task?.id || 0"
            @close="toggleNoteModal(false)"
            @reload-actions="getActions"
        />

    </div>
</template>

<script>
import Dropdown from "../components/Dropdown";
import SharedApiService from "../services/api";
import LoadingSpinner from "../components/LoadingSpinner";
import Modal from "../components/Modal";
import ApiService from "../../Tasks/services/api";
import Pagination from "../components/Pagination";
import MarkdownEditorMixin from "../mixins/markdown-editor-mixin";
import AlertsMixin from "../../../mixins/alerts-mixin";
import AlertsContainer from "../components/AlertsContainer";
import ActionsHandle from "../components/ActionsHandle";
import ActionNoteModal from "../components/ActionNoteModal";

export default {
    name: "Activity",
    components: {
        ActionNoteModal,
        ActionsHandle,
        AlertsContainer,
        Dropdown,
        LoadingSpinner,
        Modal,
        Pagination,
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        companyId: {
            type: Number,
            default: null
        },
        showFilters: {
            type: Boolean,
            default: false
        },
        task: {
            type: Object,
            default: {}
        },
        tableHeight: {
            type: String,
            default: 'h-100'
        }
    },
    mixins: [ MarkdownEditorMixin, AlertsMixin ],
    data() {
        return {
            expandActivity: false,
            openedActivities: [],
            api: SharedApiService.make(),
            taskApi: ApiService.make(),
            paginationData: null,
            actions: [],

            loading: false,
            companyContacts: [],
            users: [],
            contactsFilterOptions: [
                {id: 'all', name: 'All Contacts'},
                {id: 'notes', name: 'Contacts w/ Notes'}
            ],

            showNoteModal: false,

            selectedFilterUser: 0,
            selectedFilterContact: '',

            editAction: null,
            saving: false,
            mouseOverPin: null,

            errorMessages: {
                pinAction: 'An unknown error occurred while pinning an Action.'
            }
        }
    },
    created() {
        this.getUsers();

        if (this.companyId) {
            this.getActions();
            this.getCompanyContacts();
        }
    },
    watch: {
        companyId(newVal, oldVal) {
            if(newVal !== oldVal) {
                this.getActions();
                this.getCompanyContacts();
            }
        },
        selectedFilterUser(newVal, oldVal) {
            if(newVal !== oldVal) {
                this.$forceUpdate();
            }
        },
        selectedFilterContact(newVal, oldVal) {
            if(newVal !== oldVal) {
                this.$forceUpdate();
            }
        }
    },
    computed: {
        companyContactsWithActions: function() {
            let companyContactsWithActions = [];
            let added = [];

            this.actions.forEach((action) => {
                if(!action.to_company
                && !added.includes(action.to)) {
                    companyContactsWithActions.push({
                        id: action.to,
                        name: action.to
                    });

                    added.push(action.to);
                }
            });

            return [{id: '', name: 'All'}, {id: 'company', name: 'Company'}, ...companyContactsWithActions];
        }
    },
    methods: {
        toggleExpandActivity(id) {
            if(this.openedActivities.includes(id))
                this.openedActivities.splice(this.openedActivities.indexOf(id), 1);
            else
                this.openedActivities.push(id);
        },
        getActions() {
            this.loading = true;
            this.api.getCompanyActions(this.companyId).then(resp => this.addPaginatedData(resp))
                .finally(() => this.loading = false);
        },
        getCompanyContacts() {
            this.api.getCompanyContacts(this.companyId).then(resp => this.companyContacts = resp.data.data.contacts);
        },
        getUsers() {
            this.api.getUsers().then(resp => {
                this.users.push({
                    id: 0,
                    name: 'All'
                });

                resp.data.data.users.forEach((user) => {
                    this.users.push({
                        id: user.id,
                        name: user.name
                    });
                });
            });
        },
        checkFilters(action) {
            if(this.selectedFilterUser
            && action.from_user_id !== this.selectedFilterUser) {
                return false;
            }

            if(this.selectedFilterContact.length) {
                if(this.selectedFilterContact === 'company'
                && !action.to_company) {
                    return false;
                }
                else if(this.selectedFilterContact !== 'company'
                    && action.to !== this.selectedFilterContact) {
                    return false;
                }
            }

            return true;
        },
        toggleNoteModal(show, action = null) {
            this.editAction = show && action ? action : null;
            this.showNoteModal = show;
        },
        async handlePaginationEvent(newPageUrl) {
            this.loading = true;
            await axios.get(newPageUrl.link).then(resp => this.addPaginatedData(resp))
                .finally(() => this.loading = false);
        },
        addPaginatedData(resp) {
            if(resp.data.data.status === true) {
                let {data, ...paginationData} = resp.data.data.actions;
                this.actions = data;
                this.paginationData = paginationData;
            }
        },
        toggleActionPinned(actionId) {
            this.api.toggleActionPin(actionId, this.companyId).then(resp => {
                if (!resp.data?.data?.status) throw new Error();
            }).catch(err => {
                this.showAlert(err.response?.data?.message || this.errorMessages.pinAction);
            }).finally(() => this.getActions());
        }
    }
}
</script>

<style scoped>

</style>
