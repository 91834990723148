<template>
    <div class="grid lg:grid-cols-4 gap-4">
        <div class="lg:col-span-4 rounded-lg bg-red-100 p-4 mb-3" v-if="error">
            <p class="text-sm font-medium text-red-800">{{ error }}</p>
        </div>
        <div class="lg:col-span-4 border rounded-lg"
             :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
            <div class="p-5 flex items-center justify-between">
                <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Roles</h5>
                <div class="">
                    <button @click="addRole"
                        class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5">
                        Add New Role
                    </button>
                </div>
            </div>
            <div class="grid grid-cols-8 gap-3 mb-2 px-5">
                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs">ID</p>
                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Name</p>
                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-4">Permissions</p>
                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs">Edit</p>
            </div>
            <div class="flex items-center justify-center h-80" v-if="saving">
                <loading-spinner></loading-spinner>
            </div>
            <div class="border-t border-b h-80 overflow-y-auto" v-else
                 :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
                <div>
                    <div class="grid grid-cols-8 gap-x-3 border-b px-5 py-3"
                         v-for="role in roles" :key="role.id"
                         :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                        <p class="text-sm">
                            {{ role.id }}
                        </p>
                        <p class="text-sm col-span-2 truncate">
                            {{ role.name }}
                        </p>
                        <p class="text-sm col-span-4">
                            {{ role.permissions.map(permission => permission.name).join(', ') }}
                        </p>
                        <p class="ml-2">
                            <button @click="editRole(role)">
                                <svg width="12" height="12" viewBox="0 0 11 11" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M3.37773 10.2347L8.87785 4.7346L6.2654 2.12215L0.765271 7.62227C0.689551 7.69809 0.635763 7.79298 0.609613 7.89689L0 11L3.10251 10.3904C3.20668 10.3643 3.30197 10.3105 3.37773 10.2347V10.2347ZM10.6534 2.95903C10.8753 2.73705 11 2.43602 11 2.12215C11 1.80827 10.8753 1.50724 10.6534 1.28526L9.71474 0.346575C9.49276 0.124663 9.19173 0 8.87785 0C8.56397 0 8.26295 0.124663 8.04097 0.346575L7.10228 1.28526L9.71474 3.89772L10.6534 2.95903Z"
                                        fill="#0081FF"/>
                                </svg>
                            </button>
                        </p>
                    </div>
                </div>
            </div>
            <div class="p-3"></div>
            <Modal v-if="showModal" @confirm="saveRole" @close="closeModal" :close-text="'Cancel'" :confirm-text="editingRole.id ? 'Update' : 'Create'" :dark-mode="darkMode" :small="true">
                <template v-slot:header>
                    <h4 class="text-xl">{{ editingRole.id ? 'Edit Role' : 'Add Role' }}</h4>
                </template>
                <template v-slot:content>
                    <div class="mb-4">
                        <label class="block pb-2 font-medium">Name</label>
                        <input v-model="editingRole.name" class="w-full border rounded px-4 py-3" placeholder="Enter role name" type="text"
                               :class="{'border-grey-350 bg-light-module': !darkMode, 'border-blue-400 bg-dark-background text-blue-400': darkMode}"
                        />
                    </div>
                    <div>
                        <label class="block pb-2 font-medium font-bold">Permissions</label>
                        <div class="mb-5">
                            <div v-for="(permission, index) in editingRole.permissions" class="flex items-center justify-between">
                                <span>{{ index + 1 }}. {{ permission.name }}</span>
                                <span class="text-lg text-red-500 ml-2 font-bold cursor-pointer" @click="removePermission(index)">X</span>
                            </div>
                        </div>
                        <div class="flex justify-between items-center mb-2">
                            <dropdown :options="permissions" v-model="permission" :placeholder="'Select Permission'" :dark-mode="darkMode"></dropdown>
                            <span class="text-4xl text-blue-500 ml-2 font-bold cursor-pointer" @click="addPermission">+</span>
                        </div>
                    </div>
                </template>
            </Modal>
        </div>
    </div>
</template>

<script>
import Modal from "../../Shared/components/Modal";
import LoadingSpinner from "../../LeadProcessing/components/LoadingSpinner";
import Dropdown from "../../Shared/components/Dropdown";

export default {
    name: "Roles",
    components: {Dropdown, Modal, LoadingSpinner},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        roles: {
            type: Array,
            default: []
        },
        permissions: {
            type: Array,
            default: []
        },
        error: {
            type: String,
            default: null
        },
        saving: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            editingRole: {},
            showModal: false,
            permission: null
        }
    },
    methods: {
        addRole() {
            this.editingRole = {permissions: []};
            this.showModal = true;
        },
        editRole(role) {
            this.editingRole = {...role};
            this.showModal = true;
        },
        saveRole() {
            this.editingRole.permissions = this.editingRole.permissions.map(permission => permission.id);

            if (this.editingRole.id) this.$emit('update-role', this.editingRole);
            else this.$emit('create-role', this.editingRole);

            this.closeModal();
        },
        closeModal() {
            this.editingRole = {};
            this.showModal = false;
        },
        addPermission() {
            const permission = this.permissions.find(permission => permission.id === this.permission);

            if (permission && this.editingRole.permissions.findIndex(perm => perm.id === permission.id) === -1) this.editingRole.permissions.push(permission);
        },
        removePermission(index) {
            this.editingRole.permissions.splice(index, 1);
        }
    }
}
</script>

<style scoped>

</style>
