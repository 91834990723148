<template>
    <div class="border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="p-5 flex items-center justify-between">
            <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Account Managers List</h5>
            <button @click="addAccountManager"
                    class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5">
                Add Account Manager
            </button>
        </div>
        <div class="grid grid-cols-7 gap-x-3 mb-2 px-5">
            <p class="text-slate font-medium-400 uppercase text-xs col-span-2">User</p>
            <p class="text-slate font-medium-400 uppercase text-xs col-span-2">Type</p>
            <p class="text-slate font-medium-400 uppercase text-xs col-span-2">ST Round Robin</p>
            <p class="text-slate font-medium-400 uppercase text-xs"></p>
        </div>
        <div class="border-t border-b h-64 overflow-y-auto"
             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
            <div>
                <div class="grid grid-cols-7 gap-x-3 border-b px-5 py-3"
                     v-for="accountManager in accountManagers" :key="accountManager.id"
                     :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <p class="text-sm col-span-2 truncate">
                        {{ accountManager.name }}
                    </p>
                    <p class="text-sm col-span-2 pl-1 truncate">
                        {{ accountManager.type }}
                    </p>
                    <p class="text-sm col-span-2 pl-1 truncate">
                        {{ accountManager.include_in_sales_round_robin === 1 ? "Yes" : "No" }}
                    </p>
                    <ActionsHandle :dark-mode="darkMode" @edit="editAccountManager(accountManager)"
                                   @delete="deleteAccountManager(accountManager.id)"/>
                </div>
            </div>
        </div>
        <div class="p-3"></div>
        <Modal :dark-mode="darkMode" :small="true" @close="toggleAccountManagerModal" close-text="Cancel"
               @confirm="editId > 0 ? updateAccountManager() : createAccountManager()"
               :confirm-text="editId > 0 ? 'Update Account Manager' : 'Save & Add Account Manager'" v-if="accountManagerModal">
            <template v-slot:header>
                <h4 class="text-xl font-medium">{{ editId > 0 ? 'Edit' : 'Add' }} Account Manager</h4>
            </template>
            <template v-slot:content>
                <div class="px-6">
                    <div class="mb-6">
                        <label class="block pb-2 font-medium">User</label>
                        <Autocomplete
                            :input-class="['w-full border border-grey-350 rounded px-4 py-3', {'border-grey-350 bg-light-module': !darkMode, 'border-blue-400 bg-dark-background text-blue-400': darkMode}]"
                            :list-class="['absolute top-0 w-full border rounded', {'border-grey-200 bg-light-module': !darkMode, 'border-dark-40 bg-dark-module': darkMode}]"
                            :option-class="['cursor-pointer px-5 py-2 my-1', {'hover:bg-grey-100': !darkMode, 'hover:bg-blue-800': darkMode}]"
                            v-model="selectedUserId"
                            :value="selectedUserId"
                            :options="userOptions"
                            @search="searchUsers"
                            :placeholder="'Enter Name'">
                        </Autocomplete>
                    </div>
                    <div class="mb-5 grid grid-cols-2 gap-2">
                        <div>
                            <p class="block pb-2 font-medium">Choose a type</p>
                            <Dropdown :dark-mode="darkMode" :options="accountManagerTypes" v-model="selectedType"
                                      :selected="editType"/>
                        </div>
                    </div>
                    <div class="mb-5 grid grid-cols-2 gap-2">
                        <div>
                            <p class="block pb-2 font-medium">Include in sales tool round robin?</p>
                            <Dropdown :dark-mode="darkMode" :options="roundRobinTypes" v-model="includeInSalesToolRoundRobin"
                                      :selected="includeInSalesToolRoundRobin"/>
                        </div>
                    </div>
                </div>
                <div v-if="error !== null" class="my-4 font-bold text-red-400">{{ error }}</div>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from "../../Shared/components/Modal";
import Dropdown from "../../Shared/components/Dropdown";
import ActionsHandle from "../../Shared/components/ActionsHandle";
import Autocomplete from "../../Shared/components/Autocomplete";

export default {
    name: "AccountManagersList",
    components: {
        ActionsHandle,
        Modal,
        Dropdown,
        Autocomplete
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        accountManagers: {
            type: Array,
            default: () => []
        },
        types: {
            type: Array,
            default: () => []
        },
        userSearchOptions: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            accountManagerModal: false,
            selectedUserId: -1,
            selectedType: null,
            editType: null,
            editId: 0,
            error: null,
            includeInSalesToolRoundRobin: 0,
            roundRobinTypes: [
                {id: 0, name: "No"},
                {id: 1, name: "Yes"}
            ]
        }
    },
    computed: {
        userOptions: {
            get() {
                return this.userSearchOptions;
            },
            set(value) {
                this.$emit('change-user-search-options', value);
            }
        },
        accountManagerTypes: function() {
            let accountManagerTypes = [];

            this.types.forEach((type) => {
                accountManagerTypes.push({
                    id: type,
                    name: type[0].toUpperCase() + type.slice(1)
                });
            });

            return accountManagerTypes;
        }
    },
    methods: {
        toggleAccountManagerModal(editType = null, selectedUserId = null, selectedType = null, userOptions = [], includeInSalesToolRoundRobin = 0) {
            this.accountManagerModal = !this.accountManagerModal;

            this.editType = editType;
            this.selectedType = selectedType;
            this.selectedUserId = selectedUserId;
            this.userOptions = userOptions;
            this.includeInSalesToolRoundRobin = includeInSalesToolRoundRobin;
            this.error = null;
        },
        addAccountManager() {
            this.editId = 0;

            this.toggleAccountManagerModal();
        },
        createAccountManager() {
            if (!this.selectedUserId || !this.selectedType) {
                return this.error = "Please enter the required fields.";
            }

            this.$emit('save-account-manager', {userId: this.selectedUserId, type: this.selectedType, includeInSalesToolRoundRobin: this.includeInSalesToolRoundRobin});

            this.toggleAccountManagerModal();
        },
        searchUsers(query) {
            this.userOptions = [];
            this.$emit('search-users', query);
        },
        deleteAccountManager(id) {
            this.$emit('delete-account-manager', id);
        },
        editAccountManager(manager) {
            this.editId = manager.id;

            this.toggleAccountManagerModal(
                manager.type,
                manager.user_id,
                manager.type,
                [
                    {
                        id: manager.user_id,
                        name: manager.name
                    }
                ],
                manager.include_in_sales_round_robin
            );
        },
        updateAccountManager() {
            if (!this.selectedUserId || !this.selectedType || !this.editId) {
                return this.error = "Please enter the required fields.";
            }

            this.$emit('update-account-manager', {id: this.editId, userId: this.selectedUserId, type: this.selectedType, includeInSalesToolRoundRobin: this.includeInSalesToolRoundRobin});

            this.toggleAccountManagerModal();
        }
    }
}
</script>

<style scoped>

</style>
