import CommunicationFactory from "../../../services/communication/factory";

export default {
    props: {
        communicationDriver: {
            type: String,
            default: null
        },
        communicationToken: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            communicationService: null,
        }
    },
    mounted() {
        this.communicationService = CommunicationFactory.makeService(this.communicationDriver, this.communicationToken);
    }
}
