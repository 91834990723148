<template>
    <div class="mt-5">
        <p class="uppercase font-semibold text-xs mb-1 mt-5"
           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
            Display Name (UI Only)
        </p>
        <input type="text" class="w-full border rounded focus:outline-none focus:border focus:border-primary-500 py-2" placeholder="Display Name"
               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}" v-model="displayName"/>

        <p class="uppercase font-semibold text-xs mb-1 mt-5"
           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
            Value Type
        </p>
        <Dropdown v-model="value.valueType" :options="valueTypes" :dark-mode="darkMode"></Dropdown>
        <p class="uppercase font-semibold text-xs mb-1 mt-5"
           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}" v-if="value.valueType === 'raw'">
            Value
        </p>
        <shortcode-input :dark-mode="darkMode" v-model="value.value" v-if="value.valueType === 'raw'"></shortcode-input>

        <p class="uppercase font-semibold text-xs mb-1 mt-5"
           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
            Operator Type
        </p>
        <Dropdown v-model="operator" :options="operatorTypes" :dark-mode="darkMode"></Dropdown>

        <p class="uppercase font-semibold text-xs mb-1 mt-5"
           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
            Comparison Value Type
        </p>
        <Dropdown v-model="comparisonValue.valueType" :options="valueTypes" :dark-mode="darkMode"></Dropdown>

        <p class="uppercase font-semibold text-xs mb-1 mt-5"
           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}" v-if="comparisonValue.valueType === 'raw'">
            Comparison Value
        </p>
        <shortcode-input :dark-mode="darkMode" v-model="comparisonValue.value" v-if="comparisonValue.valueType === 'raw'"></shortcode-input>

        <p class="uppercase font-semibold text-xs mb-1 mt-5"
           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}" v-if="comparisonValue.valueType === 'array'">
            Comparison Values
        </p>
        <text-selector :dark-mode="darkMode" v-model="comparisonValue.value"  v-if="comparisonValue.valueType === 'array'"></text-selector>
    </div>
</template>

<script>
import Dropdown from "../../../Shared/components/Dropdown";
import ShortcodeInput from "../../../Shared/components/ShortcodeInput";
import TextSelector from "../../../Shared/components/TextSelector";

export default {
    name: "ComparisonNode",
    components: {TextSelector, ShortcodeInput, Dropdown},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        action: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            value: {
                valueType: null,
                value: null,
            },
            comparisonValue: {
                valueType: null,
                value: null,
            },
            valueTypes: [
                {id: 'raw', name: "Text/Shortcode Entry"},
                {id: 'previous_result_entry', name: "Previous Result Entry"},
                {id: 'array', name: "Multiple Options"},
            ],
            operatorTypes: [
                {id: '>', name: "Greater Than (>)"},
                {id: '>=', name: "Greater Than or Equal (>=)"},
                {id: '<', name: "Less Than (<)"},
                {id: '<=', name: "Less Than or Equal (<=)"},
                {id: '==', name: "Equal (=)"},
                {id: '!=', name: "Not Equal (!=)"},
                {id: 'in', name: "In"},
            ],
            displayName: null,
            operator: null,
        }
    },
    created() {
        if (this.action) {
            this.value.valueType = this.action.payload.value.value_type;
            this.value.value = this.action.payload.value.value;
            this.comparisonValue.valueType = this.action.payload.comparison_value.value_type;
            this.comparisonValue.value = this.action.payload.comparison_value.value;
            this.operator = this.action.payload.operator;
            this.displayName = this.action.payload.display_name;

            this.setArrayValue();
        }
    },
    watch: {
        action: function () {
            if (this.action) {
                this.value.valueType = this.action.payload.value.value_type;
                this.value.value = this.action.payload.value.value;
                this.comparisonValue.valueType = this.action.payload.comparison_value.value_type;
                this.comparisonValue.value = this.action.payload.comparison_value.value;
                this.operator = this.action.payload.operator;
                this.displayName = this.action.payload.display_name;
            } else {
                this.value.valueType = null;
                this.value.value = null;
                this.comparisonValue.valueType = null;
                this.comparisonValue.value = null;
                this.operator = null;
                this.displayName = "Comparison Node";
            }
        },
        ["comparisonValue.valueType"]: function() {
            this.setArrayValue();
        }
    },
    methods: {
        getDisplayName() {
            return this.displayName;
        },
        getPayload() {
            return {
                operator: this.operator,
                value: {
                    value_type: this.value.valueType,
                    value: this.value.value,
                },
                comparison_value: {
                    value_type: this.comparisonValue.valueType,
                    value: this.comparisonValue.value,
                },
                display_name: this.displayName
            }
        },
        setArrayValue() {
            if(this.comparisonValue.valueType === "array" && !Array.isArray(this.comparisonValue.value))
                this.comparisonValue.value = [];
        }
    }
}
</script>

<style scoped>

</style>
