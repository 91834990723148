<template>
    <div class="flex items-center justify-center relative">
        <div @click="toggleActionsHandle" class="relative inline-flex items-center justify-center cursor-pointer p-2 rounded-full">
            <div class="bg-primary-500 h-1 w-1 rounded-full mr-1"></div>
            <div class="bg-primary-500 h-1 w-1 rounded-full mr-1"></div>
            <div class="bg-primary-500 h-1 w-1 rounded-full mr-1"></div>
        </div>
        <div v-if="actionsHandle" class="absolute top-0 right-0 z-50 border shadow-module rounded overflow-hidden"
             :class="[darkMode ? 'border-blue-400 bg-dark-background text-blue-400' : 'border-light-border bg-light-module', width]"
        >
            <p class="py-2 px-3 border-b cursor-pointer transition duration-200"
               :class="{'border-light-border bg-light-module hover:bg-grey-120': !darkMode, 'border-blue-400 bg-dark-background hover:bg-dark-175 text-blue-400': darkMode}"
                @click="actionClicked('edit')"
            >{{actionText1}}</p>
            <p class="py-2 px-3 border-b cursor-pointer transition duration-200"
               :class="{'border-light-border bg-light-module hover:bg-grey-120': !darkMode, 'border-blue-400 bg-dark-background hover:bg-dark-175 text-blue-400': darkMode}"
                @click="actionClicked('delete')"
            >{{actionText2}}</p>
        </div>
        <div v-if="actionsHandle" @click="toggleActionsHandle" class="fixed z-40 w-full h-full bg-transparent">

        </div>
    </div>
</template>

<script>
export default {
    name: "ActionsHandle",
    data() {
        return {
            actionsHandle: false,
        }
    },
    props: {
        darkMode: false,
        actionText1: {
            type: String,
            default: 'Edit'
        },
        actionText2: {
            type: String,
            default: 'Delete'
        },
        width: {
            type: String,
            default: 'w-40'
        }
    },
    methods: {
        toggleActionsHandle() {
            this.actionsHandle = ! this.actionsHandle
        },
        actionClicked(action) {
            this.toggleActionsHandle();
            this.$emit(action);
        }
    }
}
</script>

<style scoped>

</style>
