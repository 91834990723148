<template>
    <!-- Pre-Raised Reviews -->
    <div id="pre-raised-reviews" class="border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="py-5">
            <h5 class="pl-5 text-primary-500 text-sm uppercase font-semibold pb-4 leading-tight">Pre-Raised Reviews</h5>
            <div class="grid grid-cols-7 mb-2 px-5 ml-2">
                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs">Id</p>
                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Date</p>
                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-4">Company</p>
            </div>
            <loading-spinner v-if="loading" :dark-mode="darkMode" />
            <div v-else class="border-t border-l border-r h-auto max-h-48 overflow-y-auto"
                 :class="{'bg-light-background border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
                <div class="border-b" :class="{ 'border-light-border': !darkMode, 'border-dark-border': darkMode }" v-if="consumerReviews.length > 0">
                    <div class="grid grid-cols-7 items-center px-5 py-1 border-b ml-1" v-for="review in consumerReviews"
                         :class="{ 'text-grey-800 hover:bg-light-module border-light-border': !darkMode, 'text-grey-200 hover:bg-dark-module border-dark-border': darkMode }">
                        <p class="text-xs">
                            {{ review.id }}
                        </p>
                        <p class="text-xs col-span-2">
                            {{ $filters.dateFromTimestamp(review.created_at) ?? 'Unknown' }}
                        </p>
                        <p class="text-xs col-span-4 overflow-x-clip whitespace-nowrap overflow-ellipsis">
                            {{ review.company?.name ?? 'Not found' }}
                        </p>
                    </div>
                </div>
                <div v-else>
                    <div class="px-5 py-2 text-s border-b"
                         :class="{'text-grey-800 border-light-border': !darkMode, 'text-grey-200 border-dark-border': darkMode}">
                        No previous reviews found for this Consumer
                    </div>
                </div>
            </div>
            <div v-if="paginationData && paginationData.to" class="px-3 pt-5">
                <pagination :dark-mode="darkMode" :pagination-data="paginationData" :show-pagination="true" :show-total-records-detail="false" @change-page="handlePaginationEvent"></pagination>
            </div>
            <alert :dark-mode="darkMode" v-if="alertActive" :alert-type="alertType" :text="alertText" />
        </div>
    </div>
</template>

<script>
import Alert from "../../components/Alert";
import AlertsMixin from "../../../../mixins/alerts-mixin";
import ConsumerApiService from "../../services/consumer_api";
import LoadingSpinner from "../../components/LoadingSpinner";
import Pagination from "../../components/Pagination";

export default {
    name: "ConsumerReviews",
    components: {
        LoadingSpinner,
        Alert,
        Pagination
    },
    props: {
        consumerProductId: {
            type: Number,
            default: null,
        },
        darkMode: {
            type: Boolean,
            default: false,
        }
    },
    mixins: [
        AlertsMixin
    ],
    data() {
        return {
            api: ConsumerApiService.make(),
            consumerReviews: [],
            paginationData: null,
            loading: false,
        }
    },
    methods: {
        async getReviews() {
            this.loading = true;
            this.api.getReviewsByConsumerProductId(this.consumerProductId).then(resp => {
                if (resp.data.data.status) this.addPaginatedData(resp.data.data.reviews);
                else this.showAlert('error', `Error fetching Consumer reviews from server.`);
            }).catch(() => this.showAlert('error', `Error fetching Consumer reviews from server.`)
            ).finally(() => this.loading = false);
        },
        async handlePaginationEvent(newPageUrl) {
            this.loading = true;
            await axios.get(newPageUrl.link).then(resp => {
                if (resp.data.data.reviews.data?.length) this.addPaginatedData(resp.data.data.reviews);
                else this.showAlert('error', `Error fetching Consumer reviews from server.`);
            }).catch(() => this.showAlert('error', `Error fetching Consumer reviews from server.`)
            ).finally(() => this.loading=false);
        },
        addPaginatedData({ data, ...paginationData }) {
            if (data && paginationData) {
                this.consumerReviews = data;
                this.paginationData = paginationData;
            }
            else this.showAlert('error', `Error fetching Consumer reviews from server.`);
        }
    },
    created() {
        if (this.consumerProductId) this.getReviews();
    },
    watch: {
        consumerProductId(newVal, oldVal) {
            if (newVal !== oldVal) this.getReviews();
        }
    }
}
</script>
