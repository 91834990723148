<template>
    <div v-if="this.showPagination"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}"
         class="px-4 flex items-center justify-between sm:px-6">
        <div class="flex-1 flex justify-between">
            <div v-if="this.showPreviousButton" @click="handleChangePage(this.paginationData.prev_page_url)"
                 class="relative inline-flex items-center px-4 py-2 text-sm font-medium"
                 :class="{'hover:bg-primary-800 bg-dark-module text-grey-200': darkMode, 'hover:bg-primary-100 bg-light-background text-slate-800': !darkMode}">
                Previous
            </div>
            <div v-if="this.showNextButton" @click="handleChangePage(this.paginationData.next_page_url)"
                 class="ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium"
                 :class="{'hover:bg-primary-800 bg-dark-module text-grey-200': darkMode, 'hover:bg-primary-100 bg-light-background text-slate-800': !darkMode}">
                Next
            </div>
        </div>
    </div>
</template>

<script>
import Dropdown from "./Dropdown";

export default {
    name: "CursorPagination",
    components: {Dropdown},
    props: {
        paginationData: {
            type: Object,
            default: {
                path: {
                    type: String,
                    default: null
                },
                per_page: {
                    type: Number,
                    default: null
                },
                next_cursor: {
                    type: String,
                    default: null
                },
                next_page_url: {
                    type: String,
                    default: null
                },
                prev_cursor: {
                    type: String,
                    default: null
                },
                prev_page_url: {
                    type: String,
                    default: null
                }
            }
        },
        showPagination: {
            type: Boolean,
            default: true
        },
        darkMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
    },
    computed: {
        showPreviousButton() {
            return !!this.paginationData.prev_page_url;
        },
        showNextButton() {
            return !!this.paginationData.next_page_url;
        }
    },
    methods: {
        handleChangePage: function(linkUrl) {
            this.$emit('change-page', {
                link: linkUrl,
            });
        },
    }
}
</script>

<style scoped>

</style>
