<template>
    <div class="relative z-10" role="dialog" aria-modal="true">
        <div class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <div @click="closeGlobalCommandSearch" class="fixed inset-0 bg-dark-30 bg-opacity-75 transition-opacity"></div>
            <div class="mx-auto  max-w-xl transform overflow-hidden rounded-xl border shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
                 :class="[!darkMode ? 'bg-white border-grey-125' : 'bg-dark-background border-dark-175']"
            >
                <div class="relative">
                    <svg class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                    </svg>
                    <input v-model="inputValue" type="text"
                           @keyup.enter="search(true)"
                           class="h-12 w-full border-0 pl-11 bg-transparent pr-4 focus:ring-0 sm:text-sm"
                           :class="[!darkMode ? 'text-gray-800 placeholder-gray-400' : 'text-slate-200 placeholder-gray-400']"
                           placeholder="Search..." role="combobox" aria-expanded="false" aria-controls="options" ref="input">
                </div>


                <div v-if="loading">
                    <loading-spinner :dark-mode="darkMode"></loading-spinner>
                </div>
                <!-- Default state, show/hide based on command palette state -->
                <div v-if="searchResults.length === 0 && !loading && inputValue.length < 3" class="border-t py-14 px-6 text-center text-sm sm:px-14" :class="[!darkMode ? ' border-grey-125' : 'border-dark-175']">
                    <svg class="mx-auto h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <p class="mt-4 font-semibold text-gray-900">Global Search</p>
                    <p class="mt-2 text-gray-500">Quickly access Companies, Leads, Tasks, Etc by running a global search.</p>
                </div>
                <!-- Results, show/hide based on command palette state -->
                <ul v-else-if="searchResults.length > 0 && !loading" class="max-h-80 scroll-pt-11 scroll-pb-2 space-y-2 overflow-y-auto pb-2" id="options" role="listbox">
                    <li v-for="result in searchResults">
                        <h2 class=" py-2.5 px-4 text-xs font-semibold " :class="[!darkMode ? 'text-gray-900 bg-gray-100' : 'text-slate-100 bg-dark-175']">
                            {{ result.category }}</h2>
                        <ul class="mt-2 text-sm" :class="[!darkMode ? 'text-gray-800' : 'text-slate-200']">
                            <li
                                class="cursor-pointer select-none px-4 py-2 hover:bg-blue-550 hover:text-white"
                                role="option"
                                tabindex="-1"
                                v-for="item in result.items"
                                @click="openItem(item)"
                            >
                                {{  item.displayName }}
                            </li>
                        </ul>
                    </li>
                </ul>
                <div v-else-if="searchResults.length === 0 && results.length > 0 && !loading && inputValue.length >= 3" class="border-t py-14 px-6 text-center text-sm sm:px-14" :class="[!darkMode ? ' border-grey-125' : 'border-dark-175']">
                    <svg class="mx-auto h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>

                    <p class="mt-4 font-semibold text-gray-900">No Results Found</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ManagesGlobalShortcuts from "../../../mixins/manages-global-shortcuts";
import SharedApiService from "../services/api";
import LoadingSpinner from "./LoadingSpinner";

export default {
    name: 'GlobalCommandSearch',
    props: {
        darkMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            inputValue: '',
            api: SharedApiService.make(),
            timeout: null,
            loading: false,
            results: [],
        }
    },
    mixins: [ManagesGlobalShortcuts],
    components: {LoadingSpinner},
    mounted() {
        this.registerShortcut('escape', this.closeGlobalCommandSearch.bind(this));
        this.$refs.input.focus();
    },
    watch: {
        inputValue() {
            this.attemptSearch();

            if(this.inputValue.length < 3 && this.results.length > 0)
                this.results = [];
        }
    },
    beforeUnmount() {},
    computed: {
        searchResults() {
            return this.results.filter((result) => result.items.length > 0);
        }
    },
    methods: {
        closeGlobalCommandSearch() {
            this.$emit('close-global-search');
        },
        search(manualKeypress = false) {
            this.clearTimeout();

            if(manualKeypress || this.inputValue.length >= 3) {
                this.loading = true;

                this.api.search(this.inputValue).then(resp => {
                    this.results = resp.data.data;
                }).catch(e => console.error(e)).finally(() => this.loading = false);
            }
        },
        attemptSearch() {
            this.clearTimeout();
            this.timeout = setTimeout(() => this.search(), 500);
        },
        clearTimeout() {
            if(this.timeout == null) return;
            clearTimeout(this.timeout);
            this.timeout = null;
        },
        openItem(item) {
            if(item.external === true) {
                window.open(item.url, "_blank");
            } else {
                window.location.href = item.url;
            }
        }
    }
};
</script>
