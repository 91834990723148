<template>
    <div class="p-5">
        <div class="flex items-center justify-between mb-3">
            <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Industry List</h5>
            <button
                class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5" @click="openModal">
                Add New Industry
            </button>
        </div>
        <div class="grid grid-cols-11 gap-x-3 mb-2 px-5" v-if="!loading">
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Name</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Created At</p>
        </div>
        <div class="border-t border-b" v-if="!loading"
             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-40 border-dark-border': darkMode}">
            <div>
                <div class="grid grid-cols-11 gap-x-3 border-b px-5 py-3 items-center"
                     v-for="industry in industries" :key="industry.id"
                     :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <p class="text-sm col-span-2 truncate">
                        {{ industry.name }}
                    </p>
                    <p class="text-sm col-span-2">
                        {{ formatDate(industry.created_at) }}
                    </p>
                    <p class="text-primary-500 cursor-pointer text-sm" @click="viewWebsites(industry)">
                        Websites
                    </p>
                    <p class="text-primary-500 cursor-pointer text-sm" @click="viewCompanies(industry)">
                        Companies
                    </p>
                    <p class="text-primary-500 cursor-pointer text-sm" @click="viewServices(industry)">
                        Services
                    </p>
                    <p class="text-primary-500 cursor-pointer text-sm" @click="viewCompanyFields(industry)">
                        Company Fields
                    </p>
                    <p class="text-primary-500 cursor-pointer text-sm" @click="viewConsumerFields(industry)">
                        Consumer Fields
                    </p>
                    <p class="text-primary-500 cursor-pointer text-sm" @click="viewTypes(industry)">
                        Types
                    </p>
                    <ActionsHandle :dark-mode="darkMode" @edit="editIndustry(industry)" @delete="deleteIndustry(industry)"/>
                </div>
            </div>
        </div>
        <div class="h-32 flex items-center justify-center" v-if="loading">
            <loading-spinner></loading-spinner>
        </div>
        <modal :small="true" v-if="showModal" @close="closeModal" :dark-mode="darkMode" @confirm="saveIndustry" :close-text="'Cancel'" :confirm-text="confirmText">
            <template v-slot:header>
                <h4>{{ industry.id ? 'Update' : 'Create' }} Industry</h4>
            </template>
            <template v-slot:content>
                <div class="grid gap-3">
                    <div class="flex items-center">
                        <p class="uppercase font-semibold text-xs w-20"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Name
                        </p>
                        <input class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                               placeholder="Industry Name"
                               v-model="industry.name"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                    </div>
                </div>
            </template>
        </modal>
        <alerts-container :dark-mode="darkMode" :alert-type="'error'" :text="error" v-if="error"></alerts-container>
    </div>

</template>

<script>
import Modal from "../../../Shared/components/Modal";
import ApiService from "../services/api";
import ActionsHandle from "../../../Shared/components/ActionsHandle";
import LoadingSpinner from "../../../Shared/components/LoadingSpinner";
import AlertsContainer from "../../../Shared/components/AlertsContainer";
import {DateTime} from "luxon";

export default {
    name: "Industries",
    components: {Modal, ActionsHandle, LoadingSpinner, AlertsContainer},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            api: ApiService.make(),
            showModal: false,
            industries: [],
            industry: {name: null},
            saving: false,
            loading: false,
            error: null
        }
    },
    created() {
        this.getIndustries();
    },
    computed: {
        confirmText: function () {
            if (this.saving) return 'Saving...';
            if (this.industry.id) return 'Update';

            return 'Create';
        }
    },
    methods: {
        openModal() {
            this.showModal = true;
        },
        closeModal () {
            this.showModal = false;
            this.industry = {name: null};
        },
        getIndustries() {
            this.error = null;
            this.loading = true;
            this.api.getIndustries().then(resp => this.industries = resp.data.data.industries)
                .catch(e => this.error = e.response.data.message).finally(() => this.loading = false);
        },
        saveIndustry() {
            if (this.saving) return;
            this.saving = true;
            this.error = null;

            if (this.industry.id) {
                this.updateIndustry();
                return;
            }
            this.createIndustry();
        },
        createIndustry() {
            this.api.createIndustry(this.industry).then(() => this.refreshList(true))
                .catch(e => this.error = e.response.data.message).finally(() => this.saving = false);
        },
        editIndustry(industry) {
            this.industry = {...industry};
            this.openModal();
        },
        updateIndustry() {
            this.api.updateIndustry(this.industry.id, this.industry).then(() => this.refreshList(true))
                .catch(e => this.error = e.response.data.message).finally(() => this.saving = false);
        },
        deleteIndustry(industry) {
            this.error = null;
            this.api.deleteIndustry(industry.id).then(() => this.getIndustries())
                .catch(e => this.error = e.response.data.message);
        },
        formatDate(date) {
            return date ? DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT) : null
        },
        viewWebsites(industry) {
            this.$emit('industry-websites-requested', industry);
        },
        viewCompanies(industry) {
            this.$emit('industry-companies-requested', industry);
        },
        viewServices(industry) {
            this.$emit('industry-services-requested', industry);
        },
        viewCompanyFields(industry) {
            this.$emit('industry-company-fields-requested', industry);
        },
        viewConsumerFields(industry) {
            this.$emit('industry-consumer-fields-requested', industry);
        },
        viewTypes(industry) {
            this.$emit('industry-types-requested', industry);
        },
        refreshList(closeModal = false) {
            this.getIndustries();
            if(closeModal) this.closeModal();
        }
    }
}
</script>
