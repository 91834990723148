<template>
    <div class="mt-5">
        <div class="rounded-lg bg-red-100 p-4 mb-5" v-if="errorMessage">
            <div class="text-sm text-red-700">{{ errorMessage }}</div>
        </div>
        <p class="uppercase font-semibold text-xs mb-1"
           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
            Subject
        </p>
        <shortcode-input :dark-mode="darkMode" v-model="subject"></shortcode-input>
        <p class="uppercase font-semibold text-xs mb-1 mt-5"
           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
            Notification Text
        </p>
        <shortcode-input :dark-mode="darkMode" v-model="notificationText"></shortcode-input>
        <p class="uppercase font-semibold text-xs mb-1 mt-5"
           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
            To
        </p>
        <div>
            <custom-input
                placeholder="To email"
                :dark-mode="darkMode"
                type="email"
                v-model="toEmail"
            />
        </div>
        <p class="uppercase font-semibold text-xs mb-1 mt-5"
           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
            From
        </p>
        <div>
            <custom-input
                placeholder="From email"
                :dark-mode="darkMode"
                type="email"
                v-model="fromEmail"
            />
        </div>
    </div>
</template>

<script>
import Dropdown from "../../../Shared/components/Dropdown";
import ShortcodeInput from "../../../Shared/components/ShortcodeInput";
import Validator from "../../../../../services/validator";
import CustomInput from "../../../Shared/components/CustomInput.vue";

export default {
    name: "EmailNotification",
    components: { CustomInput, ShortcodeInput, Dropdown},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        action: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            notificationText: null,
            subject: null,
            fromEmail: null,
            toEmail: null,
            errorMessage: ''
        }
    },
    created() {
        if (this.action) {
            this.subject = this.action.payload?.subject;
            this.notificationText = this.action.payload?.message;
            this.toEmail = this.action.payload?.targets[0]?.to;
            this.fromEmail = this.action.payload?.targets[0]?.from;
        }
    },
    methods: {
        validate() {
            if (!this.subject || !this.notificationText || !this.fromEmail || !this.toEmail) {
                this.errorMessage = 'All the fields are required';
                return false;
            }

            if (!Validator.validateEmail(this.fromEmail) || !Validator.validateEmail(this.toEmail)) {
                this.errorMessage = 'To and From email addresses must be valid';
                return false;
            }

            this.errorMessage = '';
            return true;
        },
        getDisplayName() {
            return 'Email Notification';
        },
        getPayload() {
            return {
                subject: this.subject,
                message: this.notificationText,
                targets: [{
                    to: this.toEmail,
                    from: this.fromEmail
                }]
            }
        }
    }
}
</script>

<style scoped>

</style>
