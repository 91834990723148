<template>
    <modal
        :small="true"
        @confirm="saveContact"
        :close-text="'Cancel'"
        :confirm-text="confirmText"
        :dark-mode="darkMode"
        v-if="showModal"
        @close="closeContactAddModal"
        :disable-confirm="disableConfirm"
    >
        <template v-slot:header>
            <h3>{{ editingContact ? "Edit" : "Add" }} Contact</h3>
        </template>
        <template v-slot:content>
            <div v-if="saveError !== null" class="mb-6">
                <alert :dark-mode="darkMode" :text="saveError" :alert-type="'error'"></alert>
            </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-3">
                <div>
                    <p class="uppercase font-semibold text-xs mb-2"
                       :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                        First Name
                    </p>
                    <input
                        class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                        placeholder="First Name" v-model="contact.first_name"
                        :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                </div>
                <div>
                    <p class="uppercase font-semibold text-xs mb-2"
                       :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                        Last Name
                    </p>
                    <input
                        class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                        placeholder="Last Name" v-model="contact.last_name"
                        :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                </div>
                <div>
                    <p class="uppercase font-semibold text-xs mb-2"
                       :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                        Title
                    </p>
                    <input
                        class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                        placeholder="Title" v-model="contact.title"
                        :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                </div>
                <div>
                    <p class="uppercase font-semibold text-xs mb-2"
                       :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                        Email
                    </p>
                    <input
                        class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                        placeholder="Email" v-model="contact.email"
                        :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                </div>
                <div>
                    <p class="uppercase font-semibold text-xs mb-2"
                       :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                        Office Phone
                    </p>
                    <input
                        class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                        placeholder="Office Phone" v-model="contact.office_phone"
                        :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                </div>
                <div>
                    <p class="uppercase font-semibold text-xs mb-2"
                       :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                        Mobile
                    </p>
                    <input
                        class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                        placeholder="Mobile" v-model="contact.cell_phone"
                        :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import SharedApiService from "../../services/api";
import Modal from "../../components/Modal.vue";
import Alert from "../../components/Alert.vue";
import {mapWritableState} from "pinia";
import {useContactsStore} from "../../../../../stores/contacts.store";

export default {
    name: "CreateEditContactModal",
    components: {Alert, Modal},
    emits: ["close:modal", "get:contacts"],
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        editingContact: {
            type: Boolean,
            default: false
        },
        showModal: {
            type: Boolean,
            required: true
        },
        companyId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            saving: false,
            saveError: null,
            api: SharedApiService.make(),
        }
    },
    methods: {
        saveContact() {
            if (this.saving) return;

            this.saving = true;
            this.saveError = null;

            const method = this.editingContact ? this.api.updateCompanyContact.bind(this.api) : this.api.createCompanyContact.bind(this.api);

            method(this.companyId, this.contact)
                .then(() => {
                    this.$emit('get:contacts');
                    this.closeContactAddModal();
                })
                .catch(e => this.saveError = e.response.data.message)
                .finally(() => this.saving = false);
        },
        closeContactAddModal() {
            this.$emit('close:modal')
        }
    },
    computed: {
        disableConfirm: function () {
            return this.saving || (!this.contact.first_name && !this.contact.last_name) || (!this.contact.email && !this.contact.office_phone && !this.contact.cell_phone);
        },
        confirmText: function () {
            if (this.saving) return 'Saving...';

            return this.editingContact ? "Save" : 'Create';
        },
        ...mapWritableState(useContactsStore, [
            'contact'
        ])
    }
}
</script>

<style scoped>

</style>
