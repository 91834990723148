<template>
    <div>
        <company-services :dark-mode="darkMode"></company-services>
    </div>
</template>

<script>
import CompanyServices from "./CompanyServices";

export default {
    name: "Companies",
    components: {CompanyServices},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        }
    }
}
</script>
