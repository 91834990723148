<template>
    <Bar
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
    />
</template>

<script>
import {Bar} from 'vue-chartjs'
import 'chart.js/auto'

export default {
    name: 'BarChart',
    components: {Bar},
    props: {
        chartId: {
            type: String,
            default: 'bar-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {
            }
        },
        plugins: {
            type: Object,
            default: () => {
            }
        },
        backgroundColor: {
            type: Array,
            default: ['#0081FF']
        },
        borderColor: {
            type: Array,
            default: ['#0081FF']
        },
        borderWidth: {
            type: Array,
            default: []
        },
        label: {
            type: String,
            default: "Bar Chart"
        },
        labelsList: {
            type: Array,
            default: []
        },
        statistics: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            chartData: {
                labels: this.labelsList,
                datasets: [
                    {
                        label: this.label,
                        data: this.statistics,
                        backgroundColor: this.backgroundColor,
                        borderColor: this.borderColor,
                        borderWidth: 4,
                        tension: 0.4,
                        pointRadius: 0
                    }
                ]
            },
            chartOptions: {
                responsive: true
            }
        }
    }
}
</script>
