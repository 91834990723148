import {defineStore} from 'pinia';
import Api from "../API/api";
import axios from "axios";
import { replaceShortcodes } from "../../Shared/services/markdownImageTags";

export const useEmailTemplatesStore = defineStore('email_templates', {
    state: () => ({
        api: Api.make(),

        templateId: 0,
        templateName: '',
        templateContent: '',
        templateHeader: '',
        templateFooter: '',
        templateContentImages: {},
        templateHeaderImages: {},
        templateFooterImages: {},
        personal: true,
        templateIndustry: 'solar',
        templateType: 'content',
        templateBackground: 0,
        templateOwner: '',
        isTemplateOwner: false,
        templateLastUpdated: '',

        templateHTML: '',

        templateTypeTab: 'content',
        view: 'list',

        templates: {},
        templateBackgrounds: {},

        alertActive: false,
        alertType: '',
        alertText: '',

        editTemplateLoading: false,
    }),
    getters: {

    },
    actions: {
        changedTemplateIndustry(newIndustry) {
            this.templateIndustry = newIndustry;
            this.getTemplateBackgrounds();
        },
        showAlert(type, message) {
            this.alertType = type;
            this.alertText = message;
            this.alertActive = true;

            setTimeout( () => {
                this.alertActive = false;
                this.alertText = '';
                this.alertType = '';
            }, 5000);
        },
        reset() {
            this.templateId = 0;
            this.templateContent = '';
            this.templateHeader = '';
            this.templateFooter = '';
            this.templateContentImages = {};
            this.templateHeaderImages = {};
            this.templateFooterImages = {};
            this.templateName = '';
            this.personal = true;
            this.templateIndustry = 'solar';
            this.templateOwner = '';
            this.templateLastUpdated = '';
            this.togglePreview(false);
        },
        changeTemplateTypeTab(templateTypeTab) {
            this.templateTypeTab = templateTypeTab;
        },
        showTemplatesList() {
            this.reset();
            this.getEmailTemplates(this.templateTypeTab);
            this.view = 'list';
        },
        togglePreview(show, html = '') {
            this.templateHTML = show ? html : '';
        },
        editTemplate(templateId = null) {
            this.reset();

            if(this.templateTypeTab === 'content') {
                this.editContentTemplate(templateId);
            }
            else if(this.templateTypeTab === 'background') {
                this.editBackgroundTemplate(templateId);
            }
        },
        editContentTemplate(templateId) {
            this.templateType = 'content';

            if(templateId) {
                this.templateId = templateId;
                this.templateName = this.templates[templateId]?.name;

                this.editTemplateLoading = true;

                return this.api.getEmailTemplate(templateId).then(res => {
                    if(res.data.data.status === true) {
                        const template = res.data.data.template;

                        this.templateId = templateId;
                        this.templateName = template.name;
                        this.templateContentImages = template.content_images;
                        this.templateContent = replaceShortcodes(template.content, this.templateContentImages);
                        this.personal = !!template.personal;
                        this.templateIndustry = template.industry;
                        this.templateBackground = template.background_id;
                        this.isTemplateOwner = template.is_owner;
                        this.templateOwner = template.owner_name;
                        this.templateLastUpdated = template.last_updated;
                        this.togglePreview(false);

                        this.getTemplateBackgrounds();

                        this.view = 'editor';
                    }
                    else {
                        this.store.showAlert('error', 'Failed to load template');
                    }
                }).catch(err => {
                    this.store.showAlert('error', 'Error loading template');
                }).finally(() => {
                    this.editTemplateLoading = false;
                })
            }
            else {
                this.getTemplateBackgrounds();
                this.view = 'editor';
            }
        },
        editBackgroundTemplate(templateId) {
            this.templateType = 'background';

            if(templateId) {
                this.templateId = templateId;
                this.templateName = this.templates[templateId]?.name;

                this.editTemplateLoading = true;

                return this.api.getEmailTemplateBackground(templateId).then(res => {
                    if(res.data.data.status === true) {
                        const template = res.data.data.template;

                        this.templateId = templateId;
                        this.templateName = template.name;
                        this.templateHeaderImages = template.header_images;
                        this.templateFooterImages = template.footer_images;
                        this.templateHeader = replaceShortcodes(template.header, this.templateHeaderImages);
                        this.templateFooter = replaceShortcodes(template.footer, this.templateFooterImages);
                        this.personal = !!template.personal;
                        this.templateIndustry = template.industry;
                        this.isTemplateOwner = template.is_owner;
                        this.templateOwner = template.owner_name;
                        this.templateLastUpdated = template.last_updated;
                        this.togglePreview(false);

                        this.view = 'editor';
                    }
                    else {
                        this.store.showAlert('error', 'Failed to load template');
                    }
                }).catch(err => {
                    this.store.showAlert('error', 'Error loading template');
                }).finally(() => {
                    this.editTemplateLoading = false;
                })
            }
            else {
                this.view = 'editor';
            }
        },
        deleteTemplate(templateId) {
            let promise;
            if(this.templateTypeTab === 'content') {
                promise = this.api.deleteEmailTemplate(templateId);
            }
            else if(this.templateTypeTab === 'background') {
                promise = this.api.deleteEmailTemplateBackground(templateId);
            }

            return promise.then(res => {
                if (res.data.data.status === true) {
                    this.showAlert('success', 'Template deleted');
                }
                else {
                    this.showAlert('error', 'Failed to delete template');
                }
            }).catch(err => {
                this.showAlert('error', 'Error deleting template');
            });
        },
        getEmailTemplates(templateType, pageUrl = null) {
            let url;
            if(pageUrl) {
                url = pageUrl;
            }
            else {
                if(templateType === 'content') {
                    url = Api.getBaseUrl() + '/list?page=1';
                }
                else if(templateType === 'background') {
                    url = Api.getBaseUrl() + '/backgrounds/list?page=1'
                }
            }

            return axios.get(url).then(res => {
                if (res.data.data.status === true) {
                    this.templates = res.data.data.templates;
                }
                else {
                    this.showAlert('error', 'Failed to load templates list');
                }
            }).catch(err => {
                this.showAlert('error', 'Error loading templates list');
            });
        },
        getTemplateBackgrounds() {
            return this.api.getEmailTemplateBackgroundsByIndustry(this.templateIndustry).then(res => {
                if (res.data.data.status === true) {
                    this.templateBackgrounds = res.data.data.templates;
                }
                else {
                    this.showAlert('error', 'Failed to load background templates');
                }
            }).catch(err => {
                this.showAlert('error', 'Error loading background templates');
            });
        }
    }
});
