<template>
    <div>
        <alerts-container v-if="alertActive" :text="alertText" :alert-type="alertType" :dark-mode="darkMode"/>

        <div class="main-layout font-body">
            <div class="w-full">
                <div class="w-full flex-auto pt-3 relative">
                    <div class="px-10" :class="[darkMode ? 'text-white' : 'text-slate-900']">
                        <div class="flex items-center justify-between flex-wrap py-6">
                            <h3 class="text-xl font-medium pb-0 leading-none mr-5">Task Management</h3>
                            <TestEventsWidget :dark-mode="darkMode"/>
                        </div>
                        <div>
                            <div class="grid md:grid-cols-1 xl:grid-cols-3 gap-4">
                                <EventsControlPanel @activate-event-tree="activateEventTree" :dark-mode="darkMode"/>
                                <ActionTree
                                    :action-selection="actionSelection"
                                    :result-selection="resultSelection"
                                    :event-tree="editingEvent"
                                    :dark-mode="darkMode"
                                    @add-result="addingResult"
                                    @add-action="addingAction"
                                    @new-workflow="newWorkflow"
                                    @rename-workflow="updateWorkflowName"
                                    @save-as-template="saveWorkflowAsTemplate"
                                    @delete-workflow="deleteWorkflow"
                                    @edit-templates="editTemplates"
                                    :loading-workflows="loadingWorkflows"
                                    @edit-action="editAction"
                                    @edit-result="editResult"/>

                                <ActionEditor
                                    @save-node="saveNode"
                                    @cancel="cancelEditor"
                                    @update-node="updateNode"
                                    @delete-node="deleteNode"
                                    :previous-node="previousNode"
                                    :node="editNode"
                                    :result-selection="resultSelection"
                                    :action-selection="actionSelection"
                                    :dark-mode="darkMode"
                                    :workflow="selectedWorkflow"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventsControlPanel from "./components/EventsControlPanel";
import ActionTree from "./components/ActionTree";
import ActionEditor from "./components/ActionEditor";
import WorkflowFactory from "./factories/workflow-factory";
import ApiService from "./services/api";
import AlertsContainer from "../Shared/components/AlertsContainer";
import TestEventsWidget from "./components/TestEventsWidget";

export default {
    name: "TaskManagement",
    components: {
        TestEventsWidget,
        ActionTree,
        EventsControlPanel,
        ActionEditor,
        AlertsContainer
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            api: ApiService.make(),
            editingEvent: {
                id: null,
                name: null,
                workflows: []
            },
            actionSelection: false,
            resultSelection: false,
            selectedWorkflow: null,
            previousNode: {
                id: null,
                type: null
            },
            loadingWorkflows: false,
            editNode: null,

            alertActive: false,
            alertType: null,
            alertText: null
        }
    },
    methods: {
        async editTemplates() {
            this.activateEventTree({id: 0, event_category: null, event_name: null, display_name: null, category_name: null, workflows: []});
        },
        async activateEventTree(payload) {
            if (this.editingEvent.id === payload.id) {
                this.resetEvent();
            }
            else {
                this.editingEvent = {...payload};
                this.editingEvent.workflows = [];
                await this.getWorkflows();
            }
        },
        addingAction(workflow, previousNode) {
            this.actionSelection = true;
            this.resultSelection = false;

            this.selectedWorkflow = workflow;
            this.previousNode = previousNode;
            this.editNode = null;
        },
        addingResult(workflow, action) {
            this.resultSelection = true;
            this.actionSelection = false;

            this.selectedWorkflow = workflow;
            this.previousNode = action;
            this.editNode = null;
        },
        resetEvent() {
            this.editingEvent.id = null;
            this.editingEvent.name = null;
            this.editingEvent.workflows = [];
            this.resultSelection = false;
            this.actionSelection = false;
            this.selectedWorkflow = null;
            this.editNode = null;
            this.previousNode = null;
        },
        async newWorkflow(workflowName) {
            this.loadingWorkflows = true;

            try {
                const workflow = await this.api.createWorkflow(this.editingEvent.id, null, workflowName);
                this.editingEvent.workflows.push(WorkflowFactory.makeWorkflow(workflow.data.data));
            } catch (error) {
            }

           this.loadingWorkflows = false;
        },
        async deleteWorkflow(payload) {
            try {
                await this.api.deleteWorkflow(payload.id);
                this.editingEvent.workflows = this.editingEvent.workflows.filter(workflow => workflow.id !== payload.id);
            } catch(error) {
            }
        },
        async updateWorkflowName(workflowId, workflowName) {
            this.loadingWorkflows = true;
            try {
                await this.api.updateWorkflowName(workflowId, workflowName);
                await this.getWorkflows();
            } catch (error) {
            }
        },
        async saveWorkflowAsTemplate(workflowId) {
            this.loadingWorkflows = true;

            await this.api.saveWorkflowAsTemplate(workflowId);
            await this.getWorkflows();
        },
        async getWorkflows()
        {
            this.editingEvent.workflows = [];
            this.loadingWorkflows = true;

            await this.api.getWorkflows(this.editingEvent.id).then(resp => {
                this.editingEvent.workflows = resp.data.data.workflows;
            }).finally(() => this.loadingWorkflows = false);
        },
        saveNode(node) {
            this.api.createWorkflowAction(node.workflow_id, node).then(resp => {
                const workflow = this.editingEvent.workflows.find(workflow => workflow.id === node.workflow_id);

                if (workflow.actions) {
                    workflow.actions.push(resp.data.data.action);
                } else {
                    workflow.actions = [resp.data.data.action];
                }

                this.getWorkflows();
            }).catch(err => {
                if(err.response.status === 422) {
                    this.alertType = 'error';
                    this.alertText = 'Missing required field(s)';
                    this.alertActive = true;
                }
                else if(err.response.status === 500) {
                    this.alertType = 'error';
                    this.alertText = 'Server error';
                    this.alertActive = true;
                }

                setTimeout(() => {
                    this.alertActive = false;
                    this.alertText = null;
                    this.alertType = null;
                }, 5000);
            });
        },
        updateNode(node) {
            this.api.updateWorkflowAction(node).then((res) => {
                this.editNode = null;

                this.getWorkflows();
            }).catch(err => {
                if(err.response.status === 422) {
                    this.alertType = 'error';
                    this.alertText = 'Missing required field(s)';
                    this.alertActive = true;
                }
                else if(err.response.status === 500) {
                    this.alertType = 'error';
                    this.alertText = 'Server error';
                    this.alertActive = true;
                }

                setTimeout(() => {
                    this.alertActive = false;
                    this.alertText = null;
                    this.alertType = null;
                }, 5000);
            });
        },
        async deleteNode(node) {
            await this.api.deleteWorkflowAction(node);
            await this.getWorkflows();
        },
        findWorkflowObject(workflowId) {
            return this.editingEvent.workflows.find(workflow => workflow.id === workflowId);
        },
        cancelEditor() {
            this.resultSelection = false;
            this.actionSelection = false;

            this.selectedWorkflow = null;

            this.previousNode = null;
            this.editNode = null;
        },
        editAction(action) {
            this.cancelEditor();

            this.editNode = action;
            this.actionSelection = true;
        },
        editResult(result) {
            this.cancelEditor();

            this.editNode = result;
            this.resultSelection = true;
        }
    }
}
</script>

<style scoped>

</style>
