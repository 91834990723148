<template>
    <div class="border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="p-5 flex items-center justify-between">
            <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Hunters List</h5>
            <button @click="openModal"
                    class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5">
                Add Hunter
            </button>
        </div>
        <div class="grid grid-cols-8 gap-x-3 mb-2 px-5">
            <p class="text-slate font-medium-400 uppercase text-xs col-span-2">User</p>
            <p class="text-slate font-medium-400 uppercase text-xs col-span-2">States</p>
            <p class="text-slate font-medium-400 uppercase text-xs col-span-2">Industries</p>
            <p class="text-slate font-medium-400 uppercase text-xs">ST Round Robin</p>
            <p class="text-slate font-medium-400 uppercase text-xs"></p>
        </div>
        <div class="border-t border-b h-64 overflow-y-auto"
             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
            <div class="h-full">
                <div class="flex items-center h-full justify-center" v-if="loading">
                    <loading-spinner></loading-spinner>
                </div>
                <div class="grid grid-cols-8 gap-x-3 border-b px-5 py-3"
                     v-else
                     v-for="hunter in hunters" :key="hunter.id"
                     :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <p class="text-sm col-span-2 truncate">
                        {{ hunter.name }}
                    </p>
                    <p class="text-sm col-span-2">
                        {{ hunter.states.sort().join(', ') }}
                    </p>
                    <p class="text-sm col-span-2">
                        {{ hunter.industries.sort().join(', ') }}
                    </p>
                    <p class="text-sm pl-1 truncate">
                        {{ hunter.include_in_round_robin ? "Yes" : "No" }}
                    </p>
                    <ActionsHandle :dark-mode="darkMode" @edit="editHunter(hunter)" @delete="deleteHunter(hunter)"/>
                </div>
            </div>
        </div>
        <div class="p-3"></div>
        <Modal :dark-mode="darkMode" :small="true" @close="closeModal" close-text="Cancel"
               @confirm="saveHunter"
               :confirm-text="confirmText" v-if="showModal">
            <template v-slot:header>
                <h4 class="text-xl font-medium">{{ hunter.id ? `Edit ${hunter.name}` : 'Add Hunter' }}</h4>
            </template>
            <template v-slot:content>
                <div class="px-6">
                    <div class="mb-6" v-if="!hunter.id">
                        <label class="block pb-2 font-medium">User</label>
                        <Autocomplete
                            :input-class="['w-full border border-grey-350 rounded px-4 py-3', {'border-grey-350 bg-light-module': !darkMode, 'border-blue-400 bg-dark-background text-blue-400': darkMode}]"
                            :list-class="['absolute top-0 w-full border rounded', {'border-grey-200 bg-light-module': !darkMode, 'border-dark-40 bg-dark-module': darkMode}]"
                            :option-class="['cursor-pointer px-5 py-2 my-1', {'hover:bg-grey-100': !darkMode, 'hover:bg-blue-800': darkMode}]"
                            v-model="hunter.user_id"
                            :value="hunter.user_id"
                            :options="userOptions"
                            @search="searchUsers"
                            :placeholder="'Enter Name'">
                        </Autocomplete>
                    </div>
                    <div class="mb-5 grid grid-cols-2 gap-2">
                        <div>
                            <p class="block pb-2 font-medium">States</p>
                            <multi-select
                                :options="states" :dark-mode="darkMode"
                                :text-place-holder="'Assign States'"
                                :show-search-box="false"
                                @input="selectedStates = $event"
                                :selected-ids="selectedStates"
                            ></multi-select>
                        </div>
                    </div>
                    <div class="mb-5 grid grid-cols-2 gap-2">
                        <div>
                            <p class="block pb-2 font-medium">Industries</p>
                            <multi-select
                                :options="industries" :dark-mode="darkMode"
                                :text-place-holder="'Assign Industries'"
                                :show-search-box="false"
                                @input="selectedIndustries = $event"
                                :selected-ids="selectedIndustries"
                            ></multi-select>
                        </div>
                    </div>
                    <div class="mb-5 grid grid-cols-2 gap-2">
                        <div>
                            <p class="block pb-2 font-medium">Include in sales tool round robin?</p>
                            <Dropdown :dark-mode="darkMode" :options="roundRobinTypes" v-model="hunter.include_in_round_robin"
                                      :selected="hunter.include_in_round_robin"/>
                        </div>
                    </div>
                </div>
                <div v-if="error !== null" class="px-6 my-4 font-bold text-red-400">{{ error }}</div>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from "../../Shared/components/Modal";
import Dropdown from "../../Shared/components/Dropdown";
import ActionsHandle from "../../Shared/components/ActionsHandle";
import Autocomplete from "../../Shared/components/Autocomplete";
import LoadingSpinner from "../../Shared/components/LoadingSpinner";
import SharedApiService from "../../Shared/services/api";
import MultiSelect from "../../Shared/components/MultiSelect.vue";

export default {
    name: "Hunters",
    components: {Modal, Dropdown, ActionsHandle, Autocomplete, LoadingSpinner, MultiSelect},
    props: ["api", "darkMode"],
    data() {
        return {
            hunters: [],
            loading: false,
            hunter: {
                user_id: null,
                include_in_round_robin: 0
            },
            showModal: false,
            roundRobinTypes: [
                {id: 0, name: "No"},
                {id: 1, name: "Yes"}
            ],
            error: null,
            userOptions: [],
            saving: false,
            sharedApi: SharedApiService.make(),
            states: [],
            industries: [],
            selectedStates: [],
            selectedIndustries: []
        }
    },
    created() {
        this.getHunters();
        this.getStates();
        this.getIndustries();
    },
    computed: {
        confirmText: function () {
            if (this.saving) return 'Saving...';
            if (this.hunter.id) return 'Update';

            return 'Save';
        }
    },
    methods: {
        getHunters() {
            this.loading = true;
            this.api.getHunters()
                .then(resp => this.hunters = resp.data.data.hunters)
                .finally(() => this.loading = false);
        },
        saveHunter() {
            this.saving = true;
            this.resetError();

            if (this.hunter.id) {
                this.updateHunter();
                return;
            }

            this.createHunter();
        },
        createHunter() {
            this.api.createHunter({...this.hunter, states: this.selectedStates, industries: this.selectedIndustries})
                .then(() => {
                    this.getHunters();
                    this.closeModal();
                })
                .catch(e => this.error = e.response.data.message)
                .finally(() => this.saving = false);
        },
        editHunter(hunter) {
            this.hunter = {...hunter};
            this.selectedStates = this.hunter.states;
            this.selectedIndustries = this.hunter.industries;

            this.openModal();
        },
        updateHunter() {
            this.api.updateHunter(this.hunter.id, {...this.hunter, states: this.selectedStates, industries: this.selectedIndustries})
                .then(() => {
                    this.getHunters();
                    this.closeModal();
                })
                .catch(e => this.error = e.response.data.message)
                .finally(() => this.saving = false);
        },
        deleteHunter(hunter) {
            this.api.deleteHunter(hunter.id).then(() => this.getHunters());
        },
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
            this.hunter = {
                user_id: null,
                include_in_round_robin: 0
            };
            this.selectedStates = [];
            this.selectedIndustries = [];
            this.resetError();
        },
        searchUsers(query) {
            this.api.searchUserNames(query).then(resp => this.userOptions = resp.data.data.users);
        },
        resetError() {
            this.error = null;
        },
        getStates() {
            this.sharedApi.getStates().then(resp => this.states = resp.data.data.map(state => Object.assign({id: state.stateAbbr, name: state.name})));
        },
        getIndustries() {
            this.sharedApi.getIndustries().then(resp => this.industries = resp.data.data.industries);
        }
    }
}
</script>
