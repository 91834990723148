<template>
    <div class="inline-flex items-center">
        <span v-if="label" class="not-sr-only mr-2" :class="labelClass">{{ label }}</span>
        <Switch v-model="enabled"
                class="my-auto"
                :class="[
                    !darkMode ? (enabled ? 'bg-primary-500 border border-primary-400' : 'bg-light-background border border-light-border') : (enabled ? 'bg-primary-500 border border-primary-400' : 'bg-dark-background border border-dark-border'),
                    'relative inline-flex items-center flex-shrink-0 h-6 w-11 border-1 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                ]">
            <span aria-hidden="true"
                  :class="[
                      !darkMode ? (enabled ? 'translate-x-5 bg-light-module' : 'translate-x-[2px] bg-slate-400') : (enabled ? 'translate-x-5 bg-light-module' : 'translate-x-[2px] bg-slate-400'),

                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-light-module shadow transform ring-0 transition ease-in-out duration-200'
                  ]" />
        </Switch>
    </div>
</template>

<script>
import { Switch } from '@headlessui/vue'

export default {
    components: {
        Switch
    },
    props: {
        darkMode: {
          type: Boolean,
          default: false,
        },
        label: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [Boolean, Number],
            default: false
        },
        labelClass: {
            type: String,
            default: ''
        }
    },
    emits: [
        'update:modelValue',
        'change'
    ],
    computed: {
        enabled: {
            get() {
                return Boolean(this.modelValue);
            },
            set(value) {
                this.$emit('update:modelValue', value);
                this.$emit('change', value);
            }
        }
    }
}

</script>
