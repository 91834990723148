<template>
    <div>
        <div class="flex items-center justify-between pb-5">
            <h5 class="text-sm font-semibold leading-tight" :class="{'text-grey-500' : !darkMode, 'text-blue-400' : darkMode}">{{ moduleTitle ?? '' }}</h5>
            <div class="text-sm rounded-full flex items-center"
                 :class="{'text-grey-400': !darkMode, 'text-blue-400': darkMode}">
                Total:
                <div class="rounded-full text-sm pl-1 h-full font-medium">
                    {{ totalStatistics }}</div>
            </div>
        </div>
        <div class="flex flex-wrap mb-5">
            <div class="flex items-center mr-8" v-if="metricsATitle">
                <div class="flex-shrink-0 w-3 h-3 mr-2 rounded-full bg-cyan-500"
                ></div>
                <p class="text-sm">{{ metricsATitle }}</p>
            </div>
            <div class="flex items-center mr-8" v-if="metricsBTitle">
                <div class="flex-shrink-0 w-3 h-3 text-sm mr-1 rounded-full bg-yellow-500"
                ></div>
                <p class="text-sm">{{ metricsBTitle }}</p>
            </div>
            <div class="flex items-center mr-8" v-if="metricsCTitle">
                <div class="flex-shrink-0 w-3 h-3 text-sm mr-1 rounded-full bg-green-500"
                ></div>
                <p class="text-sm">{{ metricsCTitle }}</p>
            </div>
        </div>
        <div class="w-full" v-if="metricsAValue">
            <div class="flex items-center mb-4" v-if="metricsATitle">
                <div class="p-2 h-10 rounded-r "
                     :class="{'bg-cyan-500' : !darkMode, 'border-r-2 border-cyan-500 bg-dark-background': darkMode}"
                     :style="{width: calculatePercentage(metricsAValue) + '%'}">
                    <div class="flex justify-end items-center h-full mr-2 text-grey-120 font-bold"
                         v-if="calculatePercentage(metricsAValue) > 10">{{ Math.round(calculatePercentage(metricsAValue)) }}%</div>
                </div>
                <p class="ml-2 font-bold text-cyan-500">
                    {{ metricsAValue }}
                </p>
            </div>
            <div class="flex items-center mb-4" v-if="metricsBTitle">
                <div class="p-2 h-10 rounded-r"
                     :class="{'bg-yellow-500' : !darkMode, 'border-r-2 border-yellow-500 bg-dark-background': darkMode}"
                     :style="{width: calculatePercentage(metricsBValue) + '%'}">
                    <div class="flex justify-end items-center h-full mr-2 text-white font-bold"
                         v-if="calculatePercentage(metricsBValue) > 10">{{ Math.round(calculatePercentage(metricsBValue)) }}%</div>
                </div>
                <p class="ml-2 font-bold text-yellow-500">
                    {{ metricsBValue }}
                </p>
            </div>
            <div class="flex items-center mb-4" v-if="metricsCTitle">
                <div class="p-2 h-10 rounded-r "
                     :class="{'bg-green-500' : !darkMode, 'border-r-2 border-green-500 bg-dark-background': darkMode}"
                     :style="{width: calculatePercentage(metricsCValue) + '%'}">
                    <div class="flex justify-end items-center h-full mr-2 text-white font-bold" v-if="calculatePercentage(metricsCValue) > 10">{{ Math.round(calculatePercentage(metricsCValue)) }}%</div>
                </div>
                <p class="ml-2 font-bold text-green-500">
                    {{ metricsCValue }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MetricsChart",
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        moduleTitle: {
            type: String,
            default: '',
        },
        metricsATitle: {
            type: String,
            default: ''
        },
        metricsBTitle: {
            type: String,
            default: ''
        },
        metricsCTitle: {
            type: String,
            default: ''
        },
        metricsAValue: {
            type: Number,
            default: 0
        },
        metricsBValue: {
            type: Number,
            default: 0
        },
        metricsCValue: {
            type: Number,
            default: 0
        }
    },
    computed: {
        totalStatistics() {
            return this.metricsAValue + this.metricsBValue + this.metricsCValue;
        }
    },
    methods: {
        calculatePercentage(value) {
            const total = this.totalStatistics;

            if(total === 0)
                return 100;

            return (value / total) * 100;
        }
    }
}
</script>

<style scoped>

</style>
