<template>
    <div class="flex items-center">
        <h3 class="text-lg font-medium leading-none mr-5">{{ title }}</h3>
        <button @click="login" :disabled="disabled"
                class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5">
            Login
        </button>
    </div>
</template>

<script>
import SharedApiService from "../services/api";

export default {
    name: "External",
    props: {
        title: {
            type: String,
            default: 'fixr.com'
        },
        authUrl: {
            type: String,
            default: null
        }
    },
    data:function () {
        return {
            api: SharedApiService.make(),
            disabled: false
        }
    },
    methods: {
        login() {
            if (!this.authUrl) return;

            this.disabled = true;
            this.api.getAuthToken()
                .then(resp => {
                    const url = this.authUrl.replace(/\/$/, '');
                    window.open(`${url}/cp/authenticate-with-token?token=${resp.data.data.token}`, '_blank')
                })
                .catch(() => console.error('Failed to get auth token'))
                .finally(() => this.disabled = false);
        }
    }
}
</script>

<style scoped>

</style>
