import {defineStore} from "pinia";
import ApiService from "../vue/components/Companies/services/api";

/**
 * @typedef state
 * @property {array} activities
 * @property {string} activityType
 * @property {object} filters
 * @property {string} filters.type
 * @property {string} filters.query
 * @property {string} filters.start_date
 * @property {string} filters.end_date
 * @property {string} filters.user_id
 * @property {string} filters.sort_by
 */

export const useActivityTabStore = defineStore('activity-tab', {
    /**
     * @return {{paginationData: *[], activities: *[], filters: {end_date: null, user_id: null, query: null, sort_by: string, start_date: null}, loading: boolean, activityType: null, users: *[]}}
     */
    state: () => {
        return {
            activities: [],
            loading: false,
            paginationData: [],
            users: [],
            activityType: null,
            filters: {
                query: null,
                start_date: null,
                end_date: null,
                user_id: null,
                sort_by: 'updated_at',
            },
        }
    },
    getters: {
        /**
         * @param state
         * @return {{end_date: string, user_id: string, query: string, sort_by: string, type: string, start_date: string}}
         */
        getFilters: (state) => {
            const filters = state.filters;

            if (!state.hasOwnProperty('activityType')) {
                throw new TypeError(`The state should have "activityType" property.`)
            }

            if (![null, "action", "call", "text", "task"].includes(state.activityType)) {
                throw new TypeError(`The "state.activityType" should be one of the following: All, Action, Call, Text or Task.`)
            }

            filters.type = state.activityType;

            return filters;
        }
    },
    actions: {
        async getActivities(companyId, api) {
            if (!(api instanceof ApiService)) {
                throw new TypeError(`"api" must be an instance of ApiService (resources/js/vue/components/Companies/services/api.js). ${JSON.stringify(api)} passed.`)
            }

            if (typeof companyId !== 'number') {
                throw new TypeError(`"companyId" must be a number. ${JSON.stringify(companyId)} passed.`)
            }

            if (typeof this.getFilters !== 'object') {
                throw new TypeError(`"filters" must be an object. ${JSON.stringify(this.getFilters)} passed.`)
            }

            this.loading = true;
            return await api.getCompanyActivityFeed(companyId, this.getFilters).then(response => {
                if (response?.data?.data?.status) {
                    this.addPaginatedData(response);
                }
            }).catch((err) => {
                throw err;
            }).finally(() => {
                this.loading = false;
            });
        },
        addPaginatedData(response) {
            if (response.data.data.status === true) {
                const {data, ...paginationData} = response.data.data.activities;
                this.activities = data;
                this.paginationData = paginationData;
            }
        },
    },
    persist: false
})
