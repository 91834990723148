<template>
    <div class="mt-5">
    </div>
</template>

<script>
import Dropdown from "../../../Shared/components/Dropdown";
import ApiService from "../../services/api";
import LoadingSpinner from "../../../Shared/components/LoadingSpinner";

export default {
    name: "AssignAccountManagerNode",
    components: {Dropdown, LoadingSpinner},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        action: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            api: ApiService.make(),
            loading: false,
        }
    },
    created() {
        if (this.action) {}
    },
    watch: {
        action: function () {
            if (this.action) {} else {}

            this.loadTemplates();
        }
    },
    methods: {
        getDisplayName() {
            return "Assign Account Manager";
        },
        getPayload() {
            return {}
        },
    }
}
</script>

<style lang="scss">

</style>
