import axios from 'axios';

export default class ApiService {
    constructor(baseUrl, baseEndpoint, version) {
        this.baseEndpoint = baseEndpoint;
        this.baseUrl = baseUrl;
        this.version = version;
    }

    axios() {
        const axiosConfig = {
            baseURL: `/${this.baseUrl}/v${this.version}/${this.baseEndpoint}`
        }

        return axios.create(axiosConfig);
    }

    static make() {
        return new ApiService('internal-api', 'company-reviews', 1);
    }

    list() {
        return this.axios().get('/');
    }

    changeListPage(url) {
        return this.axios().get(url);
    }

    update(
        id,
        status,
        emailValidated,
        phoneValidated
    ) {
        return this.axios().patch(`/${id}`, {
            status: status,
            email_validated: emailValidated,
            phone_validated: phoneValidated
        });
    }

    getReviewStatuses() {
        return this.axios().get('/statuses');
    }
}
