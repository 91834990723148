import {markRaw} from "vue";
const {Device} = require('@twilio/voice-sdk');

const STATUS_UNAVAILABLE = 'unavailable';
const STATUS_READY = 'ready';


export default class TwilioCommunicationService {
    constructor() {
        this.phone = null;
        this.status = STATUS_UNAVAILABLE;
        this.call = null;
        this.incomingCall = null;
        this.incomingListeners = {start: [], end: []};
        this._device = null;
        this.listeners = {accept: [], answer: [], decline: [], missed: []};
    }

    get device() {
        return this._device;
    }

    _incoming(connection) {
        this.incomingCall = markRaw(connection);
        this.incomingCall.on('error', () => {this.clearIncomingCall()});
        this.incomingCall.on('disconnect', () => {this.clearIncomingCall()});
        this.incomingCall.on('cancel', () => {this.missed()});
        this.incomingListeners.start.forEach(cb => cb(this._formatConnection(connection)));
    }

    _formatConnection(connection) {
        return {from: connection.parameters.From, id: connection.parameters.CallSid}
    }

    async initialize(token, phone) {
        this.phone = phone;
        // this._device = markRaw(new Device(token, {logLevel: 0}));
        this._device = markRaw(new Device(token));
        await this._device.register();
        console.log("Device registered");

        this._device.on('registered', () => this.status = STATUS_READY);
        this._device.on('incoming', this._incoming.bind(this));
    }

    initializeCall(number) {
        return new Promise(async (resolve, reject) => {
            const connection = await this._device.connect({
                params: {
                    To: number,
                    From: this.phone,
                    Direction: "outbound",
                    Record: true,
                    Outgoing: true
                },
            });

            this.call = markRaw(connection);
            this._registerCallEvents();

            resolve(connection);
        });
    }

    hangUp() {
        this.device.disconnectAll();
        this.call = null;
    }

    hold(status) {

    }

    mute(status) {
        if (this.call != null) {
            this.call.mute(status);
        }
    }

    resetListeners() {
        this.listeners = {accept: [], answer: [], decline: [], missed: []};
    }

    onAccept(cb) {
        this.listeners.accept.push(cb);
    }

    onAnswer(cb) {
        this.listeners.answer.push(cb);
    }

    onHangup(cb) {
        this.listeners.decline.push(cb);
    }

    onMissed(cb) {
        this.listeners.missed.push(cb);
    }

    accept() {
        if (!!this.incomingCall) {
            this.incomingCall.accept(false, false);
            this.call = markRaw(this.incomingCall);
            this.clearIncomingCall();
            this._registerCallEvents();
        }
    }

    _registerCallEvents() {
        this.call.on('accept', call => {
            this.listeners.accept.forEach(cb => cb());
        });

        this.call.on('cancel', call => {
            this.listeners.decline.forEach(cb => cb());
        });

        this.call.on('disconnect', call => {
            this.listeners.decline.forEach(cb => cb());
        });
    }

    decline() {
        if (!!this.incomingCall) {
            this.incomingCall.reject();
            this.clearIncomingCall();
        }
    }

    missed() {
        if(!!this.incomingCall) {
            this.listeners.missed.forEach(cb => cb());
            this.clearIncomingCall();
        }
    }

    clearIncomingCall() {
        this.incomingCall = null;
        this.incomingListeners.end.forEach(cb => cb())
    }

    onIncomingCall(cb) {
        this.incomingListeners.start.push(cb);
    }

    onIncomingEnd(cb) {
        this.incomingListeners.end.push(cb);
    }

    serviceName() {
        return 'twilio';
    }

    getCallId() {
        return this.call.parameters.CallSid;
    }

    getUserPhone() {
        return this.phone;
    }
}
