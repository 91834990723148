<template>
    <div class="relative p-6 rounded-lg my-4 "
         :class="[
             direction === 'outbound' ? 'mr-2 ml-auto' : 'ml-2',
             this.darkMode
                ? this.direction === 'outbound'
                    ? 'bg-slate-800'
                    : 'bg-slate-700'
                : this.direction === 'outbound'
                    ? 'bg-primary-50'
                    : 'bg-primary-200'
         ]">
        <p v-if="from" class="text-primary-500 text-xs mb-2">{{ from }}</p>
        <p>{{ content }}</p>
        <div class="absolute border-[1.2rem] border-b-transparent top-0"
             :class="[
                direction === 'outbound' ? 'border-r-transparent right-[-1.25rem]' : 'border-l-transparent left-[-1.25rem]',
                this.darkMode
                    ? this.direction === 'outbound'
                        ? 'border-slate-800'
                        : 'border-slate-700'
                    : this.direction === 'outbound'
                        ? 'border-primary-50'
                        : 'border-primary-200',
             ]"/>
        <div class="opacity-60 text-xs absolute bottom-1 right-1">
            {{ $filters.simpleRelativeTimeFromTimestamp(timestamp) }}
        </div>
    </div>
</template>

<script>
export default {
    name: "SpeechBubble",
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        from: {
            type: String,
            default: '',
        },
        timestamp: {
            type: String,
            default: '',
        },
        direction: {
            type: String,
            default: '',
        },
        content: {
            type: String,
            default: '',
        },
    },
    data() {
        return {

        }
    },
    mounted() {

    }
}
</script>

<style scoped>

</style>
