<template>
    <div :class="[blockDisplay ? '' : 'absolute right-0 bottom-14']" ref="container" @keyup="handleKeyUp" tabindex="0">
        <div v-if="!blockDisplay" @click="toggleDialerModal" :class="iconClasses" class="z-20 mr-10 relative border transition duration-200 shadow-module w-10 h-10 rounded-full inline-flex justify-center items-center cursor-pointer">
            <svg class="fill-current" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2Z"/>
                <path d="M10 2C10 3.10457 9.10457 4 8 4C6.89543 4 6 3.10457 6 2C6 0.89543 6.89543 0 8 0C9.10457 0 10 0.89543 10 2Z"/>
                <path d="M16 2C16 3.10457 15.1046 4 14 4C12.8954 4 12 3.10457 12 2C12 0.89543 12.8954 0 14 0C15.1046 0 16 0.89543 16 2Z"/>
                <path d="M16 8C16 9.10457 15.1046 10 14 10C12.8954 10 12 9.10457 12 8C12 6.89543 12.8954 6 14 6C15.1046 6 16 6.89543 16 8Z"/>
                <path d="M16 14C16 15.1046 15.1046 16 14 16C12.8954 16 12 15.1046 12 14C12 12.8954 12.8954 12 14 12C15.1046 12 16 12.8954 16 14Z"/>
                <path d="M10 14C10 15.1046 9.10457 16 8 16C6.89543 16 6 15.1046 6 14C6 12.8954 6.89543 12 8 12C9.10457 12 10 12.8954 10 14Z"/>
                <path d="M10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8Z"/>
                <path d="M4 8C4 9.10457 3.10457 10 2 10C0.89543 10 0 9.10457 0 8C0 6.89543 0.89543 6 2 6C3.10457 6 4 6.89543 4 8Z"/>
                <path d="M4 14C4 15.1046 3.10457 16 2 16C0.89543 16 0 15.1046 0 14C0 12.8954 0.89543 12 2 12C3.10457 12 4 12.8954 4 14Z"/>
            </svg>
        </div>
        <div v-else>
            <div :class="[darkMode ? 'bg-blue-800 text-blue-550 border border-transparent hover:border-primary-500' : 'bg-cyan-125 text-blue-550 hover:bg-cyan-200']" class="cursor-pointer transition duration-200 rounded-full h-16 w-16 inline-flex items-center justify-center"
                  @click="toggleDialerModal">
                <svg class="fill-current w-6 h-6" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2Z"/>
                    <path d="M10 2C10 3.10457 9.10457 4 8 4C6.89543 4 6 3.10457 6 2C6 0.89543 6.89543 0 8 0C9.10457 0 10 0.89543 10 2Z"/>
                    <path d="M16 2C16 3.10457 15.1046 4 14 4C12.8954 4 12 3.10457 12 2C12 0.89543 12.8954 0 14 0C15.1046 0 16 0.89543 16 2Z"/>
                    <path d="M16 8C16 9.10457 15.1046 10 14 10C12.8954 10 12 9.10457 12 8C12 6.89543 12.8954 6 14 6C15.1046 6 16 6.89543 16 8Z"/>
                    <path d="M16 14C16 15.1046 15.1046 16 14 16C12.8954 16 12 15.1046 12 14C12 12.8954 12.8954 12 14 12C15.1046 12 16 12.8954 16 14Z"/>
                    <path d="M10 14C10 15.1046 9.10457 16 8 16C6.89543 16 6 15.1046 6 14C6 12.8954 6.89543 12 8 12C9.10457 12 10 12.8954 10 14Z"/>
                    <path d="M10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8Z"/>
                    <path d="M4 8C4 9.10457 3.10457 10 2 10C0.89543 10 0 9.10457 0 8C0 6.89543 0.89543 6 2 6C3.10457 6 4 6.89543 4 8Z"/>
                    <path d="M4 14C4 15.1046 3.10457 16 2 16C0.89543 16 0 15.1046 0 14C0 12.8954 0.89543 12 2 12C3.10457 12 4 12.8954 4 14Z"/>
                </svg>
            </div>
            <p class="text-center font-medium mt-1" :class="[darkMode ? 'text-white' : ' text-black']">
                Dialer
            </p>
        </div>
        <div v-if="dialerModal" class="absolute z-30">
            <div class="fixed inset-0 flex items-center justify-center bg-opacity-75 bg-dark-background">
                <div class="absolute shadow rounded-lg overflow-hidden" :class="[blockDisplay ? 'border-none w-full py-5 bg-opacity-50 h-full' : 'w-80',!darkMode ? 'bg-light-module border border-light-border' : 'bg-dark-background border border-dark-border']">
                    <div v-if="!blockDisplay" class="border-b"
                         :class="{'border-light-border': !darkMode, 'border-dark-border': darkMode}">
                        <div class="px-6 py-5 flex justify-between items-center">
                            <div class="" :class="{'text-black': !darkMode, 'text-grey-120': darkMode}">
                                <h4 class="font-medium">Keypad</h4>
                            </div>
                            <button class="modal-default-button" @click="toggleDialerModal"
                                    :class="{'text-black': !darkMode, 'text-grey-120': darkMode}">
                                <svg class="w-4 fill-current" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.9035 4.78018L9.32136 1.36237C9.47273 1.21896 9.56036 1.01977 9.56036 0.796696C9.56036 0.358513 9.20184 0 8.76366 0C8.54058 0 8.34139 0.0876191 8.19799 0.231024L4.78018 3.65686L1.36237 0.231024C1.21896 0.0876191 1.01977 0 0.796696 0C0.358513 0 0 0.358513 0 0.796696C0 1.01977 0.0876191 1.21896 0.231024 1.36237L3.65686 4.78018L0.238999 8.19799C0.0876269 8.34139 0 8.54058 0 8.76366C0 9.20184 0.358513 9.56036 0.796696 9.56036C1.01977 9.56036 1.21896 9.47274 1.36237 9.32933L4.78018 5.9035L8.19799 9.32136C8.34139 9.47273 8.54058 9.56036 8.76366 9.56036C9.20184 9.56036 9.56036 9.20184 9.56036 8.76366C9.56036 8.54058 9.47274 8.34139 9.32933 8.19799L5.9035 4.78018Z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div v-else class="flex justify-end px-6">
                        <div class="flex items-center cursor-pointer font-bold" :class="[darkMode ? 'text-white' : 'text-slate-900']" @click="toggleDialerModal">
                            <button class="modal-default-button mr-2"
                                    :class="{'text-black': !darkMode, 'text-grey-120': darkMode}">
                                <svg class="w-4 fill-current" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.9035 4.78018L9.32136 1.36237C9.47273 1.21896 9.56036 1.01977 9.56036 0.796696C9.56036 0.358513 9.20184 0 8.76366 0C8.54058 0 8.34139 0.0876191 8.19799 0.231024L4.78018 3.65686L1.36237 0.231024C1.21896 0.0876191 1.01977 0 0.796696 0C0.358513 0 0 0.358513 0 0.796696C0 1.01977 0.0876191 1.21896 0.231024 1.36237L3.65686 4.78018L0.238999 8.19799C0.0876269 8.34139 0 8.54058 0 8.76366C0 9.20184 0.358513 9.56036 0.796696 9.56036C1.01977 9.56036 1.21896 9.47274 1.36237 9.32933L4.78018 5.9035L8.19799 9.32136C8.34139 9.47273 8.54058 9.56036 8.76366 9.56036C9.20184 9.56036 9.56036 9.20184 9.56036 8.76366C9.56036 8.54058 9.47274 8.34139 9.32933 8.19799L5.9035 4.78018Z"/>
                                </svg>
                            </button>
                            <p class="font-bold" :class="[darkMode ? 'text-white' : 'text-slate-900']">
                                Close Dialer
                            </p>
                        </div>
                    </div>
                    <div class="flex justify-center">
                        <div class="my-6 mx-8" :class="{'text-black': !darkMode, 'text-grey-120': darkMode}">
                            <input ref="dialerfocus" v-model="displayInput" @paste.prevent="onPaste" class="font-semibold text-2xl cursor-default w-full focus:outline-none border-none bg-transparent text-center">
                        </div>
                    </div>
                    <div class="flex justify-center">
                        <div class="grid grid-cols-3 gap-y-5 gap-x-6">
                            <div @click="addInput('1')" class="h-16 w-16 rounded-full inline-flex items-center justify-center font-semibold relative cursor-pointer" :class="[!darkMode ? 'bg-slate-200 text-slate-900 hover:bg-blue-100' : 'bg-slate-800 text-slate-200 hover:bg-slate-600']">
                                <p class="font-bold text-xl mb-1">1</p>
                                <div class="absolute items-end justify-center mb-5 inline-flex text-2xs w-full font-regular h-full"></div>
                            </div>
                            <div @click="addInput('2')" class="h-16 w-16 rounded-full inline-flex items-center justify-center font-semibold relative cursor-pointer" :class="[!darkMode ? 'bg-slate-200 text-slate-900 hover:bg-blue-100' : 'bg-slate-800 text-slate-200 hover:bg-slate-600']">
                                <p class="font-bold text-xl mb-1">2</p>
                                <div class="absolute items-end justify-center mb-5 inline-flex text-2xs w-full font-regular h-full">ABC</div>
                            </div>
                            <div @click="addInput('3')" class="h-16 w-16 rounded-full inline-flex items-center justify-center font-semibold relative cursor-pointer" :class="[!darkMode ? 'bg-slate-200 text-slate-900 hover:bg-blue-100' : 'bg-slate-800 text-slate-200 hover:bg-slate-600']">
                                <p class="font-bold text-xl mb-1">3</p>
                                <div class="absolute items-end justify-center mb-5 inline-flex text-2xs w-full font-regular h-full">DEF</div>
                            </div>
                            <div @click="addInput('4')" class="h-16 w-16 rounded-full inline-flex items-center justify-center font-semibold relative cursor-pointer" :class="[!darkMode ? 'bg-slate-200 text-slate-900 hover:bg-blue-100' : 'bg-slate-800 text-slate-200 hover:bg-slate-600']">
                                <p class="font-bold text-xl mb-1">4</p>
                                <div class="absolute items-end justify-center mb-5 inline-flex text-2xs w-full font-regular h-full">GHI</div>
                            </div>
                            <div @click="addInput('5')" class="h-16 w-16 rounded-full inline-flex items-center justify-center font-semibold relative cursor-pointer" :class="[!darkMode ? 'bg-slate-200 text-slate-900 hover:bg-blue-100' : 'bg-slate-800 text-slate-200 hover:bg-slate-600']">
                                <p class="font-bold text-xl mb-1">5</p>
                                <div class="absolute items-end justify-center mb-5 inline-flex text-2xs w-full font-regular h-full">JKL</div>
                            </div>
                            <div @click="addInput('6')" class="h-16 w-16 rounded-full inline-flex items-center justify-center font-semibold relative cursor-pointer" :class="[!darkMode ? 'bg-slate-200 text-slate-900 hover:bg-blue-100' : 'bg-slate-800 text-slate-200 hover:bg-slate-600']">
                                <p class="font-bold text-xl mb-1">6</p>
                                <div class="absolute items-end justify-center mb-5 inline-flex text-2xs w-full font-regular h-full">MNO</div>
                            </div>
                            <div @click="addInput('7')" class="h-16 w-16 rounded-full inline-flex items-center justify-center font-semibold relative cursor-pointer" :class="[!darkMode ? 'bg-slate-200 text-slate-900 hover:bg-blue-100' : 'bg-slate-800 text-slate-200 hover:bg-slate-600']">
                                <p class="font-bold text-xl mb-1">7</p>
                                <div class="absolute items-end justify-center mb-5 inline-flex text-2xs w-full font-regular h-full">PQRS</div>
                            </div>
                            <div @click="addInput('8')" class="h-16 w-16 rounded-full inline-flex items-center justify-center font-semibold relative cursor-pointer" :class="[!darkMode ? 'bg-slate-200 text-slate-900 hover:bg-blue-100' : 'bg-slate-800 text-slate-200 hover:bg-slate-600']">
                                <p class="font-bold text-xl mb-1">8</p>
                                <div class="absolute items-end justify-center mb-5 inline-flex text-2xs w-full font-regular h-full">TUV</div>
                            </div>
                            <div @click="addInput('9')" class="h-16 w-16 rounded-full inline-flex items-center justify-center font-semibold relative cursor-pointer" :class="[!darkMode ? 'bg-slate-200 text-slate-900 hover:bg-blue-100' : 'bg-slate-800 text-slate-200 hover:bg-slate-600']">
                                <p class="font-bold text-xl mb-1">9</p>
                                <div class="absolute items-end justify-center mb-5 inline-flex text-2xs w-full font-regular h-full">WXYZ</div>
                            </div>
                            <div @click="addInput('*')" class="h-16 w-16 rounded-full inline-flex items-center justify-center font-semibold relative cursor-pointer" :class="[!darkMode ? 'bg-slate-200 text-slate-900 hover:bg-blue-100' : 'bg-slate-800 text-slate-200 hover:bg-slate-600']">
                                <p class="font-bold text-lg">
                                    <svg class="fill-current w-2" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.85019 12.4546L5.09521 8.13992L1.47306 10.5156L0.17334 8.25711L4.04053 6.31819L0.17334 4.37927L1.47306 2.12074L5.09521 4.49645L4.85019 0.181824H7.43897L7.20459 4.49645L10.8267 2.12074L12.1265 4.37927L8.24862 6.31819L12.1265 8.25711L10.8267 10.5156L7.20459 8.13992L7.43897 12.4546H4.85019Z"/>
                                    </svg>
                                </p>
                                <div class="absolute items-end justify-center mb-5 inline-flex text-2xs w-full font-regular h-full"></div>
                            </div>
                            <div @click="addInput('0')" class="h-16 w-16 rounded-full inline-flex items-center justify-center font-semibold relative cursor-pointer" :class="[!darkMode ? 'bg-slate-200 text-slate-900 hover:bg-blue-100' : 'bg-slate-800 text-slate-200 hover:bg-slate-600']">
                                <p class="font-bold text-lg mt-1">0</p>
                            </div>
                            <div @click="addInput('#')" class="h-16 w-16 rounded-full inline-flex items-center justify-center font-semibold relative cursor-pointer" :class="[!darkMode ? 'bg-slate-200 text-slate-900 hover:bg-blue-100' : 'bg-slate-800 text-slate-200 hover:bg-slate-600']">
                                <p class="font-bold">#</p>
                            </div>
                        </div>
                    </div>
                    <div v-if="!onlyShowKeys" class="flex justify-center py-5 mt-6" :class="[!darkMode ? 'bg-slate-200' : 'bg-slate-800']">
                        <div class="grid grid-cols-3 gap-6">
                            <div @click="sms" class="h-16 w-16 rounded-full inline-flex justify-center items-center bg-primary-500 hover:bg-blue-500 cursor-pointer">
                                <svg class="w-5 mt-1 fill-current text-white" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.6 1.21378e-06H2.4C1.0764 1.21378e-06 0 1.0728 0 2.3904V15C0 16.3176 1.0764 17.3904 2.4 17.3904H6V24L13.6212 17.3904H21.6C22.9236 17.3904 24 16.3176 24 15V2.3904C23.9981 1.75535 23.7443 1.147 23.2944 0.698847C22.8444 0.250697 22.2351 -0.000637338 21.6 1.21378e-06Z"/>
                                </svg>

                            </div>
                            <div @click="call" class="h-16 w-16 rounded-full inline-flex justify-center items-center bg-green-400 hover:bg-green-500 cursor-pointer">
                                <svg class="w-6 fill-current text-white" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.5224 20.3664L19.5896 14.9722C19.3091 14.7174 18.9406 14.5814 18.5618 14.5931C18.183 14.6048 17.8236 14.7633 17.5594 15.035L14.0669 18.6268C13.2262 18.4662 11.5362 17.9394 9.79645 16.204C8.05675 14.4629 7.52988 12.7685 7.37371 11.9336L10.9626 8.43966C11.2346 8.17569 11.3933 7.81621 11.405 7.43731C11.4167 7.05841 11.2806 6.68982 11.0253 6.40954L5.63255 0.478257C5.3772 0.1971 5.02231 0.026557 4.64325 0.00284858C4.26418 -0.0208599 3.8908 0.104134 3.60241 0.351283L0.435337 3.06736C0.183009 3.3206 0.0324027 3.65764 0.0120874 4.01455C-0.00980485 4.37942 -0.427216 13.0224 6.27472 19.7272C12.1214 25.5724 19.4451 26 21.4621 26C21.7569 26 21.9379 25.9912 21.986 25.9883C22.3429 25.9683 22.6798 25.8171 22.9318 25.5636L25.6464 22.3951C25.8945 22.1076 26.0205 21.7346 25.9973 21.3555C25.9741 20.9765 25.8037 20.6215 25.5224 20.3664Z"/>
                                </svg>
                            </div>
                            <div @click="backspace" class="h-16 w-16 rounded-full inline-flex justify-center items-center bg-transparent cursor-pointer">
                                <svg class="fill-current " :class="[!darkMode ? 'text-slate-500 hover:text-slate-600' : 'text-slate-500 hover:text-slate-400']" width="42" height="19" viewBox="0 0 42 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 0H40C41.1046 0 42 0.895431 42 2V17C42 18.1046 41.1046 19 40 19H9.5L0 9.5L9.5 0ZM23.4996 8.375L26.8751 5L28 6.125L24.6253 9.5L28 12.875L26.8751 14L23.4996 10.625L20.1249 14L19 12.875L22.3747 9.5L19 6.125L20.1249 5L23.4996 8.375Z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Dialer",
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        onlyShowKeys: {
            type: Boolean,
            default: false,
        },
        blockDisplay: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            dialerModal: false,
            input: "",
        }
    },
    emits: ["call", "sms"],
    methods: {
        toggleDialerModal() {
            this.dialerModal = ! this.dialerModal;
            this.reset();

            if(this.dialerModal)
                // Focus input upon opening Dialer.
                this.$nextTick(() => this.$refs.dialerfocus.focus());
        },
        reset() {
            this.input = ""
        },
        backspace() {
            if(this.input.length > 0)
                this.input = this.input.slice(0, -1);
        },
        addInput(value) {
            this.$emit('key-pressed', value);
            this.input += String(value);
        },
        call() {
            this.$emit('call', this.input);
            this.dialerModal = false;
            this.reset();
        },
        sms() {
            this.$emit('sms', this.input);
            this.dialerModal = false;
            this.reset();
        },
        handleKeyUp(key) {
            if(this.dialerModal) {
                if(/[1234567890+*#]/.test(key.key)) {
                    this.input += key.key;
                } else if(key.key.toLowerCase() === 'backspace' && this.input.length > 0) {
                    this.input = this.input.slice(0, -1);
                }
            }
        },
        onPaste (e) {
            this.input = e.clipboardData.getData('text/plain').replace(/[-()]/g, '');
        }
    },
    computed: {
        iconClasses() {
            const classes = [];

            if(this.voicemailList) {
                if(this.darkMode) {
                    classes.push('bg-dark-background', 'text-blue-550', 'border-primary-500', 'hover:border-blue-400', 'hover:bg-dark-175');
                } else {
                    classes.push('bg-primary-500', 'text-white', 'hover:bg-blue-500', 'border-transparent');
                }
            } else {
                if(this.darkMode) {
                    classes.push('bg-dark-background', 'text-blue-550', 'border-primary-500', 'hover:border-blue-400', 'hover:bg-dark-background');
                } else {
                    classes.push('bg-light-module', 'text-blue-550', 'border-light-border', 'hover:border-primary-500', 'hover:bg-cyan-125');
                }
            }

            return classes;
        },
        displayInput() {
            if(this.input.length > 6) {
                return `${this.input.substring(0, 3)}-${this.input.substring(3, 6)}-${this.input.substring(6)}`;
            } else if(this.input.length > 3) {
                return `${this.input.substring(0, 3)}-${this.input.substring(3)}`;
            }

            return this.input;
        }
    }
}
</script>

<style scoped>

</style>
