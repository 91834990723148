<template>
    <div>
        <div class="w-auto min-h-[16rem]">
            <header class="text-lg mb-4">
                <div class="flex items-center mb-4">
                    <p class="text-sm mr-3">assigned to <span class="text-primary-500">{{ taskAssignedUser.name || 'no one' }}</span></p>
                    <p class="font-medium px-2 py-1 text-xs border text-center rounded-full" :class="[task.completed ? 'text-green-500 border-green-400' : 'text-rose-500 border-rose-500']">Task{{ task.completed ? '' : ' Not'}} Completed</p>
                </div>
                <div v-if="expanded">
                    <Dropdown class="max-w-sm"
                        placeholder="Quick Actions"
                        @change="handleAction"
                        :options="activityActions"
                        :dark-mode="darkMode"
                    />
                </div>
            </header>
            <div>
                <p><strong>Subject:</strong> {{ task.subject ?? 'none' }}</p>
                <p><strong>Category:</strong> {{ task.task_category?.name ?? 'none' }}</p>
                <p><strong>Task Type:</strong> {{ task.task_type?.name ?? 'none' }}</p>
            </div>
            <div v-if="task.task_notes?.length" class="pt-2 w-full" :class="[darkMode ? 'border-dark-border' : 'border-light-border']">
                <h5><strong>Notes:</strong></h5>
                <div v-for="item in task.task_notes" class="mt-2">
                    <p>{{ item.note }} - <span class="italic text-primary-300">{{ item.user?.name || 'Unknown User'}}</span></p>
                </div>
            </div>
            <div v-else class="mt-4">
                No task notes found
            </div>
        </div>
    </div>
</template>

<script>
import Dropdown from "../../../../Shared/components/Dropdown";

export default {
    name: "TaskItem",
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        itemData: {
            type: Object,
            default: {},
        },
        userData: {
            type: Object,
            default: {},
        },
        expanded: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        Dropdown
    },
    data() {
        return {
            task: {
                id: null,
                subject: '',
                results: '',
                completed: '',
                priority: '',
                task_type: {},
                task_category: {},
                task_notes: [],
            },
            user: {
                name: '',
                email: '',
            },
            taskAssignedUser: {
                name: '',
                email: '',
            },
            activityActions: [
                // Example follow-up actions
                { id: 'emailUser', name: `Email ${this.userData?.name ?? 'User'}`, action: this.emailUser },
                { id: 'goToTask', name: 'Jump to Task', action: this.goToTask }
            ],
        }
    },
    mounted() {
        this.task = { ...this.task, ...this.itemData };
        this.user = { ...this.user, ...this.userData };
        if (this.itemData.assigned_user) { this.taskAssignedUser = { ...this.taskAssignedUser, ...this.itemData.assigned_user }; }
    },
    methods: {
        handleAction({ id }) {
            const targetAction = this.activityActions.find(action => action.id === id);
            if (typeof(targetAction.action) === 'function') targetAction.action();
            else alert(`Triggered function ${id} not yet implemented.`);
        },
        goToTask() {
            const url = `/tasks/queue?tasks=${this.task.id}`;
            window.open(url, '_blank');
        },
        emailUser() {
            if (this.userData.email) window.open(`mailto:${this.userData.email}`, '_self');
        }
    }
}
</script>

<style scoped>

</style>
