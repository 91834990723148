<template>
    <div>
        <div class="border rounded-lg"
             :class="{'bg-light-module border-light-border text-slate-900': !darkMode, 'bg-dark-module border-dark-border text-slate-100': darkMode}">
            <div class="grid grid-cols-1 md:grid-cols-7 md:divide-x"
                 :class="[darkMode ? 'divide-dark-border' : 'divide-light-border']">
                <div class="col-span-2">
                    <div class="relative p-4">
                        <p class="">Available Credit</p>
                        <p class="text-lg font-semibold text-green-500">{{ $filters.currency(availableCredit) }}</p>
                        <div class="absolute right-0 top-0 mt-3 mr-3">
                            <ActionsHandle :dark-mode="darkMode" @edit="toggleCreditModal" @delete="toggleVoucherModal"
                                           :action-text1="'Manage Credit'" :action-text2="'Manage Voucher'"
                                           :width="'w-64'"></ActionsHandle>
                        </div>
                    </div>
                    <div class="w-full grid grid-cols-2 md:divide-x md:border-t"
                         :class="[darkMode ? 'divide-dark-border border-dark-border text-slate-400' : 'text-slate-500divide-light-border border-light-border']">
                        <div class="px-4 py-2">
                            <p class="text-sm">Credit: {{ $filters.currency(amountCredit) }}</p>
                        </div>
                        <div class="px-4 py-2">
                            <p class="text-sm">Vouchers: {{ $filters.currency(amountVouchers) }}</p>
                        </div>
                    </div>
                </div>
                <div class="p-4 flex items-center">
                    <div>
                        <p class="">Total Outstanding</p>
                        <p class="text-lg font-semibold text-red-500">{{ $filters.currency(totalOutstanding) }}</p>
                    </div>
                </div>
                <div class="p-4 flex items-center">
                    <div>
                        <p class="">Total Paid</p>
                        <p class="text-lg font-semibold">{{ $filters.currency(totalPaid) }}</p>
                    </div>
                </div>
                <div class="p-4 flex items-center">
                    <div>
                        <p class="">Total To Date</p>
                        <p class="text-lg font-semibold">{{ $filters.currency(totalToDate) }}</p>
                    </div>
                </div>
                <div class="p-4 flex items-center">
                    <div>
                        <p class="">Outstanding Leads</p>
                        <p class="text-lg font-semibold">{{ $filters.currency(outstandingLeads) }}</p>
                    </div>
                </div>
                <div class="p-4 flex items-center">
                    <div>
                        <p class="">Total To Be Invoiced</p>
                        <p class="text-lg font-semibold">{{ $filters.currency(totalToBeInvoiced) }}</p>
                    </div>
                </div>
            </div>
        </div>
        <Modal :dark-mode="darkMode" :no-buttons="true" :small="true" v-if="creditModal" @close="toggleCreditModal">
            <template v-slot:header>
                <p class="font-semibold">Manage Credit</p>
            </template>
            <template v-slot:content>
                <div v-if="!showCancelCredit" class="grid grid-cols-1 min-w-full max-w-sm gap-4">
                    <button @click="toggleCancelCredit"
                            class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-semibold focus:outline-none py-2 rounded-md px-5">
                        Cancel Credit
                    </button>
                </div>
                <div v-if="showCancelCredit" class="grid grid-cols-1 min-w-full max-w-sm gap-4">
                    <div class="border-b" :class="[darkMode ? 'border-dark-border' : 'border-light-border']">
                        <div class="relative p-4">
                            <p class="">Available Credit</p>
                            <p class="text-lg font-semibold text-green-500">{{ $filters.currency(availableCredit) }}</p>
                        </div>
                        <div class="w-full grid grid-cols-2 md:divide-x md:border-t"
                             :class="[darkMode ? 'divide-dark-border border-dark-border' : 'divide-light-border border-light-border']">
                            <div class="px-4 py-2">
                                <p class="text-slate-500 text-sm">Credit: {{ $filters.currency(amountCredit) }}</p>
                            </div>
                            <div class="px-4 py-2">
                                <p class="text-slate-500 text-sm">Vouchers: {{ $filters.currency(amountVouchers) }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="p-4">
                        <p class="font-semibold mb-4 text-lg">Cancel Credit</p>
                        <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10 pb-4"
                             :class="[!darkMode ? 'text-grey-900' : 'text-white']">
                            <div class="flex gap-5 pr-5 items-center">
                                <div class="flex items-center mt-3">
                                    <input id="cancel-credit-date" name="cancel-credit-date" type="radio"
                                           :checked="dateToCancelCredit === 'today'" value="today"
                                           class="focus:ring-blue-550 cursor-pointer h-5 w-5 text-blue-550 border-gray-300"/>
                                    <label :class="[!darkMode ? 'text-grey-900' : 'text-white']"
                                           for="report-mode-single" class="ml-2 block font-medium">
                                        Today
                                    </label>
                                </div>
                                <div class="flex items-center mt-3">
                                    <input id="cancel-credit-date" name="cancel-credit-date" type="radio"
                                           :checked="dateToCancelCredit === 'schedule'" value="scheduled"
                                           class="focus:ring-blue-550 cursor-pointer h-5 w-5 text-blue-550 border-gray-300"/>
                                    <label :class="[!darkMode ? 'text-grey-900' : 'text-white']"
                                           class="ml-2 block font-medium">
                                        Schedule Date
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div v-if="dateToCancelCredit === 'schedule'">
                            <div class="w-80">
                                <Datepicker :dark="darkMode"/>
                            </div>
                        </div>
                        <div class="flex items-center mt-4">
                            <p class="font-semibold mr-2 text-lg">$</p>
                            <div class="grid grid-cols-2 gap-3">
                                <input
                                    class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                                    placeholder="Amount"
                                    :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>
                                <button
                                    class="w-full transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-semibold focus:outline-none py-2 rounded-md px-5">
                                    Apply Credit Cancellation
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </template>
        </Modal>
        <Modal :dark-mode="darkMode" :no-buttons="true" :small="true" v-if="voucherModal" @close="toggleVoucherModal">
            <template v-slot:header>
                <p class="font-semibold">Manage Voucher</p>
            </template>
            <template v-slot:content>
                <div v-if="!showCancelVoucher && !showAddVoucher" class="grid grid-cols-1 min-w-full max-w-sm gap-4">
                    <button @click="toggleCancelVoucher"
                            class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-semibold focus:outline-none py-2 rounded-md px-5">
                        Cancel Voucher
                    </button>
                    <button @click="toggleAddVoucher"
                            class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-semibold focus:outline-none py-2 rounded-md px-5">
                        Add Voucher
                    </button>
                </div>
                <div v-if="showCancelVoucher" class="grid grid-cols-1 min-w-full max-w-sm gap-4">
                    <div class="border-b" :class="[darkMode ? 'border-dark-border' : 'border-light-border']">
                        <div class="relative p-4">
                            <p class="">Available Credit</p>
                            <p class="text-lg font-semibold text-green-500">{{ $filters.currency(availableCredit) }}</p>
                        </div>
                        <div class="w-full grid grid-cols-2 md:divide-x md:border-t"
                             :class="[darkMode ? 'divide-dark-border border-dark-border' : 'divide-light-border border-light-border']">
                            <div class="px-4 py-2">
                                <p class="text-slate-500 text-sm">Credit: {{ $filters.currency(amountCredit) }}</p>
                            </div>
                            <div class="px-4 py-2">
                                <p class="text-slate-500 text-sm">Vouchers: {{ $filters.currency(amountVouchers) }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="p-4">
                        <p class="font-semibold mb-4 text-lg">Cancel Voucher</p>
                        <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10 pb-4"
                             :class="[!darkMode ? 'text-grey-900' : 'text-white']">
                            <div class="flex gap-5 pr-5 items-center">
                                <div class="flex items-center mt-3">
                                    <input id="cancel-credit-date" name="cancel-credit-date" type="radio"
                                           :checked="dateToCancelCredit === 'today'" value="today"
                                           class="focus:ring-blue-550 cursor-pointer h-5 w-5 text-blue-550 border-gray-300"/>
                                    <label :class="[!darkMode ? 'text-grey-900' : 'text-white']"
                                           for="report-mode-single" class="ml-2 block font-medium">
                                        Today
                                    </label>
                                </div>
                                <div class="flex items-center mt-3">
                                    <input id="cancel-credit-date" name="cancel-credit-date" type="radio"
                                           :checked="dateToCancelCredit === 'schedule'" value="scheduled"
                                           class="focus:ring-blue-550 cursor-pointer h-5 w-5 text-blue-550 border-gray-300"/>
                                    <label :class="[!darkMode ? 'text-grey-900' : 'text-white']"
                                           class="ml-2 block font-medium">
                                        Schedule Date
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div v-if="dateToCancelCredit === 'schedule'">
                            <div class="w-80">
                                <Datepicker :dark="darkMode"/>
                            </div>
                        </div>
                        <div class="flex items-center mt-4">
                            <p class="font-semibold mr-2 text-lg">$</p>
                            <div class="grid grid-cols-2 gap-3">
                                <input
                                    class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                                    placeholder="Amount"
                                    :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>
                                <button
                                    class="w-full transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-semibold focus:outline-none py-2 rounded-md px-5">
                                    Apply Voucher Cancellation
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
                <div v-if="showAddVoucher" class="grid grid-cols-1 min-w-full max-w-sm gap-4">
                    <div class="border-b" :class="[darkMode ? 'border-dark-border' : 'border-light-border']">
                        <div class="relative p-4">
                            <p class="">Available Credit</p>
                            <p class="text-lg font-semibold text-green-500">{{ $filters.currency(availableCredit) }}</p>
                        </div>
                        <div class="w-full grid grid-cols-2 md:divide-x md:border-t"
                             :class="[darkMode ? 'divide-dark-border border-dark-border' : 'divide-light-border border-light-border']">
                            <div class="px-4 py-2">
                                <p class="text-slate-500 text-sm">Credit: {{ $filters.currency(amountCredit) }}</p>
                            </div>
                            <div class="px-4 py-2">
                                <p class="text-slate-500 text-sm">Vouchers: {{ $filters.currency(amountVouchers) }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="p-4">
                        <p class="font-semibold mb-4 text-lg">Add Voucher</p>
                        <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10 pb-4"
                             :class="[!darkMode ? 'text-grey-900' : 'text-white']">
                            <div class="flex gap-5 pr-5 items-center">
                                <div class="flex items-center mt-3">
                                    <input id="cancel-credit-date" name="cancel-credit-date" type="radio"
                                           :checked="dateToCancelCredit === 'today'" value="today"
                                           class="focus:ring-blue-550 cursor-pointer h-5 w-5 text-blue-550 border-gray-300"/>
                                    <label :class="[!darkMode ? 'text-grey-900' : 'text-white']"
                                           for="report-mode-single" class="ml-2 block font-medium">
                                        Today
                                    </label>
                                </div>
                                <div class="flex items-center mt-3">
                                    <input id="cancel-credit-date" name="cancel-credit-date" type="radio"
                                           :checked="dateToCancelCredit === 'schedule'" value="scheduled"
                                           class="focus:ring-blue-550 cursor-pointer h-5 w-5 text-blue-550 border-gray-300"/>
                                    <label :class="[!darkMode ? 'text-grey-900' : 'text-white']"
                                           class="ml-2 block font-medium">
                                        Schedule Date
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div v-if="dateToCancelCredit === 'schedule'">
                            <div class="w-80">
                                <Datepicker :dark="darkMode"/>
                            </div>
                        </div>
                        <div class="flex items-center mt-4">
                            <p class="font-semibold mr-2 text-lg">$</p>
                            <div class="grid grid-cols-2 gap-3">
                                <input
                                    class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                                    placeholder="Amount"
                                    :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>
                                <button
                                    class="w-full transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-semibold focus:outline-none py-2 rounded-md px-5">
                                    Apply Voucher
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </template>
        </Modal>
    </div>


</template>

<script>
import ActionsHandle from "../../Shared/components/ActionsHandle";
import Modal from "../../Shared/components/Modal";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import ApiService from "../services/api";

export default {
    name: "InvoiceSummary",
    components: {ActionsHandle, Modal, Datepicker},
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        companyId: {
            type: Number
        }
    },
    data() {
        return {
            apiService: ApiService.make(),
            availableCredit: '0.00',
            amountCredit: '0.00',
            amountVouchers: '0.00',
            totalOutstanding: 0,
            totalPaid: 0,
            totalToDate: 0,
            outstandingLeads: 0,
            totalToBeInvoiced: 0,
            creditModal: false,
            voucherModal: false,
            showCancelCredit: false,
            showCancelVoucher: false,
            showAddVoucher: false,
            dateToCancelCredit: 'today',
        }
    },
    mounted() {
        this.fetchInvoicesSummary();
    },
    methods: {
        async fetchInvoicesSummary() {
            const res = await this.apiService.getCompanyInvoicesSummary(this.companyId);
            const invoicesSummary = res.data.data.invoices_summary;
            this.totalOutstanding = invoicesSummary.unpaid;
            this.totalPaid = invoicesSummary.paid;
            this.totalToDate = invoicesSummary.total;
            this.totalToBeInvoiced = invoicesSummary.tobeinvoiced;
            this.outstandingLeads = invoicesSummary.nonrejectable;
            this.availableCredit = invoicesSummary.available_credit;
            this.amountCredit = invoicesSummary.credit_amount;
            this.amountVouchers = invoicesSummary.voucher_amount;
        },
        toggleCreditModal() {
            this.creditModal = !this.creditModal;
            this.showCancelCredit = false;
        },
        toggleVoucherModal() {
            this.voucherModal = !this.voucherModal;
            this.showCancelVoucher = false;
            this.showAddVoucher = false;
        },
        toggleCancelCredit() {
            this.showCancelCredit = !this.showCancelCredit;
        },
        toggleCancelVoucher() {
            this.showCancelVoucher = !this.showCancelVoucher;
        },
        toggleAddVoucher() {
            this.showAddVoucher = !this.showAddVoucher;
        }
    }
}
</script>

<style scoped>

</style>
