<template>
    <alerts-container v-if="alertActive" :alert-type="alertType" :text="alertText" :dark-mode="darkMode"/>

    <div class="border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="px-5 pt-5 flex items-center">
            <h5 class="text-blue-550 text-sm uppercase font-semibold leading-tight">Company Search</h5>
        </div>
        <div>
            <div class="p-5">
                <h3 class="inline-flex items-center pb-3" :class="[!darkMode ? 'text-grey-200 ' : 'text-grey-400']">
                    <svg class="fill-current mr-2" width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 4H13V6H3V4ZM0 0H16V2H0V0ZM6 8H10V10H6V8Z"/>
                    </svg>
                    Filters
                </h3>
                <div class="grid grid-cols-8 gap-3">
                    <div>
                        <select v-model="searchParams.campaignsStatus"
                            :class="{'hover:bg-grey-120 border-grey-200 text-grey-400 bg-grey-50': !darkMode, 'hover:border-blue-400 border-blue-700 text-blue-400 bg-dark-background': darkMode}"
                            class="z-30 truncate cursor-pointer uppercase text-xs font-semibold transition duration-100 w-full border flex justify-between items-center rounded py-2 px-4 h-9">
                            <option disabled value="">Campaigns Status</option>
                            <option value="">All Campaigns</option>
                            <option v-for="(label, key) in campaignsStatuses" :value="key">{{ label }}</option>
                        </select>
                    </div>
                    <div>
                        <select v-model="searchParams.state"
                                :class="{'hover:bg-grey-120 border-grey-200 text-grey-400 bg-grey-50': !darkMode, 'hover:border-blue-400 border-blue-700 text-blue-400 bg-dark-background': darkMode}"
                                class="z-30 truncate cursor-pointer uppercase text-xs font-semibold transition duration-100 w-full border flex justify-between items-center rounded py-2 px-4 h-9">
                            <option disabled value="">State</option>
                            <option value="">All States</option>
                            <option v-for="state in states" :value="state.id">{{ state.name }}</option>
                        </select>
                    </div>

                    <div class="col-span-1">
                        <select v-model="searchParams.status"
                                :class="{'hover:bg-grey-120 border-grey-200 text-grey-400 bg-grey-50': !darkMode, 'hover:border-blue-400 border-blue-700 text-blue-400 bg-dark-background': darkMode}"
                                class="z-30 truncate cursor-pointer uppercase text-xs truncate font-semibold transition duration-100 w-full border flex justify-between items-center rounded py-2 px-4 h-9">
                            <option value="">All Statuses</option>
                            <option v-for="(status, label) in statuses" :value="status.id">{{ status.name }}</option>
                        </select>
                    </div>
                    <div>
                        <select v-model="searchParams.adminUserId"
                                :class="{'hover:bg-grey-120 border-grey-200 text-grey-400 bg-grey-50': !darkMode, 'hover:border-blue-400 border-blue-700 text-blue-400 bg-dark-background': darkMode}"
                                class="z-30 truncate cursor-pointer uppercase text-xs font-semibold transition duration-100 w-full border flex justify-between items-center rounded py-2 px-4 h-9">
                            <option disabled value="">Account Manager</option>
                            <option value="0">All Account Managers</option>
                            <option value="-1">None</option>
                            <option v-for="accountManager in accountManagers" :value="accountManager.id">
                                {{ accountManager.name }}
                            </option>
                        </select>
                    </div>

                    <div>
                        <select v-model="searchParams.paymentMethod"
                                :class="{'hover:bg-grey-120 border-grey-200 text-grey-400 bg-grey-50': !darkMode, 'hover:border-blue-400 border-blue-700 text-blue-400 bg-dark-background': darkMode}"
                                class="z-30 truncate cursor-pointer uppercase text-xs font-semibold transition duration-100 w-full border flex justify-between items-center rounded py-2 px-4 h-9">
                            <option disabled value="">Payment Method</option>
                            <option value="">All Payment Methods</option>
                            <option value="">None</option>
                            <option v-for="(label, paymentMethod) in paymentMethods" :value="paymentMethod">{{ label }}</option>
                        </select>
                    </div>
                    <div>
                        <select v-model="searchParams.superPremiumOptIn"
                                :class="{'hover:bg-grey-120 border-grey-200 text-grey-400 bg-grey-50': !darkMode, 'hover:border-blue-400 border-blue-700 text-blue-400 bg-dark-background': darkMode}"
                                class="z-30 truncate cursor-pointer uppercase text-xs font-semibold transition duration-100 w-full border flex justify-between items-center rounded py-2 px-4 h-9">
                            <option disabled value="">Super Premium Opt In</option>
                            <option value="">All</option>
                            <option value="1">Opted In</option>
                            <option value="0">Not Opted In</option>
                        </select>
                    </div>
                    <div>
                        <multi-select class="w-full" v-if="adminStatuses.length > 0"
                                      :options="adminStatuses"
                                      :dark-mode="darkMode"
                                      :text-place-holder="'Admin Status'"
                                      :show-search-box="false"
                                      @input="searchParams.adminStatuses = $event"
                                      :classes="'h-9 inline-flex items-center justify-between relative border-none font-medium transition duration-200'"
                                      :selected-ids="searchParams.adminStatuses"/>
                    </div>
                    <div>
                        <multi-select class="w-full" v-if="industryServices.length > 0"
                                      :options="industryServices"
                                      :dark-mode="darkMode"
                                      :text-place-holder="'Service Type'"
                                      :show-search-box="false"
                                      @input="searchParams.serviceTypes = $event"
                                      :classes="'h-9 inline-flex items-center justify-between relative border-none font-medium transition duration-200'"
                                      :selected-ids="searchParams.serviceTypes" />
                    </div>

                    <autocomplete
                        :dark-mode="darkMode"
                        class="col-span-2"
                        v-model="tradingCompany"
                        :options="tradingCompanyNames"
                        :placeholder="'Company Name'"
                        :value="tradingCompany"
                        :create-user-input-option="true"
                        @search="searchCompanyNames('name', $event)"
                        @enter="search(1, true)" />

                    <autocomplete
                        :dark-mode="darkMode"
                        class="col-span-2"
                        v-model="entityCompany"
                        :options="entityCompanyNames"
                        :placeholder="'Entity Name'"
                        :value="entityCompany"
                        :create-user-input-option="true"
                        @search="searchCompanyNames('entity_name', $event)"
                        @enter="search(1, true)" />

                    <div class="flex flex-row justify-center col-span-2">
                        <button
                            type="button"
                            @click="search(1)"
                            class="px-5 py-2 rounded-lg text-white font-medium bg-primary-500 hover:bg-blue-500 transition duration-200 mr-3 h-9 inline-flex items-center">
                            <svg class="mr-2" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.6107 10.2141C11.2912 9.90813 10.9803 9.59339 10.6783 9.27022C10.4246 9.01243 10.2718 8.82489 10.2718 8.82489L8.36201 7.91307C9.12659 7.04602 9.54857 5.92985 9.54879 4.7739C9.54879 2.14212 7.40714 0 4.7744 0C2.14166 0 0 2.14212 0 4.7739C0 7.40568 2.14166 9.54779 4.7744 9.54779C5.97686 9.54779 7.07293 9.09768 7.9139 8.36182L8.82581 10.2714C8.82581 10.2714 9.01338 10.4241 9.2712 10.6778C9.53515 10.9254 9.88232 11.2603 10.2152 11.6101L11.1414 12.5594L11.5534 13L13 11.5535L12.5594 11.1416C12.3009 10.8879 11.9558 10.551 11.6107 10.2141V10.2141ZM4.7744 8.18382C2.89397 8.18382 1.36411 6.65413 1.36411 4.7739C1.36411 2.89366 2.89397 1.36397 4.7744 1.36397C6.65483 1.36397 8.18468 2.89366 8.18468 4.7739C8.18468 6.65413 6.65483 8.18382 4.7744 8.18382Z" fill="white"/>
                            </svg>
                            Search
                        </button>
                        <button
                            type="button"
                            @click="reset"
                            :class="[!darkMode ? 'bg-light-background hover:bg-light-background text-slate-900' : 'bg-grey-600 hover:bg-grey-500 text-slate-100']"
                            class="px-5 py-2 mr-3 rounded-lg text-white font-medium  transition duration-200 h-9 inline-flex items-center">
                            Reset
                        </button>
                        <select v-model="searchParams.perPage"
                                :class="{'hover:bg-grey-120 border-grey-200 text-grey-400 bg-grey-50': !darkMode, 'hover:border-blue-400 border-blue-700 text-blue-400 bg-dark-background': darkMode}"
                                class="z-30 cursor-pointer uppercase text-xs font-semibold transition duration-100 w-full border flex justify-between items-center rounded py-2 px-4 h-9">
                            <option value="25">25/page</option>
                            <option value="50">50/page</option>
                            <option value="100">100/page</option>
                        </select>
                    </div>
<!--                    todo map this functionality to odin-->
<!--                    <div class="inline-flex items-center justify-center col-span-1">-->
<!--                        <toggle-switch :dark-mode="darkMode" v-model="searchParams.prepaidOnly" :label="'Prepaid'" class="text-xs uppercase font-medium"/>-->
<!--                    </div>-->
<!--                        <div>-->
<!--                            <multi-select class="w-full" v-if="industries.length > 0"-->
<!--                                          :options="industries"-->
<!--                                          :dark-mode="darkMode"-->
<!--                                          :text-place-holder="'Industry'"-->
<!--                                          :show-search-box="false"-->
<!--                                          @input="searchParams.industries = $event"-->
<!--                                          :classes="'h-9 inline-flex items-center justify-between relative border-none font-medium transition duration-200'"-->
<!--                                          :selected-ids="searchParams.industries" />-->
<!--                        </div>-->
                </div>
            </div>
            <div class="mt-3">
                <div class="grid grid-cols-12 items-end mb-3 gap-x-3 px-5" :class="[!darkMode ? 'text-grey-400' : 'text-blue-400']">
                    <div class="uppercase text-xs font-medium text-center cursor-pointer rounded-lg flex items-center"
                         :class="searchParams.sortCol === 'id' ? 'text-blue-550 font-semibold' : 'hover:text-cyan-400'"
                         @click="sort('id')">
                        ID
                        <svg v-if="searchParams.sortCol === 'id'"
                             :class="{
                                'text-blue-550 rotate-180': searchParams.sortCol === 'id' && searchParams.sortDir === 'asc',
                                'text-blue-550': searchParams.sortCol === 'id' && searchParams.sortDir !== 'asc',
                                'hover:text-cyan-400': searchParams.sortCol !== 'id'
                            }"
                             class="ml-2 transform transition-all duration-200 w-6 fill-current"
                             width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70711 0.292893C10.0976 0.683418 10.0976 1.31658 9.70711 1.70711L5.70711 5.70711C5.51957 5.89464 5.26522 6 5 6C4.73478 6 4.48043 5.89464 4.29289 5.70711L0.292893 1.70711C-0.0976315 1.31658 -0.0976315 0.683417 0.292893 0.292893C0.683417 -0.0976315 1.31658 -0.0976314 1.70711 0.292893L5 3.58579L8.29289 0.292893C8.68342 -0.0976311 9.31658 -0.0976311 9.70711 0.292893Z"/>
                        </svg>
                    </div>
                    <div class="uppercase text-xs font-medium text-center cursor-pointer rounded flex items-center"
                         :class="searchParams.sortCol === 'name' ? 'text-blue-550 font-semibold' : 'hover:text-cyan-400'"
                         @click="sort('name')">
                        Company Name
                        <svg v-if="searchParams.sortCol === 'name'"
                             :class="{
                                'text-blue-550 rotate-180': searchParams.sortCol === 'name' && searchParams.sortDir === 'asc',
                                'text-blue-550': searchParams.sortCol === 'name' && searchParams.sortDir !== 'asc',
                                'hover:text-cyan-400': searchParams.sortCol !== 'name'
                            }"
                             class="ml-2 transform transition-all duration-200 w-6 fill-current"
                             width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70711 0.292893C10.0976 0.683418 10.0976 1.31658 9.70711 1.70711L5.70711 5.70711C5.51957 5.89464 5.26522 6 5 6C4.73478 6 4.48043 5.89464 4.29289 5.70711L0.292893 1.70711C-0.0976315 1.31658 -0.0976315 0.683417 0.292893 0.292893C0.683417 -0.0976315 1.31658 -0.0976314 1.70711 0.292893L5 3.58579L8.29289 0.292893C8.68342 -0.0976311 9.31658 -0.0976311 9.70711 0.292893Z"/>
                        </svg>
                    </div>
                    <div class="uppercase text-xs font-medium cursor-pointer rounded flex items-center"
                         :class="searchParams.sortCol === 'admin_status' ? 'text-blue-550 font-semibold' : 'hover:text-cyan-400'"
                         @click="sort('admin_status')">
                        Status
                        <svg v-if="searchParams.sortCol === 'admin_status'"
                             :class="{
                                'text-blue-550 rotate-180': searchParams.sortCol === 'admin_status' && searchParams.sortDir === 'asc',
                                'text-blue-550': searchParams.sortCol === 'admin_status' && searchParams.sortDir !== 'asc',
                                'hover:text-cyan-400': searchParams.sortCol !== 'admin_status'
                            }"
                             class="ml-2 transform transition-all duration-200 w-6 fill-current"
                             width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70711 0.292893C10.0976 0.683418 10.0976 1.31658 9.70711 1.70711L5.70711 5.70711C5.51957 5.89464 5.26522 6 5 6C4.73478 6 4.48043 5.89464 4.29289 5.70711L0.292893 1.70711C-0.0976315 1.31658 -0.0976315 0.683417 0.292893 0.292893C0.683417 -0.0976315 1.31658 -0.0976314 1.70711 0.292893L5 3.58579L8.29289 0.292893C8.68342 -0.0976311 9.31658 -0.0976311 9.70711 0.292893Z"/>
                        </svg>
                    </div>
                    <div class="uppercase text-xs font-medium rounded flex items-center">
                        Services
                    </div>
                    <div class="uppercase text-xs font-medium flex flex-col justify-center">
                        Addresses
                    </div>
                    <div class="uppercase text-xs font-medium flex flex-col justify-center">
                        Campaigns
                    </div>
                    <div class="uppercase text-xs font-medium rounded flex items-center">
                        Rejection %
                    </div>
                    <div class="uppercase text-xs font-medium rounded flex items-center">
                        Leads Purchased<br>Last 30 Days
                    </div>
                    <div class="uppercase text-xs font-medium rounded flex items-center">
                        Other
                    </div>
                </div>

                <template v-if="!searchLoading && companiesPaginated?.length > 0">
                    <div class="border-t border-b overflow-y-auto divide-y" :class="[darkMode ? 'bg-dark-background border-dark-border' : 'bg-light-background  border-light-border', moduleHeight]" v-if="!pageLoading">
                        <div v-for="company in companiesPaginated" class="gap-5 grid grid-cols-12 items-center py-4 rounded px-5"
                             :class="{'border-light-border hover:bg-light-module': !darkMode, 'border-dark-border hover:bg-dark-module': darkMode}">
                            <div class="">
                                <a :href="'/companies/' + company.id">{{ company.id }}</a>
                            </div>
                            <div class="">
                                <a class="text-blue-550" :href="'/companies/' + company.id">
                                    <div v-if="company.name === company.entity_name">
                                        {{ company.entity_name }}
                                    </div>
                                    <div v-else>
                                        <div class="font-medium">{{ company.name }}</div>
                                        {{ company.entity_name }}
                                    </div>
                                </a>
                            </div>
                            <div class="text-sm">
                                <span :class="company.status === 'Active' ? 'text-green-400' : 'text-orange-500'">{{ company.status }}</span>
                                <div v-if="getAdminStatusValue(company.admin_status) !== notFoundStatus" :class="getAdminStatusClass(company.admin_status)">
                                    {{ getAdminStatusValue(company.admin_status) }}
                                </div>
                            </div>
                            <div>
                                <template v-if="company.types?.length > 0">
                                    <div v-for="type in company.types" class="my-1">
                                        <div class="w-11/12 px-4 inline-flex items-center rounded-full py-1 text-sm font-medium whitespace-no-wrap"
                                             :class="[ !darkMode ? (type === 'Installation' ? 'text-blue-550 bg-cyan-100' : 'text-grey-400 bg-grey-100') : (type === 'Installation' ? 'text-blue-550 border-primary-500 bg-transparent border' : 'text-grey-400 bg-light-background bg-transparent border')]"
                                            :title="type">
                                            <span class="overflow-x-hidden overflow-ellipsis">{{ type }}</span>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <p class="text-sm">No Services Found</p>
                                </template>
                            </div>

                            <div class="">
                                <template v-if="company.addresses?.length > 0">
                                    <p class="truncate text-sm">
                                        {{ company.addresses[0].address_1 }}<br>
                                        {{ company.addresses[0].address_2 }}<br v-if="company.addresses[0].address_2">
                                        {{ company.addresses[0].city + ', ' + company.addresses[0].state + ' ' + company.addresses[0].zip_code }}
                                    </p>
                                    <a href="#" class="text-blue-500 hover:text-cyan-400 text-sm hover:underline" @click="toggleAddressModal(true, company.id)">Show All</a>
                                </template>
                                <template v-else>
                                    <p class="text-sm">No addresses found</p>
                                </template>
                            </div>
                            <div class="text-sm">
                                <b class="mb-2">Active:</b>&nbsp;{{ company.campaigns_active_count }}<br>
                                <b>Total:</b>&nbsp;{{ company.campaigns_total }}<br>
                                <a href="#" class="text-blue-500 hover:text-cyan-400 hover:underline" @click="toggleBudgetUsageModal(true, company.id)">Budget Usage</a>
                            </div>
                            <div class="text-sm">
                                {{ parseFloat(company.rejection_rate) ? `${parseFloat(company.rejection_rate).toFixed(2)}%` : "0%" }}
                            </div>
                            <div class="text-sm">
                                {{ company.total_cost_spent }}
                            </div>
                            <div class="text-sm">
                                <b class="my-2">Reviews</b>:&nbsp;{{ company.review_count || '0' }}<br>
                                <b class="my-2">Last Login</b>:<br>{{ company.last_login }}<br>
                                <b class="my-2">Revised</b>:<br>{{ company.last_revised }}
                            </div>
                        </div>
                    </div>
                    <div v-else-if="pageLoading" class="flex flex-col h-64 relative">
                        <loading-spinner :use-fa="true" class="text-4xl" />
                    </div>

                    <div class="p-3">
                        <Pagination :dark-mode="darkMode" :pagination-data="paginationData" :show-pagination="true" @change-page="handlePaginationEvent"></Pagination>
                    </div>

                </template>

                <template v-else-if="!searchLoading && !companiesPaginated?.data?.length">
                    <div class="border-t border-b overflow-y-auto divide-y" :class="[darkMode ? 'bg-dark-background border-dark-border' : 'bg-light-background  border-light-border', moduleHeight]" v-if="!pageLoading">
                        <p class="w-full h-full flex justify-center items-center" :class="[!darkMode ? 'text-grey-400' : 'text-blue-400']">No companies found</p>
                    </div>
                    <div class="p-3">
                    </div>
                </template>
                <template v-else-if="searchLoading">
                    <div class="border-t border-b h-64 overflow-y-auto divide-y" :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}" v-if="!pageLoading">
                        <div class="flex flex-col relative h-full items-center">
                            <loading-spinner :use-fa="true" class="text-4xl" />
                        </div>
                    </div>
                    <div class="p-3">
                    </div>

                </template>
            </div>
        </div>
    </div>

    <modal :container-classes="'p-0'" v-if="showBudgetUsageModal" :no-buttons="true" :dark-mode="darkMode" @close="toggleBudgetUsageModal(false)">
        <template v-slot:header>
            <p class="text-lg font-medium">Budget Usage for {{ selectedCompanyName }} ({{ selectedCompanyId }})</p>
        </template>
        <template v-slot:content>
            <budget-usage-table :dark-mode="darkMode" :company-id="selectedCompanyId"/>
        </template>
    </modal>

    <modal :container-classes="'p-0'" v-if="showAddressModal" :no-buttons="true" :dark-mode="darkMode" @close="toggleAddressModal(false)">
        <template v-slot:header>
            <p class="text-lg font-medium">Addresses for {{ selectedCompanyName }} ({{ selectedCompanyId }})</p>
        </template>
        <template v-slot:content>
            <company-locations-table :dark-mode="darkMode" :company-id="selectedCompanyId"/>
        </template>
    </modal>
</template>

<script>
    import Pagination from "../components/Pagination";
    import MultiSelect from "../components/MultiSelect";
    import Autocomplete from "../components/Autocomplete";
    import LoadingSpinner from "../components/LoadingSpinner";
    import ToggleSwitch from "../components/ToggleSwitch";
    import Tooltip from "../components/Tooltip";
    import AlertsContainer from "../components/AlertsContainer";
    import SharedApiService from "../services/api";
    import BudgetUsageTable from "./BudgetUsageTable";
    import Modal from "../components/Modal";
    import Dropdown from "../components/Dropdown";
    import CompanyLocationsTable from "./CompanyLocationsTable";

    export default {
        name: "CompaniesSearch",
        components: {
            CompanyLocationsTable,
            Dropdown,
            BudgetUsageTable,
            AlertsContainer,
            LoadingSpinner,
            Autocomplete,
            MultiSelect,
            Pagination,
            ToggleSwitch,
            Tooltip,
            Modal
        },
        props: {
            darkMode: {
                type: Boolean,
                default: false
            },
            restrictAccountManager: {
                type: Boolean,
                default: false
            },
            moduleHeight: {
                type: String,
                default: 'h-88 lg:h-100 xl:h-120 2xl:h-[42rem]',
            }
        },
        data: function() {
            return {
                api: null,

                searchLoading: false,
                pageLoading: false,

                searchParams: {},
                companiesPaginated: null,
                paginationData: null,

                campaignsStatuses: {},
                states: [],
                statuses: [],
                accountManagers: [],
                adminStatuses: [],
                industryServices: [],
                tradingCompany: null,
                entityCompany: null,

                tradingCompanyNames: [],
                entityCompanyNames: [],

                alertType: '',
                alertActive: false,
                alertText: '',

                selectedCompanyId: 0,
                selectedCompanyName: null,

                showBudgetUsageModal: false,
                showAddressModal: false,

                adminStatusMappedList: {
                    PART_SUSPENDED: {key: 1, value: 'Part Suspended'},
                    ALL_SUSPENDED: {key: 2, value: 'All Suspended'},
                    COLLECTIONS: {key: 3, value: 'Collections'},
                    ARCHIVED: {key: 4, value: 'Archived'},
                    ADMIN_LOCKED: {key: 5, value: 'Locked'},
                },

                notFoundStatus: 'Unknown'
            };
        },
        created: function() {
            this.api = SharedApiService.make();

            this.api.getIndustryServices().then((res) => {
                if(res.data.data.status === true) {
                    this.industryServices =  res.data.data.industry_services;
                }
            });
            this.api.getStates().then((res) => {
                Object.entries(res.data.data).forEach(([key, label]) => {
                    this.states.push({
                        id: label.stateAbbr,
                        name: label.name
                    })
                });
            });
            this.api.getCompanyAdminStatuses().then((res) => {
                Object.entries(res.data.data.company_admin_statuses).forEach(([key, label]) => {
                    this.adminStatuses.push({
                        id: key,
                        name: label
                    })
                });
            })
            this.api.getCompanyStatuses().then((res) => {
                Object.entries(res.data.data.company_statuses).forEach(([key, label]) => {
                    this.statuses.push({
                        id: key,
                        name: label
                    })
                });
            });
            this.api.getAccountManagers().then((res) => {
                if(res.data.data.status === true) {
                    this.accountManagers = res.data.data.account_managers;
                }
            })
            this.api.getPaymentMethods()
            this.paymentMethods = ['pg'];
            this.api.getCampaignStatuses().then((res) => {
                if(res.data.data.status === true) {
                    this.campaignsStatuses = res.data.data.campaign_statuses;
                }
            })

            this.reset();
        },
        computed: {

        },
        methods: {
            search(page = 1, newSearch = true) {
                if(newSearch) {
                    this.searchLoading = true;
                    this.tradingCompanyNames = [];
                    this.entityCompanyNames = [];
                }
                else {
                    this.pageLoading = true;
                }

                this.searchParams.companyName = this.tradingCompanyNames.find((tradingCompanyName) => {
                     return tradingCompanyName.id === this.tradingCompany;
                })?.companyName ?? this.tradingCompany ?? '';
                this.searchParams.entityName = this.entityCompanyNames.find((entityCompanyName) => {
                    return entityCompanyName.id === this.entityCompany;
                })?.companyName ?? this.entityCompany ?? '';

                this.searchParams.restrictAccountManager = Number(this.restrictAccountManager);
                this.searchParams.prepaidOnly = Number(this.searchParams.prepaidOnly);

                this.api.searchCompanies(page, this.searchParams).then((res) => {
                    if(res.data.data.status === true) {
                        let {data, ...paginationData} = res.data.data.companies_paginated;
                        this.companiesPaginated = data;
                        this.paginationData = paginationData;
                    }
                }).catch((err) => {
                    this.alertType = 'error';
                    this.alertText = err.data.message || "Error retrieving companies";
                    this.alertActive = true;

                    setTimeout(() => {
                        this.alertActive = false;
                    }, 5000);
                }).finally(() => {
                    this.searchLoading = false;
                    this.pageLoading = false;
                });
            },
            reset() {
                this.searchParams = {
                    companyName: '',
                    entityName: '',
                    status: '',
                    serviceTypes: [],
                    adminStatuses: [],
                    adminUserId: 0,
                    salesUserId: 0,
                    state: '',
                    campaignsStatus: '',
                    paymentMethod: '',
                    superPremiumOptIn: '',
                    prepaidOnly: 0,
                    sortCol: 'id',
                    sortDir: 'asc',
                    perPage: 25
                };

                this.tradingCompany = null;
                this.entityCompany = null;
                this.tradingCompanyNames = [];
                this.entityCompanyNames = [];
            },
            sort(column = 'id') {
                if(this.searchParams.sortCol === column) {
                    this.searchParams.sortDir = this.searchParams.sortDir === 'asc' ? 'desc' : 'asc';
                }

                this.searchParams.sortCol = column;

                this.search();
            },
            searchCompanyNames(nameType, query) {
                this.api.searchCompanyNames(nameType, query).then(
                    (res) => {
                        if(res.data.data.status === true) {
                            if (this.searchLoading) return;
                            if(nameType === 'entity_name') {
                                this.entityCompanyNames = res.data.data.companies;
                            }
                            else if(nameType === 'name') {
                                this.tradingCompanyNames = res.data.data.companies;
                            }
                        }
                    },
                    () => {
                        this.alertType = 'error';
                        this.alertText = "Error searching company names";
                        this.alertActive = true;

                        setTimeout(() => {
                            this.alertActive = false;
                        }, 5000);
                    }
                )
            },
            toggleBudgetUsageModal(show, companyId) {
                this.showBudgetUsageModal = !!show;

                if(show) {
                    this.selectedCompanyId = companyId;
                    this.selectedCompanyName = this.companiesPaginated.find((company) => {
                        return company.id === companyId;
                    }).name;
                }
                else {
                    this.selectedCompanyId = 0;
                    this.selectedCompanyName = '';
                }
            },
            toggleAddressModal(show, companyId) {
                this.showAddressModal = !!show;

                if(show) {
                    this.selectedCompanyId = companyId;
                    this.selectedCompanyName = this.companiesPaginated.find((company) => {
                        return company.id === companyId;
                    }).name;
                }
                else {
                    this.selectedCompanyId = 0;
                    this.selectedCompanyName = '';
                }
            },
            async handlePaginationEvent(newPageUrl) {
                const page = (new URL(newPageUrl.link)).searchParams.get('page')
                this.loading = true;
                this.search(page, false)
            },
            getAdminStatusValue(status) {
                switch (status) {
                    case this.adminStatusMappedList.PART_SUSPENDED.key:
                        return this.adminStatusMappedList.PART_SUSPENDED.value;
                    case this.adminStatusMappedList.ALL_SUSPENDED.key:
                        return this.adminStatusMappedList.ALL_SUSPENDED.value;
                    case this.adminStatusMappedList.COLLECTIONS.key:
                        return this.adminStatusMappedList.COLLECTIONS.value;
                    case this.adminStatusMappedList.ARCHIVED.key:
                        return this.adminStatusMappedList.ARCHIVED.value;
                    case this.adminStatusMappedList.ADMIN_LOCKED.key:
                        return this.adminStatusMappedList.ADMIN_LOCKED.value;
                    default:
                        return this.notFoundStatus;
                }
            },
            getAdminStatusClass(status) {
                switch (status) {
                    case this.adminStatusMappedList.PART_SUSPENDED.key:
                        return "text-orange-400";
                    case this.adminStatusMappedList.ALL_SUSPENDED.key:
                        return "text-red-500";
                    case this.adminStatusMappedList.COLLECTIONS.key:
                        return "text-green-400";
                    case this.adminStatusMappedList.ARCHIVED.key:
                        return "text-red-300";
                    case this.adminStatusMappedList.ADMIN_LOCKED.key:
                        return "text-red-400";
                    default:
                        return "text-orange-500";
                }
            }
        }
    }
</script>
