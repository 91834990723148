import axios from 'axios';
import {logicalOptions, operatorOptions} from "../../../../modules/options";
import {isNull, toInteger} from "lodash";

const VERSION = 1
const BASE_URL = 'internal-api'
const BASE_ENDPOINT =
    {
        DEFAULT: '',
        LEAD_PROCESSING: 'lead-processing',
        SALES_BAIT_MANAGEMENT: 'sales-bait-management',
        COMPANIES: 'companies',
        NON_PURCHASING_SERVICE_AREA: 'non-purchasing-service-areas',
        ROLE_PERMISSION_MANAGEMENT: 'roles-permissions-management',
        LEADS: 'leads',
        SEARCH: 'search',
        EDITORS: 'editors',
        WYSIWYG: 'wysiwyg',
        USERS: 'users',
        EXTERNAL_AUTH: 'external-auth'
    }

export default class SharedApiService {

    constructor(baseUrl, baseEndpoint, version) {
        this.baseUrl = baseUrl;
        this.baseEndpoint = baseEndpoint;
        this.version = version;
    }

    axios() {
        const axiosConfig = {
            baseURL: `/${this.baseUrl}/v${this.version}/${this.baseEndpoint}`
        }

        return axios.create(axiosConfig);
    }

    static make() {
        return new SharedApiService(BASE_URL, BASE_ENDPOINT.DEFAULT, VERSION);
    }

    static getBaseUrl() {
        return BASE_URL;
    }

    static getVersion() {
        return VERSION;
    }

    static getBaseEndpoints() {
        return BASE_ENDPOINT;
    }

    getIndustries() {
        this.baseEndpoint = BASE_ENDPOINT.DEFAULT;
        return this.axios().get('/reference/industries');
    }

    getStates() {
        this.baseEndpoint = BASE_ENDPOINT.DEFAULT;
        return this.axios().get(`/reference/states`);
    }

    getCounties(stateKey) {
        this.baseEndpoint = BASE_ENDPOINT.DEFAULT;
        return this.axios().get(`/reference/states/${stateKey}/counties`);
    }

    getIndustryServices() {
        this.baseEndpoint = BASE_ENDPOINT.DEFAULT;
        return this.axios().get('/reference/industry-services');
    }

    getGlobalTypes() {
        this.baseEndpoint = BASE_ENDPOINT.DEFAULT;
        return this.axios().get('/reference/global-types');
    }

    getStatistics(queryString = '') {
        this.baseEndpoint = BASE_ENDPOINT.SALES_BAIT_MANAGEMENT;
        return this.axios().get(`/customstatistics${queryString}`);
    }


    /**
     * Get company contacts by companyId.
     * @param {number} companyId
     * @return Promise<AxiosResponse>
     * @throws Error
     */
    getCompanyContacts(companyId) {
        if (typeof companyId != "number") {
            throw new TypeError(`"companyId" must be a number. ${companyId} passed.`);
        }

        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;
        return this.axios().get(`/${companyId}/contacts`).catch(e => {
            throw `Could not get contacts from ${companyId}. Error: ${e.message}`;
        });
    }

    createCompanyContact(companyId, payload) {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;
        return this.axios().post(`/${companyId}/contacts`, payload);
    }

    getCompanyActions(companyId) {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;
        return this.axios().get(`/${companyId}/actions`);
    }

    toggleActionPin(actionId, companyId) {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;
        return this.axios().patch(`${companyId}/actions/pin/${actionId}`);
    }

    getCompanyInvoicesSummary(companyId) {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;
        return this.axios().get(`/${companyId}/invoices-summary`)
    }

    getCompanyInvoices(companyId) {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;
        return this.axios().get(`/${companyId}/invoices`)
    }

    getCompanyLeads(companyId, params = {}) {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;
        return this.axios().get(`/${companyId}/leads`, {
            params: params
        });
    }

    getCompanyOverview(companyId) {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;
        return this.axios().get(`/${companyId}/overview`);
    }

    searchCompanies(page = 1, params = {}) {
        params.page = page;

        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;

        return this.axios().get('/search', {
            params: params
        })
    }

    getNonPurchasingCompanies(industry, zipCode, taskId = null, hideContactedCompanies = false) {
        this.baseEndpoint = BASE_ENDPOINT.NON_PURCHASING_SERVICE_AREA;

        let params = {hide_contacted_companies: hideContactedCompanies};

        if (taskId) params.task_id = taskId;

        return this.axios().get(`/${industry}/${zipCode}/companies`, {
            params: params
        });
    }

    nonPurchasingCompaniesFilteredByAmountOfLeadsPurchased = {
        /**
         * Builds params for getNonPurchasingCompaniesFilteredByAmountOfLeadsPurchased.
         * @param {string} industry
         * @param {string} zipCode
         * @param {number} taskId
         * @param {boolean} hideContactedCompanies
         * @param {number} filterByLeadPurchasedAmountTimestamp
         * @param {number} filterByLeadPurchasedAmountFirstValue
         * @param {?number} filterByLeadPurchasedAmountSecondValue
         * @param {OperatorJSDocType} filterByLeadPurchasedAmountFirstOperator
         * @param {?OperatorJSDocType} filterByLeadPurchasedAmountSecondOperator
         * @param {?"and"|"or"} filterByLeadPurchasedAmountLogical
         * @return {{filterByLeadPurchasedAmountFirstValue: number, hide_contacted_companies: boolean, filterByLeadPurchasedAmountFirstOperator: "greaterThan"|"lessThan"|"greaterThanOrEqualTo"|"lessThanOrEqualTo"|"equalTo", filterByLeadPurchasedAmountActive: boolean}}
         */
        buildParams: (industry, zipCode, taskId, hideContactedCompanies, filterByLeadPurchasedAmountTimestamp, filterByLeadPurchasedAmountFirstValue, filterByLeadPurchasedAmountFirstOperator, filterByLeadPurchasedAmountLogical = null, filterByLeadPurchasedAmountSecondValue = null, filterByLeadPurchasedAmountSecondOperator = null) => {
            if (typeof industry != 'string') {
                throw `"industry" must be a string. ${industry} passed.`
            }

            if (typeof zipCode != 'string') {
                throw `"zipCode" must be a string. ${zipCode} passed.`
            }

            if (typeof hideContactedCompanies != 'boolean')
                throw `"hideContactedCompanies" must be a boolean. ${hideContactedCompanies} passed.`

            if (typeof filterByLeadPurchasedAmountTimestamp != 'number') {

                if(isNaN(filterByLeadPurchasedAmountTimestamp))
                    throw `"filterByLeadPurchasedAmountTimestamp" must be a number or numeric. ${filterByLeadPurchasedAmountTimestamp} passed.`

                filterByLeadPurchasedAmountTimestamp = toInteger(filterByLeadPurchasedAmountTimestamp);
            }

            if (typeof filterByLeadPurchasedAmountFirstValue != 'number') {

                if(isNaN(filterByLeadPurchasedAmountFirstValue))
                    throw `"filterByLeadPurchasedAmountFirstValue" must be a number or numeric. ${filterByLeadPurchasedAmountFirstValue} passed.`

                filterByLeadPurchasedAmountFirstValue = toInteger(filterByLeadPurchasedAmountFirstValue);
            }

            if (typeof filterByLeadPurchasedAmountSecondValue != 'number' && !isNull(filterByLeadPurchasedAmountSecondValue)) {
                if(isNaN(filterByLeadPurchasedAmountSecondValue))
                    throw `"filterByLeadPurchasedAmountSecondValue" must be a number or numeric. ${filterByLeadPurchasedAmountSecondValue} passed.`

                filterByLeadPurchasedAmountSecondValue = toInteger(filterByLeadPurchasedAmountSecondValue)
            }

            if (!operatorOptions.includes(filterByLeadPurchasedAmountFirstOperator)) {
                throw `"filterByLeadPurchasedAmountFirstOperator" must be one of the following: ${JSON.stringify(operatorOptions)}. ${filterByLeadPurchasedAmountFirstOperator} passed.`
            }

            if (!operatorOptions.includes(filterByLeadPurchasedAmountSecondOperator) && !isNull(filterByLeadPurchasedAmountSecondOperator)) {
                throw `"filterByLeadPurchasedAmountSecondOperator" must be one of the following: ${JSON.stringify(operatorOptions)}. ${filterByLeadPurchasedAmountSecondOperator} passed.`
            }

            if (!logicalOptions.includes(filterByLeadPurchasedAmountLogical) && !isNull(filterByLeadPurchasedAmountLogical)) {
                throw `"filterByLeadPurchasedAmountLogical" must be one of the following: ${JSON.stringify(logicalOptions)}. ${filterByLeadPurchasedAmountLogical} passed.`
            }

            let params = {
                filterByLeadPurchasedAmountActive: true,
                filterByLeadPurchasedAmountFirstValue,
                filterByLeadPurchasedAmountFirstOperator,
                filterByLeadPurchasedAmountTimestamp,
                hide_contacted_companies: hideContactedCompanies
            };

            if (filterByLeadPurchasedAmountLogical) {
                params.filterByLeadPurchasedAmountLogical = filterByLeadPurchasedAmountLogical;

                if (filterByLeadPurchasedAmountSecondValue) {
                    params.filterByLeadPurchasedAmountSecondValue = filterByLeadPurchasedAmountSecondValue;
                }

                if (filterByLeadPurchasedAmountSecondOperator) {
                    params.filterByLeadPurchasedAmountSecondOperator = filterByLeadPurchasedAmountSecondOperator;
                }
            }

            if (taskId) params.task_id = taskId

            return params;
        },
        /**
         * Get non-purchasing companies and filter by amount of leads purchased.
         * @param {string} industry
         * @param {string} zipCode
         * @param {number} taskId
         * @param {boolean} hideContactedCompanies
         * @param {number} filterByLeadPurchasedAmountTimestamp
         * @param {number} filterByLeadPurchasedAmountFirstValue
         * @param {?number} filterByLeadPurchasedAmountSecondValue
         * @param {OperatorJSDocType} filterByLeadPurchasedAmountFirstOperator
         * @param {?OperatorJSDocType} filterByLeadPurchasedAmountSecondOperator
         * @param {?"and"|"or"} filterByLeadPurchasedAmountLogical
         * @return Promise<AxiosResponse>
         */
        get: (industry, zipCode, taskId, hideContactedCompanies, filterByLeadPurchasedAmountTimestamp, filterByLeadPurchasedAmountFirstValue, filterByLeadPurchasedAmountFirstOperator, filterByLeadPurchasedAmountLogical = null, filterByLeadPurchasedAmountSecondValue = null, filterByLeadPurchasedAmountSecondOperator = null) => {
            this.baseEndpoint = BASE_ENDPOINT.NON_PURCHASING_SERVICE_AREA;

            try {
                const params = this.nonPurchasingCompaniesFilteredByAmountOfLeadsPurchased.buildParams(industry, zipCode, taskId, hideContactedCompanies, filterByLeadPurchasedAmountTimestamp, filterByLeadPurchasedAmountFirstValue, filterByLeadPurchasedAmountFirstOperator, filterByLeadPurchasedAmountLogical, filterByLeadPurchasedAmountSecondValue, filterByLeadPurchasedAmountSecondOperator);

                return this.axios().get(`/${industry}/${zipCode}/companies`, {
                    params: params
                });
            } catch (e) {
                console.error(e);
            }
        }
    }

    initCompaniesSearchOptions() {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;
        return this.axios().get('/search/init');
    }

    searchCompanyNames(nameType, query) {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;

        return this.axios().get('/search/company-names', {
            params: {
                nameType: nameType,
                query: query
            }
        });
    }

    searchCompanyNamesAndId(query) {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;

        return this.axios().get('/search/company-names-id', {
            params: {
                query: query
            }
        });
    }

    searchUserByNamesAndId(query, filterBasicInfo = true) {
        this.baseEndpoint = BASE_ENDPOINT.USERS;

        return this.axios().get('/search', {
            params: {
                query: query,
                filter_basic_info: filterBasicInfo ? 1 : 0
            }
        });
    }

    getCompanyBudgetUsage(companyId, page = 1) {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;

        return this.axios().get(`/${companyId}/campaigns/budget-usage?page=${page}`)
    }

    getPaginatedCompanyUsers(companyId, page = 1) {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;

        return this.axios().get(`/${companyId}/users/paginated?page=${page}`);
    }

    getCompanyUsers(companyId) {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;

        return this.axios().get(`/${companyId}/users`);
    }

    updateCompanyContact(companyId, payload) {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;
        return this.axios().patch(`/${companyId}/contacts/${payload.id}`, payload);
    }

    updateCompanyUser(companyId, userId, payload) {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;

        return this.axios().patch(`/${companyId}/users/${userId}`, payload);
    }

    deleteCompanyUser(companyId, userId) {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;

        return this.axios().delete(`/${companyId}/users/${userId}`);
    }

    getPermissions() {
        this.baseEndpoint = BASE_ENDPOINT.ROLE_PERMISSION_MANAGEMENT;
        return this.axios().get('/permissions');
    }

    createPermission(permission) {
        this.baseEndpoint = BASE_ENDPOINT.ROLE_PERMISSION_MANAGEMENT;
        return this.axios().post('/permissions', {
            name: permission.name
        });
    }

    updatePermission(permission) {
        this.baseEndpoint = BASE_ENDPOINT.ROLE_PERMISSION_MANAGEMENT;
        return this.axios().patch(`/permissions/${permission.id}`, {
            name: permission.name
        });
    }

    getRoles() {
        this.baseEndpoint = BASE_ENDPOINT.ROLE_PERMISSION_MANAGEMENT;
        return this.axios().get('/roles');
    }

    search(query) {
        this.baseEndpoint = BASE_ENDPOINT.SEARCH;
        return this.axios().get(`/${query}`);
    }

    createRole(role) {
        this.baseEndpoint = BASE_ENDPOINT.ROLE_PERMISSION_MANAGEMENT;
        return this.axios().post('/roles', {
            name: role.name,
            permissions: role.permissions
        });
    }

    updateRole(role) {
        this.baseEndpoint = BASE_ENDPOINT.ROLE_PERMISSION_MANAGEMENT;
        return this.axios().patch(`/roles/${role.id}`, {
            name: role.name,
            permissions: role.permissions
        });

    }

    getCompaniesSoldTo(leadId) {
        this.baseEndpoint = BASE_ENDPOINT.LEADS;
        return this.axios().get(`/${leadId}/companies-sold-to`);
    }

    getUnsoldLeadsInCounty(zipCode, industry, days) {
        this.baseEndpoint = BASE_ENDPOINT.LEADS;
        return this.axios().get('/unsold-leads-in-county', {
            params: {
                zip_code: zipCode,
                industry: industry,
                days: days
            }
        });
    }

    getUndersoldLeadsInCounty(zipCode, industry, days) {
        this.baseEndpoint = BASE_ENDPOINT.LEADS;
        return this.axios().get('/undersold-leads-in-county', {
            params: {
                zip_code: zipCode,
                industry: industry,
                days: days
            }
        });
    }

    getLeadStatuses() {
        this.baseEndpoint = BASE_ENDPOINT.LEADS;
        return this.axios().get('/statuses')
    }

    getLeadCategories() {
        this.baseEndpoint = BASE_ENDPOINT.LEADS;
        return this.axios().get('/categories')
    }

    getUsers(filterBasicInfo = true) {
        this.baseEndpoint = BASE_ENDPOINT.DEFAULT;
        return this.axios().get('/users', {
            params: {
                filter_basic_info: !!filterBasicInfo
            }
        });
    }

    getActionCategories() {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;
        return this.axios().get(`/action-categories`);
    }

    updateCompanyAddress(companyId, addressId, payload) {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;
        return this.axios().patch(`/${companyId}/addresses/${addressId}`, payload);
    }

    updateCompanyBasicInfo(companyId, payload) {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;

        return this.axios().patch(`/${companyId}/basic-info`, payload);
    }

    getProfile(companyId) {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;

        return this.axios().get(`/${companyId}/profile`);
    }

    getCompanyLocations(companyId) {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;
        return this.axios().get(`/${companyId}/locations`);
    }

    updateCompanyLocation(companyId, locationId, payload) {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;
        return this.axios().patch(`/${companyId}/locations/${locationId}`, payload);
    }

    createCompanyLocation(companyId, payload) {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;
        return this.axios().post(`/${companyId}/locations/`, payload);
    }

    deleteCompanyLocation(companyId, locationId) {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;
        return this.axios().delete(`/${companyId}/locations/${locationId}`);
    }

    wysiwygEditorUploadFile(payload) {
        this.baseEndpoint = `${BASE_ENDPOINT.EDITORS}/${BASE_ENDPOINT.WYSIWYG}`;
        return this.axios().post('/upload-file', payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    getWebsites() {
        this.baseEndpoint = BASE_ENDPOINT.DEFAULT;

        return this.axios().get('/reference/websites');
    }

    getAdminCompanies(query) {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;
        return this.axios().get('/search/admin-company', {
            params: {
                companyName: query
            }
        });
    }

    getCompanyAdminStatuses() {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;
        return this.axios().get(`/company-admin-statuses`);
    }

    getCompanyStatuses() {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;
        return this.axios().get(`/company-statuses`);
    }

    getAccountManagers() {
        this.baseEndpoint = BASE_ENDPOINT.DEFAULT;
        return this.axios().get('/sales/info/account-managers');
    }

    getCampaignStatuses() {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;
        return this.axios().get('/search/campaign-statuses');
    }

    getPaymentMethods() {
        this.baseEndpoint = BASE_ENDPOINT.COMPANIES;
        return this.axios().get('/search/payment-methods');
    }

    getAuthToken() {
        this.baseEndpoint = BASE_ENDPOINT.EXTERNAL_AUTH;

        return this.axios().get('/get-token');
    }
}
