<template>
    <div class="relative">
        <input class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
               @blur="onBlur"
               @input="onInput"
               @keydown="onKeyDown"
               @keyup="onKeyUp"
               :placeholder="placeholder"
               type="text"
               ref="input"
               v-model="input"
               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>

        <div class="absolute z-50 -top-1.5" v-if="showMenu">
            <span
                :class="{'bg-light-module border-gray-300': !darkMode, 'bg-dark-background border-blue-700': darkMode}"
                class="absolute top-0 left-0 w-2 h-2 transform rotate-45 -mt-1 ml-3 border-r border-b z-20"></span>
            <div
                :class="{'border-blue-700 bg-dark-background text-white': darkMode, 'bg-light-module border-gray-300 text-gray-800': !darkMode}"
                class="overflow-auto rounded-lg shadow-md z-10 py-2 border text-xs absolute bottom-full">
                <ul class="list-reset">
                    <li v-if="displayedItems.length === 0">
                        <span class="block px-4 py-1 text-gray-500 text-nowrap whitespace-nowrap">No results</span>
                    </li>
                    <li v-else v-for="(code, index) in displayedItems"
                        class="px-4 py-1 flex no-underline hover:no-underline transition-colors duration-100 text-nowrap whitespace-nowrap"
                        :class="selectedIndex == index ? 'bg-primary-500 text-white' : 'hover:bg-gray-100'"
                        @click.prevent="applyShortcode(index)"
                    >
                        <span class="font-bold">{{ code.label }}</span>
                        <span class="ml-2">{{ openTag + code.value + closeTag }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import {nextTick} from 'vue';
import ApiService from "../../TaskManagement/services/api";

export default {
    name: "ShortcodeInput",
    emits: ['update:modelValue'],
    props: {
        openTag: {
            type: String,
            default: "{"
        },
        closeTag: {
            type: String,
            default: "}"
        },
        darkMode: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: "Start typing..."
        },
        limit: {
            type: Number,
            default: 5
        },
        modelValue: {
            type: String,
            default: ''
        }
    },
    data: function () {
        return {
            api: ApiService.make(),
            codes: [],
            selectedIndex: 0,
            showMenu: false,
            key: null,
            input: this.modelValue,
            lastSearchText: null,
            searchText: null,
            keyIndex: null,
            cancelKeyUp: null
        }
    },
    watch: {
        input() {
            this.$emit('update:modelValue', this.input);
        },
        modelValue() {
            this.input = this.modelValue;
        }
    },
    mounted() {
        this.getCodes();
    },
    computed: {
        displayedItems() {
            return this.filteredItems.slice(0, this.limit);
        },
        filteredItems() {
            if(!this.searchText)
                return this.codes;

            const searchText = this.searchText.toLowerCase();
            return this.codes.filter(item => item.value.toLowerCase().includes(searchText));
        }
    },
    methods: {
        getCodes() {
            this.api.getWorkflowShortcodes().then(resp => {
                this.codes = resp.data.data;
            });
        },
        onInput() {
            this.checkKey();
        },
        onKeyDown(e) {
            if (this.key) {
                if ([this.openTag].includes(e.key))
                    return this.cancelEvent(e);

                if (e.key === 'ArrowDown') {
                    this.selectedIndex++;
                    if (this.selectedIndex >= this.displayedItems.length)
                        this.selectedIndex = 0;

                    this.cancelEvent(e);
                } else if (e.key === 'ArrowUp') {
                    this.selectedIndex--;
                    if (this.selectedIndex < 0)
                        this.selectedIndex = this.displayedItems.length - 1;

                    this.cancelEvent(e);
                } else if (e.key === 'Enter' || e.key === 'Tab' && this.displayedItems.length > 0) {
                    this.applyShortcode(this.selectedIndex);
                    this.cancelEvent(e);
                } else if (e.key === 'Escape') {
                    this.closeMenu();
                    this.cancelEvent(e);
                } else {
                    this.updateDisplayedItems();
                }
            }
        },
        onKeyUp(e) {
            if (this.cancelKeyUp && e.key === this.cancelKeyUp)
                this.cancelEvent(e);

            this.cancelKeyUp = null;
        },
        onBlur() {
            this.closeMenu();
        },
        cancelEvent(e) {
            e.preventDefault();
            e.stopPropagation();
            this.cancelKeyUp = e.key;
        },
        applyShortcode(index) {
            const item = this.displayedItems[index];
            const value = (this.key || '') + String(item.value) + this.closeTag + " ";
            this.input = this.replaceText(this.input, this.searchText, value, this.keyIndex);
            this.setCaretPosition(this.keyIndex + value.length);
            this.closeMenu();
        },
        replaceText(text, searchText, newText, index) {
            return text.slice(0, index) + newText + text.slice(index + searchText.length + 1, text.length)
        },
        setCaretPosition(index) {
            nextTick(() => this.$refs.input.selectionEnd = index);
        },
        checkKey() {
            const index = this.selectionStart();

            if (index >= 0) {
                const {key, keyIndex} = this.getLastKeyBeforeCaret(index);
                const searchText = this.lastSearchText = this.getLastSearchText(index, keyIndex);
                if (!(keyIndex < 1 || /\s/.test(this.input[keyIndex - 1])))
                    return false;

                if (searchText != null) {
                    this.openMenu(key, keyIndex);
                    this.searchText = searchText;
                    return true;
                }
            }

            this.closeMenu();
            return false;
        },
        openMenu(key, keyIndex) {
            if (this.key !== key && !this.showMenu) {
                this.key = key;
                this.keyIndex = keyIndex;
                this.selectedIndex = 0;
                this.showMenu = true;
            }
        },
        closeMenu() {
            if (this.key !== null) {
                this.key = null;
                this.showMenu = false;
            }
        },
        getLastKeyBeforeCaret(caretIndex) {
            const [keyData] = [this.openTag].map(key =>
                ({
                    key,
                    keyIndex: this.input.lastIndexOf(this.openTag, caretIndex - 1),
                })).sort((a, b) => b.keyIndex - a.keyIndex)

            return keyData;
        },
        getLastSearchText(caretIndex, keyIndex) {
            if (keyIndex !== -1) {
                const searchText = this.input.substring(keyIndex + 1, caretIndex);
                if (!/\s/.test(searchText))
                    return searchText;
            }

            return null;
        },
        updateDisplayedItems() {
            this.selectedIndex = 0;
        },
        selectionStart() {
            return this.$refs.input.selectionStart;
        }
    },
}
</script>
