<template>
    <div>
        <alerts-container :dark-mode="darkMode" v-if="alertActive" :alert-type="alertType" :text="alertText" />

        <div class="grid grid-cols-4 gap-5 mb-4 px-8 pt-5">
            <div class="text-base text-xs font-medium text-grey-400 uppercase">ID</div>
            <div class="text-base text-xs font-medium text-grey-400 uppercase">Name</div>
            <div class="text-base text-xs font-medium text-grey-400 uppercase">Phone</div>
            <div class="text-base text-xs font-medium text-grey-400 uppercase">Address</div>
        </div>
        <template v-if="!loading && companyLocations.length > 0">
            <div class="border-t border-b divide-y" :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
                <div v-for="location in companyLocations" class="grid grid-cols-4 gap-5 items-center py-2 rounded px-8"
                     :class="{'border-light-border hover:bg-light-module': !darkMode, 'border-dark-border hover:bg-dark-module': darkMode}">
                    <div class="truncate">{{ location.id }}</div>
                    <div class="truncate">{{ location.name }}</div>
                    <div class="truncate">{{ location.phone }}</div>
                    <div class="truncate">
                        {{ location.address.address_1 }}<br>
                        {{ location.address.address_2 }}<br v-if="location.address.address_2">
                        {{ location.address.city + ', ' + location.address.state + ' ' + location.address.zip_code }}
                    </div>
                </div>
            </div>
        </template>
        <template v-else-if="!loading">
            <div class="text-center w-full my-10">
                No data
            </div>
        </template>
        <template v-else-if="loading">
            <div class="text-center w-full my-10">
                <loading-spinner/>
            </div>
        </template>
    </div>
</template>

<script>
import AlertsContainer from "../components/AlertsContainer";
import LoadingSpinner from "../components/LoadingSpinner";
import Pagination from "../components/Pagination";
import SharedApiService from "../services/api";

export default {
    name: "CompanyLocationsTable",
    components: {
        AlertsContainer,
        LoadingSpinner,
        Pagination
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        companyId: {
            type: Number,
            default: 0,
            required: true
        }
    },
    created: function() {
        this.api = SharedApiService.make();
        this.getCompanyLocations();
    },
    data: function() {
        return {
            api: null,
            companyLocations: null,
            loading: false,
            alertType: '',
            alertActive: false,
            alertText: '',
        };
    },
    methods: {
        getCompanyLocations(page = 1) {
            this.loading = true;

            this.api.getCompanyLocations(this.companyId, page).then(res => {
                    this.companyLocations = res.data.data.addresses;
            }).catch(err => {
                this.alertType = 'error';
                this.alertText = 'Error retrieving users';
                this.alertActive = true;

                setTimeout(() => {
                    this.alertActive = false;
                }, 5000);
            }).finally(() => {
                this.loading = false;
            });
        },
    }
}
</script>

<style scoped>

</style>
