import {ApiService} from "./api";
import {DummyApiService} from "./dummy";

class ApiFactory {
    static makeApiService(driver) {
        switch(driver) {
            case 'api':
                return new ApiService('internal-api', 'users', 1);

            case 'dummy':
            default:
                return new DummyApiService();
        }
    }
}

export { ApiFactory };
