<template>
    <nav class="list-disc flex items-center justify-between space-x-6 pb-4 border-b">
        <div>
            <div class="flex items-center py-1">
                <h3 class="text-xl inline-flex font-medium leading-none items-center"
                    :class="{'text-black': !darkMode, 'text-white': darkMode}">
                    {{ navData.breadcrumb.current }}
                </h3>
            </div>
            <p class="text-sm text-gray-400 my-2">{{ navData.description }}</p>
        </div>
        <div class="flex space-x-6" v-if="navData.showFilters">
            <div class="w-80" v-if="navData.showDatePicker">
                <Datepicker :dark="darkMode" v-model="dateRange" range multi-calendars multi-calendars-solo auto-apply
                            :max-date="new Date()" v-on:update:modelValue="processDatePeriod"
                            :format="'yyyy-MM-dd'"/>
            </div>
            <div>
                <button @click="applyFilters"
                        class="transition duration-200 text-sm font-semibold focus:outline-none py-2 rounded-md px-5 bg-primary-500 hover:bg-blue-500 text-white">
                    <svg class="h-5" width="14" height="14" viewBox="0 0 14 14" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M5.66667 2C4.69421 2 3.76157 2.38631 3.07394 3.07394C2.38631 3.76157 2 4.69421 2 5.66667C2 6.14818 2.09484 6.62498 2.27911 7.06984C2.46338 7.5147 2.73346 7.91891 3.07394 8.25939C3.41442 8.59987 3.81863 8.86996 4.26349 9.05423C4.70835 9.23849 5.18515 9.33333 5.66667 9.33333C6.14818 9.33333 6.62498 9.23849 7.06984 9.05423C7.5147 8.86996 7.91891 8.59987 8.25939 8.25939C8.59987 7.91891 8.86996 7.5147 9.05423 7.06984C9.23849 6.62498 9.33333 6.14818 9.33333 5.66667C9.33333 4.69421 8.94703 3.76158 8.25939 3.07394C7.57176 2.38631 6.63913 2 5.66667 2ZM1.65973 1.65973C2.72243 0.597022 4.16377 0 5.66667 0C7.16956 0 8.6109 0.597022 9.6736 1.65973C10.7363 2.72243 11.3333 4.16377 11.3333 5.66667C11.3333 6.41082 11.1868 7.14769 10.902 7.83521C10.7458 8.21219 10.5498 8.57029 10.3178 8.90361L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L8.90361 10.3178C8.57029 10.5498 8.21219 10.7458 7.83521 10.902C7.14769 11.1868 6.41082 11.3333 5.66667 11.3333C4.92251 11.3333 4.18564 11.1868 3.49813 10.902C2.81061 10.6172 2.18593 10.1998 1.65973 9.6736C1.13353 9.14741 0.716126 8.52272 0.431349 7.83521C0.146573 7.1477 0 6.41082 0 5.66667C0 4.16377 0.597022 2.72243 1.65973 1.65973Z"
                              fill="white"/>
                    </svg>
                </button>
            </div>
        </div>
    </nav>
</template>
<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import {onMounted, ref} from "vue";
import Dropdown from "../../../Shared/components/Dropdown.vue";
export default {
    name: "NavLeadReport",
    components: {
        Datepicker,
        Dropdown
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        navData: {
            type: Object,
            default: {}
        }
    },
    setup() {
        const dateRange = ref();
        const backDays = 30;
        const endDate = new Date();
        const startDate = new Date(new Date().setDate(endDate.getDate() - backDays));
        onMounted(() => {
            dateRange.value = [startDate, endDate];
        })
        return {
            dateRange,
            backDays,
            startDate,
            endDate
        }
    },
    created() {
        this.processRequest();
    },
    data() {
        return {
            params: {},
            periodStartDate: '',
            periodEndDate: '',
            selectedDay: null,
            daysCount: this.backDays
        }
    },
    emits: ["params"],
    methods: {
        applyFilters() {
            this.processDatePeriod();
            this.setAndEmitFilterDate();
        },
        processDatePeriod() {
            const period = [];
            this.dateRange.map(function (date) {
                period.push(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
            });
            this.periodStartDate = period[0] ?? '';
            this.periodEndDate = period[1] ?? '';
        },
        processRequest() {
            if (!this.periodStartDate || !this.periodEndDate) {
                this.periodStartDate = this.startDate.getFullYear() + '-' + (this.startDate.getMonth() + 1) + '-' + this.startDate.getDate();
                this.periodEndDate = this.endDate.getFullYear() + '-' + (this.endDate.getMonth() + 1) + '-' + this.endDate.getDate();
                this.setAndEmitFilterDate();
            }
        },
        calculatePeriods() {
            this.daysCount = (new Date(this.periodEndDate)).getTime() - (new Date(this.periodStartDate)).getTime();
            this.daysCount = Math.ceil(this.daysCount / (1000 * 3600 * 24));
        },
        setAndEmitFilterDate() {
            this.calculatePeriods()
            this.params = {
                start_date: this.periodStartDate,
                end_date: this.periodEndDate,
                daysCount: this.daysCount,
            };
            this.$emit('params', this.params);
        }
    }
}
</script>
