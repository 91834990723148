<template>
    <div>
        <alerts-container v-if="store.alertActive" :dark-mode="darkMode" :alert-type="store.alertType" :text="store.alertText" />
        <templates-list
            v-show="store.view === 'list'"
            :dark-mode="darkMode"
        />
        <templates-editor
            v-show="store.view === 'editor'"
            :dark-mode="darkMode"
        />
    </div>
</template>

<script>
    import TemplatesEditor from "./components/TemplatesEditor";
    import TemplatesList from "./components/TemplatesList";
    import LoadingSpinner from "../Shared/components/LoadingSpinner";
    import AlertsContainer from "../Shared/components/AlertsContainer";
    import {useEmailTemplatesStore} from "./store/store";

    export default {
        name: "EmailTemplates",
        props: {
            darkMode: {
                type: Boolean,
                default: false
            }
        },
        components: {
            LoadingSpinner,
            AlertsContainer,
            TemplatesEditor,
            TemplatesList
        },
        setup: function() {
            const store = useEmailTemplatesStore();

            return {
                store
            };
        },
        created: function() {

        },
        data: function() {
            return {

            };
        },
        methods: {

        }
    }
</script>

<style scoped>

</style>
