<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-20" @close="open = false">
            <div class="fixed inset-0"/>

            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                        <TransitionChild as="template"
                                         enter="transform transition ease-in-out duration-500 sm:duration-700"
                                         enter-from="translate-x-full" enter-to="translate-x-0"
                                         leave="transform transition ease-in-out duration-500 sm:duration-700"
                                         leave-from="translate-x-0" leave-to="translate-x-full">
                            <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                                <div class="flex h-full flex-col overflow-y-scroll  py-6 shadow-xl" :class="[!darkMode ? 'bg-light-module' : 'bg-dark-module']">
                                    <div class="px-4 sm:px-6">
                                        <div class="flex items-start justify-between">
                                            <DialogTitle class="text-lg font-medium text-gray-900"> Panel title
                                            </DialogTitle>
                                            <div class="ml-3 flex h-7 items-center">
                                                <button type="button"
                                                        class="rounded-lg bg-light-module text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        @click="open = false">
                                                    <span class="sr-only">Close panel</span>
                                                    <XIcon class="h-6 w-6" aria-hidden="true"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="relative mt-6 flex-1 px-4 sm:px-6">
                                        <loading-spinner class="h-full flex items-center justify-center" v-if="loading"/>
                                        <table v-else class="min-w-full divide-y " :class="[!darkMode ? 'divide-gray-300' : 'divide-dark-175']">
                                            <thead :class="[!darkMode ? 'bg-grey-50' : 'bg-dark-175']">
                                            <tr>
                                                <th scope="col"
                                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-bold sm:pl-6" :class="[!darkMode ? 'text-grey-900' : 'text-white']">
                                                    ID
                                                </th>
                                                <th scope="col"
                                                    class="px-3 py-3.5 text-left text-sm font-bold" :class="[!darkMode ? 'text-grey-900' : 'text-white']">
                                                    Name
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody class="divide-y " :class="[!darkMode ? 'divide-gray-200' : 'divide-dark-175']">
                                            <tr v-for="company in companies">
                                                <td>
                                                    <a :href="getLegacyAdminCompanyUrl(company.companyid)" target="_blank"
                                                       class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 hover:text-blue-550" :class="[!darkMode ? 'text-grey-900' : 'text-white']">
                                                        {{ company.companyid }}
                                                    </a>
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-4 text-sm" :class="[!darkMode ? 'text-grey-500' : 'text-white']">
                                                    {{ company.companyname }}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue';
import {XIcon} from '@heroicons/vue/outline';
import LoadingSpinner from "../../../Shared/components/LoadingSpinner";
import LegacyAdminMixin from "../../../Shared/mixins/legacy-admin-mixin";

export default {
    name: "CompanyDetails",
    mixins: [LegacyAdminMixin],
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        open: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: true
        },
        companies: {
            type: Object,
            default: null
        },
    },
    components: {
        Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, XIcon, LoadingSpinner
    },
}
</script>

<style scoped>

</style>
