<template>
    <div>
        <div v-if="alertType === 'error'">
            <div class="border p-4 rounded-lg shadow-xl max-w-screen-md w-full"
                 :class="[darkMode ? 'border-dark-border bg-dark-module' : 'border-light-border bg-light-module']">
                <div class="flex justify-between items-start">
                    <p class="inline-flex items-center pr-3 text-rose-500">
                        <svg class="fill-current mr-4 w-5 flex-shrink-0" width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8849 0.4905C10.5389 -0.1635 9.46294 -0.1635 9.11694 0.4905L0.116935 17.4905C0.0359187 17.6429 -0.00424573 17.8136 0.00035519 17.9861C0.00495611 18.1586 0.0541655 18.327 0.143189 18.4749C0.232212 18.6227 0.358012 18.7449 0.508333 18.8297C0.658653 18.9145 0.828366 18.9588 1.00094 18.9585H19.0009C19.1734 18.9589 19.343 18.9145 19.4932 18.8298C19.6434 18.7451 19.7691 18.6229 19.8581 18.4752C19.947 18.3274 19.9961 18.1591 20.0007 17.9867C20.0052 17.8144 19.965 17.6437 19.8839 17.4915L10.8849 0.4905ZM11.0009 15.9585H9.00094V13.9585H11.0009V15.9585ZM9.00094 11.9585V6.9585H11.0009L11.0019 11.9585H9.00094Z"/>
                        </svg>
                        {{ text }}
                    </p>

                </div>
            </div>
        </div>
        <div v-if="alertType === 'warning'">
            <div class="border p-4 rounded-lg shadow-xl max-w-screen-md w-full"
                 :class="[darkMode ? 'border-dark-border bg-dark-module' : 'border-light-border bg-light-module']">
                <div class="flex justify-between items-start">
                    <p class="inline-flex items-center pr-3 text-amber-500" :class="{'text-grey-900' : !darkMode, 'text-white' : darkMode}">
                        <svg class="fill-current mr-4 w-5 flex-shrink-0" width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8849 0.4905C10.5389 -0.1635 9.46294 -0.1635 9.11694 0.4905L0.116935 17.4905C0.0359187 17.6429 -0.00424573 17.8136 0.00035519 17.9861C0.00495611 18.1586 0.0541655 18.327 0.143189 18.4749C0.232212 18.6227 0.358012 18.7449 0.508333 18.8297C0.658653 18.9145 0.828366 18.9588 1.00094 18.9585H19.0009C19.1734 18.9589 19.343 18.9145 19.4932 18.8298C19.6434 18.7451 19.7691 18.6229 19.8581 18.4752C19.947 18.3274 19.9961 18.1591 20.0007 17.9867C20.0052 17.8144 19.965 17.6437 19.8839 17.4915L10.8849 0.4905ZM11.0009 15.9585H9.00094V13.9585H11.0009V15.9585ZM9.00094 11.9585V6.9585H11.0009L11.0019 11.9585H9.00094Z"/>
                        </svg>
                        {{ text }}
                    </p>

                </div>
            </div>
        </div>
        <div v-if="alertType === 'success'">
            <div class="border p-4 rounded-lg shadow-xl max-w-screen-md w-full"
                 :class="[darkMode ? 'border-dark-border bg-dark-module' : 'border-light-border bg-light-module']">
                <div class="flex justify-between items-start">
                    <p class="inline-flex items-center pr-3 text-emerald-500" :class="{'text-grey-900' : !darkMode, 'text-white' : darkMode}">
                        <svg class="fill-current mr-4 w-5 flex-shrink-0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0ZM8.001 14.413L4.288 10.708L5.7 9.292L7.999 11.587L13.293 6.293L14.707 7.707L8.001 14.413Z" fill="#34D399"/>
                        </svg>
                        {{ text }}
                    </p>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "Alert",
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        alertType: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: null,
        }
    }
}
</script>

<style scoped>

</style>
