<template>
    <div>
        <alerts-container v-if="alertActive" :alert-type="alertType" :text="alertText" :dark-mode="darkMode" />

        <div ref="editorContainer"></div>
    </div>
</template>

<script setup>
    //CSS
    import '@toast-ui/editor/dist/toastui-editor.css';
    import 'tui-color-picker/dist/tui-color-picker.css';
    import '@toast-ui/editor/dist/theme/toastui-editor-dark.css';
    import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';

    //JS
    import {ref, defineProps, defineEmits, onMounted, computed, watch, reactive} from 'vue';
    import AlertsContainer from "./AlertsContainer";
    import Editor from "@toast-ui/editor"
    import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
    import SharedApiService from "../services/api";
    import { extractMarkdownImageTags, __IMAGE_NAME_REGEX__ } from "../services/markdownImageTags";

    const props = defineProps({
        content: {
            type: String,
            default: ''
        },
        images: {
            type: Object,
            default: () => {}
        },
        darkMode: {
            type: Boolean,
            default: false
        },
        additionalPlugins: {
            type: Array,
            default: () => []
        },
        imageFileBasePath: {
            type: String,
            default: ''
        }
    });

    const emit = defineEmits([
        'update:content',
        'update:images'
    ]);

    const uploadImageName = ref('');

    const alertActive = ref(false);
    const alertType = ref('');
    const alertText = ref('');

    const maxFileSizeMb = 25; //TODO: confirm max file size
    const api = SharedApiService.make();

    const editorContainer = ref();

    const editorContent = computed({
        get() {
            return props.content;
        },
        set(value) {
            emit('update:content', value);
        }
    });
    const editorImages = computed({
        get() {
            return props.images;
        },
        set(value) {
            emit('update:images', value);
        }
    });

    let editor;
    onMounted(() => {
        editor = new Editor({
            minHeight: "300px",
            height: "436px",
            el: editorContainer.value,
            initialEditType: 'wysiwyg',
            previewStyle: 'tab',
            events: {
                change: () => {
                    const newContent = editor.getMarkdown().replace(/<br>\n/g, '<br>').replace(/[\n]/g, '<br>');

                    editorImages.value = extractMarkdownImageTags(newContent, editorImages.value);

                    editorContent.value = newContent;
                }
            },
            hooks: {
                addImageBlobHook: (blob, callback) => {
                    if(__IMAGE_NAME_REGEX__.test(props.imageFileBasePath + blob.name)) {
                        const reader = new FileReader();

                        reader.addEventListener("load", () => {
                            editorImages.value[props.imageFileBasePath + blob.name] = reader.result;
                            callback(reader.result, props.imageFileBasePath + blob.name);
                        });

                        reader.readAsDataURL(blob);
                    }
                    else {
                        showAlert('error', 'Image name may only contain letters, numbers, periods, and underscores');
                    }
                }
            },
            usageStatistics: false,
            toolbarItems: [
                ['heading', 'bold', 'italic', 'strike'],
                ['hr', 'quote'],
                [],
                ['ul', 'ol', 'task', 'indent', 'outdent'],
                ['table', 'image', 'link'],
                ['scrollSync']
            ],
            plugins: [colorSyntax].concat(props.additionalPlugins),
            initialValue: editorContent.value,
            theme: props.darkMode ? 'dark' : 'default'
        });
    });

    watch(editorContent, (newContent) => {
        if(newContent !== editor.getMarkdown().replace(/<br>\n/g, '<br>').replace(/[\n]/g, '<br>')) {
            editor.setMarkdown(newContent);
        }
    });
    watch(() => props.darkMode, () => {
        let editorBody = editorContainer.value.querySelector('.toastui-editor-defaultUI');

        if(props.darkMode) {
            editorBody?.classList.add("toastui-editor-dark");
        }
        else {
            editorBody?.classList.remove("toastui-editor-dark");
        }
    });

    const showAlert = (type, text) => {
        alertType.value = type;
        alertText.value = text;

        alertActive.value = true;

        setTimeout(() => {
            alertActive.value = false;
            alertType.value = '';
            alertText.value = '';
        }, 5000);
    }
</script>
