<template>
    <div class="main-layout font-body">
        <div class="w-full">
            <div class="w-full flex-auto pt-3 pb-8 relative"
                 :class="{'bg-light-background': !darkMode, 'bg-dark-background': darkMode}">

                <div class="px-10" :class="[darkMode ? 'text-white' : 'text-slate-900']">
                    <div v-if="data.lead && !loading">
                        <div class="flex items-center justify-between flex-wrap py-4">
                            <div class="flex items-center py-1">
                                <h3 class="text-xl font-medium pb-0 mr-4">Lead Processing</h3>
                                <button class="px-1 mr-3 py-1 rounded text-blue-550 outline-none"
                                        :class="{
                                        'bg-light-module hover:bg-grey-100 text-grey-350': !darkMode,
                                        'bg-dark-module hover:bg-dark-background text-blue-400': darkMode
                                    }"
                                        @click="toggleHistory(true)">
                                    <svg class="fill-current" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 4.99976V9.99976H15V7.99976H12V4.99976H10Z" />
                                        <path d="M19.292 5.49664C18.8409 4.42668 18.1861 3.45465 17.364 2.63464C16.1196 1.3902 14.5377 0.537739 12.814 0.182636C11.6176 -0.0608787 10.3844 -0.0608787 9.188 0.182636C7.46267 0.535471 5.87947 1.38863 4.636 2.63564C3.81589 3.45652 3.16133 4.42749 2.708 5.49564C2.23911 6.60425 1.99832 7.79594 2 8.99964L2.001 9.02464H0L3 12.9996L6 9.02464H4.001L4 8.99964C3.99712 7.60501 4.41346 6.24171 5.195 5.08664C5.69912 4.34132 6.34095 3.69915 7.086 3.19464C7.84394 2.68404 8.69412 2.32603 9.589 2.14064C11.4075 1.76674 13.3001 2.13027 14.8507 3.15131C16.4013 4.17235 17.483 5.76733 17.858 7.58564C18.0466 8.51752 18.0466 9.47775 17.858 10.4096C17.675 11.3053 17.3168 12.1559 16.804 12.9126C16.554 13.2836 16.267 13.6326 15.95 13.9486C15.3112 14.5867 14.5558 15.0962 13.725 15.4496C13.3018 15.6285 12.862 15.7652 12.412 15.8576C11.4804 16.0461 10.5206 16.0461 9.589 15.8576C8.6943 15.6742 7.84447 15.3164 7.088 14.8046C6.71595 14.5532 6.36871 14.2669 6.051 13.9496L4.637 15.3636C5.47212 16.1999 6.46403 16.8632 7.5559 17.3155C8.64777 17.7679 9.81815 18.0003 11 17.9996C12.2031 17.9991 13.3939 17.7588 14.503 17.2926C16.1106 16.6125 17.486 15.4799 18.462 14.0326C19.4665 12.5465 20.0022 10.7934 20 8.99964C20.0025 7.79619 19.7617 6.60465 19.292 5.49664Z"/>
                                    </svg>
                                </button>
                                <a class="mr-5 font-semibold"
                                   :class="{
                                        'text-grey-350': !darkMode,
                                        'text-blue-400': darkMode
                                    }"
                                   v-if="data.lead != null"
                                   :href="getLegacyAdminQuoteUrl(data.lead.basic.legacy_lead_id)" target="_blank">
                                    #{{ data.lead.basic.id }} (Legacy: {{ data.lead.basic.legacy_lead_id }})</a>
                                <div class="border text-sm font-medium px-5 rounded-full mr-5" v-if="data.lead != null"
                                     :class="{'bg-cyan-100 border-primary-500 text-blue-550': !darkMode, 'bg-dark-background border-primary-500 text-blue-550': darkMode}">
                                    {{ data.lead.basic.status }}
                                </div>
                                <div class="border text-sm font-medium px-5 rounded-full mr-5" v-if="data.lead != null"
                                     :class="{'bg-cyan-100 border-primary-500 text-blue-550': !darkMode, 'bg-dark-background border-primary-500 text-blue-550': darkMode}">
                                    {{ data.lead.basic.classification }}
                                </div>
                                <div class="border text-sm font-medium px-5 rounded-full mr-5" v-if="data.lead != null"
                                     :class="industryClasses()">
                                    {{ data.lead.basic.industry }}
                                </div>
                                <div class="border text-sm font-medium px-5 rounded-full mr-5" v-if="data.lead != null"
                                     :class="{'bg-cyan-100 border-primary-500 text-blue-550': !darkMode, 'bg-dark-background border-primary-500 text-blue-550': darkMode}">
                                    {{ data.lead.basic.type }}
                                </div>

                            </div>
                            <div>
                                <div class="flex items-center" v-if="!locked">
                                    <button @click="showModal('cancel')" class="transition duration-200 text-sm font-semibold focus:outline-none py-2 rounded-md px-5 mr-3"
                                            :class="{'bg-grey-250 hover:bg-grey-400 text-white': !darkMode, 'bg-grey-500 hover:bg-grey-600 text-white': darkMode}">
                                        Cancel Lead
                                    </button>
                                    <button @click="showModal('pending-review')" class="transition duration-200 text-sm font-semibold focus:outline-none py-2 rounded-md px-5 mr-3"
                                            :class="{'bg-grey-475 hover:bg-blue-800 text-white': !darkMode, 'bg-blue-400 hover:bg-blue-500 text-white': darkMode}">
                                        Needs Further Review
                                    </button>
                                    <button @click="showModal('allocate')" class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-semibold focus:outline-none py-2 rounded-md px-5">
                                        Allocate Lead
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="grid md:grid-cols-1 lg:grid-cols-3 gap-4" v-if="data.lead != null">
                                <div class="lg:col-span-2">
                                    <div class="mb-4">
                                        <div class="grid grid-cols-2 gap-4 h-full">
                                            <consumer-product-date-info :dark-mode="darkMode" :consumer-product-id="data.lead.basic.id" />
                                            <consumer-product-utility :dark-mode="darkMode" :consumer-product-id="data.lead.basic.id" />
                                            <consumer-contact :dark-mode="darkMode" :consumer-product-id="data.lead.basic.id" />
                                            <consumer-product-basic-info :dark-mode="darkMode" :consumer-product-id="data.lead.basic.id" />
                                        </div>
                                    </div>
                                    <interactive-map :dark-mode="darkMode" :address="data.lead.basic.address"></interactive-map>
                                </div>
                                <div class="lg:col-span-1 lg:col-start-3 lg:row-span-1">
                                    <consumer-product-verification :dark-mode="darkMode" :consumer-product-id="data.lead.basic.id" class="mb-4"/>
                                    <comments class="mb-4" :dark-mode="darkMode" :consumer-product-id="data.lead.basic.id"></comments>
                                    <consumer-product-previous-leads class="mb-4" :consumer-product-id="data.lead.basic.id" :dark-mode="darkMode" />
                                    <consumer-reviews class="mb-4" :dark-mode="darkMode" :consumer-product-id="data.lead.basic.id" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="loading">
                        <loading-spinner :dark-mode="darkMode" />
                    </div>
                    <div v-else>
                        <div>
                            <button class="px-5 py-1 rounded text-blue-550 outline-none my-3"
                                    :class="{
                                        'bg-cyan-100 hover:bg-cyan-300': !darkMode,
                                        'bg-dark-background hover:bg-blue-600': darkMode
                                    }"
                                    @click="toggleHistory(true)">
                                History
                            </button>
                        </div>
                        <div class="flex flex-row w-full justify-center items-center text-2xl">
                            No leads in queue
                        </div>
                    </div>
                </div>

                <Modal :dark-mode="darkMode" @close="hideModal" @confirm="confirmSelection('Qualified via conversation')" v-if="displayModal">
                    <template v-slot:header>
                        <h4 class="text-xl font-medium">{{ modalConfiguration.title }}</h4>
                    </template>

                    <template v-slot:content>
                      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 my-4">
                        <button
                            v-for="option in modalConfiguration.options" :key="option.id ? option.id : option" @click="() => selectModalOption(option)"
                            class="transition duration-200 text-sm font-medium focus:outline-none py-2 rounded-md px-5 font-bold"
                            :class="optionClasses(option)"
                        >
                          {{ option.value ? option.value : option }}
                        </button>
                      </div>

                      <div class="my-2 font-bold text-red-500" v-if="modalConfiguration.warning">
                        {{ modalConfiguration.warning }}
                      </div>

                    </template>
                </Modal>
                <processing-history v-if="showHistory" :dark-mode="darkMode" :api="api" @close="toggleHistory(false)"></processing-history>
            </div>
        </div>
    </div>
</template>

<script>
import InteractiveMap from "../components/InteractiveMap";
import Modal from "../components/Modal";
import LoadingSpinner from "../components/LoadingSpinner";
import ProcessingHistory from "../components/ProcessingHistory";
import QueueInteractionMixin from "../mixins/queue-interaction-mixin";
import ModalsMixin from "../mixins/modals-mixin";
import ConsumerProductBasicInfo from "../../Shared/modules/Consumer/ConsumerProductBasicInfo";
import ConsumerProductPreviousLeads from "../../Shared/modules/Consumer/ConsumerProductPreviousLeads";
import ConsumerContact from "../../Shared/modules/Consumer/ConsumerContact";
import ConsumerReviews from "../../Shared/modules/Consumer/ConsumerReviews";
import ConsumerProductUtility from "../../Shared/modules/Consumer/ConsumerProductUtility";
import ConsumerProductVerification from "../../Shared/modules/Consumer/ConsumerProductVerification";
import ConsumerProductDateInfo from "../../Shared/modules/Consumer/ConsumerProductDateInfo";
import LegacyAdminMixin from "../../Shared/mixins/legacy-admin-mixin";
import Comments from "../components/Comments";

export default {
    components: {
        ConsumerContact,
        ConsumerProductUtility,
        ConsumerReviews,
        ConsumerProductBasicInfo,
        ConsumerProductPreviousLeads,
        LoadingSpinner,
        InteractiveMap,
        Modal,
        ProcessingHistory,
        ConsumerProductVerification,
        ConsumerProductDateInfo,
        Comments
    },
    mixins: [
        QueueInteractionMixin,
        ModalsMixin,
        LegacyAdminMixin
    ],
    props: ["darkMode"],
    data() {
        return {
            showHistory: false
        }
    },
    methods: {
      industryClasses() {
          if(this.data.lead.basic.industry === "Roofing") {
              if(this.darkMode) {
                  return ['bg-dark-background', 'border-grey-50', 'text-grey-50'];
              } else {
                  return ['bg-grey-100', 'border-grey-600', 'text-grey-600'];
              }
          }

          if(this.darkMode) {
              return ['bg-dark-background', 'border-primary-500', 'text-blue-550'];
          } else {
              return ['bg-cyan-100', 'border-primary-500', 'text-blue-550'];
          }
      },
      toggleHistory(show) {
          this.showHistory = !!show;
      }
    }
};
</script>

<style scoped>

</style>
