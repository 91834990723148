<template>
    <div @click="toggleExpandContact(contact.id)"
         class="flex items-center relative" :class="{ 'cursor-pointer': !contactHasNoDetails }">
        <div v-if="!contactHasNoDetails" class="w-4 p-1 absolute -left-3 top-1">
            <svg class="w-4"
                 :class="{'transform transition duration-200 rotate-90' : expandedContacts.includes(contact.id) }"
                 width="6" height="10" viewBox="0 0 6 10" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M1 9L5 5L1 1" stroke="#0081FF" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="ml-3">
            <p class="text-sm truncate pb-0 font-medium">
                {{ contact.name }}
            </p>
            <p class="text-grey-400 text-sm font-medium">{{ contact.title }}</p>
            <div :class="[darkMode ? 'text-slate-400' : 'text-slate-500']">
                <p v-if="typeof contact?.latest_call_timestamp == 'number'" class=" text-sm">
                    Last
                    called on {{
                        DateTime.fromSeconds(contact.latest_call_timestamp).toLocaleString(DateTime.DATETIME_FULL)
                    }}</p>
                <p v-else>This contact was not called yet.</p>
                <div class="inline-flex items-center text-grey-600">
                    <p class=" text-sm mr-6">Total calls: {{
                            contact?.total_calls_count ?? "N/A"
                        }}</p>
                    <p class=" text-sm">Total calls over a minute: {{
                            contact?.total_calls_over_one_minute_count ?? "N/A"
                        }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {DateTime} from "luxon";

export default {
    name: "Contact",
    computed: {
        DateTime() {
            return DateTime
        },
        contactHasValidEmail() {
            return this.contact.email && this.contact.email.length > 0;
        },
        contactHasValidOfficePhone() {
            return this.contact.office_phone && this.contact.office_phone.length > 0;
        },
        contactHasValidCellPhone() {
            return this.contact.cell_phone && this.contact.cell_phone.length > 0;
        },
        contactHasNoDetails() {
            return !this.contactHasValidEmail && !this.contactHasValidOfficePhone && !this.contactHasValidCellPhone
        }
    },
    emits: ['toggle:expand'],
    props: {
        contact: {
            type: Object,
            required: true
        },
        expandedContacts: {
            type: Array,
            required: true
        },
        darkMode: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        toggleExpandContact(id) {
            if(this.contactHasNoDetails) return;
            this.$emit('toggle:expand', id)
        },
    },
}
</script>

<style scoped>

</style>
