<template>
    <div class="w-100 h-100 shadow-module border rounded-t-md rounded-bl-md rounded-br-xl"
         :class="{'bg-dark-module border-primary-500' : darkMode ,'bg-light-module border-light-border' : !darkMode }">
        <div class="p-5">
            <h5 class="text-primary-500 text-sm uppercase font-semibold">Notifications</h5>
        </div>
        <div class="border-t border-b overflow-y-auto"
             :class="{'bg-dark-background border-dark-border' : darkMode ,'bg-light-background border-light-border' : !darkMode }"
             style="height: 75%;">
            <div v-for="(notification, id) in groupedNotifications" :key="id"
                 :class="{
                    'hover:bg-dark-module border-dark-border text-grey-120' : darkMode && notification.read,
                    'hover:bg-light-module bg-grey-150 border-light-border  text-grey-900' : !darkMode && notification.read,
                    'bg-dark-175 hover:bg-blue-700 border-dark-40 text-grey-120': darkMode && !notification.read,
                    'hover:bg-light-module bg-grey-50 border-light-border  text-grey-900': !darkMode && !notification.read
                 }"
                 class="py-3 px-5 border-b transition duration-200 cursor-pointer relative"
                 @click="notification.notifications ? toggleShowLeadNotifications(notification.notifications[0].lead_id) : $emit('notification-clicked', notification)">

                <div v-if="!notification.read"
                     class="absolute left-0 bg-primary-500 h-2 w-2 rounded-full ml-2"
                     :class="Array.isArray(notification) ? 'mt-3' : 'mt-2'"></div>

                <div v-if="notification.notifications"
                    class="flex flex-col">
                    <div class="grid grid-cols-3 py-2">
                        <p class="text-sm font-medium truncate col-span-2">
                            Lead {{ notification.notifications[0].lead_id }}
                        </p>
                        <p class="text-sm text-right mr-5"
                           :class="{'text-blue-400' : darkMode ,'text-grey-400' : !darkMode }">
                            {{ $filters.dateFromTimestamp(notification.notifications[0].timestamp, 'relative') }}
                        </p>
                    </div>

                    <div v-show="shownNotificationsLeadId === notification.notifications[0].lead_id"
                         class="ml-4">
                        <div v-for="nt in notification.notifications"
                             class="border-t border-grey-200 py-2 my-1"
                             @click.stop="$emit('notification-clicked', nt)">
                            <div v-if="!nt.read"
                                 class="absolute left-0 bg-primary-500 h-2 w-2 rounded-full ml-2 mt-2"></div>

                            <div class="grid grid-cols-3">
                                <p class="text-sm font-medium truncate col-span-2">
                                    {{ nt.subject }}
                                </p>
                                <p class="text-sm text-right mr-5"
                                   :class="{'text-blue-400' : darkMode ,'text-grey-400' : !darkMode }">
                                    {{ $filters.dateFromTimestamp(nt.timestamp, 'relative') }}
                                </p>
                            </div>

                            <div class="flex items-center justify-between col-span-3">
                                <div class="grid grid-cols-8">
                                    <p v-if="nt.message"
                                       class="text-sm truncate mr-3 text-light col-span-6"
                                       :class="{'text-blue-300' : darkMode, 'text-grey-500' : !darkMode}">
                                        {{ nt.message }}
                                    </p>
                                    <div v-if="nt.type === 3 && nt.type_id !== null"
                                         class="mr-3 text-sm text-light col-span-2"
                                         :class="{'text-blue-300' : darkMode, 'text-grey-500' : !darkMode}">
                                        Go to {{ getRedirectionHint(nt) }} >
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div v-else
                     class="grid grid-cols-3">
                    <p class="text-sm font-medium truncate col-span-2">
                        {{ notification.subject }}
                    </p>
                    <p class="text-sm text-right mr-5"
                       :class="{'text-blue-400' : darkMode ,'text-grey-400' : !darkMode }">
                        {{ $filters.dateFromTimestamp(notification.timestamp ,'relative') }}
                    </p>

                    <div class="flex items-center justify-between col-span-3">
                        <!-- Regular notifications show message -->
                        <p v-if="notification.message"
                           class="text-sm mr-3 text-light w-full"
                           :title="notification.message"
                           :class="{
                                'text-blue-300': darkMode,
                                'text-grey-500': !darkMode,
                                'break-words': notification.showFullMessage,
                                'truncate': !notification.showFullMessage
                           }"
                           @click="showFullMessage(notification)">
                            {{ notification.message }}
                        </p>

                        <!-- TODO: Task Notifications will show priority and due date instead of Notification message. -->
                        <div v-if="taskNotification" class="mt-1">
                            <p v-if="priorityLevel === 1" class="text-xs font-medium truncate py-1 px-2 rounded-full text-light text-blue-550 bg-cyan-100"
                            >Low Priority</p>
                            <p v-if="priorityLevel === 2" class="text-xs font-medium truncate py-1 px-2 rounded-full text-light text-yellow-700 bg-yellow-200"
                            >Medium Priority</p>
                            <p v-if="priorityLevel === 3" class="text-xs font-medium truncate py-1 px-2 rounded-full text-light text-red-500 bg-red-100"
                            >High Priority</p>
                        </div>
                        <p v-if="taskNotification" class="text-sm text-right mr-5"
                           :class="{'text-blue-400' : darkMode ,'text-grey-400' : !darkMode }">
                            Due: 02/04/23
                        </p>
                    </div>

                    <div v-if="notification.type_id !== null && notification.type_id >0" class="absolute right-0 mr-3 h-full inline-flex items-center">
                        <svg class="flex-shrink-0 w-2" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.646447 0.646447C0.841709 0.451184 1.15829 0.451184 1.35355 0.646447L6.35355 5.64645C6.54882 5.84171 6.54882 6.15829 6.35355 6.35355L1.35355 11.3536C1.15829 11.5488 0.841709 11.5488 0.646447 11.3536C0.451184 11.1583 0.451184 10.8417 0.646447 10.6464L5.29289 6L0.646447 1.35355C0.451184 1.15829 0.451184 0.841709 0.646447 0.646447Z" fill="#0081FF"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "NotificationsList",
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        notifications: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
            taskNotification: false, // Set for testing
            priorityLevel: 3, // Set for testing
            shownNotificationsLeadId: 0
        }
    },
    computed: {
        groupedNotifications: function() {
            let groupedNotifications = {};
            let leadId;

            this.notifications.forEach((notification) => {
                if(notification.lead_id
                || (notification.type === 3 && notification.type_id)) {
                    leadId = "Lead " + (notification.lead_id || notification.type_id);

                    if(groupedNotifications[leadId] == null) {
                        groupedNotifications[leadId] = {
                            notifications: [],
                            read: true,
                            timestamp: 0
                        };
                    }

                    notification.lead_id = (notification.lead_id || notification.type_id);

                    groupedNotifications[leadId].notifications.push(notification);

                    if(groupedNotifications[leadId].read) {
                       groupedNotifications[leadId].read = notification.read;
                    }

                    if(notification.timestamp > groupedNotifications[leadId].timestamp) {
                        groupedNotifications[leadId].timestamp = notification.timestamp;
                    }
                }
                else {
                    groupedNotifications[notification.id] = notification;
                }
            });

            let groupedNotificationsSorted = Object.values(groupedNotifications).sort((a, b) => {
                 return b.timestamp - a.timestamp;
            });

            return groupedNotificationsSorted;
        }
    },
    methods: {
        toggleShowLeadNotifications(leadId) {
            this.shownNotificationsLeadId = (this.shownNotificationsLeadId === leadId ? 0 : leadId);
        },
        getRedirectionHint(nt) {
            return (nt.link !== null && nt.link_type !== null && nt.link_type === "task") ? "Task" : "Lead";
        },
        showFullMessage(notification) {
            notification.showFullMessage = !notification.showFullMessage;
        }
    }
}
</script>

<style scoped>

</style>
