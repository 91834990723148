<template>
    <div class="border-l border-dashed" :class="{'ml-8': indent, 'text-grey-800 border-grey-300': !darkMode, 'text-grey-200 border-blue-400': darkMode}">

        <template v-for="action in actions">
            <action-type
                :type="action.action.display_name"
                :node-type="action.action.action_type.replace(/-|_/gm, ' ')"
                @click="editNode(action.action)" />
            <action-branch
                v-if="action.children.length > 0 || isTaskAction(action) || isResultAction(action) || isLogicNode(action)"
                @add-action="nestedAddAction"
                @add-result="addResult"
                @edit-node="editNode"
                :dark-mode="darkMode"
                :actions="action.children"
                :parent-node="action.action"
                :indent="true"
                :workflow="workflow"/>
        </template>

        <!-- TODO: :selected="actionSelection && workingWorkflow && workingWorkflow.id === workflow.id" -->
        <AddAction
            v-if="shouldShowNewAction() && !shouldShowAddResult()"
            @add-action="addAction(workflow, previousNode)"
            :dark-mode="darkMode"/>
        <AddResult
            v-if="shouldShowAddResult()"
            :dark-mode="darkMode"
            :action="parentNode"
            :workflow="workflow"
            @add-result="addResult"
        ></AddResult>
    </div>
</template>

<script>
import ActionType from "../ActionTreeComponents/ActionType";
import AddAction from "../ActionTreeComponents/AddAction";
import {PREVIOUS_NODE_TYPE_PARENT, PREVIOUS_NODE_TYPE_SIBLING, TASK_NODE_TYPES, RESULT_NODE_TYPES, LOGIC_NODE_TYPES} from "../../factories/workflow-factory";
import AddResult from "./AddResult";

export default {
    name: "ActionBranch",
    components: {
        AddResult,
        ActionType, AddAction
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        actions: {
            type: Array,
            default: () => ([])
        },
        workflow: {
            type: Object
        },
        indent: {
            type: Boolean,
            default: false
        },
        parentNode: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            previousNode : {
                id: null,
                type: null,
                node_type: null
            }
        }
    },
    mounted() {
        this.setPreviousNode();
    },
    methods: {
        shouldShowNewAction() {
            return this.previousNode.node_type == null || !(TASK_NODE_TYPES.includes(this.previousNode.node_type));
        },
        shouldShowAddResult() {
            return this.parentNode !== null && TASK_NODE_TYPES.includes(this.parentNode.action_type);
        },
        addAction(workflow, previousNode) {
            this.$emit('add-action', workflow, previousNode);
        },
        nestedAddAction(workflow, previousNode) {
            this.$emit('add-action', workflow, previousNode);
        },
        addResult(workflow, action) {
            this.$emit('add-result', workflow, action);
        },
        setPreviousNode()
        {
            if (this.parentNode) {
                if (this.actions.length === 0) {
                    this.previousNode.id = this.parentNode.id;
                    this.previousNode.node_type = this.parentNode.action_type;
                    this.previousNode.type = PREVIOUS_NODE_TYPE_PARENT;
                    return;
                }
            }

            if (this.actions.length > 0) {
                this.previousNode.id = this.actions[this.actions.length - 1].action.id;
                this.previousNode.node_type = this.actions[this.actions.length - 1].action.action_type;
                this.previousNode.type = PREVIOUS_NODE_TYPE_SIBLING;
            }
        },
        isTaskAction(action) {
            return TASK_NODE_TYPES.includes(action.action.action_type);
        },
        isResultAction(action) {
            return RESULT_NODE_TYPES.includes(action.action.action_type);
        },
        isLogicNode(action) {
          return LOGIC_NODE_TYPES.includes(action.action.action_type);
        },
        editNode(node) {
            this.$emit('edit-node', node);
        }
    }
}
</script>

<style scoped>

</style>
