const __API_BASE_URL__ = 'internal-api';
const __VERSION__ = 1;
const __BASE_ENDPOINT__ = 'email-templates';

export default class Api {
    constructor() {

    }

    axios() {
        const axiosConfig = {
            baseURL: Api.getBaseUrl()
        }

        return axios.create(axiosConfig);
    }

    static getBaseUrl() {
        return `/${__API_BASE_URL__}/v${__VERSION__}/${__BASE_ENDPOINT__}`;
    }

    static make() {
        return new Api();
    }

    getShortcodes() {
        return this.axios().get('shortcodes');
    }

    getEmailTemplate(id) {
        return this.axios().get(`/template/${id}`);
    }

    previewEmailTemplate(content, backgroundId) {
        return this.axios().post('preview', {
            content: content,
            background_id: backgroundId
        });
    }

    createEmailTemplate(
        name,
        content,
        personal,
        industry,
        backgroundId
    )
    {
        return this.axios().post('create', {
            name: name,
            content: content,
            personal: personal ? 1 : 0,
            industry: industry,
            background_id: backgroundId
        });
    }

    updateEmailTemplate(
        id,
        name,
        content,
        personal,
        industry,
        backgroundId
    )
    {
        return this.axios().patch(`update/${id}`, {
            name: name,
            content: content,
            personal: personal ? 1 : 0,
            industry: industry,
            background_id: backgroundId
        });
    }

    deleteEmailTemplate(id) {
        return this.axios().delete(`delete/${id}`);
    }

    getEmailTemplateBackground(id) {
        return this.axios().get(`/backgrounds/template/${id}`);
    }

    getEmailTemplateBackgroundsByIndustry(industry) {
        return this.axios().get(`/backgrounds/list-industry?industry=${industry}`);
    }

    getEmailTemplateBackgrounds() {
        return this.axios().get(`/backgrounds/list`);
    }

    previewEmailTemplateBackground(header, footer) {
        return this.axios().post('/backgrounds/preview', {
            header: header,
            footer: footer
        });
    }

    createEmailTemplateBackground(
        name,
        header,
        footer,
        personal,
        industry
    )
    {
        return this.axios().post('/backgrounds/create', {
            name: name,
            header: header,
            footer: footer,
            personal: personal ? 1 : 0,
            industry: industry
        });
    }

    updateEmailTemplateBackground(
        id,
        name,
        header,
        footer,
        personal,
        industry
    )
    {
        return this.axios().patch(`/backgrounds/update/${id}`, {
            name: name,
            header: header,
            footer: footer,
            personal: personal ? 1 : 0,
            industry: industry
        });
    }

    deleteEmailTemplateBackground(id) {
        return this.axios().delete(`/backgrounds/delete/${id}`);
    }

    saveTemplateImage(name, imageDataUrl, templateId, templateType) {
        return this.axios().post('image', {
            image_name: name,
            image_data_url: imageDataUrl,
            template_id: templateId,
            template_type: templateType
        });
    }
}
