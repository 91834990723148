<template>
    <div>
        <industries v-if="page === 'industries'"
                    :dark-mode="darkMode"
                    @industry-websites-requested="loadIndustryWebsites"
                    @industry-companies-requested="loadIndustryCompanies"
                    @industry-services-requested="loadIndustryServices"
                    @industry-company-fields-requested="loadIndustryCompanyFields"
                    @industry-consumer-fields-requested="loadIndustryConsumerFields"
                    @industry-types-requested="loadIndustryTypes"
        />
        <industry-websites v-if="page === 'websites'" :dark-mode="darkMode" :industry="industryId" @back="page = 'industries'"/>
        <industry-companies v-if="page === 'companies'" :dark-mode="darkMode" :industry="industryId" @back="page = 'industries'"/>
        <industry-services v-if="page === 'services'" :dark-mode="darkMode" :industry="industryId" @back="page = 'industries'"/>
        <industry-company-fields v-if="page === 'company-fields'" :dark-mode="darkMode" :industry="industryId" @back="page = 'industries'"/>
        <industry-consumer-fields v-if="page === 'consumer-fields'" :dark-mode="darkMode" :industry="industryId" @back="page = 'industries'"/>
        <industry-types v-if="page === 'types'" :dark-mode="darkMode" :industry="industryId" @back="page = 'industries'" @industry-type-reviews-requested="loadIndustryTypeReviews"/>
        <industry-type-reviews v-if="page === 'reviews'" :dark-mode="darkMode" :industry-type="industryType" @back="page = 'types'"/>
    </div>
</template>

<script>
import Industries from "./components/Industries";
import IndustryWebsites from "./components/IndustryWebsites";
import IndustryCompanies from "./components/IndustryCompanies";
import IndustryServices from "./components/IndustryServices";
import IndustryCompanyFields from "./components/IndustryCompanyFields";
import IndustryConsumerFields from "./components/IndustryConsumerFields";
import IndustryTypes from "./components/IndustryTypes";
import IndustryTypeReviews from "./components/IndustryTypeReviews";

export default {
    name: "IndustrySetup",
    components: {
        IndustryTypeReviews,
        Industries,
        IndustryWebsites,
        IndustryCompanies,
        IndustryServices,
        IndustryCompanyFields,
        IndustryConsumerFields,
        IndustryTypes
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        breadcrumbPages: {
            type: Array,
            default: []
        },
        industry: {
            type: Number,
            default: null
        }
    },
    data () {
        return {
            page: 'industries',
            industryId: null,
            industryType: null
        }
    },
    watch: {
        breadcrumbPages: {
            immediate: true,
            handler(newVal) {
                if (this.industry === null) {
                    this.page = 'industries';
                } else {
                    switch (newVal[newVal.length - 1].name) {
                        case 'Websites':
                            this.page = 'websites';
                            break;
                        case 'Companies':
                            this.page = 'companies';
                            break;
                        case 'Services':
                            this.page = 'services';
                            break;
                        case 'Company Fields':
                            this.page = 'company-fields';
                            break;
                        case 'Consumer Fields':
                            this.page = 'consumer-fields';
                            break;
                        case 'Types':
                            this.page = 'types';
                            break;
                        case 'Reviews':
                            this.page = 'reviews';
                            break;
                    }
                }
            }
        },
        industry: {
            immediate: true,
            handler(newVal) {
                if(newVal) {
                    this.industryId = newVal;
                }
            }
        }
    },
    methods: {
        loadIndustryWebsites(industry) {
            this.addIndustryToBreadcrumb(industry);
            this.page = 'websites';
            this.$emit('add-breadcrumb-page', {name: 'Websites', id: this.page});
        },
        loadIndustryCompanies(industry) {
            this.addIndustryToBreadcrumb(industry);
            this.page = 'companies';
            this.$emit('add-breadcrumb-page', {name: 'Companies', id: this.page});
        },
        loadIndustryServices(industry) {
            this.addIndustryToBreadcrumb(industry);
            this.page = 'services';
            this.$emit('add-breadcrumb-page', {name: 'Services', id: this.page});
        },
        loadIndustryCompanyFields(industry) {
            this.addIndustryToBreadcrumb(industry);
            this.page = 'company-fields';
            this.$emit('add-breadcrumb-page', {name: 'Company Fields', id: this.page});
        },
        loadIndustryConsumerFields(industry) {
            this.addIndustryToBreadcrumb(industry);
            this.page = 'consumer-fields';
            this.$emit('add-breadcrumb-page', {name: 'Consumer Fields', id: this.page});
        },
        loadIndustryTypes(industry) {
            this.addIndustryToBreadcrumb(industry);
            this.page = 'types';
            this.$emit('add-breadcrumb-page', {name: 'Types', id: this.page});
        },
        loadIndustryTypeReviews(industryType) {
            this.$emit('add-breadcrumb-page', {name: industryType?.name, id: industryType?.key});
            this.industryType = industryType.id;
            this.page         = 'reviews';
            this.$emit('add-breadcrumb-page', {name: 'Reviews', id: this.page});
        },
        addIndustryToBreadcrumb(industry) {
            this.industryId = industry.id;
            this.$emit('add-breadcrumb-page', {name: industry?.name, id: industry?.name.toLowerCase() })
        }
    }
}
</script>
