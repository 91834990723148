<template>
    <div class="mt-2">
        <div v-for="(item, index) in payload" class="grid grid-cols-1 gap-3 border-b py-4" :class="{'border-grey-200': !darkMode, 'border-blue-400 ': darkMode}">
            <div class="flex items-center">
                <p @click="removeItem(index)" class="cursor-pointer hover:bg-red-500 bg-primary-500 h-6 w-6 rounded-full text-white mr-3 inline-flex items-center justify-center font-bold text-sm whitespace-nowrap">{{ index + 1 }}</p>
                <div class="w-full">
                    <p class="uppercase font-semibold text-xs mb-1" :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                        {{ title }}
                    </p>
                    <input type="text" class="w-full border rounded focus:outline-none focus:border focus:border-primary-500 py-2" placeholder="Display Name"
                           :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}" v-model="payload[index]" :placeholder="placeholder"/>
                </div>
            </div>
        </div>
        <div class="flex items-center justify-center my-2">
            <button @click="addItem" type="button" class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-primary-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-550">
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "TextSelector",
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "Start Typing..."
        },
        title: {
            type: String,
            default: "Enter Option"
        },
        modelValue: {
            type: Array,
            default: []
        }
    },
    components: {},
    emits: ['update:modelValue'],
    data() {
        return {

        }
    },
    computed: {
        payload: {
            get() {return this.modelValue;},
            set(value) {this.$emit('update:modelValue', value)}
        }
    },
    methods: {
        addItem() {
            this.payload.push(null);
        },
        removeItem(index) {
            this.payload.splice(index, 1);
        }
    }
}
</script>

<style lang="scss">

</style>
