<template>
    <div class="border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="p-5 flex items-center justify-between">
            <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Team Management List</h5>
            <CustomButton @click="addTeam">
                Add Team
            </CustomButton>
        </div>
        <div class="grid grid-cols-8 gap-x-3 mb-2 px-5">
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-3">Name</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Queue Type</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs">Time Zone</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs">Industry</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs"></p>
        </div>
        <div class="border-t border-b h-64 overflow-y-auto"
             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
            <div>
                <div class="grid grid-cols-8 gap-x-3 border-b px-5 py-3"
                     v-for="team in visibleTeams" :key="team.id"
                     :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <p class="text-sm col-span-3 truncate">
                        {{ team.name }}
                    </p>
                    <p class="text-sm col-span-2 truncate">
                        {{ team.queue }}
                    </p>
                    <p class="text-sm truncate">
                        {{ team.timezone }}
                    </p>
                    <p class="text-sm truncate">
                        {{ team.industry_name }}
                    </p>
                    <ActionsHandle :dark-mode="darkMode" @edit="editTeam(team)" @delete="deleteTeam(team.id)" />
                </div>
            </div>
        </div>
        <div class="p-3"></div>
        <Modal :dark-mode="darkMode" :small="true" @confirm="editId > 0 ? updateTeam() : createTeam()" @close="toggleTeamManagementModal" close-text="Cancel" :confirm-text="editId > 0 ? 'Update Team' : 'Save & Add Team'" v-if="teamManagementModal">
            <template v-slot:header>
                <h4 class="text-xl font-medium">{{ editId > 0 ? 'Edit' : 'Add' }} Team</h4>
            </template>
            <template v-slot:content>
                <div class="px-6">
                    <div class="mb-6">
                        <CustomInput
                            v-model="newTeamName"
                            :placeholder="'Enter name'"
                            :label="'Team Name'"
                            :dark-mode="darkMode"
                        />
                    </div>
                    <div class="mb-5 grid grid-cols-4 gap-3">
                        <div class="col-span-2">
                            <p class="block pb-2 font-medium">Assign a queue</p>
                            <Dropdown v-model="selectedQueue" :options="queues" :dark-mode="darkMode" :selected="editQueue"/>
                        </div>
                        <div>
                            <p class="block pb-2 font-medium">Timezone</p>
                            <Dropdown v-model="selectedTimezone" :options="timezones" :dark-mode="darkMode" :selected="editTimezone"/>
                        </div>
                        <div>
                            <p class="block pb-2 font-medium">Industry</p>
                            <Dropdown v-model="selectedIndustry" :options="industries" :dark-mode="darkMode" :selected="editIndustry"/>
                        </div>
                    </div>
                    <div v-if="error !== null" class="my-4 font-bold text-red-400">{{ error }}</div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from "../../Shared/components/Modal";
import Dropdown from "../../Shared/components/Dropdown";
import ActionsHandle from "../../Shared/components/ActionsHandle";
import CustomButton from "../../Shared/components/CustomButton";
import CustomInput from "../../Shared/components/CustomInput";

export default {
    name: "TeamManagementList",
    components: {
        CustomButton, Modal, Dropdown, ActionsHandle, CustomInput
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        teams: {
            type: Array,
            default: () => []
        },
        queues: {
            type: Array,
            default: () => []
        },
        industries: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            teamManagementModal: false,
            timezones: [
                {id: -5, name: "Eastern"},
                {id: -6, name: "Central"},
                {id: -7, name: "Mountain"},
                {id: -8, name: "Pacific"},
            ],
            selectedTimezone: null,
            selectedQueue: null,
            selectedIndustry: null,
            newTeamName: null,
            editId: 0,
            editQueue: null,
            editTimezone: null,
            editIndustry: null,
            error: null
        }
    },
    computed: {
        visibleTeams: function() {
            return this.teams.filter((team) => {
                return team.id > 0;
            });
        }
    },
    methods: {
        toggleTeamManagementModal() {
            this.teamManagementModal = ! this.teamManagementModal;

            this.selectedTimezone = null;
            this.selectedQueue = null;
            this.selectedIndustry = null;
            this.editQueue = null;
            this.editTimezone = null;
            this.editIndustry = null;
            this.newTeamName = null;
            this.error = null;
        },

        addTeam() {
            this.editId = 0;

            this.toggleTeamManagementModal();
        },
        createTeam() {
            if(!this.newTeamName
            || !this.selectedIndustry
            || !this.selectedQueue
            || !this.selectedTimezone) {
                return this.error = "Please enter the required fields.";
            }

            this.$emit('save-team', {name: this.newTeamName, queue: this.selectedQueue, timezone: this.selectedTimezone, industry: this.selectedIndustry});
            this.teamManagementModal = false;
        },

        editTeam(team) {
            this.editId = team.id;

            this.toggleTeamManagementModal();

            this.selectedQueue = team.queue_id;
            this.editQueue = {
                id: team.queue_id,
                name: team.queue
            };

            this.selectedTimezone = team.timezone_value;
            this.editTimezone = {
                id: team.timezone_value,
                name: team.timezone
            };

            this.selectedIndustry = team.industry;
            this.editIndustry = {
                id: team.industry,
                name: team.industry[0].toUpperCase() + team.industry.slice(1)
            };

            this.newTeamName = team.name;
        },
        updateTeam() {
            if(!this.newTeamName
            || !this.selectedIndustry
            || !this.selectedQueue
            || !this.selectedTimezone
            || !this.editId) {
                return this.error = "Please enter the required fields.";
            }

            this.$emit('update-team', {id: this.editId, name: this.newTeamName, queue: this.selectedQueue, timezone: this.selectedTimezone, industry: this.selectedIndustry});
            this.toggleTeamManagementModal()
        },

        deleteTeam(id) {
            this.$emit('delete-team', id);
        }
    }
}
</script>

<style scoped>

</style>
