<template>
    <div class="grid grid-cols-2 mt-5">
        <div class="pl-1">
            <p class="uppercase font-semibold text-xs mb-1 mt-5"  :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                Company Status
            </p>

            <Dropdown v-model="companyStatus" :options="companyStatusOptions" :dark-mode="darkMode" placeholder="Select option"></Dropdown>
        </div>
    </div>
</template>

<script>
import Dropdown from "../../../Shared/components/Dropdown";
import ShortcodeInput from "../../../Shared/components/ShortcodeInput";
import ApiService from "../../services/api";
import HasTargetRelations from "../../mixins/has-target-relations";

export default {
    name: "CompanyStatusNode",
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        action: {
            type: Object,
            default: null
        }
    },
    components: {
        ShortcodeInput,
        Dropdown
    },
    data() {
        return {
            api: ApiService.make(),
            companyStatus: null,
            companyStatusOptions: [
                {id: 'active', name: 'active'},
                {id: 'inactive', name: 'inactive'},
                {id: 'registering', name: 'registering'},
                {id: 'presales', name: 'presales'},
                {id: 'trial', name: 'trial'},
                {id: 'collection', name: 'collection'},
                {id: 'archive', name: 'archive'},
                {id: 'hidden', name: 'hidden'},
                {id: 'suspended', name: 'suspended'},
                {id: 'pending', name: 'pending'},
                {id: 'archived', name: 'archived'}
            ]
        }
    },
    mixins: [HasTargetRelations],
    created() {
        if (this.action) {
            this.companyStatus = this.action.payload.company_status;
        }
    },
    watch: {
        action: function () {
            if (this.action) {
                this.companyStatus = this.action.payload.company_status;
            } else {
                this.companyStatus = null;
            }
        }
    },
    methods: {
        getDisplayName() {
            return "Company Status Node";
        },
        getPayload() {
            return {
                company_status: this.companyStatus,
            }
        },
    }
}
</script>

<style scoped>

</style>
