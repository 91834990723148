<template>
    <div class="border rounded-lg h-full"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <ContactsHeader :show-add-contact-button="showAddContactButton"
                        @show:modal="showContactAddModal"></ContactsHeader>
        <div class="border-t border-b overflow-y-auto"
             :class="[darkMode ? 'bg-dark-background border-dark-border' : 'bg-light-background  border-light-border', loading ? 'flex flex-col items-center justify-center' : '',tableHeight]">
            <div v-if="!loading">
                <div class="grid grid-cols-1 gap-x-3 border-b px-5" v-for="contact in contacts"
                     :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <div class="py-4">
                        <div class="flex items-center justify-between ">
                            <Contact :dark-mode="darkMode" :expanded-contacts="expandedContacts" :contact="contact"
                                     @toggle:expand="toggleExpandContact"></Contact>

                            <div class="flex items-center">
                                <ContactButtons :contact="contact" :has-edit-rights="hasEditRights"
                                                @edit:contact="editContact"></ContactButtons>
                            </div>

                        </div>
                        <div class="grid grid-cols-2 gap-3 px-5 pt-4" v-if="expandedContacts.includes(contact.id)">
                            <ContactDetails :dark-mode="darkMode" :contact="contact"></ContactDetails>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="loading">
                <loading-spinner :dark-mode="darkMode"></loading-spinner>
            </div>
        </div>
        <div class="p-3"></div>
        <CreateEditContactModal :company-id="companyId" :dark-mode="darkMode" :editing-contact="editingContact"
                                :show-modal="showModal"
                                @close:modal="closeContactAddModal"
                                @get:contacts="getContacts"></CreateEditContactModal>
    </div>
</template>

<script>
import SharedApiService from "../services/api";
import LoadingSpinner from "../components/LoadingSpinner";
import DispatchesGlobalEventsMixin from "../../../mixins/dispatches-global-events-mixin";
import Modal from "../components/Modal";
import Alert from "../components/Alert";
import ContactsHeader from "./Contacts/Header.vue";
import Contact from "./Contacts/Contact.vue";
import ContactButtons from "./Contacts/ContactButtons.vue";
import ContactDetails from "./Contacts/ContactDetails.vue";
import CreateEditContactModal from "./Contacts/CreateEditContactModal.vue";
import {mapActions, mapWritableState} from "pinia";
import {useContactsStore} from "../../../../stores/contacts.store";
import {validateContacts} from "../../../../modules/contacts/helpers";

export default {
    name: "CompanyContacts",
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        companyId: {
            type: Number,
            default: null
        },
        tableHeight: {
            type: String,
            default: 'h-100'
        },
        showAddContactButton: {
            type: Boolean,
            default: false,
        },
        hasEditRights: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        CreateEditContactModal,
        ContactDetails, ContactButtons, Contact, ContactsHeader, Modal, LoadingSpinner, Alert
    },
    mixins: [DispatchesGlobalEventsMixin],
    data() {
        return {
            api: SharedApiService.make(),
            expandedContacts: [],
            loading: false,
            editingContact: false,
            currentlyEditing: [],
            showModal: false,
            saveError: null
        }
    },
    created() {
        if (this.companyId) {
            this.getContacts();
        }
    },
    watch: {
        companyId(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getContacts();
            }
        },
    },
    methods: {
        editContact(contact) {
            this.showModal = true;
            this.editingContact = true;

            this.contact = contact;
        },
        /**
         * @param id
         */
        toggleExpandContact(id) {
            if (this.expandedContacts.includes(id))
                this.expandedContacts.splice(this.expandedContacts.indexOf(id), 1);
            else
                this.expandedContacts.push(id);
        },
        getContacts() {
            this.loading = true;
            this.api.getCompanyContacts(this.companyId).then(resp => {
                const contacts = resp?.data?.data?.contacts ?? [];

                try {
                    validateContacts(contacts);
                } catch (e) {
                    console.warn(e);
                }

                this.contacts = contacts;
            }).catch(err => {
                console.error(err);

                this.contacts = [];
            }).finally(() => this.loading = false);
        },
        showContactAddModal() {
            this.showModal = true;
        },
        closeContactAddModal() {
            this.showModal = false;
            this.editingContact = false;
            this.saveError = null;
            this.contact = {
                first_name: undefined,
                last_name: undefined,
                title: undefined,
                email: undefined,
                office_phone: undefined,
                cell_phone: undefined
            }
        },
    },
    computed: {
        ...mapWritableState(useContactsStore, [
            'contacts',
            'contact'
        ])
    }
}
</script>

<style scoped>

</style>
