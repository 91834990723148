import axios from 'axios';
export default class ApiService {
    constructor(baseUrl, baseEndpoint, version) {
        this.baseEndpoint = baseEndpoint;
        this.baseUrl = baseUrl;
        this.version = version;
    }
    axios() {
        const axiosConfig = {
            baseURL: `/${this.baseUrl}/v${this.version}/${this.baseEndpoint}`
        }
        return axios.create(axiosConfig);
    }
    static make() {
        return new ApiService('internal-api', 'reports-leads', 1);
    }
    getPaginatedLead(link, start_date = null, end_date = null) {
        if (!start_date || !end_date) {
            return this.axios().get(link);
        }
        return this.axios().get(link + `&start_date=${start_date}&end_date=${end_date}`);
    }
    getOverSoldLead(data) {
        return this.axios().get(`/oversold?page=${data.page}&start_date=${data.start_date}&end_date=${data.end_date}`);
    }
    getAllocatedLead(data) {
        return this.axios().get(`/allocated?page=${data.page}&start_date=${data.start_date}&end_date=${data.end_date}`);
    }
    getDeliveredLead(data) {
        return this.axios().get(`/delivered?page=${data.page}&start_date=${data.start_date}&end_date=${data.end_date}`);
    }
    getNoChargedLead(data) {
        return this.axios().get(`/no-charged?page=${data.page}`);
    }
}
