<template>
    <div class="border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="p-5 flex items-center justify-between">
            <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Users</h5>
            <!--                    TODO: 'Add User' button if needed-->
            <!--                    <button-->
            <!--                        class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5">-->
            <!--                        Add User-->
            <!--                    </button>-->
        </div>
        <div class="px-5 pb-5">
            <div class="w-48">
                <Dropdown :dark-mode="darkMode" :options="filterUsersOptions" v-model="selectedFilterUserOption"
                          :selected="selectedFilterUserOption"/>
            </div>
        </div>
        <div class="grid grid-cols-6 gap-x-3 mb-2 px-5">
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Name / Email</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs text-center">Status</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs text-center">Register Date</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs text-center">ID</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs"></p>
        </div>
        <div class="my-6 py-6" v-if="loading">
            <LoadingSpinner></LoadingSpinner>
        </div>
        <div class="border-t border-b h-80 overflow-y-auto"
             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-40 border-dark-border': darkMode}" v-else>
            <div>
                <div class="grid grid-cols-6 gap-x-3 border-b px-5 py-3 items-center"
                     v-for="user in filteredUsers" :key="user.id"
                     :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <div class="col-span-2 truncate">
                        <p class="text-sm">{{ user.name }}</p>
                        <p class="text-sm truncate text-slate-400">{{  user.email }}</p>
                    </div>
                    <div class="text-sm truncate text-center">
                        <div v-if="user.status === 1" class="px-5 inline-flex items-center py-1 text-sm font-semibold rounded-full border" :class="[darkMode ? ' border-green-400 text-green-400 bg-transparent' : 'border-transparent bg-green-150 text-green-400']">
                            Active
                        </div>
                        <div v-else class="px-5 inline-flex items-center py-1 text-sm font-semibold rounded-full border" :class="[darkMode ? ' border-red-400 text-red-400 bg-transparent' : 'border-transparent bg-red-100 text-red-400']">
                            Inactive
                        </div>
                    </div>
                    <p class="text-sm truncate text-center">
                        {{ $filters.dateFromTimestamp(user.date_registered) }}
                    </p>
                    <p class="text-sm truncate text-center">
                        {{user.id}}
                    </p>
                    <ActionsHandle v-if="hasEditRights" :dark-mode="darkMode" @edit="openEditUserModal(user)" @delete="openDeleteUserModal(user)" />
                </div>
            </div>
        </div>
        <div class="p-3"></div>
        <Modal
            :dark-mode="darkMode"
            v-if="editUserModal"
            @close="closeEditUserModal"
            :small="true"
            :confirm-text="confirmText"
            :close-text="'Cancel'"
            :disable-confirm="disableConfirm"
            @confirm="updateUser"
        >
            <template v-slot:header>
                <h4 class="text-xl font-medium">Edit User</h4>
            </template>
            <template v-slot:content>
                <div class="grid grid-cols-1 gap-3 mb-4">
                    <div>
                        <label class="font-medium mb-1 block">First Name</label>
                        <input class="w-full border rounded px-3 py-2 focus:outline-none focus:border focus:border-primary-500"
                               :class="{'text-grey-600 border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"
                               placeholder="First Name" v-model="user.first_name"
                               type="text">
                    </div>
                    <div>
                        <label class="font-medium mb-1 block">Last Name</label>
                        <input class="w-full border rounded px-3 py-2 focus:outline-none focus:border focus:border-primary-500"
                               :class="{'text-grey-600 border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"
                               placeholder="Last Name" v-model="user.last_name"
                               type="text">
                    </div>
                    <div>
                        <label class="font-medium mb-1 block">Mobile</label>
                        <input class="w-full border rounded px-3 py-2 focus:outline-none focus:border focus:border-primary-500"
                               :class="{'text-grey-600 border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"
                               placeholder="Cell Phone" v-model="user.cell_phone"
                               type="text">
                    </div>
                    <div>
                        <label class="font-medium mb-1 block">Phone</label>
                        <input class="w-full border rounded px-3 py-2 focus:outline-none focus:border focus:border-primary-500"
                               :class="{'text-grey-600 border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"
                               placeholder="Office Phone" v-model="user.office_phone"
                               type="text">
                    </div>
                    <div>
                        <label class="font-medium mb-1 block">Status</label>
                        <Dropdown :dark-mode="darkMode" :options="statusOptions" v-model="user.status"
                        :selected="user.status"/>
                    </div>
                </div>
            </template>
        </Modal>
        <Modal
            :dark-mode="darkMode"
            v-if="deleteUserModal"
            @close="closeDeleteUserModal"
            :small="true"
            :confirm-text="deletingUser ? 'Deleting...' : 'Delete'"
            :close-text="'Cancel'"
            @confirm="deleteUser"
            :disable-confirm="deletingUser"
        >
            <template v-slot:header>
                <h4 class="text-xl font-medium inline-flex items-center">
                    <svg class="mr-2 fill-current text-red-500" width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.8849 0.4905C10.5389 -0.1635 9.46294 -0.1635 9.11694 0.4905L0.116935 17.4905C0.0359187 17.6429 -0.00424573 17.8136 0.00035519 17.9861C0.00495611 18.1586 0.0541655 18.327 0.143189 18.4749C0.232212 18.6227 0.358012 18.7449 0.508333 18.8297C0.658653 18.9145 0.828366 18.9588 1.00094 18.9585H19.0009C19.1734 18.9589 19.343 18.9145 19.4932 18.8298C19.6434 18.7451 19.7691 18.6229 19.8581 18.4752C19.947 18.3274 19.9961 18.1591 20.0007 17.9867C20.0052 17.8144 19.965 17.6437 19.8839 17.4915L10.8849 0.4905ZM11.0009 15.9585H9.00094V13.9585H11.0009V15.9585ZM9.00094 11.9585V6.9585H11.0009L11.0019 11.9585H9.00094Z"/>
                    </svg>
                    Delete User</h4>
            </template>
            <template v-slot:content>
                Are you sure you want to delete this user?
            </template>
        </Modal>
        <alerts-container :dark-mode="darkMode" :text="error" v-if="error !== null" :alert-type="'error'"></alerts-container>
    </div>
</template>

<script>
import Dropdown from "../../Shared/components/Dropdown";
import ActionsHandle from "../../Shared/components/ActionsHandle";
import Modal from "../components/Modal";
import SharedApiService from "../services/api";
import LoadingSpinner from "../components/LoadingSpinner";
import AlertsContainer from "../components/AlertsContainer";

export default {
    name: "CompaniesUsers",
    components: {Modal, ActionsHandle, Dropdown, LoadingSpinner, AlertsContainer},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        hasEditRights: {
            type: Boolean,
            default: false,
        },
        companyId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            api: SharedApiService.make(),
            statusOptions: [
                { id: 1, name: 'Active'},
                { id: 0, name: 'Inactive'}
            ],
            selectedStatusOption: 1,
            selectedFilterUserOption: 0,
            filterUsersOptions: [
                {id: 0, name: 'All'},
                {id: 1, name: 'Active'},
                {id: 2, name: 'Inactive'}
            ],
            users: [],
            user: {
                userid: undefined,
                first_name: undefined,
                last_name: '',
                email: '',
                status: 1,
                cell_phone: undefined,
                office_phone: undefined
            },
            deleteUserId: null,
            editUserModal: false,
            deleteUserModal: false,
            loading: false,
            savingUser: false,
            deletingUser: false,
            error: null
        }
    },
    created() {
        if (this.companyId) this.getCompanyUsers();
    },
    computed: {
        filteredUsers: function () {
            switch (this.selectedFilterUserOption) {
                case 1:
                    return this.users.filter(user => user.status === 1);
                case 2:
                    return this.users.filter(user => user.status === 0);
                case 0:
                default:
                    return {...this.users};
            }
        },
        confirmText: function () {
            if (this.savingUser) return 'Saving...';

            return 'Update';
        },
        disableConfirm: function () {
            return this.savingUser || !this.user.first_name || (!this.user.cell_phone && !this.user.office_phone && !this.user.email);
        }
    },
    methods: {
        getCompanyUsers() {
            this.loading = true;
            this.api.getCompanyUsers(this.companyId)
                .then(resp => this.users = resp.data.data.users)
                .finally(() => this.loading = false);
        },
        openEditUserModal(user) {
            this.user.userid = user.id;
            this.user.first_name = user.first_name;
            this.user.last_name = user.last_name;
            this.user.status = user.status;
            this.user.cell_phone = user.cell_phone;
            this.user.office_phone = user.office_phone;
            this.user.email = user.email;
            this.editUserModal = true;
        },
        closeEditUserModal() {
            this.editUserModal = false;
            this.user = {
                userid: undefined,
                first_name: undefined,
                last_name: undefined,
                status: 1,
                cell_phone: undefined,
                office_phone: undefined
            };

            this.clearError();
        },
        updateUser() {
            this.savingUser = true;

            this.clearError();
            this.api.updateCompanyUser(this.companyId, this.user.userid, this.user)
                .then(() => {
                    this.closeEditUserModal();
                    this.getCompanyUsers();
                })
                .catch(e => this.error = e.response.data.message)
                .finally(() => this.savingUser = false);
        },
        deleteUser() {
            this.deletingUser = true;

            this.clearError();
            this.api.deleteCompanyUser(this.companyId, this.deleteUserId)
                .then(() => {
                    this.getCompanyUsers();
                    this.closeDeleteUserModal();
                })
                .catch(e => this.error = e.response.data.message)
                .finally(() => this.deletingUser = false);
        },
        openDeleteUserModal(user) {
            this.deleteUserModal = true;
            this.deleteUserId = user.id;
        },
        closeDeleteUserModal() {
            this.deleteUserModal = false;
            this.deleteUserId = null;
            this.clearError();
        },
        clearError() {
            this.error = null;
        }
    }
}
</script>

<style scoped>

</style>
