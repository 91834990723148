<template>
    <div class="main-layout font-body">
        <alerts-container :text="alertText" :alert-type="alertType" v-if="alertActive" :dark-mode="darkMode"/>
        <company-details :dark-mode="darkMode" :open="companyDetailsOpen" :loading="companyDetailsLoading" :companies="companyDetails"></company-details>
        <div class="w-full">
            <div class="w-full flex-auto px-6 py-5 relative" :class="{'bg-light-background': !darkMode, 'bg-dark-background': darkMode}">
                <div class="border rounded-lg px-8 pb-8 "
                     :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border text-grey-120': darkMode}">
                    <div class="flex items-center justify-between flex-wrap pt-6 pb-4">
                        <div class="flex justify-between items-center w-full">
                            <div class="inline-flex items-center" :class="{'text-grey-120': darkMode}">
                                <a href="/reports" class="text-xl font-medium text-grey-300 leading-none hover:text-grey-400 transition duration-200 cursor-pointer">Reports</a>
                                <h3 class="text-xl inline-flex font-medium leading-none items-center" :class="{'text-black': !darkMode, 'text-grey-120': darkMode}">
                                    <svg class="mx-4 fill-current" width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.327334 0.260067C0.735992 -0.11144 1.36844 -0.0813234 1.73995 0.327334L6.73995 5.82733C7.08669 6.20876 7.08669 6.79126 6.73995 7.17268L1.73995 12.6727C1.36844 13.0813 0.735992 13.1115 0.327334 12.7399C-0.0813234 12.3684 -0.11144 11.736 0.260067 11.3273L4.64855 6.50001L0.260067 1.67268C-0.11144 1.26402 -0.0813234 0.631574 0.327334 0.260067Z"/>
                                    </svg>
                                    Available Budget Report
                                </h3>
                            </div>
                        </div>
                        <inputs :dark-mode="darkMode" @update-data="updateData" @update-report-mode="updateReportMode" @update-loading="updateLoading" :api-service="apiService" :report-mode="reportMode"></inputs>
                    </div>
                    <div class="text-lg" v-if="!loading">
                        <div v-if="reportMode === 'single'" class="inline-flex items-center font-medium gap-5 divide-x" :class="[!darkMode ? 'border-grey-200' : 'divide-dark-175']">
                            <p :class="[!darkMode ? 'text-grey-400 hover:text-grey-900' : 'text-grey-300 hover:text-white']" class="cursor-pointer" @click="showCompanyDetails(data.summary.company_ids)">Active Companies: <span class="text-blue-550 font-semibold">{{ data.summary.company_ids ? data.summary.company_ids.length : 0 }}</span></p>
                            <p :class="[!darkMode ? 'text-grey-400 hover:text-grey-900' : 'text-grey-300 hover:text-white']" class="cursor-pointer pl-5" @click="showCompanyDetails(data.summary.avail_budget_company_ids)">Available Budget Companies: <span class="text-blue-550 font-semibold">{{ data.summary.avail_budget_company_ids ? data.summary.avail_budget_company_ids.length : 0 }}</span></p>
                            <p :class="[!darkMode ? 'text-grey-400 hover:text-grey-900' : 'text-grey-300 hover:text-white']" class="cursor-pointer pl-5" @click="showCompanyDetails(data.summary.no_limit_company_ids)">No Limit Companies: <span class="text-blue-550 font-semibold">{{ data.summary.no_limit_company_ids ? data.summary.no_limit_company_ids.length : 0 }}</span></p>
                        </div>
                        <div v-else class="inline-flex items-center font-medium gap-5 divide-x" :class="[!darkMode ? 'border-grey-200' : 'divide-dark-175']">
                            <p :class="[!darkMode ? 'text-grey-400 hover:text-grey-900' : 'text-grey-300 hover:text-white']" class="cursor-pointer" @click="showCompanyDetails(data.summary.new_company_ids)">New Active Companies: <span class="text-blue-550 font-semibold">{{ data.summary.new_company_count }}</span></p>
                            <p :class="[!darkMode ? 'text-grey-400 hover:text-grey-900' : 'text-grey-300 hover:text-white']" class="cursor-pointer pl-5" @click="showCompanyDetails(data.summary.lost_company_ids)">Lost Active Companies: <span class="text-blue-550 font-semibold">{{ data.summary.lost_company_count }}</span></p>
                            <p :class="[!darkMode ? 'text-grey-400 hover:text-grey-900' : 'text-grey-300 hover:text-white']" class="cursor-pointer pl-5" @click="showCompanyDetails(data.summary.new_avail_budget_company_ids)">New Available Budget Companies: <span class="text-blue-550 font-semibold">{{ data.summary.new_avail_budget_company_count }}</span></p>
                            <p :class="[!darkMode ? 'text-grey-400 hover:text-grey-900' : 'text-grey-300 hover:text-white']" class="cursor-pointer pl-5" @click="showCompanyDetails(data.summary.lost_avail_budget_company_ids)">Lost Available Budget Companies: <span class="text-blue-550 font-semibold">{{ data.summary.lost_avail_budget_company_count }}</span></p>
                            <p :class="[!darkMode ? 'text-grey-400 hover:text-grey-900' : 'text-grey-300 hover:text-white']" class="cursor-pointer pl-5" @click="showCompanyDetails(data.summary.new_no_limit_company_ids)">New No Limit Companies: <span class="text-blue-550 font-semibold">{{ data.summary.new_no_limit_company_count }}</span></p>
                            <p :class="[!darkMode ? 'text-grey-400 hover:text-grey-900' : 'text-grey-300 hover:text-white']" class="cursor-pointer pl-5" @click="showCompanyDetails(data.summary.lost_no_limit_company_ids)">Lost No Limit Companies: <span class="text-blue-550 font-semibold">{{ data.summary.lost_no_limit_company_count }}</span></p>
                        </div>
                    </div>
                    <loading-spinner v-if="loading" :dark-mode="darkMode"/>
                    <available-budget-table :dark-mode="darkMode" @show-company-details="showCompanyDetails" @activate-alert="activateAlert" v-if="!loading" :states="data.states" :report-mode="reportMode"></available-budget-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AvailableBudgetTable from "./components/AvailableBudgetTable";
import AlertsContainer from "../../Shared/components/AlertsContainer";
import Inputs from "./components/Inputs";
import LoadingSpinner from "../../Shared/components/LoadingSpinner";
import CompanyDetails from "./components/CompanyDetails";
import ApiService from "./services/api/api";

export default {
    name: "HistoricalAvailableBudgetsReport",
    components: {
        Inputs,
        AvailableBudgetTable,
        AlertsContainer,
        LoadingSpinner,
        CompanyDetails
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
    },
    data: function() {
        return {
            data: null,
            loading: true,
            companyDetailsLoading: false,
            alertActive: false,
            alertType: '',
            alertText: '',
            companyDetailsOpen: false,
            apiService: null,
            companyDetails: null,
            reportMode: 'single'
        }
    },
    created() {
        this.apiService = ApiService.make();
    },
    methods: {
        activateAlert(type, text) {
            this.alertActive = true;
            this.alertType = type;
            this.alertText = text;
            setTimeout(()=>{
                this.alertActive = false
            },6000)
        },
        updateData(data) {
            this.data = data;
        },
        updateLoading(value) {
            this.loading = value;
        },
        updateReportMode(value) {
            this.reportMode = value;
        },
        showCompanyDetails(companyIds) {
            this.companyDetailsLoading = true;
            this.companyDetailsOpen = true;

            this.apiService.getCompanyDetails({
               'company_ids':  companyIds
            }).then(resp => {
                this.companyDetails = resp.data.data;
            }).catch(e => {
                console.log(e);
                this.companyDetails = null;
            }).finally(() => this.companyDetailsLoading = false);
        }
    }
}
</script>

<style scoped>

</style>
