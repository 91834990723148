<template>
    <div class="grid grid-cols-2 mt-5">
        <div class="">
            <p class="uppercase font-semibold text-xs mb-1" :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                Task type
            </p>
            <Dropdown placeholder="Select option" v-model="taskType" :options="taskTypes" :dark-mode="darkMode"></Dropdown>
        </div>
        <div class="w-24 ml-3">
            <p class="uppercase font-semibold text-xs mb-1" :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                Priority
            </p>
            <input type="number" class="w-full border rounded focus:outline-none focus:border focus:border-primary-500 py-2" placeholder="0" min="0" max="4"
                   :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}" v-model="priority"/>
        </div>
        <div class="col-span-2 mt-5">
            <p class="uppercase font-semibold text-xs mb-1" :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                Task name
            </p>
            <shortcode-input :dark-mode="darkMode" v-model="taskName" placeholder="Contact this company..."></shortcode-input>
        </div>

        <div class="pr-2">
            <p class="uppercase font-semibold text-xs mb-1 mt-5" :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                Assign To
            </p>

            <Dropdown placeholder="Select option" v-model="targetRelation" :options="targetRelations" :dark-mode="darkMode" :selected="targetRelation" :key="targetRelation"></Dropdown>
        </div>

        <div class="pl-1">
            <p class="uppercase font-semibold text-xs mb-1 mt-5"  :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                Schedule In
            </p>

            <Dropdown v-model="delay" :options="delayOptions" :dark-mode="darkMode" placeholder="Select option"></Dropdown>
        </div>

        <div class="pl-1">
            <p class="uppercase font-semibold text-xs mb-1 mt-5"  :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                Rescheduling
            </p>

            <Dropdown v-model="reschedule" :options="rescheduleOptions" :dark-mode="darkMode" placeholder="Select option" :selected="reschedule"></Dropdown>
        </div>

        <div class="pl-1">
            <p class="uppercase font-semibold text-xs mb-1 mt-5"  :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                Inherit Task Notes?
            </p>

            <Dropdown v-model="inheritTaskNotes" :options="inheritOptions" :dark-mode="darkMode" placeholder="Select option" :selected="inheritTaskNotes"></Dropdown>
        </div>

        <div class="pl-1">
            <p class="uppercase font-semibold text-xs mb-1 mt-5"  :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                Task Category
            </p>

            <Dropdown v-model="taskCategory" :options="taskCategories" :dark-mode="darkMode" placeholder="Select Category" :selected="taskCategory"></Dropdown>
        </div>

        <div class="col-span-2 mt-5">
            <p class="uppercase font-semibold text-xs mb-1" :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                Incomplete Notifiers
            </p>

            <notifier-selections
                :dropdown-options="templates"
                :dark-mode="darkMode"
                v-model="notifiers"></notifier-selections>
        </div>
    </div>
</template>

<script>
import Dropdown from "../../../Shared/components/Dropdown";
import ShortcodeInput from "../../../Shared/components/ShortcodeInput";
import ApiService from "../../services/api";
import HasTargetRelations from "../../mixins/has-target-relations";
import NotifierSelections from "../ResultTypes/NotifierSelections";

export default {
    name: "ScheduleTask",
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        action: {
            type: Object,
            default: null
        }
    },
    components: {
        NotifierSelections,
        ShortcodeInput,
        Dropdown
    },
    data() {
        return {
            taskTypes: [],
            api: ApiService.make(),
            priority: 1,
            taskName: '',
            taskType: null,
            subject: '',
            targetRelation: 'sales-manager',
            targetType: 'staff',
            delay: null,
            reschedule: 1,
            inheritTaskNotes: 0,
            inheritOptions: [
                {id: 0, name: 'No'},
                {id: 1, name: 'Yes'},
            ],
            notifiers: [],
            templates: [],
            delayOptions: [
                {id: 30, name: '30 Minutes'},
                {id: 60, name: '1 Hour'},
                {id: 1440, name: '1 Day'},
                {id: 2880, name: '2 Days'},
                {id: 4320, name: '3 Days'},
                {id: 5760, name: '4 Days'},
                {id: 7200, name: '5 Days'},
                {id: 8640, name: '6 Days'},
                {id: 10080, name: '1 Week'},
                {id: 20160, name: '2 Weeks'},
                {id: 43800, name: '1 Month'},
            ],
            rescheduleOptions: [
                {id: 1, name: 'enable'},
                {id: 0, name: 'disable'}
            ],
            taskCategories: [],
            taskCategory: 1
        }
    },
    mixins: [HasTargetRelations],
    created() {
        if (this.action) {
            this.taskType       = this.action.payload.task_type;
            this.taskCategory   = this.action.payload.task_category;
            this.priority       = this.action.payload.priority;
            this.notifiers      = this.action.payload.notifiers;
            this.taskName       = this.action.display_name;
            this.delay          = this.action.payload.delay;
            this.reschedule     = this.action.payload.reschedule;
            this.targetRelation = this.action.payload.targets[0]?.target_relation;
            this.inheritTaskNotes   = this.action.payload.inherit_task_notes;
        }

        this.getTaskTypes();
        this.getTaskCategories();
        this.loadTemplates();
    },
    watch: {
        action: function () {
            if (this.action) {
                this.taskType       = this.action.payload.task_type;
                this.taskCategory   = this.action.payload.task_category;
                this.priority       = this.action.payload.priority;
                this.notifiers      = this.action.payload.notifiers;
                this.taskName       = this.action.display_name;
                this.delay          = this.action.payload.delay;
                this.reschedule     = this.action.payload.reschedule;
                this.targetRelation = this.action.payload.targets[0]?.target_relation;
                this.inheritTaskNotes   = this.action.payload.inherit_task_notes;
            } else {
                this.taskType       = null;
                this.priority       = 1;
                this.taskName       = '';
                this.notifiers      = [];
                this.delay          = null;
                this.reschedule     = 1;
                this.targetRelation = 'sales-manager';
                this.inheritTaskNotes = 0;
            }
        }
    },
    methods: {
        getDisplayName() {
            return this.taskName;
        },
        getPayload() {
            return {
                task_type: this.taskType,
                task_category: this.taskCategory,
                task_name: this.taskName,
                priority: this.priority,
                delay: this.delay,
                reschedule: this.reschedule,
                notifiers: this.notifiers,
                targets: [{
                    target_type: this.targetType,
                    target_relation: this.targetRelation
                }],
                inherit_task_notes: this.inheritTaskNotes
            }
        },
        getTaskTypes() {
            this.api.getTaskTypes().then(resp => {
                this.taskTypes = resp.data.data.task_types;
            });
        },
        loadTemplates() {
            this.api.getWorkflows(0).then(resp => {
                this.templates = resp.data.data.workflows;
            });
        },
        getTaskCategories() {
            this.api.getTaskCategories().then(resp => this.taskCategories = resp.data.data.categories);
        }
    }
}
</script>

<style scoped>

</style>
