import {BaseApiService} from "./base";

class DummyApiService extends BaseApiService {
    constructor(delay = 150) {
        super("DummyApiService");

        this.delay = delay;
    }

    _makeResponse(data) {
        return new Promise((resolve, reject) => {
           setTimeout(() => resolve({data: {data}}), this.delay);
        });
    }

    getRecentNotifications() {
        return this._makeResponse({result: true, notifications: []});
    }

    markNotificationAsRead(id, type) {
        return this._makeResponse({result: true});
    }

    getBouncedLeads() {
        return this._makeResponse({"status":true,"leads":[{"lead_id":1,"reason":"New York Power Solutions: Error on sending alert to installer [8446]: The campaign has no enabled contact or CRM integration delivery method"}]});
    }

    getQueues() {
        return this._makeResponse({result: true});
    }

    getManagementQueue(id) {
        return this._makeResponse({result: true});
    }

    createQueue(name, status) {
        return this._makeResponse({result: true});
    }

    updateQueue(id, name, status) {
        return this._makeResponse({result: true});
    }

    deleteQueue(id) {
        return this._makeResponse({result: true});
    }

    getTeams() {
        return this._makeResponse({result: true});
    }

    getTeam(id) {
        return this._makeResponse({result: true});
    }

    createTeam(name, queueId, offset) {
        return this._makeResponse({result: true});
    }

    updateTeam(id, name, queueId, offset) {
        return this._makeResponse({result: true});
    }

    deleteTeam(id) {
        return this._makeResponse({result: true});
    }

    getProcessors() {
        return this._makeResponse({result: true});
    }

    getProcessor(id) {
        return this._makeResponse({result: true});
    }

    createProcessor(userId, teamId, phoneId) {
        return this._makeResponse({result: true});
    }

    updateProcessor(id, userId, teamId, phoneId) {
        return this._makeResponse({result: true});
    }

    deleteProcessor(id) {
        return this._makeResponse({result: true});
    }

    getNextLead(id = null) {
        return this._makeResponse({
            status: true,
            lead: {
                basic: {
                    id: 123123,
                    name: 'Evan Nicoles',
                    email: 'evan@gmail.com',
                    address: {
                        street: '172 Asbury Ave',
                        city: 'Freehold',
                        state_abbr: 'NJ',
                        zip_code: '07728',
                        full_formatted: '172 Asbury Ave, Freehold, NJ 07728',
                        latitude: '40.736652220626',
                        longitude: '-74.602822351555'
                    },
                    utility: {
                        name: 'Jersey Central Power & Light',
                        abbr: 'JCP&L',
                        id: 1682
                    },
                    electric_cost: 150,
                    own_property: true,
                    date_added: '03/08/2022 19:32:59 EST',
                    phone: '732-232-2922',
                    has_communicated: false,
                    phone_verification_status: true,
                    companies_requested: 2,
                    appointments_requested: '04/04/2022 Afternoon',
                    best_time_to_contact: 'Weekday Afternoon',
                    classification: "Verified",
                    status: "Initial",
                    status_reason: "",
                    review_reason: "",
                    industry: "Solar",
                    type: "Residential"
                },
                related_activity: {
                    ip_address: '192.168.1.1',
                    email: 'evan@gmail.com',
                    email_registered: true,
                    user_id: 193,
                    leads: [
                        {
                            id: 7584,
                            date: 'March 10, 2020',
                            status: 'allocated',
                            is_delivered: true,
                            name: 'Joe Frazier',
                            source: 'solar-estimate.org'
                        },
                        {
                            id: 1204,
                            date: 'March 10, 2017',
                            status: 'cancelled',
                            is_delivered: false,
                            name: 'No Way',
                            source: 'solarreviews.com'
                        },
                    ],
                    reviews: [
                        {
                            id: 407,
                            date: 'March 2, 2020',
                            company: 'SunRun'
                        },
                        {
                            id: 410,
                            date: 'March 3, 2020',
                            company: 'Enphase'
                        }
                    ]
                },
                lead_verification: {
                    phone: {
                        is_valid: true,
                        name_match: true,
                        subscriber_name: 'Evan Nicoles',
                        address_match: null,
                        line_type: '',
                        carrier: 'Verizon',
                        is_commercial: false,
                        is_prepaid: false,
                        warnings: [
                            'warning 1',
                            'warning 2',

                        ]
                    },
                    address: {
                        is_valid: true,
                        name_match: true,
                        resident_name: 'Evan Nicoles',
                        type: 'Residential',
                        is_commercial: false,
                        is_forwarder: false,
                        warnings: [
                            'warning 1',
                            'warning 2',
                        ]
                    },
                    email: {
                        is_valid: true,
                        name_match: true,
                        registered_name: 'Evan Nicoles',
                        age: '~1358 days',
                        is_autogenerated: false,
                        is_disposable: false,
                        warnings: [
                            'warning 1',
                            'warning 2',
                        ]
                    },
                    ip: {
                        is_valid: true,
                        name_match: true,
                        geolocation: 'Manville, New Jersey, 08835',
                        address_distance: '~13 miles',
                        phone_distance: '~22 miles',
                        is_proxy: false,
                        warnings: [
                            'warning 1',
                            'warning 2',
                        ]
                    },
                    confidence: 89
                }
            }
        });
    }

    cancelLead(id, reason, publicComment) {
        return this._makeResponse({result: true});
    }

    markLeadAsPendingReview(id, reason) {
        return this._makeResponse({result: true});
    }

    markLeadAsUnderReview(id, reason, publicComment) {
        return this._makeResponse({result: true});
    }

    approveLead(id, reason, bestTimeToContact, publicComment) {
        return this._makeResponse({result: true});
    }

    updateBasicInfo(id, data) {
        return this._makeResponse({
            status: true,
            lead: {
                basic: {
                    id: 123123,
                    name: 'Evan Nicoles',
                    email: 'evan@gmail.com',
                    address: {
                        street: '172 Asbury Ave',
                        city: 'Freehold',
                        state_abbr: 'NJ',
                        zip_code: '07728',
                        full_formatted: '172 Asbury Ave, Freehold, NJ 07728',
                        latitude: '40.736652220626',
                        longitude: '-74.602822351555'
                    },
                    utility: {
                        name: 'Jersey Central Power & Light',
                        abbr: 'JCP&L',
                        id: 1682
                    },
                    electric_cost: 150,
                    own_property: true,
                    date_added: '03/08/2022 19:32:59 EST',
                    phone: '732-232-2922',
                    phone_verification_status: true,
                    companies_requested: 2,
                    appointments_requested: '04/04/2022 Afternoon',
                    best_time_to_contact: 'Weekday Afternoon',
                    classification: "Verified",
                    status: "Initial",
                    status_reason: "",
                    review_reason: "",
                    industry: "Solar",
                    type: "Residential"
                },
                related_activity: {
                    ip_address: '192.168.1.1',
                    email: 'evan@gmail.com',
                    email_registered: true,
                    user_id: 193,
                    leads: [
                        {
                            id: 7584,
                            date: 'March 10, 2020',
                            status: 'allocated',
                            is_delivered: true,
                            name: 'Joe Frazier',
                            source: 'solar-estimate.org'
                        },
                        {
                            id: 1204,
                            date: 'March 10, 2017',
                            status: 'cancelled',
                            is_delivered: false,
                            name: 'No Way',
                            source: 'solarreviews.com'
                        },
                    ],
                    reviews: [
                        {
                            id: 407,
                            date: 'March 2, 2020',
                            company: 'SunRun'
                        },
                        {
                            id: 410,
                            date: 'March 3, 2020',
                            company: 'Enphase'
                        }
                    ]
                },
                lead_verification: {
                    phone: {
                        is_valid: true,
                        name_match: true,
                        subscriber_name: 'Evan Nicoles',
                        address_match: null,
                        line_type: 'Mobile',
                        carrier: 'Verizon',
                        is_commercial: false,
                        is_prepaid: false,
                        warnings: [
                            'warning 1',
                            'warning 2',

                        ]
                    },
                    address: {
                        is_valid: true,
                        name_match: true,
                        resident_name: 'Evan Nicoles',
                        type: 'Residential',
                        is_commercial: false,
                        is_forwarder: false,
                        warnings: [
                            'warning 1',
                            'warning 2',
                        ]
                    },
                    email: {
                        is_valid: true,
                        name_match: true,
                        registered_name: 'Evan Nicoles',
                        age: '~1358 days',
                        is_autogenerated: false,
                        is_disposable: false,
                        warnings: [
                            'warning 1',
                            'warning 2',
                        ]
                    },
                    ip: {
                        is_valid: true,
                        name_match: true,
                        geolocation: 'Manville, New Jersey, 08835',
                        address_distance: '~13 miles',
                        phone_distance: '~22 miles',
                        is_proxy: false,
                        warnings: [
                            'warning 1',
                            'warning 2',
                        ]
                    },
                    confidence: 89
                }
            }
        });
    }

    heartbeat(id) {
        return this._makeResponse({result: true});
    }

    getQueue() {
        return this._makeResponse({status: true, queue: "initial", review_time: 10});
    }

    getStatistics() {
        return this._makeResponse({result: true, "statistics":{"solar": {"initial":208,"pending_review":8,"under_review":3}, "roofing": {"initial":208,"pending_review":8,"under_review":3},"cancelled":32,"no_companies":4,"allocated":190}});
    }

    getIndustries() {
        return this._makeResponse({result: true});
    }

    lookupLead(number) {
        return this._makeResponse({status: true, lead: {name: "Evan Nicoles", lead_id: 123456, communication_id: 123}});
    }

    createOutboundCall(leadId, userPhoneNumber, otherNumber, ringCentralReference) {
        return this._makeResponse({result: true});
    }

    updateOutboundCall(communicationId, callComplete, result) {
        return this._makeResponse({result: true});
    }

    updateInboundCall(communicationId, callComplete) {
        return this._makeResponse({result: true});
    }

    getSMSHistory() {
        return this._makeResponse({result: true});
    }

    createOutboundSMS(leadId, body) {
        return this._makeResponse({result: true});
    }

    getProcessingHistory() {
        return this._makeResponse({result: true, history: {id: 1, lead_id: 1, queue: 'initial', action: 'Allocated', created_time:'3/9/2005 12:32 PDT'}});
    }

    getGlobalConfigs() {
        return this._makeResponse({
            result: true,
            configs: {
                id:1,
                time_zone_opening_delay_in_minutes: 120,
                recency_threshold_in_seconds: 150,
                minimum_review_time: 10,
                lead_processable_delay_seconds: 60,
                check_next_lead_interval_seconds: 60,
                lead_processing_time_zone_configurations: [
                    {
                        "standard_utc_offset": -5,
                        "name": "Eastern",
                        "local_open_hour": 8,
                        "local_close_hour": 20
                    },
                    {
                        "standard_utc_offset": -6,
                        "name": "Central",
                        "local_open_hour": 8,
                        "local_close_hour": 20
                    },
                    {
                        "standard_utc_offset": -7,
                        "name": "Mountain",
                        "local_open_hour": 8,
                        "local_close_hour": 20
                    },
                    {
                        "standard_utc_offset": -8,
                        "name": "Pacific",
                        "local_open_hour": 8,
                        "local_close_hour": 20
                    },
                    {
                        "standard_utc_offset": -9,
                        "name": "Alaska",
                        "local_open_hour": 8,
                        "local_close_hour": 20
                    },
                    {
                        "standard_utc_offset": -10,
                        "name": "Hawaii",
                        "local_open_hour": 8,
                        "local_close_hour": 20
                    }
                ],
                last_lead_created_interval_min: 5,
                created_at: "2022-03-31 15:00:32",
                updated_at: "2022-03-31 08:01:04",
            }
        });
    }

    saveGlobalConfigs(
        timeZoneOpeningDelay,
        recencyThreshold,
        minimumReviewTime,
        leadProcessableDelay,
        checkNextLeadInterval,
        timeZoneConfigurations,
        lastLeadCreatedInterval
    )
    {
        return this._makeResponse({result: true});
    }

    getCommunicationToken() {
        return this._makeResponse({status: true, token: "abc-123-def-456", number: "5556665656"});
    }

    getNextProduct() {
        return this._makeResponse({
            status: true,
            product: {
                basic: {
                    id: 303
                }
            }
        });
    }
}

export { DummyApiService };

