<template>
    <div class="grid grid-cols-1 gap-4 px-10 py-5">
        <Leads :company-id="companyId" :dark-mode="darkMode" :show-filters="true"></Leads>
    </div>
</template>

<script>
import Leads from "../../Shared/modules/Leads";
export default {
    name: "LeadsPage",
    components: {
        Leads
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        companyId: {
            type: Number,
            default: null
        }
    },
}
</script>

<style scoped>

</style>
