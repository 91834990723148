<template>
    <div class="border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="p-5">
            <h5 class="text-primary-500 text-sm uppercase font-semibold pb-3 leading-tight">Other Leads</h5>
            <div class="flex flex-wrap mb-5">
                <div class="flex items-center mr-8">
                    <div class="flex-shrink-0 w-3 h-3 text-sm mr-1 bg-primary-500 rounded-full"
                    ></div>
                    <p class="text-sm">Allocated</p>
                </div>
                <div class="flex items-center mr-8">
                    <div class="flex-shrink-0 w-3 h-3 text-sm mr-1 bg-red-300 rounded-full"></div>
                    <p class="text-sm">Cancelled</p>
                </div>
                <div class="flex items-center">
                    <div class="flex-shrink-0 w-3 h-3 text-sm mr-1 bg-blue-300 rounded-full"></div>
                    <p class="text-sm">No Companies</p>
                </div>
            </div>

            <div class="w-full" v-if="statistics.cancelled">
                <div class="flex items-center mb-4">
                    <div class="p-2 h-10 rounded-r"
                         :class="{'bg-primary-500' : !darkMode, 'border-r-2 border-primary-500 bg-dark-background': darkMode}"
                         :style="{width: calculatePercentage(statistics.allocated) + '%'}">
                        <div class="flex justify-end items-center h-full mr-2 text-white font-bold" v-if="calculatePercentage(statistics.allocated) > 10">{{ Math.round(calculatePercentage(statistics.allocated)) }}%</div>
                    </div>
                    <p class="ml-2 font-bold text-blue-550">
                        {{ statistics.allocated }}
                    </p>
                </div>

                <div class="flex items-center mb-4">
                    <div class="p-2 h-10 rounded-r"
                         :class="{'bg-red-300' : !darkMode, 'border-r-2 border-red-300 bg-dark-background': darkMode}"
                         :style="{width: calculatePercentage(statistics.cancelled) + '%'}">
                        <div class="flex justify-end items-center h-full mr-2 text-white font-bold" v-if="calculatePercentage(statistics.cancelled) > 10">{{ Math.round(calculatePercentage(statistics.cancelled)) }}%</div>
                    </div>
                    <p class="ml-2 font-bold text-red-300">
                        {{ statistics.cancelled }}
                    </p>
                </div>

                <div class="flex items-center">
                    <div class="p-2 h-10 rounded-r"
                         :class="{'bg-blue-300' : !darkMode, 'border-r-2 border-blue-300 bg-dark-background': darkMode}"
                         :style="{width: calculatePercentage(statistics.no_companies) + '%'}">
                        <div class="flex justify-end items-center h-full mr-2 text-white font-bold" v-if="calculatePercentage(statistics.no_companies) > 10">{{ Math.round(calculatePercentage(statistics.no_companies)) }}%</div>
                    </div>
                    <p class="ml-2 font-bold text-blue-300">
                        {{ statistics.no_companies }}
                    </p>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "UnavailableLeadsChart",
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        statistics: {
            default: () => ({})
        }
    },
    methods: {
        calculatePercentage(value) {
            const total = this.statistics.cancelled + this.statistics.no_companies + this.statistics.allocated;

            if(total === 0)
                return 100;

            return (value / total) * 100;
        }
    }
}
</script>

<style scoped>

</style>
