<template>
    <div class="w-full flex-auto pt-3 relative"
         :class="{'bg-light-background': !darkMode, 'bg-dark-background': darkMode}">
        <div class="px-10" :class="[darkMode ? 'text-white' : 'text-slate-900']">
            <div class="flex items-center justify-between flex-wrap py-4">
                <div class="flex items-center py-1">
                    <h3 class="text-xl font-medium pb-0 mr-4">Roles & Permissions Management</h3>
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <roles :dark-mode="darkMode" :roles="roles" :permissions="permissions" :saving="savingRole" :error="roleError" @create-role="createRole" @update-role="updateRole"></roles>
                </div>
                <div>
                    <permissions :dark-mode="darkMode" :permissions="permissions" @create-permission="createPermission" @update-permission="updatePermission" :saving="savingPermission"
                                 :error="permissionError"></permissions>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Roles from "./Components/Roles";
import Permissions from "./Components/Permissions";
import SharedApiService from "../Shared/services/api";

export default {
    name: "RolesPermissionsManagement",
    components: {Roles, Permissions},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        allRoles: {
            type: Array,
            default: []
        },
        allPermissions: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            api: SharedApiService.make(),
            roles: this.allRoles,
            permissions: this.allPermissions,
            permissionError: null,
            savingPermission: false,
            roleError: null,
            savingRole: false
        }
    },
    methods: {
        getRoles() {
            this.api.getRoles().then(resp => this.roles = resp.data.data.roles);
        },
        getPermissions() {
            this.api.getPermissions().then(resp => this.permissions = resp.data.data.permissions);
        },
        createPermission(permission) {
            this.permissionError = null;
            this.savingPermission = true;

            this.api.createPermission(permission)
                .then(resp => {
                    this.getRoles();
                    this.permissions.push(resp.data.data.permission);
                })
                .catch(resp => this.permissionError = resp.response.data.message)
                .finally(() => this.savingPermission = false);
        },
        updatePermission(permission) {
            this.permissionError = null;
            this.savingPermission = true;

            this.api.updatePermission(permission)
                .then(resp => {
                    this.getRoles();

                    const index = this.permissions.findIndex(perm => perm.id === permission.id)

                    if (index !== -1) this.permissions[index] = resp.data.data.permission;
                })
                .catch(resp => this.permissionError = resp.response.data.message)
                .finally(() => this.savingPermission = false);
        },
        createRole(role) {
            this.roleError = null;
            this.savingRole = true;

            this.api.createRole(role)
                .then(resp => {
                    this.getPermissions();
                    this.roles.push(resp.data.data.role);
                })
                .catch(resp => this.roleError = resp.response.data.message)
                .finally(() => this.savingRole = false);
        },
        updateRole(role) {
            this.roleError = null;
            this.savingRole = true;

            this.api.updateRole(role)
                .then(resp => {
                    this.getPermissions();

                    const index = this.roles.findIndex(rl => rl.id === role.id);

                    if (index !== -1) this.roles[index] = resp.data.data.role;
                })
                .catch(resp => this.roleError = resp.response.message)
                .finally(() => this.savingRole = false)
        }
    }
}
</script>

<style scoped>

</style>
