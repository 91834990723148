<template>
    <div class="absolute right-0 bottom-0 z-90">
        <notification-bell :dark-mode="darkMode" @toggle-notifications-list="toggleNotificationsList" :notifications="data.notifications" :notifications-list="notificationsList" :notifications-badge="notificationsBadge" class="absolute right-0 bottom-0 mr-10 mb-10 z-20"/>
        <notifications-badge :dark-mode="darkMode" @toggle-notifications-list="toggleNotificationsList" :notification="newNotification" :notifications-badge="notificationsBadge" class="absolute right-0 bottom-0 mr-8 mb-8 z-10"/>
        <notifications-list @notification-clicked="notificationClicked" :dark-mode="darkMode" v-if="notificationsList" :notifications="data.notifications" class="absolute right-0 bottom-0 mr-8 mb-8 z-10"/>
    </div>
</template>

<script>
import NotificationBell from "./NotificationBell";
import NotificationsBadge from "./NotificationsBadge";
import NotificationsList from "./NotificationsList";
import DispatchesGlobalEventsMixin from "../../../mixins/dispatches-global-events-mixin";
export default {
    name: "NotificationsWrapper",
    components: { NotificationBell, NotificationsBadge, NotificationsList},
    mixins: [DispatchesGlobalEventsMixin],
    props: {
        api: null,
        darkMode: {
            type: Boolean,
            default: false,
        },
        notificationsList: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            notificationsBadge: false,
            newNotification: null,
            data: {
                notifications: []
            },
            timeout: null,
            notificationTypes: {lead: 3, company: 4},
            notificationLinkTypes: {lead: "lead", company: "company", task: "task"}
        }
    },
    created() {
        this.api.getRecentNotifications().then(resp => {
            this.data.notifications = resp.data.data.notifications;
        });
    },
    methods: {
        toggleNotificationsList() {
            this.$emit('toggle-notifications-list');
        },
        notificationClicked(notification) {
            this.markNotificationAsRead(notification);
        },
        markNotificationAsRead(notification) {
            this.data.notifications = this.data.notifications.map(noti => {
                if(noti.id === notification.id && noti.type === notification.type)
                    noti.read = true;

                return noti;
            });

            let notiType = 'lead-processor';
            if(typeof notification.type === 'number') {
                notiType = 'user';
            }

            this.api.markNotificationAsRead(notification.id, notiType).then(() => this.performNotificationRedirection(notification));
        },
        performNotificationRedirection(notification) {
            if(notification.type_id !== null && notification.type_id > 0) {
                switch (notification.type) {
                    case this.notificationTypes.lead:
                        if(notification.link !== null && notification.link > 0
                            && notification.link_type !== null && notification.link_type === this.notificationLinkTypes.task)
                            window.location.href = `/tasks/queue?tasks=${notification.link}`;
                        else {
                            if (window.location.href.includes('lead-processing'))
                                this.dispatchGlobalEvent('load-lead', {lead_id: notification.type_id});
                            else
                                window.location.href = `/lead-processing?lead_id=${notification.type_id}`;
                        }
                        break;
                    case this.notificationTypes.company:
                        if(notification.payload && notification.payload.sms && notification.payload.phone_number) {
                            this.dispatchGlobalEvent('sms', {phone: notification.payload.phone_number, name: notification.payload?.name ?? ""});
                        } else if(notification.link !== null && notification.link > 0
                        && notification.link_type !== null && notification.link_type === this.notificationLinkTypes.company) {
                            window.location.href = `/companies/${notification.link}`;
                        }
                        break;
                    default:
                        if(notification.payload && notification.payload.sms && notification.payload.phone_number) {
                            this.dispatchGlobalEvent('sms', {phone: notification.payload.phone_number, name: notification.payload?.name ?? ""});
                        }
                }
            } else if(notification.payload !== undefined && notification.payload !== null) {
                if(notification.payload && notification.payload.sms && notification.payload.phone_number) {
                    this.dispatchGlobalEvent('sms', {phone: notification.payload.phone_number, name: notification.payload?.name ?? ""});
                }
            }
        },
        addNotification(notification) {
            this.newNotification = notification;
            this.notificationsBadge = true;
            this.data.notifications.unshift(notification);

            if(this.timeout !== null)
                clearTimeout(this.timeout);

            this.timeout = setTimeout(() => this.clearNewNotification(), 5000);
        },
        clearNewNotification() {
            this.notificationsBadge = false;
            this.newNotification = null;
        },
    }
}
</script>

<style scoped>

</style>
