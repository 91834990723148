<template>
    <div class="grid grid-cols-1 gap-4 px-10 pt-5 pb-10">
        <div class="border rounded-lg overflow-hidden"  :class="[darkMode ? 'bg-dark-module border-dark-border' : 'bg-light-module border-light-border']">
            <div class="grid grid-cols-2">
                <div>
                    <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight px-5 pt-5">Revenue</h5>
                    <Tab
                        :dark-mode="darkMode"
                        :tabs="tabs"
                        @selected="selectTab"
                        :tabs-classes="'w-full'"
                        :tab-type="'Normal'"
                        :default-tab-index="0"
                    />
                </div>
                <div class="border-l border-b grid grid-cols-3 divide-x" :class="[darkMode ? 'divide-dark-border border-dark-border bg-dark-background text-slate-100' : 'text-slate-900 divide-light-border border-light-border bg-light-background']">
                    <div class="p-5">
                        <p class="text-sm font-semibold pb-0">
                            Revenue All-time
                        </p>
                        <p class="text-lg font-semibold text-green-500">
                            {{ $filters.currency(revenueOverview.all_time) }}
                        </p>
                    </div>
                    <div class="p-5">
                        <p class="text-sm font-semibold pb-0">
                            Last Month
                        </p>
                        <p class="text-lg font-semibold">
                            {{ $filters.currency(revenueOverview.last_month) }}
                        </p>
                    </div>
                    <div class="p-5">
                        <p class="text-sm font-semibold pb-0">
                            Last 6 Months
                        </p>
                        <p class="text-lg font-semibold">
                            {{ $filters.currency(revenueOverview.last_6_months) }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="p-8">
                <div v-if="selectedTab === 'All Revenue'">
                    <div class="flex gap-x-5">
                        <p
                            class="cursor-pointer uppercase text-sm tracking-wide font-bold"
                            :class="{'text-primary-500': period === 'all-time', 'text-slate-500': period !== 'all-time'}"
                            @click="setPeriod('all-time', 1)"
                        >All Time</p>
                        <p
                            class="cursor-pointer uppercase text-sm tracking-wide font-bold"
                            :class="{'text-primary-500': period === 'monthly' && duration === 1, 'text-slate-500': period !== 'monthly' || duration !== 1}"
                            @click="setPeriod('monthly', 1)"
                        >1 M</p>
                        <p
                            class="cursor-pointer uppercase text-sm tracking-wide font-bold"
                            :class="{'text-primary-500': period === 'monthly' && duration === 6, 'text-slate-500': period !== 'monthly' || duration !== 6}"
                            @click="setPeriod('monthly', 6)"
                        >6 M</p>
                        <p
                            class="cursor-pointer uppercase text-sm tracking-wide font-bold"
                            :class="{'text-primary-500': period === 'monthly' && duration === 12, 'text-slate-500': period !== 'monthly' || duration !== 12}"
                            @click="setPeriod('monthly', 12)"
                        >1 Y</p>
                    </div>
                    <div class="w-full h-88 mt-4 flex items-center justify-center">
                        <div>
                            <LoadingSpinner v-if="loading" class="bg-slate-300"/>
                            <BarChart v-else-if="labels !== null && statistics !== null" :labels-list="labels" :statistics="statistics" :label="title" :width="1200"/>
                        </div>

                    </div>
                </div>
                <div v-if="selectedTab === 'Campaigns'">
                    <div class="flex items-center">
                        <div class="flex gap-x-5 mr-8">
                            <p class="cursor-pointer uppercase text-sm text-primary-500 tracking-wide font-bold">All Time</p>
                            <p class="cursor-pointer uppercase text-sm text-slate-500 tracking-wide font-bold">1 M</p>
                            <p class="cursor-pointer uppercase text-sm text-slate-500 tracking-wide font-bold">6 M</p>
                            <p class="cursor-pointer uppercase text-sm text-slate-500 tracking-wide font-bold">1 Y</p>
                        </div>
                        <button class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 h-9 rounded-md px-5">
                            Select Campaigns
                        </button>
                    </div>
                    <div class="w-full h-88 bg-slate-300 mt-4 flex items-center justify-center">
                        <div>
                            <LoadingSpinner></LoadingSpinner>
                            <!--  TODO: When a Charts plugin is added, add the "Campaigns" chart here.-->
                            Graph Goes Here
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Tab from "../../Shared/components/Tab";
import LoadingSpinner from "../../LeadProcessing/components/LoadingSpinner";
import ApiService from "../services/api";
import BarChart from "../../Shared/components/BarChart";
export default {
    name: "Revenue",
    components: {BarChart, LoadingSpinner, Tab},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        companyId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            api: ApiService.make(),
            tabs : [
                {name: 'All Revenue', current: true},
                // {name: 'Campaigns', current: false},
            ],
            selectedTab: 'All Revenue',
            revenueOverview: {
                all_time      : 0,
                last_month    : 0,
                last_6_months : 0
            },
            loading: false,
            title: 'Revenue',
            labels: null,
            statistics: null,
            period: "all-time",
            duration: 1,
        }
    },
    created() {
        if (this.companyId) {
            this.loading = true;
            this.getRevenueOverview();
            this.getRevenueDetailedInsight();
        }
    },
    methods: {
        selectTab(selected) {
            this.selectedTab = selected;
        },
        getRevenueOverview() {
            this.api.getRevenueOverview(this.companyId).then(resp => {
                if(resp.data.data !== null){
                    this.revenueOverview.all_time      = resp.data.data.revenue_all_time ?? 0;
                    this.revenueOverview.last_month    = resp.data.data.revenue_last_month ?? 0;
                    this.revenueOverview.last_6_months = resp.data.data.revenue_last_6_months ?? 0;
                }
            });
        },
        getRevenueDetailedInsight() {
            this.loadGraph();
        },
        setPeriod(period, duration) {
            this.period = period;
            this.duration = duration;

            this.loadGraph();
        },
        loadGraph() {
            this.loading = true;

            this.api.getRevenueGraph(this.companyId, this.period, this.duration).then(resp => {
                const data = resp.data.data.graph_data;

                this.labels = data.map((item) => item.period);
                this.statistics = data.map(item => item.revenue);
            }).catch(e => console.error(e)).finally(() => this.loading = false);
        }
    }
}
</script>

<style scoped>

</style>
