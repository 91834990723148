<template>
  <div>
    <div class="main-layout font-body">
        <div class="w-full">
            <div class="w-full flex-auto pt-2 relative">
                <loading-spinner v-if="loading" :dark-mode="darkMode"/>
                <div class="px-10" :class="[darkMode ? 'text-white' : 'text-slate-900']">
                    <div class="flex items-center justify-between flex-wrap py-4">
                        <div class="flex justify-between items-center w-full py-2">
                            <div class="inline-flex items-center" v-if="showGlobalConfigs" :class="{'text-grey-120': darkMode}">
                                <h3 @click="toggleGlobalConfigScreen" class="text-lg font-medium text-grey-300 leading-none hover:text-grey-400 transition duration-200 cursor-pointer">Lead Processing Management</h3>
                                <h3 class="text-lg inline-flex font-medium leading-none items-center" :class="{'text-black': !darkMode, 'text-grey-120': darkMode}">
                                    <svg class="mx-4 fill-current" width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.327334 0.260067C0.735992 -0.11144 1.36844 -0.0813234 1.73995 0.327334L6.73995 5.82733C7.08669 6.20876 7.08669 6.79126 6.73995 7.17268L1.73995 12.6727C1.36844 13.0813 0.735992 13.1115 0.327334 12.7399C-0.0813234 12.3684 -0.11144 11.736 0.260067 11.3273L4.64855 6.50001L0.260067 1.67268C-0.11144 1.26402 -0.0813234 0.631574 0.327334 0.260067Z"/>
                                    </svg>
                                    Global Config
                                </h3>
                            </div>
                            <h3 v-if="!showGlobalConfigs" class="text-xl font-medium pb-0 leading-none mr-5">Lead Processing Management</h3>
                            <p v-if="!showGlobalConfigs" @click="toggleGlobalConfigScreen" class="text-grey-300 hover:text-grey-400 transition duration-200 inline-flex items-center font-medium leading-none cursor-pointer">
                              Global Config
                              <svg class="ml-2 fill-current" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.123035 11.9439L1.96159 15.0579C2.08365 15.2645 2.2845 15.4151 2.51998 15.4767C2.75546 15.5383 3.00629 15.5058 3.21732 15.3864L4.50063 14.661C5.03381 15.0723 5.62307 15.4098 6.24266 15.6618V17.1C6.24266 17.3387 6.33951 17.5676 6.51191 17.7364C6.68431 17.9052 6.91813 18 7.16194 18H10.839C11.0829 18 11.3167 17.9052 11.4891 17.7364C11.6615 17.5676 11.7583 17.3387 11.7583 17.1V15.6618C12.3828 15.4073 12.9688 15.0706 13.5004 14.661L14.7837 15.3864C15.2222 15.6339 15.7866 15.4854 16.0394 15.0579L17.8779 11.9439C17.9989 11.7371 18.0316 11.4918 17.9687 11.2616C17.9059 11.0313 17.7526 10.8347 17.5424 10.7145L16.2812 10.0008C16.3797 9.33704 16.3791 8.66278 16.2793 7.9992L17.5406 7.2855C17.9781 7.038 18.1298 6.4845 17.8761 6.0561L16.0376 2.9421C15.9155 2.73555 15.7146 2.58491 15.4792 2.52331C15.2437 2.46171 14.9929 2.49418 14.7818 2.6136L13.4985 3.339C12.9677 2.92894 12.3818 2.59219 11.7574 2.3382V0.9C11.7574 0.661305 11.6606 0.432387 11.4882 0.263604C11.3158 0.0948211 11.0819 0 10.8381 0H7.16102C6.91721 0 6.68339 0.0948211 6.51099 0.263604C6.33859 0.432387 6.24174 0.661305 6.24174 0.9V2.3382C5.6173 2.59273 5.03122 2.92943 4.49971 3.339L3.21732 2.6136C3.11286 2.55435 2.99749 2.51585 2.87782 2.50029C2.75815 2.48474 2.63652 2.49244 2.51988 2.52295C2.40325 2.55346 2.29391 2.60618 2.19811 2.6781C2.10231 2.75003 2.02194 2.83974 1.96159 2.9421L0.123035 6.0561C0.00204679 6.26292 -0.030577 6.50817 0.0322679 6.73843C0.0951128 6.9687 0.248336 7.16532 0.458571 7.2855L1.71982 7.9992C1.62066 8.66287 1.62066 9.33713 1.71982 10.0008L0.458571 10.7145C0.0209951 10.962 -0.130686 11.5155 0.123035 11.9439V11.9439ZM8.99957 5.4C11.0275 5.4 12.6767 7.0146 12.6767 9C12.6767 10.9854 11.0275 12.6 8.99957 12.6C6.97165 12.6 5.32246 10.9854 5.32246 9C5.32246 7.0146 6.97165 5.4 8.99957 5.4Z"/>
                              </svg>
                            </p>
                        </div>
                    </div>
                    <div>
                        <div v-if="showGlobalConfigs" class="grid md:grid-cols-1 lg:grid-cols-4 gap-4">
                            <TimezoneManagementList :dark-mode="darkMode" v-model="globalConfigs.lead_processing_time_zone_configurations" @update="saveGlobalConfigs"/>
                            <CallingTimezoneManagementList :dark-mode="darkMode" v-model="globalConfigs.lead_processing_calling_time_zone_configurations" @update="saveGlobalConfigs"/>
                            <LeadOpeningInterval :dark-mode="darkMode" v-model="globalConfigs.recency_threshold_in_seconds" @update="saveGlobalConfigs"/>
                            <LeadTimezoneBuffer :dark-mode="darkMode" v-model="globalConfigs.time_zone_opening_delay_in_minutes" @update="saveGlobalConfigs"/>
                            <LeadProcessableDelay :dark-mode="darkMode" v-model="globalConfigs.lead_processable_delay_seconds" @update="saveGlobalConfigs"/>
                            <MinimumReviewTime :dark-mode="darkMode" v-model="globalConfigs.minimum_review_time" @update="saveGlobalConfigs" />
                            <CheckNextLeadInterval :dark-mode="darkMode" v-model="globalConfigs.check_next_lead_interval_seconds" @update="saveGlobalConfigs" />
                            <LastLeadCreatedInterval :dark-mode="darkMode" v-model="globalConfigs.last_lead_created_interval_min" @update="saveGlobalConfigs" />
                        </div>
                        <div v-if="!showGlobalConfigs" class="grid md:grid-cols-1 lg:grid-cols-3 gap-4 mb-4">
                            <pending-leads-chart :module-title="'Solar Pending Leads'" :statistics="data.statistics.solar" :dark-mode="darkMode"/>
                            <pending-leads-chart :module-title="'Roofing Pending Leads'" :statistics="data.statistics.roofing" :dark-mode="darkMode"/>
                            <unavailable-leads-chart :statistics="data.statistics" :dark-mode="darkMode"/>
                        </div>
                        <div v-if="!showGlobalConfigs" class="grid md:grid-cols-1 lg:grid-cols-7 gap-4">
                            <div class="lg:col-span-2">
                                <queue-list
                                    :queues="data.queues"
                                    :dark-mode="darkMode"/>
                            </div>
                            <div class="lg:col-span-3">
                                <team-management-list
                                    @save-team="createTeam"
                                    @delete-team="deleteTeam"
                                    @update-team="updateTeam"
                                    :queues="data.queues"
                                    :teams="data.teams"
                                    :processors="data.processors"
                                    :industries="data.industries"
                                    :dark-mode="darkMode"/>
                            </div>
                            <div class="lg:col-span-2">
                                <processor-list
                                    @save-processor="createProcessor"
                                    @update-processor="updateProcessor"
                                    @delete-processor="deleteProcessor"
                                    :queues="data.queues"
                                    :teams="data.teams"
                                    :processors="data.processors"
                                    :dark-mode="darkMode"
                                    :user-search-options="data.userSearchOptions"
                                    @change-user-search-options="data.userSearchOptions = $event"
                                    @search-users="searchUsers"/>
                            </div>
                            <div class="lg:col-span-2">
                                <bounced-leads
                                    :leads="data.bouncedLeads"
                                    :dark-mode="darkMode" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
    import LoadingSpinner from "../../Shared/components/LoadingSpinner";
    import StatisticsOverview from "../components/StatisticsOverview";
    import QueueList from "../components/QueueList";
    import TeamManagementList from "../components/TeamManagementList";
    import ProcessorList from "../components/ProcessorList";
    import PendingLeadsChart from "../components/PendingLeadsChart";
    import UnavailableLeadsChart from "../components/UnavailableLeadsChart";
    import BouncedLeads from "../components/BouncedLeads";
    import LeadOpeningInterval from "../components/GlobalConfigs/LeadOpeningInterval";
    import LeadTimezoneBuffer from "../components/GlobalConfigs/LeadTimezoneBuffer";
    import TimezoneManagementList from "../components/GlobalConfigs/TimezoneManagementList";
    import CallingTimezoneManagementList from "../components/GlobalConfigs/CallingTimezoneManagementList";
    import LeadProcessableDelay from "../components/GlobalConfigs/LeadProcessableDelay";
    import MinimumReviewTime from "../components/GlobalConfigs/MinimumReviewTime";
    import CheckNextLeadInterval from "../components/GlobalConfigs/CheckNextLeadInterval";
    import LastLeadCreatedInterval from "../components/GlobalConfigs/LastLeadCreatedInterval";

    export default {
        name: "Dashboard",
        data: function() {
            return {
                loading: false,
                showGlobalConfigs: false,
                globalConfigs: {},
                data: {
                    queues: [],
                    teams: [],
                    processors: [],
                    userSearchOptions: [],
                    statistics: [],
                    industries: [],
                    bouncedLeads: []
                }
            }
        },
        components: {
            CheckNextLeadInterval,
            MinimumReviewTime,
            LeadProcessableDelay,
            UnavailableLeadsChart,
            PendingLeadsChart,
            TimezoneManagementList,
            LeadTimezoneBuffer,
            LeadOpeningInterval,
            ProcessorList,
            QueueList,
            StatisticsOverview,
            LoadingSpinner,
            TeamManagementList,
            BouncedLeads,
            CallingTimezoneManagementList,
            LastLeadCreatedInterval
        },
        props: ["api", "darkMode"],
        created() {
            this.updateData();
            this.getStatistics();
            this.getIndustries();
            this.getGlobalConfigs();
        },
        mounted() {},
        methods: {
            toggleGlobalConfigScreen() {
                this.showGlobalConfigs = !this.showGlobalConfigs;
            },
            updateData() {
                this.getQueues();
                this.getTeams();
                this.getProcessors();
                this.getBouncedLeads();
            },

            getQueues() {
                this.data.queues = [];

                this.api.getQueues().then(resp => {
                    if(resp.data.data.status === true) {
                        this.data.queues = resp.data.data.queues;
                    }
                }).catch(e => {});
            },
            getTeams() {
                this.data.teams = [];

                this.api.getTeams().then(resp => {
                    if(resp.data.data.status === true) {
                        this.data.teams = resp.data.data.teams;
                    }
                }).catch(e => {});
            },
            getProcessors() {
                this.data.processors = [];

                this.api.getProcessors().then(resp => {
                    if(resp.data.data.status === true) {
                        this.data.processors = resp.data.data.processors;
                    }
                }).catch(e => {});
            },
            getBouncedLeads() {
                this.data.bouncedLeads = [];

                this.api.getBouncedLeads().then(resp => {
                    if(resp.data.data.status === true) {
                        this.data.bouncedLeads = resp.data.data.leads;
                    }
                }).catch(e => {});
            },
            getIndustries() {
                this.data.industries = [];

                this.api.getIndustries().then(resp => {
                    if(resp.data.data.status === true) {
                        this.data.industries = resp.data.data.industries;
                    }
                }).catch(e => {});
            },

            createTeam(data) {
                this.api.createTeam(data.name, data.queue, data.timezone, data.industry).then(resp => {
                    this.getTeams();
                })
            },
            createProcessor(data) {
                this.api.createProcessor(data.userId, data.teamId, data.phoneId).then(resp => {
                    this.getProcessors();
                })
            },

            getStatistics() {
                this.api.getStatistics().then(resp => {
                    if(resp.data.data.status && resp.data.data.statistics) {
                        this.data.statistics = resp.data.data.statistics;
                    }
                });

                setTimeout(() => this.getStatistics(), 30000);
            },

            updateTeam(data) {
                this.api.updateTeam(data.id, data.name, data.queue, data.timezone, data.industry).then((res) => {
                    this.getTeams();
                })
            },
            updateProcessor(data) {
                this.api.updateProcessor(data.id, data.userId, data.teamId, data.phoneId).then((res) => {
                    this.getProcessors();
                })
            },

            deleteTeam(id) {
                this.api.deleteTeam(id).then(
                    (res) => {
                        if(res.data.data.status) {
                            this.getTeams();
                        }
                    }
                ).catch(() => {
                    //TODO: Add Error Handling
                });
            },
            deleteProcessor(id) {
                this.api.deleteProcessor(id).then(
                    (res) => {
                        if(res.data.data.status) {
                            this.getProcessors();
                        }
                    }
                ).catch(() => {
                    //TODO: Add Error Handling
                });
            },

            searchUsers(query) {
                this.api.searchUserNames(query).then(
                    (res) => {
                        if(res.data.data.status) {
                            this.data.userSearchOptions = res.data.data.users;
                        }
                        else {
                            //TODO: Report Error
                        }
                    }
                ).catch(
                    () => {
                        //TODO: Report Error
                    }
                )
            },

            getGlobalConfigs() {
                this.globalConfigs = {};

                this.api.getGlobalConfigs().then(resp => {
                    if(resp.data.data.status === true) {
                        this.globalConfigs = resp.data.data.configs;
                    }
                }).catch(e => {});
            },
            saveGlobalConfigs() {
                this.api.saveGlobalConfigs(
                    this.globalConfigs.time_zone_opening_delay_in_minutes,
                    this.globalConfigs.recency_threshold_in_seconds,
                    this.globalConfigs.minimum_review_time,
                    this.globalConfigs.lead_processable_delay_seconds,
                    this.globalConfigs.check_next_lead_interval_seconds,
                    this.globalConfigs.lead_processing_time_zone_configurations,
                    this.globalConfigs.lead_processing_calling_time_zone_configurations,
                    this.globalConfigs.last_lead_created_interval_min
                ).then(resp => {
                    if(resp.data.data.status === true) {
                        this.getGlobalConfigs();
                    }
                }).catch(e => {});
            }
        }
    }
</script>

<style scoped>

</style>
