<template>
    <div v-if="canDelete" class="flex items-center justify-center" @click="openModal">
        <slot name="button"></slot>
    </div>
    <modal
        :small="true"
        :dark-mode="darkMode"
        v-if="showModal"
        @close="showModal = false"
        :close-text="'Cancel'"
        :confirm-text="'Delete'"
        :disable-confirm="saving"
        @confirm="deleteTask"
    >
        <template v-slot:header>
            Confirm Delete?
        </template>
        <template v-slot:content>
            <alert v-if="error !== null" :text="error" :alert-type="'error'" :dark-mode="darkMode"></alert>
        </template>
    </modal>
</template>
<script>
import Modal from "../Shared/components/Modal";
import Alert from "../Shared/components/Alert";
import ApiService from "./services/api";

export default {
    name: "DeleteTask",
    components: {Modal, Alert},
    props: {
        task: {
            type: Object,
            default: {}
        },
        darkMode: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            api: ApiService.make(),
            showModal: false,
            error: null,
            saving: false
        };
    },
    emits: ['task-deleted'],
    computed: {
        canDelete: function () {
            return this.task.manual && !this.task.completed;
        }
    },
    methods: {
        deleteTask () {
            this.error = null;
            this.saving = true;
            this.api.deleteManualTask(this.task.id)
                .then(() => {
                    this.showModal = false;
                    this.$emit('task-deleted');
                })
                .catch(e => this.error = `Failed to delete task. Error: ${e.response.data.message}`)
                .finally(() => this.saving = false);
        },
        openModal () {
            this.showModal = true;
            this.error = null;
        }
    }
}
</script>
