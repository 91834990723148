<template>
    <div class="flex justify-start">
        <div class="w-64 rounded-t-lg rounded-br-lg px-4 pt-3 pb-8 relative"
             :class="{'bg-grey-100 text-grey-800' : !darkMode, 'bg-dark-background text-blue-100' : darkMode}">
            <p class="font-medium">
                {{ message.first_name }} {{ message.last_name }}
            </p>
            <p class="leading-tight font-light">
                {{ message.message_body }}
            </p>
            <div class="absolute right-0 bottom-0 mr-3 mb-2">
                <p class="font-light text-sm">{{ $filters.dateFromTimestamp(message.timestamp, 'relative') }}</p>
            </div>
            <div class="absolute left-0" style="bottom: -6px;">
                <svg class="fill-current"
                     :class="{'text-grey-100' : !darkMode, 'text-dark-40' : darkMode}"
                     width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.000238967 2.02949e-05L0.000290823 6.79207L9.50034 -1.6739e-05L0.000238967 2.02949e-05Z"/>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "IncomingMessage",
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        message: {
            type: Object,
            default: () => {}
        }
    },
    methods: { }
}
</script>

<style scoped>

</style>
