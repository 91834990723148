export const AlertTypes = {
    error: "error",
    warning: "warning",
    success: "success"
}

export default {
    data: function() {
        return {
            alertActive: false,
            alertType: '',
            alertText: '',
            alertDelay: 5000,
        }
    },
    methods: {
        showAlert(type, text) {
            this.alertActive = true;
            this.alertType = type;
            this.alertText = text;

            setTimeout(() => this.hideAlert(), this.alertDelay);
        },
        hideAlert() {
            this.alertActive = false;
            this.alertType = '';
            this.alertText = '';
        }
    }
}
