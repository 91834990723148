<template>
    <div class="mt-8 grid grid-cols-9 gap-4 pb-5">
        <div class="col-span-2">
            <label class="font-medium text-xs uppercase" :class="[!darkMode ? 'text-grey-400' : 'text-blue-400']">Report mode</label>
            <fieldset>
                <legend class="sr-only">Report Mode</legend>
                <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10" :class="[!darkMode ? 'text-grey-900' : 'text-white']">
                    <div class="flex gap-5 pr-5 items-center">
                        <div class="flex items-center mt-3">
                            <input id="report-mode-single" name="report-mode-single" type="radio" :checked="reportMode === 'single'" value="single" @change="updateReportMode($event)" class="focus:ring-blue-550 cursor-pointer h-5 w-5 text-blue-550 border-gray-300" />
                            <label :class="[!darkMode ? 'text-grey-900' : 'text-white']" for="report-mode-single" class="ml-2 block font-medium">
                                Single
                            </label>
                        </div>
                        <div class="flex items-center mt-3">
                            <input id="report-mode-single" name="report-mode-single" type="radio" :checked="reportMode === 'comparison'" value="comparison" @change="updateReportMode($event)" class="focus:ring-blue-550 cursor-pointer h-5 w-5 text-blue-550 border-gray-300" />
                            <label :class="[!darkMode ? 'text-grey-900' : 'text-white']" class="ml-2 block font-medium">
                                Comparison
                            </label>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
        <div class="col-span-3"  :class="[!darkMode ? 'text-grey-900' : 'text-white']">
            <label for="primary-timestamp" class="font-medium text-xs uppercase" :class="[!darkMode ? 'text-grey-400' : 'text-blue-400']">Primary Date</label>
            <select v-model="primaryTimestamp" id="primary-timestamp" name="primary-timestamp" class="mt-2 border rounded w-full text-base cursor-pointer leading-none inline-flex items-center h-9"
                    :class="{'border-grey-200 bg-light-module': !darkMode, 'border-blue-400 bg-dark-background text-white': darkMode}">
                <option v-for="dateOption in dateOptions" :value="dateOption.timestamp">{{ dateOption.displayDate }}</option>
            </select>
        </div>
        <div v-if="reportMode === 'comparison'" class="col-span-3">
            <label for="primary-timestamp" class="font-medium text-xs uppercase" :class="[!darkMode ? 'text-grey-400' : 'text-blue-400']">Comparison Date</label>
            <select v-model="comparisonTimestamp" id="comparison-timestamp" name="comparison-timestamp" class="mt-2 border rounded w-full text-base cursor-pointer leading-none inline-flex items-center h-9"
                    :class="{'border-grey-200 bg-light-module': !darkMode, 'border-blue-400 bg-dark-background text-white': darkMode}">
                <option v-for="dateOption in comparisonDateOptions" :value="dateOption.timestamp">{{ dateOption.displayDate }}</option>
            </select>
        </div>
        <div class="col-span-2">
            <label for="industry" class="font-medium text-xs uppercase" :class="[!darkMode ? 'text-grey-400' : 'text-blue-400']">Industry</label>
            <select v-model="industry" id="industry" name="industry" class="mt-2 border rounded w-full text-base cursor-pointer inline-flex items-center leading-none h-9"
                    :class="{'border-grey-200 bg-light-module': !darkMode, 'border-blue-400 bg-dark-background text-white': darkMode}">
                <option v-for="industry in industries">{{ industry }}</option>
            </select>
        </div>
        <div class="col-span-2 flex items-end">
            <button @click="submit" type="button"
                    class="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-semibold rounded-lg shadow-sm text-white bg-primary-500 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 h-9">
                Submit
            </button>
        </div>
    </div>
</template>

<script>
import ApiService from "../services/api/api";

export default {
    name: "Inputs",
    data: function () {
        return {
            primaryDay: null,
            primaryTimestamp: null,
            comparisonTimestamp: null,
            industry: 'Solar',
            industries: ['Solar', 'Roofer'],
            dateOptions: null
        }
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        reportMode: {
            type: String,
            default: 'single'
        },
        apiService: null,
    },
    created() {
        this.getDateOptions();
        this.submit();
    },
    methods: {
        submit() {
            this.$emit('update-loading', true);
            this.apiService.getHistoricalAvailableBudgets({
                'industry': this.industry.toLowerCase(),
                'primary_timestamp': this.primaryTimestamp,
                'comparison_timestamp': this.reportMode === 'comparison' ? this.comparisonTimestamp : null
            }).then(resp => {
                this.$emit('update-data', resp.data.data);
            }).catch(e => console.log(e)).finally(() => this.$emit('update-loading', false));
        },
        updateReportMode(event) {
            this.$emit('update-report-mode', event.target.value);
        },
        getDateOptions() {
            this.apiService.getDateOptions().then(resp => {
                this.dateOptions = [];
                for(const timestamp of resp.data.data) {
                    this.dateOptions.push({
                        'timestamp': timestamp,
                        'displayDate': this.$filters.dateFromTimestamp(timestamp, 'weekdayWithDateTimeShort', 'America/Los_Angeles'),
                    });
                }
                this.primaryTimestamp = this.dateOptions[0].timestamp;
            }).catch(e => console.log(e));
        },
    },
    computed: {
        comparisonDateOptions() {
            return this.dateOptions.filter(dateOption => dateOption.timestamp < this.primaryTimestamp);
        }
    }
}
</script>

<style scoped>

</style>
