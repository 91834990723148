class BaseApiService {
    constructor(serviceName = "BaseApiService") {
        this.serviceName = serviceName;
    }

    _throwUnimplementedError(fn) {
        throw new Error("Not implemented error. `" + this.serviceName + "::" + fn + "()`");
    }

    getAccountManagers() {
        this._throwUnimplementedError("getAccountManagers");

        return new Promise((resolve, reject) => reject());
    }

    deleteAccountManager(id) {
        this._throwUnimplementedError("deleteAccountManager");

        return new Promise((resolve, reject) => reject());
    }

    createAccountManager() {
        this._throwUnimplementedError("createAccountManager");

        return new Promise((resolve, reject) => reject());
    }

    updateAccountManager(id) {
        this._throwUnimplementedError("updateAccountManager");

        return new Promise((resolve, reject) => reject());
    }

    getAccountManagerTypes() {
        this._throwUnimplementedError("getAccountManagerTypes");

        return new Promise((resolve, reject) => reject());
    }

    getSuccessManagers() {
        this._throwUnimplementedError("getSuccessManagers");

        return new Promise((resolve, reject) => reject());
    }

    createSuccessManager(payload) {
        this._throwUnimplementedError("createSuccessManager");

        return new Promise((resolve, reject) => reject());
    }

    updateSuccessManager(id, payload) {
        this._throwUnimplementedError("updateSuccessManager");

        return new Promise((resolve, reject) => reject());
    }

    deleteSuccessManager(id) {
        this._throwUnimplementedError("deleteSuccessManager");

        return new Promise((resolve, reject) => reject());
    }

    getSuccessManagerTypes() {
        this._throwUnimplementedError("getSuccessManagerTypes");

        return new Promise((resolve, reject) => reject());
    }

    searchUserNames() {
        this._throwUnimplementedError("searchUserNames");

        return new Promise((resolve, reject) => reject());
    }

    getSupportOfficers() {
        this._throwUnimplementedError("getSupportOfficers");

        return new Promise((resolve, reject) => reject());
    }

    createSupportOfficer(payload) {
        this._throwUnimplementedError("CreateSupportOfficer");

        return new Promise((resolve, reject) => reject());
    }

    updateSupportOfficer(id, payload) {
        this._throwUnimplementedError("updateSupportOfficer");

        return new Promise((resolve, reject) => reject());
    }

    deleteSupportOfficer(id) {
        this._throwUnimplementedError("deleteSupportOfficer");

        return new Promise((resolve, reject) => reject());
    }

    getHunters() {
        this._throwUnimplementedError("getHunters");

        return new Promise((resolve, reject) => reject());
    }

    createHunter(payload) {
        this._throwUnimplementedError("createHunter");

        return new Promise((resolve, reject) => reject());
    }

    updateHunter(id, payload) {
        this._throwUnimplementedError("updateHunter");

        return new Promise((resolve, reject) => reject());
    }

    deleteHunter(id) {
        this._throwUnimplementedError("deleteHunter");

        return new Promise((resolve, reject) => reject());
    }
}

export { BaseApiService };
