<template>
    <div v-if="messengerActive" class="absolute right-0 bottom-0 z-30">
        <div class="transition-all transform duration-400 fixed z-50 inset-0 flex items-center justify-center bg-opacity-75 bg-dark-background">
            <div class="w-3/4 md:w-400 shadow bg-light-module rounded-lg transform transition-all duration-500"
                 :class="{'bg-light-module border border-light-border' : !darkMode, 'bg-dark-background border border-primary-500' : darkMode}"
            >
                <div class="flex items-center justify-between border-b  px-6 py-4"
                     :class="{'border-light-border' : !darkMode, 'border-dark-border' : darkMode}">
                    <h4 class="text-xl inline-flex items-center"
                        :class="{'text-grey-900' : !darkMode, 'text-white' : darkMode}">
                        <svg class="mr-2 text-blue-550 fill-current w-4" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 2C0 1.46957 0.229869 0.960859 0.63904 0.585786C1.04821 0.210714 1.60316 0 2.18182 0H9.81818C10.3968 0 10.9518 0.210714 11.361 0.585786C11.7701 0.960859 12 1.46957 12 2V6C12 6.53043 11.7701 7.03914 11.361 7.41421C10.9518 7.78929 10.3968 8 9.81818 8H7.63636L4.36364 11V8H2.18182C1.60316 8 1.04821 7.78929 0.63904 7.41421C0.229869 7.03914 0 6.53043 0 6V2Z"/>
                        </svg>
                        Messenger</h4>
                    <svg class="cursor-pointer fill-current"
                         :class="{'text-grey-900' : !darkMode, 'text-white' : darkMode}"
                         @click="closeMessenger" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.38999 0.407051C0.639775 0.15709 0.978512 0.0166691 1.33171 0.0166691C1.68491 0.0166691 2.02364 0.15709 2.27343 0.407051L7.99167 6.13105L13.7099 0.407051C13.8328 0.279704 13.9798 0.178128 14.1423 0.108249C14.3048 0.0383705 14.4796 0.00158876 14.6564 5.03427e-05C14.8333 -0.00148808 15.0087 0.0322472 15.1724 0.0992884C15.3361 0.16633 15.4848 0.265334 15.6099 0.390524C15.7349 0.515714 15.8338 0.664584 15.9008 0.828446C15.9678 0.992307 16.0015 1.16788 15.9999 1.34492C15.9984 1.52196 15.9617 1.69692 15.8919 1.85959C15.8221 2.02226 15.7206 2.16939 15.5934 2.29238L9.87511 8.01638L15.5934 13.7404C15.836 13.9919 15.9702 14.3287 15.9672 14.6783C15.9642 15.0278 15.8241 15.3623 15.5771 15.6095C15.3302 15.8567 14.9961 15.9969 14.6468 15.9999C14.2976 16.003 13.9611 15.8686 13.7099 15.6257L7.99167 9.90172L2.27343 15.6257C2.02221 15.8686 1.68575 16.003 1.3365 15.9999C0.987258 15.9969 0.653177 15.8567 0.406215 15.6095C0.159253 15.3623 0.0191681 15.0278 0.0161333 14.6783C0.0130984 14.3287 0.147356 13.9919 0.38999 13.7404L6.10824 8.01638L0.38999 2.29238C0.14028 2.04235 0 1.70327 0 1.34972C0 0.996165 0.14028 0.657088 0.38999 0.407051V0.407051Z"/>
                    </svg>
                </div>
                <div class="p-6">
                    <h3 class="text-xl font-medium pb-3 text-center"
                        :class="{'text-grey-900' : !darkMode, 'text-white' : darkMode}"
                    >{{ name }}</h3>
                    <div class="border-l border-r border-t h-80 overflow-y-auto grid grid-cols-1 gap-3 items-end rounded-t p-4"
                         :class="{'bg-light-module border-light-border' : !darkMode, 'bg-dark-background border-dark-border' : darkMode}" ref="scrollContainer">
                        <loading-spinner :dark-mode="darkMode" v-if="loading"/>
                        <div v-else v-for="message in messages">
                            <outgoing-message v-if="message.direction === 'outbound'" :message="message" :dark-mode="darkMode" />
                            <incoming-message v-else :message="message" :dark-mode="darkMode" />
                        </div>
                    </div>
                    <div class="h-32 overflow-hidden rounded-b border relative"
                         :class="{'bg-light-module border-light-border' : !darkMode, 'bg-dark-background border-dark-border' : darkMode}">
                        <div class="absolute right-0 h-full inline-flex items-center mr-6">
                            <div @click="sendText" class="cursor-pointer transition duration-300" :class="{'text-blue-550 hover:text-blue-500' : typedMessage, 'text-grey-200' : !typedMessage}">
                                <svg class="fill-current" width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18 37C22.7739 37 27.3523 35.0509 30.7279 31.5815C34.1036 28.1121 36 23.4065 36 18.5C36 13.5935 34.1036 8.88795 30.7279 5.41852C27.3523 1.9491 22.7739 0 18 0C13.2261 0 8.64773 1.9491 5.27208 5.41852C1.89642 8.88795 0 13.5935 0 18.5C0 23.4065 1.89642 28.1121 5.27208 31.5815C8.64773 35.0509 13.2261 37 18 37ZM26.3407 16.8651L19.5907 9.92756C19.1688 9.49404 18.5966 9.25049 18 9.25049C17.4034 9.25049 16.8312 9.49404 16.4093 9.92756L9.65925 16.8651C9.24939 17.3012 9.02261 17.8853 9.02773 18.4917C9.03286 19.098 9.26949 19.678 9.68666 20.1068C10.1038 20.5355 10.6682 20.7787 11.2581 20.784C11.848 20.7893 12.4164 20.5562 12.8407 20.1349L15.75 17.1449V25.4375C15.75 26.0508 15.9871 26.639 16.409 27.0727C16.831 27.5064 17.4033 27.75 18 27.75C18.5967 27.75 19.169 27.5064 19.591 27.0727C20.0129 26.639 20.25 26.0508 20.25 25.4375V17.1449L23.1593 20.1349C23.5836 20.5562 24.152 20.7893 24.7419 20.784C25.3318 20.7787 25.8962 20.5355 26.3133 20.1068C26.7305 19.678 26.9671 19.098 26.9723 18.4917C26.9774 17.8853 26.7506 17.3012 26.3407 16.8651Z"/>
                                </svg>
                            </div>
                        </div>
                        <textarea v-model="typedMessage"
                                  placeholder="Start typing your message here..."
                                  class="w-full h-full py-4 pl-4 pr-16 focus:outline-none"
                                  :class="{'bg-grey-50 text-grey-800' : !darkMode, 'bg-dark-background text-blue-100' : darkMode}">
                        </textarea>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import OutgoingMessage from "./OutgoingMessage";
import IncomingMessage from "./IncomingMessage";
import LoadingSpinner from "../../Shared/components/LoadingSpinner";

export default {
    name: "Messenger",
    components: {IncomingMessage, OutgoingMessage, LoadingSpinner},
    props: {
        messengerActive: {
            type: Boolean,
            default: false,
        },
        darkMode: {
            type: Boolean,
            default: false,
        },
        messages: {
            type: Array,
            default: () => []
        },
        name: {
            type: String,
            default: 'User'
        },
        loading: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            typedMessage: null,
        }
    },
    mounted() { },
    methods: {
        closeMessenger() {
            this.$emit('close-messenger');
        },
        sendText() {
            if(this.typedMessage && this.typedMessage.length > 0) {
                this.$emit('send-message', {message: this.typedMessage});
                this.typedMessage = "";
            }
        },
        scrollDown() {
            this.$nextTick(() => {
                this.$refs.scrollContainer.scrollTop = this.$refs.scrollContainer.scrollHeight;
            });
        }
    }
}
</script>

<style scoped>

</style>
