<template>
    <div class="flex flex-col">
        <div class="flex flex-row gap-5 my-5">
            <div class="grid gap-5 w-full items-center"
                :class="`grid-cols-${ Math.min(12, numberOfColumns) }`">
                <div v-for="column in activeColumnOrder" :key="column">
                    <input v-if="['number', 'text'].includes(inputTypes[column])" class="rounded p-1 focus:outline-none focus:border focus:border-primary-500 w-full"
                           :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"
                           :type="inputTypes[column]"
                           v-model="modelValue[column]"
                           @input="updateField"
                           @change="autoGenerateKeyName(column)">
                    <input v-else-if="inputTypes[column] === 'boolean'"
                           class="rounded p-1 ml-6 block"
                           type="checkbox"
                           :data-column="column"
                           :checked="!!modelValue[column]"
                           @change="updateField">
                    <select v-else-if="inputTypes[column] === 'field_type'"
                            v-model="modelValue[column]"
                            class="rounded p-1 focus:outline-none focus:border focus:border-primary-500 w-full"
                            :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                        <option class="" v-for="(fieldType, id) in fieldTypes" :value="id" :key="id">{{ fieldType }}</option>
                    </select>
                    <select v-else-if="inputTypes[column] === 'companyFieldCategory'"
                            class="rounded p-1 focus:outline-none focus:border focus:border-primary-500 w-full"
                            v-model="modelValue[column]"
                            :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                        <option v-for="(categoryName, index) in companyFieldCategories"
                                :key="index"
                                >
                            {{ categoryName }}
                        </option>
                    </select>
                    <div v-else>
                        ---
                    </div>
                </div>
                <slot name="custom-fields"></slot>
                <button
                    class="flex flex-col transition duration-200 text-red-400 text-sm font-medium focus:outline-none py-2 rounded-md px-5 whitespace-nowrap"
                    @click="remove()">
                    Delete
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Payload from "./Payload";
    import { unslugify } from "../../../Shared/services/strings";
    import { determineInputType } from "../services/determineInputType";

    export default {
        name: "Field",
        components: {
            Payload
        },
        props: {
            darkMode: {
                type: Boolean,
                default: false
            },
            modelValue: {
                type: Object,
                default: () => {
                    return {};
                }
            },
            fieldTypes: {
                type: Object,
                default: () => {
                    return {};
                }
            },
            companyFieldCategories: {
                type: Array,
                default: [],
            },
            activeColumnOrder: {
                type: Array,
                default: [],
            },
            numberOfColumns: {
                type: Number,
                default: 7,
            }
        },
        emits: [
            'update:modelValue',
            'delete'
        ],
        data: function() {
            return {
                unslugify: unslugify,
                determineInputType: determineInputType
            };
        },
        computed: {
            inputTypes: function() {
                let inputTypes = {};
                Object.keys(this.modelValue).forEach((col) => {
                    inputTypes[col] = this.determineInputType(col);
                });

                return inputTypes;
            }
        },
        methods: {
            updateField({ target }) {
                if (target.type === 'checkbox') {
                    const columnName = target.dataset.column,
                        newVal = target.checked ? 1 : 0;
                    if (!columnName || newVal == null) return;
                    this.modelValue[columnName] = newVal;
                }
                this.$emit('update:modelValue', this.modelValue);
            },
            remove() {
                this.$emit('delete', this.modelValue.id);
            },
            // When the 'name' field changes, auto-generate a snake_case key if the key is empty
            autoGenerateKeyName(columnName) {
                if (columnName === 'name' && this.modelValue.key === '') {
                    const snake_case = this.modelValue.name.toLowerCase().trim().replace(/\s+/g, '_');
                    if (snake_case) this.modelValue.key = snake_case;
                }
            }
        }
    }
</script>
