<template>
    <div class="main-layout font-body">
        <div class="w-full">
            <div class="w-full flex-auto pt-3 relative">
                <div class="px-10" :class="[darkMode ? 'text-white' : 'text-slate-900']">
                    <div class="flex items-center justify-between flex-wrap py-4">
                        <div class="flex items-center py-1">
                            <a href="/admin" class="text-xl font-medium text-grey-300 leading-none hover:text-grey-400 transition duration-200 cursor-pointer">Admin Management</a>
                            <h3 class="text-xl inline-flex font-medium leading-none items-center" :class="{'text-black': !darkMode, 'text-grey-120': darkMode}">
                                <svg class="mx-4 fill-current" width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.327334 0.260067C0.735992 -0.11144 1.36844 -0.0813234 1.73995 0.327334L6.73995 5.82733C7.08669 6.20876 7.08669 6.79126 6.73995 7.17268L1.73995 12.6727C1.36844 13.0813 0.735992 13.1115 0.327334 12.7399C-0.0813234 12.3684 -0.11144 11.736 0.260067 11.3273L4.64855 6.50001L0.260067 1.67268C-0.11144 1.26402 -0.0813234 0.631574 0.327334 0.260067Z"/>
                                </svg>
                                Salesbait Management
                            </h3>
                        </div>
                        <div>
                            <div class="flex items-center">
                                <div class="w-80">
                                    <Datepicker :dark="darkMode" v-model="dateRange" range v-on:update:modelValue="processDatePeriod" :key="dateFilterKey"/>
                                </div>
                                <div class="rounded-lg w-40 pl-2">
                                    <Dropdown v-model="selectedIndustry" :options="industries" :dark-mode="darkMode" placeholder="Industry" :key="industryFilterKey"/>
                                </div>
                                <div class="rounded-lg w-40 pl-2">
                                    <Dropdown v-model="selectedState" v-on:update:modelValue="getCounties" :options="states" :dark-mode="darkMode" placeholder="State" :key="stateFilterKey"/>
                                </div>
                                <div class="rounded-lg w-40 pl-2">
                                    <Dropdown v-model="selectedCounty" :options="counties" :dark-mode="darkMode" placeholder="County" :key="countyFilterKey"/>
                                </div>
                                <div class="pl-2">
                                    <button @click="applyFilters" class="transition duration-200 text-sm font-semibold focus:outline-none py-2 rounded-md px-5 bg-primary-500 hover:bg-blue-500 text-white">
                                        <svg class="h-5" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.66667 2C4.69421 2 3.76157 2.38631 3.07394 3.07394C2.38631 3.76157 2 4.69421 2 5.66667C2 6.14818 2.09484 6.62498 2.27911 7.06984C2.46338 7.5147 2.73346 7.91891 3.07394 8.25939C3.41442 8.59987 3.81863 8.86996 4.26349 9.05423C4.70835 9.23849 5.18515 9.33333 5.66667 9.33333C6.14818 9.33333 6.62498 9.23849 7.06984 9.05423C7.5147 8.86996 7.91891 8.59987 8.25939 8.25939C8.59987 7.91891 8.86996 7.5147 9.05423 7.06984C9.23849 6.62498 9.33333 6.14818 9.33333 5.66667C9.33333 4.69421 8.94703 3.76158 8.25939 3.07394C7.57176 2.38631 6.63913 2 5.66667 2ZM1.65973 1.65973C2.72243 0.597022 4.16377 0 5.66667 0C7.16956 0 8.6109 0.597022 9.6736 1.65973C10.7363 2.72243 11.3333 4.16377 11.3333 5.66667C11.3333 6.41082 11.1868 7.14769 10.902 7.83521C10.7458 8.21219 10.5498 8.57029 10.3178 8.90361L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L8.90361 10.3178C8.57029 10.5498 8.21219 10.7458 7.83521 10.902C7.14769 11.1868 6.41082 11.3333 5.66667 11.3333C4.92251 11.3333 4.18564 11.1868 3.49813 10.902C2.81061 10.6172 2.18593 10.1998 1.65973 9.6736C1.13353 9.14741 0.716126 8.52272 0.431349 7.83521C0.146573 7.1477 0 6.41082 0 5.66667C0 4.16377 0.597022 2.72243 1.65973 1.65973Z" fill="white"/>
                                        </svg>
                                    </button>
                                </div>
                                <div class="pl-2">
                                    <button @click="clearFilters" class="transition duration-200 text-sm font-semibold focus:outline-none py-2 rounded-md px-5"
                                            :class="{'bg-grey-175 hover:bg-grey-200 text-grey-400': !darkMode, 'bg-dark-175 hover:bg-dark-background text-white': darkMode}">
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="grid md:grid-cols-1 lg:grid-cols-3 gap-4 mb-4">
                            <div class="border rounded-lg p-5"
                                 :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
                                <div class="flex items-center justify-between">
                                    <h5 class="text-primary-500 text-sm uppercase font-semibold py-2 leading-tight">Salesbait statistics</h5>
                                </div>
                                <div class="flex w-full h-full justify-center items-center" v-if="salesBaitLoading">
                                    <loading-spinner v-if="salesBaitLoading"/>
                                </div>
                                <div v-if="!salesBaitLoading && !statistics.salesbaits" class="overflow-y-auto h-full flex items-center justify-center text-center pb-4"
                                     :class="{'text-grey-800': !darkMode, 'text-grey-120': darkMode}"
                                >
                                    No Salesbait records found for these filters
                                </div>
                                <metrics-chart
                                    v-if="!salesBaitLoading && statistics.salesbaits"
                                    :module-title="metricsChartTile.main"
                                    :dark-mode="darkMode"
                                    :metrics-A-title="metricsChartTile.metrics.A"
                                    :metrics-B-title="metricsChartTile.metrics.B"
                                    :metrics-C-title="metricsChartTile.metrics.C"
                                    :metrics-A-value="statistics.salesbaits"
                                    :metrics-B-value="statistics.clicks"
                                    :metrics-C-value="statistics.actionsTaken"
                                />
                            </div>
                            <div class="border rounded-lg"
                                 :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
                                <div class="p-5 flex items-center justify-between">
                                    <h5 class="text-primary-500 text-sm uppercase font-semibold py-2 leading-tight">Solar
                                        Sales Baits</h5>
                                    <toggle v-model="solarEnabled"></toggle>
                                </div>
                                <div class="grid grid-cols-5 gap-x-3 mb-2 px-5">
                                    <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-3">State</p>
                                    <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Status</p>
                                </div>
                                <div class="border-t border-b h-64 overflow-y-auto"
                                     :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
                                    <div v-if="!this.loading">
                                        <div class="grid grid-cols-5 gap-x-3 border-b px-5 py-3"
                                             v-for="state in solarStates" :key="state.id"
                                             v-if="solarCounties.length === 0"
                                             :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                                            <p class="text-sm col-span-3 truncate cursor-pointer text-blue-550"
                                               @click="loadState(state.location.id, 'solar')">
                                                {{ state.location.state }}
                                            </p>
                                            <p class="text-sm col-span-2 truncate">
                                                <toggle v-model="state.enabled"></toggle>
                                            </p>
                                        </div>
                                        <div class="grid grid-cols-5 gap-x-3 border-b px-5 py-3"
                                             v-if="solarCounties.length > 0"
                                             v-for="county in solarCounties" :key="county.id"
                                             :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                                            <p class="text-sm col-span-3 truncate">
                                                {{ county.location.county }}
                                            </p>
                                            <p class="text-sm col-span-2 truncate">
                                                <toggle v-model="county.enabled"></toggle>
                                            </p>
                                        </div>
                                    </div>
                                    <div v-if="this.loading" class="flex items-center justify-center h-full">
                                        <loading-spinner/>
                                    </div>

                                </div>
                                <div class="p-3 flex justify-end">
                                    <button @click="returnToTopLevel('solar')" v-if="solarCounties.length > 0"
                                            class="mr-auto transition duration-200 bg-gray-500 hover:bg-gray-400 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5">
                                        Go Back
                                    </button>
                                    <button @click="saveSolar" v-if="solarChanges"
                                            class=" transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5">
                                        Save
                                    </button>
                                </div>
                            </div>
                            <div class="border rounded-lg"
                                 :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
                                <div class="p-5 flex items-center justify-between">
                                    <h5 class="text-primary-500 text-sm uppercase font-semibold py-2 leading-tight">Roofing
                                        Sales Baits</h5>
                                    <toggle v-model="roofingEnabled"></toggle>
                                </div>
                                <div class="grid grid-cols-5 gap-x-3 mb-2 px-5">
                                    <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-3">State</p>
                                    <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Status</p>
                                </div>
                                <div class="border-t border-b h-64 overflow-y-auto"
                                     :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
                                    <div v-if="!this.loading">
                                        <div class="grid grid-cols-5 gap-x-3 border-b px-5 py-3"
                                             v-if="roofingCounties.length === 0"
                                             v-for="state in roofingStates" :key="state.id"
                                             :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                                            <p class="text-sm col-span-3 truncate cursor-pointer text-blue-550"
                                               @click="loadState(state.location.id, 'roofing')">
                                                {{ state.location.state }}
                                            </p>
                                            <p class="text-sm col-span-2 truncate">
                                                <toggle v-model="state.enabled"></toggle>
                                            </p>
                                        </div>
                                        <div class="grid grid-cols-5 gap-x-3 border-b px-5 py-3"
                                             v-if="roofingCounties.length > 0"
                                             v-for="county in roofingCounties" :key="county.id"
                                             :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                                            <p class="text-sm col-span-3 truncate">
                                                {{ county.location.county }}
                                            </p>
                                            <p class="text-sm col-span-2 truncate">
                                                <toggle v-model="county.enabled"></toggle>
                                            </p>
                                        </div>
                                    </div>
                                    <div v-if="this.loading" class="flex items-center justify-center h-full">
                                        <loading-spinner/>
                                    </div>
                                </div>
                                <div class="p-3 flex justify-end">
                                    <button @click="returnToTopLevel('roofing')" v-if="roofingCounties.length > 0"
                                            class="mr-auto transition duration-200 bg-gray-500 hover:bg-gray-400 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5">
                                        Go Back
                                    </button>
                                    <button @click="saveRoofing" v-if="roofingChanges"
                                            class=" transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5">
                                        Save
                                    </button>
                                </div>
                            </div>
                            <div class="lg:col-span-2">
                                <sales-bait-table
                                    :sales-bait-loading="salesBaitLoading"
                                    :dark-mode="darkMode"
                                    :salesBaits="salesBaits"
                                    :pagination-data="paginationData"
                                    @change="getSalesBaits"
                                />
                            </div>
                            <div>
                                <restricted-sales-bait-companies
                                    :dark-mode="darkMode"
                                ></restricted-sales-bait-companies>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Toggle from "../Inputs/Toggle/Toggle";
import ApiService from "./services/api";
import SalesBaitTable from "./SalesBaitTable";
import SharedApiService from "../Shared/services/api";
import Dropdown from "../Shared/components/Dropdown";
import MetricsChart from "../Shared/components/MetricsChart";
import Pagination from "../Shared/components/Pagination";
import LoadingSpinner from "../Shared/components/LoadingSpinner";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { ref, onMounted } from 'vue';
import RestrictedSalesBaitCompanies from "./RestrictedSalesBaitCompanies";

const TYPE_NATIONAL = 0;
const TYPE_STATE = 1;
const TYPE_COUNTY = 2;

export default {
    name: "SalesBaitManagement",
    components: {
        RestrictedSalesBaitCompanies,
        Pagination, Toggle, MetricsChart, Dropdown, SalesBaitTable, Datepicker, LoadingSpinner},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        solarEnabled() {
            if (!this.loading)
                this.solarChanges = true;
        },
        solarStates: {
            handler() {
                if (!this.loading)
                    this.solarChanges = true;
            },
            deep: true
        },
        solarCounties: {
            handler() {
                if (!this.loading)
                    this.solarChanges = true;
            },
            deep: true
        },
        roofingEnabled() {
            if (!this.loading)
                this.roofingChanges = true;
        },
        roofingStates: {
            handler() {
                if (!this.loading)
                    this.roofingChanges = true;
            },
            deep: true
        },
        roofingCounties: {
            handler() {
                if (!this.loading)
                    this.roofingChanges = true;
            },
            deep: true
        },
    },
    data: function () {
        return {
            dateFilterKey: 0,
            industryFilterKey: 0,
            stateFilterKey: 0,
            countyFilterKey: 0,
            solarEnabled: false,
            roofingEnabled: false,
            solarChanges: false,
            roofingChanges: false,
            solarStates: [],
            solarCounties: [],
            roofingStates: [],
            roofingCounties: [],
            statistics: {
                salesbaits: null,
                clicks: null,
                actionsTaken: null
            },

            salesBaits: [],
            paginationData: null,
            salesBaitLoading: false,

            industries: [],
            states: [],
            counties: [],
            selectedIndustry: '',
            selectedState: '',
            selectedCounty: '',
            periodStartDate: '',
            periodEndDate: '',
            loading: false,
            apiService: null,
            sharedApiService: null,
            metricsChartTile: {
                metrics: {
                    A: 'Sales Baits',
                    B: 'Clicks',
                    C: 'Actions Taken'
                }
            }
        }
    },
    setup() {
        const dateRange = ref();
        const backDays  = 7;
        const endDate = new Date();
        const startDate = new Date(new Date().setDate(endDate.getDate() - backDays));

        onMounted(() => {
            dateRange.value = [startDate, endDate];
        })

        return {
            dateRange,
            backDays,
            startDate,
            endDate
        }
    },
    created() {
        this.apiService = ApiService.make();
        this.sharedApiService = SharedApiService.make();

        this.getIndustries();
        this.getStates();
        this.processRequest();
        this.load();
    },
    methods: {
        load() {
            this.loading = true;
            this.solarStates = [];
            this.solarCounties = [];
            this.roofingStates = [];
            this.roofingCounties = [];

            this.apiService.getGlobalConfiguration().then(resp => {
                const data = resp.data.data;
                this.solarEnabled = Object.values(data.solar).find(datum => datum.type === TYPE_NATIONAL)?.enabled ?? false
                this.roofingEnabled = Object.values(data.roofing).find(datum => datum.type === TYPE_NATIONAL)?.enabled ?? false

                this.solarStates = Object.values(data.solar).filter(datum => datum.type === TYPE_STATE);
                this.roofingStates = Object.values(data.roofing).filter(datum => datum.type === TYPE_STATE);
            }).catch(e => console.log(e)).finally(() => this.loading = false);
        },
        loadState(state, industry) {
            this.loading = true;

            this.apiService.getStateConfiguration(state, industry).then(resp => {
                switch (industry) {
                    case 'solar':
                        this.solarCounties = resp.data.data;
                        break;
                    case 'roofing':
                        this.roofingCounties = resp.data.data;
                        break;
                }
            }).catch(e => console.log(e)).finally(() => this.loading = false);
        },
        returnToTopLevel(industry) {
            switch (industry) {
                case 'solar':
                    this.solarCounties = [];
                    break;

                case 'roofing':
                    this.roofingCounties = [];
                    break;
            }
        },
        saveSolar() {
            this.loading = true;
            this.roofingChanges = false;
            this.solarChanges = false;

            this.apiService.save('solar', {
                industry: this.solarEnabled,
                locations: [...this.solarStates, ...this.solarCounties]
            }).finally(() => this.load());
        },
        saveRoofing() {
            this.loading = true;
            this.roofingChanges = false;
            this.solarChanges = false;

            this.apiService.save('roofing', {
                industry: this.roofingEnabled,
                locations: [...this.roofingStates, ...this.roofingCounties]
            }).finally(() => this.load());
        },
        processRequest() {
            if(!this.periodStartDate || !this.periodEndDate) {
                this.periodStartDate = this.startDate.getFullYear() + '-' + (this.startDate.getMonth() + 1) + '-' + this.startDate.getDate();
                this.periodEndDate = this.endDate.getFullYear() + '-' + (this.endDate.getMonth() + 1) + '-' + this.endDate.getDate();
            }

            this.getStatistics();
            this.getSalesBaits();
        },
        processDatePeriod() {
            const period = [];
            this.dateRange.map(function(date) {
                period.push(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
            });

            this.periodStartDate   =  period[0] ?? '';
            this.periodEndDate     =  period[1] ?? '';
        },
        async getSalesBaits(page = 1) {
            this.salesBaitLoading = true;
            let params = {
                page: page,
                industry: this.selectedIndustry,
                state: this.selectedState,
                county: this.selectedCounty,
                start_date: this.periodStartDate,
                end_date:this.periodEndDate,
            };
            await this.apiService.getSalesBaits(params).then(resp => {
                let {data, ...paginationData} = resp.data.data.salesBaits;
                this.salesBaits = data;
                this.paginationData = paginationData;
            }).catch(e => console.error(e)).finally(() => this.salesBaitLoading = false);
        },
        getIndustries() {
            this.sharedApiService.getIndustries().then(resp => {
                if(resp.data.data.status === true) {
                    this.industries = resp.data.data.industries ?? [];
                }
            }).catch(e => console.log(e));
        },
        getStates() {
            this.sharedApiService.getStates().then(resp => {
                this.states = resp.data.data ?? [];
            }).catch(e => console.log(e));
        },
        getCounties() {
            this.selectedCounty = '';
            this.sharedApiService.getCounties(this.selectedState).then(resp => {
                this.counties = resp.data.data ?? [];
            }).catch(e => console.log(e));
        },
        getStatistics() {
            this.sharedApiService.getStatistics('?'
                + 'industry='       + this.selectedIndustry
                + '&state='         + this.selectedState
                + '&county='        + this.selectedCounty
                + '&start_date='    + this.periodStartDate
                + '&end_date='      + this.periodEndDate).then(resp => {
                this.statistics = resp.data.data ?? [];
            }).catch(e => console.log(e));
        },
        applyFilters() {
            this.getStatistics();
            this.getSalesBaits();
        },
        clearFilters() {
            // @TODO: Dropdown components do not currently update selectedOption based on modelValue. Reload components for now.
            this.$forceUpdate();
            this.dateFilterKey += 1;
            this.industryFilterKey += 1;
            this.stateFilterKey += 1;
            this.countyFilterKey += 1;

        }
    }
}
</script>

<style scoped>
    .dp__theme_dark {
        --dp-background-color: rgb(13, 26, 38);
        --dp-hover-color: #484848;
        --dp-hover-text-color: #4D87C1;
        --dp-hover-icon-color: #959595;
        --dp-primary-color: #005cb2;
        --dp-primary-text-color: #4D87C1;
        --dp-secondary-color: #a9a9a9;
        --dp-border-color: rgb(0, 55, 95);
        --dp-menu-border-color: #2d2d2d;
        --dp-border-color-hover: #418FDB;
        --dp-disabled-color: #737373;
        --dp-scroll-bar-background: #212121;
        --dp-scroll-bar-color: #484848;
        --dp-success-color: #00701a;
        --dp-success-color-disabled: #428f59;
        --dp-icon-color: rgb(0, 129, 255);
        --dp-danger-color: #e53935;
    }
    .dp__theme_light {
        --dp-background-color: rgb(249, 249, 249);
        --dp-hover-color: #ECECEC;
        --dp-hover-text-color: #212121;
        --dp-hover-icon-color: #1976d2;
        --dp-border-color: rgb(202, 207, 211);
        --dp-icon-color: rgb(0, 129, 255);
    }
</style>
