<template>
    <div>
        <div class="main-layout font-body">
            <top-bar v-if="showTopBar" :content="topBarContent"></top-bar>
            <div class="w-full">
                <div class="w-full flex-auto relative"
                     :class="{'bg-light-background': !darkMode, 'bg-dark-background': darkMode, 'min-h-screen': fullPage}">
                    <div class="rounded-lg pb-8 "
                         :class="{'text-grey-120': darkMode}">
                        <div class="flex items-center justify-between flex-wrap py-6 px-8 sticky bg-opacity-90 top-16 z-40 border-b"
                             :class="{'bg-light-module  border-light-border': !darkMode, 'bg-dark-module text-white  border-dark-border': darkMode}"
                        >
                            <div class="flex items-center">
                                <a href="/tasks"><h3 class="text-xl font-medium pb-0 leading-none text-grey-300" :class="[!darkMode ? 'text-grey-300' : 'text-grey-120']">Task</h3></a>
                                <svg class="mx-3" width="7" height="12" viewBox="0 0 7 12" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M0.341709 0.675083C0.79732 0.219471 1.53601 0.219471 1.99162 0.675083L6.65829 5.34175C6.87708 5.56054 7 5.85729 7 6.16671C7 6.47613 6.87708 6.77287 6.65829 6.99166L1.99162 11.6583C1.53601 12.1139 0.79732 12.1139 0.341709 11.6583C-0.113903 11.2027 -0.113903 10.464 0.341709 10.0084L4.18342 6.16671L0.341709 2.325C-0.113903 1.86939 -0.113903 1.13069 0.341709 0.675083Z"
                                          fill="#AFAFAF"/>
                                </svg>
                                <!-- TODO: Title will be dynamic: [Task Name]: [Company Name] -->
                                <h3 v-if="task && !loading" class="text-xl font-medium pb-0 leading-none">
                                    {{ task.subject }}
                                </h3>
                                <button @click="showEditTaskSubjectModal = true" class="pl-2" title="Edit Title">
                                    <svg width="12" height="12" viewBox="0 0 11 11" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M3.37773 10.2347L8.87785 4.7346L6.2654 2.12215L0.765271 7.62227C0.689551 7.69809 0.635763 7.79298 0.609613 7.89689L0 11L3.10251 10.3904C3.20668 10.3643 3.30197 10.3105 3.37773 10.2347V10.2347ZM10.6534 2.95903C10.8753 2.73705 11 2.43602 11 2.12215C11 1.80827 10.8753 1.50724 10.6534 1.28526L9.71474 0.346575C9.49276 0.124663 9.19173 0 8.87785 0C8.56397 0 8.26295 0.124663 8.04097 0.346575L7.10228 1.28526L9.71474 3.89772L10.6534 2.95903Z"
                                            fill="#0081FF"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="ml-auto inline-flex items-center">
                                <button v-if="task && !loading && allowsRescheduling" @click="showRescheduleModal = true"
                                    class="ml-2 transition duration-200 inline-flex items-center font-semibold bg-grey-475 hover:bg-grey-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5">
                                    <svg class="mr-2" width="14" height="14" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"/>
                                    </svg>
                                    Reschedule Task
                                </button>
                                <button v-if="task && !loading" @click="skipTask"
                                        class="ml-2 transition duration-200 inline-flex items-center font-semibold bg-grey-475 hover:bg-grey-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5">

                                    <svg class="mr-2" width="14" height="14" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.933 12.8a1 1 0 000-1.6L6.6 7.2A1 1 0 005 8v8a1 1 0 001.6.8l5.333-4zM19.933 12.8a1 1 0 000-1.6l-5.333-4A1 1 0 0013 8v8a1 1 0 001.6.8l5.333-4z" />
                                    </svg>
                                    Skip Task
                                </button>
                                <button v-if="task && !loading" @click="showReassignTaskModal = true"
                                        class="ml-2 transition duration-200 inline-flex items-center font-semibold bg-grey-475 hover:bg-grey-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5">
                                    <!--todo: add icon-->
                                    Reassign Task
                                </button>
                                <button-dropdown v-if="task && !loading" :options="task.results" @selected="resultSelected">
                                    <button
                                            class="ml-2 transition duration-200 inline-flex items-center font-semibold bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5">
                                        <svg class="mr-2" width="14" height="14" viewBox="0 0 14 14" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0ZM5.6007 10.0891L3.0016 7.4956L3.99 6.5044L5.5993 8.1109L9.3051 4.4051L10.2949 5.3949L5.6007 10.0891V10.0891Z"
                                                fill="white"/>
                                        </svg>
                                        Complete Task
                                    </button>
                                </button-dropdown>
                                <div class="ml-2" v-if="task && !loading">
                                    <delete-task :task="task" :dark-mode="darkMode" @task-deleted="getTask">
                                        <template v-slot:button>
                                            <button class="transition duration-200 bg-red-400 hover:bg-red-800 text-white text-sm font-semibold focus:outline-none py-2 rounded-md px-5">
                                                Delete task
                                            </button>
                                        </template>
                                    </delete-task>
                                </div>
                            </div>

                            <div v-if="task && !loading" class="mt-3 w-full">
                                <p class="text-sm">
                                    Priority:
                                    <span v-if="!showEditPriorityOptions">
                                        <span v-if="task.priority<=1" class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">Low</span>
                                        <span v-else-if="task.priority==2" class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800">Medium</span>
                                        <span v-else-if="task.priority==3" class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-orange-100 text-orange-800">High</span>
                                        <span v-else class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">Urgent</span>
                                        <button @click="allowPriorityUpdate" class="pl-2" title="Edit Priority">
                                            <svg width="12" height="12" viewBox="0 0 11 11" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M3.37773 10.2347L8.87785 4.7346L6.2654 2.12215L0.765271 7.62227C0.689551 7.69809 0.635763 7.79298 0.609613 7.89689L0 11L3.10251 10.3904C3.20668 10.3643 3.30197 10.3105 3.37773 10.2347V10.2347ZM10.6534 2.95903C10.8753 2.73705 11 2.43602 11 2.12215C11 1.80827 10.8753 1.50724 10.6534 1.28526L9.71474 0.346575C9.49276 0.124663 9.19173 0 8.87785 0C8.56397 0 8.26295 0.124663 8.04097 0.346575L7.10228 1.28526L9.71474 3.89772L10.6534 2.95903Z"
                                                    fill="#0081FF"/>
                                            </svg>
                                        </button>
                                    </span>
                                    <span v-else>
                                        <Dropdown :dark-mode="darkMode" class="inline-flex items-center w-32" v-model="taskPriority" :options="taskPriorities" :selected="taskPriority"></Dropdown>
                                        <button @click="updatePriority" class="pl-2 inline-flex items-center" title="Save">
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0ZM5.6007 10.0891L3.0016 7.4956L3.99 6.5044L5.5993 8.1109L9.3051 4.4051L10.2949 5.3949L5.6007 10.0891V10.0891Z"
                                                    fill="#0081FF"/>
                                            </svg>
                                        </button>
                                        <button @click="restrictPriorityUpdate" class="ml-3 inline-flex items-center" title="Cancel">
                                            <svg width="14" height="14" viewBox="0 0 14 10" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.9035 4.78018L9.32136 1.36237C9.47273 1.21896 9.56036 1.01977 9.56036 0.796696C9.56036 0.358513 9.20184 0 8.76366 0C8.54058 0 8.34139 0.0876191 8.19799 0.231024L4.78018 3.65686L1.36237 0.231024C1.21896 0.0876191 1.01977 0 0.796696 0C0.358513 0 0 0.358513 0 0.796696C0 1.01977 0.0876191 1.21896 0.231024 1.36237L3.65686 4.78018L0.238999 8.19799C0.0876269 8.34139 0 8.54058 0 8.76366C0 9.20184 0.358513 9.56036 0.796696 9.56036C1.01977 9.56036 1.21896 9.47274 1.36237 9.32933L4.78018 5.9035L8.19799 9.32136C8.34139 9.47273 8.54058 9.56036 8.76366 9.56036C9.20184 9.56036 9.56036 9.20184 9.56036 8.76366C9.56036 8.54058 9.47274 8.34139 9.32933 8.19799L5.9035 4.78018Z"
                                                    fill="#0081FF"/>
                                            </svg>
                                        </button>
                                    </span>
                                </p>
                                <p class="mt-3 text-sm font-medium leading-none text-grey-300" :class="[!darkMode ? 'text-grey-300' : 'text-grey-120']">Due: {{ $filters.dateFromTimestamp(task.available_at, 'usWithTime') }}</p>
                            </div>

                            <!-- Overview Modules Only -->
                            <div v-if="overviewModules.length > 0" class="mt-4 w-full">
                                <template v-if="task && !loading" v-for="module in overviewModules">
                                        <company-overview :company-id="companyId"
                                                          v-if="module.id == 'company-overview'" :dark-mode="darkMode"/>
                                </template>
                            </div>
                        </div>
                        <div class="py-4 px-8"
                             :class="{'bg-light-background border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
                            <div v-if="task && !loading" class="grid grid-cols-6 gap-4">
                                <div class="col-span-2">
                                    <task-notes :task-notes="notes" :dark-mode="darkMode" :task="task"></task-notes>
                                </div>
                                <div v-for="module in displayModules" :class="'col-span-' + module.columns">
                                    <Contacts :company-id="companyId"
                                              v-if="module.id == 'company-contacts'" :dark-mode="darkMode" :show-add-contact-button="true" :has-edit-rights="true"/>
                                    <Campaigns :company-id="companyId"
                                              v-if="module.id == 'company-campaigns'" :dark-mode="darkMode"/>
                                    <Activity :company-id="companyId" :task="task" :show-filters="true"
                                              v-if="module.id == 'company-actions'" :dark-mode="darkMode"/>
                                    <Leads :company-id="companyId"
                                              v-if="module.id == 'company-leads'" :dark-mode="darkMode"/>
                                    <InvoiceBilling :company-id="companyId"
                                              v-if="module.id == 'company-invoices'" :dark-mode="darkMode"/>
                                    <ModularBasicInfo :lead-id="leadId" :dark-mode="darkMode" v-if="module.id == 'lead-basic-info'"></ModularBasicInfo>
                                    <ModularInteractiveMap :lead-id="leadId" :dark-mode="darkMode" v-if="module.id == 'lead-interactive-map'"></ModularInteractiveMap>
                                    <ModularRelatedActivity :lead-id="leadId" :dark-mode="darkMode" v-if="module.id == 'lead-related-activity'"></ModularRelatedActivity>
                                    <CompaniesAlreadySoldTo :dark-mode="darkMode" :lead-id="leadId" v-if="module.id == 'companies-already-sold-to'"></CompaniesAlreadySoldTo>
                                    <CompaniesServingArea class="h-1/4" :task="task" :dark-mode="darkMode" :lead-id="leadId" v-if="module.id == 'companies-servicing'"></CompaniesServingArea>
                                    <unsold-and-undersold-leads :dark-mode="darkMode" :lead-id="leadId" v-if="module.id == 'unsold-and-undersold-leads'"></unsold-and-undersold-leads>
                                </div>
                            </div>
                            <div v-if="loading">
                                <loading-spinner v-if="loading" :dark-mode="darkMode"/>
                            </div>
                            <div v-if="!loading && !task">
                                <div class="h-full flex flex-col justify-center items-center overflow-y-auto pt-10"
                                     :class="{'bg-light-background  border-light-border text-green-300': !darkMode, 'text-green-400 bg-dark-background border-dark-border': darkMode}">
                                    <div class="flex justify-center mb-3">
                                        <svg  class="fill-current" width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M40 0C17.944 0 0 17.7571 0 39.5833C0 61.4096 17.944 79.1667 40 79.1667C62.056 79.1667 80 61.4096 80 39.5833C80 17.7571 62.056 0 40 0ZM32.004 57.0515L17.152 42.3858L22.8 36.7808L31.996 45.8652L53.172 24.9098L58.828 30.5069L32.004 57.0515V57.0515Z"/>
                                        </svg>
                                    </div>
                                    <p class="font-semibold">
                                        All Tasks Completed
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal v-if="completeResult && selectedResult" :small="true" @close="toggleCompleteTaskModal" :confirm-text="'Submit Task'"
               :close-text="'Cancel'" @confirm="updateTaskStatus">
            <template v-slot:header>
                <h4 class="text-xl font-medium">{{  completeResult?.options?.text ?? "Enter Details:" }}</h4>
            </template>
            <template v-slot:content>
                <div class="flex items-center justify-center">
                    <div class="grid grid-cols-1 gap-2 w-full" v-if="['text', 'reschedule'].includes(completeResult.type)">
                        <textarea
                            class="min-h-88 w-full border rounded pl-4  focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                            placeholder="Enter notes..." type="text" v-model="selectedResult.value"
                            :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>
                    </div>

                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 my-4 w-full" v-if="completeResult.type === 'select' && !resultTypeSelectCrm">
                        <button
                            v-for="option in completeResult.options.values" @click="selectOption(option)"
                            class="transition duration-200 text-sm font-medium focus:outline-none py-2 rounded-md px-5 font-bold"
                            :class="optionClasses(option)"
                        >
                            {{ option.value }}
                        </button>
                    </div>
                    <div class="grid grid-cols-1 gap-4 my-4 w-full" v-if="completeResult.type === 'crm-entry' || resultTypeSelectCrm">
                        <div>
                            Subject
                            <input v-model="crmSubject" class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                                   placeholder="Enter subject" type="text"
                                   :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>
                        </div>
                        <div>
                            Notes
                            <textarea
                                class="min-h-88 w-full border rounded pl-4  focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                                placeholder="Enter notes..." type="text" v-model="crmNote"
                                :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>
                        </div>
                        <div>
                            CRM Target
                            <Dropdown :options="crmOptions" v-model="selectedCrmOption" :key="selectedCrmOption"></Dropdown>
                        </div>
                        <div v-if="selectedCrmOption === 'company_contact'">
                            Company Contact
                            <Dropdown :placeholder="'Select'" :options="companyContacts" v-model="selectedCompanyContact"></Dropdown>
                        </div>
                        <div class="grid grid-cols-1 gap-4 my-4 w-full">
                            <p class="uppercase font-semibold text-xs" :class="[darkMode ? 'text-slate-100' : 'text-slate-900']">Category</p>
                            <div class="my-2">
                                <Dropdown
                                    :placeholder="categories.placeholder"
                                    :dark-mode="darkMode"
                                    :options="categories.options"
                                    v-model="categoryId"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
        <Modal v-if="showDatePickerModal" :small="true" @close="showDatePickerModal = false" :no-buttons="true" :full-width="false">
            <template v-slot:header>
                <h4 class="text-xl font-medium">Select Day</h4>
            </template>
            <template v-slot:content>
                <Datepicker :inline="true" @update:modelValue="rescheduleTaskCustomDateTime" :format="'yyyy-MM-dd HH:mm'"></Datepicker>
            </template>
        </Modal>
        <modal :dark-mode="darkMode" v-if="showRescheduleModal" :small="true" @close="showRescheduleModal = false" @confirm="rescheduleTask">
            <template v-slot:header>
                <h4 class="text-xl font-medium">Reschedule</h4>
            </template>
            <template v-slot:content>
                <div class="flex justify-center mb-4">
                    <div class="grid grid-cols-2 gap-5">
                        <div>
                            <ButtonDropdown :options="rescheduleOptions" @selected="rescheduleOptionSelected" :dark-mode="darkMode">
                                <button
                                    class="w-full transition duration-200 inline-flex items-center font-semibold bg-grey-475 hover:bg-grey-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5">
                                    <svg class="mr-2" width="14" height="14" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"/>
                                    </svg>
                                    Choose a Time to Reschedule
                                </button>
                            </ButtonDropdown>
                        </div>
                        <div>
                            <div class="relative inline-block text-left">
                                <button
                                    class="w-full transition duration-200 inline-flex items-center font-semibold bg-grey-475 hover:bg-grey-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5"
                                    @click="addRescheduleNote = !addRescheduleNote">
                                    <svg class="mr-2" width="14" height="14" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                                    </svg>
                                    Add a Reason for Reschedule
                                </button>
                            </div>
                        </div>
                        <div class="col-span-2 flex items-center" v-if="rescheduleOption">
                            <div class="w-2 h-2 rounded-full bg-primary-500 mr-2 font-medium"></div>
                            Reschedule For: <span class="ml-1 text-blue-550 font-semibold">{{ rescheduleOption.id === 'custom_date_time' ? $filters.dateFromTimestamp(customRescheduleDateTime, 'long') : rescheduleOption.name + ' From Now' }}</span>
                        </div>
                        <div class="col-span-2" v-if="addRescheduleNote">
                <textarea class="w-full border rounded focus:outline-none focus:border focus:border-primary-500 py-2" placeholder="Reason" type="text" v-model="rescheduleNote"
                          :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>
                        </div>
                    </div>
                </div>
            </template>
        </modal>
        <Modal :dark-mode="darkMode" v-if="showReassignTaskModal" :small="true" @close="showReassignTaskModal = false" @confirm="reassignTask" @cancel="showReassignTaskModal = false"
               :close-text="'Cancel'" :confirm-text="'Assign'">
            <template v-slot:header>
                <h4 class="text-xl font-medium">Reassign Task</h4>
            </template>
            <template v-slot:content>
                <div class="grid grid-cols-2 gap-5 mt-6 mb-8">
                    <div>
                        <p class="text-sm font-medium mb-1">Select Team</p>
                        <dropdown :dark-mode="darkMode" :options="teams.map(team => team.name)" v-model="selectedTeam" :key="selectedTeam" :placeholder="'Select'"></dropdown>
                    </div>
                    <div>
                        <p class="text-sm font-medium mb-1">Select Team Member</p>
                        <dropdown :dark-mode="darkMode" :options="teamMembers" v-model="selectedTeamMember" :key="selectedTeamMember" :placeholder="'Select'"></dropdown>
                    </div>
                </div>

            </template>
        </Modal>
        <Modal :dark-mode="darkMode" v-if="showEditTaskSubjectModal" :small="true" @close="showEditTaskSubjectModal = false" @confirm="renameTask" @cancel="showEditTaskSubjectModal = false"
               :close-text="'Cancel'" :confirm-text="'Save'">
            <template v-slot:header>
                <h4 class="text-xl font-medium">Task Name</h4>
            </template>
            <template v-slot:content>
                <div class="grid gap-3">
                    <div class="flex items-center">
                        <p class="uppercase font-semibold text-xs w-20"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Name
                        </p>
                        <input class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                               placeholder="Task Name"
                               v-model="task.subject"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                    </div>
                </div>
            </template>
        </Modal>
        <alerts-container :dark-mode="darkMode" :alert-type="alert.type" :text="alert.text" v-if="alert.text !== null && alert.type !== null"></alerts-container>
    </div>
</template>

<script>
import Modal from "../Shared/components/Modal";
import Contacts from "../Shared/modules/Contacts";
import LoadingSpinner from "../Shared/components/LoadingSpinner";
import ApiService from "./services/api";
import Campaigns from "../Companies/Campaigns";
import Activity from "../Shared/modules/Activity";
import Leads from "../Shared/modules/Leads";
import ButtonDropdown from "../Shared/components/ButtonDropdown";
import CompanyOverview from "../Shared/modules/CompanyOverview";
import Dropdown from "../Shared/components/Dropdown";
import SharedApiService from "../Shared/services/api";
import TaskNotes from "./TaskNotes";
import InvoiceBilling from "../Shared/modules/InvoiceBilling";
import ModularBasicInfo from "../LeadProcessing/components/modular/ModularBasicInfo";
import CompaniesAlreadySoldTo from "../Shared/modules/CompaniesAlreadySoldTo";
import ModularInteractiveMap from "../LeadProcessing/components/modular/ModularInteractiveMap";
import CompaniesServingArea from "../Shared/modules/CompaniesServingArea";
import AlertsContainer from "../Shared/components/AlertsContainer";
import TopBar from "../Shared/components/TopBar";
import DeleteTask from "./DeleteTask";
import ModularRelatedActivity from "../LeadProcessing/components/modular/ModularRelatedActivity";
import UnsoldAndUndersoldLeads from "../Shared/modules/UnsoldAndUndersoldLeads";
import Datepicker from "@vuepic/vue-datepicker";

export default {
    name: "Tasks",

    components: {
        TopBar,
        AlertsContainer,
        CompaniesServingArea,
        ModularInteractiveMap,
        CompaniesAlreadySoldTo,
        ModularBasicInfo,
        ModularRelatedActivity,
        TaskNotes,
        CompanyOverview,
        Dropdown,
        ButtonDropdown,
        Activity,
        Campaigns,
        Contacts,
        Modal,
        LoadingSpinner,
        Leads,
        InvoiceBilling,
        DeleteTask,
        UnsoldAndUndersoldLeads,
        Datepicker
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        fullPage: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            api: ApiService.make(),
            sharedApi: SharedApiService.make(),
            completeTaskModal: false,
            results: [
                {
                    id: "contacted",
                    name: 'Was able to contact company'
                },
                {
                    id: "no_answer",
                    name: 'Company did not answer'
                }
            ],
            selectedResult: null,
            task: null,
            notes: [],
            taskPayload: null,
            runningWorkflowId: null,
            allowsRescheduling: false,
            modules: [],
            loading: false,
            completeResult: null,
            rescheduleOptions: [
                {id: 'one_hour', name: 'One Hour'},
                {id: 'one_day', name: 'One Day'},
                {id: 'one_week', name: 'One Week'},
                {id: 'one_month', name: 'One Month'},
                {id: 'custom_date_time', name: 'Custom (Date & Time)'},
            ],
            teams: [],
            selectedTeam: null,
            selectedTeamMember: null,
            showDatePickerModal: false,
            showRescheduleModal: false,
            addRescheduleNote: false,
            rescheduleOption: null,
            customRescheduleDateTime: null,
            rescheduleNote: null,
            selectedOptionIndex: null,
            showReassignTaskModal: false,
            crmOptions: [
                {id: 'company', name: 'Company'},
                {id: 'company_contact', name: 'Company Contact'}
            ],
            companyContacts: [],
            selectedCrmOption: 'company',
            selectedCompanyContact: null,
            selectedTasks: [],
            manualQueue: false,
            crmSubject: null,
            crmNote: null,
            resultTypeSelectCrm: false,
            showEditTaskSubjectModal: false,
            taskPriorities: [
                { id: 1, name: 'Low'   },
                { id: 2, name: 'Medium'},
                { id: 3, name: 'High'  },
                { id: 4, name: 'Urgent'}
            ],
            showEditPriorityOptions: false,
            taskPriority: null,
            alert: {type: null, text: null},
            showTopBar: false,
            tasksEnqueued: 0,
            tasksCompleted: 0,

            categories: {
                options: [],
                placeholder: 'Select Category',
            },

            categoryId: null,

            errorMessages: null,
        }
    },
    created: function () {
        const params = new URLSearchParams(window.location.search);

        if (params.has('tasks')) {
            this.selectedTasks = params.get('tasks').split(',');

            if (this.selectedTasks.length > 1) {
                this.showTopBar    = true;
                this.tasksEnqueued = this.selectedTasks.length;
            }

            if (this.selectedTasks.length > 0) this.manualQueue = true;
        }

        this.getTask();
        this.getTeams();
        this.getActionCategories();
    },
    computed: {
        teamMembers: function () {
            if (!this.selectedTeam) {
                return [];
            }

            return this.teams.find((team) => team.name === this.selectedTeam).members;
        },
        displayModules: function () {
            return this.modules.filter((module) => !module.id.includes('overview'));
        },
        overviewModules: function () {
            return this.modules.filter((module) => module.id.includes('overview'));
        },
        companyId: function () {
            const companyId = this.taskPayload?.event?.event_data?.company_id;

            if (companyId) return companyId;

            return this.task?.payload?.company_id;
        },
        leadId: function() {
            const leadId = this.taskPayload?.event?.event_data?.lead_id;

            if (leadId) return leadId;

            return this.task?.payload?.lead_id;
        },
        topBarContent: function() {
            return "Tasks " + this.tasksCompleted + "/" + this.tasksEnqueued;
        }
    },
    watch: {
        selectedTeam: function () {
            this.selectedTeamMember = null;
        }
    },
    methods: {
        async getActionCategories() {
            this.sharedApi.getActionCategories().then(response => {
                if (response.data.data.status) { this.categories.options = response.data.data.categories; }
                else { this.showAlert('error', this.errorMessages.getCategories); }
            }).catch(err => {
                this.showAlert('error', err.response?.data?.message || this.errorMessages.getCategories);
            });
        },
        toggleCompleteTaskModal() {
            this.completeTaskModal = !this.completeTaskModal;

            if(!this.completeTaskModal)
                this.completeResult = null;
        },
        getTask() {
            this.loading = true;

            this.api.getTask(this.getNextTask()).then(res => {
                if (res.data.data.status === true) {
                    this.task = res.data.data.task;
                    this.notes = res.data.data.notes;
                    this.taskPayload = res.data.data.task_payload;
                    this.modules = res.data.data.modules;
                    this.runningWorkflowId = res.data.data.running_workflow_id;
                    this.allowsRescheduling = this.task.allows_rescheduling ?? false;
                    this.taskPriority = this.task.priority;

                    this.getCompanyContacts();
                }
            }).catch(err => {

            }).finally(() => {
                this.loading = false;
            });
        },

        getNextTask() {
            if (this.manualQueue) {
                this.updateUrl();

                const taskId = this.selectedTasks.pop();

                if (taskId) return taskId;

                window.location.assign('/tasks');

                return null;
            }

            return null;
        },

        updateUrl() {
            if (!this.selectedTasks.length) return;

            const params = new URLSearchParams();

            params.append('tasks', this.selectedTasks.join(','));

            window.history.replaceState(null, '', window.location.href.split('?')[0]+ '?' + params.toString());
        },

        getTeams() {
            this.api.getTeams().then(resp => {
                this.teams = resp.data.data;

                if (this.teams.length > 0) {
                    this.selectedTeam = this.teams[0].name;
                }
            });
        },
        updateTaskStatus() {
            if (this.selectedResult == null) {
                return;
            }

            if (this.completeResult?.type === 'crm-entry' || this.resultTypeSelectCrm) {
                if (!this.crmSubject || !this.crmNote) {
                    return;
                }

                if (this.selectedCrmOption === 'company_contact' && !this.selectedCompanyContact) {
                    return;
                }

                this.createAction();

                if (this.completeResult?.type === 'crm-entry') this.selectedResult.value = this.crmNote;
            }

            this.handleTaskUpdate();
        },
        handleTaskUpdate() {
            this.loading = true;
            this.completeTaskModal = false;

            if (this.task.manual) {
                this.handleManualTaskUpdate(this.completeResult ? this.completeResult.type : null);
                return;
            }

            this.completeResult = null;

            this.api.updateWorkflowStatus(this.task.id, this.runningWorkflowId, this.selectedResult.id, this.selectedResult.value).then(res => {
                if (res.data.data.status === true) {
                    this.tasksCompleted++;
                    this.getTask();
                }
            }).catch(err => {
                this.loading = false;
            });
        },
        handleManualTaskUpdate(type) {
            this.api.updateManualTaskStatus(this.task.id, type, this.selectedResult.value).then(resp => {
                if (resp.data.data.status === true) {
                    this.tasksCompleted++;
                    this.getTask();
                }
            }).catch(err => {
                this.loading = false;
            });
        },
        createAction() {
            this.api.saveAction(
                0,
                this.task.id,
                this.selectedCrmOption === 'company' ? this.companyId : this.selectedCompanyContact,
                this.selectedCrmOption,
                this.crmSubject,
                this.crmNote,
                this.categoryId,
            );
        },
        resultSelected(option) {
            this.selectedResult = {...option, value: null};
            this.resetCrmModal();

            if(option.type === 'no-action')
                this.updateTaskStatus();
            else
                this.selectResult(option);
        },
        selectResult(result) {
            this.completeResult = result;
            this.completeTaskModal = true;
        },
        skipTask() {
            this.api.skipTask(this.task.id).then(resp => this.getTask());
        },
        reassignTask() {
            if (!this.selectedTeamMember) {
                return;
            }

            this.api.reassignTask(this.task.id, this.selectedTeamMember).then(resp => this.getTask());

            this.showReassignTaskModal = false;
        },
        rescheduleOptionSelected(option) {
            if (option.id === 'custom_date_time') {
                this.showDatePickerModal = true;
                this.showRescheduleModal = false;
            }
            this.rescheduleOption = option;
        },
        rescheduleTask() {
            this.showRescheduleModal = false;

            this.loading = true;
            this.api.rescheduleTask(this.task.id, this.rescheduleOption.id, this.customRescheduleDateTime, this.rescheduleNote).then(resp => {
                this.rescheduleOption = null;
                this.customRescheduleDateTime = null;
                this.addRescheduleNote = false;
                this.rescheduleNote = null;
                this.getTask();
            });
        },
        rescheduleTaskCustomDateTime(date) {
            this.customRescheduleDateTime = date.toISOString();
            this.showDatePickerModal = false;
            this.showRescheduleModal = true;
        },
        selectOption(option) {
            this.resultTypeSelectCrm = option.workflow === -2;
            this.selectedResult.value = option.workflow === 0 ? option.template : option.workflow;
            this.selectedOptionIndex = this.completeResult.options.values.indexOf(option);

            if (this.resultTypeSelectCrm) this.crmSubject = option.value
        },
        optionClasses(option) {
            const classes = [];

            if(this.selectedOptionIndex === this.completeResult.options.values.indexOf(option)) {
                if(this.darkMode) {
                    classes.push('bg-primary-500', 'text-white');
                }
                else {
                    classes.push('bg-cyan-150', 'hover:bg-blue-200', 'text-blue-550');
                }
            }
            else {
                if(this.darkMode) {
                    classes.push('bg-dark-module', 'border', 'border-primary-500', 'hover:bg-blue-800', 'text-grey-300');
                }
                else {
                    classes.push('bg-grey-100', 'hover:bg-grey-200', 'text-grey-475');
                }
            }

            return classes;
        },
        getCompanyContacts() {
            if (this.companyId) {
                this.sharedApi.getCompanyContacts(this.companyId).then(resp => this.companyContacts = resp.data.data.contacts);
            }
        },
        resetCrmModal() {
            this.resultTypeSelectCrm = false;
            this.crmNote = null;
            this.crmSubject = null;
            this.selectedOptionIndex = null;
        },
        renameTask() {
            if(this.priority) this.updatePriority();
            this.api.updateTaskSubject(this.task.id, this.task.subject).then(() => this.showEditTaskSubjectModal = false)
                .catch(e => this.showAlert("error", e.response.data.message));
        },
        updatePriority() {
            if(this.alert.type !== null || this.alert.text !== null) return;

            if(this.taskPriority === this.task.priority) {
                this.restrictPriorityUpdate();
                return;
            }

            this.api.updateTaskPriority(this.task.id, this.taskPriority).then(() => {
                this.showAlert("success", "Priority updated successfully!");
                this.showEditPriorityOptions = false
                this.task.priority = this.taskPriority;
            }).catch(e => this.showAlert("error", e.response.data.message));
        },
        allowPriorityUpdate() {
            this.showEditPriorityOptions = true;
        },
        restrictPriorityUpdate() {
            this.showEditPriorityOptions = false;
        },
        showAlert(type, text) {
            if(type === null || text === null) return;

            this.alert.type = type;
            this.alert.text = text;

            setTimeout(() => {
                this.alert.type = null;
                this.alert.text = null;
            }, 2000);
        }
    }
}
</script>

<style scoped>

</style>
