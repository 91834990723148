<template>
    <div class="fixed bottom-0 inset-x-0 z-40">
<!--        <recordings-wrapper-->
<!--            @toggle-recording-list="toggleRecordingList"-->
<!--            :recording-list="recordingList"-->
<!--            :dark-mode="darkMode"-->
<!--            :recordings="data.recordings"-->
<!--            v-if="data.recordings !== null && data.recordings.length > 0"-->
<!--            />-->
<!--        <voicemail-wrapper-->
<!--            @toggle-voicemail-list="toggleVoicemailList"-->
<!--            :voicemail-list="voicemailList"-->
<!--            :dark-mode="darkMode"-->
<!--            :voicemails="data.voicemails"-->
<!--            v-if="data.voicemails !== null && data.voicemails.length > 0"-->
<!--        />-->
        <notifications-wrapper
            :notifications-list="notificationsList"
            @toggle-notifications-list="toggleNotificationsList"
            @get-next-lead="requestLead"
            :api="api"
            ref="notificationWrapper"
            :dark-mode="darkMode"/>

<!--        <outgoing-call-->
<!--            :minimize-call="minimizeCall"-->
<!--            :call-active="callActive"-->
<!--            :muted="muted"-->
<!--            :on-hold="onHold"-->
<!--            :dark-mode="darkMode"-->
<!--            :lead-id="contactId"-->
<!--            :lead-name="contactName"-->
<!--            :lead-phone="contactPhone"-->
<!--            :status="status"-->
<!--            :session="null"-->
<!--            @minimize-call="toggleMinimizeCall"-->
<!--            @end-call="endCall"-->
<!--            @on-hold="toggleHold"-->
<!--            @muted="toggleMute"-->
<!--        />-->


<!--        <incoming-call-->
<!--            @answer-call="answerCall"-->
<!--            @end-call="declineCall"-->
<!--            :call-active="incoming !== null"-->
<!--            :lead-id="incomingId"-->
<!--            :lead-name="incomingName"-->
<!--            :lead-phone="incomingPhone"-->
<!--        />-->

<!--        <messenger-->
<!--            :messenger-active="messengerActive"-->
<!--            :messages="messages"-->
<!--            :dark-mode="darkMode"-->
<!--            :loading="messagesLoading"-->
<!--            :name="name"-->
<!--            ref="messenger"-->
<!--            @close-messenger="closeMessenger"-->
<!--            @send-message="sendMessage"-->
<!--        />-->
    </div>
</template>

<script>

import NotificationsWrapper from "./components/NotificationsWrapper";
import {ApiFactory} from "../LeadProcessing/services/api/factory";
import HandlesNotificationsMixin from "./mixins/handles-notifications-mixin";
// import OutgoingCall from "./components/OutgoingCall";
// import IncomingCall from "./components/IncomingCall";
import Messenger from "./components/Messenger";
import {DateTime} from "luxon";
// import VoicemailWrapper from "./components/VoicemailWrapper";
// import RecordingsWrapper from "./components/RecordingsWrapper";
import DispatchesGlobalEventsMixin from "../../mixins/dispatches-global-events-mixin";
// import CommunicationMixin from "../../mixins/communcation/communication-mixin";

export default {
    name: "LeadProcessingNotifications",
    props: {
        apiDriver: {
            type: String,
            default: 'dummy'
        },
        darkMode: {
            type: Boolean,
            default: false,
        }
    },
    data: function() {
        return {
            api: null,
            messengerActive: false,
            messages: null,
            messagesLoading: true,
            name: null,
            targetPhone: null,
            targetId: null,
            voicemailList: false,
            notificationsList: false,
            recordingList: false,
        }
    },
    components: {Messenger, NotificationsWrapper},
    created() {
        this.api = ApiFactory.makeApiService(this.apiDriver);

        // this.initializeEventListeners();
    },
    mixins: [HandlesNotificationsMixin, DispatchesGlobalEventsMixin],
    mounted() {},
    computed: {},
    methods: {
        requestLead(lead_id) {
            this.dispatchGlobalEvent('next-lead', {lead_id})
        },
        // onAttemptCall({phone, name, id}) {
        //     this.attemptCall({phone, name, id});
        //     // this.attemptCall(phone, name, id);
        // },
        // onAttemptSMS({phone, name, id}) {
        //     this.messagesLoading = true;
        //     this.messages = null;
        //     this.targetPhone = phone;
        //     this.targetId = id;
        //
        //     this.api.getSMSHistory(this.targetPhone).then(resp => {
        //         this.messages = resp.data.data.messages;
        //         this.messagesLoading = false;
        //         this.$refs.messenger.scrollDown();
        //     });
        //
        //     this.name = name;
        //     this.messengerActive = true;
        // },
        // initializeEventListeners() {
        //     this.listenForGlobalEvent('call', this.onAttemptCall.bind(this));
        //     this.listenForGlobalEvent('sms', this.onAttemptSMS.bind(this));
        // },
        closeMessenger() {
            this.messengerActive = false;
            this.targetPhone = null;
            this.targetId = null;
        },
        sendMessage(data) {
            const body = data.message;
            const formatted = {direction: 'outbound', first_name: 'You', last_name: '', message_body: body, timestamp: DateTime.now().toSeconds()};
            if(!this.messages)
                this.messages = [];

            this.messages.push(formatted);
            this.$refs.messenger.scrollDown();

            this.api.createOutboundSMS(this.targetId,body)
                .catch(e => console.error(e));
        },
        toggleNotificationsList() {
            this.notificationsList = ! this.notificationsList;
            this.voicemailList = false;
            this.recordingList = false;
        },
        addMessage(direction, first_name, last_name, message_body, timestamp) {
            this.messages.push({direction, first_name, last_name, message_body, timestamp});
            this.$refs.messenger.scrollDown();
        }
    }
}
</script>
