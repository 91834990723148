<template>
    <div class="grid grid-cols-2 mt-5">
        <div class="pl-1">
            <p class="uppercase font-semibold text-xs mb-1 mt-5"  :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                Delay
            </p>

            <Dropdown v-model="delay" :options="delayOptions" :dark-mode="darkMode" placeholder="Select option"></Dropdown>
        </div>
    </div>
</template>

<script>
import Dropdown from "../../../Shared/components/Dropdown";
import ShortcodeInput from "../../../Shared/components/ShortcodeInput";
import ApiService from "../../services/api";
import HasTargetRelations from "../../mixins/has-target-relations";

export default {
    name: "DelayNode",
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        action: {
            type: Object,
            default: null
        }
    },
    components: {
        ShortcodeInput,
        Dropdown
    },
    data() {
        return {
            api: ApiService.make(),
            delay: null,
            delayOptions: [
                {id: 30, name: '30 Seconds'},
                {id: 60, name: '1 Minute'},
                {id: 120, name: '2 Minutes'},
                {id: 180, name: '3 Minutes'},
                {id: 240, name: '4 Minutes'},
                {id: 300, name: '5 Minutes'},
                {id: 900, name: '15 Minutes'},
                {id: 1800, name: '30 Minutes'},
                {id: 3600, name: '1 Hour'},
                {id: 1440*60, name: '1 Day'},
                {id: 2880*60, name: '2 Days'},
                {id: 4320*60, name: '3 Days'},
                {id: 5760*60, name: '4 Days'},
                {id: 7200*60, name: '5 Days'},
                {id: 8640*60, name: '6 Days'},
                {id: 10080*60, name: '1 Week'},
                {id: 20160*60, name: '2 Weeks'},
                {id: 43800*60, name: '1 Month'},
            ]
        }
    },
    mixins: [HasTargetRelations],
    created() {
        if (this.action) {
            this.delay = this.action.payload.delay;
        }
    },
    watch: {
        action: function () {
            if (this.action) {
                this.delay = this.action.payload.delay;
            } else {
                this.delay = null;
            }
        }
    },
    methods: {
        getDisplayName() {
            return "Delay Node";
        },
        getPayload() {
            return {
                delay: this.delay,
            }
        },
    }
}
</script>

<style scoped>

</style>
