import axios from 'axios';

export default class Api {
    constructor(baseUrl, baseEndpoint, version) {
        this.baseUrl = baseUrl;
        this.baseEndpoint = baseEndpoint;
        this.version = version;
    }

    axios() {
        const axiosConfig = {
            baseURL: `/${this.baseUrl}/v${this.version}/${this.baseEndpoint}`
        }

        return axios.create(axiosConfig);
    }

    static make() {
        return new Api('internal-api', 'advertising', 1);
    }

    getPlatformAccounts(platform) {
        return this.axios().get(`accounts/${platform}`);
    }

    getPaginatedPlatformAccounts(platform, page = 1) {
        return this.axios().get(`accounts-paginated/${platform}`, {
            params: {
                page: page
            }
        });
    }

    initReferenceLists() {
        return this.axios().get('/init-reference-lists');
    }

    getPlatformReferenceLists(platform) {
        return this.axios().get(`/reference-lists/${platform}`);
    }

    saveAccount(platform, id, name, industry, websites, tracksConversions) {
        return this.axios().post(`accounts/${platform}`, {
            platform_account_id: id,
            name: name,
            industry: industry,
            websites: websites,
            tracks_conversions: tracksConversions
        });
    }

    getCampaignsPaginated(
        platform,
        accountId,
        page = 1,
        perPage = 10,
        state = null,
        costMetric = null,
        campaignStatus = null,
        pageTokens = null
    )
    {
        return this.axios().get(`campaigns-paginated/${platform}/${accountId}`, {
            params: {
                page: page,
                per_page: perPage,
                search_state_location_id: state,
                cost_metric: costMetric,
                campaign_status: campaignStatus,
                page_tokens: pageTokens
            }
        });
    }

    updateCampaigns(
        platform,
        accountId,
        campaigns
    ) {
        return this.axios().patch(`campaigns/${platform}/${accountId}`, {
            campaigns: campaigns
        });
    }

    deleteAccount(platform, accountId) {
        return this.axios().delete(`accounts/${platform}/${accountId}`);
    }

    getAuthenticationPlatforms() {
        return this.axios().get('token-auth-platforms');
    }

    getPlatformTokenAuthEndpoint(platform) {
        return this.axios().get(`token-auth-endpoint/${platform}`);
    }

    getAllPlatforms() {
        return this.axios().get('all-platforms');
    }
}
