<template>
    <alerts-container :dark-mode="darkMode" v-if="alertActive" :alert-type="alertType" :text="alertText"/>
    <p v-if="contactHasValidOfficePhone" @click="dial(contact, contact.office_phone)" class="text-blue-550 text-sm"
       :class="{'cursor-pointer': phoneIsValid(contact.office_phone)}">
        {{ $filters.formatPhoneNumber(contact.office_phone) || '-' }} (Office)</p>
    <p v-if="contactHasValidCellPhone" @click="dial(contact, contact.cell_phone)" class="text-blue-550 text-sm"
       :class="{'cursor-pointer': phoneIsValid(contact.cell_phone)}">
        {{ $filters.formatPhoneNumber(contact.cell_phone) || '-' }} (Mobile)</p>
    <p v-if="contactHasValidEmail" class="text-blue-550 text-sm col-span-2">{{ contact.email }} (Business)</p>
</template>

<script>
import DispatchesGlobalEventsMixin from "../../../../mixins/dispatches-global-events-mixin";
import {phoneIsValid} from "../../../../../modules/contacts/helpers";
import {mapState} from "pinia";
import {useCallStore} from "../../../../../stores/call.store";
import AlertsContainer from "../../components/AlertsContainer.vue";
import AlertsMixin from "../../../../mixins/alerts-mixin";

export default {
    name: "ContactDetails",
    components: {AlertsContainer},
    props: {
        contact: {
            type: Object,
            required: true
        },
        darkMode: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [DispatchesGlobalEventsMixin, AlertsMixin],
    methods: {
        /**
         * @param contact
         * @param phone
         */
        dial(contact, phone) {
            if (phoneIsValid(phone)) {
                if (!this.callActive) {
                    this.dispatchGlobalEvent('call', {phone: phone, name: contact.name, id: contact.id});
                } else {
                    console.warn('Please end your current call before making another one.')
                    this.showAlert('warning', 'Please end your current call before making another one.')
                }
            }
        },
        phoneIsValid
    },
    computed: {
        ...mapState(useCallStore, [
            'callActive'
        ]),
        contactHasValidEmail() {
            return this.contact.email && this.contact.email.length > 0;
        },
        contactHasValidOfficePhone() {
            return this.contact.office_phone && this.contact.office_phone.length > 0;
        },
        contactHasValidCellPhone() {
            return this.contact.cell_phone && this.contact.cell_phone.length > 0;
        }
    }
}
</script>

<style scoped>

</style>
