<template>
    <div class="border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="p-5">
            <div class="flex items-center justify-between pb-3">
                <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">{{ moduleTitle }}</h5>
                <div class="text-sm rounded-full flex items-center"
                     :class="{'text-grey-400': !darkMode, 'text-blue-400': darkMode}">
                    Total:
                    <div class="rounded-full text-sm pl-1 h-full font-medium">
                        {{ totalLeads }}</div>
                </div>
            </div>
            <div class="flex flex-wrap mb-5">
                <div class="flex items-center mr-8">
                    <div class="flex-shrink-0 w-3 h-3 mr-2 rounded-full bg-cyan-500"
                    ></div>
                    <p class="text-sm">Initial</p>
                </div>
                <div class="flex items-center mr-8">
                    <div class="flex-shrink-0 w-3 h-3 text-sm mr-1 rounded-full bg-yellow-500"
                    ></div>
                    <p class="text-sm">Pending</p>
                </div>
                <div class="flex items-center mr-8">
                    <div class="flex-shrink-0 w-3 h-3 text-sm mr-1 bg-orange-500 rounded-full"
                    ></div>
                    <p class="text-sm">Under Review</p>
                </div>
            </div>
            <div class="w-full" v-if="statistics.initial">
                <div class="flex items-center mb-4">
                    <div class="p-2 h-10 rounded-r "
                         :class="{'bg-cyan-500' : !darkMode, 'border-r-2 border-cyan-500 bg-dark-background': darkMode}"
                         :style="{width: calculatePercentage(statistics.initial) + '%'}">
                        <div class="flex justify-end items-center h-full mr-2 text-grey-120 font-bold"
                             v-if="calculatePercentage(statistics.initial) > 10">{{ Math.round(calculatePercentage(statistics.initial)) }}%</div>
                    </div>
                    <p class="ml-2 font-bold text-cyan-500">
                        {{ statistics.initial }}
                    </p>
                </div>

                <div class="flex items-center mb-4">
                    <div class="p-2 h-10 rounded-r"
                         :class="{'bg-yellow-500' : !darkMode, 'border-r-2 border-yellow-500 bg-dark-background': darkMode}"
                         :style="{width: calculatePercentage(statistics.pending_review) + '%'}">
                        <div class="flex justify-end items-center h-full mr-2 text-white font-bold"
                             v-if="calculatePercentage(statistics.pending_review) > 10">{{ Math.round(calculatePercentage(statistics.pending_review)) }}%</div>
                    </div>
                    <p class="ml-2 font-bold text-yellow-500">
                        {{ statistics.pending_review }}
                    </p>
                </div>

                <div class="flex items-center mb-4">
                    <div class="p-2 h-10 rounded-r "
                         :class="{'bg-orange-500' : !darkMode, 'border-r-2 border-orange-500 bg-dark-background': darkMode}"
                         :style="{width: calculatePercentage(statistics.under_review) + '%'}">
                        <div class="flex justify-end items-center h-full mr-2 text-white font-bold" v-if="calculatePercentage(statistics.under_review) > 10">{{ Math.round(calculatePercentage(statistics.under_review)) }}%</div>
                    </div>
                    <p class="ml-2 font-bold text-orange-500">
                        {{ statistics.under_review }}
                    </p>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PendingLeadsChart",
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        moduleTitle: {
          type: String,
          default: 'Pending Leads',
        },
        statistics: {
            default: () => ({})
        }
    },
    computed: {
      totalLeads() {
          return this.statistics.initial + this.statistics.pending_review + this.statistics.under_review;
      }
    },
    methods: {
        calculatePercentage(value) {
            const total = this.totalLeads;

            if(total === 0)
                return 100;

            return (value / total) * 100;
        }
    }
}
</script>

<style scoped>

</style>
