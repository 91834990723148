<template>
    <div class="border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="p-5 flex items-center justify-between">
            <h5 class="text-primary-500 text-sm uppercase font-semibold py-2 leading-tight">Restricted Companies</h5>
            <button
                    class="ml-2 transition duration-200 inline-flex items-center font-semibold bg-grey-475 hover:bg-grey-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5"
                    @click="openModal">
                Restrict Company
            </button>
        </div>
        <div class="grid grid-cols-3 gap-x-3 mb-2 px-5">
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Company</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1"></p>
        </div>
        <div>
            <div class="border-t border-b h-64 overflow-y-auto"
                 :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
                <loading-spinner v-if="loading"></loading-spinner>
                <div
                    class="grid grid-cols-3 gap-x-3 border-b px-5 py-3"
                    v-else
                    v-for="company in companies"
                    :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <a class="text-sm col-span-1 truncate hover:text-blue-550" :href="`/companies/${company.company_id}`">
                        {{ company.name }}
                    </a>
                    <p class="text-sm col-span-1 pl-1 truncate hover:text-blue-550"></p>
                </div>
            </div>
        </div>

        <modal
            :confirm-text="saving ? 'Saving...' : 'Restrict Company'"
            :close-text="'Cancel'"
            :dark-mode="darkMode"
            @close="closeModal"
            @confirm="addCompany"
            :small="true"
            v-if="showModal"
            :disable-confirm="saving"
        >
            <template v-slot:header>
                <h4 class="text-xl">Restrict Company</h4>
            </template>
            <template v-slot:content>
                <div class="mb-6" v-if="alertActive">
                    <alert :text="alertText" :alert-type="alertType" :dark-mode="darkMode"></alert>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-3">
                    <div>
                        <p class="uppercase font-semibold text-xs mb-2"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Company
                        </p>
                        <autocomplete
                            :dark-mode="darkMode"
                            class="col-span-2"
                            v-model="modalInputs.companyId"
                            :options="companyResults"
                            :placeholder="'Company name'"
                            :create-user-input-option="true"
                            @search="searchCompanies('companyname', $event)">
                        </autocomplete>
                    </div>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import Modal from "../Shared/components/Modal";
import Autocomplete from "../Shared/components/Autocomplete";
import SharedApiService from "../Shared/services/api";
import HasAlertsMixin, {AlertTypes}from "../Shared/mixins/has-alerts-mixin";
import Alert from "../Shared/components/Alert";
import ApiService from "./services/api";
import LoadingSpinner from "../Shared/components/LoadingSpinner";

export default {
    name: "RestrictedSalesBaitCompanies",
    mixins: [HasAlertsMixin],
    components: {LoadingSpinner, Alert, Autocomplete, Modal},
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            apiService: ApiService.make(),
            companies: [],
            companyResults: [],
            loading: false,
            modalInputs: {
                companyId: null,
            },
            saving: false,
            sharedApi: SharedApiService.make(),
            showModal: false,
        }
    },
    created() {
        this.load();
    },
    mounted() {
    },
    computed: {},
    methods: {
        load() {
            this.loading = true;

            this.apiService.getRestrictedCompanies()
                .then(resp => this.companies = resp.data.data.companies)
                .catch(e => {})
                .finally(() => this.loading = false);
        },
        openModal() {
            this.resetInputs();
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
            this.resetInputs();
        },
        resetInputs() {
            this.modalInputs.companyId = null;
        },
        searchCompanies(nameType, query) {
            this.sharedApi.searchCompanyNamesAndId(query).then(res => {
                if (res.data.data.status === true) {
                    this.companyResults = res.data.data.companies;
                }
            })
        },
        addCompany() {
            if(!this.modalInputs.companyId || isNaN(this.modalInputs.companyId))
                return this.showAlert(AlertTypes.error, "Please select a company to add.");

            this.saving = true;
            this.apiService.restrictCompany(this.modalInputs.companyId).then(resp => {
                this.closeModal();
                this.load();
            }).catch(e => this.showAlert(AlertTypes.error, `Failed to restrict company: ${e}`)).finally(() => this.saving = false);
        }
    }
}
</script>

<style lang="scss">

</style>
