<template>
    <div>
        <div class="grid grid-cols-1 gap-4">
            <div class="w-full flex flex-auto rounded-lg border overflow-hidden" style="height: 388px;"
                 :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
                <div ref="map" class="w-full h-full"></div>
            </div>
            <div class="flex items-center">
                <svg class="mr-1 flex-shrink-0" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.08902 3.11111H1.15557C0.518275 3.11111 0 3.62939 0 4.26669V10.0446C0 10.6819 0.518275 11.2001 1.15557 11.2001H8.08902C8.72632 11.2001 9.2446 10.6819 9.2446 10.0446V4.26669C9.2446 3.62939 8.72632 3.11111 8.08902 3.11111ZM1.15557 10.0446V5.42226H8.08902V4.26669L8.09018 10.0446H1.15557Z" fill="#0081FF"/>
                    <path d="M11.5557 1.9555C11.5557 1.3182 11.0375 0.799927 10.4002 0.799927H2.88892C2.25162 0.799927 1.73335 1.3182 1.73335 1.9555H9.24516C9.88131 1.9555 10.3984 2.47262 10.4002 3.10761L10.4013 7.73338H10.4002V8.88895C11.0375 8.88895 11.5557 8.37068 11.5557 7.73338V3.69291L11.5563 3.68886V2.53329L11.5557 2.52751V1.9555Z" fill="#0081FF"/>
                </svg>
                <a class="text-blue-550 text-sm" :href="mapLink" target="_new">Click here for map in new window</a>
            </div>
        </div>
    </div>
</template>

<script>
import {waitForCallbackToReturnTrue} from "../../../../utilities/loading-utilities";

export default {
    name: "InteractiveMap",
    props: {
        address: Object,
        darkMode: {
            type: Boolean,
            default: false,
        }
    },
    mounted() {
        waitForCallbackToReturnTrue(() => window.google !== null && window.google.maps !== null).then(() => this.initMap());
    },
    beforeDestroy() {
    },
    computed: {
        mapLink: function() {
            return 'https://google.com/maps/?q=' +
                this.address.latitude + ',' +
                this.address.longitude + '&ll=' +
                this.address.latitude + ',' +
                this.address.longitude + '&spn=0.004250,0.011579&t=h&z=19&hl=en';
        }
    },
    data: function() {
      return {
        map: null
      }
    },
    methods: {
        initMap() {
          if(this.map !== null)
            return this.updateMap();

          const latLng = {lat: Number(this.address.latitude), lng: Number(this.address.longitude)};
          try {
            this.map = new window.google.maps.Map(this.$refs.map, {
              zoom: 18,
              scaleControl: true,
              mapTypeId: 'satellite',
              center: latLng
            });

            this.map.setTilt(0);

            this.marker = new google.maps.Marker({
              position: latLng,
              map: this.map,
              title: 'Interactive Map'
            });
          } catch(e) {console.error(e);}
        },
        updateMap() {
          if(this.marker !== null)
            this.marker.setMap(null);

          const latLng = {lat: Number(this.address.latitude), lng: Number(this.address.longitude)};

          this.map.setCenter(latLng);
          this.map.setZoom(18);

          this.marker = new google.maps.Marker({
            position: latLng,
            map: this.map,
            title: 'Interactive Map'
          });
        }
    },
    watch: {
        'address.full_formatted'() {
            this.initMap();
        }
    }
};
</script>
<style scoped>
    #map {
        height: 388px;
        width: 388px;
    }
</style>
