import axios from 'axios';
import {BaseApiService} from "./base";

class ApiService extends BaseApiService {
    constructor(baseUrl, baseEndpoint, version) {
        super("ApiService");

        this.baseEndpoint = baseEndpoint;
        this.baseUrl = baseUrl;
        this.version = version;
    }

    axios() {
        const axiosConfig = {
            baseURL: `/${this.baseUrl}/v${this.version}/${this.baseEndpoint}`
        }

        return axios.create(axiosConfig);
    }

    getCallForwardingData(userId) {
        return this.axios().get('/' + userId + '/get-call-forwarding-data');
    }

    updateCallForwardingStatus(userId, status) {
        return this.axios().patch('/' + userId + '/update-call-forwarding-status', {
            status: status
        });
    }

    updateCallForwardingNumber(userId, number) {
        return this.axios().patch('/' + userId + '/update-call-forwarding-number', {
            number: number
        });
    }

    getUserTimezone(userId) {
        return this.axios().get(`/${userId}/timezone`);
    }

    updateUserTimezone(userId, timezone) {
        return this.axios().post(`/${userId}/timezone`, {timezone});
    }
}

export { ApiService };

