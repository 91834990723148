<template>
    <div class="border rounded-lg" :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div v-if="!loading" class="p-5 grid grid-cols-1 md:grid-cols-4 md:gap-2">
            <div class="md:border-r grid grid-cols-2" :class="{'border-light-border': !darkMode, 'border-dark-border': darkMode}">
                <div class="flex flex-col">
                    <p class="text-xs leading-loose uppercase font-medium" :class="{'text-grey-200': !darkMode, 'text-grey-400': darkMode}">Company Name</p>
                    <p class="font-medium">{{ company.name }}</p>
                </div>
                <div class="flex flex-col items-end pr-4">
                    <a class="text-blue-550" :href="'/companies/' + company.mi_id" target="_blank">{{ company.mi_id }}</a>
                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1 text-grey-400" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z" clip-rule="evenodd" />
                        </svg>

                        <a class="text-blue-550" :href="company.website" target="_blank">
                            {{ company.website }}
                        </a>
                    </div>
                </div>
            </div>

            <div class="md:border-r flex flex-col px-2 justify-between" :class="{'border-light-border': !darkMode, 'border-dark-border': darkMode}">
                <div class="flex flex-row items-center text-sm">
                    <span class="font-medium mr-2">Prescreened:</span>
                    <svg v-if="company.prescreened" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </div>
                <div class="flex flex-row items-center gap-2">
                    <span class="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium capitalize"
                        :class="{
                            'bg-green-150 text-green-550': company.status == 'active',
                            'bg-cyan-150 text-blue-550': ['registering', 'presales'].includes(company.status),
                            'bg-red-100 text-red-450': !['active', 'registering', 'presales'].includes(company.status),
                        }"
                    >
                        {{ company.status }}
                    </span>

                    <span class="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium capitalize bg-cyan-150 text-blue-550"
                    >
                        {{ company.type }}
                    </span>

                    <span class="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium capitalize"
                          :class="{
                            'bg-cyan-150 text-blue-550': company.buying_leads,
                            'bg-red-100 text-red-450': !company.buying_leads,
                        }"
                    >
                        {{ company.buying_leads ? "Buying Leads" : "Not Buying Leads" }}
                    </span>
                </div>
            </div>

            <div class="md:col-span-2 grid grid-cols-5 gap-2 px-2">
                <div class="flex flex-col">
                    <p class="text-xs leading-loose uppercase font-medium" :class="{'text-grey-200': !darkMode, 'text-grey-400': darkMode}">Rejection %</p>
                    <p class="font-medium">{{ company.rejection_percentage }}%</p>
                </div>

                <div class="flex flex-col">
                    <p class="text-xs leading-loose uppercase font-medium" :class="{'text-grey-200': !darkMode, 'text-grey-400': darkMode}">Total Spend</p>
                    <p class="font-medium">{{ company.total_spend }}</p>
                </div>

                <div class="flex flex-col">
                    <p class="text-xs leading-loose uppercase font-medium" :class="{'text-grey-200': !darkMode, 'text-grey-400': darkMode}">Registered</p>
                    <p class="font-medium">{{ company.date_registered }}</p>
                </div>

                <div class="flex flex-col">
                    <p class="text-xs leading-loose uppercase font-medium" :class="{'text-grey-200': !darkMode, 'text-grey-400': darkMode}">Last Quote</p>
                    <p class="font-medium">{{ company.last_quote }}</p>
                </div>

                <div class="flex flex-col">
                    <p class="text-xs leading-loose uppercase font-medium" :class="{'text-grey-200': !darkMode, 'text-grey-400': darkMode}">Last Review</p>
                    <p class="font-medium">{{ company.last_review }}</p>
                </div>
            </div>
        </div>
        <div v-else class="flex w-full justify-center items-center">
            <loading-spinner/>
        </div>
    </div>
</template>

<script>
import ApiService from "../services/api";
import LoadingSpinner from "../components/LoadingSpinner";
import LegacyAdminMixin from "../mixins/legacy-admin-mixin";

export default {
    name: "CompanyOverview",
    components: {LoadingSpinner},
    mixins: [LegacyAdminMixin],
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        companyId: {
            type: Number,
            default: null
        },
    },
    data() {
        return {
            loading: false,
            api: ApiService.make(),
            company: {},
        }
    },
    created() {},
    mounted() {
        this.loadCompanyOverview();
    },
    computed: {},
    methods: {
        loadCompanyOverview() {
            this.loading = true;
            this.api.getCompanyOverview(this.companyId).then(resp => {
                this.company = resp.data.data.overview;
            }).catch(e => console.log(e)).finally(() => this.loading = false);
        }
    }
}
</script>

<style lang="scss">
</style>
