<template>
    <div class="py-4">
        <wysiwyg-editor v-model="commentContent" :dark-mode="darkMode" auto-width="75%"></wysiwyg-editor>
        <button class="text-white text-sm font-semibold bg-primary-500 mt-5 mb-2 rounded px-5 py-2" @click="saveComment">Submit</button>
        <button class="text-white bg-slate-500 font-semibold mb-2 mb-2 ml-3 text-sm rounded px-5 py-2" @click="cancelComment">Cancel</button>
    </div>
</template>

<script>
import WysiwygEditor from "../../../../Shared/components/WysiwygEditor";

export default {
    name: "CommentEditor",
    components: {WysiwygEditor},
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        parentId: {
            type: Number,
            default: null,
        }
    },
    emits: [ 'cancelComment', 'saveComment' ],
    data () {
        return {
            commentContent: '',
        }
    },
    methods: {
        saveComment() {
            this.$emit('saveComment', this.parentId, this.commentContent);
        },
        cancelComment() {
            this.$emit('cancelComment');
        }
    }
}
</script>

<style scoped>

</style>
