<template>
    <div class="p-5">
        <div class="flex items-center justify-between mb-3">
            <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Industry - Service List</h5>
            <button
                class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5" @click="openModal">
                Add New Service
            </button>
        </div>
        <div class="grid grid-cols-11 gap-x-3 mb-2 px-5" v-if="!loading">
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Industry</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Name</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Slug</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Show On Website</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Created At</p>
        </div>
        <div class="border-t border-b" v-if="!loading"
             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-40 border-dark-border': darkMode}">
            <div>
                <div class="grid grid-cols-11 gap-x-3 border-b px-5 py-3 items-center"
                     v-for="industryService in industryServices" :key="industryService.id"
                     :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <p class="text-sm col-span-2 truncate">
                        {{ industryService.industry }}
                    </p>
                    <p class="text-sm col-span-2 truncate">
                        {{ industryService.name }}
                    </p>
                    <p class="text-sm col-span-2 truncate">
                        {{ industryService.slug }}
                    </p>
                    <p class="text-sm col-span-2 truncate">
                        {{ industryService.show_on_website }}
                    </p>
                    <p class="text-sm col-span-2">
                        {{ formatDate(industryService.created_at) }}
                    </p>
                    <ActionsHandle :dark-mode="darkMode" @edit="editIndustryService(industryService)" @delete="deleteIndustryService(industryService)"/>
                </div>
            </div>
        </div>
        <div class="h-32 flex items-center justify-center" v-if="loading">
            <loading-spinner></loading-spinner>
        </div>
        <modal :small="true" v-if="showModal" @close="closeModal" :dark-mode="darkMode" @confirm="saveIndustryService" :close-text="'Cancel'" :confirm-text="confirmText">
            <template v-slot:header>
                <h4>{{ industryService.id ? 'Update' : 'Create' }} Industry Service</h4>
            </template>
            <template v-slot:content>
                <div class="grid gap-3">
                    <div class="flex items-center">
                        <p class="uppercase font-semibold text-xs w-20"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Name
                        </p>
                        <input class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                               placeholder="Service Name"
                               v-model="industryService.name"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"
                               @change="generateSlug" />
                    </div>
                    <div class="flex items-center">
                        <p class="uppercase font-semibold text-xs w-20"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Slug
                        </p>
                        <input class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                               placeholder="Service Slug"
                               v-model="industryService.slug"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                    </div>
                    <div class="flex items-center">
                        <p class="uppercase font-semibold text-xs w-32"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Show On Website
                        </p>
                        <toggle v-model="industryService.show_on_website"></toggle>
                    </div>
                </div>
            </template>
        </modal>
        <alerts-container :dark-mode="darkMode" :alert-type="'error'" :text="error" v-if="error"></alerts-container>
    </div>

</template>

<script>
import Modal from "../../../Shared/components/Modal";
import ApiService from "../services/api";
import ActionsHandle from "../../../Shared/components/ActionsHandle";
import LoadingSpinner from "../../../Shared/components/LoadingSpinner";
import AlertsContainer from "../../../Shared/components/AlertsContainer";
import Toggle from "../../../Inputs/Toggle/Toggle";
import {DateTime} from "luxon";

export default {
    name: "IndustryServices",
    components: {Toggle, Modal, ActionsHandle, LoadingSpinner, AlertsContainer},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        industry: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            api: ApiService.make(),
            showModal: false,
            industryServices: [],
            industryService: {name: null, slug: null, show_on_website: false},
            saving: false,
            loading: false,
            error: null
        }
    },
    created() {
        this.getIndustryServices();
    },
    computed: {
        confirmText: function () {
            if (this.saving) return 'Saving...';
            if (this.industryService.id) return 'Update';

            return 'Create';
        }
    },
    methods: {
        openModal() {
            this.showModal = true;
        },
        closeModal () {
            this.showModal = false;
            this.industryService = {name: null, slug: null, show_on_website: false};
        },
        getIndustryServices() {
            if(!this.industry) return;
            if(!this.loading) this.loading = true;
            this.error = null;

            this.api.getIndustryServices(this.industry).then(resp => this.industryServices = resp.data.data.services)
                .catch(e => this.error = e.response.data.message).finally(() => this.loading = false);
        },
        saveIndustryService() {
            if(this.saving) return;
            this.saving = true;
            this.error = null;

            if (this.industryService.id) {
                this.updateIndustryService();
                return;
            }
            this.createIndustryService();
        },
        getParamsToMakeRequest() {
            return {
                name            : this.industryService.name,
                slug            : this.industryService.slug,
                show_on_website : this.industryService.show_on_website === true ?? false,
                industry        : this.industry,
            }
        },
        createIndustryService() {
            this.api.createIndustryServices(this.industry, this.getParamsToMakeRequest()).then(() => this.refreshList(true))
                .catch(e => this.error = e.response.data.message).finally(() => this.saving = false);
        },
        editIndustryService(industryService) {
            this.industryService = {...industryService};
            this.industryService.show_on_website = this.industryService.show_on_website === 'Yes';
            this.openModal();
        },
        updateIndustryService() {
            this.api.updateIndustryService(this.industryService.id, this.industry, this.getParamsToMakeRequest()).then(() => this.refreshList(true))
                .catch(e => this.error = e.response.data.message).finally(() => this.saving = false);
        },
        deleteIndustryService(industryService) {
            this.error = null;
            this.api.deleteIndustryService(industryService.id).then(() => this.refreshList())
                .catch(e => this.error = e.response.data.message);
        },
        formatDate(date) {
            return date ? DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT) : null
        },
        refreshList(closeModal = false) {
            this.getIndustryServices();
            if(closeModal) this.closeModal();
        },
        generateSlug() {
            if (!this.industryService.slug) {
                this.industryService.slug = `${this.industryService.name}`.trim().toLowerCase().split(/[\s_-]+/g).join('-');
            }
        },
    }
}
</script>
