<template>
    <modal :dark-mode="darkMode" :no-buttons="true" @close="close">
        <template v-slot:header>
            <h4 class="text-xl font-medium">Processing History</h4>
        </template>

        <template v-slot:content>
            <div class="grid grid-cols-4 gap-5 my-5">
                <div class="uppercase text-base">
                    Lead
                </div>
                <div class="uppercase text-base">
                    Queue
                </div>
                <div class="uppercase text-base">
                    Action
                </div>
                <div class="uppercase text-base">
                    Time
                </div>
            </div>

            <template v-if="entries && entries.length > 0">
                <div v-for="entry in entries" :key="entry.id" class="grid grid-cols-4 gap-5 my-5">
                    <a :href="getLegacyAdminQuoteUrl(entry.lead_id)" target="_blank">
                        {{ entry.lead_id }}
                    </a>
                    <div>
                        {{ entry.queue }}
                    </div>
                    <div>
                        {{ entry.action }}
                    </div>
                    <div>
                        {{ entry.created_time }}
                    </div>
                </div>
            </template>
            <template v-else-if="loading">
                <div class="w-full text-center">
                    Loading
                </div>
            </template>
            <template v-else>
                <div class="w-full text-center">
                    No History
                </div>
            </template>

        </template>
    </modal>
</template>

<script>
    import Modal from "./Modal";
    import LegacyAdminMixin from "../mixins/legacy-admin-mixin";

    export default {
        name: "ProcessingHistory",
        components: {
            Modal,
        },
        mixins: [LegacyAdminMixin],
        props: [
            "api",
            "darkMode"
        ],
        data: function() {
            return {
                entries: [],
                loading: false
            };
        },
        created: function() {
            this.getProcessingHistory();
        },
        methods: {
            getProcessingHistory() {
                this.loading = true;

                this.api.getProcessingHistory().then((res) => {
                    if(res.data.data.status) {
                        this.entries = res.data.data.history;
                    }
                }).catch((err) => {

                }).finally(() => {
                    this.loading = false;
                });
            },
            close() {
                this.$emit('close');
            }
        }
    }
</script>
