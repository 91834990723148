import {BaseApiService} from "./base";

class DummyApiService extends BaseApiService {
    constructor(delay = 150) {
        super("DummyApiService");

        this.delay = delay;
    }

    _makeResponse(data) {
        return new Promise((resolve, reject) => {
            setTimeout(() => resolve({data: {data}}), this.delay);
        });
    }

    getAvailableNumbers() {
        return this._makeResponse({status: true, numbers: [{id: 1, number: "5556665656"}]});
    }

    getUsers() {
        return this._makeResponse({result: true});
    }

    deleteUsers() {
        return this._makeResponse({result: true});
    }

}

export { DummyApiService };
