<template>
    <div class="mt-5">
        <p class="uppercase font-semibold text-xs mb-1 mt-5"
           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
            Burns Round Robin Turn?
        </p>
        <Dropdown v-model="burnsTurn" :options="choices" :dark-mode="darkMode" placeholder="Select Option"></Dropdown>
    </div>
</template>

<script>
import Dropdown from "../../../Shared/components/Dropdown";
import ApiService from "../../services/api";
import LoadingSpinner from "../../../Shared/components/LoadingSpinner";

export default {
    name: "ReassignAccountManagerNode",
    components: {Dropdown, LoadingSpinner},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        action: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            api: ApiService.make(),
            loading: false,
            burnsTurn: 0,
            choices: [
                {id: 0, name: "No"},
                {id: 1, name: "Yes"},
            ]
        }
    },
    created() {
        if (this.action) {
            this.burnsTurn = this.action.payload.burns_turn;
        }
    },
    watch: {
        action: function () {
            if (this.action) {
                this.burnsTurn = this.action.payload.burns_turn;
            } else {
                this.burnsTurn = 0;
            }

            this.loadTemplates();
        }
    },
    methods: {
        getDisplayName() {
            return "Reassign Account Manager";
        },
        getPayload() {
            return {
                burns_turn: this.burnsTurn
            }
        },
    }
}
</script>

<style lang="scss">

</style>
