<template>
    <div class="grid grid-cols-3 gap-4">
        <div class="border rounded-md h-full"
             :class="[selectedCompany !== null ? 'col-span-1' : 'col-span-3',darkMode ? 'bg-dark-module border-dark-border' : 'bg-light-module border-light-border']">
            <CompaniesServingAreaHeader :selected-company="selectedCompany" :dark-mode="darkMode"
                                        @update:selected-company="selectedCompany = $event"
                                        @update:hide-contacted-companies="hideContactedCompanies = $event"
                                        @apply="filterCompanies"
                                        :amount-of-purchased-leads="amountOfPurchasedLeads"
                                        :purchased-leads-condition="purchasedLeadsCondition"
                                        :active-filters="activeFilters"
                                        @toggle-sort="sortCompanies($event)"
            ></CompaniesServingAreaHeader>
            <CompaniesServingAreaBody :loading="loading" :companies="filteredCompanies" :dark-mode="darkMode"
                                      @update:call-office="callOffice"
                                      :selected-company="selectedCompany"
                                      @update:selected-company="selectedCompany = $event" :contacts="contacts"
                                      @update:contacts="contacts = $event"
                                      :amount-of-purchased-leads="amountOfPurchasedLeads"
                                      @update:activities="activityReload++"
            ></CompaniesServingAreaBody>
            <div class="p-3"></div>
        </div>
        <company-contacts :table-height="'h-[37rem]'" v-if="selectedCompany !== null"
                          :has-edit-rights="true" :dark-mode="darkMode" :show-add-contact-button="true"
                          :company-id="selectedCompany"></company-contacts>
        <activity-page v-if="selectedCompany !== null" :dark-mode="darkMode"
                       :company-id="selectedCompany" :key="activityReload">
            <template v-slot:extra-filters>
                <div class="col-span-3 flex items-center">
                    <toggle-switch :dark-mode="darkMode" v-model="onlyShowCallsLongerThanOneMinute"></toggle-switch>
                    <label :class="[darkMode ? 'text-slate-100' : 'text-slate-900']" class="text-sm font-medium ml-2">
                        Only Show Calls Longer Than 1 Minute</label>
                </div>
            </template>
        </activity-page>
    </div>
</template>

<script>
import SharedApiService from "../services/api";
import {ApiFactory} from "../../LeadProcessing/services/api/factory";
import DispatchesGlobalEventsMixin from "../../../mixins/dispatches-global-events-mixin";
import Activity from "./Activity";
import CompanyContacts from "./Contacts";
import ActivityPage from "../../Companies/components/ActivityPage";
import ToggleSwitch from "../components/ToggleSwitch";
import CompaniesServingAreaHeader from "./CompaniesServingArea/CompaniesServingAreaHeader.vue";
import CompaniesServingAreaBody from "./CompaniesServingArea/CompaniesServingAreaBody.vue";
import {filterCompanies as globalFilterCompanies} from "../../../../modules/company-servicing-area-filter";
import {sortCompanies as globalSortCompanies} from "../../../../modules/company-servicing-area-sorter";
import {useCompanyServicingAreaFiltersStore} from "../../../../stores/company-servicing-area-filters.store";
import {mapState, mapWritableState} from "pinia";
import {translateDateConditionStringToIntTimestamp} from "../../../../modules/helpers";
import {useCompanyServicingAreaSorterStore} from "../../../../stores/company-servicing-area-sorter.store";
import {useActivityTabStore} from "../../../../stores/activity-tab.store";
import Api from "../../Companies/services/api";
import {useActivityPageStore} from "../../../../stores/activity-page.store";

export default {
    name: "CompaniesServingArea",
    components: {
        CompaniesServingAreaBody,
        CompaniesServingAreaHeader,
        ToggleSwitch,
        ActivityPage,
        CompanyContacts,
        Activity
    },
    props: {
        leadId: {
            type: Number,
            default: null
        },
        darkMode: {
            type: Boolean,
            default: false,
        },
        task: {
            type: Object,
            default: {},
            validator: (o) => {
                return o.hasOwnProperty('id') && _.isInteger(o.id);
            }
        }
    },
    computed: {
        ...mapState(useCompanyServicingAreaFiltersStore, {
            storeFilters: 'filters'
        }),
        ...mapState(useCompanyServicingAreaSorterStore, {
            storeSortObject: 'sorters'
        }),
        ...mapWritableState(useActivityTabStore, {
            activityTabFilters: 'filters'
        })
    },
    mixins: [DispatchesGlobalEventsMixin],
    data() {
        return {
            companies: [],
            filteredCompanies: [],
            contacts: [],
            sharedApi: SharedApiService.make(),
            api: Api.make(),
            selectedCompany: null,
            lead: null,
            loading: false,
            leadApi: ApiFactory.makeApiService("api"),
            zipCode: null,
            expandedContacts: [],
            hideContactedCompanies: false,
            gettingCompanies: false,
            amountOfPurchasedLeads: false,
            purchasedLeadsCondition: null,
            activeFilters: 0,
            activityReload: 0,
            onlyShowCallsLongerThanOneMinute: true,
        }
    },
    mounted() {
        this.load();

        const check = localStorage.getItem('only-show-calls-longer-than-one-minute-in-activity-feed');

        this.onlyShowCallsLongerThanOneMinute = check ? check.toLowerCase() === "true" : true;

        this.buildQueryParamsForCallsOverAMinuteFilter(this.onlyShowCallsLongerThanOneMinute);
    },
    watch: {
        leadId(newVal, oldVal) {
            if (newVal !== oldVal)
                this.load();
        },
        hideContactedCompanies() {
            this.getCompanies();
        },
        selectedCompany(to, from) {
            if (to) {
                if (!_.isInteger(to)) {
                    throw new TypeError(`"to" must be an integer. ${to} passed.`)
                }

                this.buildQueryParamsForCallsOverAMinuteFilter(this.onlyShowCallsLongerThanOneMinute);
            }
        },
        onlyShowCallsLongerThanOneMinute(to, from) {
            if (_.isBoolean(to) && this.selectedCompany) {
                this.buildQueryParamsForCallsOverAMinuteFilter(to);

                localStorage.setItem('only-show-calls-longer-than-one-minute-in-activity-feed', to)

                this.updateActivityPageAndActivityTab();
            } else {
                if (this.selectedCompany) {
                    console.error(`Could not toggle call list. "onlyShowCallsLongerThanOneMinute" must be a boolean value. ${to} passed.`);
                }
            }
        }
    },
    methods: {
        /**
         * @param {boolean} toggle
         */
        buildQueryParamsForCallsOverAMinuteFilter(toggle) {

            if (!_.isBoolean(toggle)) {
                throw new TypeError(`"toggle" must be a boolean. ${toggle} passed.`)
            }

            let filters;

            if (toggle) {
                filters = {
                    query: null,
                    start_date: null,
                    end_date: null,
                    user_id: null,
                    sort_by: 'updated_at',
                    min_call_duration_in_seconds: 60,
                    min_call_duration_in_seconds_exclusive: true
                }
            } else {
                filters = {
                    query: null,
                    start_date: null,
                    end_date: null,
                    user_id: null,
                    sort_by: 'updated_at',
                    min_call_duration_in_seconds: 0,
                    min_call_duration_in_seconds_exclusive: false
                }
            }

            this.activityTabFilters = filters;
            return filters;
        },
        updateActivityPageAndActivityTab() {
            try {
                useActivityPageStore().getActivitiesOverview(this.selectedCompany, this.activityTabFilters);
            } catch (e) {
                console.error(e);
            }

            try {
                useActivityTabStore().getActivities(this.selectedCompany, this.api).catch(e => {
                    console.error(e);
                })
            } catch (e) {
                console.error(e);
            }
        },
        /**
         * @param {string} field
         */
        sortCompanies(field) {
            /**
             * @type {sortObject}
             */
            const sortObject = this.storeSortObject;

            this.filteredCompanies = globalSortCompanies(this.filteredCompanies, sortObject, field)
        },
        load() {
            this.companies = [];
            this.contacts = [];
            this.expandedContacts = [];
            this.getLead().then(resp => this.getCompanies());
        },
        getLead() {

            if (this.leadId) {
                return new Promise((resolve) => {
                    this.leadApi.getLeadBasicInfo(this.leadId).then(resp => {
                        this.lead = resp.data.data.lead;
                        this.zipCode = resp.data.data.lead.address.zip_code;
                    }).catch(e => console.error(e)).finally(() => {
                        this.loading = false;
                        resolve();
                    });
                })
            } else {
                console.error("No lead id.")
            }
        },
        getCompanies() {
            this.companies = [];
            this.filteredCompanies = [];
            this.contacts = [];
            this.expandedContacts = [];
            this.selectedCompany = null;
            this.gettingCompanies = true;

            const industry = this?.lead?.industry?.toLocaleLowerCase();

            const zipCode = this.zipCode;

            const taskId = this.task?.id;

            if (industry && zipCode && taskId) {
                this.sharedApi.getNonPurchasingCompanies(this?.lead?.industry?.toLocaleLowerCase(), this.zipCode, this.task?.id, this.hideContactedCompanies)
                    .then(resp => {
                        this.companies = resp.data.data.companies;
                        this.filterCompanies();

                        const activeSort = Object.entries(this.storeSortObject).filter(x => x.active)[0]?.value;

                        if (activeSort) {
                            this.sortCompanies(activeSort);
                        }
                    })
                    .finally(() => this.gettingCompanies = false);
            } else {
                if (!industry) {
                    console.error("Missing industry.")
                }

                if (!zipCode) {
                    console.error("Missing zip code.")
                }

                if (!taskId) {
                    console.error("Missing task id.")
                }
            }
        },
        /**
         * Applies the filters to the current companies and sets them to filteredCompanies.
         */
        filterCompanies() {
            const previousAmountOfPurchasedLeadsFilterStatus = this.amountOfPurchasedLeads;

            /**
             * @type CompanyServicingAreaFiltersJsDocType
             */
            const filters = _.cloneDeep(this.storeFilters);

            /**
             * Show Purchased Leads header and amount if filter is active.
             * @type {?boolean}
             */
            this.amountOfPurchasedLeads = filters?.amountOfPurchasedLeads?.active;

            /**
             * Get Purchased Leads Condition to dynamically show date range in header.
             * @type {AmountOfLeadsPurchasedFiltersConditionOptionsJsDocType}
             */
            this.purchasedLeadsCondition = filters?.amountOfPurchasedLeads?.condition;

            if (this.amountOfPurchasedLeads) {

                let timestamp = null;

                try {
                    timestamp = translateDateConditionStringToIntTimestamp(this.purchasedLeadsCondition);
                } catch (e) {
                    throw new Error(`Could not filter by Amount of Leads Purchased. ${e}`);
                }

                const amountOfPurchasedLeadsFilter = filters?.amountOfPurchasedLeads;

                if (amountOfPurchasedLeadsFilter) {
                    this.sharedApi.nonPurchasingCompaniesFilteredByAmountOfLeadsPurchased.get(
                        this?.lead?.industry?.toLocaleLowerCase(),
                        this.zipCode,
                        this.task?.id,
                        this.hideContactedCompanies,
                        timestamp,
                        amountOfPurchasedLeadsFilter.firstValue,
                        amountOfPurchasedLeadsFilter.firstOperator,
                        amountOfPurchasedLeadsFilter.logical,
                        amountOfPurchasedLeadsFilter.secondValue,
                        amountOfPurchasedLeadsFilter.secondOperator,
                    )?.then(response => {
                        this.companies = response?.data?.data?.companies ?? [];

                        this.filteredCompanies = globalFilterCompanies(filters, this.companies) ?? [];
                    })?.catch(e => {
                        console.error(e.message);
                    });
                } else {
                    console.warn("Amount of Purchased Leads filter was empty, so it was treated as inactive.")
                }
            } else {
                if (previousAmountOfPurchasedLeadsFilterStatus) {
                    this.getCompanies();
                }

                try {
                    this.filteredCompanies = globalFilterCompanies(filters, this.companies) ?? [];
                } catch (e) {
                    console.error(e);
                }
            }

            /**
             * Show amount of filters active
             */
            const currentActiveFilters = [];

            Object.values(filters).forEach(filter => {
                currentActiveFilters.push(filter.active);
            });

            this.activeFilters = currentActiveFilters.filter(item => item).length;
        },
        toggleExpandContact(id) {
            if (this.expandedContacts.includes(id))
                this.expandedContacts.splice(this.expandedContacts.indexOf(id), 1);
            else
                this.expandedContacts.push(id);
        },
        call(contact) {
            this.dispatchGlobalEvent('call', {
                phone: contact.phone ?? contact.mobile,
                name: contact.name,
                id: contact.id
            });
        },
        callOffice(office) {
            this.dispatchGlobalEvent('call', {
                phone: office.phone,
                name: `${office.city}, ${office.state}`,
                id: office.addressid
            });
        },
        sms(contact) {
            this.dispatchGlobalEvent('sms', {
                phone: contact.phone ?? contact.mobile,
                name: contact.name,
                id: contact.id
            });
        },
    }
}
</script>
