<template>
    <div class="grid grid-cols-1 gap-4 px-10 pt-5 pb-10">
        <Tasks
            :dark-mode="darkMode"
            :company-id="companyId"
            @start-tasks="startTasks"
            :company-name="companyName"
            :all-user-task="true"
        ></Tasks>
    </div>
</template>

<script>
import Tasks from "../../Shared/modules/Tasks";
export default {
    name: "CompanyTasks",
    components: {Tasks},
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        companyId: {
            type: Number,
            default: null
        },
        companyName: {
            type: String,
            default: null
        }
    },
    methods: {
        startTasks(tasks) {
            if (tasks.length > 0) {
                const params = new URLSearchParams();
                params.append('tasks', tasks.join(','));
                window.location.assign('/tasks/queue?' + params.toString());
                return;
            }
            window.location.assign('/tasks/queue');
        }
    }
}
</script>

<style scoped>

</style>
