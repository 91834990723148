<template>
    <div>
        <div class="flex items-end py-2">
            <div>
                <div class="flex items-center">
                    <div class="w-4 mt-1 border-b border-dashed"  :class="{'border-grey-300': !darkMode, 'border-blue-400': darkMode}"></div>
                    <button @click="addAction" :class="{'bg-transparent': !selected, 'bg-opacity-25 bg-cyan-500': selected}" class="text-blue-550 hover:bg-cyan-500 hover:bg-opacity-25 rounded-lg font-semibold text-sm py-1 px-3">+ Action</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AddAction",
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        addAction() {
            this.$emit('add-action');
        }
    }
}
</script>

<style scoped>

</style>
