import {ApiService} from "./api";
import {DummyApiService} from "./dummy";

class UserSettingsApiFactory {
    static makeApiService(driver) {
        switch(driver) {
            case 'api':
                return new ApiService('internal-api', 'user-settings', 1);

            case 'dummy':
            default:
                return new DummyApiService();
        }
    }
}

export { UserSettingsApiFactory };
