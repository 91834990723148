<template>
    <div>
        <alerts-container v-if="alertActive" :alert-type="alertType" :text="alertText" :dark-mode="darkMode"/>

        <modal v-if="showNewAccountModal" :no-buttons="true" :dark-mode="darkMode" :full-width="true" @close="toggleNewAccountModal(false)">
            <template v-slot:header>
                New Account
            </template>
            <template v-slot:content>
                <div class="grid grid-cols-3 gap-5">
                    <div>
                        <p class="font-semibold text-sm mb-2" :class="[darkMode ? 'text-slate-200' : 'text-slate-900']">
                            ID
                        </p>
                        <input
                            type="text"
                            v-model="newAccount.platform_account_id"
                            class="rounded text-sm font-medium w-full h-9 border focus:outline-none outline-none focus:ring-0 focus:border-primary-500 focus:shadow-lg focus:shadow-primary-500/10"
                            :class="{
                                'text-slate-100 bg-dark-background border-dark-border': darkMode,
                                'text-slate-900 bg-light-background border-light-border': !darkMode,
                            }">
                    </div>
                    <div>
                        <p class="font-semibold text-sm mb-2" :class="[darkMode ? 'text-slate-200' : 'text-slate-900']">
                            Name
                        </p>
                        <input type="text"
                               v-model="newAccount.name"
                               class="rounded text-sm font-medium w-full h-9 border focus:outline-none outline-none focus:ring-0 focus:border-primary-500 focus:shadow-lg focus:shadow-primary-500/10"
                               :class="{
                                    'text-slate-100 bg-dark-background border-dark-border': darkMode,
                                    'text-slate-900 bg-light-background border-light-border': !darkMode,
                               }">
                    </div>
                    <div>
                        <p class="font-semibold text-sm mb-2" :class="[darkMode ? 'text-slate-200' : 'text-slate-900']">
                            Industry
                        </p>
                        <Dropdown :dark-mode="darkMode" v-model="newAccount.industry" :options="industryOptions" :placeholder="'Choose an Industry'"/>
                    </div>

                    <div>
                        <p class="font-semibold text-sm mb-2" :class="[darkMode ? 'text-slate-200' : 'text-slate-900']">
                            Websites
                        </p>
                        <multi-select
                            :options="websiteOptions"
                            :dark-mode="darkMode"
                            :text-place-holder="'Select sites'"
                            :show-search-box="false"
                            @input="newAccount.websites = $event" />
                    </div>
                    <div>
                        <p class="font-semibold text-sm mb-2" :class="[darkMode ? 'text-slate-200' : 'text-slate-900']">
                            Tracks Conversions
                        </p>
                        <toggle-switch
                            v-model="newAccount.tracks_conversions"
                            :dark-mode="darkMode" />
                    </div>
                </div>
                <div class="mt-10 flex flex-row gap-5 justify-end items-center">
                    <button
                        type="button"
                        @click="toggleNewAccountModal(false)"
                        class="px-5 py-2 h-9 w-32 rounded bg-grey-300 hover:bg-grey-600 text-white text-sm font-medium">
                        Cancel
                    </button>

                    <div v-if="newAccount.saving" class="text-center px-5 py-2 h-9 w-32">
                        Saving...
                    </div>
                    <button
                        v-else
                        type="button"
                        @click="saveAccount(platform, newAccount.platform_account_id, newAccount.name, newAccount.industry, newAccount.websites, newAccount.tracks_conversions)"
                        class="px-5 py-2 h-9 w-32 rounded bg-primary-500 hover:bg-primary-600 text-white text-sm font-medium">
                        Save
                    </button>
                </div>
            </template>
        </modal>
        <modal v-else-if="showDeleteModal"
               :no-buttons="true"
               :dark-mode="darkMode"
               :full-width="true"
               @close="toggleDeleteModal(false)">
            <template v-slot:header>
                <div class="text-red-400 font-medium">
                    Delete Account
                </div>
            </template>
            <template v-slot:content>
                <div class="text-red-400 font-medium">
                    This will delete all account information, including any automated campaigns and automation parameters. Do you still want to delete?
                </div>
                <div class="flex flex-row gap-5 justify-end">
                    <button
                        type="button"
                        @click="toggleDeleteModal(false)"
                        class="px-5 py-2 h-9 w-32 rounded bg-grey-300 hover:bg-grey-600 text-white text-sm font-medium">
                        Cancel
                    </button>

                    <div v-if="deleting" class="text-center px-5 py-2 h-9 w-32">
                        Deleting...
                    </div>
                    <button
                        v-else
                        type="button"
                        @click="deleteAccount"
                        class="px-5 py-2 h-9 w-32 rounded bg-red-400 hover:bg-red-600 text-white text-sm font-medium">
                        Delete
                    </button>
                </div>
            </template>
        </modal>
        <modal v-else :no-buttons="true" :dark-mode="darkMode" :full-width="true" @close="closeAccountsModal" :container-classes="'p-4'">
            <template v-slot:header>
                Accounts Management
            </template>
            <template v-slot:content>
                <div class="flex flex-row justify-between items-center">
                    <div class="w-1/2 mr-5">
                        <Dropdown :dark-mode="darkMode" :placeholder="platforms.length > 0 ? 'Choose a platform' : 'Loading platforms'" :options="platforms" v-model="platform" @change="getAccounts($event.id)"/>
                    </div>
                    <div>
                        <button
                            v-if="platform.length > 0 && !loading"
                            type="button"
                            class="px-5 py-2 h-9 rounded bg-primary-500 hover:bg-primary-600 text-white text-sm font-medium"
                            @click="toggleNewAccountModal(true)">
                            + New Account
                        </button>
                    </div>
                </div>

                <div v-if="loading" class="my-5">
                    <loading-spinner :dark-mode="darkMode" :label="'Loading'"/>
                </div>
                <div v-else-if="accounts.data && accounts.data.length > 0">
                    <div class="grid grid-cols-1 md:grid-cols-3 gap-3 my-4">
                        <div v-for="account in accounts.data"
                             :key="account.platform_account_id"
                             class="border rounded-md p-4 relative"
                             :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
                            <div class="mb-2">
                                <p class="font-semibold text-sm mb-2" :class="[darkMode ? 'text-slate-200' : 'text-slate-900']">
                                    ID
                                </p>
                                {{ account.platform_account_id }}
                            </div>
                            <div class="mb-2">
                                <p class="font-semibold text-sm mb-2" :class="[darkMode ? 'text-slate-200' : 'text-slate-900']">
                                    Name
                                </p>
                                <input v-if="editingAccounts[account.platform_account_id]"
                                       type="text"
                                       v-model="editingAccounts[account.platform_account_id].name"
                                       class="rounded text-sm font-medium w-full h-9 border focus:outline-none outline-none focus:ring-0 focus:border-primary-500 focus:shadow-lg focus:shadow-primary-500/10"
                                       :class="{
                                        'text-slate-100 bg-dark-background border-dark-border': darkMode,
                                        'text-slate-900 bg-light-background border-light-border': !darkMode,
                                   }">
                                <span v-else>{{ account.name }}</span>
                            </div>
                            <div class="mb-2 grid grid-cols-2 gap-5">
                                <div>
                                    <p class="font-semibold text-sm mb-2" :class="[darkMode ? 'text-slate-200' : 'text-slate-900']">
                                        Industry
                                    </p>
                                    <Dropdown v-if="editingAccounts[account.platform_account_id]" :dark-mode="darkMode" v-model="editingAccounts[account.platform_account_id].industry" :options="industryOptions" :placeholder="'Choose an Industry'"/>
                                    <span v-else>{{ account.industry }}</span>
                                </div>
                                <div>
                                    <p class="font-semibold text-sm mb-2" :class="[darkMode ? 'text-slate-200' : 'text-slate-900']">
                                        Tracks Conv.
                                    </p>
                                    <toggle-switch v-if="editingAccounts[account.platform_account_id]" v-model="editingAccounts[account.platform_account_id].tracks_conversions" :dark-mode="darkMode" />
                                    <span v-else>{{ account.tracks_conversions ? "Yes" : "No" }}</span>
                                </div>
                            </div>
                            <div class="mb-2">
                                <p class="font-semibold text-sm mb-2" :class="[darkMode ? 'text-slate-200' : 'text-slate-900']">
                                    Websites
                                </p>
                                <multi-select
                                    v-if="editingAccounts[account.platform_account_id]"
                                    :options="websiteOptions"
                                    :dark-mode="darkMode"
                                    :text-place-holder="'Select sites'"
                                    :selected-ids="editingAccounts[account.platform_account_id].websites"
                                    :show-search-box="false"
                                    @input="editingAccounts[account.platform_account_id].websites = $event" />
                                <div v-else class="w-full overflow-y-auto h-16">
                                    <div v-for="websiteId in account.websites" class="mb-1 truncate">
                                        {{ websitesKeyed[websiteId] }}
                                    </div>
                                </div>
                            </div>

                            <div v-if="editingAccounts[account.platform_account_id]" class="flex flex-row gap-5 items-center">
                                <button
                                    type="button"
                                    @click="editAccount(false, account.platform_account_id)"
                                    class="px-5 py-2 h-9 w-32 rounded bg-grey-300 hover:bg-grey-600 text-white text-sm font-medium">
                                    Cancel
                                </button>

                                <div v-if="editingAccounts[account.platform_account_id].saving" class="text-center">
                                    Saving...
                                </div>
                                <button
                                    v-else
                                    type="button"
                                    @click="saveAccount(
                                        platform,
                                        account.platform_account_id,
                                        editingAccounts[account.platform_account_id].name,
                                        editingAccounts[account.platform_account_id].industry,
                                        editingAccounts[account.platform_account_id].websites,
                                        editingAccounts[account.platform_account_id].tracks_conversions
                                    )"
                                    class="px-5 py-2 h-9 w-32 rounded bg-primary-500 hover:bg-primary-600 text-white text-sm font-medium">
                                    Save
                                </button>
                            </div>
                            <div v-else class="flex flex-row gap-5 items-center">
                                <button
                                    type="button"
                                    @click="editAccount(true, account.platform_account_id)"
                                    class="px-5 py-2 h-9 w-32 rounded bg-primary-500 hover:bg-primary-600 text-white text-sm font-medium">
                                    Edit
                                </button>
                                <button
                                    type="button"
                                    @click="toggleDeleteModal(true, account.platform_account_id)"
                                    class="px-5 py-2 h-9 w-32 rounded bg-red-400 hover:bg-red-600 text-white text-sm font-medium">
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                    <pagination
                        :pagination-data="accounts"
                        :dark-mode="darkMode"
                        @change-page="getAccounts(platform, $event.newPage)"
                    />
                </div>
                <div v-else-if="platform.length > 0" class="text-center my-5">
                    No accounts
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
    import AlertsContainer from "../../Shared/components/AlertsContainer";
    import LoadingSpinner from "../../Shared/components/LoadingSpinner";
    import AlertsMixin from '../../../mixins/alerts-mixin';
    import Api from './../services/api';
    import Modal from "../../Shared/components/Modal";
    import Dropdown from "../../Shared/components/Dropdown";
    import Pagination from "../../Shared/components/Pagination";
    import SharedApiService from "../../Shared/services/api";
    import MultiSelect from "../../Shared/components/MultiSelect";
    import ToggleSwitch from "../../Shared/components/ToggleSwitch";
    import {unslugify} from "../../Shared/services/strings";

    export default {
        name: "AccountManagement",
        components: {
            ToggleSwitch,
            Pagination,
            Dropdown,
            AlertsContainer,
            LoadingSpinner,
            Modal,
            MultiSelect
        },
        mixins: [
            AlertsMixin
        ],
        props: {
            darkMode: {
                type: Boolean,
                default: false
            }
        },
        emits: [
            'close'
        ],
        data: function() {
            return {
                api: Api.make(),
                sharedApi: SharedApiService.make(),

                accounts: {},
                platforms: [],
                platform: '',
                editingAccounts: {},
                newAccount: null,

                industryOptions: ['solar', 'roofing'],
                websiteOptions: [],

                showNewAccountModal: false,

                showDeleteModal: false,

                loading: false,
                deleting: false,

                unslugify: unslugify
            };
        },
        computed: {
            websitesKeyed: function() {
                let websitesKeyed = {};

                this.websiteOptions.forEach(website => {
                    websitesKeyed[website.id] = website.name;
                });

                return websitesKeyed;
            }
        },
        created: function() {
            this.api.getAllPlatforms().then(res => {
                if(res.data.data.status === true) {
                    this.platforms = res.data.data.platforms.map((platform) => {
                        return {
                            id: platform,
                            name: this.unslugify(platform, '_')
                        };
                    });
                }
                else {
                    this.showAlert('error', 'Problem retrieving platforms for account management');
                }
            }).catch(err => {
                this.showAlert('error', 'Error retrieving platforms for account management');
            });

            this.sharedApi.getWebsites().then(res => {
                if(res.data.data.status === true) {
                    this.websiteOptions = res.data.data.websites.map((website) => {
                        return {
                            id: website.id,
                            name: website.url
                        };
                    });
                }
                else {
                    this.showAlert('error', 'Problem loading websites');
                }
            }).catch(err => {
                this.showAlert('error', 'Error loading websites');
            });
        },
        methods: {
            getAccounts(platform, page = 1) {
                this.loading = true;
                this.newAccount = null;
                this.accounts = {};
                this.editingAccounts = {};

                return this.api.getPaginatedPlatformAccounts(platform, page).then(res => {
                    if(res.data.data.status === true) {
                        this.accounts = res.data.data.accounts;
                    }
                    else {
                        this.showAlert('error', 'Failed to retrieve platform accounts');
                    }
                }).catch(err => {
                    this.showAlert('error', 'Error retrieving platform accounts');
                }).finally(() => {
                    this.loading = false;
                });
            },
            editAccount(edit, accountId) {
                if(edit) {
                    const editAccount = this.accounts.data.find((account) => {
                        return account.platform_account_id === accountId;
                    });

                    this.editingAccounts[accountId] = {
                        platform_account_id: editAccount.platform_account_id,
                        name: editAccount.name,
                        industry: editAccount.industry,
                        tracks_conversions: editAccount.tracks_conversions,
                        websites: editAccount.websites
                    };
                }
                else {
                    delete this.editingAccounts[accountId];
                }
            },
            saveAccount(platform, id, name, industry, websites, tracksConversions) {
                if(this.newAccount
                && id === this.newAccount.platform_account_id) {
                    this.newAccount.saving = true;
                }
                else {
                    this.editingAccounts[id].saving = true;
                }

                this.api.saveAccount(platform, id, name, industry, websites, tracksConversions).then(res => {
                    if(res.data.data.status === true) {
                        if(this.newAccount
                        && id === this.newAccount.platform_account_id) {
                            this.toggleNewAccountModal(false);
                            this.getAccounts(this.platform);
                        }
                        else {
                            delete this.editingAccounts[id];

                            let editAccount = this.accounts.data.find((account) => {
                                return account.platform_account_id === id;
                            });

                            if(editAccount) {
                                editAccount.name = name;
                                editAccount.industry = industry;
                                editAccount.websites = websites;
                                editAccount.tracks_conversions = tracksConversions;
                            }
                        }
                    }
                    else {
                        this.showAlert('error', 'Failed to save account');

                        if(this.newAccount
                        && id === this.newAccount.platform_account_id) {
                            this.newAccount.saving = false;
                        }
                        else {
                            this.editingAccounts[id].saving = false;
                        }
                    }
                }).catch(err => {
                    this.showAlert('error', 'Error saving account');

                    if(this.newAccount
                    && id === this.newAccount.platform_account_id) {
                        this.newAccount.saving = false;
                    }
                    else {
                        this.editingAccounts[id].saving = false;
                    }
                })
            },
            toggleNewAccountModal(show) {
                this.editingAccounts = {};

                if(show) {
                    this.newAccount = {
                        platform_account_id: '',
                        name: '',
                        industry: '',
                        websites: [],
                        tracks_conversions: false
                    };

                    this.showNewAccountModal = true;
                }
                else {
                    this.newAccount = null;
                    this.showNewAccountModal = false;
                }
            },
            closeAccountsModal() {
                this.$emit('close');
            },
            toggleDeleteModal(show, accountId = 0) {
                this.editingAccounts = {};

                if(show) {
                    this.deleteAccountId = accountId;
                    this.showDeleteModal = true;
                }
                else {
                    this.deleteAccountId = 0;
                    this.showDeleteModal = false;
                }
            },
            deleteAccount() {
                this.deleting = true;

                this.api.deleteAccount(this.platform, this.deleteAccountId).then(res => {
                    if(res.data.data.status === true) {
                        this.toggleDeleteModal(false);
                        this.getAccounts(this.platform, 1);
                    }
                    else {
                        this.showAlert('error', 'Problem deleting account');
                    }
                }).catch(err => {
                    this.showAlert('error', 'Error deleting account');
                }).finally(() => {
                    this.deleting = false;
                });
            }
        }
    }
</script>
