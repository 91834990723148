<template>
    <div class="border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="p-5">
            <h5 class="text-primary-500 text-sm uppercase font-semibold pb-4 leading-tight">Check Next Lead Interval</h5>
            <p class="font-medium pb-3">
                Time interval between automatically checking for next lead
            </p>
            <div class="grid grid-cols-2 gap-4 mb-5">
                <div class="flex items-center">
                    <input :disabled="!nextLeadCheckIntervalInput"
                           class="text-center w-full border border-grey-350 rounded px-4 py-1 mr-2 outline-none"
                           min="0"
                           type="number"
                           v-model="nextLeadCheckInterval"
                           :class="{
                                'border-grey-350 bg-light-module': !darkMode,
                                'bg-grey-100 cursor-not-allowed' : !darkMode && !nextLeadCheckIntervalInput,
                                'border-blue-400 bg-dark-background text-blue-400': darkMode && nextLeadCheckIntervalInput,
                                'bg-dark-module border-blue-400 cursor-not-allowed' : darkMode && !nextLeadCheckIntervalInput
                           }"
                    />
                    <span class="text-sm">Second(s)</span>
                </div>
            </div>
            <button v-if="nextLeadCheckIntervalInput" @click="toggleNextLeadIntervalInput" class="transition duration-200 text-sm font-medium focus:outline-none py-2 rounded-md px-5 mr-3"
                    :class="{'bg-grey-250 hover:bg-light-background text-white': !darkMode, 'bg-grey-500 hover:bg-grey-600 text-white': darkMode}">
                Cancel
            </button>

            <button v-if="nextLeadCheckIntervalInput" @click="update" class="transition duration-200 text-sm font-medium focus:outline-none py-2 rounded-md px-5"
                    :class="{'border border-primary-500 text-blue-550': !nextLeadCheckIntervalInput, 'bg-primary-500 hover:bg-blue-500 text-white': nextLeadCheckIntervalInput}">
                Update Interval
            </button>
            <button v-else
                    @click="toggleNextLeadIntervalInput"
                    class="transition duration-200 text-sm font-medium focus:outline-none py-2 rounded-md px-5"
                    :class="{'border border-primary-500 text-blue-550': !nextLeadCheckIntervalInput, 'bg-primary-500 hover:bg-blue-500 text-white': nextLeadCheckIntervalInput}">
                Edit Interval
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CheckNextLeadInterval",
        props: {
            darkMode: {
                type: Boolean,
                default: false
            },
            modelValue: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                nextLeadCheckIntervalInput: false,
            }
        },
        emits: ['update:modelValue', 'update'],
        computed: {
            nextLeadCheckInterval: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit('update:modelValue', parseInt(value));
                }
            }
        },
        methods: {
            toggleNextLeadIntervalInput() {
                this.nextLeadCheckIntervalInput = !this.nextLeadCheckIntervalInput;
            },
            update() {
                this.toggleNextLeadIntervalInput();
                this.$emit('update');
            }
        },
    }
</script>

<style scoped>
    /* Hide arrows for input type number */
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
</style>
