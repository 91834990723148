<template>
    <div>
        <div class="p-5">
            <div class="mb-4">
                <p class="text-base">
                    <span class="mr-2">Message {{ sentOrReceived }} </span>
                    <span class="text-primary-500"> {{ text.other_number }}</span>
                </p>
                <p class="text-sm mt-2">
                    <span class="mr-2">{{ receivedOrSentBy }} </span>
                    <span class="text-primary-500 mr-2"> {{ user.name ?? 'Unknown User'}} </span>
                    <span v-if="text.phone.phone">({{ text.phone.phone}})</span>
                </p>
            </div>
            <div v-if="expanded">
                <Dropdown class="max-w-sm"
                          placeholder="Quick Actions"
                          @change="handleAction"
                          :options="activityActions"
                          :dark-mode="darkMode"
                />
            </div>
        </div>
        <div class="w-max ml-16 min-w-[50%]">
            <speech-bubble class="max-w-[90%] w-max"
                           v-for="message in textHistory"
                           :key="message.id"
                           :dark-mode="darkMode"
                           :content="message.message_body"
                           :from="message.direction === 'outbound' ? message.phone?.phone : 'External contact'"
                           :direction="message.direction"
                           :timestamp="message.created_at"
            />
            <div v-if="!showLoadHistoryButton && !textHistory.length" class="mt-6 opacity-75 italic">
                <p>No previous text messages found.</p>
            </div>
            <alerts-container :dark-mode="darkMode" v-if="alertActive" :text="alertText" :type="alertType" />
            <loading-spinner v-if="loadingHistory" />
            <div>
                <button v-if="showLoadHistoryButton" @click="getTextHistory" class="text-primary-300 my-4 text-sm font-semibold border border-primary-500 rounded-md py-2 px-5">
                    <span class="mr-2">Show message history</span>
                    <svg class="inline-block w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" >
                        <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 00-1.032-.211 50.89 50.89 0 00-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 002.433 3.984L7.28 21.53A.75.75 0 016 21v-4.03a48.527 48.527 0 01-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979z" />
                        <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 001.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0015.75 7.5z" />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import ActivityComments from "../comments/ActivityComments";
import Dropdown from "../../../../Shared/components/Dropdown";
import SpeechBubble from "./SpeechBubble";
import LoadingSpinner from "../../../../Shared/components/LoadingSpinner";
import AlertsContainer from "../../../../Shared/components/AlertsContainer";
import AlertsMixin from "../../../../../mixins/alerts-mixin";
import ApiService from "../../../services/api";
import dispatchesGlobalEventsMixin from "../../../../../mixins/dispatches-global-events-mixin";

export default {
    name: "TextItem",
    components: {
        AlertsContainer,
        LoadingSpinner,
        SpeechBubble,
        Dropdown,
        ActivityComments,
    },
    mixins: [ AlertsMixin, dispatchesGlobalEventsMixin ],
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        itemData: {
            type: Object,
            default: {},
        },
        userData: {
            type: Object,
            default: {},
        },
        expanded: {
            type: Boolean,
            default: false,
        },
        companyId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            api: ApiService.make(),
            loadingHistory: false,
            showLoadHistoryButton: true,
            text: {
                created_at: null,
                direction: null,
                external_reference: null,
                external_type: null,
                id: null,
                message_body: null,
                other_number: null,
                phone_id: null,
                updated_at: null,
                phone: {
                    id: null,
                    phone: '',
                }
            },
            user: {
                name: null,
                email: null,
            },
            textHistory: [],
            activityActions: [
                { id: 'textContact', name: 'Text external contact' },
                { id: 'textUser', name: `Text ${this.userData.name || 'User'}` },
                { id: 'emailUser', name: `Email ${this.userData.name || 'User'}` },
            ],
        }
    },
    computed: {
        sentOrReceived() { return this.text.direction === 'outbound' ? `sent to` : `received from`; },
        receivedOrSentBy() { return this.text.direction === 'outbound' ? `sent by` : `received by`; },
    },
    mounted() {
        Object.assign(this.text, this.itemData);
        Object.assign(this.user, this.userData);
        this.textHistory.push(this.text);
    },
    methods: {
        async getTextHistory() {
            this.loadingHistory = true;
            this.api.getSmsHistory(this.text.other_number, this.companyId).then(response => {
                if (response.data.data.status) {
                    this.textHistory = response.data.data.messages;
                    this.showLoadHistoryButton = false;
                }
                else this.showAlert('error', err.response?.data?.message || 'Error loading text history.' );
            }).catch(err => {
                this.showAlert('error', err.response?.data?.message || 'Error loading text history.' );
            }).finally(() => {
                this.loadingHistory = false;
            });
        },
        handleAction({ id }) {
            if (typeof(this[id]) === 'function') this[id]();
            else alert(`Quick Action ${id} not yet implemented.`);
        },
        async textContact(contactName = 'Contact', phoneNumber = this.text.other_number) {
            phoneNumber = `${phoneNumber}`.replace(/\D/g, '');
            if (/\d{10,11}/.test(phoneNumber)) {
                this.dispatchGlobalEvent('sms', { phone: phoneNumber, name: contactName, comment: 'ActivityFeed Text' });
            }
        },
        textUser() {
            this.textContact(this.user.name ?? 'User', this.text.phone.phone);
        },
        emailUser() {
            if (this.userData.email) window.open(`mailto:${this.userData.email}`, '_self');
        },
    }
}
</script>

<style scoped>

</style>
