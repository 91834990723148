import {defineStore} from "pinia";
import ApiService from "../vue/components/Companies/services/api";
import _ from "lodash";

export const useActivityPageStore = defineStore('activity-page', {
    state: () => {
        return {
            apiService: ApiService.make(),
            totalActivityItems: null,
            selectedTab: 'All',
            tabs: [
                {name: 'All', current: true, itemType: null},
                {name: 'Action', current: false, itemType: 'action'},
                {name: 'Call', current: false, itemType: 'call'},
                {name: 'Text', current: false, itemType: 'text'},
                {name: 'Task', current: false, itemType: 'task'},
            ],
        }
    },
    actions: {
        /**
         * @param {number} companyId
         * @param {object} activityTabFilters
         */
        getActivitiesOverview(companyId, activityTabFilters = {}) {
            if (typeof companyId !== 'number') {
                throw new TypeError(`"companyId" must be a number. ${companyId} passed.`);
            }

            if (!_.isObject(activityTabFilters)) {
                throw new TypeError(`"activityTabFilters" must be an object if it. ${JSON.stringify(activityTabFilters)} passed.`);
            }

            this.apiService.getCompanyActivityFeedOverview(companyId, activityTabFilters).then(resp => {
                if (resp.data.data.status === true) {
                    /**
                     * @typedef resp
                     * @property {object} data.data.overview
                     */
                    this.totalActivityItems = resp.data.data.overview ?? null;
                }
            }).catch((e) => {
                throw new Error(e)
            });
        },
        selectTab(selected) {
            const options = ['All', 'Action', 'Call', 'Text', 'Task'];

            if (!options.includes(selected)) {
                throw new TypeError(`"selected" bust be one of the following: ${JSON.stringify(options)}. ${JSON.stringify(selected)} passed.`);
            }

            if (!_.isArray(this.tabs)) {
                throw new TypeError(`"this.tabs" must be an array.`);
            }

            this.tabs.forEach((x) => {
                if (!_.isObject(x)) {
                    throw new TypeError(`Each element in "this.tabs" must be an object.`)
                }

                if (!x.hasOwnProperty('name')) {
                    throw new TypeError(`Each element in "this.tabs" must be an object with "name" property.`)
                }
            })

            const tab = this.tabs.filter(x => x.name === selected)[0];

            if (!tab) {
                throw new TypeError(`Could not find value for tab in ${JSON.stringify(this.tabs)}.`);
            }

            if (!tab.hasOwnProperty('itemType')) {
                throw new TypeError(`${JSON.stringify(tab)} does not have "itemType" property.`);
            }

            const itemTypeOptions = [null, 'action', 'call', 'text', 'task'];

            if (!itemTypeOptions.includes(tab.itemType)) {
                throw new TypeError(`"tab.itemType" must be one of the following: ${JSON.stringify(itemTypeOptions)}. ${tab.itemType} passed.`)
            }

            this.selectedTab = selected;
        },
        sendToTab(value) {
            this.selectTab(value);
        }
    },
    persist: false,
})
