<template>
    <div class="mt-2">
        <div v-if="dropdownOptions !== null && dropdownOptions.length > 0" v-for="(item, index) in payload?.values ?? []" class="grid grid-cols-1 gap-3 border-b py-4" :class="{'border-grey-200': !darkMode, 'border-blue-400 ': darkMode}">
            <div class="flex items-start">
                <p @click="removeItem(index)" class="cursor-pointer hover:bg-red-500 bg-primary-500 h-6 w-6 rounded-full text-white mr-3 inline-flex items-center justify-center font-bold text-sm whitespace-nowrap">{{ index + 1 }}</p>
                <div class="w-full md:w-1/2 md:pr-1">
                    <p class="uppercase font-semibold text-xs mb-1" :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                        {{ inputTitle }}
                    </p>
                    <input v-model="payload.values[index].value" :class="{'hover:bg-grey-120 border-grey-200 text-grey-400 bg-grey-50': !darkMode, 'hover:border-blue-400 border-blue-700 text-blue-400 bg-dark-background': darkMode}"
                           class="z-30 cursor-pointer uppercase text-xs font-semibold transition duration-100 w-full border flex justify-between items-center rounded py-2 px-4 h-9"/>
                </div>
                <div class="w-full md:w-1/2 md:pl-1">
                    <p class="uppercase font-semibold text-xs mb-1" :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                        {{ actionDropdownTitle }}
                    </p>
                    <Dropdown :options="dropdownOptions" :dark-mode="darkMode"
                              v-model="payload.values[index].workflow" placeholder="Select Option" :key="payload.values[index].workflow"/>
                    <div class="mt-4" v-if="payload.values[index].workflow === 0">
                        <p class="uppercase font-semibold text-xs mb-1" :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            {{ templateDropdownTitle }}
                        </p>
                        <dropdown :options="templates" :dark-mode="darkMode" v-model="payload.values[index].template" placeholder="Select Template"
                                   :key="payload.values[index].template"></dropdown>
                    </div>

                </div>
            </div>
        </div>
        <div class="flex items-center justify-center my-2">
            <button @click="addItem" type="button" class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-primary-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-550">
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
import Dropdown from "../../../Shared/components/Dropdown";

export default {
    name: "WorkflowSelections",
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        dropdownOptions: {
            type: Array,
            default: null
        },
        templates: {
            type: Array,
            default: []
        },
        templateDropdownTitle: {
            type: String,
            default: "Select Template"
        },
        actionDropdownTitle: {
            type: String,
            default: "Select Action"
        },
        inputTitle: {
            type: String,
            default: "Result Name"
        },
        modelValue: {
            type: Object,
            default: ({values: []})
        }
    },
    components: {Dropdown},
    emits: ['update:modelValue'],
    data() {
        return {

        }
    },
    computed: {
        payload: {
            get() {return this.modelValue ? this.modelValue : {};},
            set(value) {this.$emit('update:modelValue', value)}
        }
    },
    methods: {
        addItem() {
            if(!this.payload.values)
                this.payload.values = [];

            this.payload.values.push({value: null, workflow: null, template: null});
            this.$emit('update:modelValue', this.payload);
        },
        removeItem(index) {
            this.payload.values.splice(index, 1);
        }
    }
}
</script>

<style lang="scss">

</style>
