<template>
    <div ref="editor-wrapper">
        <slot name="title"></slot>
        <textarea class="tiny-mice-editor"></textarea>
    </div>
</template>

<script>

import {waitForCallbackToReturnTrue} from "../../../../utilities/loading-utilities";
import SharedApiService from "../services/api";

export default {
    name: "WysiwygEditor",
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        autoWidth: {
            type: [Number, String],
            default: null,
        },
        plugins: {
            type: String,
            default: null
        },
        toolBar: {
            type: String,
            default: null
        },
        modelValue: {
            type: String,
            default: ''
        },
        editorHeight: {
            type: Number,
            default: 500
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            tinyMCEPlugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
            tinyMCEToolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
            editor: null,
            api: SharedApiService.make(),
            acceptedImages: ['image/jpeg', 'image/png', 'image/gif'],
            acceptedMedias: ['audio/mpeg', 'audio/wav', 'audio/x-wav', 'video/mp4', 'video/mpeg'],
            acceptedFiles: ['application/pdf'],
            maxMediaSize: 50,
            maxFileSize: 10,
            maxImageSize: 10,
            dialog: null,
            autoWidthPixels: null,
        }
    },
    created() {
        if (this.plugins) this.tinyMCEPlugins = this.plugins;
        if (this.toolBar) this.tinyMCEToolbar = this.toolBar;
    },
    mounted() {
        if (this.autoWidth) this.setAutoWidth();
        waitForCallbackToReturnTrue(() => window.tinymce !== undefined).then(() => this.initTinymce());
    },
    methods: {
        initTinymce() {
            window.tinymce.init({
                selector: '.tiny-mice-editor',
                plugins: this.tinyMCEPlugins,
                toolbar: this.tinyMCEToolbar,
                height: this.editorHeight,
                width: this.autoWidthPixels,
                convert_urls : false,
                file_picker_types: 'file image media',
                setup: (editor) => this.editorSetup(editor),
                skin: this.darkMode ? 'oxide-dark' : 'oxide',
                content_css: this.darkMode ? 'dark' : 'default',
                images_upload_handler: (blobInfo, success, failure) => this.handleFIleUpload(blobInfo, success, failure),
                file_picker_callback: (callback, value, meta) => this.handleFilePickerCallback(callback, value, meta)
            });
        },
        setAutoWidth() {
            const availableWidth = this.$refs["editor-wrapper"].offsetWidth,
                autoWidthInteger = parseInt(this.autoWidth);
            this.autoWidthPixels = /%/.test(`${this.autoWidth}`)
                ? (autoWidthInteger/100) * availableWidth
                : autoWidthInteger;
        },
        editorSetup(editor) {
            this.editor = editor;
            editor.on('init', (e) => this.onInit(e));
            editor.on('change', (e) => this.onChange(e));
        },
        handleFIleUpload(blobInfo, success, failure) {
            const formData = new FormData();

            formData.append('file', blobInfo.blob(), blobInfo.filename());

            this.api.wysiwygEditorUploadFile(formData)
                .then(resp => success(resp.data.data.url))
                .catch(() => failure('File upload was failed.'));
        },
        handleFilePickerCallback(callback, value, meta) {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');

            if (meta.filetype === 'media') input.setAttribute('accept', this.acceptedMedias.join(','));
            else if (meta.filetype === 'file') input.setAttribute('accept', this.acceptedFiles.join(','));
            else input.setAttribute('accept', this.acceptedImages.join(','));

            input.onchange = () => {
                const file = input.files[0];
                const reader = new FileReader();

                if (!this.validateFile(meta.filetype, file)) return;

                reader.onload = () => {
                    const id = 'blobid' + (new Date()).getTime();
                    const blobCache =  this.editor.editorUpload.blobCache;
                    const base64 = reader.result.split(',')[1];
                    const blobInfo = blobCache.create(id, file, base64);

                    blobCache.add(blobInfo);

                    this.editor.ui.disable();
                    this.openDialog('Uploading', 'Please wait....');

                    this.handleFIleUpload(blobInfo, (url) => {
                        callback(url, {title: file.name});
                        this.editor.ui.enable();
                        this.closeDialog();
                    }, (error) =>{
                        this.alertError(error);
                        this.editor.ui.enable();
                    });
                };
                reader.readAsDataURL(file);
            };

            input.click();
        },
        onInit(e) {
            this.editor.setContent(this.modelValue);
        },
        onChange(e) {
            this.$emit('update:modelValue', this.editor.getContent());
        },
        validateFile(type, file) {
            switch (type) {
                case 'file':
                    if (!this.validateFileType(type, file.type) || !this.validateFIleSize(type, file.size)) {
                        this.alertError(`Only files types ${this.acceptedFiles.join(', ')} and size ${this.maxFileSize}M accepted`);
                        return false;
                    }

                   return true;
                case 'media':
                    if (!this.validateFileType(type, file.type) || !this.validateFIleSize(type, file.size)) {
                        this.alertError(`Only files types ${this.acceptedMedias.join(', ')} and size ${this.maxMediaSize}M accepted`);
                        return false;
                    }

                    return true;
                case 'image':
                    if (!this.validateFileType(type, file.type) || !this.validateFIleSize(type, file.size)) {
                        this.alertError(`Only files types ${this.acceptedImages.join(', ')} and size ${this.maxImageSize}M accepted`);
                        return false;
                    }

                    return true;
                default:
                    if (!this.validateFileType(type, file.type) || !this.validateFIleSize(type, file.size)) {
                        this.alertError(`Only files types ${this.acceptedImages.concat(this.acceptedFiles).concat(this.acceptedMedias).join(', ')} and size 5M accepted`);
                        return false;
                    }

                    return true;
            }
        },
        validateFileType(type, filetype) {
            switch (type) {
                case 'file':
                    return this.acceptedFiles.indexOf(filetype) !== -1;
                case 'media':
                    return this.acceptedMedias.indexOf(filetype) !== -1;
                case 'image':
                    return this.acceptedImages.indexOf(filetype) !== -1;
                default:
                    return this.acceptedImages.concat(this.acceptedFiles).concat(this.acceptedMedias).indexOf(filetype);
            }
        },
        validateFIleSize(type, filesize) {
            switch (type) {
                case 'file':
                    return filesize < (this.maxFileSize * 1024 * 1024);
                case 'media':
                    return filesize < (this.maxMediaSize * 1024 * 1024);
                case 'image':
                    return filesize < (this.maxImageSize * 1024 * 1024);
                default:
                    return filesize < (5 * 1024 * 1024);
            }
        },
        alertError(error) {
            this.openDialog('Error', error);
        },
        openDialog(title, text, buttons = []) {
            if (this.dialog) this.dialog.close();

            this.dialog = this.editor.windowManager.open({
                title: title,
                body: {
                    type: 'panel',
                    items: [
                        {
                            type: 'htmlpanel',
                            html: text
                        }
                    ]
                },
                buttons: buttons
            });
        },
        closeDialog() {
            if (this.dialog) this.dialog.close();
        }
    }
}
</script>

<style scoped>

</style>
