<template>
    <div>
        <FilterRow @update:filter="storeFilters.companyContacts = $event" :filter="storeFilters.companyContacts"
                   :dark-mode="darkMode"
                   :filter-name="storeFilters.companyContacts.name">
            <template v-slot:condition>
                <Dropdown
                    :options="communicationConditionOptions"
                    :selected="storeFilters.companyContacts.condition"
                    v-model="storeFilters.companyContacts.condition"
                    :dark-mode="darkMode"
                    :maxHeight="'max-h-[8rem]'"
                    class="col-span-2"
                ></Dropdown>
            </template>
        </FilterRow>
        <FilterRow @update:filter="storeFilters.amountOfPurchasedLeads = $event"
                   :filter="storeFilters.amountOfPurchasedLeads"
                   :dark-mode="darkMode"
                   :filter-name="storeFilters.amountOfPurchasedLeads.name">
            <template v-slot:condition>
                <Dropdown
                    :options="dateConditionOptions"
                    :selected="storeFilters.amountOfPurchasedLeads.condition"
                    v-model="storeFilters.amountOfPurchasedLeads.condition"
                    :dark-mode="darkMode"
                    :maxHeight="'max-h-[8rem]'"
                    class="col-span-2"
                ></Dropdown>
            </template>
        </FilterRow>
        <FilterRow @update:filter="storeFilters.estimatedRevenue = $event" :filter="storeFilters.estimatedRevenue"
                   :dark-mode="darkMode"
                   :filter-name="storeFilters.estimatedRevenue.name">
        </FilterRow>
        <FilterRow @update:filter="storeFilters.employeeCount = $event" :filter="storeFilters.employeeCount"
                   :dark-mode="darkMode"
                   :filter-name="storeFilters.employeeCount.name">
        </FilterRow>
        <FilterRow @update:filter="storeFilters.googleReviews = $event" :filter="storeFilters.googleReviews"
                   :dark-mode="darkMode"
                   :filter-name="storeFilters.googleReviews.name">
        </FilterRow>
        <FilterRow @update:filter="storeFilters.googleRating = $event" :filter="storeFilters.googleRating"
                   :dark-mode="darkMode"
                   :filter-name="storeFilters.googleRating.name">
            <template v-slot:first-input>
                <Dropdown
                    placeholder="Enter Value"
                    :options="ratingValueOptions"
                    :selected="storeFilters.googleRating.firstValue"
                    v-model="storeFilters.googleRating.firstValue"
                    :dark-mode="darkMode"
                    :maxHeight="'max-h-[8rem]'"
                    class="col-span-2"
                ></Dropdown>
            </template>

            <template v-slot:second-input>
                <Dropdown
                    placeholder="Enter Value"
                    :options="ratingValueOptions"
                    :selected="storeFilters.googleRating.secondValue"
                    v-model="storeFilters.googleRating.secondValue"
                    :dark-mode="darkMode"
                    :maxHeight="'max-h-[8rem]'"
                    class="col-span-2"
                ></Dropdown>
            </template>
        </FilterRow>
    </div>
</template>

<script>
import Dropdown from "../Dropdown";
import ToggleSwitch from "../ToggleSwitch";
import {useCompanyServicingAreaFiltersStore} from "../../../../../stores/company-servicing-area-filters.store";
import {mapWritableState} from 'pinia'
import CustomInput from "../CustomInput";
import FilterRow from "../FilterConfigModal/FilterRow.vue";
import he from "he";

/**
 * @typedef {"withPhone"|"withEmail"|"withBoth"|"withNeither"} CompanyContactsFiltersConditionOptionsJsDocType
 */

/**
 * @typedef {"allTime"|"last30Days"|"last60Days"|"last90Days"|"lastSixMonths"|"lastYear"|"lastTwoYears"} AmountOfLeadsPurchasedFiltersConditionOptionsJsDocType
 */

/**
 * @typedef CompanyContactsCompanyServicingAreaFilterJsDocType
 * @property {string} name The name of the filter being used.
 * @property {boolean} active Whether or not the filter is being sued.
 * @property {number} firstValue The first value to be filtering against.
 * @property {OperatorJSDocType} firstOperator The operator used against the value.
 * @property {?"and"|"or"} logical A string representation of logical operators like && (and) and || (or).
 * @property {?number} secondValue
 * @property {?"greaterThan"|"lessThan"|"greaterThanOrEqualTo"|"lessThanOrEqualTo"|"equalTo"} secondOperator
 * @property {CompanyContactsFiltersConditionOptionsJsDocType} condition
 */

/**
 * @typedef APLCompanyServicingAreaFilterJsDocType
 * @property {string} name The name of the filter being used.
 * @property {boolean} active Whether or not the filter is being sued.
 * @property {number} firstValue The first value to be filtering against.
 * @property {OperatorJSDocType} firstOperator The operator used against the value.
 * @property {?"and"|"or"} logical A string representation of logical operators like && (and) and || (or).
 * @property {?number} secondValue
 * @property {?"greaterThan"|"lessThan"|"greaterThanOrEqualTo"|"lessThanOrEqualTo"|"equalTo"} secondOperator
 * @property {AmountOfLeadsPurchasedFiltersConditionOptionsJsDocType} condition
 */

/**
 * @typedef CompanyServicingAreaFiltersJsDocType
 * @property {CompanyContactsCompanyServicingAreaFilterJsDocType} companyContacts
 * @property {APLCompanyServicingAreaFilterJsDocType} amountOfPurchasedLeads
 * @property {CompanyServicingAreaFilterJsDocType} estimatedRevenue
 * @property {CompanyServicingAreaFilterJsDocType} employeeCount
 * @property {CompanyServicingAreaFilterJsDocType} googleReviews
 * @property {CompanyServicingAreaFilterJsDocType} googleRating
 */

export default {
    emits: ['update:filters'],
    name: "CompanyServicingAreaFilters",
    components: {
        FilterRow,
        CustomInput,
        Dropdown, ToggleSwitch
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        initialPreset: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            communicationConditionOptions: [
                {id: 'withPhone', name: 'With Phone'},
                {id: 'withEmail', name: 'With Email'},
                {id: 'withBoth', name: 'With Both'},
                {id: 'withNeither', name: 'With Neither'},
            ],
            dateConditionOptions: [
                {id: 'allTime', name: 'All Time'},
                {id: 'last30Days', name: 'Last 30 Days'},
                {id: 'last60Days', name: 'Last 60 Days'},
                {id: 'last90Days', name: 'Last 90 Days'},
                {id: 'lastSixMonths', name: 'Last Six Months'},
                {id: 'lastYear', name: 'Last Year'},
                {id: 'lastTwoYears', name: 'Last Two Years'},
            ],
            ratingValueOptions: [
                {
                    id: 1,
                    name: he.decode("&#9733;&#9734;&#9734;&#9734;&#9734;")
                },
                {
                    id: 2,
                    name: he.decode(`&#9733;&#9733;&#9734;&#9734;&#9734;`)
                },
                {
                    id: 3,
                    name: he.decode(`&#9733;&#9733;&#9733;&#9734;&#9734;`)
                },
                {
                    id: 4,
                    name: he.decode(`&#9733;&#9733;&#9733;&#9733;&#9734;`)
                },
                {
                    id: 5,
                    name: he.decode(`&#9733;&#9733;&#9733;&#9733;&#9733;`)
                },
            ]
        }
    },
    computed: {
        ...mapWritableState(useCompanyServicingAreaFiltersStore, {
            /**
             * @param {CompanyServicingAreaFiltersJsDocType} storeFilters
             */
            storeFilters: 'filters'
        })
    }
}
</script>

<style scoped>

</style>
