import axios from 'axios';

export default class ApiService {
    constructor(baseUrl, baseEndpoint, version) {
        this.baseEndpoint = baseEndpoint;
        this.baseUrl = baseUrl;
        this.version = version;
    }

    axios() {
        const axiosConfig = {
            baseURL: `/${this.baseUrl}/v${this.version}/${this.baseEndpoint}`
        }

        return axios.create(axiosConfig);
    }

    static make() {
        return new ApiService('internal-api', 'tasks', 1);
    }

    getTask(taskId = null)
    {
        return this.axios().get('/next-task', {
            params: taskId ? {task_id: taskId} : {}
        });
    }

    getTeams() {
        return this.axios().get('/teams');
    }

    updateWorkflowStatus(
        taskId,
        runningWorkflowId,
        status,
        value
    ) {
        return this.axios().patch(`${taskId}/running-workflow/${runningWorkflowId}`, {
            status,
            value
        });
    }

    updateManualTaskStatus(taskId, type, value) {
        return this.axios().patch(`${taskId}/update-manual-task-status`, {
            type,
            value
        });
    }

    skipTask(taskId) {
        return this.axios().patch(`/${taskId}/skip-task`);
    }

    rescheduleTask(taskId, option, availableAt = null, note) {
        const payload = {option: option};

        if (availableAt) {
            payload.available_at = availableAt
        }

        if (note) {
            payload.note = note;
        }

        return this.axios().patch(`/${taskId}/reschedule-task`, payload);
    }

    getTaskNotes(taskId) {
        return this.axios().get(`${taskId}/task-notes`);
    }

    getAllTasks(params) {
        return this.axios().get('/all', {params: params});
    }

    getTodayTasks(params) {
        return this.axios().get('/today', {params: params});
    }

    getOverdueTasks(params) {
        return this.axios().get('/overdue', {params: params});
    }

    getUpcomingTasks(params) {
        return this.axios().get('/upcoming', {params: params});
    }

    getCompletedTasks(params) {
        return this.axios().get('/completed', {params: params});
    }

    getTaskOverview(companyId, allUser) {
        return this.axios().get('/overview', {params: {
            company_id: companyId,
            all_user: allUser
        }});
    }

    reassignTask(taskId, userId) {
        return this.axios().patch(`/${taskId}/reassign-task`, {
            user_id: userId
        });
    }

    saveAction(actionId, taskId, forId, relationType, subject, message, categoryId) {
        return this.axios().post(`/${taskId ?? 0}/actions`, {
            action_id: actionId,
            for_id: forId,
            for_relation_type: relationType,
            subject: subject,
            message: message,
            action_category_id: categoryId,
        });
    }

    createTaskNote(taskId, note) {
        return this.axios().post(`/${taskId}/task-notes`, {
            note
        });
    }

    createTask(task) {
        return this.axios().post(`/`, task);
    }

    updateTaskSubject(taskId, subject) {
        return this.axios().patch(`/${taskId}/task-subject`, {
            subject: subject
        })
    }

    updateTaskPriority(taskId, priority) {
        return this.axios().patch(`/${taskId}/priority`, {
            priority: priority
        })
    }

    batchComplete(payload) {
        return this.axios().patch('/batch-complete', payload);
    }

    deleteManualTask(taskId) {
        return this.axios().delete(`/manual-tasks/${taskId}`);
    }
}
