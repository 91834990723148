<template>
    <div class="border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="p-5">
            <h5 class="text-primary-500 text-sm uppercase font-semibold pb-4 leading-tight">Lead Opening Interval</h5>
            <p class="font-medium pb-3">
                Interval between opening lead delivery
            </p>
            <div class="grid grid-cols-2 gap-4 mb-5">
                <div class="flex items-center">
                    <input :disabled="!openingIntervalInput"
                           class="text-center w-full border border-grey-350 rounded px-4 py-1 mr-2 outline-none"
                           min="0"
                           type="number"
                           v-model="leadOpeningInterval"
                           :class="{
                                'border-grey-350 bg-light-module': !darkMode,
                                'bg-grey-100 cursor-not-allowed' : !darkMode && !openingIntervalInput,
                                'border-blue-400 bg-dark-background text-blue-400': darkMode && openingIntervalInput,
                                'bg-dark-module border-blue-400 cursor-not-allowed' : darkMode && !openingIntervalInput
                           }"
                    />
                    <span class="text-sm">Second(s)</span>
                </div>
            </div>
            <button v-if="openingIntervalInput" @click="toggleEditIntervalInput" class="transition duration-200 text-sm font-medium focus:outline-none py-2 rounded-md px-5 mr-3"
                    :class="{'bg-grey-250 hover:bg-light-background text-white': !darkMode, 'bg-grey-500 hover:bg-grey-600 text-white': darkMode}">
                Cancel
            </button>

            <button v-if="openingIntervalInput" @click="update" class="transition duration-200 text-sm font-medium focus:outline-none py-2 rounded-md px-5"
                    :class="{'border border-primary-500 text-blue-550': !openingIntervalInput, 'bg-primary-500 hover:bg-blue-500 text-white': openingIntervalInput}">
                Update Interval
            </button>
            <button v-else
                    @click="toggleEditIntervalInput"
                    class="transition duration-200 text-sm font-medium focus:outline-none py-2 rounded-md px-5"
                    :class="{'border border-primary-500 text-blue-550': !openingIntervalInput, 'bg-primary-500 hover:bg-blue-500 text-white': openingIntervalInput}">
                Edit Interval
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "LeadOpeningInterval",
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            openingIntervalInput: false,
        }
    },
    emits: ['update:modelValue', 'update'],
    computed: {
        leadOpeningInterval: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', parseInt(value));
            }
        }
    },
    methods: {
        toggleEditIntervalInput() {
            this.openingIntervalInput = !this.openingIntervalInput;
        },
        update() {
            this.toggleEditIntervalInput();
            this.$emit('update');
        }
    },
}
</script>

<style scoped>
    /* Hide arrows for input type number */
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
</style>
