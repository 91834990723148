export default {
    data: {
        images: {},
        additionalPlugins: [],
        imageFileBasePath: ''
    },
    methods: {
        saveImages() {
            //todo: implement saving image to google cloud
        }
    }
}
