<template>
    <div v-if="content">
        <div class="relative h-9">
        </div>
        <div class="bg-primary-800 relative w-full inset-x-0 h-9 z-50">
            <div class="absolute w-full h-9 bg-primary-500 left-0 top-0 animate-pulse z-10"></div>
            <div class="flex h-full justify-center items-center px-3 sm:px-6 lg:px-8 z-20 relative">
                <p class="font-medium text-white text-center text-sm">
                    <span class="md:hidden">{{ content }}</span>
                    <span class="hidden md:inline">{{ content }}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TopBar",
    props: {
        content: {
            type: String,
            default: null
        }
    }
}
</script>

<style scoped>

</style>
