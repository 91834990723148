<template>
    <button class="transition duration-200 font-semibold bg-grey-500 hover:bg-grey-700 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5"
            @click="showWorkflowAddForm = true">
        + Create Test Event
    </button>
    <Modal :small="true" v-if="showWorkflowAddForm" :confirm-text="confirmText" :close-text="'Cancel'" :dark-mode="darkMode" @close="showWorkflowAddForm = false" @confirm="fireEvent">
            <template v-slot:header>
                <h4 class="text-xl font-medium">Create Test Event</h4>
            </template>
            <template v-slot:content>
                <div class="rounded-lg bg-red-100 p-4 mb-8" v-if="creatingError">
                    <div class="text-sm text-red-700">
                        {{ creatingError }}
                    </div>
                </div>
                <div class="grid grid-cols-4 md:grid-cols-5 gap-4 mb-4">
                    <p class="text-right font-medium flex items-center justify-end">Event Category:</p>
                    <div class="col-span-3">
                        <Dropdown :placeholder="'Event Category'"
                          v-model="selectedEventCategory"
                          v-on:update:modelValue="fetchEvents"
                          :options="eventCategories"
                          :dark-mode="darkMode"
                          :selected="selectedEventCategory"
                          :key="selectedEventCategory"/>
                    </div>
                </div>
                <div>
                    <div class="grid grid-cols-4 md:grid-cols-5 gap-4 mb-4">
                        <p class="text-right font-medium flex items-center justify-end">Event Name:</p>
                        <div class="col-span-3">
                            <Dropdown :placeholder="'Event Name'"
                              v-model="selectedEventName"
                              :options="events"
                              :dark-mode="darkMode"
                              :key="selectedEventName"/>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="grid grid-cols-4 md:grid-cols-5 gap-4 mb-4">
                        <p class="text-right font-medium flex items-center justify-end">Company:</p>
                        <input class="w-full border rounded pl-8  focus:outline-none focus:border focus:border-primary-500 pr-4 py-2" placeholder="ID" type="text" v-model="requestedCompany"
                           :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>

                        <p v-if="showCampaign" class="text-right font-medium flex items-center justify-end">Campaign:</p>
                        <input v-if="showCampaign" class="w-full border rounded pl-8  focus:outline-none focus:border focus:border-blue-550 pr-4 py-2" placeholder="ID" type="text" v-model="requestedCampaign"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-30 text-blue-400': darkMode}"/>

                        <p v-if="showLeadId" class="text-right font-medium flex items-center justify-end">Lead Id:</p>
                        <input v-if="showLeadId" class="w-full border rounded pl-8  focus:outline-none focus:border focus:border-blue-550 pr-4 py-2" placeholder="ID" type="text" v-model="requestedLeadId"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-30 text-blue-400': darkMode}"/>
                    </div>
                </div>
            </template>
        </Modal>
</template>

<script>
import ApiService from "../services/api";
import Dropdown from "../../Shared/components/Dropdown";
import Modal from "../../Shared/components/Modal";

export default {
    name: "TestEventsWidget",
    components: {Dropdown, Modal},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selectedEventCategory: null,
            selectedEventName: null,
            requestedCompany: null,
            requestedCampaign: null,
            requestedLeadId: null,
            groupedEvents: {},
            eventCategories: [],
            events: [],
            showWorkflowAddForm: false,
            showCampaign: false,
            showLeadId: false,
            api: ApiService.make(),
            creatingEvent: false,
            confirmText: 'Create',
            creatingError: null
        }
    },
    mounted() {
        this.fetchEventCategories();
    },
    methods: {
        fetchEventCategories(){
            this.api.getEventCategories(true).then(resp => {
                this.groupedEvents = resp.data.data;
                this.eventCategories = Object.keys(this.groupedEvents);
                this.selectedEventCategory = this.eventCategories[0] ?? null;

                if(this.selectedEventCategory) {this.fetchEvents();}
            }).catch(e => console.log(e));
        },
        fetchEvents(){

            this.showCampaign = this.selectedEventCategory == 'campaigns' ? true : false;
            this.showLeadId = this.selectedEventCategory == 'leads' ? true : false;

            this.events = [];
            this.events = this.groupedEvents[this.selectedEventCategory];
            this.selectedEventName = this.events[0] ?? null;
        },
        fireEvent(){
            if(this.creatingEvent) {return;}

            this.confirmText = 'Creating...';
            this.creatingEvent = true;
            this.creatingError = null;

            this.api.fireTestEvent(
                this.selectedEventCategory,
                this.selectedEventName,
                this.requestedCompany,
                this.requestedCampaign,
                this.requestedLeadId
            ).then(() => {
                this.showWorkflowAddForm = false;
                this.creatingError = null;

            }).catch(e => {
                this.creatingError = e.response.data.message;
            }).finally(() => {
                this.creatingEvent = false;
                this.confirmText = 'Create'
            });
        }
    }
}
</script>

<style scoped>

</style>
