export const validateAlert = (type, text) => {
    const alertTypes = ['warning', 'error', 'success'];

    if (!alertTypes.includes(type)) {
        throw new TypeError(`"type" must be one of the following: ${JSON.stringify(alertTypes)}. "${type}" passed. Defaulting to "success".`)
    }

    if (typeof text !== 'string') throw new TypeError(`"text" must be a string. ${text} passed.`)

    return {
        type,
        text
    }
}
