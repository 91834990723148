import { computed, toRefs } from 'vue'

export default function useClasses (props, context, dependencies)
{
    const refs = toRefs(props)
    const disabled = refs.disabled

    // ============ DEPENDENCIES ============

    const checked = dependencies.checked

    // ============== COMPUTED ==============

    const classes = computed(() => ({
        container: 'inline-block',
        toggle: 'flex w-14 h-6 rounded-full relative cursor-pointer transition-all items-center border',
        toggleOn: 'bg-primary-500 border-primary-500 justify-start text-white',
        toggleOff: 'bg-slate-500 border-slate-300 border-opacity-25 bg-opacity-25 justify-start text-white',
        toggleOnDisabled: '',
        toggleOffDisabled: '',
        handle: 'inline-block bg-light-module w-5 h-5 border-2 border-transparent top-1/2 -translate-y-1/2 rounded-full absolute transition-all',
        handleOn: 'left-full -translate-x-full',
        handleOff: 'left-0',
        handleOnDisabled: '',
        handleOffDisabled: '',
        label: 'toggle-label',
        ...refs.classes.value,
    }))

    const classList = computed(() => {
        return {
            container: classes.value.container,
            toggle: [
                classes.value.toggle,
                disabled.value
                    ? (checked.value ? classes.value.toggleOnDisabled : classes.value.toggleOffDisabled)
                    : (checked.value ? classes.value.toggleOn : classes.value.toggleOff)
            ],
            handle: [
                classes.value.handle,
                disabled.value
                    ? (checked.value ? classes.value.handleOnDisabled : classes.value.handleOffDisabled)
                    : (checked.value ? classes.value.handleOn : classes.value.handleOff)
            ],
            label: classes.value.label,
        }
    })

    return {
        classList,
    }
}
