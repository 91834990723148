<template>
    <div class="row-span-2 border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="p-5 flex items-center justify-between">
            <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Calling Timezones</h5>
        </div>
        <div class="grid grid-cols-4 gap-3 mb-2 px-5">
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Timezone</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs">Open</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs">Close</p>
        </div>
        <div class="border-t overflow-y-auto"
             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
            <div>
                <div class="grid grid-cols-4 gap-3 border-b px-5 py-3 items-center"
                     v-for="timezone in timezones" :key="timezone.standard_utc_offset"
                     :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <p class="text-sm col-span-2 truncate">
                        {{ timezone.name }}
                    </p>
                    <div>
                        <input :value="getTimezoneFromOffset(timezone.standard_utc_offset).local_open_hour"
                               @input="handleTimezoneInput(timezone.standard_utc_offset, $event.target.value, 'open')"
                               :disabled="!timezoneEditMode"
                               class="text-center w-full border border-grey-350 rounded px-2 py-1"
                               placeholder="8" type="number"
                               :class="{
                                    'border-grey-350 bg-light-module': !darkMode,
                                    'bg-grey-100 cursor-not-allowed' : !darkMode && !timezoneEditMode,
                                    'border-blue-400 bg-dark-background text-blue-400': darkMode && timezoneEditMode,
                                    'bg-dark-module border-blue-400 cursor-not-allowed' : darkMode && !timezoneEditMode
                               }" />
                    </div>
                    <div>
                        <input :value="getTimezoneFromOffset(timezone.standard_utc_offset).local_close_hour"
                               @input="handleTimezoneInput(timezone.standard_utc_offset, $event.target.value, 'close')"
                               :disabled="!timezoneEditMode"
                               class="text-center w-full border border-grey-350 rounded px-2 py-1"
                               placeholder="20" type="number"
                               :class="{
                                    'border-grey-350 bg-light-module': !darkMode,
                                    'bg-grey-100 cursor-not-allowed' : !darkMode && !timezoneEditMode,
                                    'border-blue-400 bg-dark-background text-blue-400': darkMode && timezoneEditMode,
                                    'bg-dark-module border-blue-400 cursor-not-allowed' : darkMode && !timezoneEditMode
                               }" />
                    </div>
                </div>
            </div>
        </div>
        <div class="p-3">
            <button v-if="timezoneEditMode" @click="toggleEditTimezones" class="transition duration-200 text-sm font-medium focus:outline-none py-2 rounded-md px-5 mr-3"
                    :class="{'bg-grey-250 hover:bg-light-background text-white': !darkMode, 'bg-grey-500 hover:bg-grey-600 text-white': darkMode}">
                Cancel
            </button>

            <button v-if="timezoneEditMode" @click="update" class="transition duration-200 text-sm font-medium focus:outline-none py-2 rounded-md px-5"
                    :class="{'border border-primary-500 text-blue-550': !timezoneEditMode, 'bg-primary-500 hover:bg-blue-500 text-white': timezoneEditMode}">
                Update Timezones
            </button>
            <button v-else @click="toggleEditTimezones" class="transition duration-200 text-sm font-medium focus:outline-none py-2 rounded-md px-5"
                    :class="{'border border-primary-500 text-blue-550': !timezoneEditMode, 'bg-primary-500 hover:bg-blue-500 text-white': timezoneEditMode}">
                Edit Timezones
            </button>
        </div>
    </div>
</template>

<script>

export default {
    name: "CallingTimezoneManagementList",
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            timezoneEditMode: false,
            timezones: [
                {standard_utc_offset: -5, name: "Eastern"},
                {standard_utc_offset: -6, name: "Central"},
                {standard_utc_offset: -7, name: "Mountain"},
                {standard_utc_offset: -8, name: "Pacific"},
            ]
        }
    },
    emits: ['update:modelValue', 'update'],
    methods: {
        toggleEditTimezones() {
            this.timezoneEditMode = !this.timezoneEditMode
        },
        getTimezoneFromOffset(offset) {
            return this.modelValue.find((tz) => {
                return parseInt(tz.standard_utc_offset) === parseInt(offset);
            }) || {};
        },
        handleTimezoneInput(offset, hour, type) {
            let timezone = this.modelValue.find((tz) => {
                return parseInt(tz.standard_utc_offset) === parseInt(offset);
            });

            if(!timezone) {
                return;
            }

            if(type === 'open') {
                timezone.local_open_hour = parseInt(hour);
            }
            else if(type === 'close') {
                timezone.local_close_hour = parseInt(hour);
            }

            this.$emit('update:modelValue', this.modelValue);
        },
        update() {
            this.toggleEditTimezones();
            this.$emit('update');
        }
    }
}
</script>

<style scoped>
    /* Hide arrows for input type number */
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
</style>
