import {BaseApiService} from "./base";

class DummyApiService extends BaseApiService {
    constructor(delay = 150) {
        super("DummyApiService");

        this.delay = delay;
    }

    _makeResponse(data) {
        return new Promise((resolve, reject) => {
           setTimeout(() => resolve({data: {data}}), this.delay);
        });
    }

    getVoicemails() {
        return this._makeResponse({result: true, voicemails: []});
    }

}

export { DummyApiService };

