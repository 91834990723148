export default {
    methods: {
        dispatchGlobalEvent(name, data = {}) {
            document.dispatchEvent(new CustomEvent(`global-event-${name}`, {detail: data}));
        },
        listenForGlobalEvent(name, callback) {
            document.addEventListener(`global-event-${name}`, (e) => callback(e.detail));
        }
    }
}
