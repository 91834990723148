import {BaseApiService} from "./base";

class DummyApiService extends BaseApiService {
    constructor(delay = 150) {
        super("DummyApiService");

        this.delay = delay;
    }

    _makeResponse(data) {
        return new Promise((resolve, reject) => {
           setTimeout(() => resolve({data: {data}}), this.delay);
        });
    }

    getCallForwardingData() {
        return this._makeResponse([
            { status: 1, forward_to_number: '5555555555' }
        ]);
    }

    updateCallForwardingStatus() {
        return this._makeResponse({
            status: true,
        });
    }

    updateCallForwardingNumber() {
        return this._makeResponse({
            errors: "Dummy API driver only",
        });
    }

    getUserTimezone() {
        return this._makeResponse({
            name: "MOUNTAIN",
            value: "-07:00",
        });
    }
    updateUserTimezone() {
        return this._makeResponse({
            status: true,
        });
    }


}

export { DummyApiService };

