<template>
    <user-table :dark-mode="darkMode" :users="users" :can-impersonate="canImpersonate" @delete-user="deleteUser"></user-table>
</template>

<script>
import UserTable from "./components/UserTable";
import {ApiFactory} from "./services/api/factory";
export default {
    components: {UserTable},
    mixins: [],
    data() {
        return {
            api: null,
            users: [],
        }
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        canImpersonate: {
            type: Boolean,
            default: false
        },
        apiDriver: {
            type: String,
            default: 'api'
        },
    },
    created() {
        this.api = ApiFactory.makeApiService(this.apiDriver);
        this.getUsers()
    },
    methods: {
        getUsers() {
            this.api.getUsers().then(res => {
                if (res.data.data.status === true) {
                    this.users = res.data.data.users;
                }
            }).catch(err => {

            });
        },
        deleteUser(userId) {
            this.api.deleteUser(userId).then(res => {
                if (res.data.data.status === true) {
                    this.getUsers();
                }
            }).catch(err => {

            });
        }
    },
};
</script>
