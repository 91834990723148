// noinspection JSFileReferences

import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime.js';
import utc from 'dayjs/plugin/utc.js';
import Timezone from 'dayjs/plugin/timezone.js';
import advancedFormat from 'dayjs/plugin/advancedFormat.js';
import {operatorOptions} from "./options";
import {DateTime} from "luxon";

[relativeTime, utc, Timezone, advancedFormat].forEach(extension => dayjs.extend(extension));

/**
 * @typedef FilterConfig
 * @property {string} dateTime.format.us
 * @property {string} dateTime.format.usWithTime
 * @property {string} dateTime.format.long
 * @property {string} dateTime.format.relative
 * @property {string} dateTime.format.weekdayWithDateTimeShort
 * @property {regex} phoneNumbers.na.invalidInputCharacters
 * @property {function} phoneNumbers.na.formatOutput
 */

/**
 * @type FilterConfig
 * Presets for global Date/Currency formatters
 */
export const filterConfig = {
    dateTime: {
        format: {
            us: (dayjsDateTime) => dayjsDateTime.format('MM/DD/YYYY'),
            usWithTime: (dayjsDateTime) => dayjsDateTime.format('MM/DD/YYYY[,] h:mm a'),
            long: (dayjsDateTime) => dayjsDateTime.format('DD MMMM YYYY [at] HH:MM a ([UTC]ZZ)'),
            relative: (dayjsDateTime) => dayjsDateTime.fromNow(),
            weekdayWithDateTimeShort: (dayjsDateTime) => dayjsDateTime.format(`ddd - M/D/YYYY[,] h:mm A z`),
        }
    },
    phoneNumbers: {
        na: { // North America
            invalidInputCharacters: /[^-\d\s]/,
            formatOutput: (digitString) => {
                let prefix = '';
                if (digitString.length > 10 && /^1/.test(digitString)) {
                    prefix = '+1 ';
                    digitString = digitString.slice(1);
                }
                const parts = digitString.match(/(\d{0,3})(\d{0,3})(\d{0,4})(\d*)?/);
                parts.shift();
                return `${prefix}${parts.filter(v => v).join('-')}`;
            }
        }
    }
}

/**
 * Global Vue date formatter. Accepts unix epoch/ISO 8601 input and uses dayjs library
 * @param value {string | number} - unix seconds / unix milliseconds / ISO 8601
 * @param dateFormat {string} - format string: use a key name shortcut from filtersConfig{} presets,
 *  otherwise see https://day.js.org/docs/en/display/format for all formatting options
 * @param forceTimezone {?string} - convert to specified IANA-valid timezone before formatting.
 *
 * @returns {string | string}
 */
export function dateFromTimestamp(value, dateFormat = 'us', forceTimezone = null) {
    let newDate = /\D/.test(value) || `${value}`.length === 13 ? // ISO and unix milliseconds
        dayjs(value)
        : dayjs.unix(value); // unix seconds
    if (!newDate || !newDate.$y) return '';
    if (forceTimezone) {
        try {
            newDate = newDate.tz(forceTimezone);
        } catch {
            console.warn(`Bad timezone passed to global formatter: "${forceTimezone}". Date was not converted.`)
        }
    }
    return dateFormat ?
        filterConfig.dateTime.format[dateFormat] ?
            filterConfig.dateTime.format[dateFormat](newDate) // preset format from above config object
            : newDate.format(dateFormat) // custom format string passed to dayjs
        : filterConfig.dateTime.format.us(newDate); // default to US 'MM/DD/YYYY' format
}

/**
 * Determines whether value and valueTwo equate to boolean based on operator string value.
 * @param {?number} value
 * @param {?number} valueTwo
 * @param {?OperatorJSDocType} operatorString
 * @returns {boolean}
 */
export const getOperatorResult = (value = null, operatorString = null, valueTwo = null) => {
    const checkValueParams = (param) => {
        const valueIsNotANumber = isNaN(param);

        if (valueIsNotANumber) {
            throw new TypeError(`"value" must be a number. ${param} passed.`)
        }
    }

    checkValueParams(value);
    checkValueParams(valueTwo);

    switch (operatorString) {
        case "equalTo":
            return value === valueTwo;
        case "lessThan":
            return value < valueTwo;
        case "greaterThan":
            return value > valueTwo;
        case "lessThanOrEqualTo":
            return value <= valueTwo;
        case "greaterThanOrEqualTo":
            return value >= valueTwo;
        default:
            throw new TypeError(`operator must be one of ${JSON.stringify(operatorOptions)}. ${operatorString} passed.`)
    }
}

/**
 * Takes an unlimited amount of array arguments and converts them to an array of every possible combination array.
 * @param {array} a
 * @returns array
 */
export const cartesian =
    (...a) => {
        if (!(a instanceof Array)) {
            throw `"a" must be instance of array. ${a} passed.`
        }

        const allValuesAreArrays = a.every(i => i instanceof Array);

        if (!allValuesAreArrays) {
            throw `"a" can only contain arrays. One or more elements is not an array.`
        }

        return a.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())))
    };

/**
 * Translates the date condition string to a unix integer timestamp.
 * @param {AmountOfLeadsPurchasedFiltersConditionOptionsJsDocType} condition
 * @return number
 * @throws Error
 */
export const translateDateConditionStringToIntTimestamp = (condition) => {
    switch (condition) {
        case "allTime":
            return 0;
        case "last30Days":
            return DateTime.now().minus({days: 30}).toUnixInteger()
        case "last60Days":
            return DateTime.now().minus({days: 60}).toUnixInteger()
        case "last90Days":
            return DateTime.now().minus({days: 90}).toUnixInteger()
        case "lastSixMonths":
            return DateTime.now().minus({months: 6}).toUnixInteger()
        case "lastTwoYears":
            return DateTime.now().minus({years: 2}).toUnixInteger()
        default:
            throw new TypeError(`"condition" must be one of the following: allTime, last30Days, last60Days, last90Days, lastSixMonths, or lastTwoYears. ${condition} passed.`)
    }
}

export const isObject = (obj) => {
    return (typeof obj === "object" && !Array.isArray(obj) && obj !== null)
}
