<template>
    <div class="grid grid-cols-1 gap-4 px-10 py-5">
        <InvoiceSummary :dark-mode="darkMode" :company-id="companyId"></InvoiceSummary>
        <InvoiceBilling :dark-mode="darkMode" :company-id="companyId"></InvoiceBilling>
    </div>
</template>

<script>
import ActionsHandle from "../../Shared/components/ActionsHandle";
import InvoiceSummary from "../../Shared/modules/InvoiceSummary";
import InvoiceBilling from "../../Shared/modules/InvoiceBilling";
export default {
    name: "InvoicesAndBilling",
    components: {InvoiceBilling, InvoiceSummary, ActionsHandle},
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        companyId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            invoices: [
                {id: 25270, generated: '08/13/2018', due: '08/13/2018', status: 'Unpaid', paymentMethod: 'Credit Card', amount: '693.00'},
                {id: 25271, generated: '08/13/2018', due: '08/13/2018', status: 'Paid', paymentMethod: 'Credit Card', amount: '693.00'},
                {id: 25272, generated: '08/13/2018', due: '08/13/2018', status: 'Paid', paymentMethod: 'Credit Card', amount: '693.00'},
                {id: 25273, generated: '08/13/2018', due: '08/13/2018', status: 'Paid', paymentMethod: 'Credit Card', amount: '693.00'}
            ]

        }
    },
    methods: {

    }
}
</script>

<style scoped>

</style>
