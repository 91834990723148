<template>
    <div class="col-span-3 border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div>
            <Tab
                :dark-mode="darkMode"
                :tabs="tabs"
                :total="totalTasks"
                :loading="loadingTasks"
                @selected="processTabFilter"
                :tabs-classes="'xl:w-1/2 md:w-2/3'"
                :default-tab-index="1"
            >
                <template v-slot:extra-tab>
                    <button v-show="false" class="text-blue-550 hover:text-blue-400 font-medium px-5 text-sm">+ Add queue</button>
                </template>
            </Tab>
        </div>
        <div class="flex justify-between px-5 my-8">
            <div class="flex items-center">
                <div class="flex items-center mr-3">
                    <p class="text-grey-300 uppercase text-xs mr-2">Filter</p>
                    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 4H13V6H3V4ZM0 0H16V2H0V0ZM6 8H10V10H6V8Z" fill="#ABB0B5"/>
                    </svg>
                </div>
                <div class="relative mr-3">
                    <input class="w-full border rounded pl-8  focus:outline-none focus:border focus:border-primary-500 pr-4 py-2 h-9" placeholder="Search" v-model="filterTaskName" type="text" v-on:keyup.enter="getTasks"
                           :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>
                    <div class="absolute top-0 left-0 w-8 h-full flex justify-center items-center">
                        <svg class="inset-y-0 fill-current text-grey-400" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.66667 2C4.69421 2 3.76158 2.38631 3.07394 3.07394C2.38631 3.76158 2 4.69421 2 5.66667C2 6.14818 2.09484 6.62498 2.27911 7.06984C2.46338 7.5147 2.73346 7.91891 3.07394 8.25939C3.41442 8.59987 3.81863 8.86996 4.26349 9.05423C4.70835 9.23849 5.18515 9.33333 5.66667 9.33333C6.14818 9.33333 6.62498 9.23849 7.06984 9.05423C7.5147 8.86996 7.91891 8.59987 8.25939 8.25939C8.59987 7.91891 8.86996 7.5147 9.05423 7.06984C9.23849 6.62498 9.33333 6.14818 9.33333 5.66667C9.33333 4.69421 8.94703 3.76158 8.25939 3.07394C7.57176 2.38631 6.63913 2 5.66667 2ZM1.65973 1.65973C2.72243 0.597022 4.16377 0 5.66667 0C7.16956 0 8.6109 0.597022 9.6736 1.65973C10.7363 2.72243 11.3333 4.16377 11.3333 5.66667C11.3333 6.41082 11.1868 7.14769 10.902 7.83521C10.7458 8.21219 10.5498 8.57029 10.3178 8.90361L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L8.90361 10.3178C8.57029 10.5498 8.21219 10.7458 7.83521 10.902C7.14769 11.1868 6.41082 11.3333 5.66667 11.3333C4.92251 11.3333 4.18564 11.1868 3.49813 10.902C2.81062 10.6172 2.18593 10.1998 1.65973 9.6736C1.13353 9.14741 0.716126 8.52272 0.431349 7.83521C0.146573 7.1477 0 6.41082 0 5.66667C0 4.16377 0.597022 2.72243 1.65973 1.65973Z"/>
                        </svg>
                    </div>
                </div>
                <Dropdown :dark-mode="darkMode" class="w-48 mr-2" v-model="filterTaskPriority" :options="taskPriorities" placeholder="Priority" :selected="filterTaskPriority"></Dropdown>
                <Dropdown v-model="searchCategory" class="w-48 mr-2" :options="taskCategories" :dark-mode="darkMode" placeholder="Category" :selected="searchCategory"></Dropdown>
                <Dropdown v-model="selectedTimezone" class="w-48 mr-2" :options="timezoneOptions" :dark-mode="darkMode" placeholder="Timezone"></Dropdown>
                <Datepicker class="mr-2" v-if="filterTab === 'Overdue' || filterTab === 'All'" v-model="filterDateTime" :dark="darkMode" :format="'yyyy-MM-dd'"></Datepicker>
                <button class="transition duration-200 bg-primary-500 font-semibold hover:bg-blue-500 text-white text-sm h-9 justify-center flex items-center font-medium focus:outline-none py-2 rounded-md px-5" @click="getTasks">
                    <svg class="inset-y-0 w-4 my-1 fill-current" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.66667 2C4.69421 2 3.76158 2.38631 3.07394 3.07394C2.38631 3.76158 2 4.69421 2 5.66667C2 6.14818 2.09484 6.62498 2.27911 7.06984C2.46338 7.5147 2.73346 7.91891 3.07394 8.25939C3.41442 8.59987 3.81863 8.86996 4.26349 9.05423C4.70835 9.23849 5.18515 9.33333 5.66667 9.33333C6.14818 9.33333 6.62498 9.23849 7.06984 9.05423C7.5147 8.86996 7.91891 8.59987 8.25939 8.25939C8.59987 7.91891 8.86996 7.5147 9.05423 7.06984C9.23849 6.62498 9.33333 6.14818 9.33333 5.66667C9.33333 4.69421 8.94703 3.76158 8.25939 3.07394C7.57176 2.38631 6.63913 2 5.66667 2ZM1.65973 1.65973C2.72243 0.597022 4.16377 0 5.66667 0C7.16956 0 8.6109 0.597022 9.6736 1.65973C10.7363 2.72243 11.3333 4.16377 11.3333 5.66667C11.3333 6.41082 11.1868 7.14769 10.902 7.83521C10.7458 8.21219 10.5498 8.57029 10.3178 8.90361L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L8.90361 10.3178C8.57029 10.5498 8.21219 10.7458 7.83521 10.902C7.14769 11.1868 6.41082 11.3333 5.66667 11.3333C4.92251 11.3333 4.18564 11.1868 3.49813 10.902C2.81062 10.6172 2.18593 10.1998 1.65973 9.6736C1.13353 9.14741 0.716126 8.52272 0.431349 7.83521C0.146573 7.1477 0 6.41082 0 5.66667C0 4.16377 0.597022 2.72243 1.65973 1.65973Z"/>
                    </svg>
                </button>
                <button class="transition duration-200 ml-3 h-9 text-sm font-semibold focus:outline-none py-2 rounded-md px-4 mr-2"
                        :class="{'bg-grey-250 hover:bg-light-background text-white': !darkMode, 'bg-grey-500 hover:bg-grey-600 text-white': darkMode}" @click="resetFilters">
                    Reset
                </button>
            </div>
            <div class="flex items-center">
                <button class="transition duration-200 text-sm font-semibold focus:outline-none py-2 rounded-md px-5 mr-3" @click="showCreateTask"
                        :class="{'bg-grey-475 hover:bg-blue-800 text-white': !darkMode, 'bg-blue-400 hover:bg-blue-500 text-white': darkMode}">
                    Create task
                </button>
                <button
                    class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-semibold focus:outline-none py-2 rounded-md px-5"
                    @click="startTasks"
                    v-if="!readOnly"
                >
                    Start tasks
                </button>
            </div>
        </div>
        <div v-if="!loadingTasks && tasks.length" class="grid grid-cols-12 items-center gap-x-3 my-2 px-5">
            <div class="text-slate-400 font-medium tracking-wide uppercase text-xs flex col-span-1">
                <input
                    v-model="taskSelected"
                    :class="[!darkMode ? 'hover:bg-grey-50 border-grey-200' : 'bg-dark-background hover:bg-dark-175 border-blue-400']"
                    @click="toggleBulkSelect($event)"
                    class="rounded-sm w-5 h-5 cursor-pointer border"
                    type="checkbox"
                    v-if="!readOnly"
                >
                <span v-if="headerVisible" class="text-center flex-1 text-slate-400 font-medium tracking-wide uppercase text-xs">Status</span>
            </div>
            <div v-if="headerVisible" class="col-span-2 uppercase text-xs text-center cursor-pointer rounded-lg flex items-center"
                 :class="filterSortCol === sortColumns.taskName ? 'text-blue-550 font-semibold' : 'hover:text-cyan-400 text-blue-400'"
                 @click="sort(sortColumns.taskName)">
                Task Name
                <svg v-if="filterSortCol === sortColumns.taskName"
                     :class="{
                                'text-blue-550 rotate-180': filterSortCol === sortColumns.taskName && filterSortDir === 'asc',
                                'text-blue-550': filterSortCol === sortColumns.taskName && filterSortDir !== 'asc',
                                'hover:text-cyan-400': filterSortCol !== sortColumns.taskName
                            }"
                     class="ml-2 transform transition-all duration-200 w-6 fill-current"
                     width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70711 0.292893C10.0976 0.683418 10.0976 1.31658 9.70711 1.70711L5.70711 5.70711C5.51957 5.89464 5.26522 6 5 6C4.73478 6 4.48043 5.89464 4.29289 5.70711L0.292893 1.70711C-0.0976315 1.31658 -0.0976315 0.683417 0.292893 0.292893C0.683417 -0.0976315 1.31658 -0.0976314 1.70711 0.292893L5 3.58579L8.29289 0.292893C8.68342 -0.0976311 9.31658 -0.0976311 9.70711 0.292893Z"/>
                </svg>
            </div>
            <div v-if="headerVisible" class="col-span-1 uppercase text-xs text-center cursor-pointer rounded-lg flex items-center"
                 :class="filterSortCol === sortColumns.assignedTo ? 'text-blue-550 font-semibold' : 'hover:text-cyan-400 text-blue-400'"
                 @click="sort(sortColumns.assignedTo)">
                Assigned To
                <svg v-if="filterSortCol === sortColumns.assignedTo"
                     :class="{
                                'text-blue-550 rotate-180': filterSortCol === sortColumns.assignedTo && filterSortDir === 'asc',
                                'text-blue-550': filterSortCol === sortColumns.assignedTo && filterSortDir !== 'asc',
                                'hover:text-cyan-400': filterSortCol !== sortColumns.assignedTo
                            }"
                     class="ml-2 transform transition-all duration-200 w-6 fill-current"
                     width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70711 0.292893C10.0976 0.683418 10.0976 1.31658 9.70711 1.70711L5.70711 5.70711C5.51957 5.89464 5.26522 6 5 6C4.73478 6 4.48043 5.89464 4.29289 5.70711L0.292893 1.70711C-0.0976315 1.31658 -0.0976315 0.683417 0.292893 0.292893C0.683417 -0.0976315 1.31658 -0.0976314 1.70711 0.292893L5 3.58579L8.29289 0.292893C8.68342 -0.0976311 9.31658 -0.0976311 9.70711 0.292893Z"/>
                </svg>
            </div>
            <p v-if="headerVisible" class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Category</p>
            <p v-if="headerVisible" class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Timezone</p>
            <div v-if="actionVisible" class="col-span-4 flex items-center">
                <p class="mr-10 text-blue-550 hover:text-blue-400 text-sm font-medium cursor-pointer inline-flex items-center" @click="showBulkComplete = true">
                    <svg class="mr-2" width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.59213 6.71136L1.07909 4.0464L0 5.19072L3.59213 9L11 1.14432L9.92091 0L3.59213 6.71136Z" fill="#339AFF"/>
                    </svg>
                    Mark as completed
                </p>
                <p class="mr-10 text-blue-550 hover:text-blue-400 text-sm font-medium cursor-pointer inline-flex items-center" v-if="false">
                    <svg class="mr-2" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.07066 9.3043L8.07078 4.30418L5.69582 1.92922L0.695701 6.92934C0.626865 6.99826 0.577967 7.08453 0.554193 7.17899L0 10L2.82047 9.44581C2.91516 9.42213 3.00179 9.37317 3.07066 9.3043V9.3043ZM9.68493 2.69003C9.88667 2.48823 10 2.21457 10 1.92922C10 1.64388 9.88667 1.37022 9.68493 1.16842L8.83158 0.315069C8.62978 0.11333 8.35612 0 8.07078 0C7.78543 0 7.51177 0.11333 7.30997 0.315069L6.45662 1.16842L8.83158 3.54338L9.68493 2.69003Z" fill="#339AFF"/>
                    </svg>
                    Edit
                </p>
                <p class="mr-10 text-blue-550 hover:text-blue-400 text-sm font-medium cursor-pointer inline-flex items-center" v-if="false">
                    <svg class="mr-2" width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 2.75H1V9.9C1 10.1917 1.10536 10.4715 1.29289 10.6778C1.48043 10.8841 1.73478 11 2 11H7C7.26522 11 7.51957 10.8841 7.70711 10.6778C7.89464 10.4715 8 10.1917 8 9.9V2.75H1.5ZM6.809 1.1L6 0H3L2.191 1.1H0V2.2H9V1.1H6.809Z" fill="#339AFF"/>
                    </svg>
                    Delete
                </p>
                <p class="text-blue-550 hover:text-blue-400 text-sm font-medium cursor-pointer inline-flex items-center" v-if="false">
                    <svg class="mr-2" width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 1.1C9 0.49335 8.5515 0 8 0H1C0.4485 0 0 0.49335 0 1.1V4.95H9V1.1ZM6.5 3.3H2.5V1.65H3.5V2.2H5.5V1.65H6.5V3.3ZM1 11H8C8.5515 11 9 10.5067 9 9.9V6.05H0V9.9C0 10.5067 0.4485 11 1 11ZM2.5 7.7H3.5V8.25H5.5V7.7H6.5V9.35H2.5V7.7Z" fill="#339AFF"/>
                    </svg>
                    Change queue
                </p>
            </div>
            <p v-if="headerVisible" class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Relation</p>
            <p v-if="headerVisible" class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">State : City</p>
            <p v-if="headerVisible" class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-1">Queue</p>
            <div v-if="headerVisible" class="col-span-1 uppercase text-xs text-center cursor-pointer rounded-lg flex items-center"
                 :class="filterSortCol === sortColumns.priority ? 'text-blue-550 font-semibold' : 'hover:text-cyan-400 text-blue-400'"
                 @click="sort(sortColumns.priority)">
                Priority
                <svg v-if="filterSortCol === sortColumns.priority"
                     :class="{
                                'text-blue-550 rotate-180': filterSortCol === sortColumns.priority && filterSortDir === 'asc',
                                'text-blue-550': filterSortCol === sortColumns.priority && filterSortDir !== 'asc',
                                'hover:text-cyan-400': filterSortCol !== sortColumns.priority
                            }"
                     class="ml-2 transform transition-all duration-200 w-6 fill-current"
                     width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70711 0.292893C10.0976 0.683418 10.0976 1.31658 9.70711 1.70711L5.70711 5.70711C5.51957 5.89464 5.26522 6 5 6C4.73478 6 4.48043 5.89464 4.29289 5.70711L0.292893 1.70711C-0.0976315 1.31658 -0.0976315 0.683417 0.292893 0.292893C0.683417 -0.0976315 1.31658 -0.0976314 1.70711 0.292893L5 3.58579L8.29289 0.292893C8.68342 -0.0976311 9.31658 -0.0976311 9.70711 0.292893Z"/>
                </svg>
            </div>
            <div v-if="headerVisible && filterTab !== 'Completed'" class="col-span-1 uppercase text-xs text-center cursor-pointer rounded-lg flex items-center"
                 :class="filterSortCol === sortColumns.due ? 'text-blue-550 font-semibold' : 'hover:text-cyan-400 text-blue-400'"
                 @click="sort(sortColumns.due)">
                Due
                <svg v-if="filterSortCol === sortColumns.due"
                     :class="{
                                'text-blue-550 rotate-180': filterSortCol === sortColumns.due && filterSortDir === 'asc',
                                'text-blue-550': filterSortCol === sortColumns.due && filterSortDir !== 'asc',
                                'hover:text-cyan-400': filterSortCol !== sortColumns.due
                            }"
                     class="ml-2 transform transition-all duration-200 w-6 fill-current"
                     width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70711 0.292893C10.0976 0.683418 10.0976 1.31658 9.70711 1.70711L5.70711 5.70711C5.51957 5.89464 5.26522 6 5 6C4.73478 6 4.48043 5.89464 4.29289 5.70711L0.292893 1.70711C-0.0976315 1.31658 -0.0976315 0.683417 0.292893 0.292893C0.683417 -0.0976315 1.31658 -0.0976314 1.70711 0.292893L5 3.58579L8.29289 0.292893C8.68342 -0.0976311 9.31658 -0.0976311 9.70711 0.292893Z"/>
                </svg>
            </div>
            <div v-else class="col-span-1 uppercase text-xs text-center cursor-pointer rounded-lg flex items-center"
                 :class="filterSortCol === sortColumns.completedAt ? 'text-blue-550 font-semibold' : 'hover:text-cyan-400 text-blue-400'"
                 @click="sort(sortColumns.completedAt)">
                Completed
                <svg v-if="filterSortCol === sortColumns.completedAt"
                     :class="{
                                'text-blue-550 rotate-180': filterSortCol === sortColumns.completedAt && filterSortDir === 'asc',
                                'text-blue-550': filterSortCol === sortColumns.completedAt && filterSortDir !== 'asc',
                                'hover:text-cyan-400': filterSortCol !== sortColumns.completedAt
                            }"
                     class="ml-2 transform transition-all duration-200 w-6 fill-current"
                     width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70711 0.292893C10.0976 0.683418 10.0976 1.31658 9.70711 1.70711L5.70711 5.70711C5.51957 5.89464 5.26522 6 5 6C4.73478 6 4.48043 5.89464 4.29289 5.70711L0.292893 1.70711C-0.0976315 1.31658 -0.0976315 0.683417 0.292893 0.292893C0.683417 -0.0976315 1.31658 -0.0976314 1.70711 0.292893L5 3.58579L8.29289 0.292893C8.68342 -0.0976311 9.31658 -0.0976311 9.70711 0.292893Z"/>
                </svg>
            </div>
        </div>
        <div v-if="loadingTasks" class="flex w-full h-100 justify-center items-center">
            <loading-spinner/>
        </div>
        <div v-if="!loadingTasks && !tasks.length" class="h-100 flex items-center justify-center text-center pb-4"
             :class="{'text-grey-800': !darkMode, 'text-grey-120': darkMode}">
            No Tasks found for this type
        </div>
        <div v-if="!loadingTasks && tasks.length" class="border-t border-b h-100 overflow-y-auto divide-y" ref="tasks"
             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
            <div v-for="task in tasks" class="grid grid-cols-12 gap-x-3 p-5 items-center relative cursor-pointer"
                 :class="{'text-grey-800 hover:bg-light-module border-light-border': !darkMode, 'text-grey-200 hover:bg-dark-module border-dark-border': darkMode}"
                 @click="handleTaskClick($event, task)">
                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs flex col-span-1">
                    <input
                        @click="toggleTaskSelected($event, task)"
                        :class="[!darkMode ? 'hover:bg-grey-50 border-grey-200' : 'bg-dark-background hover:bg-dark-175 border-blue-400']"
                        class="rounded-sm w-5 h-5 cursor-pointer border"
                        type="checkbox"
                        v-if="!readOnly"
                    >
                    <svg
                        :class="{
                            'text-grey-200': !darkMode && !task.completed,
                            'text-grey-400': darkMode && !task.completed,
                            'text-green-450': !darkMode && task.completed,
                            'text-green-400': darkMode && task.completed,
                        }"
                        class="fill-current flex-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0ZM8.001 14.413L4.288 10.708L5.7 9.292L7.999 11.587L13.293 6.293L14.707 7.707L8.001 14.413Z"/>
                    </svg>
                </p>
                <p class="text-sm col-span-2">{{ task.name }}</p>
                <p class="text-sm col-span-1">{{ task.assigned_user }}</p>
                <p class="text-sm col-span-1">{{ task.category }}</p>
                <p class="text-sm col-span-1">{{ task.timezone }}</p>
                <p class="text-sm col-span-1">{{ getTaskRelation(task) }}</p>
                <p class="text-sm col-span-1">{{ getStateCityForDisplay(task) }}</p>
                <p class="text-sm col-span-1">{{ task.queue }}</p>
                <p class="text-sm col-span-1">
                    <span v-if="task.priority<=1" class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">Low</span>
                    <span v-else-if="task.priority==2" class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800">Medium</span>
                    <span v-else-if="task.priority==3" class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-orange-100 text-orange-800">High</span>
                    <span v-else class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">Urgent</span>
                </p>
                <p class="text-sm col-span-1">{{ $filters.dateFromTimestamp(filterTab === 'Completed' ? task.completed_at : task.due, "usWithTime") }}</p>
                <div id="delete_button" class="py-2" v-if="!readOnly">
                    <delete-task :task="task" :dark-mode="darkMode" @task-deleted="getTasks">
                        <template v-slot:button>
                            <svg width="12" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 2.75H1V9.9C1 10.1917 1.10536 10.4715 1.29289 10.6778C1.48043 10.8841 1.73478 11 2 11H7C7.26522 11 7.51957 10.8841 7.70711 10.6778C7.89464 10.4715 8 10.1917 8 9.9V2.75H1.5ZM6.809 1.1L6 0H3L2.191 1.1H0V2.2H9V1.1H6.809Z" fill="#339AFF"/>
                            </svg>
                        </template>
                    </delete-task>
                </div>
                <div class="absolute h-full mr-6 w-8 flex items-center justify-center right-0 inset-y-0" v-if="!readOnly">
                    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z" fill="#0081FF"/>
                    </svg>
                </div>
            </div>
        </div>
        <div v-if="paginationData && paginationData.to" class="p-3">
            <Pagination :dark-mode="darkMode" :pagination-data="paginationData" :show-pagination="true" :show-results-per-page="true" @change-page="handlePaginationEvent"></Pagination>
        </div>
        <modal
            :confirm-text="savingTask ? 'Saving...' : 'Create Task'"
            :close-text="'Cancel'"
            :dark-mode="darkMode"
            @close="closeModal"
            @confirm="createTask"
            :small="true"
            v-if="showCreateTaskModal"
            :disable-confirm="savingTask"
        >
            <template v-slot:header>
                <h4 class="text-xl">Create Task</h4>
            </template>
            <template v-slot:content>
                <div class="mb-6" v-if="alertMessage">
                    <alert :text="alertMessage" :alert-type="alertType" :dark-mode="darkMode"></alert>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-3">
                    <div>
                        <p class="uppercase font-semibold text-xs mb-2"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Company
                        </p>
                        <autocomplete
                            :dark-mode="darkMode"
                            class="col-span-2"
                            v-model="company"
                            :options="companies"
                            :placeholder="'Company name'"
                            :model-value="companyId"
                            :create-user-input-option="true"
                            @search="searchCompanies('companyname', $event)">
                        </autocomplete>
                    </div>
                    <div>
                        <p class="uppercase font-semibold text-xs mb-2"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Task Type
                        </p>
                        <dropdown :options="taskTypes" :dark-mode="darkMode" :placeholder="'Select Task Type'" v-model="taskType"></dropdown>
                    </div>
                    <div>
                        <p class="uppercase font-semibold text-xs mb-2"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Task Note
                        </p>
                        <input class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                               placeholder="Task note"
                               v-model="taskNote"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                    </div>
                    <div>
                        <p class="uppercase font-semibold text-xs mb-2"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Available At
                        </p>
                        <button class="transition duration-200 text-sm font-semibold focus:outline-none py-3 rounded-lg px-5" @click="showCalender = true"
                                :class="{'bg-grey-475 hover:bg-blue-800 text-white': !darkMode, 'bg-blue-400 hover:bg-blue-500 text-white': darkMode}">
                            Pick Date Time
                        </button>
                        {{ availableAt }}
                        <div v-if="showCalender" class="mt-3">
                            <Datepicker :inline="true" @update:modelValue="setDateTime" :format="'yyyy-MM-dd'" :start-time="manualTaskDefaultTime"></Datepicker>
                        </div>
                    </div>
                    <div>
                        <p class="uppercase font-semibold text-xs mb-2"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Priority
                        </p>
                        <Dropdown :dark-mode="darkMode" class="mr-2" v-model="priority" :options="taskPriorities" placeholder="Priority" :selected="priority"></Dropdown>
                    </div>
                    <div>
                        <p class="uppercase font-semibold text-xs mb-2"  :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Task Category
                        </p>

                        <Dropdown v-model="taskCategory" :options="taskCategories" :dark-mode="darkMode" placeholder="Select Category" :selected="taskCategory"></Dropdown>
                    </div>
                </div>
            </template>
        </modal>
        <bulk-complete :dark-mode="darkMode" :tasks="selectedTasks" v-if="showBulkComplete" @close="showBulkComplete = false" @completed="tasksCompleted"></bulk-complete>
    </div>
</template>

<script>
import Tab from "../components/Tab";
import ApiService from "../../Tasks/services/api";
import LoadingSpinner from "../components/LoadingSpinner";
import Pagination from "../components/Pagination";
import Dropdown from "../components/Dropdown";
import InvoiceBilling from "./InvoiceBilling";
import Leads from "./Leads";
import CompanyContacts from "./Contacts";
import Campaigns from "../../Companies/Campaigns";
import Modal from "../components/Modal";
import Autocomplete from "../components/Autocomplete";
import SharedApiService from "../services/api";
import TaskManagementApiService from "../../TaskManagement/services/api";
import Alert from "../components/Alert";
import BulkComplete from "../../Tasks/BulkComplete";
import DeleteTask from "../../Tasks/DeleteTask";
import dayjs from "dayjs";

export default {
    name: "Tasks",
    components: {
        Campaigns,
        CompanyContacts,
        Leads,
        InvoiceBilling,
        Dropdown,
        Tab,
        LoadingSpinner,
        Pagination,
        Modal,
        Autocomplete,
        Alert,
        BulkComplete,
        DeleteTask
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        companyId: {
            type: Number,
            default: null
        },
        companyName: {
            type: String,
            default: null
        },
        allUserTask: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            apiService: null,
            sharedApi: SharedApiService.make(),
            taskManagementApi: TaskManagementApiService.make(),
            loadingTasks: false,
            tabs: [
                { name: 'All',       current: false  },
                { name: 'Due Today', current: true },
                { name: 'Overdue',   current: false },
                { name: 'Upcoming',  current: false },
                { name: 'Completed', current: false },
            ],
            taskPriorities: [
                { id: 1, name: 'Low'   },
                { id: 2, name: 'Medium'},
                { id: 3, name: 'High'  },
                { id: 4, name: 'Urgent'}
            ],
            sortColumns: {
                taskName    : 'subject',
                priority    : 'priority',
                due         : 'available_at',
                completedAt : 'completed_at',
                assignedTo  : 'assigned_user_id'
            },
            paginationData: null,
            totalTasks: null,
            tasks: [],
            taskSelected: false,
            selectedTasks: [],
            companies: [],
            taskTypes: [],
            showCreateTaskModal: false,
            taskNote: null,
            availableAt: null,
            priority: 1,
            taskType: null,
            company: null,
            showCalender: false,
            createTaskError: null,
            filterTab: null,
            filterTaskName: null,
            filterTaskPriority: null,
            filterSortCol: null,
            filterSortDir: 'desc',
            alertMessage: null,
            savingTask: false,
            alertType: 'success',

            perPage: '100',
            showBulkComplete: false,
            manualTaskDefaultTime: {hours: 8, minutes: 0, seconds: 0},
            taskCategory: 1,
            taskCategories: [],
            searchCategory: null,
            timezoneOptions: [
                {id: "any", name: "Any Timezone"},
                {id: "-05:00", name: "Eastern"},
                {id: "-06:00", name: "Central"},
                {id: "-07:00", name: "Mountain"},
                {id: "-08:00", name: "Pacific"},
                {id: "-09:00", name: "Alaska"},
                {id: "-10:00", name: "Hawaii"},
            ],
            selectedTimezone: "any",
            filterDateTime: null,
        }
    },
    created() {
      this.apiService = ApiService.make();
      this.load();
      this.getTaskCategories();

      if (this.companyId && this.companyName) {
          this.companies = [{
              id: this.companyId,
              name: `${this.companyId}: ${this.companyName}`
          }];
      }
    },
    computed: {
        actionVisible() {
            return this.selectedTasks.length > 0;
        },
        headerVisible() {
            return this.selectedTasks.length === 0;
        },
        readOnly() {
            return this.allUserTask;
        }
    },
    methods: {
        load() {
            this.loadingTasks = true;
            this.filterTab = this.tabs[1].name;
            this.filterSortCol = this.sortColumns.priority;

            this.getTasksOverview();
            this.getTasks();
            this.getTaskTypes();
        },
        toggleTaskSelected(event, task) {
            if (event.target.checked) {
                this.selectedTasks.push(task.id);
            } else {
                const index = this.selectedTasks.indexOf(task.id);

                if (index !== -1) {
                    this.selectedTasks.splice(index, 1);
                }
            }

            this.taskSelected = this.selectedTasks.length === this.tasks.length;
        },
        toggleBulkSelect(event) {
            if (event.target.checked) {
                this.selectedTasks = this.tasks.map(task => task.id);
                this.$refs.tasks.querySelectorAll('input[type=checkbox]').forEach(checkbox => checkbox.checked = true);
            }
            else {
                this.selectedTasks = [];
                this.$refs.tasks.querySelectorAll('input[type=checkbox]').forEach(checkbox => checkbox.checked = false);
            }
        },
        async getTasks() {
            this.tasks = [];
            if(!this.loadingTasks) this.loadingTasks = true;

            let params = this.getParams();

            switch (this.filterTab) {

                case 'All':
                    await this.apiService.getAllTasks(params).then(resp => {
                        if(resp.data.data.status === true) {
                            this.addTaskAndPaginationData(resp);
                            this.totalTasks[this.tabs[0].name] = resp.data.data.tasks.total ?? 0;
                        }
                    }).catch(e => console.error(e)).finally(() => this.loadingTasks = false);
                    break;

                case 'Due Today':
                    this.apiService.getTodayTasks(params).then(resp => {
                        if(resp.data.data.status === true) {
                            this.addTaskAndPaginationData(resp);
                            this.totalTasks[this.tabs[1].name] = resp.data.data.tasks.total ?? 0;
                        }
                    }).catch(e => console.error(e)).finally(() => this.loadingTasks = false);
                    break;

                case 'Overdue':
                    this.apiService.getOverdueTasks(params).then(resp => {
                        if(resp.data.data.status === true) {
                            this.addTaskAndPaginationData(resp);
                            this.totalTasks[this.tabs[2].name] = resp.data.data.tasks.total ?? 0;
                        }
                    }).catch(e => console.error(e)).finally(() => this.loadingTasks = false);
                    break;

                case 'Upcoming':
                    this.apiService.getUpcomingTasks(params).then(resp => {
                        if(resp.data.data.status === true) {
                            this.addTaskAndPaginationData(resp);
                            this.totalTasks[this.tabs[3].name] = resp.data.data.tasks.total ?? 0;
                        }
                    }).catch(e => console.error(e)).finally(() => this.loadingTasks = false);
                    break;

                case 'Completed':
                    this.apiService.getCompletedTasks(params).then(resp => {
                        if(resp.data.data.status === true) {
                            this.addTaskAndPaginationData(resp);
                            this.totalTasks[this.tabs[4].name] = resp.data.data.tasks.total ?? 0;
                        }
                    }).catch(e => console.error(e)).finally(() => this.loadingTasks = false);
                    break;
            }
        },
        addTaskAndPaginationData(resp) {
            let {data, ...paginationData} = resp.data.data.tasks;
            this.tasks = data ?? [];
            this.paginationData = paginationData;
        },
        getTasksOverview() {
            this.apiService.getTaskOverview(this.companyId, this.allUserTask).then(resp => {
                if(resp.data.data.status === true) {
                    this.totalTasks = resp.data.data.overview;
                }
            }).catch(e => {});
        },
        processTabFilter(filter) {
            this.filterTab = filter;
            this.filterSortCol = filter === 'Completed' ? this.sortColumns.completedAt : this.sortColumns.priority;
            this.getTasksOverview();
            this.getTasks();
        },
        handleTaskClick(event, task) {
            if (this.readOnly) return;
            if (event.target.nodeName.toLowerCase() === 'input') return;
            if (event.target.id === 'delete_button' || event.target.closest('#delete_button')) return;

            this.$emit('start-tasks', [task.id]);
        },
        startTasks() {
            this.$emit('start-tasks', this.selectedTasks.reverse());
        },
        resetFilters() {
            this.filterTaskName = this.filterTaskPriority = null;
            this.filterSortCol = this.sortColumns.priority;
            this.filterSortDir = 'desc';
            this.searchCategory = null;
            this.filterDateTime = null;
            this.getTasks();
        },
        sort(column = this.sortColumns.due) {
            this.filterSortDir = this.filterSortCol === column && this.filterSortDir === 'asc' ? 'desc' : 'asc';
            this.filterSortCol = column;
            this.getTasks();
        },
        showCreateTask() {
            this.showCreateTaskModal = true;
            this.taskNote = null;
            this.availableAt = null;
            this.taskType = null;
            this.company = null;
            this.showCalender = false;
            this.alertMessage = null;
        },
        closeModal() {
            this.showCreateTaskModal = false;
        },
        createTask() {
            if (this.savingTask) return;

            this.alertMessage = null;
            this.savingTask = true;

            this.apiService.createTask({
                company_id: this.company,
                task_type_id: this.taskType,
                subject: this.taskNote,
                available_at: this.availableAt,
                priority: this.priority,
                task_category_id: this.taskCategory
            }).then(() => {
                this.filterTab = this.tabs[1].name;
                this.getTasks();
                this.resetTabs();
                this.alertType = 'success';
                this.alertMessage = 'A task was created successfully'
                this.closeModal();
            }).catch(e => {
                this.alertType = 'error';
                this.alertMessage = e.response.data.message;
            }).finally(() => {
                this.savingTask = false;
                this.load();
            });
        },
        resetTabs() {
            this.tabs.forEach(tab => {
                tab.current = tab.name === 'Due Today';
            });
        },
        searchCompanies(nameType, query) {
            this.sharedApi.searchCompanyNamesAndId(query).then(res => {
                if (res.data.data.status === true) {
                    this.companies = res.data.data.companies;
                }
            })
        },
        getTaskTypes() {
            this.taskManagementApi.getTaskTypes().then(resp => this.taskTypes = resp.data.data.task_types);
        },
        setDateTime(date) {
            this.availableAt = this.$filters.dateFromTimestamp(date, 'YYYY-MM-DD HH:mm');
            this.showCalender = false;
        },
        async handlePaginationEvent(newPageUrl) {
            this.loadingTasks = true;
            this.perPage = newPageUrl.perPage;
            await axios.get(newPageUrl.link, {
                params: this.getParams()
            }).then(resp => {
                let {data, ...paginationData} = resp.data.data.tasks;
                this.tasks = data;
                this.paginationData = paginationData;
            }).catch(e => console.error(e)).finally(() => this.loadingTasks = false);
        },
        getTaskRelation(task) {
            if (task.manual) return task.payload?.company_name;

            return task?.event?.company?.company_name ?? task?.event?.campaign?.name;
        },
        tasksCompleted() {
            this.getTasks();
            this.showBulkComplete = false;
            this.selectedTasks = [];
            this.taskSelected = false;
        },
        getParams() {
            return {
                all_user: this.allUserTask,
                subject: this.filterTaskName,
                priority: this.filterTaskPriority,
                sort_col: this.filterSortCol,
                sort_dir: this.filterSortDir,
                company_id: this.companyId,
                task_category_id: this.searchCategory ?? undefined,
                timezone: this.selectedTimezone,
                date: this.filterDateTime ? dayjs(this.filterDateTime).format('YYYY-MM-DD') : null,
                perPage: this.perPage,
            };
        },
        getTaskCategories() {
            this.taskManagementApi.getTaskCategories().then(resp => this.taskCategories = resp.data.data.categories);
        },
        getStateCityForDisplay(task)
        {
            if (!task.event?.lead?.address) return '';

            return `${task.event.lead.address.state} : ${task.event.lead.address.city}`;
        }
    }
}
</script>

<style scoped>

</style>
