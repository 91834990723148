<template>
    <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <select id="tabs" name="tabs"
                class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-lg">
            <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
        </select>
    </div>
    <div class="hidden sm:flex border-b"
         :class="{'border-dark-border bg-dark-background' : darkMode, 'border-gray-200 bg-grey-50' : !darkMode}">
        <nav v-if="tabType === 'Badges'" class="relative z-0 flex cursor-pointer" :class="tabsClasses"
             aria-label="Tabs">
            <a v-for="(tab, tabIdx) in tabs" :key="tab.name" @click="tabSelected(tab)"
               :class="[!darkMode ? (tab.current ? 'text-blue-550' : 'text-gray-500 hover:text-gray-700')  : (tab.current ? 'text-blue-550' : 'text-gray-400 hover:text-gray-300'), !darkMode ? 'bg-light-module' : 'bg-dark-module', 'group flex-1 relative min-w-0 overflow-hidden py-4 px-4 text-sm font-medium text-center focus:z-10']"
               :aria-current="tab.current ? 'page' : undefined">
                <span>{{ tab.name }}</span>
                <span v-if="!loading && showTotal"
                      :class="[!darkMode ? (tab.current ? 'bg-cyan-100 text-blue-550' : 'bg-gray-100 text-gray-900') : (tab.current ? 'bg-dark-175 text-blue-550' : 'bg-dark-background text-grey-400'), 'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block']">{{
                        total ? total[tab.name] ?? 0 : 0
                    }}</span>
                <span v-if="loading && showTotal"
                      :class="[!darkMode ? (tab.current ? 'bg-cyan-100 text-cyan-100' : 'bg-gray-100 text-gray-100') : (tab.current ? 'bg-dark-175 text-dark-175' : 'bg-dark-background text-dark-40'), 'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block']">0</span>
                <span aria-hidden="true"
                      :class="[tab.current ? 'bg-primary-500' : 'bg-transparent', 'absolute inset-x-0 transform transition-all duration-300 bottom-0 h-0.5']"/>
            </a>
        </nav>
        <nav v-else-if="tabType === 'Normal'" class="relative z-0 flex cursor-pointer" :class="tabsClasses"
             aria-label="Tabs">
            <a v-for="(tab, tabIdx) in tabs" :key="tab.name" @click="tabSelected(tab)" class="font-medium"
               :class="[!darkMode ? (tab.current ? 'text-blue-550' : 'text-gray-500 hover:text-gray-700')  : (tab.current ? 'text-blue-550' : 'text-gray-400 hover:text-gray-300'), !darkMode ? 'bg-light-module' : 'bg-dark-module', 'group flex-1 relative min-w-0 overflow-hidden py-4 px-4 text-sm font-medium text-center focus:z-10']"
               :aria-current="tab.current ? 'page' : undefined">
                <span>{{ tab.name }}</span>
                <span aria-hidden="true"
                      :class="[tab.current ? 'bg-primary-500' : 'bg-transparent', 'absolute inset-x-0 transform transition-all duration-300 bottom-0 h-0.5']"/>
            </a>
        </nav>
        <slot name="extra-tab"></slot>
    </div>
</template>

<script>

/**
 * @typedef tab
 * @property {string} name
 * @property {boolean} current
 */

export default {
    name: "Tab",
    props: {
        tabs: {
            default: null,
            validator: (o) => {
                if (!_.isArray(o)) {
                    return false;
                }

                o.forEach(function (i) {
                    if (!i.hasOwnProperty('current')) {
                        return false;
                    }

                    if (!i.hasOwnProperty('name')) {
                        return false;
                    }
                })

                return true;
            }
        },
        tabType: {
            type: String,
            default: 'Badges',
        },
        darkMode: {
            type: Boolean,
            default: false
        },
        total: {
            type: Object,
            default: ({})
        },
        showTotal: {
            type: Boolean,
            default: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        tabsClasses: {
            type: String,
            default: ''
        },
        defaultTabIndex: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            lastSelectedTab: this.tabs[this.defaultTabIndex]
        }
    },
    emits: ['selected'],
    methods: {
        tabSelected(tab) {

            if (!tab.hasOwnProperty('current')) {
                throw new Error(`tab missing "current" property.`)
            }

            if (!_.isBoolean(tab.current)) {
                throw new Error(`"tab.current" must be a boolean value. ${tab.current} passed.`)
            }

            if (!tab.hasOwnProperty('name')) {
                throw new Error(`tab missing "name" property.`)
            }

            this.lastSelectedTab.current = false;
            tab.current = !tab.current;
            this.lastSelectedTab = tab;
            this.$emit('selected', tab.name);
        }
    }
}
</script>

<style scoped>

</style>
