import { useMagicKeys, whenever } from '@vueuse/core'
import {markRaw} from "vue";

export default {
    data: function() {
        return {
            keys: null
        }
    },
    created() {
        this.keys = markRaw(useMagicKeys());
    },
    methods: {
        registerShortcut(shortcodes, callback) {
            const split = this._getKeys(shortcodes);
            split.forEach(hotkey => {
                const shortcut = this.keys[hotkey];

                whenever(shortcut, callback);
            });
        },
        _getKeys(hotkeys) {
            hotkeys = hotkeys.replace(/\s/g, '');
            const keys = hotkeys.split(',');
            let index = keys.lastIndexOf('');

            for(; index >= 0;) {
                keys[index -1] += ",";
                keys.splice(index, 1);
                index = keys.lastIndexOf('');
            }

            return keys;
        }
    }
}
