import axios from 'axios';
import {BaseApiService} from "./base";

class ApiService extends BaseApiService {
    constructor(baseUrl, baseEndpoint, version) {
        super("ApiService");

        this.baseEndpoint = baseEndpoint;
        this.baseUrl = baseUrl;
        this.version = version;
    }

    axios() {
        const axiosConfig = {
            baseURL: `/${this.baseUrl}/v${this.version}/${this.baseEndpoint}`
        }

        return axios.create(axiosConfig);
    }

    getAvailableNumbers() {
        return this.axios().get('/management/available-numbers');
    }

    getUsers() {
        return this.axios().get('/');
    }

    deleteUser(userId) {
        return this.axios().delete(`/management/user/${userId}`);
    }
}

export { ApiService };
