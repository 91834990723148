<template>
    <div class="relative inline-block text-left z-50" v-click-outside="clickOutside">
        <div @click="click" >
            <slot></slot>
        </div>

        <div v-if="open" class="origin-top-right absolute right-0 w-56 overflow-hidden rounded-lg shadow-lg bg-light-module focus:outline-none"
             :class="{'border-grey-200 text-grey-500 bg-grey-50': !darkMode, 'border-blue-700 text-blue-300 bg-dark-background': darkMode}"
             role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
            <div class="py-1" role="none">
                <div v-for="option in options"
                     :class="{'hover:bg-grey-120': !darkMode, 'hover:bg-dark-175': darkMode}"
                     class="py-3 px-4 text-xs font-semibold cursor-pointer transition duration-200" @click="optionSelected(option)">
                    {{ option.name ? option.name : option }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ButtonDropdown",
    emits: ['selected'],
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            open: false,
        }
    },
    methods: {
        click() {
            this.open = !this.open;
        },
        optionSelected(option) {
            this.open = false;
            this.$emit('selected', option);
        },
        clickOutside() {
            if(this.open)
                this.open = false;
        }
    }
}
</script>

<style lang="scss">

</style>
