class BaseApiService {
    constructor(serviceName = "BaseApiService") {
        this.serviceName = serviceName;
    }

    _throwUnimplementedError(fn) {
        throw new Error("Not implemented error. `" + this.serviceName + "::" + fn + "()`");
    }

    getRecentNotifications() {
        this._throwUnimplementedError("getRecentNotifications");

        return new Promise((resolve, reject) => reject());
    }

    markNotificationAsRead(id, type) {
        this._throwUnimplementedError("markNotificationAsRead");

        return new Promise((resolve, reject) => reject());
    }

    getBouncedLeads() {
        this._throwUnimplementedError("getBouncedLeads");

        return new Promise((resolve, reject) => reject());
    }

    getQueues() {
        this._throwUnimplementedError("getQueues");

        return new Promise((resolve, reject) => {reject()});
    }

    getManagementQueue(id) {
        this._throwUnimplementedError("getQueue");

        return new Promise((resolve, reject) => {reject()});
    }

    createQueue(name, status) {
        this._throwUnimplementedError("createQueue");

        return new Promise((resolve, reject) => {reject()});
    }

    updateQueue(id, name, status) {
        this._throwUnimplementedError("updateQueue");

        return new Promise((resolve, reject) => {reject()});
    }

    deleteQueue(id) {
        this._throwUnimplementedError("deleteQueue");

        return new Promise((resolve, reject) => {reject()});
    }

    getTeams() {
        this._throwUnimplementedError("getTeams");

        return new Promise((resolve, reject) => {reject()});
    }

    getTeam(id) {
        this._throwUnimplementedError("getTeam");

        return new Promise((resolve, reject) => {reject()});
    }

    createTeam(name, queueId, offset) {
        this._throwUnimplementedError("createTeam");

        return new Promise((resolve, reject) => {reject()});
    }

    updateTeam(id, name, queueId, offset) {
        this._throwUnimplementedError("updateTeam");

        return new Promise((resolve, reject) => {reject()});
    }

    deleteTeam(id) {
        this._throwUnimplementedError("deleteTeam");

        return new Promise((resolve, reject) => {reject()});
    }

    getProcessors() {
        this._throwUnimplementedError("getProcessors");

        return new Promise((resolve, reject) => {reject()});
    }

    getProcessor(id) {
        this._throwUnimplementedError("getProcessor");

        return new Promise((resolve, reject) => {reject()});
    }

    createProcessor(userId, teamId, phoneId) {
        this._throwUnimplementedError("createProcessor");

        return new Promise((resolve, reject) => {reject()});
    }

    updateProcessor(id, userId, teamId, phoneId) {
        this._throwUnimplementedError("updateProcessor");

        return new Promise((resolve, reject) => {reject()});
    }

    deleteProcessor(id) {
        this._throwUnimplementedError("deleteProcessor");

        return new Promise((resolve, reject) => {reject()});
    }

    getNextLead(id = null) {
        this._throwUnimplementedError("getNextLead");

        return new Promise((resolve, reject) => {reject()});
    }

    cancelLead(id, reason, publicComment) {
        this._throwUnimplementedError("cancelLead");

        return new Promise((resolve, reject) => {reject()});
    }

    markLeadAsPendingReview(id, reason) {
        this._throwUnimplementedError("markLeadAsPendingReview");

        return new Promise((resolve, reject) => {reject()});
    }

    markLeadAsUnderReview(id, reason, publicComment) {
        this._throwUnimplementedError("markLeadAsPendingReview");

        return new Promise((resolve, reject) => {reject()});
    }

    approveLead(id, reason, bestTimeToContact, publicComment) {
        this._throwUnimplementedError("approveLead");

        return new Promise((resolve, reject) => {reject()});
    }

    updateBasicInfo(id, data) {
        this._throwUnimplementedError("updateLeadData");

        return new Promise((resolve, reject) => {reject()});
    }

    heartbeat(id) {
        this._throwUnimplementedError("heartbeat");

        return new Promise((resolve, reject) => {reject()});
    }

    getQueue() {
        this._throwUnimplementedError("getQueue");

        return new Promise((resolve, reject) => {reject()});
    }

    getStatistics() {
        this._throwUnimplementedError("getStatistics");

        return new Promise((resolve, reject) => {reject()});
    }

    getIndustries() {
        this._throwUnimplementedError("getIndustries");

        return new Promise((resolve, reject) => {reject()});
    }

    lookupLead(number) {
        this._throwUnimplementedError("lookupLead");

        return new Promise((resolve, reject) => {reject()});
    }

    getSMSHistory(leadId) {
        this._throwUnimplementedError("getSMSHistory");

        return new Promise((resolve, reject) => {reject()});
    }

    createOutboundCall(leadId, userPhoneNumber, otherNumber, ringCentralReference) {
        this._throwUnimplementedError("createOutboundCall");

        return new Promise((resolve, reject) => {reject()});
    }

    updateOutboundCall(communicationId, callComplete, result) {
        this._throwUnimplementedError("updateOutboundCall");

        return new Promise((resolve, reject) => {reject()});
    }

    updateInboundCall(communicationId, callComplete) {
        this._throwUnimplementedError("updateInboundCall");

        return new Promise((resolve, reject) => {reject()});
    }

    createOutboundSMS(leadId, body) {
        this._throwUnimplementedError("createOutboundSMS");

        return new Promise((resolve, reject) => {reject()});
    }

    getProcessingHistory() {
        this._throwUnimplementedError("getProcessingHistory");

        return new Promise((resolve, reject) => {reject()});
    }

    getGlobalConfigs() {
        this._throwUnimplementedError("getGlobalConfigs");

        return new Promise((resolve, reject) => {reject()});
    }

    saveGlobalConfigs(
        timeZoneOpeningDelay,
        recencyThreshold,
        minimumReviewTime,
        leadProcessableDelay,
        checkNextLeadInterval,
        timeZoneConfigurations,
        lastLeadCreatedInterval
    )
    {
        this._throwUnimplementedError("saveGlobalConfigs");

        return new Promise((resolve, reject) => {reject()});
    }

    getCommunicationToken() {
        this._throwUnimplementedError("getCommunicationToken");

        return new Promise((resolve, reject) => {reject()});
    }

    getNextProduct() {
        this._throwUnimplementedError("getCommunicationToken");

        return new Promise((resolve, reject) => {reject()});
    }
}

export { BaseApiService };
