<template>
    <div class="border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="px-5 pt-5 pb-4 flex items-center justify-between">
            <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Event Control Panel</h5>
            <button class="transition duration-200 font-semibold bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5"
                    @click="showWorkflowAddForm = true">
                + Add Event
            </button>
        </div>
        <div class="flex items-center px-5 mb-3">
            <div class="flex items-center mr-4" :class="{'text-grey-300': !darkMode, 'text-blue-400': darkMode}">
                <p class="mr-2 text-xs font-medium uppercase">
                    Filters</p>
                <svg class="fill-current" width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 4H13V6H3V4ZM0 0H16V2H0V0ZM6 8H10V10H6V8Z"/>
                </svg>
            </div>
            <div class="w-48">
                <Dropdown :placeholder="'Event Category'" v-model="filterEventCategory" :options="eventCategories" :dark-mode="darkMode" :selected="filterEventCategory"
                          :key="filterEventCategory"
                          @change="handleCategoryChange"
                />
            </div>
        </div>
        <div class="grid grid-cols-1 xl:grid-cols-2 gap-3 flex items-center px-5 mb-5">
            <div class="relative">
                <input class="w-full border rounded pl-8  focus:outline-none focus:border focus:border-primary-500 pr-4 py-2" placeholder="Search event" type="text" v-model="filterEventName"
                       :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}"/>
                <div class="absolute top-0 left-0 w-8 h-full flex justify-center items-center">
                    <svg class="inset-y-0" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.66667 2C4.69421 2 3.76158 2.38631 3.07394 3.07394C2.38631 3.76158 2 4.69421 2 5.66667C2 6.14818 2.09484 6.62498 2.27911 7.06984C2.46338 7.5147 2.73346 7.91891 3.07394 8.25939C3.41442 8.59987 3.81863 8.86996 4.26349 9.05423C4.70835 9.23849 5.18515 9.33333 5.66667 9.33333C6.14818 9.33333 6.62498 9.23849 7.06984 9.05423C7.5147 8.86996 7.91891 8.59987 8.25939 8.25939C8.59987 7.91891 8.86996 7.5147 9.05423 7.06984C9.23849 6.62498 9.33333 6.14818 9.33333 5.66667C9.33333 4.69421 8.94703 3.76158 8.25939 3.07394C7.57176 2.38631 6.63913 2 5.66667 2ZM1.65973 1.65973C2.72243 0.597022 4.16377 0 5.66667 0C7.16956 0 8.6109 0.597022 9.6736 1.65973C10.7363 2.72243 11.3333 4.16377 11.3333 5.66667C11.3333 6.41082 11.1868 7.14769 10.902 7.83521C10.7458 8.21219 10.5498 8.57029 10.3178 8.90361L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L8.90361 10.3178C8.57029 10.5498 8.21219 10.7458 7.83521 10.902C7.14769 11.1868 6.41082 11.3333 5.66667 11.3333C4.92251 11.3333 4.18564 11.1868 3.49813 10.902C2.81062 10.6172 2.18593 10.1998 1.65973 9.6736C1.13353 9.14741 0.716126 8.52272 0.431349 7.83521C0.146573 7.1477 0 6.41082 0 5.66667C0 4.16377 0.597022 2.72243 1.65973 1.65973Z" fill="#C5C5C5"/>
                    </svg>
                </div>
            </div>
            <div class="flex items-center">
                <button class="transition duration-200 bg-primary-500 font-semibold hover:bg-blue-500 text-white text-sm h-9 justify-center flex items-center font-medium focus:outline-none py-2 rounded-md px-5" @click="searchEvents">
                    <svg class="inset-y-0 w-4 my-1 fill-current" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.66667 2C4.69421 2 3.76158 2.38631 3.07394 3.07394C2.38631 3.76158 2 4.69421 2 5.66667C2 6.14818 2.09484 6.62498 2.27911 7.06984C2.46338 7.5147 2.73346 7.91891 3.07394 8.25939C3.41442 8.59987 3.81863 8.86996 4.26349 9.05423C4.70835 9.23849 5.18515 9.33333 5.66667 9.33333C6.14818 9.33333 6.62498 9.23849 7.06984 9.05423C7.5147 8.86996 7.91891 8.59987 8.25939 8.25939C8.59987 7.91891 8.86996 7.5147 9.05423 7.06984C9.23849 6.62498 9.33333 6.14818 9.33333 5.66667C9.33333 4.69421 8.94703 3.76158 8.25939 3.07394C7.57176 2.38631 6.63913 2 5.66667 2ZM1.65973 1.65973C2.72243 0.597022 4.16377 0 5.66667 0C7.16956 0 8.6109 0.597022 9.6736 1.65973C10.7363 2.72243 11.3333 4.16377 11.3333 5.66667C11.3333 6.41082 11.1868 7.14769 10.902 7.83521C10.7458 8.21219 10.5498 8.57029 10.3178 8.90361L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L8.90361 10.3178C8.57029 10.5498 8.21219 10.7458 7.83521 10.902C7.14769 11.1868 6.41082 11.3333 5.66667 11.3333C4.92251 11.3333 4.18564 11.1868 3.49813 10.902C2.81062 10.6172 2.18593 10.1998 1.65973 9.6736C1.13353 9.14741 0.716126 8.52272 0.431349 7.83521C0.146573 7.1477 0 6.41082 0 5.66667C0 4.16377 0.597022 2.72243 1.65973 1.65973Z"/>
                    </svg>
                </button>
                <button class="transition duration-200 ml-3 h-9 text-sm font-semibold focus:outline-none py-2 rounded-md px-4"
                        :class="{'bg-grey-250 hover:bg-light-background text-white': !darkMode, 'bg-grey-500 hover:bg-grey-600 text-white': darkMode}">
                    Reset
                </button>
            </div>
        </div>
        <div class="grid grid-cols-1 gap-x-3 mb-2 px-5">
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs">Event Name</p>
        </div>
        <div class="border-t border-b h-100 overflow-y-auto"
             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
            <div class="h-full flex items-center justify-center" v-if="loadingEvents">
                <loading-spinner></loading-spinner>
            </div>
            <div v-else>
                <div class="grid grid-cols-1 gap-x-3 border-b px-5"
                     v-for="event in events" :key="event.id"
                     :class="[darkMode ? 'text-slate-100 hover:bg-dark-module border-dark-border' : 'text-slate-900 hover:bg-light-module border-light-border']">
                    <p @click="activateEventTree(event)"
                        class="text-sm truncate flex items-center justify-between py-4 cursor-pointer"
                        :class="[ event.id === activeEvent ? 'text-primary-400' : '' ]"
                    >
                        {{ event.display_name }}
                        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z" fill="#0081FF"/>
                        </svg>
                    </p>
                </div>
            </div>
        </div>
        <div class="p-3"></div>
        <Modal :small="true" v-if="showWorkflowAddForm" :confirm-text="confirmText" :close-text="'Cancel'" :dark-mode="darkMode" @close="showWorkflowAddForm = false" @confirm="saveWorkflow">
            <template v-slot:header>
                <h4 class="text-xl font-medium">Add Event</h4>
            </template>
            <template v-slot:content>
                <div class="rounded-lg bg-red-100 p-4 mb-8" v-if="savingError">
                    <div class="text-sm text-red-700">
                        {{ savingError }}
                    </div>
                </div>
                <div class="grid grid-cols-4 md:grid-cols-5 gap-4 mb-4">
                    <p class="text-right font-medium flex items-center justify-end">Event Category:</p>
                    <div class="col-span-3">
                        <Dropdown :placeholder="'Event Category'" v-model="addEventCategory" :options="eventCategories" :dark-mode="darkMode" :selected="addEventCategory"
                                  :key="addEventCategory"></Dropdown>
                    </div>
                </div>
                <div>
                    <div class="grid grid-cols-4 md:grid-cols-5 gap-4">
                        <p class="text-right font-medium flex items-center justify-end">Event Name:</p>
                        <div class="col-span-3">
                            <Dropdown :placeholder="'Event Name'" v-model="addEventName" :options="eventNames" :dark-mode="darkMode" :selected="addEventName"
                                      :key="addEventName"></Dropdown>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import ApiService from "../services/api";
import Dropdown from "../../Shared/components/Dropdown";
import Modal from "../../Shared/components/Modal";
import LoadingSpinner from "../../Shared/components/LoadingSpinner";
import { nextTick } from "vue";

export default {
    name: "EventsControlPanel",
    components: {
        Dropdown, Modal, LoadingSpinner
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            filterEventCategory: null,
            filterEventName: null,
            addEventCategory: null,
            addEventName: null,
            eventCategories: [],
            showWorkflowAddForm: false,
            api: ApiService.make(),
            events: [],
            groupedEvents: {},
            loadingEvents: false,
            savingEvent: false,
            confirmText: 'Save',
            savingError: null,
            activeEvent: null,
        }
    },
    mounted() {
        this.api.getEventCategories().then(resp => {
            this.groupedEvents = resp.data.data;
            this.eventCategories = Object.keys(this.groupedEvents);
            this.addEventCategory = this.filterEventCategory = this.eventCategories[0];

            if (this.filterEventCategory) {
                this.searchEvents();
            }
        });
    },
    computed: {
        eventNames: function () {
            return this.groupedEvents[this.addEventCategory];
        }
    },
    watch: {
        eventNames: function () {
            this.addEventName = this.eventNames[0];
        }
    },
    methods: {
        activateEventTree(event) {
            this.activeEvent = event.id === this.activeEvent
                ? null
                : event.id;
            this.$emit('activate-event-tree', event);
        },
        saveWorkflow()
        {
            if (this.savingEvent) {
                return;
            }

            this.confirmText = 'Saving...';
            this.savingEvent = true;
            this.savingError = null;

            this.api.createWorkflowEvent(this.addEventCategory, this.addEventName).then(() => {
                this.filterEventCategory = this.addEventCategory;
                this.filterEventName = null;
                this.showWorkflowAddForm = false;
                this.savingError = null;

                this.searchEvents();
            }).catch(e => {
                this.savingError = e.response.data.message;
            }).finally(() => {
                this.savingEvent = false;
                this.confirmText = 'Save'
            });
        },
        searchEvents()
        {
            this.events = [];
            this.loadingEvents = true;
            this.api.getEvents(this.filterEventCategory, this.filterEventName).then(resp => {
                this.events = resp.data.data;
                this.events.map(event => {
                    event.category_name = this.filterEventCategory
                });
            }).catch(e => console.log(e)).finally(() => this.loadingEvents = false);
        },
        async handleCategoryChange() {
            await nextTick();
            this.addEventCategory = this.filterEventCategory
            this.searchEvents();
        }
    }

}
</script>

<style scoped>

</style>
