import axios from 'axios';

export default class ApiService {
    constructor(baseUrl, baseEndpoint, version) {
        this.baseEndpoint = baseEndpoint;
        this.baseUrl = baseUrl;
        this.version = version;
    }

    axios() {
        const axiosConfig = {
            baseURL: `/${this.baseUrl}/v${this.version}/${this.baseEndpoint}`
        }

        return axios.create(axiosConfig);
    }

    static make() {
        return new ApiService('internal-api', 'products', 1);
    }

    getProducts() {
        return this.axios().get('/');
    }

    createProduct(data) {
        return this.axios().post('/', data);
    }

    updateUpdate(id, data) {
        return this.axios().patch(`/${id}`, data);
    }

    deleteProduct(id) {
        return this.axios().delete(`/${id}`);
    }

    getServices(productId) {
        return this.axios().get(`/${productId}/services`)
    }

    addService(productId, serviceId) {
        return this.axios().post(`/${productId}/services/${serviceId}`)
    }

    deleteService(productId, serviceId) {
        return this.axios().delete(`/${productId}/services/${serviceId}`)
    }
}
