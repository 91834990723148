<!-- This Component may be unnecessary,
    but will leave it here in case some work needs to be done for displaying mixed content -->
<template>
    <p ref="commentContainer" v-html="cleanComment"></p>
</template>

<script>
import { defineComponent, createApp } from "vue";
import AudioPlayer from "../../../../Shared/components/AudioPlayer";

export default {
    name: "CommentContent",
    props: {
        content: {
            type: String,
            default: ''
        }
    },
    computed: {
        cleanComment() { return this.$filters.scrubHtml(this.content); }
    },
    mounted() {
        this.replaceAudioElements();
    },
    methods: {
        replaceAudioElements() {
            const audioElements = Array.from(this.$refs.commentContainer.querySelectorAll('audio'));
            audioElements.forEach(audioElement => {
                const parent = audioElement.parentElement;
                const src = audioElement.src;
                const newAudioPlayer = defineComponent(AudioPlayer);
                audioElement.remove();
                createApp(newAudioPlayer, { audioFile: src }).mount(parent);
            });
        }
    },
}
</script>
