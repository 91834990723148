<template>
    <div class="border rounded-lg overflow-hidden"
         :class="[darkMode ? 'bg-dark-module border-dark-border' : 'bg-light-module border-light-border']">
        <div class="border-b" :class="[darkMode ? 'border-dark-border' : 'border-light-border']">
            <Tab
                :dark-mode="darkMode"
                :tabs="tabs"
                :total="totalActivityItems"
                @selected="selectTab"
                :tabs-classes="'w-full'"
            />
            <div v-for="tab in tabs">
                <activity-tab
                    :key="tab.name"
                    :dark-mode="darkMode"
                    v-if="selectedTab === tab.name"
                    :company-id="companyId"
                    :activity-type="tab.itemType"
                >
                    <template v-slot:extra-filters>
                        <slot name="extra-filters"></slot>
                    </template>
                </activity-tab>
            </div>
        </div>
        <div class="p-3"></div>
    </div>
</template>

<script>
import Tab from "../../Shared/components/Tab";
import ActivityTab from "./activity/ActivityTab";
import {mapActions, mapState, mapWritableState} from "pinia";
import {useActivityPageStore} from "../../../../stores/activity-page.store";
import {useActivityTabStore} from "../../../../stores/activity-tab.store";

export default {
    name: "ActivityPage",
    components: {
        ActivityTab,
        Tab,
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        companyId: {
            type: Number,
            default: null
        }
    },
    created() {
        if (this.companyId) {
            if (!(this.activityTabFilters instanceof Object)) {
                console.warn(`ActivityPage.vue: On create, this.activityTabFilters was found to not be an object.`);
            }

            if (_.isEmpty(this.activityTabFilters)) {
                console.warn(`ActivityPage.vue: On create, this.activityTabFilters was found to be an empty object.`);
            }

            this.getActivitiesOverview(this.companyId, this.activityTabFilters);
        }
    },
    watch: {
        companyId(newVal, oldVal) {
            if (newVal !== oldVal) {
                if (!(this.activityTabFilters instanceof Object)) {
                    console.warn(`ActivityPage.vue: Upon changing "companyId", this.activityTabFilters was found to not be an object.`);
                }

                if (_.isEmpty(this.activityTabFilters)) {
                    console.warn(`ActivityPage.vue: Upon changing "companyId", this.activityTabFilters was found to be an empty object.`);
                }

                this.getActivitiesOverview(this.companyId, this.activityTabFilters);
            }
        },
    },
    methods: {
        selectTab(value) {
            const options = ['All', 'Action', 'Call', 'Text', 'Task'];

            if (!options.includes(value)) {
                throw new Error(`Must be one of the following: ${JSON.stringify(options)}. ${JSON.stringify(value)} passed.`);
            }

            const tab = this.tabs.filter(x => x.name === value)[0];

            if (!tab) {
                throw new Error(`Could not find value for tab in ${JSON.stringify(this.tabs)}.`);
            }

            if (!tab.hasOwnProperty('itemType')) {
                throw new Error(`${JSON.stringify(tab)} does not have "itemType" property.`);
            }

            const itemTypeOptions = [null, 'action', 'call', 'text', 'task'];

            if (!itemTypeOptions.includes(tab.itemType)) {
                throw new Error(`"tab.itemType" must be one of the following: ${JSON.stringify(itemTypeOptions)}. ${tab.itemType} passed.`)
            }

            this.tabActivityType = tab.itemType;

            useActivityPageStore().selectTab(value);
        },
        ...mapActions(useActivityPageStore,
            /**
             * @type Record<string, "getActivitiesOverview">
             */
            [
                'getActivitiesOverview',
            ]
        ),
    },
    computed: {
        ...mapWritableState(useActivityPageStore,
            [
                'selectedTab',
            ]
        ),
        ...mapState(useActivityPageStore, [
            'totalActivityItems',
            'tabs'
        ]),
        ...mapState(useActivityTabStore, {
            activityTabFilters: 'filters'
        }),
        ...mapWritableState(useActivityTabStore, {
            tabActivityType: 'activityType'
        })
    }
}
</script>

<style scoped>

</style>
