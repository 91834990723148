<template>
    <div class="h-120 p-5 rounded">
        <alerts-container v-if="alertActive" :alert-type="alertType" :text="alertText" :dark-mode="darkMode"/>
        <div class="flex items-center justify-between mb-3">
            <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Selected Categories</h5>
        </div>
        <div class="flex flex-row justify-between pb-5">
            <div class="grid grid-cols-4 gap-5 w-3/4">
                <div class="flex flex-col justify-center">
                    <p>Type</p>
                    <dropdown :dark-mode="darkMode" :options="dropdownOptions.type" :placeholder="'Choose a type'" v-model="type"></dropdown>
                </div>
                <div class="flex flex-col justify-center" :class="(type?.length > 0 ? 'visible' : 'invisible')">
                    <p>Scope</p>
                    <dropdown :dark-mode="darkMode" :options="dropdownOptions.category" :placeholder="'Choose a Scope'" v-model="category"></dropdown>
                </div>
                <div class="flex flex-col justify-center" :class="(category?.length > 0 && category !== 'global' ? 'visible' : 'invisible')">
                    <p>{{ unslugify(category, '_') }}</p>
                    <dropdown :dark-mode="darkMode" :options="dropdownOptions.categoryIds" :placeholder="'choose a category'" v-model="categoryId"></dropdown>
                </div>
                <div class="flex flex-col justify-end">
                    <button class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5 whitespace-nowrap"
                        @click="getFields">
                        Select
                    </button>
                </div>
            </div>

            <div v-if="this.fieldCols.length > 0" class="flex flex-row items-end justify-end">
                <button class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5 whitespace-nowrap mr-5"
                    @click="addField">
                    + New Field
                </button>
                <button class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5 whitespace-nowrap"
                    @click="saveFields">
                    Save
                </button>
            </div>
        </div>
        <hr :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}" />
        <div v-if="currentFieldsLabel" class="w-full my-5">
            Currently viewing {{ currentFieldsLabel }} fields
        </div>

        <div v-if="loading || saving" class="flex flex-col h-80 items-center justify-center w-full">
            <loading-spinner :label="saving ? 'Saving' : 'Loading'" />
        </div>
        <div v-else-if="fields?.length > 0">
            <div class="h-88 overflow-y-auto">
                <div class="grid gap-5 w-full text-grey-400"
                     :class="`grid-cols-${ Math.min(12, numberOfColumns) }`">
                    <div class="flex flex-col" v-for="col in activeColumnOrder">
                        {{ unslugify(col, '_') }}
                    </div>
                </div>
                <field v-for="(field, idx) in fields"
                       :key="idx"
                       v-model="fields[idx]"
                       :active-column-order="activeColumnOrder"
                       :number-of-columns="numberOfColumns"
                       :field-types="fieldTypes"
                       :company-field-categories="companyFieldCategories"
                       :dark-mode="darkMode"
                       @delete="deleteField(idx)" />
            </div>
        </div>
        <div v-else-if="currentFieldsLabel.length > 0" class="flex flex-col h-80 items-center justify-center text-2xl w-full">
            No {{ currentFieldsLabel }} fields
        </div>
    </div>
</template>

<script>
    import ApiService from './services/api';
    import SharedApiService from "../../Shared/services/api";
    import AlertsMixin from '../../../mixins/alerts-mixin';
    import AlertsContainer from "../../Shared/components/AlertsContainer";
    import LoadingSpinner from "../../Shared/components/LoadingSpinner";
    import Field from "./components/Field";
    import { unslugify } from "../../Shared/services/strings";
    import { determineInputType } from "./services/determineInputType";
    import Dropdown from "../../Shared/components/Dropdown";

    export default {
        name: "ConfigurableFields",
        props: {
            darkMode: {
                type: Boolean,
                default: false
            }
        },
        components: {
            Dropdown,
            Field,
            LoadingSpinner,
            AlertsContainer
        },
        mixins: [
            AlertsMixin
        ],
        created: function() {
            this.api.getTypeCategories().then(res => {
                if(res.data.data.status === true) {
                    this.typeCategories = res.data.data.type_categories;
                    this.dropdownOptions.type = Object.keys(this.typeCategories).map(key => ({ id: key, name: unslugify(key, '_') }));
                }
                else {
                    this.showAlert('error', 'Problem loading type categories');
                }
            }).catch(() => {
                this.showAlert('error', 'Error loading type categories');
            });

            this.api.getFieldTypes().then(res => {
                if(res.data.data.status === true) {
                    this.fieldTypes = res.data.data.field_types;
                }
                else {
                    this.showAlert('error', 'Problem loading field types');
                }
            }).catch(() => {
                this.showAlert('error', 'Error loading field types');
            });

            this.getCompanyFieldCategory();
        },
        data: function() {
            return {
                api: ApiService.make(),
                sharedApi: SharedApiService.make(),
                category: '',
                type: '',
                typeCategories: {},
                categoryId: '',
                categoryIds: {},
                fields: [],
                fieldCols: [],
                activeColumnOrder: [],
                fieldTypes: {},
                currentFieldsLabel: '',
                currentFieldsCategory: '',
                currentFieldsCategoryId: null,
                currentFieldsType: '',
                unslugify: unslugify,
                determineInputType: determineInputType,
                loading: false,
                saving: false,
                companyFieldCategories: [],
                numberOfColumns: 7,
                dropdownOptions: {
                    type: [],
                    category: [],
                    categoryIds: [],
                },
            };
        },
        computed: {
        },
        methods: {
            getFields() {
                if(!this.type
                || !this.category
                || (this.category !== 'global' && !this.categoryId)) {
                    this.showAlert('warning', 'Missing search parameters');
                    return;
                }

                this.loading = true;
                this.fields = {};
                this.setCurrentFields();

                this.api.list(this.type, this.category, this.currentFieldsCategoryId).then(res => {
                    if(res.data.data.status === true) {
                        this.fields = res.data.data.fields;
                        this.fieldCols = res.data.data.field_columns;
                        this.createColumnOrder();
                    }
                    else {
                        this.showAlert('error', 'Problem loading fields');
                    }
                }).catch(() => {
                    this.showAlert('error', 'Error loading fields');
                }).finally(() => {
                    this.loading = false;
                })
            },
            createColumnOrder() {
                const columnArray = [],
                    primaryKeys = [ 'name', 'key', 'type', 'category', 'payload', 'show_on_dashboard', 'show_on_profile' ],
                    hideKeys = [ 'id', 'created_at', 'modified_at' ];
                columnArray.push(...primaryKeys.filter(key => this.fieldCols.includes(key), ...primaryKeys.filter(key => ![ ...primaryKeys, ...hideKeys ].includes(key))));
                this.activeColumnOrder = columnArray.filter(v=>v);
                this.numberOfColumns = this.activeColumnOrder.length + 1;
            },
            addField() {
                let newField = {};
                let inputType;
                this.fieldCols.forEach((col) => {
                    inputType = this.determineInputType(col);

                    if(inputType === 'json') {
                        newField[col] = {};
                    }
                    else if(inputType === 'id') {
                        if(['industry_id', 'industry_service_id'].includes(col)) {
                            newField[col] = this.currentFieldsCategoryId;
                        }
                        else {
                            newField[col] = 0;
                        }
                    }
                    else if(inputType === 'boolean') {
                        newField[col] = false;
                    }
                    else {
                        newField[col] = '';
                    }
                });

                this.fields.push(newField);
            },
            deleteField(idx) {
                this.fields.splice(idx, 1);
            },
            saveFields() {
                this.saving = true;
                this.api.save(this.fields, this.currentFieldsType, this.currentFieldsCategory, this.currentFieldsCategoryId).then(res => {
                    if(res.data.data.status === true) {
                        this.showAlert('success', 'Fields saved');
                        this.getFields();
                    }
                    else {
                        this.showAlert('error', 'Problem saving fields');
                    }
                }).catch(err => {
                    let errorMsg = 'Error saving fields';
                    if(err.response.status === 422) {
                        const errMsgs = new Set();
                        Object.values(err.response.data.errors || {}).forEach((messages) => {
                            errMsgs.add(messages[0]);
                        });

                        errorMsg = Array.from(errMsgs).join('. ');
                    }

                    this.showAlert('error', errorMsg);
                }).finally(() => {
                    this.saving = false;
                });
            },
            getCategoryIds() {
                if(this.category === 'industry') {
                    this.sharedApi.getIndustries().then(res => {
                        if(res.data.data.status === true) {
                            this.categoryIds = res.data.data.industries;
                        }
                        else {
                            this.showAlert('error', 'Problem loading industries');
                        }
                    }).catch(() => {
                        this.showAlert('error', 'Error loading industries');
                    });
                }
                else if(this.category === 'service') {
                    this.sharedApi.getIndustryServices().then(res => {
                        if(res.data.data.status === true) {
                            this.categoryIds = res.data.data.industry_services;
                        }
                        else {
                            this.showAlert('error', 'Problem loading services');
                        }
                    }).catch(() => {
                        this.showAlert('error', 'Error loading services');
                    });
                }
                else if(this.category === 'global_type') {
                    this.sharedApi.getGlobalTypes().then(res => {
                        if(res.data.data.status === true) {
                            this.categoryIds = res.data.data.global_types;
                        }
                        else {
                            this.showAlert('error', 'Problem loading global types');
                        }
                    }).catch(() => {
                        this.showAlert('error', 'Error loading global types');
                    });
                }
            },
            setCurrentFields() {
                this.currentFieldsCategory = this.category;
                if(this.categoryId) {
                    let index = this.categoryIds.findIndex(cat => cat.id === this.categoryId);
                    this.currentFieldsCategoryId = this.categoryIds[index].num_id;
                }
                this.currentFieldsType = this.type;
                let typeLabel;
                if(this.currentFieldsCategory === 'global') {
                    typeLabel = this.currentFieldsCategory.slice(0, 1).toUpperCase() + this.currentFieldsCategory.slice(1);
                }
                else {
                    const selectedCid = this.categoryIds.find((cid) => {
                        return cid.num_id === this.currentFieldsCategoryId;
                    });

                    typeLabel = selectedCid.name + (selectedCid.industry ? " (" + selectedCid.industry + ") " : " ") + this.unslugify(this.currentFieldsCategory);
                }

                this.currentFieldsLabel = typeLabel + " " + this.unslugify(this.currentFieldsType, '_')
            },

            getCompanyFieldCategory()
            {
                this.api.getCompanyFieldCategories()
                    .then(resp => this.companyFieldCategories = resp.data.data.categories)
                    .catch(() => this.showAlert('error', 'Problem loading company configurable field categories'));
            }
        },
        watch: {
            category(newCategory) {
                if(newCategory !== 'global') {
                    this.categoryId = null;
                    this.getCategoryIds();
                }
            },
            type(newType) {
                this.category = '';
                this.dropdownOptions.category = this.typeCategories[newType].map(key => ({ id: key, name: unslugify(key, '_') }));
            },
            categoryIds(newIds) {
                if (!newIds) return;
                this.dropdownOptions.categoryIds = Object.values(newIds).map(category => ({ id: category.id, name: category.name }));
            }
        }
    }
</script>
