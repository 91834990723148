<template>
    <div class="px-5 pt-5 pb-4">
        <div class="flex items-center justify-between">
            <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">
                Contacts</h5>
            <button v-if="showAddContactButton"
                    class="ml-2 transition duration-200 inline-flex items-center font-semibold bg-grey-475 hover:bg-grey-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5"
                    @click="showContactAddModal">
                Add Contact
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContactsHeader",
    emits: ['show:modal'],
    props: {
        showAddContactButton: {
            required: false,
            default: true
        },
    },
    methods: {
        showContactAddModal() {
            this.$emit('show:modal')
        }
    }
}
</script>

<style scoped>

</style>
