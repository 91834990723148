import axios from 'axios';

export default class ApiService {
    constructor(baseUrl, baseEndpoint, version) {
        this.baseEndpoint = baseEndpoint;
        this.baseUrl      = baseUrl;
        this.version      = version;
    }

    axios() {
        const axiosConfig = {
            baseURL: `/${this.baseUrl}/v${this.version}/${this.baseEndpoint}`
        }

        return axios.create(axiosConfig);
    }

    /**
     * @returns {ApiService}
     */
    static make() {
        return new ApiService('internal-api', 'industries', 1);
    }

    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    getIndustries() {
        return this.axios().get('/');
    }

    /**
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    createIndustry(data) {
        return this.axios().post('/', data);
    }

    /**
     * @param id
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateIndustry(id, data) {
        return this.axios().patch(`/${id}`, data);
    }

    /**
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteIndustry(id) {
        return this.axios().delete(`/${id}`);
    }

    /**
     * @param industry
     * @returns {Promise<AxiosResponse<any>>}
     */
    getIndustryWebsites(industry) {
        return this.axios().get(`/${industry}/websites`);
    }

    /**
     * @param industry
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    createIndustryWebsite(industry, data) {
        return this.axios().post(`/${industry}/websites`, data);
    }

    /**
     * @param industryWebsite
     * @param industry
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateIndustryWebsite(industryWebsite, industry, data) {
        return this.axios().patch(`/${industry}/websites/${industryWebsite}`, data);
    }

    /**
     * @param industryWebsite
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteIndustryWebsite(industryWebsite) {
        return this.axios().delete(`/website/${industryWebsite}`);
    }

    /**
     * @param industry
     * @returns {Promise<AxiosResponse<any>>}
     */
    getNonAddedWebsites(industry) {
        return this.axios().get(`/${industry}/websites/non-added`);
    }

    /**
     * @param industry
     * @returns {Promise<AxiosResponse<any>>}
     */
    getIndustryServices(industry) {
        return this.axios().get(`/${industry}/services`);
    }

    /**
     * @param industry
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    createIndustryServices(industry, data) {
        return this.axios().post(`/${industry}/services`, data);
    }

    /**
     * @param industryService
     * @param industry
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateIndustryService(industryService, industry, data) {
        return this.axios().patch(`/${industry}/services/${industryService}`, data);
    }

    /**
     * @param industryService
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteIndustryService(industryService) {
        return this.axios().delete(`/service/${industryService}`);
    }

    /**
     * @param industry
     * @returns {Promise<AxiosResponse<any>>}
     */
    getIndustryCompanyFields(industry) {
        return this.axios().get(`/${industry}/company-fields`);
    }

    /**
     * @param industry
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    createIndustryCompanyField(industry, data) {
        return this.axios().post(`/${industry}/company-fields`, data);
    }

    /**
     * @param industryCompanyField
     * @param industry
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateIndustryCompanyField(industryCompanyField, industry, data) {
        return this.axios().patch(`/${industry}/company-fields/${industryCompanyField}`, data);
    }

    /**
     * @param industryCompanyField
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteIndustryCompanyField(industryCompanyField) {
        return this.axios().delete(`/company-field/${industryCompanyField}`);
    }

    /**
     * @param industry
     * @returns {Promise<AxiosResponse<any>>}
     */
    getIndustryConsumerFields(industry) {
        return this.axios().get(`/${industry}/consumer-fields`);
    }

    /**
     * @param industry
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    createIndustryConsumerField(industry, data) {
        return this.axios().post(`/${industry}/consumer-fields`, data);
    }

    /**
     * @param industryConsumerField
     * @param industry
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateIndustryConsumerField(industryConsumerField, industry, data) {
        return this.axios().patch(`/${industry}/consumer-fields/${industryConsumerField}`, data);
    }

    /**
     * @param industryConsumerField
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteIndustryConsumerField(industryConsumerField) {
        return this.axios().delete(`/consumer-field/${industryConsumerField}`);
    }

    /**
     * @param industry
     * @returns {Promise<AxiosResponse<any>>}
     */
    getIndustryTypes(industry) {
        return this.axios().get(`/${industry}/types`);
    }

    /**
     * @param industry
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    createIndustryType(industry, data) {
        return this.axios().post(`/${industry}/types`, data);
    }

    /**
     * @param industryType
     * @param industry
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateIndustryType(industryType, industry, data) {
        return this.axios().patch(`/${industry}/types/${industryType}`, data);
    }

    /**
     * @param industryType
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteIndustryType(industryType) {
        return this.axios().delete(`/type/${industryType}`);
    }

    /**
     * @param industryType
     * @returns {Promise<AxiosResponse<any>>}
     */
    getIndustryTypeReviews(industryType) {
        return this.axios().get(`/type/${industryType}/reviews`);
    }

    /**
     * @param industryType
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    createIndustryTypeReview(industryType, data) {
        return this.axios().post(`/type/${industryType}/reviews`, data);
    }

    /**
     * @param review
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteIndustryTypeReview(review) {
        return this.axios().delete(`/review/${review}`);
    }

    /**
     * @param industry
     * @returns {Promise<AxiosResponse<any>>}
     */
    getIndustryCompanies(industry) {
        return this.axios().get(`/${industry}/companies`);
    }

    /**
     * @param industry
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    addIndustryCompany(industry, data) {
        return this.axios().post(`/${industry}/companies`, data);
    }

    /**
     * @param industryCompany
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteIndustryCompany(industryCompany) {
        return this.axios().delete(`/company/${industryCompany}`);
    }

    /**
     * @param industry
     * @returns {Promise<AxiosResponse<any>>}
     */
    getNonAddedCompanies(industry) {
        return this.axios().get(`/${industry}/companies/non-added`);
    }
}
