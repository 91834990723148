export const unslugify = (str, delimiter = '-') => {
    let slugParts = str.replace(/\s+/g, '').split(delimiter).filter(e => e);

    if(!slugParts.length) {
        return '';
    }
    else if(slugParts.length === 1) {
        return slugParts[0][0].toUpperCase() + slugParts[0].slice(1);
    }
    else {
        return slugParts.reduce((prev, next) => {
            return prev[0].toUpperCase() + prev.slice(1) + ' ' + next[0].toUpperCase() + next.slice(1);
        })
    }
};

export const slugify = (str, delimiter = '-') => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    return str.replace(new RegExp(`[^a-z0-9 ${delimiter}]`, 'g'), '')
            .replace(/\s+/g, delimiter)
            .replace(new RegExp(`${delimiter}+`, 'g'), delimiter);
};
