<template>
    <!-- Pre-Raised Leads -->
    <div id="pre-raised-leads" class="row-span-3 border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="pb-6">
            <h5 class="pt-5 px-5 pb-3 text-primary-500 text-sm uppercase font-semibold pb-4 leading-tight">Pre-Raised Leads</h5>
            <div class="grid grid-cols-11 gap-2 mb-2 px-5 ml-1">
                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs">Id</p>
                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Date</p>
                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Status</p>
                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-3">Name</p>
                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-3">Source</p>
            </div>
            <loading-spinner v-if="loading" :dark-mode="darkMode" />
            <div v-else class="border-t border-l border-r h-auto max-h-48 overflow-y-auto"
                 :class="{'bg-light-background border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
                <div class="border-b" :class="{ 'border-light-border': !darkMode, 'border-dark-border': darkMode }" v-if="previousConsumerProducts.length > 0">
                    <div class="grid grid-cols-11 gap-2 items-center px-5 py-2 border-b" v-for="consumerProduct in previousConsumerProducts"
                         :class="{ 'text-grey-800 hover:bg-light-module border-light-border': !darkMode, 'text-grey-200 hover:bg-dark-module border-dark-border': darkMode }">
                        <p class="text-xs">
                            {{ consumerProduct.id }}
                        </p>
                        <p class="text-xs col-span-2">
                            {{ $filters.dateFromTimestamp(consumerProduct.created_at) }}
                        </p>
                        <p class="text-xs col-span-2">
                            {{ consumerProduct.status }}
                        </p>
                        <p class="text-xs col-span-3">
                            {{ consumerProduct.name }}
                        </p>
                        <p class="text-xs col-span-3">
                            {{ consumerProduct.source }}
                        </p>
                    </div>
                </div>
                <div v-else>
                    <div class="px-5 py-2 text-s border-b"
                         :class="{'text-grey-800 border-light-border': !darkMode, 'text-grey-200 border-dark-border': darkMode}">
                        No previous leads found for this Consumer
                    </div>
                </div>
            </div>
            <div v-if="paginationData" class="px-3 pt-5">
                <cursor-pagination :dark-mode="darkMode" :pagination-data="paginationData" @change-page="handlePaginationEvent"></cursor-pagination>
            </div>
            <alert :dark-mode="darkMode" v-if="alertActive" :alert-type="alertType" :text="alertText" />
        </div>
    </div>
</template>

<script>
import ConsumerApiService from "../../services/consumer_api";
import AlertsMixin from "../../../../mixins/alerts-mixin";
import Alert from "../../components/Alert";
import EditField from "../../components/EditField";
import LoadingSpinner from "../../components/LoadingSpinner";
import CursorPagination from "../../components/CursorPagination";

export default {
    name: "ConsumerProductPreviousLeads",
    components: {
        Alert,
        EditField,
        LoadingSpinner,
        CursorPagination,
    },
    props: {
        consumerProductId: {
            type: Number,
            default: null,
        },
        darkMode: {
            type: Boolean,
            default: false,
        }
    },
    mixins: [
        AlertsMixin
    ],
    data() {
        return {
            api: ConsumerApiService.make(),
            previousConsumerProducts: [],
            responseKey: 'previous_consumer_products',
            paginationData: null,
            loading: false,
            errorMessages: {
                get: `Error fetching leads from server.`,
            }
        }
    },
    methods: {
        async getPreviousConsumerProducts() {
            this.loading = true;
            this.api.getConsumerProductsByConsumerProductId(this.consumerProductId).then(resp => {
                if (resp.data.data.status) this.addPaginatedData(resp.data.data[this.responseKey]);
                else this.showAlert('error', this.errorMessages.get);
            }).catch(() => this.showAlert('error', this.errorMessages.get)
            ).finally(() => this.loading = false);
        },
        async handlePaginationEvent(newPageUrl) {
            this.loading = true;
            await axios.get(newPageUrl.link).then(resp => {
                if (resp.data.data.status) this.addPaginatedData(resp.data.data[this.responseKey]);
                else this.showAlert('error', this.errorMessages.get);
            }).catch(() => this.showAlert('error', this.errorMessages.get)
            ).finally(() => this.loading = false);
        },
        addPaginatedData({ data, ...paginationData }) {
            if (data && paginationData) {
                this.previousConsumerProducts = data;
                this.paginationData = paginationData;
            }
            else this.showAlert('error', this.errorMessages.get);
        }
    },
    created() {
        if (this.consumerProductId) this.getPreviousConsumerProducts();
    },
    watch: {
        consumerProductId(newVal, oldVal) {
            if (newVal !== oldVal) this.getPreviousConsumerProducts();
        }
    },
}
</script>

<style scoped>

</style>
