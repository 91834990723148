<template>
    <div class="mt-5">
        <div v-if="loading">
            <loading-spinner :dark-mode="darkMode"></loading-spinner>
        </div>
        <div v-if="!loading">
            <p class="uppercase font-semibold text-xs mb-1 mt-5"
               :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                Choose Template
            </p>
            <Dropdown v-model="template" :options="templates" :dark-mode="darkMode" placeholder="Select Template"></Dropdown>
        </div>
    </div>
</template>

<script>
import Dropdown from "../../../Shared/components/Dropdown";
import ApiService from "../../services/api";
import LoadingSpinner from "../../../Shared/components/LoadingSpinner";

export default {
    name: "RunTemplateNode",
    components: {Dropdown, LoadingSpinner},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        action: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            api: ApiService.make(),
            templates: [],
            template: null,
            loading: false,
        }
    },
    created() {
        if (this.action) {
            this.template = this.action.payload.template_id;
        }

        this.loadTemplates();
    },
    watch: {
        action: function () {
            if (this.action) {
                this.template = this.action.payload.template_id;
            } else {
                this.template = null;
            }

            this.loadTemplates();
        }
    },
    methods: {
        getDisplayName() {
            return this.getTemplateName();
        },
        getTemplateName() {
            const template = this.templates.find(item => item.id == this.template);

            return template?.name ? "Run " + template.name : "Run Template";
        },
        getPayload() {
            return {
                template_id: this.template
            }
        },
        loadTemplates() {
            this.loading = true;

            this.api.getWorkflows(0).then(resp => {
                this.templates = resp.data.data.workflows;
            }).finally(() => this.loading = false);
        }
    }
}
</script>

<style lang="scss">

</style>
