<template>
    <div>
        <div class="w-auto min-h-[16rem]">
            <header class="text-lg mb-4">
                <p class="text-primary-800 font-semibold text-xs px-3 py-1 rounded-full">{{ action.category?.name ?? '' }}</p>
                <p class=" text-sm italic text-gray-500 ml-8 mt-1 mb-4">
                    {{ pinnedText }}
                </p>
                <div v-if="expanded">
                    <Dropdown class="max-w-sm"
                        placeholder="Quick Actions"
                        @change="handleAction"
                        :options="activityActions"
                        :dark-mode="darkMode"
                    />
                </div>
            </header>
            <div class="w-full pb-2">
                <p class="font-semibold text-base" :class="[darkMode ? 'text-slate-100' : ' text-slate-900']">{{ action.subject }}</p>
            </div>
            <p class="ml-1 border-l border-slate-100 pl-4" v-html="cleanMessage" />
        </div>
    </div>
</template>

<script>
import Dropdown from "../../../../Shared/components/Dropdown";
import SharedApiService from "../../../../Shared/services/api";
import ActionNoteModal from "../../../../Shared/components/ActionNoteModal";

export default {
    name: "ActionItem",
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        itemData: {
            type: Object,
            default: {},
        },
        userData: {
            type: Object,
            default: {},
        },
        companyId: {
            type: Number,
            default: null,
        },
        expanded: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        ActionNoteModal,
        Dropdown
    },
    data() {
        return {
            action: {
                id: null,
                subject: '',
                message: '',
                category: '',
                relation_task_id: null,
                pinned: false,
            },
            user: {
                name: '',
                email: '',
            },
            activityActions: [
                // Example follow-up actions
                { id: 'emailUser', name: `Email ${this.userData.name || 'User'}` },
            ],
            api: SharedApiService.make(),
            showNoteModal: false
        }
    },
    emits: [
        "reload"
    ],
    computed: {
        cleanMessage() { return this.$filters.scrubHtml(this.action.message); },
        pinnedText() { return this.action.pinned ? '(Action pinned)' : ''; },
        pinnedLabel() { return this.action.pinned ? 'Unpin Action' : 'Pin Action' },
    },
    mounted() {
        for (const key in this.itemData) {
            this.action[key] = this.itemData[key]
        }
        for (const key in this.userData) {
            this.user[key] = this.userData[key]
        }
        this.setFollowUpActions();
    },
    methods: {
        handleAction({ id }) {
            if (typeof(this[id]) === 'function') this[id]();
            else alert(`Action triggered: ${id}, not yet implemented.`);
        },
        async toggleActionPin() {
            if (this.action.id && this.companyId) {
                this.api.toggleActionPin(this.action.id, this.companyId).then(response => {
                    if (response.data.data.status) {
                        this.action.pinned = 1 - this.action.pinned;
                        const pinAction = this.activityActions.find(action => action.id === 'toggleActionPin')
                        if (pinAction) pinAction.name = this.pinnedLabel;
                    }
                }).catch(() => {
                    console.warn('Error toggling action pin');
                });
            }
        },
        emailUser() {
            if (this.userData.email) window.open(`mailto:${this.userData.email}`, '_self');
        },
        reloadActions() {
            this.$emit('reload');
        },
        setFollowUpActions() {
            if (this.action.relation_task_id) {
                this.activityActions.push({ id: 'jumpToTask', name: 'View related task' });
            }
            this.activityActions.push({ id: 'toggleActionPin', name: this.pinnedLabel });
        },
    }
}
</script>

<style scoped>

</style>
