import CommunicatesMixin from "./communicates-mixin";
import {waitForCallbackToReturnTrue} from "../../../utilities/loading-utilities";
import {CommunicationApiFactory} from "../../../services/api/communication/factory";
import DispatchesGlobalEventsMixin from "../dispatches-global-events-mixin";
import {mapWritableState} from "pinia";
import {useCallStore} from "../../../stores/call.store";

export default {
    data() {
        return {
            minimizeCall: false,
            muted: false,
            onHold: false,
            contactComment: null,
            contactName: null,
            contactPhone: null,
            contactRelationType: null,
            contactRelationId: null,
            inbound: false,
            incoming: null,
            incomingId: null,
            incomingSid: null,
            incomingType: null,
            incomingName: null,
            incomingPhone: null,
            status: null,
            communicationApi: null,
            voicemails: [],
            recordings: []
        }
    },
    mixins: [CommunicatesMixin, DispatchesGlobalEventsMixin],
    created() {
        this.resetCall();
        this.communicationApi = CommunicationApiFactory.makeApiService(this.apiDriver);

        this.communicationApi.getVoicemails().then(resp => {
            this.voicemails = resp.data.data;
        });
    },
    mounted() {
        this.initializeToken();
    },
    methods: {
        resetCall() {
            this.minimizeCall = false;
            this.callActive = false;
            this.muted = false;
            this.onHold = false;
            this.contactComment = null;
            this.contactName = null;
            this.contactPhone = null;
            this.contactRelationType = null;
            this.contactRelationId = null;

            this.status = "Dialing"

            if(this.communicationService)
                this.communicationService.resetListeners();
        },

        initializeToken() {
            this.api.getCommunicationToken().then(async resp => {
                if (resp.data.data.status === true) {
                    await this.communicationService.initialize(resp.data.data.token, resp.data.data.number);
                    this.communicationService.onIncomingCall((call) => this.incomingCall(call));
                    this.communicationService.onIncomingEnd(() => this.incomingCall(null));
                }
            });
        },

        attemptCall({phone, name, comment, relType, relId}) {
            this.contactName = name;
            this.contactPhone = phone;
            this.contactComment = comment;
            this.contactRelationType = relType;
            this.contactRelationId = relId;
            this.callActive = true;
            this.inbound = false;

            this.communicationService.initializeCall(phone).then(resp => {
                this.callActive = true;
                waitForCallbackToReturnTrue( () => this.communicationService.getCallId() !== undefined, 500, 20).then(() => {
                    this.communicationApi.createOutboundCall(
                        this.communicationService.serviceName(),
                        this.communicationService.getUserPhone(),
                        this.contactPhone,
                        this.communicationService.getCallId()
                    );
                });

                this.communicationService.onAccept(() => this.status = "Ringing");
                this.communicationService.onHangup(() => {
                    this.handleHangup();
                });
            });
        },

        toggleMinimizeCall() {
            this.minimizeCall = !this.minimizeCall;
        },
        endCall() {
            if(this.inbound)
                this.handleHangup();

            this.communicationService.hangUp();
            this.resetCall();
        },
        toggleHold() {
            this.onHold = !this.onHold;
            this.communicationService.hold(this.onHold);
        },
        toggleMute() {
            this.muted = !this.muted;
            this.communicationService.mute(this.muted);
        },

        async lookupCaller(number) {
            const resp = await this.communicationApi.lookupCaller(number);

            return resp.data.data;
        },

        incomingCall(incomingCall) {
            this.incoming = incomingCall;
            console.log(incomingCall);

            if (incomingCall == null) {
                this.incomingId = null;
                this.incomingName = null;
                this.incomingPhone = null;
                this.incomingType = null;

                this.resetCall();
            } else {
                this.incomingPhone = incomingCall.from.replace("+1", "");
                this.incomingSid = incomingCall.id;

                this.communicationApi.lookupCaller(this.incomingPhone).then(resp => {
                    this.incomingName = resp.data.data.name;
                    this.incomingId = resp.data.data.relation_id;
                    this.incomingType = resp.data.data.relation_type;
                });

                this.communicationApi.createInboundCall(
                    this.communicationService.serviceName(),
                    this.communicationService.getUserPhone(),
                    this.incomingPhone,
                    this.incomingSid
                )

                this.communicationService.onMissed(() => this.missedCall());
            }
        },
        answerCall() {
            if(this.incomingType === "lead") {
                this.dispatchGlobalEvent('load-lead', {lead_id: this.incomingId});
            }

            this.resetCall();
            this.contactPhone = this.incomingPhone;
            this.contactName = this.incomingName;
            this.contactComment = this.incomingId;
            this.contactRelationType = this.incomingType;
            this.contactRelationId = this.incomingId;
            this.communicationService.accept();
            this.callActive = true;
            this.inbound = true;

            setTimeout(() => {
                this.status = "Answered";

                this.communicationService.onHangup(() => {
                    this.handleHangup();
                    console.log("HANGUP");
                });
            })
        },
        handleHangup() {
            this.status = "Ended"
            const method = this.inbound ? this.communicationApi.concludeInboundCall.bind(this.communicationApi) : this.communicationApi.concludeOutboundCall.bind(this.communicationApi)

            method(
                this.communicationService.serviceName(),
                this.communicationService.getUserPhone(),
                this.contactPhone,
                this.communicationService.getCallId()
            );

            setTimeout(() => this.resetCall(), 1500);
        },
        declineCall() {
            this.communicationApi.concludeInboundCall(
                this.communicationService.serviceName(),
                this.communicationService.getUserPhone(),
                this.incomingPhone,
                this.incomingSid,
                'busy'
            );

            this.communicationService.decline();
        },
        missedCall() {
            this.communicationApi.concludeInboundCall(
                this.communicationService.serviceName(),
                this.communicationService.getUserPhone(),
                this.incomingPhone,
                this.incomingSid,
                'missed'
            );
        },
        attemptSMS(contactPhone, body) {
            this.communicationApi.createOutboundSMS(contactPhone, body);
        },
        markVoicemailHeard(voiceMailId) {
            this.communicationApi.markVoicemailHeard(voiceMailId);
        },
        clearCallCallRecordings() {
            this.recordings = [];
        },
        getCallRecordingsForLeadId(leadId) {
            this.communicationApi.getCallRecordingsForLeadId(leadId).then( (resp) => {
                if(resp.status === 200){
                    this.recordings = resp.data.data;
                }
            } );
        },
        generateKeyTone(key) {
            if(this.communicationService.call){
                this.communicationService.call.sendDigits(key);
            }
        }
    },
    computed: {
        ...mapWritableState(useCallStore, [
            'callActive'
        ])
    }
}
