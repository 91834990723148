<template>
    <div class="grid md:grid-cols-2 gap-4 mt-5">
        <task-categories
            :dark-mode="darkMode"
            @edit-category="editCategory"
            ref="categories"
        ></task-categories>
        <editor
            :dark-mode="darkMode"
            :task-category="category"
            @category-updated="categoryUpdated"
            @canceled="canceled"
        ></editor>
    </div>
</template>

<script>
import TaskCategories from "./Components/TaskCategories";
import Editor from "./Components/Editor";

export default {
    name: "TaskCategoryEditor",
    components: {TaskCategories, Editor},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            category: {}
        };
    },
    methods: {
        editCategory(category) {
            if (this.category.id && this.category.id === category.id) category = {};

            this.category = category;
        },
        categoryUpdated() {
            this.resetCategoryEdit();
            this.$refs.categories.getTaskCategories();
        },
        canceled() {
            this.resetCategoryEdit();
        },
        resetCategoryEdit() {
            this.category = {};
        }
    }
}
</script>
