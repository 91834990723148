<template>
    <!-- Date Info -->
    <div id="date-info" class="row-span-2 border rounded-lg"
         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
        <div class="p-5">
            <h5 class="text-primary-500 text-sm uppercase font-semibold pb-4 leading-tight">Date Info</h5>
            <div class="flex items-center">
                <svg class="mr-2 flex-shrink-0" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 0C3.26794 0 3.04538 0.0921872 2.88128 0.256282C2.71719 0.420376 2.625 0.642936 2.625 0.875V1.75H1.75C1.28587 1.75 0.840752 1.93437 0.512563 2.26256C0.184374 2.59075 0 3.03587 0 3.5V12.25C0 12.7141 0.184374 13.1592 0.512563 13.4874C0.840752 13.8156 1.28587 14 1.75 14H12.25C12.7141 14 13.1592 13.8156 13.4874 13.4874C13.8156 13.1592 14 12.7141 14 12.25V3.5C14 3.03587 13.8156 2.59075 13.4874 2.26256C13.1592 1.93437 12.7141 1.75 12.25 1.75H11.375V0.875C11.375 0.642936 11.2828 0.420376 11.1187 0.256282C10.9546 0.0921872 10.7321 0 10.5 0C10.2679 0 10.0454 0.0921872 9.88128 0.256282C9.71719 0.420376 9.625 0.642936 9.625 0.875V1.75H4.375V0.875C4.375 0.642936 4.28281 0.420376 4.11872 0.256282C3.95462 0.0921872 3.73206 0 3.5 0V0ZM3.5 4.375C3.26794 4.375 3.04538 4.46719 2.88128 4.63128C2.71719 4.79538 2.625 5.01794 2.625 5.25C2.625 5.48206 2.71719 5.70462 2.88128 5.86872C3.04538 6.03281 3.26794 6.125 3.5 6.125H10.5C10.7321 6.125 10.9546 6.03281 11.1187 5.86872C11.2828 5.70462 11.375 5.48206 11.375 5.25C11.375 5.01794 11.2828 4.79538 11.1187 4.63128C10.9546 4.46719 10.7321 4.375 10.5 4.375H3.5Z" fill="#0081FF"/>
                </svg>
                <p class="pb-0">{{ getDateFormatted(consumerProduct.product_date_created) }}</p>
                <p class="text-sm ml-2"> (Added) </p>
            </div>
            <div class="flex items-center">
                <svg class="mr-2 flex-shrink-0" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 0C3.26794 0 3.04538 0.0921872 2.88128 0.256282C2.71719 0.420376 2.625 0.642936 2.625 0.875V1.75H1.75C1.28587 1.75 0.840752 1.93437 0.512563 2.26256C0.184374 2.59075 0 3.03587 0 3.5V12.25C0 12.7141 0.184374 13.1592 0.512563 13.4874C0.840752 13.8156 1.28587 14 1.75 14H12.25C12.7141 14 13.1592 13.8156 13.4874 13.4874C13.8156 13.1592 14 12.7141 14 12.25V3.5C14 3.03587 13.8156 2.59075 13.4874 2.26256C13.1592 1.93437 12.7141 1.75 12.25 1.75H11.375V0.875C11.375 0.642936 11.2828 0.420376 11.1187 0.256282C10.9546 0.0921872 10.7321 0 10.5 0C10.2679 0 10.0454 0.0921872 9.88128 0.256282C9.71719 0.420376 9.625 0.642936 9.625 0.875V1.75H4.375V0.875C4.375 0.642936 4.28281 0.420376 4.11872 0.256282C3.95462 0.0921872 3.73206 0 3.5 0V0ZM3.5 4.375C3.26794 4.375 3.04538 4.46719 2.88128 4.63128C2.71719 4.79538 2.625 5.01794 2.625 5.25C2.625 5.48206 2.71719 5.70462 2.88128 5.86872C3.04538 6.03281 3.26794 6.125 3.5 6.125H10.5C10.7321 6.125 10.9546 6.03281 11.1187 5.86872C11.2828 5.70462 11.375 5.48206 11.375 5.25C11.375 5.01794 11.2828 4.79538 11.1187 4.63128C10.9546 4.46719 10.7321 4.375 10.5 4.375H3.5Z" fill="#0081FF"/>
                </svg>
                <p class="pb-0">{{ getDateFormatted(consumerProduct.product_date_updated) }}</p>
                <p class="text-sm ml-2"> (Last Updated) </p>
            </div>
        </div>
        <loading-spinner v-if="loading" :dark-mode="darkMode" />
        <alert :dark-mode="darkMode" v-if="alertActive" :alert-type="alertType" :text="alertText" />
    </div>
</template>

<script>
import ConsumerApiService from "../../services/consumer_api";
import AlertsMixin from "../../../../mixins/alerts-mixin";
import Alert from "../../components/Alert";
import LoadingSpinner from "../../components/LoadingSpinner";

export default {
    name: "ConsumerProductDateInfo",
    components: {
        LoadingSpinner,
        Alert
    },
    props: {
        consumerProductId: {
            type: Number,
            default: null,
        },
        darkMode: {
            type: Boolean,
            default: false,
        }
    },
    mixins: [
        AlertsMixin
    ],
    data() {
        return {
            api: ConsumerApiService.make(),
            loading: false,
            error: `Error fetching Consumer Product Date.`,
            consumerProduct: {product_date_created:null, product_date_updated: null}
        }
    },
    methods: {
        async getConsumerProductDateInfo() {
            this.loading = true;
            this.api.getConsumerProductDateInfo(this.consumerProductId).then(resp => {
                if (resp.data.data.status) {
                    this.consumerProduct.product_date_created = resp.data.data.product_date_created ?? null;
                    this.consumerProduct.product_date_updated = resp.data.data.product_date_updated ?? null;
                    return;
                }
                this.showAlert('error', this.error);
            })
            .catch(() => this.showAlert('error', this.error))
            .finally(() => this.loading = false);
        },
        getDateFormatted(timestamp) {
            return timestamp
                ? this.$filters.dateFromTimestamp(timestamp, 'usWithTime')
                : 'Unknown'
        }
    },
    created() {
        if (this.consumerProductId) this.getConsumerProductDateInfo();
    },
    watch: {
        consumerProductId(newVal, oldVal) {
            if (newVal !== oldVal) this.getConsumerProductDateInfo();
        }
    }
}
</script>

<style scoped>

</style>
