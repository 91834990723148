<template>
    <div class="p-5">
        <div class="flex items-center justify-between mb-3">
            <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Website List</h5>
            <button
                class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5" @click="openModal">
                Add New Website
            </button>
        </div>
        <div class="grid grid-cols-7 gap-x-3 mb-2 px-5" v-if="!loading">
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Name</p>
            <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-3">URL</p>
        </div>
        <div class="border-t border-b" v-if="!loading"
             :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-40 border-dark-border': darkMode}">
            <div>
                <div class="grid grid-cols-7 gap-x-3 border-b px-5 py-3 items-center"
                     v-for="website in websites" :key="website.id"
                     :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                    <p class="text-sm col-span-2 truncate">
                        {{ website.name }}
                    </p>
                    <p class="text-sm col-span-3 truncate">
                        {{ website.url }}
                    </p>
                    <p class="text-primary-500 cursor-pointer text-sm" @click="$emit('api-keys', website)">
                        Keys
                    </p>
                    <ActionsHandle :dark-mode="darkMode" @edit="editWebsite(website)" @delete="deleteWebsite(website)" />
                </div>
            </div>
        </div>
        <div class="h-32 flex items-center justify-center" v-if="loading">
            <loading-spinner></loading-spinner>
        </div>
        <modal :small="true" v-if="showModal" @close="closeModal" :dark-mode="darkMode" @confirm="saveWebsite" :close-text="'Cancel'" :confirm-text="confirmText">
            <template v-slot:header>
                <h4>{{ website.id ? 'Update' : 'Create' }} Website</h4>
            </template>
            <template v-slot:content>
                <div class="grid gap-3">
                    <div class="flex items-center">
                        <p class="uppercase font-semibold text-xs w-20"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Name
                        </p>
                        <input class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                               placeholder="Website name"
                               v-model="website.name"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                    </div>
                    <div class="flex items-center">
                        <p class="uppercase font-semibold text-xs w-20"
                           :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                            Url
                        </p>
                        <input class="w-full border rounded px-3 focus:outline-none focus:border focus:border-primary-500 pr-4 py-2"
                               placeholder="Website URL"
                               v-model="website.url"
                               :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}">
                    </div>
                </div>
            </template>
        </modal>
        <alerts-container :dark-mode="darkMode" :alert-type="'error'" :text="error" v-if="error !== null"></alerts-container>
    </div>

</template>

<script>
import Modal from "../../Shared/components/Modal";
import ApiService from "./services/api";
import ActionsHandle from "../../Shared/components/ActionsHandle";
import LoadingSpinner from "../../Shared/components/LoadingSpinner";
import AlertsContainer from "../../Shared/components/AlertsContainer";

export default {
    name: "Websites",
    components: {Modal, ActionsHandle, LoadingSpinner, AlertsContainer},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            api: ApiService.make(),
            showModal: false,
            websites: [],
            website: {
                name: null,
                url: null
            },
            saving: false,
            loading: false,
            error: null
        }
    },
    created() {
        this.getWebsites();
    },
    computed: {
        confirmText: function () {
            if (this.saving) return 'Saving...';
            if (this.website.id) return 'Update';

            return 'Create';
        }
    },
    methods: {
        openModal() {
            this.showModal = true;
        },
        closeModal () {
            this.showModal = false;
            this.website = {
                name: null,
                url: null
            };
        },
        getWebsites() {
            this.resetError();
            this.loading = true;
            this.websites = [];
            this.api.getWebsites()
                .then(resp => this.websites = resp.data.data.websites)
                .catch(e => this.error = e.response.data.message)
                .finally(() => this.loading = false);
        },
        saveWebsite() {
            if (this.saving) return;

            this.saving = true;
            this.resetError();

            if (this.website.id) {
                this.updateWebsite();
                return;
            }

            this.createWebsite();
        },
        createWebsite() {
            this.api.createWebsite(this.website).then(() => {
                this.getWebsites();
                this.closeModal();
            })
                .catch(e => this.error = e.response.data.message)
                .finally(() => this.saving = false);
        },
        editWebsite(website) {
            this.website = {...website};
            this.openModal();
        },
        updateWebsite() {
            this.api.updateWebsite(this.website.id, this.website).then(() => {
                this.getWebsites();
                this.closeModal();
            })
                .catch(e => this.error = e.response.data.message)
                .finally(() => this.saving = false);
        },
        deleteWebsite(website) {
            this.resetError();
            this.api.deleteWebsite(website.id)
                .then(() => this.getWebsites())
                .catch(e => this.error = e.response.data.message);
        },
        resetError() {
            this.error = null;
        }
    }
}
</script>
