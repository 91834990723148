import Pusher from 'pusher-js';

const PUSHER_AUTH_ENDPOINT = '/internal-api/v1/lead-processing/notifications/auth';

export default class PusherService {
    constructor(appKey, userId = null, cluster = 'us2', debug = false) {
        this.appKey = appKey;
        this.userId = userId;
        this.cluster = cluster;

        if(debug)
            Pusher.logToConsole = true;

        this._pusher = null;
    }

    get pusher() {
        if(this._pusher !== null)
            return this._pusher;

        return this._pusher = new Pusher(this.appKey, {
            cluster: this.cluster,
            auth: {
                params: {
                    user_id: this.userId,
                },
            },
            authEndpoint: window.location.origin + PUSHER_AUTH_ENDPOINT
        });
    }
}

let globalPusher = null;

export class PusherServiceFactory {
    /**
     * Handles the creation of a pusher service.
     *
     * @param appKey
     * @param userId
     * @param cluster
     * @param debug
     * @returns {null|PusherService}
     */
    static make(appKey, userId = null, cluster = 'us2', debug = false) {
        if(globalPusher !== null)
            return globalPusher;

        return globalPusher = new PusherService(appKey, userId, cluster, debug);
    }
}
