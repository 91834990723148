<template>
    <div>
        <company-reviews-list v-show="tab === 'list'"
                              :api="api"
                              :dark-mode="darkMode"
                              @change-tab="changeTab"
                              @edit-review="setEditReview" />
        <company-reviews-editor v-show="tab === 'editor'"
                                :api="api"
                                :dark-mode="darkMode"
                                @change-tab="changeTab"
                                :review="editReview" />
    </div>
</template>

<script>
    import ApiService from './API/api';
    import CompanyReviewsEditor from "./components/CompanyReviewsEditor";
    import CompanyReviewsList from "./components/CompanyReviewsList";

    export default {
        name: "CompanyReviews",
        components: {
            CompanyReviewsEditor,
            CompanyReviewsList
        },
        props: {
            darkMode: {
                type: Boolean,
                default: false
            }
        },
        data: function() {
            return {
                api: ApiService.make(),
                tab: 'list',
                editReview: null
            }
        },
        created: function() {

        },
        computed: {

        },
        methods: {
            resetEditReview() {
                this.editReview = null;
            },
            changeTab(tab) {
                this.tab = tab;

                if(tab === 'list') {
                    this.resetEditReview();
                }
            },
            setEditReview(review) {
                this.editReview = review;
            }
        }
    }
</script>
