export default {
    data: function() {
      return {
          displayModal: false,
          modalConfiguration: {
              title: "",
              options: [],
              selectedValues: [],
              multiSelect: false,
              type: null,
              warning: null,
          },
          defaultModalConfigurations: {
              "cancel": {
                  title: "Why is this lead being cancelled?",
                  options: [
                      {id: "duplicate", value: "Duplicate"},
                      {id: "disconnected", value: "Disconnected"},
                      {id: "insufficientinformation", value: "Insufficient Information"},
                      {id: "fake", value: "Fake"},
                      {id: "test", value: "Test"},
                      {id: "wrongnumber", value: "Wrong Number"},
                      {id: "notinterested", value: "Not Interested"},
                      {id: "mobilehomepark", value: "Mobile Home Park"},
                      {id: "salestool", value: "Sent as Sales Tool"},
                      {id: "notsuitablesolar", value: "Not Suitable for Solar"}
                  ],
                  multiSelect: false
              },
              "pending-review": {
                  title: "Why does this lead need further review?",
                  options: [
                      "Qualify Shade",
                      "Qualify Already Has Solar",
                      "Qualify Mobile Home",
                      "Qualify HOA",
                      "Qualify Own Property",
                      "Qualify Low Spend",
                      "Qualify Angi",
                      "Qualify Name",
                      "Qualify Email",
                      "Verify Phone Number"
                  ],
                  multiSelect: true
              },
              "under-review": {
                  title: "Why does this lead need to be under review?",
                  options: [
                      "No Answer, Follow Up Text",
                      "No Answer, No Message Left",
                      "No Answer, Left Voicemail",
                      "Straight To Busy",
                      "Straight To Voicemail",
                      "Pick Up, Hang Up",
                  ],
                  multiSelect: true
              },
              "allocate": {
                  title: "Allocate Lead",
                  options: [
                      "Verified without speaking to",
                      "Qualified via conversation",
                      "No validation required"
                  ],
                  multiSelect: false
              },
              "best-time-to-contact": {
                  title: "Best Time to Contact",
                  options: [
                      "Weekday Morning",
                      "Weekday Afternoon",
                      "Weekday Evening",
                      "Anytime",
                      "Weekend Morning",
                      "Weekend Afternoon",
                      "Weekend Evening",
                  ],
                  multiSelect: false
              }
          }
      }
    },
    methods: {
        hideModal() {
            this.displayModal = false;
            this.resetModal();
        },
        resetModal() {
            this.modalConfiguration.title = "";
            this.modalConfiguration.multiSelect = false;
            this.modalConfiguration.type = null;
            this.modalConfiguration.warning = null;
            this.modalConfiguration.options = [];
            this.modalConfiguration.selectedValues = [];
        },
        showModal(type, options, title, multiselect) {
            if(Object.keys(this.defaultModalConfigurations).includes(type)) {
                this.resetModal();

                const defaultConf = this.defaultModalConfigurations[type];

                this.modalConfiguration.type = type;
                this.modalConfiguration.title = title || defaultConf.title;
                this.modalConfiguration.multiSelect = multiselect || defaultConf.multiSelect;
                this.modalConfiguration.options = options && options.length > 0 ? options : defaultConf.options;

                this.displayModal = true;
            }
        },
        selectModalOption(value) {
            if(this.modalConfiguration.multiSelect === false && !this.modalConfiguration.selectedValues.includes(value)) {
                this.modalConfiguration.selectedValues = [];
            }

            if(!this.modalConfiguration.selectedValues.includes(value)) {
                this.modalConfiguration.selectedValues.push(value);
            }
            else {
                this.modalConfiguration.selectedValues = [...this.modalConfiguration.selectedValues].filter(val => val !== value);
            }

            this.modalConfiguration.warning = null;
        },
        optionSelected(value) {
            return this.modalConfiguration.selectedValues.includes(value);
        },
        optionClasses(option) {
            const classes = [];

            if(this.optionSelected(option)) {
                if(this.darkMode) {
                    classes.push('bg-primary-500', 'text-white');
                }
                else {
                    classes.push('bg-cyan-150', 'hover:bg-blue-200', 'text-blue-550');
                }
            }
            else {
                if(this.darkMode) {
                    classes.push('bg-dark-module', 'border', 'border-primary-500', 'hover:bg-blue-800', 'text-grey-300');
                }
                else {
                    classes.push('bg-grey-100', 'hover:bg-grey-200', 'text-grey-475');
                }
            }

            return classes;
        },
        confirmSelection(contactScenario, publicComment = null) {
            this.modalConfiguration.warning = null;

            switch(this.modalConfiguration.type) {
                case 'cancel':
                    if(this.modalConfiguration.selectedValues.length === 0) {
                        return this.modalConfiguration.warning = "Please select a reason.";
                    }

                    this.cancelConsumerProduct(this.modalConfiguration.selectedValues[0].id, publicComment);
                    break;
                case 'pending-review':
                    if (this.modalConfiguration.selectedValues.length === 0) {
                        return this.modalConfiguration.warning = "Please select reasons for further review.";
                    }

                    this.markForReview(this.modalConfiguration.selectedValues.join(", "));
                    break;
                case 'under-review':
                    if(this.modalConfiguration.selectedValues.length === 0) {
                        return this.modalConfiguration.warning = "Please select reasons for moving to under review.";
                    }

                    this.markAsUnderReview(this.modalConfiguration.selectedValues.join(", "), publicComment);
                    break;
                case 'allocate':
                    if(this.modalConfiguration.selectedValues.length === 0) {
                        return this.modalConfiguration.warning = "Please select reasons for allocating.";
                    }

                    if(this.modalConfiguration.selectedValues[0] === contactScenario) {
                        return this.showModal('best-time-to-contact');
                    }

                    this.allocateProduct(this.modalConfiguration.selectedValues[0], null, publicComment);
                    break;
                case 'best-time-to-contact':
                    if(this.modalConfiguration.selectedValues.length === 0) {
                        return this.modalConfiguration.warning = "Please select the best time to contact.";
                    }

                    this.allocateProduct(contactScenario, this.modalConfiguration.selectedValues[0], publicComment);
                    break;
            }

            this.hideModal();
        },
    }
}
