<template>
    <div class="w-full flex-auto px-6 py-5 relative"
         :class="{'bg-light-background': !darkMode, 'bg-dark-background': darkMode}">

        <div class="px-10" :class="[darkMode ? 'text-white' : 'text-slate-900']">
            <div class="flex items-center justify-between flex-wrap">
                <div class="flex items-center py-4">
                    <div class="inline-flex items-center h-9" :class="{'text-grey-120': darkMode}">
                        <h3 @click="store.showTemplatesList" class="text-xl font-medium text-grey-300 leading-none hover:text-grey-400 transition duration-200 cursor-pointer">Email Templates</h3>
                        <h3 class="text-xl inline-flex font-medium leading-none items-center" :class="{'text-black': !darkMode, 'text-grey-120': darkMode}">
                            <svg class="mx-4 fill-current" width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.327334 0.260067C0.735992 -0.11144 1.36844 -0.0813234 1.73995 0.327334L6.73995 5.82733C7.08669 6.20876 7.08669 6.79126 6.73995 7.17268L1.73995 12.6727C1.36844 13.0813 0.735992 13.1115 0.327334 12.7399C-0.0813234 12.3684 -0.11144 11.736 0.260067 11.3273L4.64855 6.50001L0.260067 1.67268C-0.11144 1.26402 -0.0813234 0.631574 0.327334 0.260067Z"/>
                            </svg>
                            Editor
                        </h3>
                    </div>
                </div>
            </div>
            <div>
                <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <div class="border rounded-lg"
                         :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
                        <div class="flex flex-col w-full px-5">
                            <div class="grid grid-cols-3 items-end gap-3 mt-5 mb-2">
                                <div class="col-span-2">
                                    <p class="pb-2 text-sm font-medium">Template Name</p>
                                    <input type="text" v-model="store.templateName"
                                           :class="{'border-grey-200 text-grey-900 bg-grey-50': !darkMode, 'border-blue-700 text-white bg-dark-background': darkMode}"
                                           class="relative z-20 transition duration-100 w-full border inline-flex justify-between items-center rounded py-2 px-4 h-9"
                                           placeholder="">
                                </div>
                                <div>
                                    <p class="pb-2 text-sm font-medium">Template Industry</p>
                                    <select :value="store.templateIndustry"
                                            @change="store.changedTemplateIndustry($event.target.value)"
                                            :class="{'hover:bg-grey-120 border-grey-200 text-grey-400 bg-grey-50': !darkMode, 'hover:border-blue-400 border-blue-700 text-blue-400 bg-dark-background': darkMode}"
                                            class="relative z-20 cursor-pointer uppercase text-xs font-semibold transition duration-100 w-full border inline-flex justify-between items-center rounded py-2 px-4">
                                        <option value="solar">Solar</option>
                                        <option value="roofing">Roofing</option>
                                    </select>
                                </div>
                            </div>

                            <div v-if="store.templateType === 'content'" class="my-2">
                                <p class="pb-2 text-sm font-medium">Template Header & Footer</p>
                                <select v-model="store.templateBackground"
                                        :class="{'hover:bg-grey-120 border-grey-200 text-grey-400 bg-grey-50': !darkMode, 'hover:border-blue-400 border-blue-700 text-blue-400 bg-dark-background': darkMode}"
                                        class="relative z-20 cursor-pointer uppercase text-xs font-semibold transition duration-100 w-full border inline-flex justify-between items-center rounded py-2 px-4">
                                    <option value="0" selected disabled>Select a Header & Footer</option>
                                    <option v-for="templateBackground in store.templateBackgrounds" :value="templateBackground.id" :key="templateBackground.id">{{ templateBackground.name }}</option>
                                </select>
                            </div>

                            <div class="my-4 grid grid-cols-1 gap-2 text-sm items-center">
                                <p class="text-grey-400">
                                    Owner: <span class="text-blue-550">{{ store.templateOwner }}</span>
                                </p>
                                <p class="text-grey-400">
                                    Last Updated: <span class="text-blue-550">{{ store.templateLastUpdated }}</span>
                                </p>
                            </div>

                            <template v-if="store.templateType === 'content' && editorPlugins.length">
                                <markdown-editor v-model:content="store.templateContent" v-model:images="store.templateContentImages" :dark-mode="darkMode" :additional-plugins="editorPlugins" />
                            </template>
                            <template v-else-if="store.templateType === 'background' && editorPlugins.length">
                                <div class="my-2">
                                    <p class="uppercase text-sm text-grey-400">Header</p>
                                    <markdown-editor
                                        v-model:content="store.templateHeader"
                                        v-model:images="store.templateHeaderImages"
                                        :dark-mode="darkMode"
                                        :additional-plugins="editorPlugins"
                                        :image-file-base-path="'header/'"
                                        class="my-1" />
                                </div>
                                <div class="my-2">
                                    <p class="uppercase text-sm text-grey-400">Footer</p>
                                    <markdown-editor
                                        v-model:content="store.templateFooter"
                                        v-model:images="store.templateFooterImages"
                                        :dark-mode="darkMode"
                                        :additional-plugins="editorPlugins"
                                        :image-file-base-path="'footer/'"
                                        class="my-1" />
                                </div>
                            </template>

                            <div class="flex flex-row justify-between items-center my-5">
                                <div class="flex items-center">
                                    <input class="mr-2" type="checkbox" v-model="store.personal">
                                    <p>Personal</p>
                                </div>
                                <div class="flex items-center">
                                    <div v-if="saving" class="text-center px-5">
                                        Saving...
                                    </div>
                                    <button
                                        v-else-if="store.isTemplateOwner || !store.templateId"
                                        type="button"
                                        class="rounded px-5 py-2 mr-4 text-white bg-primary-500 hover:bg-blue-500 font-semibold text-sm"
                                        @click="saveTemplate">
                                        Save
                                    </button>

                                    <div>
                                        <button
                                            type="button"
                                            class="rounded text-blue-550 px-5 py-2 border border-primary-500 hover:bg-blue-100 hover:bg-opacity-25 font-semibold text-sm"
                                            @click="preview">
                                            Preview
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="rounded-lg overflow-hidden flex justify-center">
                        <iframe v-if="!previewLoading && store.templateHTML"
                                ref="previewFrame"
                                src="javascript:void(0);"
                                :srcdoc="store.templateHTML"
                                style="height: calc(100vh - 15rem);"
                                class="w-full">
                        </iframe>
                        <div v-else-if="previewLoading">
                            <loading-spinner/>
                        </div>
                        <div v-else class="flex flex-row justify-center text-blue-500 uppercase">
                            Preview Here
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import MarkdownEditor from "../../Shared/components/MarkdownEditor";
    import LoadingSpinner from "../../Shared/components/LoadingSpinner";
    import Modal from "../../Shared/components/Modal";
    import shortcodesPlugin from "../../Shared/includes/shortcodesPlugin";
    import { useEmailTemplatesStore } from "../store/store";
    import { replaceMarkdownImageTags } from "../../Shared/services/markdownImageTags";

    export default {
        name: "TemplatesEditor",
        props: {
            templateId: {
                type: Number,
                default: 0
            },
            darkMode: {
                type: Boolean,
                default: false
            }
        },
        components: {
            LoadingSpinner,
            MarkdownEditor,
            Modal
        },
        created: function() {
            this.getShortcodes();
        },
        emits: [
            'show-alert',
            'show-template-list'
        ],
        data: function() {
            return {
                editorPlugins: [],

                loading: false,
                saving: false,
                loadingTemplate: false,
                previewLoading: false
            };
        },
        setup: function() {
            const store = useEmailTemplatesStore();

            return {
                store
            };
        },
        computed: {

        },
        methods: {
            getShortcodes() {
                return this.store.api.getShortcodes().then(res => {
                    if(res.data.data.status === true) {
                        this.editorPlugins = [
                            [shortcodesPlugin, res.data.data.shortcodes]
                        ];
                    }
                    else {
                        this.store.showAlert('error', 'Failed to load shortcodes list');
                    }
                }).catch(err => {
                    this.store.showAlert('error', 'Error loading shortcodes list');
                });
            },
            preview() {
                this.previewLoading = true;
                this.store.togglePreview(false);

                let promise;
                if(this.store.templateType === 'content') {
                    promise = this.store.api.previewEmailTemplate(this.store.templateContent, this.store.templateBackground);
                }
                else if(this.store.templateType === 'background') {
                    promise = this.store.api.previewEmailTemplateBackground(this.store.templateHeader, this.store.templateFooter);
                }

                return promise.then(res => {
                    if(res.data.data.status === true) {
                        this.store.togglePreview(true, res.data.data.preview_html);
                    }
                    else {
                        this.store.showAlert('error', 'Failed to create template');
                    }
                }).catch(err => {
                    this.store.showAlert('error', 'Error creating preview');
                }).finally(() => {
                    this.previewLoading = false;
                });
            },
            saveTemplate() {
                if(this.store.templateType === 'content') {
                    this.saveContentTemplate();
                }
                else if(this.store.templateType === 'background') {
                    this.saveBackgroundTemplate();
                }
            },
            saveContentTemplate() {
                this.saving = true;

                let apiPromise;
                if(this.store.templateId) {
                    apiPromise = this.store.api.updateEmailTemplate(
                        this.store.templateId,
                        this.store.templateName,
                        replaceMarkdownImageTags(this.store.templateContent),
                        this.store.personal,
                        this.store.templateIndustry,
                        this.store.templateBackground
                    ).then(res => {
                        if(res.data.data.status === true) {
                            this.store.showAlert('success', 'Template updated');
                            this.store.templateLastUpdated = res.data.data.last_updated;
                        }
                        else {
                            this.store.showAlert('error', 'Failed to update template');

                            throw new Error();
                        }
                    }).catch(err => {
                        this.store.showAlert('error', 'Error updating template');

                        throw new Error();
                    });
                }
                else {
                    apiPromise = this.store.api.createEmailTemplate(
                        this.store.templateName,
                        replaceMarkdownImageTags(this.store.templateContent),
                        this.store.personal,
                        this.store.templateIndustry,
                        this.store.templateBackground
                    ).then(res => {
                        if(res.data.data.status === true) {
                            this.store.showAlert('success', 'Template created');
                            this.store.templateId = res.data.data.template_id;
                            this.store.isTemplateOwner = true;
                            this.store.templateOwner = res.data.data.owner_name;
                            this.store.templateLastUpdated = res.data.data.last_updated;
                        }
                        else {
                            this.store.showAlert('error', 'Failed to create template');

                            throw new Error();
                        }
                    }).catch(err => {
                        this.store.showAlert('error', 'Error creating template');

                        throw new Error();
                    });
                }

                apiPromise.then(res => {
                    return this.saveImages();
                }).finally(() => {
                    this.saving = false;
                });
            },
            saveBackgroundTemplate() {
                this.saving = true;

                let apiPromise;
                if(this.store.templateId) {
                    apiPromise = this.store.api.updateEmailTemplateBackground(
                        this.store.templateId,
                        this.store.templateName,
                        replaceMarkdownImageTags(this.store.templateHeader),
                        replaceMarkdownImageTags(this.store.templateFooter),
                        this.store.personal,
                        this.store.templateIndustry
                    ).then(res => {
                        if(res.data.data.status === true) {
                            this.store.showAlert('success', 'Template background updated');
                            this.store.templateLastUpdated = res.data.data.last_updated;
                        }
                        else {
                            this.store.showAlert('error', 'Failed to update template background');

                            throw new Error();
                        }
                    }).catch(err => {
                        this.store.showAlert('error', 'Error updating template background');

                        throw new Error();
                    })
                }
                else {
                    apiPromise = this.store.api.createEmailTemplateBackground(
                        this.store.templateName,
                        replaceMarkdownImageTags(this.store.templateHeader),
                        replaceMarkdownImageTags(this.store.templateFooter),
                        this.store.personal,
                        this.store.templateIndustry
                    ).then(res => {
                        if(res.data.data.status === true) {
                            this.store.showAlert('success', 'Template background created');
                            this.store.templateId = res.data.data.template_id;
                            this.store.isTemplateOwner = true;
                            this.store.templateOwner = res.data.data.owner_name;
                            this.store.templateLastUpdated = res.data.data.last_updated;
                        }
                        else {
                            this.store.showAlert('error', 'Failed to create template background');

                            throw new Error();
                        }
                    }).catch(err => {
                        this.store.showAlert('error', 'Error creating template background');

                        throw new Error();
                    })
                }

                apiPromise.then(res => {
                    return this.saveImages();
                }).finally(() => {
                    this.saving = false;
                });
            },
            saveImages() {
                let promises = [];

                if(this.store.templateType === 'content') {
                    Object.entries(this.store.templateContentImages).forEach(([name, imageDataUrl]) => {
                        promises.push(this.store.api.saveTemplateImage(name, imageDataUrl, this.store.templateId, this.store.templateType));
                    });
                }
                else if(this.store.templateType === 'background') {
                    Object.entries(this.store.templateHeaderImages).forEach(([name, imageDataUrl]) => {
                        promises.push(this.store.api.saveTemplateImage(name, imageDataUrl, this.store.templateId, this.store.templateType));
                    });

                    Object.entries(this.store.templateFooterImages).forEach(([name, imageDataUrl]) => {
                        promises.push(this.store.api.saveTemplateImage(name, imageDataUrl, this.store.templateId, this.store.templateType));
                    });
                }

                return Promise.all(promises).then(responses => {
                    let success = true;
                    let failedImages = [];
                    for(const res of responses) {
                        if(res.data.data.status !== true) {
                            success = false;
                            failedImages.push(res.data.data.filename);
                        }
                    }

                    if(success) {
                        this.store.showAlert('success', 'Images uploaded');
                    }
                    else {
                        const failedImageNames = failedImages.join(', ');

                        this.store.showAlert('error', `Failed to upload images: ${failedImageNames}`);
                    }
                }).catch(err => {
                    this.store.showAlert('error', 'Error uploading images');
                });
            }
        }
    }
</script>
