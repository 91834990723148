<template>
    <div class="border-t border-b h-[33rem] overflow-y-auto"
         :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode, 'flex flex-col items-center justify-center': loading}">
        <div v-if="companies.length !== 0" :id="'company-servicing-area-' + index"
             class="grid gap-x-3 border-b px-5 py-3 transition duration-200 relative"
             v-for="(company, index) in companies"
             :class="[ darkMode ? ( company?.companyid === selectedCompany ? 'bg-dark-module text-slate-100 hover:bg-dark-module border-dark-border' : 'text-slate-100 hover:bg-dark-module border-dark-border') : ( company?.companyid === selectedCompany ? 'bg-light-module text-slate-900 hover:bg-white border-light-border' : 'text-slate-900 hover:bg-white border-light-border'), selectedCompany === null ? 'grid-cols-8' : 'grid-cols-3']">
            <div :id="'company-name-' + index"
                 :class="[company?.companyid === selectedCompany ? 'text-primary-500' : '']"
                 class="col-span-2 text-sm font-medium cursor-pointer truncate"
                 @click="getContacts(company?.companyid)">
                {{
                    company.hasOwnProperty("companyname") && typeof company.companyname == 'string' ? company.companyname : "N/A"
                }}
            </div>
            <div :id="'company-status-' + index" class="text-center">
                <p v-if="company.status === 'active'"
                   class="inline border border-emerald-600 bg-emerald-50 text-emerald-600 rounded-full py-1 px-3 capitalize text-xs font-semibold">
                    {{ company.hasOwnProperty("status") ? company.status : "N/A" }}
                </p>
                <p v-else
                   :class="[darkMode ? 'bg-dark-background border-dark-border' : 'border-slate-600 bg-slate-50']"
                   class="inline border text-slate-600 rounded-full py-1 px-3 capitalize text-xs font-semibold">
                    {{ company.hasOwnProperty("status") ? company.status : "N/A" }}
                </p>
            </div>
            <div v-if="amountOfPurchasedLeads && selectedCompany === null">
                <p class=" text-sm text-center">
                    {{ company.hasOwnProperty("lead_cost") ? currency(company.lead_cost ?? 0).format() : "N/A" }}</p>
            </div>
            <p :id="'company-estimated-revenue-' + index" v-if="selectedCompany === null" class=" text-sm text-center">
                {{
                    company.hasOwnProperty('revenue_in_thousands') && typeof company.revenue_in_thousands == 'number' ? currency(company.revenue_in_thousands * 1000).format() : "N/A"
                }}
            </p>
            <p :id="'company-employee-count-' + index" v-if="selectedCompany === null" class=" text-sm text-center">
                {{
                    company.hasOwnProperty("employee_count") && typeof company.employee_count == 'number' ? company.employee_count : "N/A"
                }}
            </p>
            <p :id="'company-google-review-count-' + index" v-if="selectedCompany === null"
               class=" text-sm text-center">{{
                    company.hasOwnProperty("google_review_count") && typeof company.google_review_count == 'number' ? company.google_review_count : "N/A"
                }}</p>
            <p :id="'company-google-rating-' + index" v-if="selectedCompany === null" class=" text-sm text-center">
                {{ company.hasOwnProperty("google_rating") && typeof company.google_rating == 'number' ? company.google_rating : "N/A" }}
            </p>
            <div class="col-span-3 cursor-default p-2" v-if="contactsVisible(company.companyid)">
                <h2 class="text-xs font-semibold " :class="[!darkMode ? 'text-slate-500' : 'text-slate-400']">
                    Offices:
                </h2>
                <div class="divide-y"
                     :class="[darkMode ? 'divide-slate-800 text-slate-400' : 'divide-slate-200 text-slate-600']">
                    <div class="py-4" v-for="office in company.addresses?.filter(address => !!address.phone)">
                        <div class="flex items-center justify-between cursor-pointer">
                            <div class="flex items-center">
                                <div>
                                    <p class="text-sm truncate pb-0 font-medium">
                                        {{ office.city }}, {{ office.state }}
                                    </p>
                                    <p class="text-grey-400 text-sm">{{ office.phone }}</p>
                                </div>
                            </div>
                            <div class="flex items-center">
                                <svg @click="callOffice(office)" class="w-4 mr-5 cursor-pointer" width="16" height="16"
                                     viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M15.7061 12.5332L12.0551 9.21369C11.8825 9.05684 11.6558 8.97318 11.4227 8.98038C11.1895 8.98759 10.9684 9.0851 10.8058 9.25231L8.65655 11.4626C8.13922 11.3638 7.09917 11.0396 6.02859 9.97172C4.958 8.90024 4.63377 7.85751 4.53767 7.34378L6.7462 5.19364C6.91363 5.03119 7.01128 4.80998 7.01848 4.57681C7.02569 4.34364 6.94189 4.11681 6.78482 3.94433L3.46619 0.294312C3.30905 0.121292 3.09065 0.0163428 2.85738 0.00175297C2.62411 -0.0128368 2.39434 0.0640823 2.21687 0.216174L0.2679 1.8876C0.112621 2.04344 0.0199401 2.25085 0.00743837 2.47049C-0.00603376 2.69503 -0.262902 8.01378 3.86137 12.1398C7.45933 15.7368 11.9662 16 13.2074 16C13.3889 16 13.5002 15.9946 13.5299 15.9928C13.7495 15.9805 13.9568 15.8874 14.1119 15.7315L15.7824 13.7816C15.9351 13.6047 16.0126 13.3751 15.9983 13.1419C15.9841 12.9086 15.8792 12.6902 15.7061 12.5332V12.5332Z"
                                        fill="#0081FF"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="border-b border-t py-3 mt-3">
                    <p v-if="company.mi_company">
                        Profile: <a :href="`/companies/${company.mi_company.id}`" class="text-blue-550 cursor-pointer" target="_blank">{{ company.mi_company.id }}</a>
                    </p>
                    <p v-if="company.website">Website: <a :href="company.website" class="text-blue-550 cursor-pointer" target="_blank">{{ company.website }}</a></p>
                </div>

                <div class="my-5">
                    <button
                        class="ml-2 my-1 transition duration-200 inline-flex items-center font-semibold bg-grey-475 hover:bg-grey-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5"
                        @click="toggleTaskModal(true)">
                        Create Task
                    </button>
                    <button
                        class="ml-2 my-1 transition duration-200 inline-flex items-center font-semibold bg-grey-475 hover:bg-grey-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5"
                        @click="toggleActionModal(true)">
                        Create Action
                    </button>
                </div>
            </div>
        </div>
        <div v-else id="no-companies-returned" class="flex justify-center h-screen items-center">
            <span class="text-lg text-gray-400 italic">No Companies Returned</span>
        </div>
        <action-note-modal
            v-if="actionModalActive"
            :dark-mode="darkMode"
            :company-id="selectedCompany"
            :category-default="'Other'"
            @close="toggleActionModal(false)"
            @reload-actions="reloadAction"
        ></action-note-modal>
        <create-task-modal
            v-if="taskModalActive"
            :dark-mode="darkMode"
            :company-id="selectedCompany"
            @close="toggleTaskModal(false)"
            @task-created="taskCreated"
        ></create-task-modal>
        <alerts-container
            v-if="alertActive"
            :alert-type="alertType"
            :text="alertText"
            :dark-mode="darkMode">
        </alerts-container>
    </div>
</template>
<script>
import ToggleSwitch from "../../components/ToggleSwitch.vue";
import SharedApiService from "../../services/api";
import ActionNoteModal from "../../components/ActionNoteModal.vue";
import CreateTaskModal from "../../components/CreateTaskModal.vue";
import AlertsContainer from "../../components/AlertsContainer.vue";
import alertsMixin from "../../../../mixins/alerts-mixin";
import currency from "currency.js";

/**
 * @typedef company
 * @property {number} companyid
 */

export default {
    emits: ['update:selected-company', 'update:contacts', 'update:call-office'],
    components: {
        ToggleSwitch,
        ActionNoteModal,
        CreateTaskModal,
        AlertsContainer
    },
    props: {
        selectedCompany: {type: [null, Number], required: true},
        darkMode: {type: Boolean, required: true},
        companies: {type: Array, required: true},
        loading: {type: Boolean, required: true},
        contacts: {type: Array, required: true},
        amountOfPurchasedLeads: {type: Boolean, default: false},
    },
    watch: {
        selectedCompany(to, from) {
            this.changeSelectedCompany(to);
        },
    },
    mixins: [alertsMixin],
    methods: {
        currency,
        /**
         * Emits the update:selected-company event.
         * @param to
         */
        changeSelectedCompany(to) {
            this.$emit("update:selected-company", to);
        },
        /**
         * Determines if contacts should be visible under the company name. When a company is selected (i.e., clicked on), if it has contact information, then it should show under it.
         * @param companyId
         * @returns {boolean}
         */
        contactsVisible(companyId) {
            return this.selectedCompany === companyId;
        },
        /**
         * Emits the update:contacts event.
         * @param to
         */
        changeContacts(to) {
            this.$emit("update:contacts", to);
        },
        /**
         * Emits the update:call-office event.
         * @param to
         */
        callOffice(to) {
            this.$emit("update:call-office", to);
        },
        /**
         * Gets the company's contacts from the api.
         * @param companyId
         */
        getContacts(companyId) {
            this.changeContacts([]);
            if (this.selectedCompany !== companyId) {
                this.changeSelectedCompany(companyId);

                this.api.getCompanyContacts(companyId).then(resp => this.changeContacts(resp?.data?.data?.contacts ?? []));
            } else {
                this.changeSelectedCompany(null);
            }
        },
        toggleActionModal(show) {
            this.actionModalActive = show;
            this.taskModalActive = false;
        },
        toggleTaskModal(show) {
            this.taskModalActive = show;
            this.actionModalActive = false;
        },
        reloadAction() {
            this.toggleActionModal(false);
            this.showAlert('success', 'An action was successfully created.')
            this.$emit("update:activities")

        },
        taskCreated() {
            this.toggleTaskModal(false);
            this.showAlert('success', 'A task was successfully created.')
            this.$emit("update:activities")
        }
    },
    data() {
        return {
            api: SharedApiService.make(),
            actionModalActive: false,
            taskModalActive: false
        }
    }
}
</script>
