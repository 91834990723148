<template>
    <div class="border rounded-lg"  :class="[darkMode ? 'bg-dark-module border-dark-border' : 'bg-light-module border-light-border']">
        <div class="px-5 pt-5">
            <div class="flex items-center justify-between">
                <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Leads Overview</h5>
            </div>
        </div>
        <Tab
            :dark-mode="darkMode"
            :tabs="tabs"
            @selected="selectTab"
            :tabs-classes="'w-full'"
            :tab-type="'Normal'"
            :default-tab-index="0"
        />
        <div class="p-5" v-if="!loading">
            <div class="flex flex-wrap mb-5 mt-3">
                <div class="flex items-center mr-8">
                    <div class="flex-shrink-0 w-3 h-3 mr-2 rounded-full bg-red-300"
                    ></div>
                    <p class="text-sm" :class="[darkMode ? 'text-slate-100' : 'text-slate-900']">Rejected Leads</p>
                </div>
                <div class="flex items-center mr-8">
                    <div class="flex-shrink-0 w-3 h-3 text-sm mr-1 rounded-full bg-primary-400"
                    ></div>
                    <p class="text-sm" :class="[darkMode ? 'text-slate-100' : 'text-slate-900']">Total Leads Received</p>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center mb-6 pt-2">
                    <div class="p-2 h-10 rounded-r "
                         :class="{'bg-red-300' : !darkMode, 'border-r-4 border-red-300 bg-dark-background': darkMode}"
                         :style="{width: rejectionPercentage + '%'}">
                        <p class="flex justify-end items-center h-full mr-2 text-white font-bold">
                            {{ rejectedLeads }}
                        </p>

                    </div>
                    <div class="ml-2 font-bold text-red-300">{{ rejectionPercentage }}%</div>
                </div>

                <div class="flex items-center">
                    <div class="p-2 h-10 rounded-r"
                         :class="{'bg-primary-400' : !darkMode, 'border-r-4 border-primary-400 bg-dark-background': darkMode}"
                         style="width: 100%">
                        <p class="flex justify-end items-center h-full mr-2 text-white font-bold">
                            {{ totalLeads }}
                        </p>
                    </div>

                </div>
            </div>
        </div>
        <div v-else>
            <loading-spinner></loading-spinner>
        </div>
    </div>
</template>

<script>
import Tab from "../components/Tab";
import ApiService from "../../Companies/services/api";
import LoadingSpinner from "../components/LoadingSpinner";
import dayjs from "dayjs";

export default {
    name: "LeadsOverview",
    components: {Tab, LoadingSpinner},
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        companyId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            tabs : [
                {name: '30 Days', current: true},
                {name: '3 Months', current: false},
                {name: 'All Time', current: false},
            ],
            api: ApiService.make(),
            selectedTab: '30 Days',
            rejectedLeads: 0,
            totalLeads: 0,
            loading: false
        }
    },
    created() {
        if (this.companyId) this.getLeadsOverview();
    },
    computed: {
        rejectionPercentage: function () {
            if (!this.rejectedLeads || !this.totalLeads) return 0;

            return Math.round((this.rejectedLeads / this.totalLeads) * 100);
        },
        startTimestamp: function () {
            if (this.selectedTab === 'All Time') return undefined;
            if (this.selectedTab === '30 Days') return dayjs().subtract(30, 'day').startOf('day').unix();
            if (this.selectedTab === '3 Months') return dayjs().subtract(3, 'month').startOf('day').unix();
        }
    },
    methods: {
        getLeadsOverview() {
            this.loading = true;

            this.api.getLeadsOverview(this.companyId, this.startTimestamp)
                .then(resp => {
                    this.rejectedLeads = resp.data.data.total_rejection;
                    this.totalLeads = resp.data.data.total_leads;
                })
                .finally(() => this.loading = false);
        },
        selectTab(selected) {
            this.selectedTab = selected;
            this.getLeadsOverview();
        },
        sendToTab(value) {
            this.$emit('send-to-tab', value)
        }
    }
}
</script>

<style scoped>

</style>
