import {defineStore} from "pinia";

export const useCompanyServicingAreaFiltersStore = defineStore('company-servicing-area-filter', {
    state: () => {
        return {
            filters: {
                "companyContacts": {
                    "name": "Company Contacts",
                    "active": false,
                    "firstValue": 100,
                    "firstOperator": "greaterThan",
                    "logical": null,
                    "secondValue": 100,
                    "secondOperator": "lessThan",
                    "condition": "withPhone"
                },
                "amountOfPurchasedLeads": {
                    "name": "Amount Of Purchased Leads",
                    "active": false,
                    "firstValue": 10000,
                    "firstOperator": "greaterThan",
                    "logical": null,
                    "secondValue": 1000000,
                    "secondOperator": "lessThan",
                    "condition": "lastSixMonths"
                },
                "estimatedRevenue": {
                    "name": "Estimated Revenue",
                    "active": false,
                    "firstValue": 1000,
                    "firstOperator": "greaterThan",
                    "logical": null,
                    "secondValue": 1000000,
                    "secondOperator": "lessThan",
                },
                "employeeCount": {
                    "name": "Employee Count",
                    "active": false,
                    "firstValue": 500,
                    "firstOperator": "greaterThan",
                    "logical": null,
                    "secondValue": 1000,
                    "secondOperator": "lessThan",
                },
                "googleReviews": {
                    "name": "Google Reviews",
                    "active": false,
                    "firstValue": 500,
                    "firstOperator": "greaterThan",
                    "logical": null,
                    "secondValue": 1000,
                    "secondOperator": "lessThan",
                },
                "googleRating": {
                    "name": "Google Rating",
                    "active": false,
                    "firstValue": 1,
                    "firstOperator": "greaterThan",
                    "logical": null,
                    "secondValue": 5,
                    "secondOperator": "lessThan",
                }
            },
        }
    },
    persist: true,
})
