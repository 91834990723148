import {createApp} from 'vue'
import { createPinia } from 'pinia';
import {dateFromTimestamp, filterConfig} from "../modules/helpers"

// Available Budget Report
import HistoricalAvailableBudgetsReport from "./components/Reports/HistoricalAvailableBudgetsReport/HistoricalAvailableBudgetsReport.vue";

// Estimated Revenue Per Lead Report
import EstimatedRevenuePerLeadReport from "./components/Reports/EstimatedRevenuePerLeadReport/EstimatedRevenuePerLeadReport.vue";

// Lead Processing
import LeadProcessing from "./components/LeadProcessing/LeadProcessing.vue";

// Lead Processing Management
import LeadProcessingManagement from "./components/LeadProcessingManagement/LeadProcessingManagement.vue";

// Communication
import Communications from "./components/Communications/Communications";

// Lead Processing Notifications
import LeadProcessingNotifications from "./components/LeadProcessingNotifications/LeadProcessingNotifications.vue";
import UserManagement from "./components/UserManagement/UserManagement";
import UserSettings from "./components/UserSettings/UserSettings";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import SalesBaitManagement from "./components/SalesBaitManagement/SalesBaitManagement";

import TaskManagement from "./components/TaskManagement/TaskManagement"
import TaskTypeEditor from "./components/TaskTypeEditor/TaskTypeEditor"
import Tasks from "./components/Tasks/Tasks"
import TasksList from "./components/TasksList/TasksList";
import CompanyPage from "./components/Companies/CompanyPage";

import AppLogo from "./components/Shared/components/AppLogo";

// Sales Management
import SalesManagement from "./components/SalesManagement/SalesManagement.vue";

//Companies Search
import CompaniesSearch from "./components/Shared/modules/CompaniesSearch";

//Company Activity
import ActivityItem from "./components/Companies/components/activity/activityItem/ActivityItem";

//Email Templates
import EmailTemplates from "./components/EmailTemplates/EmailTemplates";

import GlobalCompanyWrapper from "./components/Companies/GlobalCompanyWrapper";

//Roles & Permissions
import RolesPermissionsManagement from "./components/RolesPermissionsManagement/RolesPermissionsManagement";

import GlobalCommandSearch from "./components/Shared/components/GlobalCommandSearch";

//Industry Management
import IndustryManagement from "./components/IndustryManagement/IndustryManagement";
import ConfigurableFields from "./components/IndustryManagement/ConfigurableFields/ConfigurableFields";

import CompanyReviews from "./components/CompanyReviews/CompanyReviews";

// Date Picker
import Datepicker from "@vuepic/vue-datepicker";
import '@vuepic/vue-datepicker/dist/main.css';
import ManagesGlobalShortcuts from "./mixins/manages-global-shortcuts";

//Advertising
import Advertising from './components/Advertising/Advertising';
import {HtmlScrubber} from "../utilities/HtmlScrubber";
import {SimpleRelativeTime} from "../utilities/SimpleRelativeTime";

//Filter Presets Config
import FilterPresetConfig from "./components/FilterPresetConfig/FilterPresetConfig";

// Date, Time & Currency formatting
import currency from "currency.js";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime.js';
import utc from 'dayjs/plugin/utc.js';
import Timezone from 'dayjs/plugin/timezone.js';
import advancedFormat from 'dayjs/plugin/advancedFormat.js';
[relativeTime, utc, Timezone, advancedFormat].forEach(extension => dayjs.extend(extension));

//external website authentication
import External from "./components/Shared/Auth/External";
import DispatchesGlobalEventsMixin from "./mixins/dispatches-global-events-mixin";
import OverSoldLeadReport from "./components/AdminDashboard/LeadIssueReports/OverSoldLeadReport.vue";
import DeliveredLeadReport from "./components/AdminDashboard/LeadIssueReports/DeliveredLeadReport.vue";
import AllocatedLeadReport from "./components/AdminDashboard/LeadIssueReports/AllocatedLeadReport.vue";
import NoChargedLeadReport from "./components/AdminDashboard/LeadIssueReports/NoChargedLeadReport.vue";
import TableLeadReport from "./components/AdminDashboard/LeadIssueReports/components/TableLeadReport.vue";

import piniaPluginPersistedState from "pinia-plugin-persistedstate"

export const ADMIN_MOUNT_SELECTOR = '#admin-app';

/**
 * Handles the registration of components.
 *
 * Currently, we explicitly register our vue components.
 * We could automatically register components in the `./components` directory.
 *
 * @param app
 */
function registerComponents(app) {
    app.component("AdminDashboard", AdminDashboard);
    app.component('LeadProcessing', LeadProcessing);
    app.component('LeadProcessingManagement', LeadProcessingManagement);
    app.component('Communications', Communications)
    app.component('LeadProcessingNotifications', LeadProcessingNotifications);
    app.component("SalesBaitManagement", SalesBaitManagement);
    app.component('UserManagement', UserManagement);
    app.component('UserSettings', UserSettings);
    app.component('FilterPresetConfig', FilterPresetConfig);
    app.component('Datepicker', Datepicker);
    app.component('HistoricalAvailableBudgetsReport', HistoricalAvailableBudgetsReport);
    app.component('EstimatedRevenuePerLeadReport', EstimatedRevenuePerLeadReport);
    app.component('SalesManagement', SalesManagement);
    app.component('TaskManagement', TaskManagement);
    app.component('Tasks', Tasks);
    app.component('TasksList', TasksList);
    app.component('TaskTypeEditor', TaskTypeEditor);
    app.component('CompaniesSearch', CompaniesSearch);
    app.component('EmailTemplates', EmailTemplates);
    app.component('RolesPermissionsManagement', RolesPermissionsManagement);
    app.component('GlobalCommandSearch', GlobalCommandSearch);
    app.component('CompanyPage', CompanyPage);
    app.component('AppLogo', AppLogo);
    app.component('IndustryManagement', IndustryManagement);
    app.component('CompanyReviews', CompanyReviews);
    app.component('ConfigurableFields', ConfigurableFields);
    app.component('Advertising', Advertising);
    app.component('ActivityItem', ActivityItem);
    app.component('ExternalAuth', External);
    app.component('GlobalCompanyWrapper', GlobalCompanyWrapper);
    app.component('OverSoldLeadReport', OverSoldLeadReport);
    app.component('DeliveredLeadReport', DeliveredLeadReport);
    app.component('AllocatedLeadReport', AllocatedLeadReport);
    app.component('NoChargedLeadReport', NoChargedLeadReport);
    app.component('TableLeadReport', TableLeadReport);
}

const clickOutside = {
    beforeMount: (el, binding) => {
        el.clickOutsideEvent = event => {
            if (!(el == event.target || el.contains(event.target))) {
                binding.value();
            }
        };
        document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: el => {
        document.removeEventListener("click", el.clickOutsideEvent);
    },
};

/**
 * Handles initializing the top level application for our project.
 */
export function initializeApp() {
    const app = createApp({
        data() {
            return {
                darkMode: false,
                userMenu: false,
                navShowing: false,
                globalCommandSearchShowing: false,
                hideMainContent: false,
            }
        },
        created() {
            this.darkMode = window.localStorage.getItem("admin-dark-mode") === "dark";
            this.registerShortcut("Command+K,Command+/,Ctrl+k,Ctrl+/", this.searchShortcut.bind(this));
            this.listenForGlobalEvent('load-company-page', () => {
                this.hideMainContent = true;
            });
        },
        mixins: [ManagesGlobalShortcuts, DispatchesGlobalEventsMixin],
        methods: {
            toggleDarkMode() {
                this.darkMode = !this.darkMode;
                window.localStorage.setItem("admin-dark-mode", this.darkMode ? "dark" : "light");

                // Allow scrollbars to change to dark or light based on current theme.
                if(this.darkMode) {
                    document.querySelector('body').classList.add("bg-dark-background");
                }
                else {
                    document.querySelector('body').classList.remove("bg-dark-background");
                }
            },
            toggleGlobalCommandSearch() {
                this.globalCommandSearchShowing ? this.closeGlobalSearch() : this.openGlobalSearch();
            },
            openGlobalSearch() {
                this.globalCommandSearchShowing = true;
            },
            closeGlobalSearch() {
                this.globalCommandSearchShowing = false;
            },
            toggleUserMenu() {
                this.userMenu = ! this.userMenu;
            },
            toggleNavigationMenu() {
                this.navShowing = ! this.navShowing;
            },
            searchShortcut() {
                console.log("search");

                if(!this.globalCommandSearchShowing)
                    this.openGlobalSearch();
            }
        },
        mounted() {
            // Allow scrollbars to change to dark or light based on current theme.
            if(this.darkMode) {
                document.querySelector('body').classList.add("bg-dark-background");
            }
            else {
                document.querySelector('body').classList.remove("bg-dark-background");
            }
        }
    }).directive("click-outside", clickOutside);

    if(process && process.env && process.env.MIX_APP_ENV === "local")
        app.config.devtools = true;

    registerComponents(app);

    const pinia = createPinia();
    app.use(pinia);

    pinia.use(piniaPluginPersistedState)

    const htmlScrubber = new HtmlScrubber();
    const simpleRelativeTime = new SimpleRelativeTime();

    app.config.globalProperties.$filters = {
        currency(value) {
            return currency(value).format();
        },
        /**
         * Global Vue date formatter. Accepts unix epoch/ISO 8601 input and uses dayjs library
         * @param value {string | number} - unix seconds / unix milliseconds / ISO 8601
         * @param dateFormat {string} - format string: use a key name shortcut from filtersConfig{} presets,
         *  otherwise see https://day.js.org/docs/en/display/format for all formatting options
         * @param forceTimezone {?string} - convert to specified IANA-valid timezone before formatting.
         *
         * @returns {string | string}
         */
        dateFromTimestamp,
        /**
         * Display a phone number in regional format
         * Any phone number with invalid characters will be returned intact so the error is visible to the User
         * Default (and currently only) region is North America
         *
         * @param input {string|number}
         * @param region {string} - must match a region in the above filterConfig{} object, fallback to NA
         * @returns {string}
         */
        formatPhoneNumber(input, region = 'na') {
            const regexInvalidCharacters = filterConfig.phoneNumbers[region].invalidInputCharacters ?? filterConfig.phoneNumbers.na.invalidInputCharacters,
                regionFormatter = filterConfig.phoneNumbers[region].formatOutput ?? filterConfig.phoneNumbers.na.formatOutput;
            input = typeof(input) === 'number'
                ? `${input}`
                : typeof(input) === 'string'
                    ? input
                    : '';
            if (!input || regexInvalidCharacters.test(input)) return input; // Return invalid 'numbers' without formatting
            const digits = input.replace(/\D/g, '');
            return regionFormatter(digits);
        },
        scrubHtml(htmlString) {
            return typeof(htmlString) === 'string'
                ? htmlScrubber.clean(htmlString)
                : '';
        },
        /** @param {number|string} timestamp - unix epoch milliseconds, or valid datetime string
         *  @param {boolean} UTC - assume UTC if no timezone provided
         *  @returns {string} - relative time from Now as a natural language string */
        simpleRelativeTimeFromTimestamp(timestamp, UTC = true) {
            return simpleRelativeTime.getRelativeTimeString(timestamp);
        },
        // TODO: replace with existing formatter once merged with newer branch
        absoluteDatetimeFromTimestamp(datetimeString) {
            datetimeString = /(T|\s)\d+:\d+:\d+(\.\d+)?$/.test(datetimeString)
                ? `${datetimeString}Z`
                : datetimeString;
            const date = new Date(datetimeString);
            return date
                ? date.toLocaleString()
                : 'Unknown comment date';
        },
    }

    app.mount(ADMIN_MOUNT_SELECTOR);
}
