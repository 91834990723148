<template>
    <div class="inline relative cursor-pointer"
         :class="{'active-tip': isActiveTip}"
         @click="toggleActiveTip"
         @hover="isActiveTip = true">
        <div class="flex flex-col relative">
            <div :class="{'fixed inset-0 z-30': isActiveTip}"></div>
            <slot name="title"></slot>
            <slot name="icon">
                <template v-if="$slots.icon">

                </template>
                <template v-else>
                    <svg class="w-5 h-5" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 14.0625C11.1244 14.0625 14.0625 11.1244 14.0625 7.5C14.0625 3.87563 11.1244 0.9375 7.5 0.9375C3.87563 0.9375 0.9375 3.87563 0.9375 7.5C0.9375 11.1244 3.87563 14.0625 7.5 14.0625ZM7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z" fill="#AFAFAF"/>
                        <path d="M7.91324 11H7.06849V5.86856H7.91324V11ZM7 4.50745C7 4.36518 7.0411 4.24503 7.12329 4.14702C7.20852 4.04901 7.33333 4 7.49772 4C7.6621 4 7.78691 4.04901 7.87215 4.14702C7.95738 4.24503 8 4.36518 8 4.50745C8 4.64973 7.95738 4.76829 7.87215 4.86314C7.78691 4.95799 7.6621 5.00542 7.49772 5.00542C7.33333 5.00542 7.20852 4.95799 7.12329 4.86314C7.0411 4.76829 7 4.64973 7 4.50745Z" fill="#AFAFAF"/>
                    </svg>
                </template>
            </slot>
        </div>
        <div class="relative">
            <div v-show="isActiveTip"
                 class="shadow-xl absolute transition-all duration-150 bg-light-module p-2 rounded border-grey-200 z-20"
                 :class="{
                    'right-0': right,
                    'left-0': mobileLeft,
                    'right-0': mobileRight,
                    'w-64': large,
                    'w-96': extraLarge,
                    'w-48': !large && !extraLarge,
                    'text-2xl': fontLarger
                }"
            >
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Tooltip",
    props: {
        right: {
            type: Boolean,
            default: false
        },
        mobileLeft: {
            type: Boolean,
            default: false
        },
        mobileRight: {
            type: Boolean,
            default: false
        },
        large: {
            type: Boolean,
            default: false
        },
        extraLarge: {
            type: Boolean,
            default: false
        },
        fontLarger: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            isActiveTip: false,
        }
    },
    methods: {
        toggleActiveTip () {
            this.isActiveTip = !this.isActiveTip;
        }
    }
}
</script>

<style>

</style>
