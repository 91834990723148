<template>
    <div class="grid grid-cols-2 mt-5">
        <div class="rounded-lg bg-red-100 p-4 mb-5 col-span-2" v-if="errorMessage">
            <div class="text-sm text-red-700">{{ errorMessage }}</div>
        </div>
        <div class="">
            <p class="uppercase font-semibold text-xs mb-1" :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                Task type
            </p>
            <Dropdown placeholder="Select option" v-model="taskType" :options="taskTypes" :dark-mode="darkMode"></Dropdown>
        </div>
        <div class="w-24 ml-3">
            <p class="uppercase font-semibold text-xs mb-1" :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                Priority
            </p>
            <input type="number" class="w-full border rounded focus:outline-none focus:border focus:border-primary-500 py-2" placeholder="0" min="0" max="4"
                   :class="{'border-grey-200 bg-grey-50': !darkMode, 'border-blue-700 bg-dark-background text-blue-400': darkMode}" v-model="priority"/>
        </div>
        <div class="col-span-2 mt-5">
            <p class="uppercase font-semibold text-xs mb-1" :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                Task name
            </p>
            <shortcode-input :dark-mode="darkMode" v-model="taskName" placeholder="Contact this company..."></shortcode-input>
        </div>

        <div class="pr-2">
            <p class="uppercase font-semibold text-xs mb-1 mt-5" :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                Assign To
            </p>

            <Dropdown placeholder="Select option" v-model="targetRelation" :options="targetRelations" :dark-mode="darkMode" :selected="targetRelation" :key="targetRelation"></Dropdown>
        </div>

        <div class="pl-1">
            <p class="uppercase font-semibold text-xs mb-1 mt-5"  :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                Rescheduling
            </p>

            <Dropdown v-model="reschedule" :options="rescheduleOptions" :dark-mode="darkMode" placeholder="Select option" :selected="reschedule"></Dropdown>
        </div>

        <div class="pl-1">
            <p class="uppercase font-semibold text-xs mb-1 mt-5"  :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                Inherit Task Notes?
            </p>

            <Dropdown v-model="inheritTaskNotes" :options="inheritOptions" :dark-mode="darkMode" placeholder="Select option" :selected="inheritTaskNotes"></Dropdown>
        </div>

        <div class="pl-1">
            <p class="uppercase font-semibold text-xs mb-1 mt-5"  :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                Task Category
            </p>

            <Dropdown v-model="taskCategory" :options="taskCategories" :dark-mode="darkMode" placeholder="Select Category" :selected="taskCategory"></Dropdown>
        </div>
        <div class="pl-1">
            <p class="uppercase font-semibold text-xs mb-1 mt-5"  :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                Dynamic Priority
            </p>

            <Dropdown v-model="dynamicPriorityEnabled" :options="dynamicPriorityOptions" :dark-mode="darkMode" placeholder="Select" :selected="dynamicPriorityEnabled"></Dropdown>
        </div>
        <div class="pl-1" v-if="dynamicPriorityEnabled">
            <p class="uppercase font-semibold text-xs mb-1 mt-5"  :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                Dynamic Priority Type
            </p>

            <Dropdown v-model="dynamicPriorityType" :options="dynamicPriorityTypes" :dark-mode="darkMode" placeholder="Select Type" :selected="dynamicPriorityType"></Dropdown>
        </div>

        <div class="col-span-2 mt-5">
            <p class="uppercase font-semibold text-xs mb-1" :class="{'text-grey-600': !darkMode, 'text-blue-400 ': darkMode}">
                Incomplete Notifiers
            </p>

            <notifier-selections
                :dropdown-options="templates"
                :dark-mode="darkMode"
                v-model="notifiers"></notifier-selections>
        </div>
    </div>
</template>

<script>
import Dropdown from "../../../Shared/components/Dropdown";
import ShortcodeInput from "../../../Shared/components/ShortcodeInput";
import ApiService from "../../services/api";
import HasTargetRelations from "../../mixins/has-target-relations";
import NotifierSelections from "../ResultTypes/NotifierSelections";

export default {
    name: "Task",
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        action: {
            type: Object,
            default: null
        }
    },
    components: {
        NotifierSelections,
        ShortcodeInput,
        Dropdown
    },
    data() {
        return {
            taskTypes: [],
            api: ApiService.make(),
            priority: 1,
            taskName: '',
            taskType: null,
            subject: '',
            targetRelation: 'sales-manager',
            reschedule: 1,
            inheritTaskNotes: 0,
            inheritOptions: [
                {id: 0, name: 'No'},
                {id: 1, name: 'Yes'},
            ],
            targetType: 'staff',
            notifiers: [],
            templates: [],
            rescheduleOptions: [
                {id: 1, name: 'enable'},
                {id: 0, name: 'disable'}
            ],
            taskCategories: [],
            taskCategory: 1,
            dynamicPriorityOptions: [
                {id: 0, name: 'disable'},
                {id: 1, name: 'enable'}
            ],
            dynamicPriorityEnabled: 0,
            dynamicPriorityType: null,
            dynamicPriorityTypes: [],
            errorMessage: null,
        }
    },
    mixins: [HasTargetRelations],
    created() {
        if (this.action) {
            this.taskType           = this.action.payload.task_type;
            this.taskCategory       = this.action.payload.task_category;
            this.priority           = this.action.payload.priority;
            this.notifiers          = this.action.payload.notifiers;
            this.taskName           = this.action.display_name;
            this.reschedule         = this.action.payload.reschedule;
            this.targetRelation     = this.action.payload.targets[0]?.target_relation;
            this.inheritTaskNotes   = this.action.payload.inherit_task_notes;

            this.dynamicPriorityEnabled = this.action.payload.dynamic_priority_enabled ?? 0;
            this.dynamicPriorityType    = this.action.payload.dynamic_priority_type ?? null;
        }

        this.getTaskTypes();
        this.getTaskCategories();
        this.loadTemplates();
        this.getDynamicPriorityTypes();
    },
    watch: {
        action: function () {
            if (this.action) {
                this.taskType           = this.action.payload.task_type;
                this.taskCategory       = this.action.payload.task_category;
                this.priority           = this.action.payload.priority;
                this.notifiers          = this.action.payload.notifiers;
                this.taskName           = this.action.display_name;
                this.reschedule         = this.action.payload.reschedule;
                this.targetRelation     = this.action.payload.targets[0]?.target_relation;
                this.inheritTaskNotes   = this.action.payload.inherit_task_notes;

                this.dynamicPriorityEnabled = this.action.payload.dynamic_priority_enabled ?? 0;
                this.dynamicPriorityType    = this.action.payload.dynamic_priority_type ?? null;
            } else {
                this.taskType       = null;
                this.priority       = 1;
                this.taskName       = '';
                this.reschedule     = 1;
                this.targetRelation = 'sales-manager';
                this.inheritTaskNotes = 0;

                this.dynamicPriorityEnabled = 0;
                this.dynamicPriorityType    = null;
            }
        }
    },
    methods: {
        getDisplayName() {
            return this.taskName;
        },
        getPayload() {
            return {
                task_type: this.taskType,
                task_category: this.taskCategory,
                task_name: this.taskName,
                priority: this.priority,
                reschedule: this.reschedule,
                targets: [{
                    target_type: this.targetType,
                    target_relation: this.targetRelation
                }],
                notifiers: this.notifiers,
                inherit_task_notes: this.inheritTaskNotes,
                dynamic_priority_enabled: this.dynamicPriorityEnabled,
                dynamic_priority_type: this.dynamicPriorityType
            }
        },
        getTaskTypes() {
            this.api.getTaskTypes().then(resp => {
                this.taskTypes = resp.data.data.task_types;
            });
        },
        loadTemplates() {
            this.api.getWorkflows(0).then(resp => {
                this.templates = resp.data.data.workflows;
            });
        },
        getTaskCategories() {
            this.api.getTaskCategories().then(resp => this.taskCategories = resp.data.data.categories);
        },
        getDynamicPriorityTypes() {
            this.api.getDynamicPriorityTypes().then(resp => {
                const types = resp.data.data.types;

                Object.keys(types).forEach(key => this.dynamicPriorityTypes.push({id: Number(key), name:types[key]}));
            });
        },
        validate() {
            this.errorMessage = !this.taskType
                ? `Task must have a selected Task Type.`
                : !this.taskName
                    ? `Task must have a Name.`
                    : null;
            return !this.errorMessage
        },
    }
}
</script>

<style scoped>

</style>
