<template>
    <div class="fixed z-50 w-screen flex justify-center mt-6">
        <alert :text="text" :alert-type="alertType" :dark-mode="darkMode"></alert>
    </div>
</template>

<script>
import Alert from "./Alert";
export default {
    name: "AlertsContainer",
    components: {
        Alert,
    },
    data() {
        return {
            showAlert: false,
        }
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        },
        alertType: {
            type: String,
            default: null
        },
        text: {
            type: String,
            default: null
        }
    }
}
</script>

<style scoped>

</style>
