<template>
    <div>
        <div class="main-layout font-body">
            <div class="w-full">
                <div class="w-full flex-auto pt-3 relative">
                    <div class="px-10" :class="[darkMode ? 'text-white' : 'text-slate-900']">
                        <div class="flex items-center justify-between flex-wrap py-6">
                            <h3 class="text-xl font-medium pb-0 leading-none mr-5">Tasks</h3>
                        </div>
                        <div>
                            <div class="grid md:grid-cols-1 xl:grid-cols-3 gap-4">
                                <Tasks :dark-mode="darkMode" @start-tasks="startTasks"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Tasks from "../Shared/modules/Tasks";
import DispatchesGlobalEventsMixin from "../../mixins/dispatches-global-events-mixin";
export default {
    name: "TasksList",
    components: {Tasks},
    props: {
        darkMode: {
            type: Boolean,
            default: false,
        }
    },
    mixins: [DispatchesGlobalEventsMixin],
    methods: {
        startTasks(tasks) {
            if (tasks.length > 0) {
                const params = new URLSearchParams();

                params.append('tasks', tasks.join(','));

                window.location.assign('/tasks/queue?' + params.toString());

                return;
            }

            window.location.assign('/tasks/queue');
        }
    }
}
</script>

<style scoped>

</style>
