<template>
    <div>
        <div class="w-full flex-auto pt-3 relative"
             :class="{'bg-light-background': !darkMode, 'bg-dark-background': darkMode}">
            <div class="px-10" :class="[darkMode ? 'text-white' : 'text-slate-900']">
                <div class="flex items-center justify-between flex-wrap">
                    <div class="flex justify-between items-center w-full py-4">
                        <h3  class="text-xl font-medium pb-0 leading-none">Email Templates</h3>
                        <button @click="toggleNewTemplateModal(true)" class="transition duration-200 bg-primary-500 hover:bg-blue-500 text-white text-sm font-medium focus:outline-none py-2 rounded-md px-5">
                            Create New Template
                        </button>
                    </div>
                </div>

                <div>
                    <div v-if="loading" class="text-center">
                        <loading-spinner/>
                    </div>
                    <div v-else-if="!loading && Object.keys(store.templates?.data || {}).length > 0">
                        <div v-show="pageLoading">
                            <loading-spinner/>
                        </div>
                        <div class="border rounded-lg"
                             :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
                            <div class="flex flex-row mb-8 border-b" :class="[darkMode ? 'border-dark-border' : 'border-light-border']">
                                <div class="uppercase border-b-2 px-6 pt-5 px-3 text-sm cursor-pointer pb-3 font-semibold"
                                     :class="store.templateTypeTab === 'content' ? 'text-blue-550 border-primary-500' : 'text-slate-400 border-transparent'"
                                     @click="store.changeTemplateTypeTab('content'); loadTemplatesList(store.templateTypeTab);">
                                    Content
                                </div>
                                <div class="uppercase border-b-2 px-6 pt-5 px-3 text-sm cursor-pointer pb-3 font-semibold"
                                     :class="store.templateTypeTab === 'background' ? 'text-blue-550 border-primary-500' : 'text-slate-400 border-transparent'"
                                     @click="store.changeTemplateTypeTab('background'); loadTemplatesList(store.templateTypeTab);">
                                    Header & Footer
                                </div>
                            </div>
                            <div class="grid grid-cols-7 gap-x-3 mb-2 px-5">
                                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-3">Name</p>
                                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs col-span-2">Creator</p>
                                <p class="text-slate-400 font-medium tracking-wide uppercase text-xs">Last Updated</p>
                            </div>
                            <div class="border-t border-b h-88 overflow-y-auto"
                                 :class="{'bg-light-background  border-light-border': !darkMode, 'bg-dark-background border-dark-border': darkMode}">
                                <div>
                                    <div class="grid grid-cols-7 gap-x-3 border-b px-5 py-3 items-center"
                                         v-for="template in store.templates.data" :key="template.id"
                                         :class="{'text-slate-900 hover:bg-light-module border-light-border': !darkMode, 'text-slate-100 hover:bg-dark-module border-dark-border': darkMode}">
                                        <p class="text-sm col-span-3 truncate">
                                            {{ template.name }}
                                        </p>
                                        <p class="text-sm col-span-2 truncate">
                                            {{ template.owner_name }}
                                        </p>
                                        <p class="text-sm truncate">
                                            {{ template.last_updated }}
                                        </p>
                                        <div v-if="store.editTemplateLoading && store.templateId === template.id">
                                            <loading-spinner :size="'w-4 h-4'"/>
                                        </div>
                                        <ActionsHandle v-else :dark-mode="darkMode" @edit="store.editTemplate(template.id)" @delete="toggleDeleteModal(true, template.id)" />
                                    </div>
                                </div>
                            </div>
                            <div class="p-3"></div>
                            <pagination
                            class="mt-5"
                            :pagination-data="store.templates"
                            :show-pagination="store.templates?.last_page > 1"
                            @change-page="loadTemplatesList(store.templateType, $event.link, true)"
                            :dark-mode="darkMode"
                        />
                        </div>
                    </div>
                    <div v-else class="text-center text-2xl">
                        No templates
                    </div>
                </div>
            </div>
        </div>

        <modal v-if="showDeleteModal"
               :confirm-text="'Yes'"
               :dark-mode="darkMode"
               :close-text="'No'"
               @confirm="deleteTemplate"
               @close="toggleDeleteModal(false)"
               :no-buttons="deleting"
               :small="true">
            <template v-if="!deleting" v-slot:header>
                <span class="font-bold">Confirm Deletion</span>
            </template>
            <template v-slot:content>
                <div v-if="deleting" class="text-center">Deleting...</div>
                <div v-else class="text-red-500">Are you sure you want to delete this template?</div>
            </template>
        </modal>

        <modal v-if="showNewTemplateModal"
               :confirm-text="'Confirm'"
               :dark-mode="darkMode"
               :close-text="'Cancel'"
               @confirm="toggleNewTemplateModal(false); store.changeTemplateTypeTab(store.templateType); store.editTemplate();"
               @close="toggleNewTemplateModal(false)"
               :small="true">
            <template v-slot:header>
                <span class="font-bold">Choose Template Type</span>
            </template>
            <template v-slot:content>
                <p class="font-bold">Template Type</p>
                <select v-model="store.templateType" class="w-full p-2 rounded my-1" :class="{'hover:bg-grey-120 border-grey-200 text-grey-400 bg-grey-50': !darkMode, 'hover:border-blue-400 border-blue-700 text-blue-400 bg-dark-background': darkMode}">
                    <option value="content">Content</option>
                    <option value="background">Header & Footer</option>
                </select>
            </template>
        </modal>
    </div>
</template>

<script>
    import LoadingSpinner from "../../Shared/components/LoadingSpinner";
    import Pagination from "../../Shared/components/Pagination";
    import Modal from "../../Shared/components/Modal";
    import {useEmailTemplatesStore} from "../store/store";
    import ActionsHandle from "../../Shared/components/ActionsHandle";

    export default {
        name: "TemplatesList",
        props: {
            darkMode: {
                type: Boolean,
                default: false
            }
        },
        components: {
            ActionsHandle,
            LoadingSpinner,
            Pagination,
            Modal
        },
        setup: function() {
            const store = useEmailTemplatesStore();

            return {
                store
            };
        },
        created: function() {
            this.loadTemplatesList('content');
        },
        data: function() {
            return {
                loading: false,
                pageLoading: false,
                showDeleteModal: false,
                deleteTemplateId: 0,
                deleting: false,
                showNewTemplateModal: false,
            };
        },
        methods: {
            loadTemplatesList(templateType, url = '', pageLoading = false) {
                if(pageLoading) {
                    this.pageLoading = true;
                }
                else {
                    this.loading = true;
                }

                this.store.getEmailTemplates(templateType, url).finally(() => {
                    this.loading = false;
                    this.pageLoading = false;
                });
            },
            toggleDeleteModal(show, templateId = 0) {
                this.deleteTemplateId = show ? templateId : 0;
                this.showDeleteModal = show;
            },
            toggleNewTemplateModal(show) {
                this.store.reset();
                this.showNewTemplateModal = show;
            },
            deleteTemplate() {
                this.deleting = true;

                this.store.deleteTemplate(this.deleteTemplateId).then(() => {
                    this.loadTemplatesList(this.store.templateTypeTab);
                }).finally(() => {
                    this.toggleDeleteModal(false);
                    this.deleting = false;
                });
            }
        }
    }
</script>
