<template>
    <div id="public-comment">
        <div class="p-5 border rounded-lg" :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
            <div>
                <h5 class="text-primary-500 text-sm uppercase font-semibold leading-tight">Public Comments</h5>
            </div>
            <div class="mt-5">
                <textarea v-model="publicComment"
                          class="w-full h-full p-2 rounded-lg border"
                          :class="{'bg-light-module border-light-border': !darkMode, 'bg-dark-module border-dark-border': darkMode}">
                </textarea>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PublicComment",
        props: {
            modelValue: {
                type: String,
                default: ''
            },
            darkMode: {
                type: Boolean,
                default: false,
            }
        },
        emits: ['update:modelValue'],
        computed: {
            publicComment: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit('update:modelValue', value);
                }
            }
        }
    };
</script>
